import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import "./giving.css";
import Alert from "../../../components/Alert/Alert";
import Loading from "../../../components/Loading/Loading";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const Methods = () => {
  const [clicked, setClicked] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, methods) => {
    setShow2(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (e, methods) => {
    e.preventDefault();
    setShow1(true);
    setClicked(methods);
  };

  const [methodName, setMethodName] = useState("");
  const [methodNamex, setMethodNamex] = useState("");
  const [uniqueData, setUniqueData] = useState(null);
  const [error, setError] = useState(false);

  const {
    displayAlert,
    showAlert,
    getMethod,

    deleteMethod,
    addMethod,
    allmethods,
    isLoading,
    user,
    logoutUser,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getMethod();
  }, []);

  const getMethodById = async (e, method) => {
    e.preventDefault();

    const response = await authFetch.get(
      `/giving/give/getmethodbyidunique/${method}`
    );
    if (response.status === 200 || response.status === 201) {
      const { data } = response;
      setUniqueData(data);
      handleShow2();
    }
    // const uniqueDatax = data.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    // setUniqueData(uniqueDatax);
  };

  useEffect(() => {
    if (uniqueData) {
      setMethodNamex(uniqueData.methodName);
    }
  }, [uniqueData]);

  const handleDelete = async () => {
    const response = deleteMethod(clicked._id, {
      alertText: "Method Deleted",
    });
    if (response) {
      getMethod();
      handleClose1();
      toast(
        <CustomToastContent
          message="Method Deleted Successfully"
          type="success"
        />
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!methodName) {
      setError(true);
      return;
    }
    const addMethods = {
      methodName: methodName,
      churchName: user.churchName,
    };
    setMethodName();
    addMethod({ addMethods });
    getMethod();
    handleClose();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!methodNamex) {
      displayAlert();
      return;
    }

    const addMethods = {
      methodName: methodNamex,
    };

    const response = await authFetch.patch(
      `/giving/give/updatemethod/${uniqueData._id}`,
      addMethods
    );

    if (response.status === 200 || response.status === 201) {
      setMethodName();
      getMethod();
      handleClose2();
      toast(
        <CustomToastContent
          message="Method Updated Successfully!"
          type="success"
        />
      );
    } else {
      // console.error("Unexpected response status:", response.status);
      toast(
        <CustomToastContent message="Error Updating Method" type="error" />
      );
    }
  };

  if (isLoading) {
    <Loading />;
    return;
  }
  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="courseheader">
          <Col>
            <p className="sahs">Giving Method</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button className="wedweded" onClick={handleShow}>
              New Giving Method
            </Button>
          </div>
        </Row>
        {allmethods && allmethods.length > 0 ? (
          <Row className="fundc2" style={{ paddingTop: "3rem" }}>
            <Table responsive hover style={{ backgroundColor: "#f8f8f8" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Method</th>
                  <th style={{ fontSize: "1em" }}>Actions</th>
                </tr>
              </thead>

              <tbody>
                {allmethods.map((methods) => (
                  <tr>
                    <td style={{ fontSize: "1em" }}>{methods.methodName}</td>

                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getMethodById(e, methods._id)}
                      >
                        Edit
                      </button>

                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, methods)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Giving Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">
                Giving Method is required to proceed
              </p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Method Name"
                value={methodName}
                onChange={(e) => setMethodName(e.target.value)}
                name="methodName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Update Giving Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Method Name"
                value={methodNamex}
                onChange={(e) => setMethodNamex(e.target.value)}
                name="methodName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Giving Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected Giving Method.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Methods;
