import React from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const CustomToastContent = ({ title, message, type }) => {
  const iconStyle = { marginRight: 10 };

  const getIcon = () => {
    if (type === "error") {
      return <FaExclamationCircle size={24} style={iconStyle} color="red" />;
    }
    return (
      <FaCheckCircle size={24} style={iconStyle} color="var(--primary-color)" />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "var(--primary-color)",
        fontFamily: "var(--font-family)",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        backgroundColor: "#d2dbff",
      }}
    >
      {getIcon()}
      <div style={{ backgroundColor: "#d2dbff" }}>
        <strong>{title}</strong>
        <div>{message}</div>
      </div>
    </div>
  );
};

export default CustomToastContent;
