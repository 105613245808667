import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import PlayAudio from "./PlayAudio";
import AudioLink from "./AudioLink";

const AudioCard = ({ audio }) => {
  const handlePlay = (e, audio) => {
    setShow(true);
    setSelectedAudio(audio);
  };

  const handlePlay2 = (e, audio) => {
    setShow2(true);
    setSelectedAudio(audio);
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);

  return (
    <>
      {audio.audioLink ? (
        <div
          onClick={(e) => handlePlay2(e, audio)}
          className="pointer"
          style={{ textDecoration: "none" }}
        >
          <Card className="main">
            <Card.Body>
              <Card.Title className="courseTitle">
                {audio.audioName.length > 50
                  ? `${audio.audioName.substring(0, 50)}...`
                  : audio.audioName}
              </Card.Title>
              <small className="text-muted">
                posted in {moment(audio.createdAt).format("MMMM YYYY")}
              </small>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div
          onClick={(e) => handlePlay(e, audio)}
          className="pointer"
          style={{ textDecoration: "none" }}
        >
          <Card className="main">
            <Card.Body>
              <Card.Title className="courseTitle">
                {audio.audioName.length > 50
                  ? `${audio.audioName.substring(0, 50)}...`
                  : audio.audioName}
              </Card.Title>
              <small className="text-muted">
                posted in {moment(audio.createdAt).format("MMMM YYYY")}
              </small>
            </Card.Body>
          </Card>
        </div>
      )}
      <PlayAudio show={show} setShow={setShow} selectedAudio={selectedAudio} />
      <AudioLink
        show={show2}
        setShow={setShow2}
        selectedAudio={selectedAudio}
      />
    </>
  );
};

export default AudioCard;
