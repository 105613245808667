import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const AddPlanner = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //const [tasks, setTasks] = useState([""]);
  const [notifyMe, setNotifyMe] = useState(false);
  const [assign, setAssign] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [radioOptions, setRadioOptions] = useState([{ id: 1, value: "" }]);
  const [checkboxOptions, setCheckboxOptions] = useState([
    { id: 1, value: "" },
  ]);
  const maxChars = 800;

  const [tasks, setTasks] = useState([
    { question: "", type: "text", options: [], order: 1, checked: false }, // Initial task structure
  ]);

  // Function to handle question text change
  const handleTaskChange = (index, value) => {
    setTasks((currentTasks) => {
      // Make a copy of the current tasks array
      const newTasks = [...currentTasks];

      // Update the 'question' property of the task at the specified index
      // Ensure that the element at the specified index is indeed an object
      if (typeof newTasks[index] === "object" && newTasks[index] !== null) {
        newTasks[index] = { ...newTasks[index], question: value };
      } else {
        console.error(
          "Task at index",
          index,
          "is not an object:",
          newTasks[index]
        );
        // Optionally, correct the data issue by resetting this task to a default object structure
        newTasks[index] = { question: value, type: "text", options: [] };
      }

      return newTasks;
    });
  };

  // Function to change the type of response for each task
  const handleTypeChange = (index, value) => {
    const newTasks = [...tasks];
    newTasks[index].type = value;
    setTasks(newTasks);
  };

  // Function to add a new task
  const addTask = () => {
    setTasks([...tasks, { question: "", type: "text", options: [] }]);
  };

  // Function to add an option to a task
  const addOptionToTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].options.push("");
    setTasks(newTasks);
  };

  // Function to handle option text change
  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const newTasks = [...tasks];
    newTasks[taskIndex].options[optionIndex] = value;
    setTasks(newTasks);
  };

  // Function to remove an option from a task
  const removeOption = (taskIndex, optionIndex) => {
    const newTasks = [...tasks];
    newTasks[taskIndex].options.splice(optionIndex, 1);
    setTasks(newTasks);
  };

  const handleQuillChange = (content) => {
    setDescription(content);
  };

  const handleCheckChange = (index, isChecked) => {
    const newTasks = tasks.map((task, i) => {
      if (i === index) {
        return { ...task, checked: isChecked };
      }
      return task;
    });
    setTasks(newTasks);
  };

  const removeForm = (index) => {
    const updatedTasks = tasks
      .filter((_, taskIndex) => taskIndex !== index)
      .map((task, i) => ({ ...task, order: i + 1 }));
    setTasks(updatedTasks);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const handlePlanner = async (e) => {
    e.preventDefault();
    try {
      if (!title) return setError(true);

      if (description.length > maxChars) return setError2(true);

      const dataxx = {
        title,
        description,
        tasks,
        notifyMe,
        assign,
        churchName: user.churchName,
      };

      const response = await authFetch.post("/learning/planner", dataxx);
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setTitle("");
        setDescription("");
        setTasks([""]);
        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Growth Planner Created"
          />
        );
      }
      //console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>New Development Planner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && (
            <p className="inlineerror">
              Please complete the required fields to proceed!!
            </p>
          )}

          {error2 && (
            <p className="inlineerror">
              Description is above the required length!!
            </p>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicDescription" className="mb-5">
              <Form.Label>Form Description</Form.Label>

              <ReactQuill
                theme="snow"
                placeholder="Enter description"
                className="lpmw"
                style={{ height: "200px" }}
                value={description}
                onChange={handleQuillChange}
              />
              <div className="character-count flex items-center justify-end">
                {`${description.length} / ${maxChars}`} characters.
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicDescription" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Notify me at completion"
                checked={notifyMe}
                onChange={(e) => setNotifyMe(e.target.checked)}
              />
              <Form.Check
                type="checkbox"
                label="I will assign this to someone else"
                checked={assign}
                onChange={(e) => setAssign(e.target.checked)}
              />
            </Form.Group>

            <div className="mt-3">
              <Form.Label>Tasks</Form.Label>
              {tasks.map((task, index) => (
                <Row key={index} className="mb-3">
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      placeholder="Question"
                      value={task.question}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Select
                      aria-label="Default select example"
                      value={task.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                    >
                      <option>Choose...</option>
                      <option value="text">Short answer</option>
                      <option value="textarea">Paragraph</option>
                      <option value="radio">Multiple Choice</option>
                      <option value="checkbox">Checkboxes</option>
                    </Form.Select>
                  </Col>
                  <Col
                    md={2}
                    className="flex flex-row justify-between "
                    style={{ alignItems: "center" }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={task.isChecked} // Use `checked` for checkboxes instead of `value`
                      label="Required"
                      onChange={(e) =>
                        handleCheckChange(index, e.target.checked)
                      } // Pass `e.target.checked` to get the boolean state
                    />

                    {index > 0 && (
                      <p
                        onClick={() => removeForm(index)}
                        style={{
                          marginBottom: "0px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Remove
                      </p>
                    )}
                  </Col>
                  {task.type === "radio" || task.type === "checkbox" ? (
                    <>
                      {task.options.map((option, optionIndex) => (
                        <Form.Group
                          key={optionIndex}
                          className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                        >
                          <Form.Check
                            type={task.type}
                            name={`group-${index}`}
                          />
                          <Form.Control
                            type="text"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                e.target.value
                              )
                            }
                          />
                          <FaTimes
                            onClick={() => removeOption(index, optionIndex)}
                            style={{ cursor: "pointer" }}
                          />
                        </Form.Group>
                      ))}
                      <button
                        className="jiluil3 mt-3 mb-3"
                        onClick={() => addOptionToTask(index)}
                      >
                        Add Option
                      </button>
                      <hr></hr>
                    </>
                  ) : null}
                </Row>
              ))}
              <hr></hr>
              <button className="jiluil mb-3s" onClick={addTask}>
                Add Task
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil3" onClick={handlePlanner}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPlanner;
