import dayjs from "dayjs";
import React, { useContext } from "react";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import "./calendar.css";

export default function Day({ day, rowIdx, events, getEvents }) {
  const { setDaySelected, setShowEventModal, setSelectedEvent } =
    useContext(GlobalContext);

  // const dayEvent =
  //   !!events?.length &&
  //   events.filter(
  //     (evt) =>
  //       dayjs(+evt?.eventDay).format("YY-MM-DD") === day?.format("YY-MM-DD")
  //   );

  const dayEvent =
    !!events?.length &&
    events.filter(
      (evt) =>
        dayjs(evt?.eventDay)?.format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
    );

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "text-light bg-secondary rounded-circle"
      : "";
  }

  return (
    <div className="daymain">
      <header className="dayheader">
        {rowIdx === 0 && (
          <p className="daypara">{day.format("ddd").toUpperCase()}</p>
        )}
        <p
          className={`daypara2  ${getCurrentDayClass()}`}
          style={{ marginBottom: "0" }}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className="dayselect"
        onClick={() => {
          //  setDaySelected(day);
          setShowEventModal(true);
        }}
      >
        {!!dayEvent?.length &&
          dayEvent?.map((evt, idx) => (
            <div
              key={idx}
              onClick={() => setSelectedEvent(evt)}
              style={{
                backgroundColor: `${evt.eventLabel}`,
                color: "white",
                textAlign: "center",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                fontSize: "0.8em",
                cursor: "pointer",
              }}
            >
              {evt.eventTitle.substring(0, 20)}
            </div>
          ))}
      </div>
    </div>
  );
}
