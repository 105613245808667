import React from "react";
import Container from "react-bootstrap/Container";

import "./calendar.css";

export default function Labels({ lables, handleChange }) {
  return (
    <>
      <Container fluid className="labelmain">
        <p className="mainlabel">Label</p>
        {lables.map(({ lbl, checked }, idx) => (
          <label key={idx} className="labelz">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => handleChange({ lbl, checked: !checked }, idx)}
              className="labelz2"
              style={{ color: `${lbl}`, backgroundColor: `${lbl}` }}
            />

            <span className="myla" style={{ color: `${lbl}` }}>
              {lbl}
            </span>
          </label>
        ))}
      </Container>
    </>
  );
}
