import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastManager() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      toastStyle={{ backgroundColor: "#d2dbff" }} // Ensure the background color and padding are applied
    />
  );
}

export default ToastManager;
