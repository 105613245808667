import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import { Helmet } from "react-helmet";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer.js";
import "./contact.css";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent.js";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [churchName, setChurchName] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [country, setCountry] = useState("");
  const { isLoading, showAlert, displayAlert, userDemo } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !country ||
      !lastName ||
      !churchName ||
      !aboutUs ||
      !email
    ) {
      toast(
        <CustomToastContent title="Error" message="All fields are required" />
      );
      return;
    }

    const demoUser = {
      email,
      firstName,
      lastName,
      churchName,
      country,
      aboutUs,
      mmobilePhone: phoneNumber,
    };

    userDemo({
      demoUser,
      alertText: "Request Submitted Successfully!",
    });

    setFirstName("");
    setLastName("");
    setChurchName("");
    setCountry("");
    setAboutUs("");
    setPhoneNumber("");
    setEmail("");
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Churchease</title>
        <meta
          name="description"
          content="Have questions or need support? Contact the Churchease team today for assistance or more information about our services."
        />
        <link rel="canonical" href="https://www.churchease.com/contact" />
        <meta property="og:title" content="Contact Us - Churchease" />
        <meta
          property="og:description"
          content="Have questions or need support? Contact the Churchease team today for assistance or more information about our services."
        />
        <meta property="og:url" content="https://www.churchease.com/contact" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Churchease, contact Churchease, support, church management software support, church software, customer service"
        />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="contactease">
            <p className="contactease1">GET IN TOUCH</p>
            <p className="contactease2">
              We’d love to hear from you! Whether you have questions, need
              support, or want to learn more about how Churchease can assist
              you, please complete the form below, and one of our experts will
              be in touch shortly. Thank you!
            </p>
          </div>
        </Row>
        <Row className="flex-grow-1">
          <Col md={6} className="mx-auto my-5">
            <Form className="formdivcontact">
              <Form.Group className="mb-3" controlId="formCountry">
                <Form.Label>
                  Select Country <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="forminputcontact"
                >
                  <option value=""></option>
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formChurchName">
                <Form.Label>
                  Church Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Church Name"
                  value={churchName}
                  onChange={(e) => setChurchName(e.target.value)}
                  className="forminputcontact"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>
                  First Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="forminputcontact"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>
                  Last Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="forminputcontact"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="forminputcontact"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="forminputcontact"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formAboutUs">
                <Form.Label>
                  What would you like to discuss?{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder=""
                  value={aboutUs}
                  onChange={(e) => setAboutUs(e.target.value)}
                  className="forminput2222"
                />
              </Form.Group>

              <div className="text-center">
                <button
                  onClick={handleSubmit}
                  className="contactButton"
                  disabled={isLoading}
                >
                  Contact Us
                </button>
              </div>
              <p className="rediv text-danger pt-4">
                Fields marked with an asterisk (*) are required.
              </p>
              <p className="rediv">
                By completing and submitting the form, you will receive
                information, tips, and promotions from Churchease. To learn more
                about how Churchease uses your information, see our{" "}
                <Link to="/privacy" className="text-decoration-none">
                  Privacy Policy
                </Link>
                .
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
