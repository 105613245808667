import React, { useState } from "react";
import CourseCreateForm from "../../../components/CourseCreateForm/CourseCreateForm";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const AddCourse = () => {
  const { logoutUser, user } = useAppContext();

  const [values, setValues] = useState({
    name: "",
    description: "",
    uploading: false,
    loading: false,
    churchName: user.churchName,
  });
  const navigate = useNavigate();
  const [uploaded, setUploaded] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [image, setImage] = useState({});
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const maxSizeInBytes = 1024 * 1024; // 1 MB

  const handleImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 700, 500, "JPEG", 100, 0, async (uri) => {
      try {
        // Calculate the current size of the image in bytes
        const currentSizeInBytes = uri.length;

        // If the image size exceeds the maximum size, calculate a new quality value to meet the size constraint
        if (currentSizeInBytes > maxSizeInBytes) {
          const newQuality = Math.floor(
            (maxSizeInBytes / currentSizeInBytes) * 100
          );

          // Resize the image again with the new quality value
          Resizer.imageFileResizer(
            file,
            700,
            500,
            "JPEG",
            newQuality,
            0,
            async (newUri) => {
              // Now, 'newUri' contains the resized image with the adjusted quality to meet the size constraint

              try {
                let { data } = await authFetch.post("/learning/upload-image", {
                  image: newUri,
                });

                // set image in the state
                setImage(data);
                setValues({ ...values, loading: false });
                setUploaded(true);
              } catch (err) {
                setValues({ ...values, loading: false });
              }
            }
          );
        } else {
          // If the image size is already within the size constraint, upload it without further resizing
          let { data } = await authFetch.post("/learning/upload-image", {
            image: uri,
          });

          // set image in the state
          setImage(data);
          setValues({ ...values, loading: false });
          setUploaded(true);
        }
      } catch (err) {
        setValues({ ...values, loading: false });
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      await authFetch.post(`/learning/remove-image`, { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Image");
      setValues({ ...values, loading: false });
    } catch (err) {
      setValues({ ...values, loading: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation: Check if the required values are present
    if (!values.name || !values.description) {
      setError(true);
      setErrorMessage(
        "Please provide a course name and description to proceed."
      );
      return;
    }

    try {
      const response = await authFetch.post("/learning/course", {
        ...values,
        image,
      });
      navigate("/auth/learninghome");
    } catch (err) {
      // Handle network errors or issues with the fetch call
      setErrorMessage("Network error or issue with the request.");
      console.error("Request error:", err);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <p className="sahs">Create Learning</p>
        </Row>
        {/* {errorMessage && (
          <CustomAlert alertText={errorMessage} alertType="danger" />
        )} */}
        <Row>
          <div className="pt-5 pb-5">
            <CourseCreateForm
              handleSubmit={handleSubmit}
              handleImage={handleImage}
              handleChange={handleChange}
              values={values}
              setValues={setValues}
              preview={preview}
              uploadButtonText={uploadButtonText}
              handleImageRemove={handleImageRemove}
              error={error}
              setError={setError}
              uploaded={uploaded}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddCourse;
