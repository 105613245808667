import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./prayers.css";
import Loading from "../../../components/Loading/Loading";
import moment from "moment/moment";
import { FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const PrayerAchieve = () => {
  const {
    getPrayerRequest,

    prayers,
    isLoading,
    deletePrayer,
    updatePrayer,
  } = useAppContext();

  useEffect(() => {
    getPrayerRequest();
  }, []);

  const prayerCompleted = prayers.filter((prayer) => prayer.status === false);

  const [hoveredItem, setHoveredItem] = useState(null);
  const [show, setShow] = useState(false);
  const [prayer, setPrayer] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (prayer) => {
    setShow(true);
    setPrayer(prayer);
  };

  const handleMouseEnter = (prayerId) => {
    setHoveredItem(prayerId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDelete = async () => {
    const response = await deletePrayer(prayer._id, {
      alertText: "Prayer Deleted",
    });

    if (response.status === 200 || response.status === 201) {
      toast(
        <CustomToastContent
          message="Prayer Deleted Successfully"
          title="Success"
        />
      );
      handleClose();
    }
  };

  const [status, setStatus] = useState(true);

  const handleCheckboxChange = async (e, prayer) => {
    e.stopPropagation();
    setStatus(true);
    const prayerRequestUpdate = {
      status: status,
      prayerId: prayer._id,
    };

    let prayerId = prayer._id;

    const response = await updatePrayer(prayerRequestUpdate, prayerId);
    if (response.status === 200 || response.status === 201) {
      toast(
        <CustomToastContent
          message="Prayer Restored Successfully"
          title="Success"
        />
      );
    }
  };

  const extractedData = prayerCompleted.map(
    ({ prayerSubject, prayerRequest, createdAt }) => ({
      prayerSubject,
      prayerRequest,
      createdAt,
    })
  );
  <div className="flex justify-center items-center w-full h-screen">
    {isLoading && <Loading />}
  </div>;
  return (
    <Container fluid>
      <Row className="prayerhead">
        <p className="sahs" style={{ display: "flex", justifyContent: "left" }}>
          Prayer Achieve
        </p>
      </Row>
      {/* <Row className="sssssssecwef">
        <div
          className="bnmjo"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button className="buttonner1ss">
            <CSVLink
              data={extractedData}
              filename="achievedPrayer.csv"
              style={{ textDecoration: "none", color: "white" }}
              className="buttoner"
            >
              Download
            </CSVLink>
          </Button>
        </div>
      </Row> */}
      <Row>
        <div className="prayerwall">
          {prayerCompleted.length === 0 ? (
            <div style={{ fontWeight: "600" }}>No Achieved Prayer Request</div>
          ) : (
            prayerCompleted.map((prayer) => (
              <div
                className={`prayer2 ${
                  hoveredItem === prayer._id ? "hovered" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(prayer._id)}
                onMouseLeave={handleMouseLeave}
              >
                <p className="praydate">
                  {moment(prayer.createdAt).format("MMMM Do YYYY")}
                </p>
                <p className="prayersubject">
                  {prayer?.prayerSubject?.substring(0, 25) + "..."}
                </p>
                <p style={{ flex: 1 }}>
                  {prayer?.prayerRequest?.substring(0, 75) + "..."}
                </p>
                {hoveredItem === prayer._id && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="jiluil"
                      onClick={(e) => handleCheckboxChange(e, prayer)}
                    >
                      Restore
                    </button>
                    <button
                      className="jiluil3"
                      onClick={() => handleShow(prayer)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em", fontWeight: "600" }}>
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Deleted prayer requests cannot be recovered!</Modal.Body>
        <Modal.Footer>
          <button
            className="jiluil"
            style={{ fontSize: "1em" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="jiluil3"
            style={{ fontSize: "1em" }}
            onClick={handleDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PrayerAchieve;
