import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const PieChartMaritalStatus = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [data, setData] = useState([]);

  useEffect(() => {
    getMaritalStatusStats();
  }, []);

  const getMaritalStatusStats = async () => {
    try {
      const response = await authFetch.get("/members/getmaritalstatusstat");

      if (response.status === 200 || response.status === 201) {
        // Extract marital status counts
        const stats = response.data.maritalStatusStats;

        // Transform the data to fit the PieChart format
        const maritalStatusData = [
          { name: "Single", value: stats.Single },
          { name: "Married", value: stats.Married },
          { name: "Divorced", value: stats.Divorced },
          { name: "Widowed", value: stats.Widowed },
          { name: "Separated", value: stats.Separated },
          { name: "Engaged", value: stats.Engaged },
          { name: "Undefined", value: stats.Undefined },
        ];
        setData(maritalStatusData);
      }
    } catch (error) {
      console.error("Error fetching marital status data:", error);
    }
  };

  const COLORS = [
    "#243060",
    "#00C49F",
    "#FFBB28",
    "#FE7064",
    "#FF6384",
    "#36A2EB",
    "#888888",
  ]; // Colors for each marital status

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          //   label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartMaritalStatus;
