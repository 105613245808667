import React, { useEffect } from "react";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
const Security = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Security - Churchease</title>
        <meta
          name="description"
          content="At Churchease, we take the security of your data seriously. Learn more about our security practices and measures in place to protect your information."
        />
      </Helmet>

      <Header />
      <div className="privacy-body">
        <div className="privacy-policy-container">
          <h1>Security and Privacy</h1>
          <p>How We Secure Your Data On Churchease</p>
        </div>
        <div>
          <p className="privacy-di" style={{ paddingBottom: "2rem" }}>
            At Churchease, we prioritize the security of your data above all
            else. We understand the significance of trust in handling sensitive
            information within a faith-based community. This Security Assurance
            page outlines our steadfast commitment to safeguarding your data
            through stringent security measures.
          </p>

          <div className="privacy-content">
            <h2>Robust Data Encryption</h2>
            <p className="privacy-di">
              All data transmitted to and from Churchease is encrypted using
              advanced encryption protocols. This ensures that your data remains
              confidential and secure, mitigating the risk of unauthorized
              access during transit.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Stringent Access Control</h2>
            <p className="privacy-di">
              Our platform implements strict access controls that limit data
              access to authorized individuals only. Through rigorous
              authentication mechanisms, including multi-factor authentication
              (MFA), we ensure that only authenticated users can access
              sensitive information.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Continuous Monitoring and Auditing</h2>
            <p className="privacy-di">
              We continuously monitor our systems to detect and respond to any
              anomalies or potential security threats promptly. Regular security
              audits and assessments are conducted to identify vulnerabilities
              and ensure that our security protocols remain effective.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Certified Platform Hosting</h2>
            <p className="privacy-di">
              Churchease is hosted on a SOC 2 Type 2, ISO 27001, and PCI DSS
              certified platform. These certifications validate the security of
              our infrastructure, indicating our dedication to maintaining the
              highest standards of data protection.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Data Minimization and Retention</h2>
            <p className="privacy-di">
              We adhere to the principle of data minimization, collecting only
              the information necessary to provide our services. Additionally,
              we maintain strict data retention policies to ensure that data is
              stored for only as long as required and is securely disposed of
              when no longer needed.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Regular Security Training</h2>
            <p className="privacy-di">
              Our team members undergo regular security training to stay updated
              on the latest security best practices and industry standards. This
              empowers us to stay vigilant and respond effectively to emerging
              security challenges.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Confidentiality and Data Ownership</h2>
            <p className="privacy-di">
              We respect the confidentiality of your data and do not share it
              with third parties without your explicit consent. You retain full
              ownership of the data you entrust to us, and we ensure its
              security throughout its lifecycle.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Rapid Incident Response</h2>
            <p className="privacy-di">
              In the event of a security incident, we have a well-defined
              incident response plan in place. This allows us to respond
              swiftly, mitigate the impact, and take necessary actions to
              prevent future occurrences.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Your Security, Our Priority</h2>
            <p className="privacy-di">
              We recognize the responsibility entrusted to us in safeguarding
              your data. Our unwavering commitment to security reflects our
              dedication to protecting your church's information. If you have
              any questions or concerns about our security practices, please
              contact our security team at{" "}
              <span style={{ color: "#FE7064" }}>security@churchease.com</span>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Security;
