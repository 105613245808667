import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "./signup.css";
import InputGroup from "react-bootstrap/InputGroup";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { Button } from "react-bootstrap";

const ChurchInfo = ({ nextStep, handleFormData, values }) => {
  const [error, setError] = useState("");
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    if (
      validator.isEmpty(values.churchName) ||
      !validator.isLength(values.churchName, { min: 2, max: 120 })
    ) {
      setError("Church name is invalid or too short/long.");
    } else if (
      validator.isEmpty(values.churchDomain) ||
      !validator.isLength(values.churchDomain, { min: 2, max: 120 })
    ) {
      setError("Church domain is invalid or too short/long.");
    } else {
      setError(""); // Clear any existing errors
      nextStep(); // Proceed to the next step
    }
  };

  return (
    <>
      <Form onSubmit={submitFormData}>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            defaultValue={values.churchName}
            type="text"
            placeholder="Church Name"
            label="Church Name"
            className="mb-3 forminputsign"
            onChange={handleFormData("churchName")}
          />
          {error && <div className="error-message">{error}</div>}

          <InputGroup
            className="mb-3"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              style={{ border: error ? "2px solid red" : "" }}
              placeholder="Preferred Domain Name"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className="mb-3 forminputsign"
              defaultValue={values.churchDomain}
              onChange={handleFormData("churchDomain")}
            />
            <InputGroup.Text id="basic-addon2" style={{ height: "3rem" }}>
              @churchease.com
            </InputGroup.Text>
          </InputGroup>
          {error && <div className="error-message">{error}</div>}
        </Form.Group>

        <button className="jiluil" type="submit">
          Continue
        </button>
      </Form>
    </>
  );
};

export default ChurchInfo;
