import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import { SiQuickbooks, SiMailchimp } from "react-icons/si";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import CustomAlert from "../../CustomAlert/CustomAlert";

const Step7 = ({ formData, handleChange, handleCompletionChange }) => {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get("code");
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser, getCurrentUser } = useAppContext();

  const [mfirstName, setMFirstName] = useState("");
  const [mlastName, setMLastName] = useState("");
  const [mstatus, setMStatus] = useState("");
  const [moffice, setMOffice] = useState("");
  const [memail, setMEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [show, setShow] = useState(false);

  const addMember = async (e) => {
    e.preventDefault();
    const data = {
      firstName: mfirstName,
      lastName: mlastName,
      email: memail,
      status: mstatus,
      office: moffice,
    };

    try {
      const response = await authFetch.post("/members/addnewmembertour", {
        data,
      });

      if (response.status === 200 || response.status === 201) {
        setSuccess("Member added successfully");
        setShow(true);
        setMFirstName("");
        setMLastName("");
        setMEmail("");
        setMStatus("");
        setMOffice("");

        setTimeout(() => {
          setSuccess("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error getting secret:", error);
    }
  };

  return (
    <div className="pt-4 ">
      {" "}
      {/* Center the content */}
      <p className="text-lg text-bold pb-3">Add Member</p>
      <div className="qedqxqx flex justify-center" style={{ width: "100%" }}>
        <div
          style={{
            backgroundColor: "#f7f7f7",
            padding: "2rem",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          <Form>
            {show && (
              <p
                className="flex justify-center align-center"
                style={{ color: "var(--secondary-color)" }}
              >
                {success}
              </p>
            )}

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="hed">
                First Name<span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={mfirstName}
                name="mfirstName"
                //handleChange={handleMemberInput}
                onChange={(e) => setMFirstName(e.target.value)}
                className="formvalue"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="hed">Last Name</Form.Label>
              <Form.Control
                type="text"
                value={mlastName}
                name="mlastName"
                //handleChange={handleMemberInput}
                onChange={(e) => setMLastName(e.target.value)}
                className="formvalue"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="hed">Email Address</Form.Label>
              <Form.Control
                type="email"
                className="formvalue"
                value={memail}
                // handleChange={handleMemberInput}
                onChange={(e) => setMEmail(e.target.value)}
                name="memail"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="hed">
                Status<span className="myast">*</span>
              </Form.Label>
              <Form.Select
                value={mstatus}
                //handleChange={handleMemberInput}
                onChange={(e) => setMStatus(e.target.value)}
                name="mstatus"
                aria-label="Default select example"
                className="formvalue"
                required
              >
                <option>Select an Option</option>

                <option value="Member">Member</option>
                <option value="Visitor">Visitor</option>

                <option value="Inactive">Inactive</option>
                <option value="Membership In Progress">
                  Membership In Progress
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="hed">
                Service Area<span className="myast">*</span>
              </Form.Label>
              <Form.Select
                value={moffice}
                //handleChange={handleMemberInput}
                onChange={(e) => setMOffice(e.target.value)}
                name="moffice"
                aria-label="Default select example"
                className="formvalue"
                required
              >
                <option>Select an Option</option>

                <option value="Leader">Leader</option>
                <option value="Elder">Elder</option>
                <option value="Deacon">Deacon</option>
                <option value="Worker">Worker</option>
                <option value="Minister">Minister</option>
                <option value="Pastor">Pastor</option>
                <option value="Volunteer">Volunteer</option>
              </Form.Select>
            </Form.Group>
            <div className="flex justify-center">
              <button className="jiluil4 mt-3" onClick={addMember}>
                Add Member
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div className="flex justify-end">
        <Form.Check
          type="checkbox"
          id="step7Complete"
          label="Mark as Completed"
          checked={formData.step7Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Step7;
