import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import moment from "moment/moment";
import axios from "axios";

import { Link } from "react-router-dom";

const MyVolunteerApp = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, deleteApplication, logoutUser } = useAppContext();

  useEffect(() => {
    getExpressionInterest();
  }, []);

  const [schedules, setSchedules] = useState();

  const getExpressionInterest = async () => {
    try {
      const { data } = await authFetch.get("/volunteerapp");

      setSchedules(data);
    } catch (error) {
      logoutUser();
    }
  };
  const [clickedApp, setClickedApp] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e, data) => {
    setShow(true);
    setClickedApp(data);
  };

  const handleDelete = () => {
    deleteApplication(clickedApp._id, {
      alertText: "Application Deleted",
    });
    getExpressionInterest();
    handleClose();
  };

  const handleWithdraw = async (data) => {
    const mydata = {
      status: "Withdrawn",
      scheduleId: data.scheduleId,
    };
    try {
      await authFetch.patch(`/volunteerapp/withdraw/${data._id}`, {
        mydata,
      });
    } catch (error) {
      logoutUser();
    }
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">My Volunteer Interests</Col>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <tbody>
              {schedules && schedules.length > 0 ? (
                schedules.map((data, index) =>
                  (<thead>
                    <tr>
                      <th style={{ fontSize: "1em" }}>Application Date</th>
                      <th style={{ fontSize: "1em" }}>Program Title</th>
                      <th style={{ fontSize: "1em" }}>Postion Applied For</th>
                      <th style={{ fontSize: "1em" }}>Status</th>
                      <th style={{ fontSize: "1em" }}>Action</th>
                    </tr>
                  </thead>)(
                    <tr key={data._id || index}>
                      <td style={{ fontSize: "1em" }}>
                        {moment(`${data.createdAt}`).format("LL")}
                      </td>
                      <td style={{ fontSize: "1em" }}>{data.scheduleName}</td>
                      <td style={{ fontSize: "1em" }}>{data.positionName}</td>
                      <td style={{ fontSize: "1em" }}>{data?.status}</td>
                      <td style={{ gap: "10px", display: "flex" }}>
                        <Link to={"/auth/volunteersignup/" + data.scheduleId}>
                          <button className="q3rqww3r231s">View</button>
                        </Link>
                        <button
                          className="q3rq3r23"
                          onClick={() => handleWithdraw(data)}
                          disabled={data?.status === "Withdrawn"}
                        >
                          Withdraw
                        </button>
                        <button
                          className="but-delete"
                          onClick={(e) => handleShow(e, data)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <p className="noData">No data available for display.</p>
              )}
            </tbody>
          </Table>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleted Applications cannot be recovered!</Modal.Body>
            <Modal.Footer>
              <Button className="wewecwe" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="wewecwe1" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default MyVolunteerApp;
