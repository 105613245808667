import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import Select from "react-select";
import axios from "axios";
import debounce from "lodash.debounce";
import "./usermanagement.css";

import { FaClock, FaAngleRight } from "react-icons/fa";

const Automations = () => {
  const quillRef = useRef(null);
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser } = useAppContext();
  const [birthMessage, setBirthMessage] = useState("");

  const modules = {
    toolbar: {
      container: "#toolbar",
      "custom-dropdown": ["option1", "option2", "option3"],

      // Linking to our custom toolbar
    },
  };

  const handleBgColorChange = (selectedOption) => {
    setEditorBgColor(selectedOption.value);
  };
  const handleBgColorChangeWelcome = (selectedOption) => {
    setEditorBgColorWelcome(selectedOption.value);
  };

  const [bgColor, setBgColor] = useState("");
  const [bgColorWelcome, setBgColorWelcome] = useState("");
  const [editorBgColor, setEditorBgColor] = useState("white"); // State for the editor's background color
  const [editorBgColorWelcome, setEditorBgColorWelcome] = useState("white"); // State for the editor's background color

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "purple", label: " Purple" },
    { value: "orange", label: " Orange" },
    { value: "brown", label: " Brown" },
    { value: "black", label: "Black" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightcoral", label: "Light Coral" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
    { value: "lightsalmon", label: "Light Salmon" },
    { value: "lightseagreen", label: "Light Sea Green" },
    { value: "lightcyan", label: "Light Cyan" },

    // Add more colors as needed
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value,
      color: state.data.value === "white" ? "black" : "white",
    }),
    // Add more custom styles if needed
  };

  const handleBirthDayEmailChange = useCallback(
    debounce((value) => {
      setBirthMessage(value);
    }, 500),
    []
  );

  const handleWelcomeEmailChange = useCallback(
    debounce((value) => {
      setWelcomeMessage(value);
    }, 500),
    []
  );

  const [automation, setAutomation] = useState([]);
  const [birthEmailChecked, setBirthEmailChecked] = useState(false);
  const [birthSMSChecked, setBirthSMSChecked] = useState(false);
  const [welcomeEmailChecked, setWelcomeEmailChecked] = useState(false);
  const [welcomeSMSChecked, setWelcomeSMSChecked] = useState(false);
  const [birthsubject, setBirthSubject] = useState("");

  // const [sendBirthData, setSendBirthData] = useState([]);
  const [welcomesubject, setWelcomeSubject] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [smsMessage, setSmsMessage] = useState("");
  const [smsSubject, setSmsSubject] = useState("");
  const [smsWelcome, setSmsWelcome] = useState("");
  const [smsSubjectWelcome, setSmsSubjectWelcome] =
    useState("Welcome To Church!");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  useEffect(() => {
    const fetchAutomation = async () => {
      const response = await authFetch.get("/automation/getautomation");
      setAutomation(response.data.data);
    };

    fetchAutomation();
  }, []);

  useEffect(() => {
    if (automation) {
      setBirthEmailChecked(automation[0]?.birthEmailChecked);
      setBirthSMSChecked(automation[0]?.birthSMSChecked);
      setWelcomeEmailChecked(automation[0]?.welcomeEmailChecked);
      setEditorBgColor(automation[0]?.bgColorBirthday);
      setEditorBgColorWelcome(automation[0]?.bgColorWelcome);
      setWelcomeSMSChecked(automation[0]?.welcomeSMSChecked);
      setBirthSubject(automation[0]?.birthsubject);
      setBirthMessage(automation[0]?.birthMessage);

      setWelcomeSubject(automation[0]?.welcomesubject);
      setWelcomeMessage(automation[0]?.welcomeMessage);
      setSmsMessage(automation[0]?.smsMessage);

      setSmsSubject(automation[0]?.smsSubject);
      setSmsWelcome(automation[0]?.smsWelcome);
      setSmsSubjectWelcome(automation[0]?.smsSubjectWelcome);

      // setChurchNamex(messageCenter[0]?.churchNamex);
    }
  }, [automation]);

  useEffect(() => {
    if (quillRef.current && birthMessage !== null) {
      const quill = quillRef.current.getEditor();
      quill.root.style.backgroundColor = bgColor; // Apply background color directly to Quill's content area

      // Check if the content is different before updating
      if (quill.root.innerHTML !== birthMessage) {
        const range = quill.getSelection(); // Save the current cursor position
        quill.clipboard.dangerouslyPasteHTML(0, birthMessage);

        if (range) {
          // Restore the saved cursor position
          quill.setSelection(range.index, range.length);
        }
      }
    }
  }, [birthMessage, bgColor]);

  useEffect(() => {
    if (quillRef.current && welcomeMessage !== null) {
      const quill = quillRef.current.getEditor();
      quill.root.style.backgroundColor = bgColorWelcome; // Apply background color directly to Quill's content area

      // Check if the content is different before updating
      if (quill.root.innerHTML !== welcomeMessage) {
        const range = quill.getSelection(); // Save the current cursor position
        quill.clipboard.dangerouslyPasteHTML(0, welcomeMessage);

        if (range) {
          // Restore the saved cursor position
          quill.setSelection(range.index, range.length);
        }
      }
    }
  }, [welcomeMessage, bgColorWelcome]);

  const sendBirthdayInfo = async (e) => {
    e.preventDefault();
    setBirthEmailChecked(true);

    try {
      await authFetch.post("/automation/addbirthday", {
        birthMessage,
        birthsubject,
        birthEmailChecked: true,
        birthdayTag: "Birthday",
        bgColorBirthday: editorBgColor,
      });

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const sendWelcomeInfo = async (e) => {
    e.preventDefault();
    setWelcomeEmailChecked(e.target.checked);
    try {
      await authFetch.post("/automation/addwelcome", {
        welcomeMessage,
        welcomesubject,
        welcomeEmailChecked: true,
        welcomeTag: "Welcome",
        bgColorWelcome: editorBgColorWelcome,
      });

      handleClose2();
    } catch (err) {
      console.log(err);
    }
  };

  const sendBirthDaySMS = async (e) => {
    e.preventDefault();
    setBirthSMSChecked(e.target.checked);
    try {
      await authFetch.post("/automation/addbirthdaysms", {
        smsMessage,
        smsSubject,
        birthSMSChecked,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const sendWelcomeSMS = async (e) => {
    e.preventDefault();
    setWelcomeSMSChecked(e.target.checked);
    try {
      await authFetch.post("/automation/addwelcomesms", {
        smsSubjectWelcome,
        smsWelcome,
        welcomeSMSChecked,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col>
            <p className="sahs">Automations</p>
          </Col>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#F1F1F1" }}>
            <thead>
              <tr className="trdata">
                <th>Task</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr
                  className="trdata"
                  onClick={handleShow}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ fontWeight: "600" }}>
                    <Form.Check
                      type="checkbox"
                      label=" Birthday Email"
                      name="followUpChecked"
                      value="defaultFund"
                      // onChange={handleBirthEmailCheckboxChange}
                      checked={birthEmailChecked}
                      // onChange={(e) => setDefaultFund(e.target.checked)}
                    />
                  </td>
                  <td>
                    A customized happy brth message is automatically emailed to
                    people on their birthday.
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#7C7C7C",
                        fontStyle: "italic",
                        marginBottom: "0px",
                      }}
                    >
                      <FaClock /> This task runs daily at 9am, emailing those
                      who have a birthday that day.
                    </p>
                  </td>
                  <td>
                    <FaAngleRight style={{ fontSize: "30px" }} />
                  </td>
                </tr>
                <tr
                  className="trdata"
                  onClick={handleShow1}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ ffontWeight: "600" }}>
                    <Form.Check
                      type="checkbox"
                      label=" Birthday SMS"
                      name="followUpChecked"
                      value="defaultFund"
                      // onChange={handleBirthSMSCheckboxChange}
                      checked={birthSMSChecked}
                      // onChange={(e) => setDefaultFund(e.target.checked)}
                    />
                  </td>
                  <td>
                    A customized happy birthday message is automatically sent
                    via SMS to people on their birthday.
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#7C7C7C",
                        fontStyle: "italic",
                        marginBottom: "0px",
                      }}
                    >
                      <FaClock /> This task runs daily at 9am, emailing those
                      who have a birthday that day.
                    </p>
                  </td>
                  <td>
                    <FaAngleRight style={{ fontSize: "30px" }} />
                  </td>
                </tr>
                <tr
                  className="trdata"
                  onClick={handleShow2}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ fontWeight: "600" }}>
                    <Form.Check
                      type="checkbox"
                      label="Welcome Message (Email)"
                      name="followUpChecked"
                      value="defaultFund"
                      // onChange={handleWelcomeEmailCheckboxChange}
                      checked={welcomeEmailChecked}
                      // onChange={(e) => setDefaultFund(e.target.checked)}
                    />
                  </td>
                  <td>
                    A customized happy brth message is automatically emailed to
                    people on their birthday.
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#7C7C7C",
                        fontStyle: "italic",
                        marginBottom: "0px",
                      }}
                    >
                      <FaClock /> This task runs daily at 9am, emailing those
                      who have a birthday that day.
                    </p>
                  </td>
                  <td>
                    <FaAngleRight style={{ fontSize: "30px" }} />
                  </td>
                </tr>
                <tr
                  className="trdata"
                  onClick={handleShow3}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ fontWeight: "600" }}>
                    <Form.Check
                      type="checkbox"
                      label="Welcome Message (SMS)"
                      name="followUpChecked"
                      value="defaultFund"
                      // onChange={handleWelcomeSMSCheckboxChange}
                      checked={welcomeSMSChecked}
                      // onChange={(e) => setDefaultFund(e.target.checked)}
                    />
                  </td>
                  <td>
                    A customized happy brth message is automatically emailed to
                    people on their birthday.
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#7C7C7C",
                        fontStyle: "italic",
                        marginBottom: "0px",
                      }}
                    >
                      <FaClock /> This task runs daily at 9am, emailing those
                      who have a birthday that day.
                    </p>
                  </td>
                  <td>
                    <FaAngleRight style={{ fontSize: "30px" }} />
                  </td>
                </tr>
              </>
            </tbody>
          </Table>
          <p>
            <span style={{ fontWeight: "600" }}>Note:</span> Accurate email
            address and mobile number are required to receive Emails and SMS.
          </p>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Birthday Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send To</Form.Label>
              <Form.Control placeholder="Birthday Celebrants" disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send From</Form.Label>
              <Form.Control placeholder={user.memail} disabled />
            </Form.Group>
            <div id="toolbar" className="flex items-center">
              <button className="ql-bold">Bold</button>
              <button className="ql-italic">Italic</button>
              <button className="ql-underline">Underline</button>
              <button className="ql-header" value="1">
                Header 1
              </button>
              <button className="ql-header" value="2">
                Header 2
              </button>
              <button className="ql-list" value="ordered">
                Ordered List
              </button>
              <button className="ql-list" value="bullet">
                Bullet List
              </button>
              <button className="ql-align" value=""></button>
              <button className="ql-align" value="center"></button>
              <button className="ql-align" value="right"></button>
              <button className="ql-align" value="justify"></button>
              <select className="ql-color">
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                <option value="white">White</option>
                {/* Add more colors as needed */}
              </select>

              <select className="ql-background" onChange={handleBgColorChange}>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                {/* Add more colors as needed */}
              </select>
              <Select
                options={colorOptions}
                styles={customStyles}
                onChange={handleBgColorChange}
                placeholder="Background"
              />

              <select className="ql-size">
                <option value="small">Small</option>
                <option selected>Normal</option>
                <option value="large">Large</option>
                <option value="huge">Huge</option>
              </select>
              <button className="ql-link">Link</button>
              <button className="ql-image">Image</button>
            </div>
            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={birthsubject}
                onChange={(e) => setBirthSubject(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={birthMessage}
                defaultValue={birthMessage}
                onChange={handleBirthDayEmailChange}
                modules={modules}
                className="react-quill-editor"
                placeholder="Enter a message here..."
                style={{ backgroundColor: editorBgColor, height: "200px" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: "40px" }}>
          <div className="flex items-end justify-end gap-3 flex-row ">
            <button onClick={handleClose} className="jiluil">
              Close
            </button>
            <button onClick={sendBirthdayInfo} className="jiluil3">
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Birthday SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send To</Form.Label>
              <Form.Control placeholder="Birthday Celebrants" disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send From</Form.Label>
              <Form.Control placeholder={user.mmobilePhone} disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={smsSubject}
                onChange={(e) => setSmsSubject(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <ReactQuill
                theme="snow"
                value={smsMessage}
                onChange={setSmsMessage}
                modules={modules}
                style={{ height: "100px" }}
                dangerouslySetInnerHTML={{ __html: smsMessage }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: "40px" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={sendBirthDaySMS}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Welcome Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send To</Form.Label>
              <Form.Control placeholder="New Members" disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send From</Form.Label>
              <Form.Control placeholder={user.memail} disabled />
            </Form.Group>
            <div id="toolbar" className="flex items-center">
              <button className="ql-bold">Bold</button>
              <button className="ql-italic">Italic</button>
              <button className="ql-underline">Underline</button>
              <button className="ql-header" value="1">
                Header 1
              </button>
              <button className="ql-header" value="2">
                Header 2
              </button>
              <button className="ql-list" value="ordered">
                Ordered List
              </button>
              <button className="ql-list" value="bullet">
                Bullet List
              </button>
              <button className="ql-align" value=""></button>
              <button className="ql-align" value="center"></button>
              <button className="ql-align" value="right"></button>
              <button className="ql-align" value="justify"></button>
              <select className="ql-color">
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                <option value="white">White</option>
                {/* Add more colors as needed */}
              </select>

              <select
                className="ql-background"
                onChange={handleBgColorChangeWelcome}
              >
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                {/* Add more colors as needed */}
              </select>
              <Select
                options={colorOptions}
                styles={customStyles}
                onChange={handleBgColorChangeWelcome}
                placeholder="Background"
              />

              <select className="ql-size">
                <option value="small">Small</option>
                <option selected>Normal</option>
                <option value="large">Large</option>
                <option value="huge">Huge</option>
              </select>
              <button className="ql-link">Link</button>
              <button className="ql-image">Image</button>
            </div>
            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={welcomesubject}
                onChange={(e) => setWelcomeSubject(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={welcomeMessage}
                defaultValue={welcomeMessage}
                onChange={handleWelcomeEmailChange}
                modules={modules}
                style={{
                  backgroundColor: editorBgColorWelcome,
                  height: "200px",
                }}
                className="react-quill-editor"
                placeholder="Enter a message here..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: "40px" }}>
          <button className="jiluil" onClick={handleClose2}>
            Close
          </button>
          <button className="jiluil3" onClick={sendWelcomeInfo}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send To</Form.Label>
              <Form.Control placeholder="New Members" disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Send From</Form.Label>
              <Form.Control placeholder={user.mmobilePhone} disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={smsSubjectWelcome}
                onChange={(e) => setSmsSubjectWelcome(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <ReactQuill
                theme="snow"
                value={smsWelcome}
                onChange={setSmsWelcome}
                modules={modules}
                style={{ height: "100px" }}
                dangerouslySetInnerHTML={{ __html: smsWelcome }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: "40px" }}>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={sendWelcomeSMS}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Automations;
