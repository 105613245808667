import { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import { useNavigate, useParams } from "react-router-dom";
import SingleCourseJumbotron from "../../../components/SingleCourseJumbotron/SingleCourseJumbotron";
import PreviewModal from "../../../components/PreviewModal/PreviewModal";
import SingleCourseLessons from "../../../components/SingleCourseLessons/SingleCourseLessons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { loadStripe } from "@stripe/stripe-js";

const SingleCourse = () => {
  const [course, setCourse] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [enrolled, setEnrolled] = useState({});
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { slug } = useParams();

  useEffect(() => {
    if (slug) fetchCourse();
  }, [slug]);

  const fetchCourse = async () => {
    let { data } = await authFetch.get(`/learning/course/${slug}`);
    if (data) setCourse(data);
  };

  useEffect(() => {
    // is already enrolled?
    if (user && course) checkEnrollment();
  }, []);

  const checkEnrollment = async () => {
    if (course && course._id) {
      let { data } = await authFetch.get(
        `/learning/check-enrollment/${course?._id}`
      );

      setEnrolled(data);
    }
  };

  const handleFreeEnrollment = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if user exists, if not redirect to login
    if (!user) {
      setLoading(false);
      return navigate("/login");
    }

    // Check if user is already enrolled, if so redirect to course page
    if (enrolled && enrolled?.status) {
      setLoading(false);
      return navigate(`/auth/singlecourseview/${enrolled?.course?.slug}`);
    }

    // Construct the data object
    const myData = {
      mId: user._id,
      courseId: course?._id,
      Type: "Enrolled",
      Name: `${user.mfirstName} ${user.mlastName}`,
      churchName: user.churchName,
      lessonsLength: course?.lessons?.length || 0,
      courseName: course?.name,
    };

    try {
      const { data } = await authFetch.post(
        `/learning/free-enrollment/${course?._id}`,
        { myData }
      );

      return navigate(`/auth/singlecourseview/course/${data?.course?.slug}`);
    } catch (err) {
      console.error("Error during enrollment:", err); // Added for better error insight
      setLoading(false);
    }
  };

  const handlePaidEnrollment = async (e) => {
    // console.log("handle paid enrollment");
    e.preventDefault();

    try {
      setLoading(true);
      // check if user is logged in
      if (!user) {
        setLoading(false);
        return navigate("/login");
      }
      // check if already enrolled
      if (enrolled && enrolled?.status) {
        setLoading(false);
        return navigate(`/auth/singlecourseview/${enrolled?.course?.slug}`);
      }

      const { data } = await authFetch.post(
        `/learning/paid-enrollment/${course._id}`
      );
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      stripe.redirectToCheckout({ sessionId: data });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid>
        <SingleCourseJumbotron
          course={course}
          showModal={showModal}
          setShowModal={setShowModal}
          preview={preview}
          setPreview={setPreview}
          loading={loading}
          user={user}
          handleFreeEnrollment={handleFreeEnrollment}
          handlePaidEnrollment={handlePaidEnrollment}
          enrolled={enrolled}
          setEnrolled={setEnrolled}
        />

        <Row>
          <PreviewModal
            showModal={showModal}
            setShowModal={setShowModal}
            preview={preview}
            setPreview={setPreview}
            course={course}
          />
          {course.lessons && (
            <SingleCourseLessons
              lessons={course.lessons}
              setPreview={setPreview}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default SingleCourse;
