import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { Modal } from "react-bootstrap";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ show, handleClose, selectedDocument, handleAudio }) => {
  const [numPages, setNumPages] = useState(null);
  const [uniqueDocument, setUniqueDocument] = useState(null);
  const [fullscreen, setFullscreen] = useState(true);

  useEffect(() => {
    if (selectedDocument) {
      // Assuming `selectedDocument` has a property `Location` which is the file path
      setUniqueDocument(selectedDocument.documentFile);
    }
  }, [selectedDocument]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
      <Modal.Header closeButton>
        <Modal.Title>View Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex justify-center">
        {uniqueDocument && (
          <Document
            file={uniqueDocument.Location}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PdfViewer;
