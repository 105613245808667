import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./giving.css";

import { useReactToPrint } from "react-to-print";
import PrintStatement from "./PrintStatement";

const GivingHome = () => {
  const navigate = useNavigate();
  const [statementType1, setStatementType1] = useState("");

  const {
    isLoading,
    handleChange,
    getReport,
    logoutUser,
    showAlert,
    statementStartDate,
    statementEndDate,
    statementType,
    statementUser,
    specificUserName,
    reports,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });

    if (e.target.value) {
      setStatementType1(e.target.value);
    }
  };

  useEffect(() => {
    getReport();
  }, [
    statementStartDate,
    statementEndDate,
    statementType,
    statementUser,
    specificUserName,
  ]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    removeAfterPrint: true,
    bodyClass: "printbody",
    copyStyles: true,
  });

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="courseheader">
          <p className="sahs">Generate Statements</p>
        </Row>
        <Row
          className="sssssssecwef"
          style={{ paddingBottom: "3rem", fontSize: "1em" }}
        >
          <div className="leftgivediv">
            <form className="registerform">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="labfont" style={{ fontSize: "1em" }}>
                  Who is the statement for?
                </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  value={statementUser}
                  onChange={handleSearch}
                  name="statementUser"
                  style={{ fontSize: "1em" }}
                >
                  <option value="all" style={{ fontSize: "1em" }}>
                    All Contributors
                  </option>

                  <option value="forEmail" style={{ fontSize: "1em" }}>
                    Specific Contributors
                  </option>
                </Form.Select>
              </Form.Group>
              {statementUser === "forEmail" && (
                <Form.Group
                  className="mb-3"
                  style={{ fontSize: "1em" }}
                  controlId="formBasicEmail"
                >
                  <Form.Label className="labfont" style={{ fontSize: "1em" }}>
                    Enter Member Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={specificUserName}
                    onChange={handleSearch}
                    name="specificUserName"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              )}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="labfont" style={{ fontSize: "1em" }}>
                  Statement State Date
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Amount"
                  value={statementStartDate}
                  onChange={handleSearch}
                  name="statementStartDate"
                  style={{ fontSize: "1em" }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="labfont" style={{ fontSize: "1em" }}>
                  Statement End Date
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Amount"
                  value={statementEndDate}
                  onChange={handleSearch}
                  name="statementEndDate"
                  style={{ fontSize: "1em" }}
                />
              </Form.Group>

              <div style={{ display: "none" }}>
                <PrintStatement ref={componentRef} reports={reports} />
              </div>
              <Button
                className="wedweded"
                style={{ fontSize: "1em" }}
                onClick={handlePrint}
              >
                Print Statement
              </Button>
            </form>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default GivingHome;
