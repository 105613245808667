import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import "./messaging.css";

import moment from "moment";
const Inbox = () => {
  const { logoutUser, user, sendBulkSMS } = useAppContext();
  const textareaRef = useRef(null);
  const bottomRef = useRef(null);
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [sentSms, setSentSms] = useState([]);
  const maxChars = 2500;
  const [newMessage, setNewMessage] = useState("");

  const insertAtCursorPosition = (content) => {
    // Check if content and smsMessageRef.current are defined
    if (!content || !textareaRef.current) {
      console.error("Content or newMessageRef is undefined");
      return;
    }

    const cursorPosition = textareaRef.current.selectionStart;
    const textBeforeCursor = newMessage
      ? newMessage.slice(0, cursorPosition)
      : "";
    const textAfterCursor = newMessage ? newMessage.slice(cursorPosition) : "";
    const newText = textBeforeCursor + content + textAfterCursor;

    if (newText.length <= maxChars) {
      setNewMessage(newText);
      setTimeout(() => {
        // Check if smsMessageRef.current is still defined
        if (textareaRef.current) {
          textareaRef.current.selectionStart =
            textareaRef.current.selectionEnd = cursorPosition + content.length;
        }
      }, 0);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleVariableInsert = (event) => {
    const variable = event.target.value;
    if (variable === "Select Variables") return; // Ignore default select option
    insertAtCursorPosition(variable);
    event.target.value = "Select Variables"; // Reset dropdown
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleFetchSms = async () => {
    try {
      const response = await authFetch.get("/sms/byid");

      const allMessages = response.data.messages;

      // Filter messages to include only those with 'inbound' direction
      const inboundMessages = allMessages.filter(
        (msg) => msg.direction === "inbound"
      );
      setSentSms(inboundMessages);
    } catch (err) {
      console.error("Error fetching SMS:", err);
    }
  };

  useEffect(() => {
    handleFetchSms();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const [messages, setMessages] = useState([]);

  const showMessage = async (e, saved) => {
    e.preventDefault();

    try {
      const response = await authFetch.get(`/sms/inbound/${saved.from}}`);
      const data = response.data; // Directly use response.data

      const groupedMessages = groupMessagesByDate(data.messages);

      setMessages(groupedMessages); // Make sure to set the grouped messages
    } catch (err) {
      console.error("Error fetching SMS:", err);
    }
    setShow(true);

    //setClickedMember(saved);
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = moment(message.dateSent).format("YYYY-MM-DD");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  // Function to get initials from a name
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...sentSms].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setSentSms(sorted);
    } else {
      let sorted = [...sentSms].reverse();
      setSentSms(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleDateSort = (e) => {
    let sorted = [...sentSms].sort((a, b) => {
      if (dateFlag) {
        return moment(b.dateSent).unix() - moment(a.dateSent).unix();
      } else {
        return moment(a.dateSent).unix() - moment(b.dateSent).unix();
      }
    });
    setSentSms(sorted);
    setDateFlag(!dateFlag);
  };

  const handleSortStatus = (e) => {
    let sorted = [...sentSms].sort((a, b) => {
      if (statusFlag) {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });
    setSentSms(sorted);
    setStatusFlag(!statusFlag);
  };
  const handleSubmitMessage = async (e) => {
    e.preventDefault();

    const smsData = {
      recipients: [messages[Object.keys(messages)[0]][0].to],

      textmessage: newMessage,
    };

    sendBulkSMS({ smsData });
    setNewMessage("");
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">Inbox</p>
      </Row>
      {sentSms && sentSms.length > 0 ? (
        <Row className="tabw">
          <p
            style={{
              fontWeight: "700",
              fontSize: "100%",
              color: "var(--primary-color)",
              paddingTop: "3rem",
            }}
          >
            {sentSms.length ? (
              <p>
                {sentSms.length} record{sentSms.length > 1 ? "s" : ""} found
              </p>
            ) : (
              <p>No Saved View</p>
            )}
          </p>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr className="trdataheader">
                <th onClick={handleDateSort}>
                  Date Sent {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleViewName}>
                  From {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th>Phone Number</th>
                <th>Delivery Time</th>
                <th onClick={handleSortStatus}>
                  {" "}
                  Status {statusFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th></th>
              </tr>
            </thead>
            {sentSms.map((saved, index) => (
              <tbody>
                <tr key={index} className="trdata">
                  <td>{moment(`${saved.dateSent}`).format("LL")}</td>
                  <td>{saved.memberName}</td>

                  <td>{saved.from}</td>
                  <td>{moment(`${saved.dateSent}`).format("LTS")}</td>
                  <td>{saved.status}</td>

                  <td className="rightco">
                    <button
                      className="jiluil3"
                      onClick={(e) => showMessage(e, saved)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Row>
      ) : (
        <div className="noDatax">
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {Object.keys(messages).length > 0 &&
              Object.keys(messages)
                .sort()
                .map((date) => (
                  <div key={date}>
                    <p className="message-ke my-3">
                      <span className="awqw">{moment(date).format("LL")}</span>
                    </p>
                    {messages[date].map((msg, index) => (
                      <div
                        key={index}
                        className={`messagess ${
                          msg.from === user.primaryPhoneNumber ? "from" : "to"
                        }`}
                      >
                        <div className="zzzww">
                          <span className="member-initials">
                            {getInitials(msg.memberName || "Unknown")}
                          </span>
                          <span className="message-from">
                            {msg.memberName || "Unknown"}
                          </span>
                        </div>

                        <div className="message-body">
                          <p className="flex gap-3 items-center mt-2">
                            {msg.status && (
                              <FaCheckCircle style={{ color: "green" }} />
                            )}
                            {msg.body}
                          </p>
                          <span className="message-date">
                            {moment(msg.dateSent).format("LT")}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
          </div>
          <div className="message-input mb-5">
            <div className="character-count flex items-center justify-end">
              {`${newMessage.length} / ${maxChars}`} characters. For better
              delivery, use a maximum of 2500 characters.
            </div>
            <textarea
              ref={textareaRef}
              rows={4}
              value={newMessage}
              onChange={handleInputChange}
              placeholder="Type your message here..."
              className="send-message my-3 px-3 py-2"
            />
            <div className="flex justify-between">
              <select
                aria-label="Default select example"
                onChange={handleVariableInsert}
                style={{ width: "30%" }}
              >
                <option>Select Variables</option>
                <option value="[FirstName]">First Name</option>
                <option value="[LastName]">Last Name</option>
                <option value="[Email]">Email</option>
                <option value="[PhoneNumber]">Phone Number</option>
              </select>
            </div>
          </div>
          <div ref={bottomRef} />
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={handleSubmitMessage}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Inbox;
