import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { useAppContext } from "../../../../context/appContext";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loading from "../../../../components/Loading/Loading";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { currencyFormatter } from "../../../../utils/helpers";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#ffffff",
      fontFamily: '"popins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1em",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "40px", // Adjust line height to increase the element's height
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid red", // Add a border to the element
      borderRadius: "4px", // Optional: if you want rounded corners
      padding: "0.375rem 0.75rem",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StandardOneTimeGiving = ({
  clientSecret,
  secretKey,
  amount,
  currency,
  churchName,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [clientSecretx, setClientSecretx] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [amountx, setAmount] = useState(null);
  const [currencyx, setCurrency] = useState(null);
  const [churchNamex, setChurchName] = useState(null);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();

  useEffect(() => {
    if (clientSecret && amount && currency) {
      setClientSecretx(clientSecret);
      setAmount(amount);
      setCurrency(currency);
      setChurchName(churchName);
    }
  }, [clientSecret]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      //console.log("Stripe.js has not loaded yet.");
      return;
    }
    //console.log("clientSecret: ", clientSecretx);
    const result = await stripe.confirmCardPayment(clientSecretx, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    //console.log("result: ", result);
    if (result.error) {
      // console.log(result.error.message);
      setMessage(result.error.message);
      // Handle errors here (e.g., show error message to the user)
    } else {
      setIsLoading(true);
      if (result.paymentIntent.status === "succeeded") {
        window.location.href = `/auth/standardsuccess`;
        //console.log("Payment succeeded!");
        // console.log("result.paymentIntent.id", result.paymentIntent.id);
        // Use axios to send the PaymentIntent ID to your server
        const paymentIntentId = result.paymentIntent.id;
        try {
          await authFetch.post("/payment/payment-confirmation", {
            paymentIntentId,
            secretKey,
            churchNamex,
          });

          // Navigate to a success page or update UI accordingly
        } catch (error) {
          console.error("Error confirming payment on server:", error);
          // Optionally, handle the error in a user-friendly way
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="flex justify-center rre332">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>

        <div className="flex justify-center">
          <button
            disabled={isLoading || !stripe || !elements}
            type="submit"
            className="jiluil inline-block px-5 py-2" // Added padding and set inline-block
            style={{ width: "100%" }} // Ensures the button takes full width
          >
            <span className="flex flex-row items-center justify-center gap-3">
              {isLoading ? (
                <>
                  <Loading /> Loading...
                </>
              ) : (
                <span id="button-text">
                  Pay {currencyx}{" "}
                  {currencyFormatter({
                    amount: amountx, // Assuming 'amount' is directly under 'paymentDetails'
                    currency: currencyx, // Assuming 'currency' is directly under 'paymentDetails'
                  })}
                </span>
              )}
            </span>
          </button>

          {message && (
            <CustomAlert
              alertType="danger"
              alerttext={message && <div id="payment-message">{message}</div>}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default StandardOneTimeGiving;
