import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Resizer from "react-image-file-resizer";
import { Container, Col } from "react-bootstrap";
import he from "he";

const BlogEdit = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const { id } = useParams();

  useEffect(() => {
    getBlog();
  }, []);

  const [blog, setBlog] = useState({});
  const getBlog = async () => {
    try {
      const response = await authFetch.get(`/blog/${id}`);

      if (response && response.data) {
        setBlog(response.data);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [category, setCategory] = useState("uncategorized");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [homeIntro, setHomeIntro] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  useEffect(() => {
    if (blog) {
      setBlogTitle(blog.blogTitle);
      const decodedDescription = he.decode(blog.blogDescription || "");
      setBlogDescription(decodedDescription);
      setCategory(blog.category);
      setImage(blog.image);
      setPreview(blog?.image?.Location);
      setHomeIntro(blog.homeIntro);
    }
  }, [blog]);

  const handleChange = (e) => {
    //setValues({ [e.target.name]: e.target.value });
  };

  const handleQuillChange = (content) => {
    setBlogDescription(content);
  };
  const maxSizeInBytes = 1024 * 1024; // 1 MB

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);

    // resize
    Resizer.imageFileResizer(file, 700, 500, "JPEG", 100, 0, async (uri) => {
      try {
        // Assuming maxSizeInBytes is defined somewhere in your code
        const currentSizeInBytes = uri.length;

        // If the image size exceeds the maximum size, calculate a new quality value to meet the size constraint
        if (currentSizeInBytes > maxSizeInBytes) {
          const newQuality = Math.floor(
            (maxSizeInBytes / currentSizeInBytes) * 100
          );

          // Resize the image again with the new quality value
          Resizer.imageFileResizer(
            file,
            700,
            500,
            "JPEG",
            newQuality,
            0,
            async (newUri) => {
              try {
                let { data } = await authFetch.put("/blog/update-image", {
                  image: newUri,
                });
                console.log("IMAGE UPLOADED", data);
                setImage(data);
              } catch (err) {
                console.log(err);
              }
            }
          );
        } else {
          let { data } = await authFetch.put("/blog/update-image", {
            image: uri,
          });
          console.log("IMAGE UPLOADED", data);
          setImage(data);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      // Assuming 'id' and 'image' are already defined in your function's scope
      const response = await authFetch.post(
        `/blog/remove-image/${id}`,
        { image: image } // Ensure the key name is 'image', and it's correctly formatted
      );

      if (response.ok) {
        setImage({});
        setPreview("");
        setUploadButtonText("Upload Image");
      } else {
        throw new Error("Failed to remove the image");
      }
    } catch (err) {
      console.log("Error removing image:", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      blogTitle,
      blogDescription,
      image,
      category,
      homeIntro,
    };

    try {
      const response = await authFetch.put(`/blog/edit/${id}`, { data });
      if (response && response.data) {
        setLoading(false);
        setEditPage(true);
      }
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  return (
    <Container fluid>
      <Row className="courseform">
        <Form onSubmit={handleSubmit} className="formercoursexx">
          {/* Post Title */}
          <Form.Group className="mb-3" controlId="blogTitle">
            <Form.Label className="corelabel">Post Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Post Title"
              name="blogTitle"
              onChange={(e) => setBlogTitle(e.target.value)}
              value={blogTitle}
              className="lpm"
            />
          </Form.Group>

          {/* Post Category */}
          <Form.Group className="mb-3" controlId="postCategory">
            <Form.Label className="corelabel">Post Category</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
              value={category}
            >
              <option value="uncategorized">Select a category</option>
              <option value="general">General Topics</option>
              <option value="guides">How To Guides</option>
              <option value="learning">Learning and Developments</option>
              <option value="NewReleases">New Releases</option>
              <option value="Announcements">Announcements</option>
              <option value="spiritual growth">Spiritual Growth</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="blogTitle">
            <Form.Label className="corelabel">Post Intro (max 400)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Post Title"
              name="homeintro"
              onChange={(e) => setHomeIntro(e.target.value)}
              value={homeIntro}
              className="lpm"
            />
            <p>{homeIntro ? homeIntro.length : 0}/400</p>
          </Form.Group>

          {/* Post Details */}
          <Form.Group className="mb-3" controlId="blogDescription">
            <Form.Label className="corelabel">
              Post Details (use markup)
            </Form.Label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              className="lpmw"
              style={{ height: "300px" }}
              onChange={handleQuillChange}
              value={blogDescription} // This binds the ReactQuill editor to the blogDescription state
            />
          </Form.Group>

          {/* Image Upload */}
          <Form.Group controlId="formFile" className="mb-3 mt-5">
            {loading ? (
              <p className="corelabel">Uploading</p>
            ) : (
              <p className="corelabel">Image Upload</p>
            )}
            <Form.Control
              type="file"
              name="image"
              onChange={handleImage}
              accept="image/*"
              className="lpm"
            />
            <p style={{ fontSize: "70%", color: "#FE7064" }}>
              For the best quality, image dimension should be 1208 by 698.
            </p>
          </Form.Group>

          {/* Image Preview and Removal */}
          {preview && (
            <>
              <p className="corelabel">Image Preview</p>
              <Image src={preview} style={{ width: "600px", height: "auto" }} />
            </>
          )}

          <button onClick={handleImageRemove} className="wedweded">
            Remove Image
          </button>

          {/* Submit Button */}
          <button onClick={handleSubmit} className="wedweded">
            Save
          </button>
        </Form>
      </Row>
    </Container>
  );
};

export default BlogEdit;
