import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/appContext";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import Card from "react-bootstrap/Card";
import { Container, Row } from "react-bootstrap";
import "./learning.css";
import Loading from "../../../components/Loading/Loading";

const ViewAllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [show, setShow] = useState(false);
  const { logoutUser, isLoading } = useAppContext();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const navigate = useNavigate();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (e, course) => {
    e.stopPropagation();
    setSelectedValue(course._id);
    setShow1(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (e, coursess) => {
    e.stopPropagation();
    setSelectedValue(coursess._id);
    setShow(true);
  };
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, coursess) => {
    e.stopPropagation();
    setSelectedValue(coursess._id);
    setShow2(true);
  };
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/course`);
    setCourses(data);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  const handleDelete = async () => {
    try {
      await authFetch.delete(`/learning/course/${selectedValue}`);
      toast(
        <CustomToastContent
          title="Success"
          message="Delete Successful"
          type="success"
        />
      );
      loadCourse();
    } catch (err) {
      console.log(err);
    }
  };

  const handlePublish = async () => {
    try {
      await authFetch.put(`/learning/course/publish/${selectedValue}`);
      loadCourse();
      toast(
        <CustomToastContent
          title="Success"
          message="Course Published"
          type="success"
        />
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      await authFetch.put(`/learning/course/unpublish/${selectedValue}`);
      toast(
        <CustomToastContent
          title="Success"
          message="Course Unpublished"
          type="success"
        />
      );

      loadCourse();
    } catch (err) {
      console.log(err);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openCourse = (course) => {
    navigate(`/auth/courseview/${course.slug}`);
  };

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <>
      <Container fluid className="courseViewAllCourses">
        {courses && courses.length > 0 ? (
          <>
            <Row className="flex justify-start">
              <p className="coursetext">Unpublished Courses</p>
              {courses &&
                courses.map((course) => (
                  <Card
                    className="mian"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => openCourse(course)}
                  >
                    <div className="pklkm">
                      <a>
                        <img
                          src={
                            course.image ? course.image.Location : "/course.png"
                          }
                          style={{
                            backgroundColor: "#f7f7f7",
                          }}
                        />
                      </a>
                    </div>
                    <Card.Body>
                      <a>
                        <Card.Title className="courseTitle">
                          {course.name.length > 50
                            ? `${course.name.substring(0, 50)}...`
                            : course.name}
                        </Card.Title>
                      </a>

                      <Card.Text>
                        <p style={{ fontSize: "1em", paddingBottom: "1px" }}>
                          {course.lessons.length} Lessons
                        </p>
                      </Card.Text>
                      {course.lessons.length < 5 ? (
                        <>
                          <>
                            <p className="courseSuccess">
                              At least 5 lessons are required to publish.
                            </p>
                            <button
                              className="jiluil mt-3"
                              onClick={(e) => handleShow2(e, course)}
                            >
                              Delete
                            </button>
                          </>
                        </>
                      ) : course.published ? (
                        <>
                          <p className="courseSuccess">
                            Your course is live in the digital library
                          </p>
                          <div className="flex gap-3">
                            <button
                              className="jiluil3 mt-3"
                              onClick={(e) => handleShow(e, course)}
                            >
                              Unpublish
                            </button>
                            <button
                              className="jiluil mt-3"
                              onClick={(e) => handleShow2(e, course)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="courseReady">
                            Your course is ready to be published
                          </p>
                          <div className="flex gap-3">
                            <button
                              className="jiluil3 mt-3"
                              onClick={(e) => handleShow1(e, course)}
                            >
                              Publish
                            </button>
                            <button
                              className="jiluil mt-3"
                              onClick={(e) => handleShow2(e, course)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                ))}
            </Row>
          </>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Publish Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          You are about to publish this course. Are you sure you want to
          proceed?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose1} className="jiluil">
            Cancel
          </button>
          <button
            onClick={(e) => {
              handlePublish(e, selectedValue);
              handleClose1();
            }}
            className="jiluil3"
          >
            Publish
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Unpublish Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          You are about to unpublish this course. Are you sure you want to
          proceed?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="jiluil">
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleUnpublish(e, selectedValue);
              handleClose();
            }}
            className="jiluil3"
          >
            Unpublish
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Delete Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          This action cannot be reversed.
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose2} className="jiluil">
            Close
          </button>
          <button
            onClick={(e) => {
              handleDelete(e);
              handleClose2();
            }}
            className="jiluil3"
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAllCourses;
