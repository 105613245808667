import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./peopleheader.css";
import PeopleContact from "../People/PeopleContact";
import Alert from "../Alert/Alert";
import PageBtn from "../PageButton/PageBtn-old";
import { Avatar } from "antd";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { SiMailchimp } from "react-icons/si";

const PeopleContainer = () => {
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [isChecked, setIsChecked] = useState([]);
  const [show, setShow] = useState(false);
  const [clickedMember, setClickedMember] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const handleNotification = (loading) => {
    setShowNotification(loading);
  };

  const handleClose = () => setShow(false);

  const handleShow = (event, members) => {
    setShow(true);
    setClickedMember(members);
  };

  const deleteThisMember = () => {
    deleteMember(clickedMember._id, clickedMember.familyId, {
      alertText: "Delete Successful",
    });
    setShow(false);
    handleClose();
  };

  const {
    getAllMembers,
    logoutUser,
    members, // Only paginated members are required now
    isLoading,
    page,
    user,
    numOfPages,
    deleteMember,
    showAlert,
    sort,
    search,
    searchAge,
    searchGender,
    searchBirthMonth,
    searchAnniversaryMonth,
    searchAccountStatus,
    searchStatus,
    searchCampus,
    searchMarital,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    totalMembers,
  } = useAppContext();

  const [enrollStatus, setEnrollStatus] = useState(false);

  // useEffect(() => {
  //   getMailChimpStatus();
  // }, []);

  // useEffect(() => {
  //   getMailChimpStatus();
  //   if (user.mailchimpAccessToken?.length > 0) {
  //     setEnrollStatus("true");
  //   }
  // }, []);

  const handleChange = (e, member) => {
    const { checked } = e.target;
    if (checked) {
      setIsChecked((pre) => [...pre, member]);
    } else {
      setIsChecked((pre) => {
        return [...pre.filter((id) => id.memail !== member.memail)];
      });
    }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  console.log(members.length);
  const handleSelectAll = (e) => {
    const { checked } = e.target;

    if (checked) {
      setIsChecked(members.map((member) => member)); // Select only members on the current page
      setIsCheckAll(true);
    } else {
      setIsChecked([]); // Deselect all members on the current page
      setIsCheckAll(false);
    }
  };

  // const [toggles, setToggles] = useState({});

  // useEffect(() => {
  //   let initialToggles = {};
  //   if (members.length > 0 && members[0].dynamicFields) {
  //     members[0].dynamicFields.forEach((dynamicField) => {
  //       dynamicField.fields.forEach((field) => {
  //         initialToggles[field.title] = true; // Initialize all as true, or false if you prefer them to be hidden initially
  //       });
  //     });
  //   }
  //   setToggles(initialToggles);
  // }, [members]);

  // const toggleFieldVisibility = (title) => {
  //   setToggles((prev) => ({
  //     ...prev,
  //     [title]: !prev[title],
  //   }));
  // };

  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const closeDropdown = () => setIsOpen(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const [fnamex, setFnamex] = useState(true);
  const showFname = () => {
    setFnamex(!fnamex);
  };

  const [photox, setPhotox] = useState(true);
  const showPhoto = () => {
    setPhotox(!photox);
  };

  const [lnamex, setLnamex] = useState(true);
  const showLname = () => {
    setLnamex(!lnamex);
  };

  const [accountStatusx, setAccountStatusx] = useState(true);
  const showAccountStatus = () => {
    setAccountStatusx(!accountStatusx);
  };
  const [emailx, setEmailx] = useState(true);
  const showEmail = () => {
    setEmailx(!emailx);
  };

  const [mobilex, setmobilePhonex] = useState(true);
  const showMobile = () => {
    setmobilePhonex(!mobilex);
  };

  const [genderx, setGenderx] = useState(true);
  const showGender = () => {
    setGenderx(!genderx);
  };

  const [preferredNamex, setPreferredNamex] = useState(true);
  const showPreferredName = () => {
    setPreferredNamex(!preferredNamex);
  };

  const [campusx, setCampusx] = useState(false);
  const showCampus = () => {
    setCampusx(!campusx);
  };

  const [statusx, setStatusx] = useState(false);
  const showStatus = () => {
    setStatusx(!statusx);
  };

  const [maritalx, setMaritalx] = useState(false);
  const showMartial = () => {
    setMaritalx(!maritalx);
  };

  const [agex, setAgex] = useState(false);
  const showAge = () => {
    setAgex(!agex);
  };

  const [familyx, setFamilyx] = useState(false);
  const showFamily = () => {
    setFamilyx(!familyx);
  };
  const [gradex, setGradex] = useState(false);
  const showGrade = () => {
    setGradex(!gradex);
  };
  const [schoolx, setSchoolx] = useState(false);
  const showSchool = () => {
    setSchoolx(!schoolx);
  };

  const [employerx, setEmployerx] = useState(false);
  const showEmployer = () => {
    setEmployerx(!employerx);
  };

  const [workPhonex, setworkPhonex] = useState(false);
  const showWork = () => {
    setworkPhonex(!workPhonex);
  };

  const [postalx, setPostalx] = useState(false);
  const showPostal = () => {
    setPostalx(!postalx);
  };

  const [statex, setStatex] = useState(false);
  const showState = () => {
    setStatex(!statex);
  };

  const [homephonex, sethomePhonex] = useState(false);
  const showHomePhone = () => {
    sethomePhonex(!homephonex);
  };

  const [cityx, setCityx] = useState(false);
  const showCity = () => {
    setCityx(!cityx);
  };

  const [familyRolex, setFamilyRolex] = useState(false);
  const showFamilyRolex = () => {
    setFamilyRolex(!familyRolex);
  };

  useEffect(() => {
    fetchVisibility();
  }, []);

  const fetchVisibility = async () => {
    try {
      const { data } = await authFetch(`/members/visibility/${user._id}`);
      if (data) {
        setFnamex(data.mfirstName);
        setPhotox(data.mProfilePicture);
        setLnamex(data.mlastName);
        setAccountStatusx(data.accountStatus);
        setPreferredNamex(data.preferredName);
        setEmailx(data.memail);
        setmobilePhonex(data.mmobilePhone);
        setGenderx(data.mgender);
        setCampusx(data.campus);
        setStatusx(data.mstatus);
        setMaritalx(data.mmarital);
        setAgex(data.mage);
        setFamilyx(data.family);
        setGradex(data.mgrade);
        setSchoolx(data.mschool);
        setEmployerx(data.memployer);
        setworkPhonex(data.mworkPhone);
        setPostalx(data.mpostal);
        setStatex(data.mstate);
        sethomePhonex(data.mhomePhone);
        setCityx(data.mcity);
        setFamilyRolex(data.familyRole);
      }
    } catch (error) {
      console.error("Error fetching visibility:", error);
    }
  };

  const [initialState, setInitialState] = useState({});
  const [visibleUpdate, setVisibleUpdate] = useState("");

  const saveChanges = async () => {
    const currentSettings = {
      mfirstName: fnamex,
      mProfilePicture: photox,
      preferredName: preferredNamex,
      mlastName: lnamex,
      memail: emailx,
      mmobilePhone: mobilex,
      mgender: genderx,
      campus: campusx,
      mstatus: statusx,
      accountStatus: accountStatusx,
      mmarital: maritalx,
      mage: agex,
      family: familyx,
      mgrade: gradex,
      mschool: schoolx,
      memployer: employerx,
      mworkPhone: workPhonex,
      mpostal: postalx,
      mstate: statex,
      mhomePhone: homephonex,
      mcity: cityx,
      familyRole: familyRolex,
    };

    const changes = {};
    Object.keys(currentSettings).forEach((key) => {
      if (currentSettings[key] !== initialState[key]) {
        changes[key] = currentSettings[key];
      }
    });

    if (Object.keys(changes).length > 0) {
      try {
        const response = await authFetch.patch(
          `/members/visibility/${user._id}`,
          changes
        );
        setVisibleUpdate("View Updated Successfully");

        const data = await response?.data;
        if (!response.ok)
          throw new Error(
            data.message || "Failed to update visibility settings"
          );

        setInitialState(currentSettings);
        fetchVisibility();
      } catch (error) {
        console.error("Error updating visibility settings:", error);
      }
    }
  };

  useEffect(() => {
    getAllMembers();
  }, [
    page, // Pagination
    rowsPerPage, // Items per page
    search, // General search term
    searchAge, // Filter by age
    searchGender, // Filter by gender
    searchMarital, // Filter by marital status
    searchStatus, // Filter by member status
    searchCampus, // Filter by campus
    searchBirthMonth, // Filter by birth month
    searchAnniversaryMonth, // Filter by anniversary month
    sort, // Sorting order
  ]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = Number(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Optionally
  };

  const renderRangeDescription = () => {
    const start = (page - 1) * rowsPerPage + 1;
    const end = Math.min(page * rowsPerPage, totalMembers);
    return `${start}-${end} of ${totalMembers} entries`;
  };

  function toSentenceCase(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const syncMembersToMailchimp = async () => {
    try {
      const response = await authFetch.patch("/mailchimp/sync-members");

      if (response?.data?.message === "Sync Successful") {
        setEnrollStatus(true);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const [syncLogData, setSyncLogData] = useState(null);

  useEffect(() => {
    fetchSyncLog();
  }, []);

  const fetchSyncLog = async () => {
    try {
      const response = await authFetch("/mailchimp/sync-log");
      const data = await response?.data;
      setSyncLogData(data);
    } catch (error) {
      console.error("Error fetching sync log:", error);
    }
  };

  const handleRowClick = (e, memberId) => {
    e.stopPropagation();
    navigate(`/auth/peopleview/${memberId}`);
  };

  return (
    <>
      <Container fluid className="neww">
        <Row style={{ width: "77%", paddingBottom: "25px" }}>
          {visibleUpdate && <p className="inlinesuccess">{visibleUpdate}</p>}
          {showAlert && <Alert />}
          <Col>
            {isLoading ? (
              <div
                style={{
                  color: "var(--secondary-color)", // Change the color to a noticeable one
                  fontSize: "1em",
                  fontWeight: "700",
                }}
              >
                Retrieving member records....
              </div>
            ) : members.length === 0 ? (
              <div
                style={{
                  color: "red", // Highlight with red to emphasize no results found
                  fontSize: "1em",
                  fontWeight: "700",
                }}
              >
                No user found
              </div>
            ) : (
              <p
                className="myv"
                style={{ paddingLeft: "20px", fontSize: "1em" }}
              >
                {members.length} member{members.length !== 1 && "s"} found
              </p>
            )}
          </Col>

          <Col className="checknm">
            {enrollStatus && enrollStatus === "true" && (
              <div>
                <button className="eomoop" onClick={syncMembersToMailchimp}>
                  Sync with MailChimp
                </button>
                <p style={{ fontSize: "83.5%", fontStyle: "italic" }}>
                  {syncLogData?.countOfSyncedItems} members synced on{" "}
                  {moment(syncLogData?.lastSynced).format("MMMM D, YYYY")}
                </p>
              </div>
            )}

            <DropdownButton
              id="dropdown-item-button"
              title="View Filter"
              show={isOpen}
              onToggle={toggleDropdown}
            >
              <div className=" flex justify-center">
                <button
                  onClick={saveChanges}
                  className="buttonnerHe "
                  style={{ padding: "5px 10px", margin: "10px 0px" }}
                >
                  Save Changes
                </button>
              </div>
              <Form.Check
                label="First Name"
                aria-label="option 8"
                onClick={showFname}
                className="chename"
                checked={fnamex}
              />

              <Form.Check
                label="Photo"
                aria-label="option 8"
                onClick={showPhoto}
                className="chename"
                checked={photox}
              />
              <Form.Check
                label="Last Name"
                aria-label="option 8"
                onClick={showLname}
                className="chename"
                checked={lnamex}
              />

              <Form.Check
                label="Preferred Name"
                aria-label="option 8"
                onClick={showPreferredName}
                className="chename"
                checked={preferredNamex}
              />
              <Form.Check
                label="Email Address"
                aria-label="option 8"
                onClick={showEmail}
                className="chename"
                checked={emailx}
              />
              <Form.Check
                label="Mobile Phone"
                aria-label="option 8"
                onClick={showMobile}
                className="chename"
                checked={mobilex}
              />

              <Form.Check
                label="Gender"
                aria-label="option 1"
                onClick={showGender}
                className="chename"
                checked={genderx}
              />
              <Form.Check
                label="Campus"
                aria-label="option 2"
                onClick={showCampus}
                className="chename"
                checked={campusx}
              />
              <Form.Check
                label="Status"
                aria-label="option 2"
                onClick={showStatus}
                className="chename"
                checked={statusx}
              />
              <Form.Check
                label="Account Status"
                aria-label="option 25"
                onClick={showAccountStatus}
                className="chename"
                checked={accountStatusx}
              />
              <Form.Check
                label="Birth Date"
                aria-label="option 3"
                onClick={showAge}
                className="chename"
                checked={agex}
              />
              <Form.Check
                inline
                label="Marital Status"
                aria-label="option 4"
                onClick={showMartial}
                className="chename"
                checked={maritalx}
              />
              <Form.Check
                label="Mobile Phone"
                aria-label="option 6"
                onClick={showMobile}
                className="chename"
                checked={mobilex}
              />
              <Form.Check
                label="Work Phone"
                aria-label="option 7"
                onClick={showWork}
                className="chename"
                checked={workPhonex}
              />
              <Form.Check
                inline
                label="Family"
                aria-label="option 5"
                onClick={showFamily}
                className="chename"
                checked={familyx}
              />
              <Form.Check
                label="School"
                aria-label="option 6"
                onClick={showSchool}
                className="chename"
                checked={schoolx}
              />
              <Form.Check
                label="Grade"
                aria-label="option 7"
                onClick={showGrade}
                className="chename"
                checked={gradex}
              />
              <Form.Check
                label="Employer"
                aria-label="option 8"
                onClick={showEmployer}
                className="chename"
                checked={employerx}
              />
              <Form.Check
                label="Home Phone"
                aria-label="option 8"
                onClick={showHomePhone}
                className="chename"
                checked={homephonex}
              />

              <Form.Check
                label="City"
                aria-label="option 8"
                onClick={showCity}
                className="chename"
                checked={cityx}
              />
              <Form.Check
                label="Postal"
                aria-label="option 8"
                onClick={showState}
                className="chename"
                checked={statex}
              />
              <Form.Check
                label="Postal"
                aria-label="option 8"
                onClick={showPostal}
                className="chename"
                checked={postalx}
              />
              <Form.Check
                label="Family Role"
                aria-label="option 8"
                onClick={showFamilyRolex}
                className="chename"
                checked={familyRolex}
              />
              {/* {Object.keys(toggles).map((title) => (
                <Form.Check
                  key={title}
                  label={`Show ${title}`}
                  checked={toggles[title]}
                  onChange={() => toggleFieldVisibility(title)}
                  className="chename"
                />
              ))} */}
              {/* {Object.keys(toggles).map((title) => (
                <Form.Check
                  key={title}
                  label={title} // Remove "Show" from label
                  checked={toggles[title]} // Disable by default
                  onChange={() => toggleFieldVisibility(title)} // Enable on selection
                  className="chename"
                  disabled={!toggles[title]} // Disable checkboxes by default
                />
              ))} */}
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col sm={9} lg={9} md={10}>
            <Table responsive>
              <thead>
                <tr className="trdata">
                  <th>
                    <Form.Check
                      aria-label="option 8"
                      onClick={handleSelectAll}
                    />
                  </th>
                  <th>SYNCED</th>
                  {photox && <th>PHOTO</th>}
                  {fnamex && <th>FIRST NAME</th>}
                  {lnamex && <th>LAST NAME</th>}
                  {preferredNamex && <th>PREFERRED NAME</th>}
                  {emailx && <th>EMAIL ADDRESS</th>}
                  {mobilex && <th>MOBILE</th>}
                  {genderx && <th>GENDER</th>}
                  {campusx && <th>CAMPUS</th>}
                  {statusx && <th>STATUS</th>}
                  {accountStatusx && <th>ACCOUNT STATUS</th>}
                  {maritalx && <th>MARITAL STATUS</th>}
                  {agex && <th>BIRTH DATE</th>}
                  {schoolx && <th>SCHOOL</th>}
                  {gradex && <th>GRADE</th>}
                  {homephonex && <th>HOME PHONE</th>}
                  {workPhonex && <th>WORK PHONE</th>}
                  {cityx && <th>CITY</th>}
                  {postalx && <th>POSTAL CODE</th>}
                  {employerx && <th>EMPLOYER</th>}
                  {familyRolex && <th>FAMILY ROLE</th>}
                </tr>
              </thead>

              {/* Prioritize loading state */}
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan="100%">
                      <div className="loadingiconxx">
                        <Loading />
                        <div>Retrieving member records....</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : members.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="100%">
                      <div className="loadingiconxx">
                        <div
                          style={{
                            color: "red",
                            fontSize: "1em",
                            fontWeight: "700",
                          }}
                        >
                          No user found
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                members.map((member) => {
                  const check = isChecked.find((e) => e._id === member._id);
                  return (
                    <tbody key={member._id}>
                      <tr className="trdata">
                        <td>
                          <Form.Check
                            aria-label="option 1"
                            checked={check}
                            onChange={(event) => handleChange(event, member)}
                            type="checkbox"
                          />
                        </td>
                        <td>
                          {member.synced === true ? (
                            <SiMailchimp style={{ color: "green" }} />
                          ) : null}
                        </td>
                        {photox && (
                          <td>
                            <Avatar
                              src={
                                member?.mProfilePicture?.Location
                                  ? member.mProfilePicture.Location
                                  : require(`../../assets/images/blank2.png`)
                              }
                              size={30}
                            />
                          </td>
                        )}
                        {fnamex && (
                          <td
                            onClick={(e) => handleRowClick(e, member._id)}
                            style={{ cursor: "pointer" }}
                          >
                            {toSentenceCase(member.mfirstName)}
                          </td>
                        )}
                        {lnamex && (
                          <td
                            onClick={(e) => handleRowClick(e, member._id)}
                            style={{ cursor: "pointer" }}
                          >
                            {toSentenceCase(member.mlastName)}
                          </td>
                        )}
                        {preferredNamex && (
                          <td>{toSentenceCase(member.preferredName)}</td>
                        )}
                        {emailx && <td>{member.memail}</td>}
                        {mobilex && (
                          <td>
                            {member.mmobilePhone ? member.mmobilePhone : "NA"}
                          </td>
                        )}
                        {genderx && (
                          <td>{member.mgender ? member.mgender : "NA"}</td>
                        )}
                        {campusx && (
                          <td>{member.campus ? member.campus : "NA"}</td>
                        )}
                        {statusx && (
                          <td>{member.mstatus ? member.mstatus : "NA"}</td>
                        )}
                        {accountStatusx && (
                          <td>
                            {member.accountStatus ? member.accountStatus : "NA"}
                          </td>
                        )}
                        {maritalx && (
                          <td>{member.mmarital ? member.mmarital : "NA"}</td>
                        )}
                        {agex && <td>{member.mage ? member.mage : "NA"}</td>}
                        {schoolx && (
                          <td>{member.mschool ? member.mschool : "NA"}</td>
                        )}
                        {gradex && (
                          <td>{member.mgrade ? member.mgrade : "NA"}</td>
                        )}
                        {homephonex && (
                          <td>
                            {member.mhomePhone ? member.mhomePhone : "NA"}
                          </td>
                        )}
                        {workPhonex && (
                          <td>
                            {member.mworkPhone ? member.mworkPhone : "NA"}
                          </td>
                        )}
                        {cityx && <td>{member.mcity ? member.mcity : "NA"}</td>}
                        {statex && (
                          <td>{member.mstate ? member.mstate : "NA"}</td>
                        )}
                        {postalx && (
                          <td>{member.mpostal ? member.mpostal : "NA"}</td>
                        )}
                        {employerx && (
                          <td>{member.memployer ? member.memployer : "NA"}</td>
                        )}
                        {familyRolex && (
                          <td>
                            {member.familyRole ? member.familyRole : "NA"}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  );
                })
              )}
            </Table>
          </Col>
          <Col sm={3} style={{ width: "20%" }}>
            <PeopleContact
              isChecked={isChecked}
              members={members}
              setIsChecked={setIsChecked}
              onLoadingChange={handleNotification}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="auto">
            <div className="pagination-container">
              <select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="rows-per-page-selector"
              >
                {/* <option value={5}>5</option> */}
                {/* <option value={10}>10</option> */}
                <option value={20}>20</option>
                <option value={40}>40</option>
                <option value={80}>80</option>
                <option value={100}>100</option>
              </select>
            </div>
          </Col>
          <Col className="text-center">
            {numOfPages > 1 && (
              <PageBtn
                currentPage={page}
                totalPages={numOfPages}
                handlePageChange={handlePageChange}
              />
            )}
          </Col>
          <Col xs="auto" className="ms-auto text-end pe-5">
            <span className="range-description">
              {renderRangeDescription()}
            </span>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this member. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={deleteThisMember}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNotification}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <Loading />
            <p className="mt-3">Deleting Members...</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PeopleContainer;
