import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useAppContext } from "../../context/appContext";
import Table from "react-bootstrap/Table";
import { Form } from "react-bootstrap";

const BulkValidation = ({ show, setShow }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const { logoutUser } = useAppContext();

  const [memberWithErrors, setMemberWithErrors] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]); // State to track selected members

  const handleCheckboxChange = (memberId) => {
    setSelectedMembers((prevSelected) => {
      if (prevSelected.includes(memberId)) {
        // If the member is already selected, remove it
        return prevSelected.filter((id) => id !== memberId);
      } else {
        // If the member is not selected, add it
        return [...prevSelected, memberId];
      }
    });
  };

  const runValidation = async () => {
    try {
      const response = await authFetch.get("/bulk/validate");
      if (response.status === 200 || response.status === 201) {
        setMemberWithErrors(response.data.membersWithoutFamily);
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error?.response?.status === 401) {
        toast(
          <CustomToastContent title="You are not authorized" type="error" />
        );
      } else {
        toast(
          <CustomToastContent
            title="Validation Failed. Please try again."
            type="error"
          />
        );
      }
    }
  };

  const handleValidate = async () => {
    if (selectedMembers.length === 0) {
      toast(
        <CustomToastContent
          title="Please select at least one member to validate."
          type="error"
        />
      );
      return;
    }

    try {
      const response = await authFetch.post("/bulk/validatequick", {
        selectedMembers,
      });

      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent title="Validation successful." type="success" />
        );
        handleClose();
      } else {
        toast(
          <CustomToastContent
            title="Validation failed. Please try again."
            type="error"
          />
        );
      }
    } catch (error) {
      toast(
        <CustomToastContent
          title="An error occurred during validation. Please try again."
          type="error"
        />
      );
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (show) {
      runValidation();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Validation</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Select</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Family Role</th>
              <th>Related</th>
            </tr>
          </thead>
          <tbody>
            {memberWithErrors?.map((member) => (
              <tr key={member._id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedMembers.includes(member._id)}
                    onChange={() => handleCheckboxChange(member._id)}
                  />
                </td>
                <td>{member.mfirstName}</td>
                <td>{member.mlastName}</td>
                <td>{member.familyRole}</td>
                <td>{member.familyId ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <button onClick={handleClose}>Cancel</button>
        <button onClick={handleValidate}>Validate</button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkValidation;
