import React from "react";
import { Row, Col } from "react-bootstrap";
import "./main.css";
import Logo from "../../assets/images/maini.png";
import { FaCheck } from "react-icons/fa";
import { logEvent } from "../../analytics";
import { useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import kin from "../../assets/images/kin.png";

const Main = ({ title, buttonLabel }) => {
  const navigate = useNavigate(); // Add navigate variable
  const freeClicked = (e) => {
    e.preventDefault();
    // Log the event

    logEvent("Free Trial", "Clicked", "Free Trial Button Clicked", 1);
    navigate("/signuptrial"); // Redirect to the signup page
  };

  return (
    <>
      <Row className="main">
        <p className="highlight-text">Experience Churchease free for 90 days</p>
        <p className="subtitle">
          Maximize your church management with no strings attached
        </p>
        <div className="mainssax flex align-center justify-center">
          <button className="mainbutton" onClick={freeClicked}>
            Get Started
          </button>
        </div>
        <img src={kin} alt="logo" className="image-placeholder" />
      </Row>
      {/* <Row>
        <Col md={6} className="text-col">
          <p className="highlight-text">
            Experience Churchease free for 90 days
          </p>
          <p className="subtitle">
            Maximize your church management with no strings attached
          </p>
          <ul className="mainlist">
            <li>
              <span className="maincheck">
                <FaCheck />
              </span>
              Unlimited functionality with advanced features
            </li>
            <li>
              <span className="maincheck">
                <FaCheck />
              </span>
              Cancel at any time
            </li>
            <li>
              <span className="maincheck">
                <FaCheck />
              </span>
              No credit card required
            </li>
          </ul>
          <div className="mt-4 mainssax">
            <button className="mainbutton" onClick={freeClicked}>
              Get Started
            </button>
          </div>
        </Col>
        <Col className="image-col">
          <img src={Logo} alt="logo" className="image-placeholder" />
        </Col>
      </Row> */}
    </>
  );
};

export default Main;
