import React from "react";

import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { FaPeopleArrows, FaUserAlt } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import "./product.css";
import p1 from "../../assets/newi/p1.png";
import p2 from "../../assets/newi/p2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductPeople = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          People Management | Churchease - Strengthening Community Ties
        </title>
        <meta
          name="description"
          content="Effortlessly manage member data and track engagement with Churchease. Our intuitive interface ensures seamless updates, making every member feel valued and well-connected."
        />
        <link rel="canonical" href="https://churchease.com/product/people" />
        <meta
          property="og:title"
          content="People Management | Churchease - Strengthening Community Ties"
        />
        <meta
          property="og:description"
          content="Effortlessly manage member data and track engagement with Churchease. Our intuitive interface ensures seamless updates, making every member feel valued and well-connected."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/people"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro">
            <div>
              <FaUserAlt size={100} />
              <p className="headssc">People</p>
              <p className="headpp">
                Effortlessly manage member data, tracking engagement and
                personal details to strengthen community ties. Our intuitive
                interface ensures seamless updates and easy accessibility,
                making every member feel valued and well-connected.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for member management
            </p>
            <p className="product13">
              Your all-inclusive solution to manage and engage all church
              members
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={p1} alt="People" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">QUICK SEND TEXT AND EMAIL</p>

              <p className="proddz1">
                Effortlessly communicate with church members using our quick
                send options for email and SMS, ensuring timely and effective
                outreach.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">COMPREHENSIVE COMMUNICATION TOOLS</p>
              <p className="proddz1">
                Leverage integrated email, SMS text, and in-app messaging
                capabilities to keep your congregation informed and engaged.
              </p>
            </div>

            <div className="productdiv">
              <p className="proddz">CENTRALIZED MEMBER DATA</p>
              <p className="proddz1">
                Store and access all your people data in one convenient
                location, making member management smooth and efficient.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain">
          <Col>
            <div className="productdiv">
              <p className="proddz">TARGETED LIST CREATION</p>
              <p className="proddz1">
                Create and customize lists to reach the right people with the
                right messages, enhancing the effectiveness of your
                communication strategies.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CUSTOMIZABLE DATA COLLECTION</p>
              <p className="proddz1">
                Customize the information you collect from members to better
                serve their needs and support your church’s operations.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">QUICK GROUP AND TASK MANAGEMENT</p>
              <p className="proddz1">
                Easily add members to groups, assign follow-ups, and engage
                actively. Streamline these crucial interactions to enhance your
                ministry's effectiveness.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={p2} alt="People" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#909fdb" />
      <Footer />
    </>
  );
};

export default ProductPeople;
