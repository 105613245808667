import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loading from "../../components/Loading/Loading";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { currencyFormatter } from "../../utils/helpers";
import { Container, Row } from "react-bootstrap";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#ffffff",
      fontFamily: '"popins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1em",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "40px", // Adjust line height to increase the element's height
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid red", // Add a border to the element
      borderRadius: "4px", // Optional: if you want rounded corners
      padding: "0.375rem 0.75rem",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CustomPay = ({ amount, priceId, rawData, currency, category }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [amountx, setAmount] = useState(null);
  const [currencyx, setCurrency] = useState(null);
  const [churchNamex, setChurchName] = useState(null);
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error.message);
    } else {
      setIsLoading(true);
      try {
        const response = await authFetch.post("/payment/makecustompayment", {
          paymentMethodId: paymentMethod.id,
          priceId: priceId,
          amount: amount,
          currency: currency,
          rawData: rawData,
          category: category,
          // interval: interval,
          email: email,
          // Include any other relevant information
        });
        // Handle server response here
        if (response.data.status === "succeeded") {
          navigate("/stripe/success");
          // Redirect to a success page
        }
        console.log("Server response:", response.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex justify-center rre332">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <Form.Control
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-black">
            Contribution Information <span style={{ color: "#454843" }}>*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>

        <div className="flex justify-center">
          <button
            disabled={isLoading || !stripe || !elements}
            type="submit"
            className="jiluil inline-block px-5 py-2" // Added padding and set inline-block
            style={{ width: "100%" }} // Ensures the button takes full width
          >
            <span className="flex flex-row items-center justify-center gap-3">
              {isLoading ? (
                <>
                  <Loading /> Loading...
                </>
              ) : (
                <span id="button-text">
                  Pay {currency}
                  {currencyFormatter({
                    amount: amount, // Assuming 'amount' is directly under 'paymentDetails'
                    currency: currency, // Assuming 'currency' is directly under 'paymentDetails'
                  })}
                </span>
              )}
            </span>
          </button>

          {message && (
            <CustomAlert
              alertType="danger"
              alerttext={message && <div id="payment-message">{message}</div>}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

const PaymentForm = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [searchParams] = useSearchParams();
  const priceId = searchParams.get("price_id");
  const id = searchParams.get("id");
  const [loading, setLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [price_id, setPrice_id] = useState("");
  const [userId, setUserId] = useState("");
  const [pkValue, setPkValue] = useState("");
  const [userData, setUserData] = useState({});
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("usd");
  const [rawData, setRawData] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (priceId && id) {
      setPrice_id(priceId);
      setUserId(id);
    }
  }, []);

  useEffect(() => {
    if (pkValue) {
      const stripe = loadStripe(pkValue);
      setStripePromise(stripe);
    }
  }, [pkValue]);

  useEffect(() => {
    if (userData && pkValue) {
      setAmount(userData.paymentAmount);
      setCurrency(userData.currency);
    }
  }, [userData]);
  // Fetch price details from the backend or Stripe

  const fetchPriceDetails = async () => {
    // Replace 'YOUR_API_ENDPOINT' with the actual URL of your GET API endpoint
    // Update the URL accordingly
    const queryParams = {
      price_id: price_id,
      id: userId,
    };

    try {
      // Make the GET request using axios or your preferred HTTP client library
      const response = await authFetch.get("/members/getcustom", {
        params: queryParams,
      });
      setCategory(response.data.customData.category);
      setPkValue(response.data.userPk);
      setUserData(response.data.customData);
      setRawData(response.data.sk);

      // Extract data from the response and update state variables
      setAmount(response.data.userData);
      //setCurrency(response.data.payment);
      setLoading(false);
    } catch (error) {
      // Handle errors, such as network issues or server errors
      console.error("Error fetching price details:", error);
    }
  };

  useEffect(() => {
    if (price_id && userId) {
      fetchPriceDetails();
    }
  }, [price_id, userId]);

  return (
    <Container fluid className="contWwainer2">
      <Row style={{ padding: "0", margin: "0" }}>
        <div className="flex items-center flex-column wrrerx">
          <div className="formdivfdssx">
            <div className="flex flex-column">
              <p className="awwq mb-4">Secure Payment</p>
            </div>
            {stripePromise ? (
              <Elements stripe={stripePromise}>
                <CustomPay
                  amount={amount}
                  currency={currency}
                  pkValue={pkValue}
                  priceId={priceId}
                  rawData={rawData}
                  category={category}
                />
              </Elements>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default PaymentForm;
