import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Card from "react-bootstrap/Card";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./blog.css";

const Blog = () => {
  const { logoutUser } = useAppContext();
  const [posts, setPosts] = useState([]);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await authFetch.get("/unblog/getblog");
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>The Churchease Blog | Updates and Trends in Church Tech</title>
        <meta
          name="description"
          content="Explore the newest updates and trends in Church Tech with Churchease. Stay informed about the latest innovations and insights in church management technology."
        />
        <link rel="canonical" href="https://churchease.com/blog" />
        <meta
          property="og:title"
          content="The Churchease Blog | Updates and Trends in Church Tech"
        />
        <meta
          property="og:description"
          content="Explore the newest updates and trends in Church Tech with Churchease. Stay informed about the latest innovations and insights in church management technology."
        />
        <meta property="og:url" content="https://churchease.com/blog" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="bloga">
            <p className="pricingb">CHURCHEASE BLOG</p>
            <p className="pricingb2">
              Stay Ahead with the Latest Updates and Insights in Church Tech –
              Brought to You by Churchease.
            </p>
          </div>
        </Row>
        <Row className="bloguna pt-5">
          {posts.map((post, index) => (
            <Col sm={6} md={4} lg={4} key={index} className="mb-5 bloguss">
              <div className="d-flex flex-row items-start gap-2 h-100 blogboduu">
                {/* <Link to={"/blogview/" + post.slug}>
                  <img
                    src={post?.image ? post.image.Location : "/course.png"}
                    alt="Blog Description"
                    className="blogImagemn"
                  />
                </Link> */}
                <div className="flex flex-column justify-between gap-4">
                  <img
                    src={post?.image ? post.image.Location : "/course.png"}
                    alt="Blog Description"
                    className="blogImagemn"
                  />
                  <Link to={"/blogview/" + post.slug} className="yj7y">
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <p className="bpadins">{post.category?.toUpperCase()}</p>
                      <p>
                        <Card.Title
                          style={{
                            wordWrap: "break-word",
                            lineHeight: "1.5",
                          }}
                        >
                          {post.blogTitle}
                        </Card.Title>
                      </p>
                      <p>
                        <Link to={"/blogview/" + post.slug} className="yj7y">
                          <Card.Text
                            style={{
                              wordWrap: "break-word",
                              lineHeight: "1.5",
                            }}
                          >
                            {post.homeIntro.substring(0, 150) + "..."}
                          </Card.Text>
                        </Link>
                      </p>
                      <p className="smalltextblog mb-5">
                        {(post.blogDescription.length / 2500).toFixed(0)} MINS
                        READ
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Blog;
