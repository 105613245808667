import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const DeleteDocument = ({ show, setShow, selectedDocument, handleReload }) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/document`);
    setDocument(data);
  };
  console.log(selectedDocument);
  const handleDelete = async () => {
    const data = {
      Key: selectedDocument?.documentFile?.Key,
      Bucket: selectedDocument?.documentFile?.Bucket,
    };

    try {
      const response = await authFetch.delete(
        `/learning/document/${selectedDocument?._id}`,
        {
          data,
        }
      );

      if (response.status === 200 || response.status === 201) {
        handleReload();
        loadCourse();
        handleClose();
        toast(
          <CustomToastContent
            message="Document Deleted Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      toast(
        <CustomToastContent
          message="An error occurred while deleting document"
          type="error"
        />
      );
      Sentry.captureException(err);
    }
  };

  const [document, setDocument] = useState([]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Document
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        This action cannot be reversed.
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            handleDelete(selectedDocument);
            handleClose();
          }}
          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDocument;
