import React from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const FollowUp = ({
  show,
  handleClose,
  myHandleSubmit,
  setError,
  error,
  setting,
  followName,
  setFollowName,
  followHow,
  setFollowComplete,
  followDesc,
  setFollowDesc,
  followDate,
  setFollowDate,
  handleCheckboxChange,
  handleRadioChange,
  followReason,
  followNote,
  setFollowNote,
  username,
  refresh,
}) => {
  const { completemembers } = useAppContext();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Follow-Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Follow-up with <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={followName || username}
              onChange={(e) => setFollowName(e.target.value)}
              name="followName"
              style={{ fontSize: "1em" }}
              placeholder="Enter name"
            />
            <div>
              {completemembers
                .filter((item) => {
                  const searchTerm = followName?.toLowerCase();
                  const fname = item.mfirstName?.toLowerCase();
                  const lname = item.mlastName?.toLowerCase();

                  const fullname = fname + lname;

                  return (
                    searchTerm &&
                    fullname.includes(searchTerm) &&
                    fullname !== searchTerm
                  );
                })
                .slice(0, 10)
                .map((item) => (
                  <div
                    onClick={() =>
                      setFollowName(item.mfirstName + " " + item.mlastName)
                    }
                    className="dropdownmy-row ewfwefwefwe"
                    key={item.mfirstName}
                  >
                    {item.mfirstName} {item.mlastName}
                  </div>
                ))}
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Assign to <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={followDesc}
              onChange={(e) => setFollowDesc(e.target.value)}
              name="followDesc"
              style={{ fontSize: "1em" }}
              placeholder="Enter name"
            />
            <div>
              {completemembers
                .filter((item) => {
                  const searchTerm = followDesc?.toLowerCase();
                  const fname = item.mfirstName?.toLowerCase();
                  const lname = item.mlastName?.toLowerCase();

                  const fullname = fname + lname;

                  return (
                    searchTerm &&
                    fullname.includes(searchTerm) &&
                    fullname !== searchTerm
                  );
                })
                .slice(0, 10)
                .map((item) => (
                  <div
                    onClick={() =>
                      setFollowDesc(item.mfirstName + " " + item.mlastName)
                    }
                    className="dropdownmy-row ewfwefwefwe"
                    key={item.mfirstName}
                  >
                    {item.mfirstName} {item.mlastName}
                  </div>
                ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check // prettier-ignore
              type="radio"
              label="Email"
              name="followHow"
              value="Email"
              checked={followHow === "Email"}
              onChange={handleRadioChange}
              aria-label="radio 1"
              required
            />
            <Form.Check // prettier-ignore
              type="radio"
              label="Phone Call"
              name="followHow"
              value="Phone"
              checked={followHow === "Phone"}
              onChange={handleRadioChange}
              aria-label="radio 1"
              required
            />

            <Form.Check // prettier-ignore
              type="radio"
              label="Visit"
              name="followHow"
              value="Visit"
              checked={followHow === "Visit"}
              onChange={handleRadioChange}
              aria-label="radio 1"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Reason <span className="myast">*</span>
            </Form.Label>
            {setting &&
              setting.map((item, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={item.followUpName}
                  name="followReason"
                  value={item.followUpName} // Use the item's value as the checkbox value
                  checked={followReason?.includes(item.followUpName)} // Check if it's selected
                  onChange={(e) => handleCheckboxChange(e, item.followUpName)} // Pass the value
                />
              ))}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              When <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              value={followDate}
              // onChange={(e) => setFundId(e.target.value)}
              onChange={(e) => setFollowDate(e.target.value)}
              name="followDate"
              style={{ fontSize: "1em" }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Note </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={followNote}
              // onChange={(e) => setFundId(e.target.value)}
              onChange={(e) => setFollowNote(e.target.value)}
              name="followNote"
              style={{ fontSize: "1em" }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Confirm Completion"
              name="defaultFund"
              value="defaultFund"
              onChange={(e) => setFollowComplete(e.target.checked)}
              // onChange={(e) => setDefaultFund(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Cancel
        </button>
        <button className="jiluil" onClick={myHandleSubmit}>
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FollowUp;
