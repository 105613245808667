import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
const PublishAudio = ({ show, setShow, selectedValue, handleReload }) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [audio, setAudio] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/audio`);
    setAudio(data);
  };

  const handlePublish = async () => {
    try {
      await authFetch.put(`/learning/audio/unpublish/${selectedValue._id}`);
      handleReload();
      loadCourse();
      handleClose5();
      toast(
        <CustomToastContent
          title="Success"
          message="Audio Content Unpublished"
        />
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      await authFetch.put(`/learning/audio/${selectedValue._id}`);
      handleReload();
      loadCourse();
      handleClose5();
      toast(
        <CustomToastContent title="Success" message="Audio Content Published" />
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose5 = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          {selectedValue?.published ? "Unpublish Audio" : "Publish Audio"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        {selectedValue?.published
          ? "You are about to unpublish this audio. Do you want to continue?"
          : "You are about to publish this audio. Do you want to continue?"}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose5} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            selectedValue.published
              ? handlePublish(selectedValue)
              : handleUnpublish(selectedValue);
            handleClose5();
          }}
          className="jiluil"
        >
          {selectedValue?.published ? "Unpublish" : "Publish"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishAudio;
