import ChurchInfo from "./ChurchInfo";
import Signup from "./Signup";
import CardInfo from "./CardInfo";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./signup.css";
import { useAppContext } from "../../context/appContext";
import Loading from "../../components/Loading/Loading";
import sss from "../../assets/images/sss.png";

const SignupPage = ({ values }) => {
  const { isLoading } = useAppContext();
  const [step, setstep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    churchName: "",
    churchDomain: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <Container fluid style={{ margin: "0", padding: "0" }}>
          <Row className="container2">
            <Col sm={7} md={7} className="logindiv2xx">
              <Image fluid src={sss} className="imgsizexs" />
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  paddingTop: "2rem",
                  fontSize: "1.9rem",
                  width: "65%",
                }}
              >
                Transform Your Church Management with Churchease
              </p>
              <p className="redi">
                <Link to="/" className="single5">
                  <FontAwesomeIcon icon={faArrowLeft} className="single1" />{" "}
                  Back to home page
                </Link>
              </p>
            </Col>
            <Col className="signupcolumn">
              <h4>Step 1/2</h4>
              <ChurchInfo
                nextStep={nextStep}
                handleFormData={handleInputData}
                values={formData}
              />
            </Col>
          </Row>
        </Container>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <Container fluid style={{ margin: "0", padding: "0" }}>
          <Row className="container2">
            <Col sm={7} md={7} className="logindiv2xx">
              <Image fluid src={sss} className="imgsizexs" />
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  paddingTop: "2rem",
                  fontSize: "1.9rem",
                  width: "65%",
                }}
              >
                Transform Your Church Management with Churchease
              </p>
              <p className="redi">
                <Link to="/" className="single5">
                  <FontAwesomeIcon icon={faArrowLeft} className="single1" />{" "}
                  Back to home page
                </Link>
              </p>
            </Col>
            <Col className="signupcolumn">
              <h4>Step 2/2</h4>
              <Signup
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={handleInputData}
                values={formData}
              />
            </Col>
          </Row>
        </Container>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 3:
      return (
        <Container fluid style={{ margin: "0", padding: "0" }}>
          <Row className="container2">
            <Col sm={7} md={7} className="logindiv2xx">
              <Image fluid src={sss} className="imgsizexs" />
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  paddingTop: "2rem",
                  fontSize: "1.9rem",
                  width: "65%",
                }}
              >
                Transform Your Church Management with Churchease
              </p>
              <p className="redi">
                <Link to="/" className="single5">
                  <FontAwesomeIcon icon={faArrowLeft} className="single1" />{" "}
                  Back to home page
                </Link>
              </p>
            </Col>
            <Col className="signupcolumn">
              <h4>Step 3/3</h4>
              {isLoading && <Loading center />}
              <CardInfo values={formData} />
            </Col>
          </Row>
        </Container>
      );
    // default case to show nothing
    default:
      return <div className="App"></div>;
  }
};

export default SignupPage;
