import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/appContext";
import axios from "axios";
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import { FaThumbsUp } from "react-icons/fa";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StandardSuccess = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, user } = useAppContext();
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const sessionId = query.get("session_id");

    if (sessionId) {
      // Call verifyPayment or any other function to handle the sessionId
      verifyPayment(sessionId);
    }
  }, [query]);

  const goHome = () => {
    navigate("/auth/dashboard");
  };

  async function verifyPayment(sessionId) {
    try {
      const response = await authFetch.post("/payment/custom/verify-payment", {
        sessionId: sessionId,
      });

      // Handle response data, e.g., display a confirmation message or details
      //console.log("Payment verified:", response.data);
      // You can now use response.data to display confirmation or payment details to the user
    } catch (error) {
      console.error(
        "Error verifying payment:",
        error.response ? error.response.data : error.message
      );
      // Handle error, e.g., show an error message to the user
    }
  }

  return (
    <>
      <Helmet>
        <title>Contribution Successful</title>
        <meta
          name="description"
          content="Contributions Successful - Churchease"
        />
      </Helmet>

      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", margin: "0" }}>
          <div className="flex items-center flex-column wrrerxxx">
            <div className="flex flex-column items-center justify-center h-100">
              <div className="flex flex-column items-center">
                <FaThumbsUp className="mt-5" size={100} color="white" />
                <h1 className="text-center mt-5 text-white">
                  Contribution Successful
                </h1>
                <h5 className="text-center mt-3 text-white">
                  Thank you for your contribution!
                </h5>
              </div>
              <button className="mt-3 jiluil344" onClick={goHome}>
                Return to home page
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StandardSuccess;
