import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import "./steps.css";
import { toast } from "react-toastify";
import axios from "axios";
import CustomToastContent from "../../Toast/CustomToastContent";
import { SiMailchimp } from "react-icons/si";

const Step5 = ({ formData, handleChange, handleCompletionChange }) => {
  const { user, logoutUser } = useAppContext(); // use the context here

  const [uniqueTextId, setUniqueTextId] = useState({});
  const [uniqueText, setUniqueText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phone, setPhone] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [alloted, setAlloted] = useState(false);
  const [error, setError] = useState(false);
  const [dataValue, setDataValue] = useState(false);
  const [userNumber, setUserNumber] = useState(user.primaryPhoneNumber);
  const [selectedNumber, setSelectedNumber] = useState();

  // Get available phone numbers
  const getPhoneNumbers = async () => {
    try {
      const response = await authFetch.get("/text");

      setPhoneNumbers(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTextById = async () => {
    try {
      const response = await authFetch.get(`/text/getnumberId/${userNumber}`);
      const texts = response.data;
      const [data] = texts;
      setUniqueText(data);
      const text = texts.length > 0 ? texts[0] : null;
      setUniqueTextId(text);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (user && user.primaryPhoneNumber) {
      setUserNumber(user.primaryPhoneNumber);
      setDataValue(user._id);
    }
  }, [user]);

  useEffect(() => {
    getTextById();
    getPhoneNumbers();
  }, [user.primaryPhoneNumber]);

  useEffect(() => {
    getPhoneNumbers();
  }, []);

  useEffect(() => {
    getMemberById();
  }, []);

  const getMemberById = async () => {
    try {
      const response = await authFetch.get(`/members/getthisuser/${user._id}`);

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setSelectedNumber(data.primaryPhoneNumber);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPhone) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please choose a preferred phone number to continue!"
        />
      );
      return;
    }

    const data = {
      primaryPhoneNumber: selectedPhone,
      churchId: user._id,
      // textId: selectedPhone._id,
    };

    setIsLoading(true); // Start loading

    try {
      const response = await authFetch.post(`/text/push`, data);

      if (response.status === 200 || response.status === 201) {
        getMemberById();

        getPhoneNumbers();
        getTextById();

        toast(
          <CustomToastContent
            title="Success"
            message="Phone number successfully taken!"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const [primaryPhoneNumber, setprimaryPhoneNumber] = useState("");

  useEffect(() => {
    if (user?.primaryPhoneNumber)
      setprimaryPhoneNumber(user?.primaryPhoneNumber);
  }, [user]);

  return (
    <div className="pt-4">
      <p className="text-lg text-bold pb-3">Text Setup</p>
      <div>
        <form>
          <p className="pt-2"></p>

          <Form.Group className="mb-3">
            <Form.Label className="flex justify-between">
              <p style={{ marginBottom: "0px" }}>Select a Phone Number </p>
              {!selectedNumber && (
                <p
                  style={{
                    marginBottom: "0px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {phoneNumbers.length} Phone numbers available in your region
                </p>
              )}
            </Form.Label>
            <Form.Control
              as="select"
              disabled={selectedNumber}
              //onChange={handlePhoneChange}
              value={selectedPhone}
              onChange={(e) => setSelectedPhone(e.target.value)}
            >
              <option value="">
                {selectedNumber ? selectedNumber : "Select a phone number"}
              </option>
              {phoneNumbers?.map((phone) => (
                <option key={phone._id} value={phone.phoneNumber}>
                  {phone.friendlyName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <div className="flex justify-center">
            {!selectedNumber && (
              <button
                type="button"
                className={`jiluil mt-3 ${!alloted ? "mb-5" : "mb-3"}`}
                onClick={handleSubmit}
              >
                <SiMailchimp /> TAKE NUMBER
              </button>
            )}
          </div>

          {selectedNumber && (
            <p style={{ textAlign: "center" }}>
              Your primary phone number is{" "}
              <span style={{ fontWeight: "bold" }}>{selectedNumber}.</span>
            </p>
          )}
        </form>
      </div>
      <div
        className="flex align-center justify-center flex-column w-100 gap-5"
        style={{ textAlign: "center" }}
      >
        <p>
          <span style={{ fontWeight: "600", color: "var(--secondary-color)" }}>
            Note:
          </span>{" "}
          The selected phone number will be used for all text communications in
          your church. This action can only be performed once.
          <span style={{ fontWeight: "600" }}>
            {" "}
            Selected phone numbers cannot be modified for the entire lifecycle
            of the church.
          </span>
        </p>
      </div>

      <div className="flex justify-end">
        <Form.Check
          type="checkbox"
          id="step5Complete"
          label="Mark as Completed"
          checked={formData.step5Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Step5;
