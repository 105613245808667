import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const AddDynamicFields = ({
  show,
  setShow,

  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //const [tasks, setTasks] = useState([""]);
  const [notifyMe, setNotifyMe] = useState(false);
  const [assign, setAssign] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [radioOptions, setRadioOptions] = useState([{ id: 1, value: "" }]);
  const [checkboxOptions, setCheckboxOptions] = useState([
    { id: 1, value: "" },
  ]);
  const maxChars = 250;

  const [fields, setFields] = useState([
    { title: "", type: "text", options: [], order: 1, checked: false }, // Initial task structure
  ]);

  // Function to handle question text change
  const handleTaskChange = (index, value) => {
    const newTasks = [...fields];
    newTasks[index].title = value;
    setFields(newTasks);
  };

  // Function to change the type of response for each task
  const handleTypeChange = (index, value) => {
    const newTasks = [...fields];
    newTasks[index].type = value;
    setFields(newTasks);
  };

  // Function to add a new task
  const addTask = () => {
    setFields([...fields, { title: "", type: "text", options: [] }]);
  };

  // Function to add an option to a task
  const addOptionToTask = (index) => {
    const newTasks = [...fields];
    newTasks[index].options.push("");
    setFields(newTasks);
  };

  // Function to handle option text change
  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const newTasks = [...fields];
    newTasks[taskIndex].options[optionIndex] = value;
    setFields(newTasks);
  };

  // Function to remove an option from a task
  const removeOption = (taskIndex, optionIndex) => {
    const newTasks = [...fields];
    newTasks[taskIndex].options.splice(optionIndex, 1);
    setFields(newTasks);
  };

  const handleCheckChange = (index, isChecked) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, checked: isChecked };
      }
      return field;
    });
    setFields(newFields);
  };

  const removeFields = (index) => {
    const updateFields = fields
      .filter((_, fieldIndex) => fieldIndex !== index)
      .map((field, i) => ({ ...field, order: i + 1 }));
    setFields(updateFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const handleAddfield = async (e) => {
    e.preventDefault();
    try {
      // Validate the form before sending
      if (!title || fields.some((field) => !field.title || !field.type)) {
        //  setError(true); // Set an error if title or any field lacks title or type
        toast(
          <CustomToastContent
            title="Error"
            message="Please complete the required fields to proceed!!"
          />
        );
        return;
      }

      // Construct the data payload for the backend
      const dataToSend = {
        category: title, // Title in this form refers to the category of the fields
        churchDomain: user.churchDomain,
        fields: fields.map((field) => ({
          fieldName: field.title, // Send the field name
          fieldType: field.type, // Map the type to fieldType
          options: field.options || [], // Options for select types
          isRequired: field.checked, // Check if the field is required
        })),
      };

      // Send the data to the backend
      const response = await authFetch.post("/dynamicfields", dataToSend);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc(); // A function to handle successful upload, e.g., showing a success message

        setTitle(""); // Reset category (title)
        setFields([{ title: "", type: "", options: [] }]); // Reset fields to initial state with one empty field
        handleClose(); // Close the modal
        toast(
          <CustomToastContent title="Success" message="Dynamic Form Created" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="general-label">
          Create Dynamic Field
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Category Name <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <div>
              {fields.map((task, index) => (
                <Row key={index} className="mb-3">
                  <Col md={6}>
                    <Form.Label className="form-label">
                      Field Name <span className="myast">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g. First Name"
                      value={task.title}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Label>
                      Select Type <span className="myast">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={task.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                    >
                      <option>Choose...</option>
                      <option value="text">Single Line</option>
                      <option value="textarea">Multi Line</option>
                      <option value="radio">Single Select Option</option>
                      <option value="checkbox">Multiple Select Option</option>
                      <option value="date">Date</option>
                      <option value="number">Number</option>
                    </Form.Select>
                  </Col>
                  <Col
                    md={2}
                    className="flex flex-row justify-between "
                    style={{ alignItems: "end" }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={task.isChecked} // Use `checked` for checkboxes instead of `value`
                      label="Required"
                      onChange={(e) =>
                        handleCheckChange(index, e.target.checked)
                      } // Pass `e.target.checked` to get the boolean state
                    />

                    {index > 0 && (
                      <p
                        onClick={() => removeFields(index)}
                        style={{
                          marginBottom: "0px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Remove
                      </p>
                    )}
                  </Col>
                  {task.type === "radio" || task.type === "checkbox" ? (
                    <>
                      {task.options.map((option, optionIndex) => (
                        <Form.Group
                          key={optionIndex}
                          className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                        >
                          <Form.Check
                            type={task.type}
                            name={`group-${index}`}
                          />
                          <Form.Control
                            type="text"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                e.target.value
                              )
                            }
                          />
                          <FaTimes
                            onClick={() => removeOption(index, optionIndex)}
                            style={{ cursor: "pointer" }}
                          />
                        </Form.Group>
                      ))}
                      <button
                        className="jiluil3 mt-3 mb-3"
                        onClick={() => addOptionToTask(index)}
                      >
                        Add Option
                      </button>
                      <hr></hr>
                    </>
                  ) : null}

                  {/* {task.type === "number" && (
                    <Form.Control
                      type="number"
                      placeholder="Enter a number"
                      value={task.title}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  )}

                  {task.type === "date" && (
                    <Form.Control
                      type="date"
                      value={task.title}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  )} */}
                </Row>
              ))}
              <hr></hr>
              <button className="jiluil mb-3s" onClick={addTask}>
                Add Task
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleAddfield}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDynamicFields;
