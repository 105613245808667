import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import "./addtag.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CustomToastContent from "../Toast/CustomToastContent";

const ManageTags = ({ show, setShow, tagId, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    getContainerById,
    user,
    uniquecontainer,
    getCompleteMembers,
    completemembers,
    getContainerMembers,
    alltagmembers,
    getContainers,
    logoutUser,
  } = useAppContext();
  const [giverName, setGiverName] = useState("");
  const [selectedName, setSelectedName] = useState([]);

  useEffect(() => {
    getContainerById(tagId);
  }, [tagId]);

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    getContainerMembers(uniquecontainer?._id);
  }, [uniquecontainer]);

  const [allmembers, setAllMembers] = useState(null);
  useEffect(() => {
    if (alltagmembers) {
      setAllMembers(alltagmembers.alltagmembers);
    }
  }, [alltagmembers]);

  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
    setSelectedName([...selectedName, searchTerm]);
  };

  const [notice, setNotice] = useState(false);

  // const handleAddToGroup = async () => {
  //   const [fname, lname] = giverName.trim().split(" ");

  //   if (fname === "" || lname === "") {
  //     setNotice(true);
  //     return;
  //   }

  //   const addtoGroup = {
  //     selectedName: giverName.trim(),
  //     fname: fname,
  //     lname: lname,
  //     tags: uniquecontainer?._id,
  //     tagsName: uniquecontainer?.name,
  //     churchName: user?.churchName,
  //   };

  //   const { data: memberIdData } = await authFetch(
  //     `/members/getmemberidonly/${fname}/${lname}`
  //   );

  //   const [memberIdOnly] = memberIdData;

  //   if (!memberIdOnly || !memberIdOnly._id) {
  //     throw new Error("Member ID not found");
  //   }

  //   // Add to container
  //   const { status } = await authFetch.put(`/members/tag`, {
  //     tagsName: uniquecontainer?.name,
  //     tags: uniquecontainer?._id,
  //     mId: memberIdOnly._id,
  //   });

  //   const response = await authFetch.post(`/container/addmember`, addtoGroup);

  //   if (response.status === 200 || response.status === 201) {
  //     handleReload();
  //     setGiverName("");
  //     setSelectedName([]);
  //     setShow(false);
  //     getContainerMembers(uniquecontainer?._id);
  //     getContainers();
  //     getCompleteMembers();
  //     toast(
  //       <CustomToastContent
  //         title="Success"
  //         message="Member Added Successfully!"
  //       />
  //     );
  //   } else {
  //     setNotice(true);
  //   }
  // };

  const handleAddToGroup = async () => {
    try {
      const [fname, lname] = giverName.trim().split(" ");

      if (!fname || !lname) {
        setNotice(true);
        return;
      }

      const addtoGroup = {
        selectedName: giverName.trim(),
        fname: fname,
        lname: lname,
        tags: uniquecontainer?._id,
        tagsName: uniquecontainer?.name,
        churchName: user?.churchName,
      };

      const { data: memberIdData } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );

      const [memberIdOnly] = memberIdData;

      if (!memberIdOnly || !memberIdOnly._id) {
        throw new Error("Member ID not found");
      }

      const tagsName = uniquecontainer?.name;
      const tags = uniquecontainer?._id;
      const mId = memberIdOnly._id;

      // Add to container
      const response = await authFetch.put(`/members/tag`, {
        tagsName,
        tags,
        mId,
      });

      if (response.status !== 200) {
        throw new Error("Failed to add tag to member");
      }

      if (response.status === 200 || response.status === 201) {
        handleReload();
        setGiverName("");
        setSelectedName([]);
        setShow(false);
        getContainerMembers(uniquecontainer?._id);
        getContainers();
        getCompleteMembers();
        toast(
          <CustomToastContent
            title="Success"
            message="Member Added Successfully!"
          />
        );
      } else {
        setNotice(true);
        throw new Error("Failed to add member to container");
      }
    } catch (error) {
      setNotice(true);
      console.error(error);
      toast(
        <CustomToastContent
          title="Error"
          message={error.message || "An error occurred"}
        />
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Member to Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-column gap-2">
          {" "}
          <div className="flex items-center flex-row justify-between  ">
            <Form.Control
              type="text"
              value={giverName}
              onChange={onChange}
              required
              placeholder="Enter Member Name"
            />
          </div>
          <div>
            {completemembers
              .filter((item) => {
                const searchTerm = giverName.toLowerCase();
                const fname = item.mfirstName.toLowerCase();
                const lname = item.mlastName.toLowerCase();

                const fullname = fname + lname;

                return (
                  searchTerm &&
                  fullname.includes(searchTerm) &&
                  fullname !== searchTerm
                );
              })
              .slice(0, 10)
              .map((item) => (
                <div
                  onClick={() =>
                    onSearch((item.mfirstName + " " + item.mlastName).trim())
                  }
                  className="dropdownmy-row ewfwefwefwe"
                  key={item.mfirstName}
                >
                  {item.mfirstName} {item.mlastName}
                </div>
              ))}
          </div>
          <div className="flex justify-center">
            <button
              disabled={!giverName}
              className="jiluil"
              onClick={handleAddToGroup}
            >
              <PlusOutlined className="mccccc" /> Add Member
            </button>
          </div>
        </div>

        {/* <div className="flex flex-col gap-3">
          <div className="my-3 text-bold">
            {allmembers?.length} members added
          </div>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            {allmembers?.length > 0 ? (
              <tbody>
                {allmembers.map((saved, index) => (
                  <tr key={index} className="trdata">
                    <div className="flex justify-between items-center">
                      <td>
                        <Link
                          to={"/auth/peopleview/" + saved._id}
                          style={{ color: "black", textDecoration: "none" }}
                          className="text-md"
                        >
                          {saved.mfirstName} {saved.mlastName}
                        </Link>
                      </td>
                      <td className="rightco">
                        <div>
                          <FaTrash
                            onClick={() =>
                              removeFromContainer(
                                saved._id,
                                uniquecontainer?._id
                              )
                            }
                            className="iconfa"
                          />
                        </div>
                      </td>
                    </div>
                  </tr>
                ))}
              </tbody>
            ) : (
              <div>No members</div>
            )}
          </Table>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageTags;
