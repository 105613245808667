import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";

import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loading/Loading";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BlogDashboard = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { getSavedView, savedViews, deleteView, isLoading, logoutUser } =
    useAppContext();

  const [show, setShow] = useState(false);
  const [clickedMember, setClickedMember] = useState([]);
  const [savedView, setSavedView] = useState([]);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [show2, setShow2] = useState(false);

  useEffect(() => {
    if (savedViews && savedViews.length > 0) {
      setSavedView(savedViews);
    }
  }, [savedViews]);

  const handleClose = () => setShow(false);

  const handleShow = (event, blog) => {
    setShow(true);
    setClickedMember(blog);
  };

  const handleClose2 = () => setShow2(false);

  const handleShow2 = (eventx, blog) => {
    setShow2(true);
    setClickedMember(blog);
  };

  useEffect(() => {
    getBlog();
  }, []);

  const [blog, setBlog] = useState([]);
  const getBlog = async () => {
    try {
      const response = await authFetch.get("/blog");

      if (response && response.data) {
        setBlog(response.data);
      }
    } catch (error) {
      console.error("Error fetching campus:", error);
    }
  };

  const deleteBlog = async () => {
    try {
      // Perform the delete operation and wait for it to finish
      await authFetch.delete(`/blog/${clickedMember._id}`);

      // Update the state only after the blog has been successfully deleted
      let filt = blog.filter((item) => item._id !== clickedMember._id);
      setBlog(filt);

      handleClose();
      getBlog(); // Refresh the blog list
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  useEffect(() => {
    getSavedView();
  }, []);

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...savedView].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setSavedView(sorted);
    } else {
      let sorted = [...savedView].reverse();
      setSavedView(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...savedView].sort((a, b) => {
      if (dateFlag) {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      }
    });
    setSavedView(sorted);
    setDateFlag(!dateFlag);
  };

  return (
    <>
      <Container fluid>
        {blog && blog.length > 0 ? (
          <Row className="tabw">
            <p
              style={{
                fontWeight: "700",
                fontSize: "1em",
                color: "var(--primary-color)",
                paddingTop: "2rem",
              }}
            >
              {blog.length ? (
                <p>
                  {blog.length} record{blog.length > 1 ? "s" : ""} found
                </p>
              ) : (
                <p>No Saved View</p>
              )}
            </p>
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr className="trdataheader">
                  <th onClick={handleDateSort}>
                    POSTED DATE{dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleViewName}>
                    POST TITLE {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th>IMAGE</th>
                  <th>CATEGORY</th>
                  <th style={{ textAlign: "right" }}>STATUS</th>
                </tr>
              </thead>
              {blog.length > 0 &&
                blog.map((blog, index) => (
                  <tbody>
                    <tr key={index} className="trdata">
                      <td>{moment(`${blog.createdAt}`).format("LL")}</td>
                      <td>{blog.blogTitle}</td>
                      <td>{blog.image ? "Yes" : "No"}</td>
                      <td>{blog.category}</td>
                      <td>
                        <Link to={"/auth/blogedit/" + blog._id}>
                          <button className="jiluil">Edit</button>
                        </Link>
                        <button
                          className="jiluil3"
                          onClick={(event) => handleShow(event, blog)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this blog. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={deleteBlog}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlogDashboard;
