import React from "react";
import "./support.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import visuals from "../../assets/newi/visuals.png";

const Support = () => {
  return (
    <Row className="contactus" style={{ backgroundColor: "#F8F9FF" }}>
      <Col className="contactus2" xs={12} sm={12} md={12} lg={6}>
        <img src={visuals} className="imgnewxxx2" alt="Supportive Visual" />
      </Col>
      <Col xs={12} sm={12} md={12} lg={6}>
        <div className="rer">
          <p className="contactus1">Scalable Solutions for Every Church</p>
          <p className="contactus1a">
            Churchease offers versatile technology designed for any church,
            whether small, medium, or large. Save time, reduce costs, and focus
            more on your mission. Tailored for growth, built for savings.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Support;
