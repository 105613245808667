import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import he from "he";

const KnowledgeBases = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, showAlert } = useAppContext();

  const { id } = useParams();

  const [resourceDetails, setResourceDetails] = useState({});
  const [yesCount, setYesCount] = useState(0);
  const [noCount, setNoCount] = useState(0);

  useEffect(() => {
    fetchResourceDetails();
  }, []);

  const fetchResourceDetails = async () => {
    try {
      const { data } = await authFetch(`/knowledgebase/resources/${id}`);
      setResourceDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleYesClick = () => {
    const newCount = yesCount + 1;
    setYesCount(newCount);
    sendFeedbackToServer({ yes: newCount });
  };

  const handleNoClick = () => {
    const newCount = noCount + 1;
    setNoCount(newCount);
    sendFeedbackToServer({ no: newCount });
  };
  useEffect(() => {
    getFeedback();
  }, []);

  const sendFeedbackToServer = async (feedback) => {
    try {
      const file = {
        ...feedback,
      };
      const { data } = await authFetch.post(
        `/generalknowledge/feedback/${id}`,
        feedback
      );

      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      // // Handle success
      // console.log("Feedback sent successfully", await response.json());
    } catch (error) {
      // Handle errors
      console.error("Failed to send feedback", error);
    }
  };

  const getFeedback = async () => {
    try {
      const { data } = await authFetch(`/generalknowledge/feedback/${id}`);

      if (data) {
        setYesCount(data.yesCount);
        setNoCount(data.noCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log("resourceDetails", resourceDetails.blocks);
  return (
    <Container fluid>
      <Row
        style={{
          marginTop: "30px",
          paddingLeft: "10rem",
          paddingRight: "10rem",
        }}
      >
        <Col md={8}>
          {resourceDetails ? (
            <div>
              <p className="sahsxx">{resourceDetails?.title}</p>
              <Col>
                {resourceDetails.blocks?.map((block, index) => (
                  <div key={index}>
                    {block.type === "text" ? (
                      <div
                        style={{
                          color: "black",
                          lineHeight: "2.5",
                          fontFamily: "Open Sans",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: he.decode(block.content),
                        }}
                      />
                    ) : block.type === "image" ? (
                      <img
                        src={block?.imageUrl?.Location}
                        alt={resourceDetails.title}
                      />
                    ) : null}
                  </div>
                ))}
              </Col>
            </div>
          ) : (
            <div>No resource details available.</div>
          )}
          <div
            className="flex justify-center flex-col"
            style={{ paddingBottom: "6rem" }}
          >
            <p className="text-center">Was this helpful?</p>
            <div className="flex justify-center gap-3">
              <button onClick={handleYesClick} className="ibub">
                Yes ({yesCount && yesCount})
              </button>

              <button onClick={handleNoClick} className="ibub">
                No ({noCount && noCount})
              </button>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          style={{
            position: "sticky",
            top: 0,
            height: "100vh",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <p className="sahs">Resources</p>
            <div className="mainsss">
              <p>Resource 1</p>
              <p>Resource 2</p>
              <p>Resource 3</p>
              <p>Resource 4</p>
              <p>Resource 5</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default KnowledgeBases;
