import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./prayers.css";
import axios from "axios";
import Alert from "../../../components/Alert/Alert";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";

const Prayers = () => {
  const { showAlert, user, createPrayerRequest, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [prayerSubject, setPrayerSubject] = useState("");
  const [prayerRequest, setPrayerRequest] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [allSettings, setAllSettings] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [anonymousPost, setAnonymousPost] = useState(false);

  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = async () => {
    const data = await authFetch.get(`/testimony/category`);
    if (data.statusText === "OK") {
      setAllSettings(data.data.categories);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!prayerSubject || !prayerRequest || !selectedCategory) {
      setShow1(true);
      return;
    }

    if (prayerRequest.length > 500) {
      setShow(true);
      return;
    }

    // Define prayerRequestData outside the if-else block
    let prayerRequestData;

    if (anonymousPost) {
      // Anonymous post
      prayerRequestData = {
        prayerSubject: prayerSubject,
        prayerRequest: prayerRequest,
        churchName: user.churchName,
        mfirstName: "anonymous", // Set as "anonymous"
        mlastName: "anonymous", // Set as "anonymous"
        selectedCategory: selectedCategory,
        mId: user._id,
        anonymousPost: anonymousPost,
      };
    } else {
      // Not anonymous post
      prayerRequestData = {
        prayerSubject: prayerSubject,
        prayerRequest: prayerRequest,
        churchName: user.churchName,
        mfirstName: user.mfirstName,
        mlastName: user.mlastName,
        selectedCategory: selectedCategory,
        mId: user._id,
        // anonymousPost: anonymousPost, // Note: Ensure this is intentionally included in both cases
      };
    }

    // Proceed to create the prayer request
    createPrayerRequest(prayerRequestData, {
      alertText: "Prayer Request Sent",
    });

    // Reset form fields
    setPrayerSubject("");
    setPrayerRequest("");
    setAnonymousPost(false);
    setSelectedCategory("");
  };

  const maxChars = 500;

  return (
    <Container fluid>
      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <p className="sahs" style={{ display: "flex", justifyContent: "left" }}>
          Prayer Request
        </p>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showAlert && <Alert />}
        {show && (
          <CustomAlert
            alertText="Prayer Request cannot be more than 500 characters"
            alertType="danger"
          />
        )}
        {show1 && (
          <CustomAlert
            alertText="Please fill in all fields"
            alertType="danger"
          />
        )}

        <form
          className="registerform "
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Prayer Request Subject"
              value={prayerSubject}
              onChange={(e) => setPrayerSubject(e.target.value)}
              name="prayerSubject"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Select
              aria-label="Default select example"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option>Select Category</option>
              {allSettings
                .filter((setting) => setting.selectedOption === "Prayer") // Filter settings for "Prayer" category
                .map((setting) => (
                  <option key={setting._id}>{setting.categoryName}</option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label className="hed">Prayer Requests</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter Prayer Requests here"
              rows={10}
              value={prayerRequest}
              name="prayerRequest"
              onChange={(e) => setPrayerRequest(e.target.value)}
            />
            <div className="character-count flex items-center justify-end">
              {`${prayerRequest.length} / ${maxChars}`} characters
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Check
              type="checkbox"
              value={anonymousPost}
              onChange={(e) => setAnonymousPost(e.target.checked)}
              label="Make Prayer Request Anonymous"
            />
          </Form.Group>

          <Button className="wewewe" onClick={handleSubmit}>
            Send Prayer Request
          </Button>
        </form>
      </Row>
    </Container>
  );
};

export default Prayers;
