import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Row, Button } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";

const Text = () => {
  const { user, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("Available");
  const [church, setChurch] = useState(user.churchName);
  const [country, setCountry] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      phone,
      status,

      country,
    };

    try {
      await authFetch.post("/text", data);
      setPhone("");
      setCountry("");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">Add Text Data</p>
      </Row>
      <Row>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Enter Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Select Country</Form.Label>
            <Form.Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">Select Country</option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
              <option value="Others">Others</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled
            />
          </Form.Group>

          <Button onClick={handleSubmit}>Submit</Button>
        </Form>
      </Row>
    </Container>
  );
};

export default Text;
