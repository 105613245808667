import SearchContainer from "../../../components/SearchContainer/SearchContainer";
import "./memberlist.css";

const MemberList = () => {
  return (
    <>
      <div className="bgc">
        <SearchContainer />
      </div>
    </>
  );
};

export default MemberList;
