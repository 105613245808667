import React from "react";
import { FaCheck } from "react-icons/fa";

import { FcCheckmark } from "react-icons/fc";

const ProPlan = () => {
  return (
    <div>
      <div>
        <p className="colbb cardli noino">All Standard features, plus</p>
        <p className="noino">
          <FaCheck className="okjyb " /> Volunteer Management
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> FollowUp
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Check In/Out
        </p>
      </div>

      <div className="my-3">
        <p style={{ fontWeight: "600" }} className="noino">
          Also includes
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> 1500 texts per month
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> 150GB storage space
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited users and contacts
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited emails
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited support
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Free data transfer
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Cancel anytime
        </p>
      </div>
    </div>
  );
};

export default ProPlan;
