import React, { useState, useEffect } from "react";
import "../Register/register.css";
import Form from "react-bootstrap/Form";
import validator from "validator";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Alert from "../../components/Alert/Alert";
import Loading from "../../components/Loading/Loading";
import { useParams, useLocation } from "react-router-dom";

const Signup = ({ nextStep, handleFormData, prevStep, values }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isMember, setIsMember] = useState(true);
  const { user, alertText, registerMember } = useAppContext();

  //creating error state for validation
  const [error, setError] = useState("");

  const { size, features, billing } = useParams();

  const [featuresArray, setFeaturesArray] = useState([]);

  useEffect(() => {
    try {
      // Decode and parse the 'features' param back into an array
      const parsedFeatures = JSON.parse(decodeURIComponent(features));
      setFeaturesArray(parsedFeatures);
    } catch (error) {
      console.error("Error parsing features:", error);
      setFeaturesArray([]); // Set to an empty array in case of parsing error
    }
  }, [features]);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // Clear any existing errors
    setError("");

    const isFirstNameValid =
      !validator.isEmpty(values.firstName) &&
      validator.isLength(values.firstName, { min: 3, max: 120 });

    const isLastNameValid =
      !validator.isEmpty(values.lastName) &&
      validator.isLength(values.lastName, { min: 3, max: 120 });

    const isEmailValid =
      !validator.isEmpty(values.email) &&
      validator.isLength(values.email, { min: 3, max: 100 }) &&
      validator.isEmail(values.email);

    const isPasswordValid =
      !validator.isEmpty(values.password) &&
      validator.isStrongPassword(values.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      });

    const doPasswordsMatch =
      !validator.isEmpty(values.confirmPassword) &&
      validator.equals(values.password, values.confirmPassword);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPasswordValid &&
      doPasswordsMatch
    ) {
      // No errors, proceed to the next step
      nextStep(); // Proceed to the next step
    } else {
      // Set an error based on which validation check failed
      if (!isFirstNameValid) setError("First name is invalid.");
      else if (!isLastNameValid) setError("Last name is invalid.");
      else if (!isEmailValid) setError("Email is invalid.");
      else if (!isPasswordValid)
        setError("Password does not meet the strength requirements.");
      else if (!doPasswordsMatch) setError("Passwords do not match.");
    }
  };

  const handleProcess = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      churchName,
      churchDomain,
    } = values;

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      churchName === "" ||
      churchDomain === ""
    ) {
      setError("Please fill in all fields.");
      return;
    }

    const currentUser = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      mfirstName: firstName,
      mlastName: lastName,
      churchName: churchName,
      churchDomainx: churchDomain,
      role: [{ roleName: "Super Admin" }],
      entitlement: featuresArray,
      churchSize: size,
      billing: billing,
      mstatus: "Member",
      accountStatus: "Active",
    };

    if (isMember) {
      registerMember({
        currentUser,
        endPoint: "register",
        alertText: "Registration Successful! Redirecting...",
      });
    }
  };

  useEffect(() => {
    let timeoutId;

    if (user) {
      setIsLoading(true); // Start loading

      timeoutId = setTimeout(() => {
        setIsLoading(false); // Stop loading
        navigate("/auth");
      }, 3000);
    }

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setIsLoading(false); // Ensure loading is stopped if the component unmounts
      }
    };
  }, [user, navigate]);

  // Render the Loading component while loading
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Sign Up - Churchease</title>
        <meta
          name="description"
          content="Create your Churchease account today and start managing your church's administration seamlessly."
        />
      </Helmet>

      <Form onSubmit={submitFormData}>
        <Alert />
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            type="text"
            placeholder="First Name*"
            label="First Name"
            className="mb-3 forminputsign"
            onChange={handleFormData("firstName")}
          />
          {error && <div className="error-message">{error}</div>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            type="text"
            placeholder="Last Name*"
            label="Last Name"
            className="mb-3 forminputsign"
            onChange={handleFormData("lastName")}
          />
          {error && <div className="error-message">{error}</div>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            type="email"
            placeholder="Church Email Address*"
            label="Email Address"
            className="mb-3 forminputsign"
            onChange={handleFormData("email")}
          />
          {error && <div className="error-message">{error}</div>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            type="password"
            placeholder="Password*"
            label="Password"
            className="mb-3 forminputsign"
            onChange={handleFormData("password")}
          />
          {error && <div className="error-message">{error}</div>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ border: error ? "2px solid red" : "" }}
            type="password"
            placeholder="Confirm Password*"
            label="Confirm Password"
            className="mb-3 forminputsign"
            onChange={handleFormData("confirmPassword")}
          />
          {error && <div className="error-message">{error}</div>}
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {/* <button className="buttsign" onClick={prevStep}>
            Previous
          </button> */}
          <button className="jiluil4" onClick={prevStep}>
            Previous
          </button>
          <button
            className="jiluil"
            onClick={handleProcess}
            disabled={isLoading}
          >
            {isLoading ? <Loading /> : "Submit"}
          </button>

          {/* <button
            className="buttsign"
            onClick={handleProcess}
            disabled={isLoading}
          >
            Submit
          </button> */}
        </div>
      </Form>
    </>
  );
};

export default Signup;
