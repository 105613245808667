import React, { useState, useEffect } from "react";
import ViewAllCourses from "./../ViewAllCourses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../../../../components/Toast/CustomToastContent";
import * as Sentry from "@sentry/react";
import "../learning.css";
import Table from "react-bootstrap/Table";

import AddPlanner from "../../../../components/Planner/AddPlanner";

import PublishPlanner from "../../../../components/Planner/PublishPlanner";
import AssignPlanner from "../../../../components/Planner/AssignPlanner";
import DeletePlanner from "../../../../components/Planner/DeletePlanner";
import EditPlanner from "../../../../components/Planner/EditPlanner";

const DevelopmentPlan = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/planner`);

    setPlanner(data);
  };

  const [planner, setPlanner] = useState([]);
  const [selectedPlanner, setSelectedPlanner] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    loadCourse();
  }, []);

  const uploadSuccessFunc = () => {
    loadCourse();
  };

  const publishedPlan = () => {
    loadCourse();
  };

  const unpublishedPlan = () => {
    loadCourse();
  };

  const handleShow = (event, plan) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedPlanner(plan);
  };

  const handleShow5 = (event, plan) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedPlanner(plan);
  };

  const handleShow6 = (event, plan) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedPlanner(plan);
    setShow6(true);
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  const addPlanner = (id) => {
    setShow2(true);
  };

  const handleView = (event, plan) => {
    event.preventDefault();
    event.stopPropagation();
    setShow3(true);
    setSelectedPlanner(plan);
  };

  const handleCopy = async (event, plan) => {
    event.preventDefault();
    event.stopPropagation();

    const copyForm = { ...plan };
    delete copyForm._id;
    copyForm.title += " (copy)";
    try {
      const response = await authFetch.post("/learning/planner", copyForm);
      if (response.status === 200 || response.status === 201) {
        loadCourse();
        toast(
          <CustomToastContent message="Operation Successfully" type="success" />
        );
      } else {
        console.error("Unexpected response status:", response.status);
        toast(
          <CustomToastContent message="Error copying planner" type="error" />
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Planner Dashboard</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addPlanner}>
              New Planner
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {planner && planner.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {planner.length ? (
                    <p>
                      {planner.length} record{planner.length > 1 ? "s" : ""}{" "}
                      found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {planner.map((plan, index) => (
                    <tbody>
                      <tr key={index} className="trdata">
                        <td>{plan.title}</td>
                        <td className="rightco">
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleView(event, plan)}
                          >
                            Edit
                          </button>
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleCopy(event, plan)}
                          >
                            Make a Copy
                          </button>
                          <button
                            className={plan.published ? "jiluil3" : "jiluil"}
                            onClick={(event) => handleShow5(event, plan)}
                          >
                            {plan.published ? "Unpublish" : "Publish"}
                          </button>

                          <button
                            className="jiluil3"
                            onClick={(event) => handleShow6(event, plan)}
                          >
                            Assign
                          </button>

                          <button
                            className="jiluil2"
                            onClick={(event) => handleShow(event, plan)}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <div className="flex items-center gap-3">
                            <span className="memcountgr">
                              {plan.mid.length || memberCount}
                            </span>{" "}
                          </div>
                        </td>
                        <td>
                          <Link to={`/auth/growthresponses/${plan._id}`}>
                            <button className="jiluil">View Response</button>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <AddPlanner
        show={show2}
        setShow={setShow2}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <EditPlanner
        show={show3}
        setShow={setShow3}
        selectedPlanner={selectedPlanner}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <DeletePlanner
        show={show}
        setShow={setShow}
        selectedPlanner={selectedPlanner}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <PublishPlanner
        show={show5}
        AssignPlanner
        setShow={setShow5}
        selectedPlanner={selectedPlanner}
        uploadSuccessFunc={uploadSuccessFunc}
        publishedPlan={publishedPlan}
        unpublishedPlan={unpublishedPlan}
      />

      <AssignPlanner
        show={show6}
        setShow={setShow6}
        selectedPlanner={selectedPlanner}
        uploadSuccessFunc={uploadSuccessFunc}
        refreshPage={loadCourse}
      />
    </>
  );
};

export default DevelopmentPlan;
