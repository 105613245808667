import { Badge } from "antd";
import MarkdownCheatsheet from "../Modalmy/MarkdownCheatsheet";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";

const CourseEditForm = ({
  values,
  handleChange,
  handleImage,
  handleSubmit,
  uploadButtonText,
  markdownCheatsheetModal,
  setMarkdownCheatsheetModal = (f) => f,
}) => {
  // router
  const navigate = useNavigate();

  const navigateToView = () => {
    navigate("/auth/courseview/" + values.slug);
  };

  return (
    <>
      <Container fluid>
        <Row className="courseform">
          {values && (
            <form onSubmit={handleSubmit} className="formercourse">
              <div className="form-row pb-2">
                <div className="label">
                  <Form.Label className="corelabel">Course Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name"
                    id="email"
                    name="name"
                    className="forminput"
                    onChange={handleChange}
                    value={values.name}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <Badge style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    <Image
                      width={250}
                      src={values.image && values.image.Location}
                    />
                  </Badge>

                  <div>
                    <label className=" jiluil4 mt-4 ">
                      {uploadButtonText}
                      <input
                        name="image"
                        onChange={handleImage}
                        type="file"
                        accept="image/*"
                        hidden
                      />
                    </label>
                    <p
                      style={{
                        fontSize: "70%",
                        color: "var(--secondary-color)",
                      }}
                    >
                      For the best quality, image dimension should be 700 by
                      500.
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div
                onClick={() =>
                  setMarkdownCheatsheetModal(!markdownCheatsheetModal)
                }
                className="text-center mb-4 pointer"
                style={{ height: "10px" }}
              >
                <b>Learn</b> to <i>write</i> in <code>markdown</code>
              </div>
              <MarkdownCheatsheet
                markdownCheatsheetModal={markdownCheatsheetModal}
                setMarkdownCheatsheetModal={setMarkdownCheatsheetModal}
              />

              <div className="form-row">
                <div className="col">
                  <Form.Label className="corelabel">
                    Course Description
                  </Form.Label>

                  <textarea
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    className="form-control markdown-textarea"
                    placeholder="Description"
                    cols="7"
                    rows="7"
                    required
                    style={{ fontSize: "1em" }}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div
                  className="col"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    onClick={handleSubmit}
                    disabled={values.loading}
                    className="buttonner1111"
                    loading={values.loading}
                  >
                    Save & Continue
                  </button>

                  {/* <button
                    onClick={navigateToView}
                    disabled={values.loading}
                    className="buttonner11112"
                  >
                    Go back
                  </button> */}
                </div>
              </div>
            </form>
          )}
        </Row>
      </Container>
    </>
  );
};

export default CourseEditForm;
