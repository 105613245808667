import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert";
import { SiQuickbooks, SiMailchimp } from "react-icons/si";
import axios from "axios";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import CustomToastContent from "../Toast/CustomToastContent";
import { toast } from "react-toastify";

const MultiFactorAuth = ({ show, setShow }) => {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get("code");
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser, getCurrentUser } = useAppContext();

  const [userName, setUserName] = useState("");
  const [authStatus, setAuthStatus] = useState(null);

  // const [showMessage, setShowMessage] = useState(false);
  // const [showMessage1, setShowMessage1] = useState(false);
  const [secretURL, setSecretURL] = useState(user.mfaSecretURL);

  const generateSecret = async (e) => {
    e.preventDefault();

    try {
      const response = await authFetch.get("/multifactorauth/generate-secret");

      setSecretURL(response.data.secretURL);
      //setShowMessage1(response.data.message);
      toast(
        <CustomToastContent title="Success" message={response.data.message} />
      );
      getSecret();
    } catch (error) {
      console.error("Error generating secret:", error);
    }
  };

  const handleUnenroll = async (e) => {
    e.preventDefault();
    try {
      const response = await authFetch.post("/multifactorauth/unenroll", {
        memail: user.memail, // This should be the email of the logged-in user
      });

      //setShowMessage(response.data.message);
      toast(
        <CustomToastContent title="Success" message={response.data.message} />
      );
      getSecret();
      // navigate("/auth/profile");
    } catch (error) {
      console.error("Error unenrolling from MFA:", error);
      //setShowMessage("Error unenrolling. Please try again.");
      toast(
        <CustomToastContent
          title="Error"
          message="Error unenrolling. Please try again."
        />
      );
    }
  };
  const [fullscreen, setFullscreen] = useState(true);
  const [mfaSecretURL, setMfaSecretURL] = useState("");
  const [mfaEnabled, setMfaEnabled] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    getSecret();
  }, []);

  const getSecret = async () => {
    try {
      const response = await authFetch.get("/multifactorauth/getsecret");

      setMfaSecretURL(response.data.secretURL);
      setMfaEnabled(response.data.mfaEnabled);
    } catch (error) {
      console.error("Error getting secret:", error);
    }
  };

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>MultiFactor Authentication Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Container fluid>
            {/* {showAlert && <Alert />} */}
            <p className="profiletitle pt-5"></p>
            <Row className="courseheader">
              <Col>
                {authStatus && authStatus.access_token && (
                  <div>Authenticated successfully!</div>
                )}
                {authStatus && authStatus.error && (
                  <div>{authStatus.error}</div>
                )}
              </Col>
            </Row>

            <Row className="fundc2">
              <div className="qedqxqx">
                <Table>
                  <tbody>
                    <tr>
                      <td
                        style={{ backgroundColor: "#f7f7f7", padding: "2rem" }}
                      >
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="mb-3">
                              Enrol with your churchease email address
                            </Form.Label>
                            <Form.Control
                              value={user.memail}
                              onChange={(e) => setUserName(e.target.value)}
                              placeholder="Enter Email"
                              className="mb-3"
                              disabled
                            />
                            <div className="w-100">
                              {mfaEnabled && mfaSecretURL ? (
                                <button
                                  onClick={handleUnenroll}
                                  className="buttonner1ss mt-3 mb-3 w-100"
                                >
                                  UNENROLL MFA
                                </button>
                              ) : (
                                <button
                                  onClick={generateSecret}
                                  className="jiluil3  mb-3 w-100"
                                >
                                  GENERATE SECRET
                                </button>
                              )}
                            </div>
                            {mfaEnabled && mfaSecretURL && (
                              <div>
                                <Form.Label>Scan this QR Code</Form.Label>
                              </div>
                            )}
                          </Form.Group>
                          {mfaSecretURL && mfaEnabled && (
                            <QRCode value={secretURL} />
                          )}
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Row>
            <Row>
              <div
                className="flex align-center justify-center flex-column w-100"
                style={{ textAlign: "center" }}
              >
                <p className="qubulkf2">
                  Check our{" "}
                  <a
                    href="https://churchease.com/knowledgebaseview/66716941c206720057060802"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "none",
                    }}
                  >
                    knowledge base
                  </a>{" "}
                  for more information on MultiFactor Authentication.
                </p>
              </div>
            </Row>
          </Container>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultiFactorAuth;
