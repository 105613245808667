import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import moment from "moment/moment";
import axios from "axios";

const CheckOut = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    logoutUser,
    events,
    getEvents,
    getCompleteMembers,
    createCheckin,
  } = useAppContext();

  const [event, setEvent] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [singleEvent, setSingleEvent] = useState("");
  const [singleCheckin, setSingleCheckin] = useState([]);
  const [secureCode, setSecureCode] = useState("");
  const [passed, setPassed] = useState(false);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(
        `/events/getattendancebyid/${event}`
      );
      if (data) {
        setSingleEvent(data.singleEvent);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const getCheckinById = async (e) => {
    e?.preventDefault();

    try {
      const { data } = await authFetch.get(`/checkin/${singleEvent.eventId}`);
      if (data) {
        setSingleCheckin(data.checkin);
      }
    } catch (error) {
      logoutUser();
    }
  };
  const [myAlert, setMyAlert] = useState(false);
  const [checkedin, setCheckedin] = useState(false);
  useEffect(() => {
    if (myAlert) {
      const timer = setTimeout(() => {
        setMyAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [myAlert]);

  useEffect(() => {
    if (checkedin) {
      const timer = setTimeout(() => {
        setCheckedin(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [checkedin]);

  useEffect(() => {
    if (event) {
      getEventById();
    }
  }, [event]);

  useEffect(() => {
    if (singleEvent) {
      getCheckinById();
    }
  }, [singleEvent]);

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    events && getEvents();
  }, []);
  // Extract users older than 30 and add them to a new object

  const changeEvent = async () => {
    setShowForm(!showForm);
  };

  const [checkinData, setCheckinData] = useState(null);

  useEffect(() => {
    if (!checkinData) return;

    const checkValue = singleCheckin.some(
      (item) => item.selectedName === checkinData.selectedName // use checkinData instead of singleEvent for accuracy
    );

    if (checkValue) {
      setMyAlert(true);
    } else {
      createCheckin({ checkin: checkinData });
      setCheckedin(true);
      setSingleCheckin((prevCheckins) => [...prevCheckins, checkinData]); // Update the state directly
      getCheckinById();
    }
  }, [checkinData, singleCheckin]);

  const handleCheckOut = async (e) => {
    e.preventDefault();
    const currentTime = new Date().toISOString();

    const { churchName, eventTitle, _id, eventId, eventStartDate } =
      singleEvent; // Destructuring

    const checkout = {
      churchName,
      eventTitle,
      event_id: _id,
      eventId,
      eventDate: eventStartDate,
      checkIn: currentTime,
      familySecCode: secureCode,
    };

    try {
      const { data } = await authFetch.post(`/checkin/checkout`, checkout);
      if (data) {
        setPassed(true);
        setSelectedDate(data.checkin);
      }
      getCheckinById();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert(error.response.data.status); // Display the error message from the backend
        } else {
          console.error("Data:", error.response.data);
          console.error("Status:", error.response.status);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  const checkoutMember = async (selectedd) => {
    const currentTime = new Date().toISOString();

    const { churchName } = singleEvent; // Destructuring
    const eventId = selectedd._id;
    const checkout = {
      churchName,
      checkOut: currentTime,
      familySecCode: selectedd.familySecCode,
    };

    try {
      const { data } = await authFetch.patch(
        `/checkin/checkout/${eventId}`,
        checkout
      );
      if (data) {
        setPassed(true);
        setSelectedDate(data.checkin);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert(error.response.data.status); // Display the error message from the backend
        } else {
          console.error("Data:", error.response.data);
          console.error("Status:", error.response.status);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        {myAlert && <div className="interte">Member is already checked.</div>}

        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">Check Out</Col>
        </Row>

        <Row className="fundc2" style={{ padding: "3rem" }}>
          <div>
            <Form.Group
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              controlId="formBasicCheckbox"
            >
              <Form.Select
                aria-label="Default select example"
                value={event}
                onChange={(e) => {
                  setEvent(e.target.value);
                  changeEvent();
                }}
                style={{ width: "50%" }}
              >
                <option value="">Select an event to Check Out</option>
                {events
                  .filter((e) => new Date(e.eventDay) >= new Date()) // Filter out past events
                  .sort((a, b) => new Date(a.eventDay) - new Date(b.eventDay))
                  .slice(0, 10)
                  .map((event, index) => (
                    <option key={event._id} value={event._id}>
                      {event.eventTitle} -{" "}
                      {moment(event.eventDay).format("MMMM Do YYYY")}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            {showForm && (
              <Form.Group
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                controlId="formBasicEmail"
              >
                <Form.Label className="hed"></Form.Label>
                <Form.Control
                  type="text"
                  value={secureCode}
                  name="secureCode"
                  onChange={(e) => setSecureCode(e.target.value)}
                  placeholder="Enter your family security code"
                  className="formvalue"
                  required
                  style={{ width: "50%" }}
                />
                <button className="q3rq3r23sss" onClick={handleCheckOut}>
                  Submit
                </button>
              </Form.Group>
            )}
          </div>
        </Row>

        {passed && (
          <Row className="fundc2">
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Name</th>
                  <th style={{ fontSize: "1em" }}>Status</th>
                  <th style={{ fontSize: "1em" }}>Time</th>
                </tr>
              </thead>

              <tbody>
                {selectedDate?.map((selectedd, index) => (
                  <tr>
                    <td style={{ fontSize: "1em" }}>
                      {selectedd.selectedName}
                    </td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {selectedd.checkOut ? (
                        "Checked Out"
                      ) : (
                        <button
                          className="q3rq3r23sss1"
                          onClick={() => checkoutMember(selectedd)}
                        >
                          Check Out
                        </button>
                      )}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {selectedd.checkOut
                        ? moment(`${selectedd.checkOut}`).format("hh:mma")
                        : "No"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

export default CheckOut;
