import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

import "./giving.css";

const AddFunds = () => {
  const [clicked, setClicked] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, fundss) => {
    setShow2(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };

  const [fundNamex, setFundNamex] = useState();
  const [fundIdx, setFundIdx] = useState();
  const [taxDeductiblex, setTaxDeductiblex] = useState();
  const [uniqueData, setUniqueData] = useState(null);
  const {
    displayAlert,
    showAlert,
    isLoading,
    deleteFund,
    getAllFund,
    addFund,
    funds,
    user,
    logoutUser,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const getFundsById = async (e, fundss) => {
    e.preventDefault();

    const { data } = await authFetch.get(`/giving/give/getfundsbyid/${fundss}`);

    // const uniqueDatax = data?.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    setUniqueData(data);
    handleShow2();
  };

  const [fundName, setFundName] = useState();
  const [defaultFund, setDefaultFund] = useState();
  const [taxDeductible, setTaxDeductible] = useState();
  const [fundId, setFundId] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (uniqueData) {
      setFundNamex(uniqueData.fundName);
      setFundIdx(uniqueData.fundId);
      setTaxDeductiblex(uniqueData.taxDeductible);
    }
  }, [uniqueData]);

  const handleDelete = async () => {
    deleteFund(clicked._id, {
      alertText: "Method Deleted",
    });

    handleClose1();
  };

  useEffect(() => {
    getAllFund();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fundName) {
      toast(
        <CustomToastContent title="Error" message="Fund Name is required" />
      );
      return;
    }
    const fundDetails = {
      fundName: fundName,
      fundId: fundId,
      taxDeductible: taxDeductible,
      churchName: user.churchName,
    };

    addFund({ fundDetails });
    getAllFund();
    handleClose();
    setFundId();
    setFundName();
    setDefaultFund();
    setTaxDeductible();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!fundNamex) {
      toast(
        <CustomToastContent title="Error" message="Fund Name is required" />
      );
      return;
    }

    const updateFunds = {
      fundName: fundNamex,
      fundId: fundIdx,
      taxDeductible: taxDeductiblex,
    };
    const response = await authFetch.patch(
      `/giving/give/updatefunds/${uniqueData._id}`,
      updateFunds
    );

    if (response.status === 200 || response.status === 201) {
      toast(
        <CustomToastContent
          message="Fund Updated Successfully"
          title="Success"
        />
      );
      setFundName("");
      setFundId("");
      setTaxDeductible("");

      getAllFund();
      handleClose2();
    }
  };

  if (isLoading) {
    <Loading />;
    return;
  }

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col>
            <p className="sahs">Giving Category</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button className="wedweded" onClick={handleShow}>
              New Giving Category
            </Button>
          </div>
        </Row>
        {funds && funds.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Category Id</th>
                  <th style={{ fontSize: "1em" }}>Category Name</th>
                  <th style={{ fontSize: "1em" }}>Tax Deductible</th>
                  <th style={{ fontSize: "1em" }}>Actions</th>
                </tr>
              </thead>
              {funds &&
                funds?.map((fundss) => (
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "1em" }}>{fundss.fundId}</td>
                      <td style={{ fontSize: "1em" }}>{fundss.fundName}</td>
                      <td style={{ fontSize: "1em" }}>
                        {fundss.taxDeductible === true ? "Yes" : "No"}
                      </td>
                      <td
                        style={{
                          gap: "10px",
                          display: "flex",
                          fontSize: "1em",
                        }}
                      >
                        <button
                          className="jiluil3"
                          onClick={(e) => getFundsById(e, fundss._id)}
                        >
                          Edit
                        </button>

                        <button
                          className="jiluil"
                          onClick={(e) => handleShow1(e, fundss)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
            {/* <button className="noDataButton" onClick={handleShow}>
              Add Giving Category
            </button> */}
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Giving Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">
                Category Name is required to proceed
              </p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category Name"
                value={fundName}
                onChange={(e) => setFundName(e.target.value)}
                name="fundName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category ID"
                value={fundId}
                onChange={(e) => setFundId(e.target.value)}
                name="fundId"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="This fund is Tax Deductible"
                name="taxDeductible"
                value="taxDeductible"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  fontSize: "1em",
                }}
                onChange={(e) => setTaxDeductible(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Giving Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected Giving Category.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Update Giving Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category Name"
                value={fundNamex}
                onChange={(e) => setFundNamex(e.target.value)}
                name="fundName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category ID"
                value={fundIdx}
                onChange={(e) => setFundIdx(e.target.value)}
                name="fundId"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="This fund is Tax Deductible"
                name="taxDeductiblex"
                value="taxDeductiblex"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  fontSize: "1em",
                }}
                onChange={(e) => setTaxDeductiblex(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddFunds;
