import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "../../../components/Alert/Alert";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import nodd from "../../../assets/newi/nodd.png";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT_SMALL,
} from "../../../utils/validation";

const Classroom = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, showAlert } = useAppContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);

  const [classData, setClassData] = useState("");
  const [classDescription, setClassDescription] = useState("");
  const [classid, setClassid] = useState("");
  const [error, setError] = useState("");
  const [fromAgeYear, setFromAgeYear] = useState("");
  const [toAgeYear, setToAgeYear] = useState("");
  const [fromAgeMonth, setFromAgeMonth] = useState("");
  const [toAgeMonth, setToAgeMonth] = useState("");
  const [show1, setShow1] = useState(false);
  const [clicked, setClicked] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isValidNumber = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 && !isNaN(trimmedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(""); // Move error reset to the top

    const trimmedClassData = classData.trim();
    const trimmedClassDescription = classDescription.trim();

    if (!trimmedClassDescription) {
      toast(
        <CustomToastContent
          title="Please provide Class Description
          to proceed."
          type="error"
        />
      );
      return;
    }

    // Initialize and trim age-related data
    const trimmedFromAgeYear = fromAgeYear.trim();
    const trimmedToAgeYear = toAgeYear.trim();
    const trimmedFromAgeMonth = fromAgeMonth.trim();
    const trimmedToAgeMonth = toAgeMonth.trim();

    if (
      !isValidNumber(trimmedFromAgeMonth) ||
      !isValidNumber(trimmedToAgeMonth) ||
      !isValidNumber(trimmedFromAgeYear) ||
      !isValidNumber(trimmedToAgeYear)
    ) {
      toast(
        <CustomToastContent title="Invalid age information!" type="error" />
      );

      return;
    }

    if (trimmedClassData.length === 0) {
      toast(
        <CustomToastContent
          title="Please provide Class Name to proceed."
          type="error"
        />
      );

      return;
    }

    if (trimmedClassData.length > NAME_LENGTH_LIMIT) {
      toast(
        <CustomToastContent
          title={`Class Name should not exceed ${NAME_LENGTH_LIMIT} characters.`}
          type="error"
        />
      );

      return;
    }

    if (trimmedClassDescription.length > DESCRIPTION_LENGTH_LIMIT_SMALL) {
      toast(
        <CustomToastContent title="Class Description too long." type="error" />
      );

      return;
    }

    const data = {
      className: trimmedClassData,
      classDescription: trimmedClassDescription,
      fromAgeYear: trimmedFromAgeYear,
      toAgeYear: trimmedToAgeYear,
      fromAgeMonth: trimmedFromAgeMonth,
      toAgeMonth: trimmedToAgeMonth,
    };

    const response = await authFetch.post("/children/addclassroom", data);

    if (response.status === 200) {
      toast(<CustomToastContent title="Classroom added successfully" />);
      // Clearing form fields after submission
      setClassData("");
      setClassDescription("");
      setFromAgeYear("");
      setToAgeYear("");
      setFromAgeMonth("");
      setToAgeMonth("");
      fetchData();
      handleClose();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await authFetch.get("/children/getclassrooms");
      // Assuming the actual data is in `response.data.result` based on typical Axios response structure

      if (response && response.data) {
        setClassrooms(response.data);
        setIsLoading(false);
      } else {
        // Handle the case where the response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // Handle errors from the fetch operation
      console.error("Error fetching classroom data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [classrooms, setClassrooms] = useState([]);

  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };
  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(
        `/children/deleteclassroom/${clicked._id}`
      );

      if (response.status === 200 || response.status === 201) {
        // Assuming you have a state variable named `classrooms` that holds the list
        setClassrooms((prevClassrooms) =>
          prevClassrooms.filter((classroom) => classroom._id !== clicked._id)
        );

        fetchData(); // Fetch the updated list from the server if necessary
        handleClose1(); // Close any modal or reset any state related to the deletion UI
        toast(<CustomToastContent title="Classroom deleted successfully" />);
      }
    } catch (error) {
      toast(
        <CustomToastContent title="Error deleting classroom" type="error" />
      );
    }
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="sssssssecwef mt-5">
          <div className="bnmjo">
            <Button className="jiluil4" onClick={handleShow}>
              Add Classroom
            </Button>
          </div>
        </Row>
        {isLoading ? (
          <Loading />
        ) : classrooms && classrooms.length > 0 ? (
          <Row className="fundc2">
            <p
              style={{
                fontWeight: "600",
                fontSize: "1.2em",
                marginBottom: "40px",
              }}
            >
              {classrooms.length} Classroom{classrooms.length > 0 ? "s" : ""}
            </p>
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em", width: "20%" }}>CLASS NAME</th>
                  <th
                    style={{
                      fontSize: "1em",
                    }}
                  >
                    CLASS DESCRIPTION
                  </th>
                  <th style={{ fontSize: "1em", width: "30%" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {classrooms
                  ?.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )
                  .map((classroom) => (
                    <tr key={classroom._id}>
                      <td style={{ fontSize: "1em" }}>{classroom.className}</td>
                      <td
                        style={{
                          fontSize: "1em",
                        }}
                      >
                        {classroom.classDescription}
                      </td>
                      <td style={{ fontSize: "1em" }}>
                        <Link to={"/auth/gotoclass/" + classroom._id}>
                          <button
                            className="jiluil"
                            style={{ marginRight: "10px" }}
                          >
                            Go to classroom
                          </button>
                        </Link>
                        <button
                          className="jiluil2"
                          onClick={(e) => handleShow1(e, classroom)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Create Classroom
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="inlineerror">{error}</p>}
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Class Name"
                value={classData}
                onChange={(e) => setClassData(e.target.value)}
                name="className"
                style={{ fontSize: "1em" }}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                as="textarea" // Changes the input to a textarea
                placeholder="Class Description"
                value={classDescription}
                onChange={(e) => setClassDescription(e.target.value)}
                name="classDescription"
                style={{ fontSize: "1em" }}
                rows={3} // Sets the default number of visible text lines
              />
              <p className="smalltext flex justify-end">
                {classDescription.length}/100
              </p>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="fromAgeYear">
                  <Form.Control
                    type="number"
                    placeholder="From Age Year"
                    value={fromAgeYear}
                    onChange={(e) => setFromAgeYear(e.target.value)}
                    name="fromAgeYear"
                    style={{ fontSize: "1em" }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="fromAgeMonth">
                  <Form.Control
                    type="number"
                    placeholder="From Age Month"
                    value={fromAgeMonth}
                    onChange={(e) => setFromAgeMonth(e.target.value)}
                    name="fromAgeMonth"
                    style={{ fontSize: "1em" }}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="toAgeYear">
                  <Form.Control
                    type="number"
                    placeholder="To Age Year"
                    value={toAgeYear}
                    onChange={(e) => setToAgeYear(e.target.value)}
                    name="toAgeYear"
                    style={{ fontSize: "1em" }}
                    required
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="toAgeMonth">
                  <Form.Control
                    type="number"
                    placeholder="To Age Month"
                    value={toAgeMonth}
                    onChange={(e) => setToAgeMonth(e.target.value)}
                    name="toAgeMonth"
                    style={{ fontSize: "1em" }}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add Classroom
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>
            Delete Classroom
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected classroom.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Classroom;
