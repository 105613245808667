import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarked } from "react-icons/fa";
import g1 from "../../assets/newi/g1.png";
import g2 from "../../assets/newi/g2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductServicePlanner = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>Service Planner | Churchease - Seamless Service Planning</title>
        <meta
          name="description"
          content="Efficiently plan and execute services with Churchease. Our tool simplifies scheduling and resource management, ensuring smooth operations and enhanced community engagement."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/service-planner"
        />
        <meta
          property="og:title"
          content="Service Planner | Churchease - Seamless Service Planning"
        />
        <meta
          property="og:description"
          content="Efficiently plan and execute services with Churchease. Our tool simplifies scheduling and resource management, ensuring smooth operations and enhanced community engagement."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/service-planner"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#D68E29" }}>
            <div>
              <FaMapMarked size={100} />
              <p className="headssc">Service Planner</p>
              <p className="headpp">
                Efficiently plan and execute services with our sophisticated
                tool. It simplifies scheduling and resource management, ensuring
                smooth operations. The intuitive design enhances community
                engagement and service delivery.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for seamless service planning
            </p>
            <p className="product13">
              Your comprehensive solution to schedule, coordinate, and manage
              all church services with precision and ease
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={g2} alt="Service Planner" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">EVENT SCHEDULING</p>
              <p className="proddz1">
                Schedule services and events with ease using our intuitive
                calendar interface, designed for optimal organization.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RESOURCE ALLOCATION</p>
              <p className="proddz1">
                Efficiently manage and allocate resources such as rooms,
                equipment, and personnel to ensure every event runs smoothly.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">VOLUNTEER MANAGEMENT</p>
              <p className="proddz1">
                Coordinate volunteer schedules and roles directly through the
                platform, enhancing participation and engagement.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">REMINDER SYSTEM</p>
              <p className="proddz1">
                Send automated reminders to staff and volunteers, ensuring
                everyone is well-prepared for upcoming church events.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CUSTOMIZABLE TEMPLATES</p>
              <p className="proddz1">
                Use customizable templates for different types of services and
                meetings, simplifying the planning process.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">REAL-TIME UPDATES</p>
              <p className="proddz1">
                Keep all team members up to date with real-time changes and
                adjustments to schedules and plans.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={g2} alt="Service Planner" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#D68E29" />
      <Footer />
    </>
  );
};

export default ProductServicePlanner;
