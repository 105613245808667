import React from "react";
import TagBody from "./TagBody";

const Tags = () => {
  return (
    <>
      <TagBody />
    </>
  );
};

export default Tags;
