import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import HomeIntro from "../../components/HomeIntro/HomeIntro";
// import GetStarted from "../../components/Why/GetStarted";
// import WeDeliver from "../../components/WeDeliver/WeDeliver";
import ContactUs from "../../components/ContactUs/ContactUs";
// import ForEveryone from "../../components/ForEveryone/ForEveryone";
import Blog from "../../components/Blog/Blog";
import Pillars from "../../components/ForEveryone/Pillars";
import Support from "../../components/Support/Support";
import Efficiency from "../../components/Efficiency/Efficiency";
import { Helmet } from "react-helmet";
import Faq from "../../components/Faq/Faq";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Unifying Church Communities | Churchease</title>
        <meta
          name="description"
          content="churchease.com is an end-to-end software solution designed to cater to the administrative and management needs of small and large churches alike."
        />
      </Helmet>
      <Container fluid>
        <Header />
        <HomeIntro />

        <Pillars />

        <Faq />

        <Support />
        <Blog />
        <ContactUs />

        <Efficiency />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
