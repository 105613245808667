import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  Tooltip as ReTooltip,
  Legend,
} from "recharts";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../utils/validation";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]; // Colors for pie chart slices

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ReviewForm = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();
  const [errorMessage1, setErrorMessage1] = useState("");
  const [error, setError] = useState("");
  const [note, setNote] = useState("");

  const [form, setForm] = useState({
    forms: [
      {
        question: "Example Question 1",
        type: "text",
        text: "",
        response: "",
        submitted: false,
        status: "pending", // Initial status can be "pending", "in progress", "completed", etc.
      },
      // Add more tasks as needed
    ],
  });

  const aggregateRadioData = (plans) => {
    return plans
      .filter((plan) => plan.type === "radio")
      .map((plan) => ({
        name: plan.question,
        data: plan.options.map((option) => ({
          name: option,
          value: plan.response === option ? 1 : 0,
        })),
      }));
  };

  // Helper function to aggregate data for checkbox responses
  const aggregateCheckboxData = (plans) => {
    return plans
      .filter((plan) => plan.type === "checkbox")
      .map((plan) => ({
        name: plan.question,
        data: plan.options.map((option) => ({
          name: option,
          value: plan.response?.includes(option) ? 1 : 0,
        })),
      }));
  };

  // Aggregate data for charts
  const radioData = form.forms ? aggregateRadioData(form.forms) : [];
  const checkboxData = form.forms ? aggregateCheckboxData(form.forms) : [];

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms/viewresponse/${id}`);

    const [dataxx] = data;

    setForm(dataxx);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  //console.log(planner);

  const handleTaskTextChange = (newValue, taskIndex) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = newValue; // Update the response field
    setForm({ ...form, forms: updatedTasks });
  };

  const handleRadioChange = (taskIndex, selectedOption) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = selectedOption; // Set the selected option as the response
    setForm({ ...form, forms: updatedTasks });
  };

  const handleCheckboxChange = (taskIndex, optionValue) => {
    const updatedTasks = [...form.forms];
    const selectedOptions = updatedTasks[taskIndex].response || [];

    if (selectedOptions.includes(optionValue)) {
      updatedTasks[taskIndex].response = selectedOptions.filter(
        (option) => option !== optionValue
      );
    } else {
      updatedTasks[taskIndex].response = [...selectedOptions, optionValue];
    }

    setForm({ ...form, forms: updatedTasks });
  };

  return (
    <Container fluid>
      <Row className="flex justify-center" style={{ paddingTop: "6rem" }}>
        {form.forms && form.forms.length > 0 ? (
          <div className="taskmain" style={{ paddingBottom: "4rem" }}>
            <div className=" flex flex-column mb-4">
              <p className="sahs"> {form.title}</p>
              {form.description}
              <hr />
            </div>
            {form.forms.map((plan, index) => (
              <div key={index} className="mb-4">
                <Form.Label
                  style={{ fontWeight: "600" }}
                  htmlFor={`taskInput-${index}`}
                >
                  {index + 1}. {plan.question}
                </Form.Label>

                {plan.type === "text" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    type="text"
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled
                  />
                )}

                {plan.type === "textarea" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    as="textarea"
                    rows={10}
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled
                  />
                )}

                {/* {plan.type === "radio" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="radio"
                      id={`radio-${index}-${optionIndex}`}
                      label={option}
                      name={`radioGroup-${index}`}
                      checked={option === plan.response}
                      onChange={() => handleRadioChange(index, option)}
                      disabled
                    />
                  ))}

                {plan.type === "checkbox" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="checkbox"
                      id={`checkbox-${index}-${optionIndex}`}
                      label={option}
                      name={`checkboxGroup-${index}`}
                      checked={plan.response?.includes(option)}
                      onChange={() => handleCheckboxChange(index, option)}
                      disabled
                    />
                  ))} */}
                {plan.type === "radio" && (
                  <PieChart width={400} height={300}>
                    <Pie
                      data={
                        radioData.find((data) => data.name === plan.question)
                          ?.data || []
                      }
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {radioData
                        .find((data) => data.name === plan.question)
                        ?.data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                    </Pie>
                    <ReTooltip />
                    <Legend />
                  </PieChart>
                )}

                {/* Render Bar Chart for Checkbox Responses */}
                {plan.type === "checkbox" && (
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={
                        checkboxData.find((data) => data.name === plan.question)
                          ?.data || []
                      }
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ReviewForm;
