import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const styles = {
  container: {
    minHeight: "100vh",
    background: `linear-gradient(135deg, #243060 0%, #243060 100%)`,
  },
  card: {
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "0 10px 30px rgba(36, 48, 96, 0.2)",
    padding: "3rem",
    maxWidth: "500px",
    margin: "auto",
  },
  icon: {
    color: "#FED970",
    fontSize: "5rem",
    marginBottom: "1.5rem",
    filter: "drop-shadow(0 4px 6px rgba(254, 217, 112, 0.3))",
  },
  title: {
    color: "#243060",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  subtitle: {
    color: "#666",
    fontSize: "1.1rem",
    marginBottom: "2rem",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#243060",
    border: "none",
    borderRadius: "10px",
    color: "white",
    fontSize: "1.1rem",
    padding: "0.8rem 2rem",
    transition: "all 0.3s ease",
    cursor: "pointer",
    width: "100%",
    fontWeight: "600",
  },
  buttonHover: {
    backgroundColor: "#ff8577",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(254, 112, 100, 0.3)",
  },
};

const SuccessRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.success("Profile created successfully!", {
      position: "top-right",
      autoClose: 3000,
    });
  }, []);

  const handleReturn = () => {
    navigate("/", { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Profile Created | ChurchEase</title>
        <meta
          name="description"
          content="Your profile has been created successfully on ChurchEase"
        />
      </Helmet>

      <Container fluid style={styles.container}>
        <Row className="align-items-center" style={{ minHeight: "100vh" }}>
          <Col xs={12} className="py-5">
            <div style={styles.card}>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={styles.icon}
                  className="animate__animated animate__bounceIn"
                />
                <h1 style={styles.title}>Profile Created Successfully!</h1>
                <p style={styles.subtitle}>
                  Your ChurchEase profile is now ready to use. Welcome to our
                  community!
                </p>
                <button
                  style={styles.button}
                  onMouseOver={(e) => {
                    Object.assign(e.target.style, styles.buttonHover);
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor =
                      styles.button.backgroundColor;
                    e.target.style.transform = "none";
                    e.target.style.boxShadow = "none";
                  }}
                  onClick={handleReturn}
                  className="d-flex align-items-center justify-content-center gap-2"
                >
                  <FontAwesomeIcon icon={faHome} />
                  Return to Home
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SuccessRedirect;
