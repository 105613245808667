import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import axios from "axios";
import FollowUpx from "../../../components/FollowUp/FollowUpx";
import "./followup.css";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import { toast } from "react-toastify";

const FollowUp = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    uniquememberdetails,
    getCompleteMembers,
    showAlert,
    deleteFollowUp,
    getAllSettings,
    setting,
    user,
    logoutUser,
    followups,
    getAllFollowUp,
    displayAlert,
    isLoading,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
    getAllFollowUp();
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleShow1 = (item) => {
    setShow1(true);
    setSelectedValue(item);
  };

  const refresh = () => {
    getAllFollowUp();
    getAllSettings();
  };

  const [showx, setShowx] = useState(false);
  const handleClosex = () => setShowx(false);
  const handleShowx = (item) => {
    setShowx(true);
    setItemId(item);
  };

  const [filteredData, setFilteredData] = useState([]);
  const [followName, setFollowName] = useState("");
  const [followDesc, setFollowDesc] = useState("");
  const [followDate, setFollowDate] = useState("");
  const [followHow, setFollowHow] = useState("");
  const [followComplete, setFollowComplete] = useState(false && false);
  const [followReason, setFollowReason] = useState([]);
  const [followNote, setFollowNote] = useState("");
  const [checkField, setCheckField] = useState(false);
  const [includesStandard, setIncludesStandard] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [itemId, setItemId] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const [givingfilter, setGivingfilter] = useState([]);
  const [nameFlag, setNameFlag] = useState(true);
  const [viaFlag, setViaFlag] = useState(true);
  const [reasonFlag, setReasonFlag] = useState(true);
  const [assignedFlag, setAssignedFlag] = useState(true);
  const [targetDateFlag, setTargetDateFlag] = useState(true);
  const [statusFlag, setStatusFlag] = useState(true);
  const [settingDescx, setSettingDescx] = useState("");

  useEffect(() => {
    if (user.role.includes("Standard")) {
      setIncludesStandard(false);
    }
  }, [user.role]);

  const handleRadioChange = (e) => {
    setFollowHow(e.target.value);
  };

  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      // If checkbox is checked, add the value to the selectedReasons array
      setFollowReason([...followReason, value]);
    } else {
      // If checkbox is unchecked, remove the value from the selectedReasons array
      setFollowReason(followReason.filter((reason) => reason !== value));
    }
  };

  useEffect(() => {
    const filtered = followups?.filter(
      (item) =>
        item?.followUpAssignTo === `${user?.mfirstName} ${user?.mlastName}` &&
        (item?.completed === false || item?.completed === undefined)
    );

    setFilteredData(filtered);
  }, [followups]);

  const myHandleSubmit = async (e) => {
    e.preventDefault();

    if (
      !followName ||
      !followDate ||
      !followDesc ||
      !followHow ||
      !followReason
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Complete the form to proceed!"
        />
      );
      return;
    }
    const [fname, lname] = followName?.split(" ");
    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );

    const [memberIdOnly] = data;

    const checkIt = {
      followUpName: followName,
      followUpAssignTo: followDesc,
      followupDate: followDate,
      churchName: user?.churchName,
      mfname: fname,
      mlname: lname,
      mId: memberIdOnly?._id,
      followUpCompleteUpdatepDate: followComplete || false,
      followMedia: followHow,
      followReason: followReason,
      followNote: followNote,
      completed: false,
      assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
    };

    try {
      const response = await authFetch.post("/followup/assignto", checkIt);

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.statusText === "Created"
      ) {
        getAllFollowUp();
        setFollowName("");
        setFollowDesc("");
        setFollowDate("");
        setFollowComplete("");
        setFollowHow("");
        setFollowReason("");
        setFollowNote("");
        handleClose();
        toast(
          <CustomToastContent title="Success" message="Follow-Up Assigned" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateSort = (e) => {
    let sorted = filteredData.sort((a, b) => {
      if (dateFlag) {
        return moment(b.postDate).unix() - moment(a.postDate).unix();
      } else {
        return moment(a.postDate).unix() - moment(b.postDate).unix();
      }
    });
    setGivingfilter(sorted);
    setDateFlag(!dateFlag);
  };

  const handleTargetDateSort = (e) => {
    let sorted = filteredData.sort((a, b) => {
      if (targetDateFlag) {
        return moment(b.followupDate).unix() - moment(a.followupDate).unix();
      } else {
        return moment(a.followupDate).unix() - moment(b.followupDate).unix();
      }
    });
    setGivingfilter(sorted);
    setTargetDateFlag(!targetDateFlag);
  };

  const handleNameSort = (e) => {
    if (nameFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followUpName.localeCompare(b.followUpName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setNameFlag(!nameFlag);
  };

  const handleStatusSort = (e) => {
    if (statusFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.completed === b.completed ? 0 : a.completed ? -1 : 1;
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setStatusFlag(!statusFlag);
  };

  const handleViaSort = (e) => {
    if (viaFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followMedia.localeCompare(b.followMedia);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setViaFlag(!viaFlag);
  };

  const handleReasonSort = (e) => {
    if (reasonFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return (a.followReason[0] || "").localeCompare(b.followReason[0] || "");
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setReasonFlag(!reasonFlag);
  };

  const handleAssignedSort = (e) => {
    if (assignedFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followUpAssignTo.localeCompare(b.followUpAssignTo);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setAssignedFlag(!assignedFlag);
  };

  const [error, setError] = useState(false);

  const myHandleCompleted = async () => {
    if (!isChecked || !itemId || !settingDescx) {
      setError(true);

      return;
    }
    const checkedData = {
      completed: isChecked,
      itemId: itemId,
      completionNote: settingDescx,
    };

    try {
      const response = await authFetch.patch(
        "/followup/completed",
        checkedData
      );

      if (response.status === 200 || response.status === 201) {
        handleClosex();
        getAllFollowUp();
        setSettingDescx("");
        toast(
          <CustomToastContent title="Success" message="Follow-Up Completed" />
        );
      } else {
        toast(
          <CustomToastContent
            title="Error"
            message="An error occurred while completing follow-up"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const isStandard = user.role.some((r) => r.roleName === "Standard");

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Assigned To Me</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            {!isStandard &&
              includesStandard &&
              setting &&
              setting.length > 0 && (
                <button className="jiluil" onClick={handleShow}>
                  Add Follow-Up
                </button>
              )}
          </div>
          {setting && setting.length > 0 && (
            <div style={{ fontWeight: "600" }}>
              {filteredData.length > 0
                ? `Total Follow-Ups Assigned To Me = ${filteredData.length}`
                : `There are no follow-ups assigned to you`}
            </div>
          )}
        </Row>
        <Row className="fundc2">
          {setting && setting.length < 1 && (
            <div className="notification-prompt">
              To access this feature, create a reason for follow-up in the
              settings menu. Click{" "}
              <Link className="wqe44" to="/auth/followupsettings">
                here
              </Link>{" "}
              to get started.
            </div>
          )}
          <Table>
            {filteredData && filteredData.length > 0 ? (
              <>
                <thead>
                  <tr className="trdata">
                    <th onClick={handleDateSort} className="rwewwww">
                      Assigned Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleNameSort} className="rwewwww">
                      Member Name {nameFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleViaSort} className="rwewwww">
                      Via {viaFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleReasonSort} className="rwewwww">
                      Reason {reasonFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleAssignedSort} className="rwewwww">
                      Assigned By{" "}
                      {assignedFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleTargetDateSort} className="rwewwww">
                      Target Date{" "}
                      {targetDateFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th>Note</th>
                    <th>Status</th>
                    <th>Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr className="trdata">
                      <td className="rwewwww">
                        {moment(item.createdAt).format("MMM DD, YYYY")}
                      </td>
                      <td className="rwewwww">{item.followUpName}</td>
                      <td className="rwewwww">{item.followMedia}</td>
                      <td className="rwewwww">
                        {item.followReason.join(", ")}
                      </td>
                      <td className="rwewwww">{item.assignedBy}</td>
                      <td className="rwewwww">
                        {moment(item.followupDate).format("MMM DD, YYYY")}
                      </td>
                      <td className="rwewwww">{item.followNote}</td>
                      <td className="rwewwww">
                        {item.completed === true
                          ? "Closed"
                          : item.completed === false
                          ? "Opened"
                          : "Not applicable"}
                      </td>
                      <td className="rwewwww">
                        <button
                          onClick={(e) => handleShowx(item._id)}
                          className="jiluil3"
                        >
                          Complete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              !user.role.includes("Standard") &&
              setting &&
              setting.length > 0 && (
                <div className="noDatax">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <div>
                      <p className="noDataBackup">
                        No data available for display.
                      </p>
                    </div>
                  )}
                </div>
              )
            )}
          </Table>
        </Row>
      </Container>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Delete Follow-Up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          This action cannot be reversed.
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose1} className="jiluil">
            Close
          </button>
          <button
            onClick={() => {
              deleteFollowUp(selectedValue._id, {
                alertText: "Follow-Up Deleted",
              });
              handleClose1();
            }}
            className="jiluil3"
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showx} onHide={handleClosex}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add Completion Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">Confirm completion to proceed!</p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Completion Note</Form.Label>
              <Form.Control
                as="textarea"
                row={8}
                value={settingDescx}
                onChange={(e) => setSettingDescx(e.target.value)}
                name="settingDesc"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Confirm Completion"
                name="defaultFund"
                value="defaultFund"
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
                // onChange={(e) => setDefaultFund(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClosex}>
            Cancel
          </button>
          <button className="jiluil" onClick={myHandleCompleted}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
      <FollowUpx
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myHandleSubmit={myHandleSubmit}
        uniquememberdetails={uniquememberdetails}
        setting={setting}
        followName={followName}
        setFollowName={setFollowName}
        followDesc={followDesc}
        setFollowDesc={setFollowDesc}
        followDate={followDate}
        setFollowDate={setFollowDate}
        followHow={followHow}
        setFollowHome={setFollowHow}
        followComplete={followComplete}
        setFollowComplete={setFollowComplete}
        handleRadioChange={handleRadioChange}
        checkField={checkField}
        setCheckField={setCheckField}
        followReason={followReason}
        setFollowReason={setFollowReason}
        handleCheckboxChange={handleCheckboxChange}
        followNote={followNote}
        setFollowNote={setFollowNote}
        refresh={refresh}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default FollowUp;
