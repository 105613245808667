import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./dataMigration.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const DataMigration = () => {
  return (
    <Container fluid className="mt-5" style={{ padding: "0", margin: "0" }}>
      <Row className="datadiv1">
        <div className="datadiv">
          <p className="dataat">
            Switch to Churchease! We'll handle your data migration for FREE!
          </p>

          <p className="dataat2">
            Leave the data transfer in our capable hands! Our import team will
            smoothly transfer your people data from your previous system,
            completely free of charge. We're here to support your success!
          </p>

          <p style={{ textAlign: "center" }}>
            <Link to="/signup">
              <Button className="databutton">Get Started</Button>
            </Link>
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default DataMigration;
