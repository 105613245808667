import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";

import Loading from "../../../components/Loading/Loading";

const PrayerSettings = () => {
  const [clicked, setClicked] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e, methods) => {
    setShow2(true);
  };

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (e, methods) => {
    e.preventDefault();
    setShow1(true);
    setClicked(methods);
  };

  const [categoryName, setCategoryName] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [categoryNamex, setCategoryNamex] = useState(false);
  const [selectedOptionx, setSelectOptionx] = useState("");
  const [selectedOptionxx, setSelectOptionxx] = useState("");
  const [methodNamex, setMethodNamex] = useState("");

  const [uniqueData, setUniqueData] = useState(null);
  const [error, setError] = useState(false);

  const {
    displayAlert,

    deleteMethod,
    addMethod,
    allmethods,
    isLoading,
    user,
    logoutUser,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [allSettings, setAllSettings] = useState([]);
  useEffect(() => {
    getCategory();
  }, []);

  const addTestimony = async (e) => {
    e.preventDefault();

    const data = {
      categoryName: categoryName,
      selectedOption: selectOption,
      churchName: user.churchName,
    };

    try {
      const response = await authFetch.post("/testimony/category", data);
      if (response.status === 201 || response.status === 200) {
        getCategory();
        handleClose();
        setCategoryName("");
        setSelectOption("");
        toast(<CustomToastContent title="Category Created" status="success" />);
      }
    } catch (error) {
      if (error.response.status === 401) return;
    }
  };
  const handleSelectChange = (value) => {
    setSelectOptionx(value);
  };
  const getCategory = async () => {
    const data = await authFetch.get(`/testimony/category`);
    if (data.statusText === "OK") {
      setAllSettings(data.data.categories);
    }
  };

  const getMethodById = async (e, method) => {
    const { data } = await authFetch.get(`/testimony/category/${method}`);

    setUniqueData(data);
    handleShow2();
  };

  useEffect(() => {
    if (uniqueData) {
      setMethodNamex(uniqueData.categoryName);
      setSelectOptionx(uniqueData.selectedOption);
    }
  }, [uniqueData]);

  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(
        `/testimony/category/${clicked._id}`
      );
      if (response.status === 200 || response.status === 201) {
        getCategory();
        handleClose1();
        toast(<CustomToastContent title="Category Deleted" status="success" />);
      }
    } catch (error) {
      if (error.response.status === 401) return;
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!methodNamex || !selectedOptionx) {
      displayAlert();
      return;
    }

    const adddMethods = {
      categoryName: methodNamex,
      selectedOption: selectedOptionx,
    };
    const response = await authFetch.put(
      `/testimony/category/${uniqueData._id}`,
      adddMethods
    );

    if (response.status === 200 || response.status === 201) {
      setMethodNamex();
      setSelectOptionx();

      getCategory();
      handleClose2();
      toast(<CustomToastContent title="Category Updated" status="success" />);
    }
  };

  if (isLoading) {
    <Loading />;
    return;
  }
  return (
    <>
      <Container fluid>
        {/* {showAlert && <Alert />} */}
        <Row className="courseheader">
          <Col>
            <p className="courseHeaderPadding">
              Prayer and Testimony Categories
            </p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button className="wedweded" onClick={handleShow}>
              Create Category
            </Button>
          </div>
        </Row>
        {allSettings && allSettings.length > 0 ? (
          <Row className="fundc2" style={{ paddingTop: "3rem" }}>
            <Table responsive hover style={{ backgroundColor: "#f8f8f8" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Category Name</th>
                  <th style={{ fontSize: "1em" }}>Category Type</th>
                  <th style={{ fontSize: "1em" }}>Actions</th>
                </tr>
              </thead>

              <tbody>
                {allSettings.map((methods) => (
                  <tr>
                    <td style={{ fontSize: "1em" }}>{methods.categoryName}</td>
                    <td style={{ fontSize: "1em" }}>
                      {methods.selectedOption}
                    </td>

                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getMethodById(e, methods._id)}
                      >
                        Edit
                      </button>

                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, methods)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Create Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">
                Category name is required to proceed
              </p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                name="categoryName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "1em" }}
                onChange={(e) => setSelectOption(e.target.value)}
                value={selectOption}
              >
                <option>Select Name</option>
                <option value="Prayer">Prayer</option>
                <option value="Testimony">Testimony</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil3" onClick={addTestimony}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Method Name"
                value={methodNamex}
                onChange={(e) => setMethodNamex(e.target.value)}
                name="methodName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "1em" }}
                onChange={(e) => handleSelectChange(e.target.value)}
                value={selectedOptionx}
              >
                <option>Select Name</option>
                <option value="Prayer">Prayer</option>
                <option value="Testimony">Testimony</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected prayer category.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrayerSettings;
