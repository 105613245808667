import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import "./usermanagement.css";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const RolesManagement = () => {
  const navigate = useNavigate();
  const { logoutUser,  permission, getPermission, deletedRole } =
    useAppContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [allRoles, setAllRoles] = useState([]);

  const [clickedRole, setClickedRole] = useState([]);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [fromDB, setFromDB] = useState([]);
  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    if (permission.length && permission) {
      setFromDB(permission);
    }
  }, [fromDB]);

  const handleShow = (event, role) => {
    setShow(true);
    setClickedRole(role);
  };

  const getRoles = async () => {
    const { data } = await authFetch.get("/roles");

    setAllRoles(data);
  };

  const deleteRole = () => {
    deletedRole(clickedRole._id, {
      alertText: "View Deleted",
    });
    getPermission();
    handleClose();
  };

  const addRole = () => {
    navigate("/auth/rolesadd");
  };
  console.log(permission);

  return (
    <Container fluid>
      {/* {showAlert && <Alert />} */}
      <Row className="courseheader" style={{ marginBottom: "3rem" }}>
        <Col className="sahs">Account Management</Col>
      </Row>
      <Row className="qr3wrq3r">
        <div className="bnmjo">
          <Button className="jiluil3" onClick={addRole}>
            Add Role
          </Button>
        </div>
      </Row>

      <Row
        className="tabw"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <p>
          <span style={{ fontWeight: "700", fontSize: "1em" }}>Note:</span>{" "}
          Default roles cannot be edited or deleted.
        </p>
        <Table hover style={{ backgroundColor: "#f7f7f7" }}>
          <thead>
            <tr style={{ fontSize: "100%" }}>
              <th>Role Name</th>
              <th>Role Description</th>

              <th>Last Modified By</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {permission.map((role) => (
              <tr
                key={role?._id}
                style={{ fontSize: "1em" }}
                className={role?.churchName === "System" ? "asas1" : ""}
              >
                <td>{role?.roleName}</td>
                <td>{role?.roleDescription}</td>
                <td>{moment(`${role?.updatedAt}`).format("LL")}</td>

                <td>
                  <button
                    className="but-deleter5"
                    onClick={(event) => handleShow(event, role)}
                    disabled={role?.churchName === "System"}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%" }}>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          This action will permanently delete this role. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteRole}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RolesManagement;
