import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import "./profile.css";
import Resizer from "react-image-file-resizer";
import Profilez from "../../assets/images/blank2.png";
import { useAppContext } from "../../context/appContext";
import Modal from "react-bootstrap/Modal";
import Alert from "../Alert/Alert";

import { Nav, Table, Col } from "react-bootstrap";

const ChurchAddress = ({ show, setShow }) => {
  const { user, isLoading, showAlert, displayAlert, updateUser, logoutUser } =
    useAppContext();

  const [streetAddress, setStreetAddress] = useState(user.maddress);
  const [aptUnitBox, setAptUnitBox] = useState(user.aptUnitBox);
  const [city, setCity] = useState(user.mcity);
  const [state, setState] = useState(user.mstate);
  const [zipCode, setZipCode] = useState(user.mpostal);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentUser = {
      mcity: city,
      mstate: state,
      mpostal: zipCode,
      maddress: streetAddress,
      aptUnitBox: aptUnitBox,
      churchName: user.churchName,
    };
    updateUser({ currentUser });
  };

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Church Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="leftgivediv eweyuu">
          <form className="srgerged ">
            {showAlert && <Alert />}
            <p className="profiletitle"></p>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Street Address
              </Form.Label>
              <Form.Control
                type="text"
                name="streetAddress"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Apt/Unit/Box
              </Form.Label>
              <Form.Control
                type="text"
                name="aptUnitBox"
                value={aptUnitBox}
                onChange={(e) => setAptUnitBox(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="forminput"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                State/Province
              </Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="forminput"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Zip/Postal Code{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="forminput"
              />
            </Form.Group>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleSubmit}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChurchAddress;
