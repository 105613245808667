import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import { currencyFormatter } from "../../../utils/helpers";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./giving.css";
import Col from "react-bootstrap/esm/Col";
import { CSVLink } from "react-csv";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import PrintReport from "./PrintReport";
import { useReactToPrint } from "react-to-print";
import axios from "axios";

const Report = React.forwardRef((props, ref) => {
  const [showData, setShowData] = useState(false);
  const componentRef = useRef();
  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
  });

  const printMe = () => {
    printView();
    setShowData(!showData);
  };

  const {
    getGiving,
    giving,
    getAllFund,
    funds,
    getMethod,
    allmethods,
    searchByStartDateReport,
    searchByEndDateReport,
    searchByFundReport,
    searchByMethodReport,
    searchByMembersReport,
    searchByBatchReport,
    searchByMinAmountReport,
    searchByMaxAmountReport,
    handleChange,

    isLoading,

    logoutUser,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [givingfilter, setGivingfilter] = useState([]);
  const [amountFlag, setAmountFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [fundFlag, setFundFlag] = useState(true);
  const [methodFlag, setMethodFlag] = useState(true);
  const [memberFlag, setMemberFlag] = useState(true);
  const [batchFlag, setBatchFlag] = useState(false);

  useEffect(() => {
    getAllFund();
    getMethod();
  }, []);

  useEffect(() => {
    getGiving();
  }, [
    searchByStartDateReport,
    searchByEndDateReport,
    searchByBatchReport,
    searchByFundReport,
    searchByMaxAmountReport,
    searchByMembersReport,
    searchByMethodReport,
    searchByMinAmountReport,
  ]);

  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const handleAmount = (e) => {
    let sorted = giving.sort((a, b) => {
      return amountFlag ? b.amount - a.amount : a.amount - b.amount;
    });
    setGivingfilter(sorted);
    setAmountFlag(!amountFlag);
  };

  const handleDateSort = (e) => {
    let sorted = giving.sort((a, b) => {
      if (dateFlag) {
        return moment(b.postDate).unix() - moment(a.postDate).unix();
      } else {
        return moment(a.postDate).unix() - moment(b.postDate).unix();
      }
    });
    setGivingfilter(sorted);
    setDateFlag(!dateFlag);
  };

  const handleFundsSort = (e) => {
    if (fundFlag) {
      let sorted = giving.sort(function (a, b) {
        return a.category.localeCompare(b.category);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setFundFlag(!fundFlag);
  };

  const handleMethodSort = (e) => {
    if (methodFlag) {
      let sorted = giving.sort(function (a, b) {
        return a.paymentMethod.localeCompare(b.paymentMethod);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setMethodFlag(!methodFlag);
  };

  const handleMemberSort = (e) => {
    if (memberFlag) {
      let sorted = giving.sort(function (a, b) {
        return a.giverName.localeCompare(b.giverName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setMemberFlag(!memberFlag);
  };

  const handleBatchSort = (e) => {
    let sorted = giving.sort((a, b) => {
      return batchFlag ? b.batch - a.batch : a.batch - b.batch;
    });
    setGivingfilter(sorted);
    setBatchFlag(!batchFlag);
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">Reports</p>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo" style={{ gap: "10px" }}>
          <button className="jiluil">
            <CSVLink
              data={giving}
              filename="report-givings.csv"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Export
            </CSVLink>
          </button>{" "}
          <div style={{ display: "none" }}>
            <PrintReport ref={componentRef} giving={giving} />
          </div>
          <button className="jiluil3" onClick={printMe}>
            Print
          </button>
          {/* <Button className="buttonner">Email Statment</Button> */}
        </div>
      </Row>
      <Row className="fundc2" style={{ paddingTop: "3rem" }}>
        <Col sm={2}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="wefwefrrg">
              Date
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Form.Control
                type="date"
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Start Date"
                onChange={handleSearch}
                value={searchByStartDateReport}
                name="searchByStartDateReport"
                style={{ fontSize: "1em" }}
              />

              <Form.Control
                type="date"
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="End Start"
                onChange={handleSearch}
                value={searchByEndDateReport}
                name="searchByEndDateReport"
                style={{ fontSize: "1em" }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col sm={2}>
          <Form.Group controlId="formBasicEmail">
            <Form.Select
              aria-label="Floating label select example"
              value={searchByFundReport}
              onChange={handleSearch}
              name="searchByFundReport"
              style={{ height: "2.5rem", fontSize: "1em" }}
            >
              <option value="all" style={{ fontSize: "1em" }}>
                Filter by Funds
              </option>
              {funds?.map((fundv) => (
                <option value={fundv.fundName} style={{ fontSize: "1em" }}>
                  {fundv.fundName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group controlId="formBasicEmail">
            <Form.Select
              aria-label="Floating label select example"
              value={searchByMethodReport}
              onChange={handleSearch}
              name="searchByMethodReport"
              style={{ height: "2.5rem", fontSize: "1em" }}
            >
              <option value="all" style={{ fontSize: "1em" }}>
                Filter by Method
              </option>
              {allmethods?.map((method) => (
                <option value={method.methodName} style={{ fontSize: "1em" }}>
                  {method.methodName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="wefwefrrg">
              Members
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Form.Control
                type="text"
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type Name"
                value={searchByMembersReport}
                onChange={handleSearch}
                name="searchByMembersReport"
                style={{ fontSize: "1em" }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col sm={2}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="wefwefrrg">
              Batch
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Enter Batch Number"
                value={searchByBatchReport}
                onChange={handleSearch}
                name="searchByBatchReport"
                style={{ fontSize: "1em" }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col sm={2}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="wefwefrrg">
              Amount
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Min Amount"
                onChange={handleSearch}
                value={searchByMinAmountReport}
                name="searchByMinAmountReport"
                style={{ fontSize: "1em" }}
              />

              <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Max Amount"
                onChange={handleSearch}
                value={searchByMaxAmountReport}
                name="searchByMaxAmountReport"
                style={{ fontSize: "1em" }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      <Row className="fundc2">
        <div>
          <Table hover style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr style={{ cursor: "pointer" }}>
                <th onClick={handleDateSort} style={{ fontSize: "1em" }}>
                  Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleFundsSort} style={{ fontSize: "1em" }}>
                  Fund {fundFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleMethodSort} style={{ fontSize: "1em" }}>
                  Method {fundFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleMemberSort} style={{ fontSize: "1em" }}>
                  Members {fundFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleBatchSort} style={{ fontSize: "1em" }}>
                  Batch {batchFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleAmount} style={{ fontSize: "1em" }}>
                  Amount {amountFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
              </tr>
            </thead>
            {giving?.map((batchess, index) => (
              <tbody key={index}>
                <tr>
                  <td style={{ fontSize: "1em" }}>
                    {moment(batchess.postDate).format("MMM Do, YYYY")}
                  </td>
                  <td style={{ fontSize: "1em" }}>{batchess.category}</td>
                  <td style={{ fontSize: "1em" }}>{batchess.paymentMethod}</td>
                  <td style={{ fontSize: "1em" }}>
                    <Link
                      to={"/auth/peopleview/" + batchess.mId}
                      className="pointer"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p style={{ color: "black" }}>{batchess.giverName}</p>
                    </Link>
                  </td>
                  <td style={{ fontSize: "1em" }}>{batchess.batch}</td>
                  <td style={{ fontSize: "1em" }}>
                    {currencyFormatter({
                      amount: batchess.amount,
                      currency: "usd",
                    })}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </Row>
    </Container>
  );
});

export default Report;
