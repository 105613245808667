import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";

const ManageCustomPayment = ({ show, setShow }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [paymentName, setPaymentName] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [error, setError] = useState(false);
  const [customPayment, setCustomPayment] = useState([]);

  useEffect(() => {
    // Call the pullData function
    pullData();
  }, []);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseDelete = () => setShowDeleteModal(false);

  //const handleShowDelete = () => setShowDeleteModal(true);
  const [payment, setPayment] = useState({});

  const pullData = async () => {
    try {
      const response = await authFetch.get("/members/getallcustompayment");
      const customPaymentData = response.data
        .map((member) => member.customPaymentData)
        .flat();

      if (customPaymentData) {
        setCustomPayment(customPaymentData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (payment) {
      setPaymentAmount(payment.paymentAmount);
      setPaymentDescription(payment.paymentDescription);
      setPaymentName(payment.paymentName);
    }
  }, [payment]);

  const showPayment = (e, payment) => {
    e.preventDefault();
    window.open(payment.secureLink, "_blank");
  };

  return (
    <>
      {" "}
      {error && (
        <p className="inlineerror">Please check all fields and try again!</p>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Payment Name</th>
            <th>Payment Description</th>
            <th>Payment Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customPayment
            ?.filter((payment) => payment.secureLink) // Filter payments with a secureLink
            .map((payment, index) => (
              <tr key={index}>
                <td>{payment.paymentName}</td>
                <td>{payment.paymentDescription}</td>
                <td>{payment.paymentAmount}</td>
                <td onClick={(e) => showPayment(e, payment)}>
                  {payment.secureLink} {/* Display the secureLink */}
                </td>
                <td>
                  <div className="flex justify-center gap-3">
                    {/* Ensure you pass the correct argument to showPayment if it's meant to be used here */}
                    <button
                      className="jiluil3"
                      onClick={(e) => showPayment(e, payment)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default ManageCustomPayment;
