import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaMinus, FaTimes, FaPlus } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import Modal from "react-bootstrap/Modal";
import "./serviceplanner.css";
import Loading from "../../../components/Loading/Loading";
import { useReactToPrint } from "react-to-print";
import PrintOrderOfService from "../../../components/PrintOrderOfService/PrintOrderofService";
import {
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../utils/validation";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const ViewService = () => {
  const params = useParams();
  const [id, setId] = useState("");
  const _id = params.id;
  useEffect(() => {
    if (_id) {
      setId(_id);
    }
  }, [_id]);
  const fileInputRef = useRef();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const componentRef = useRef();
  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order of Service",
  });

  const printMe = () => {
    printView();
  };

  const {
    updateService,
    user,
    logoutUser,

    getCompleteMembers,
    completemembers,
    getServices,
    isLoading,
  } = useAppContext();
  const [allTagData, setAllTagData] = useState([]);
  const [noticeData, setNoticeData] = useState({});
  const [show, setShow] = useState(false);
  const max = 200;

  const handleClose = () => setShow(false);
  const handleShow = (form) => {
    setShow(true);
    setNoticeData(form);
  };

  const [show1, setShow1] = useState(false);
  const [uniqueservice, setUniqueservice] = useState([]);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  useEffect(() => {
    serviceViewById();
  }, []);

  const serviceViewById = async () => {
    try {
      const { data } = await authFetch.get(`/serviceplanner/${_id}`);
      const [uniqueview] = data || [];

      setUniqueservice(uniqueview);
    } catch (error) {
      logoutUser();
    }
  };

  const allTags = async () => {
    try {
      const { data } = await authFetch(`/tags/all-tags`);
      setAllTagData(data);
    } catch (error) {
      logoutUser();
    }
  };

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    allTags();
  }, []);

  useEffect(() => {
    if (uniqueservice?.activities) {
      const activityNames = uniqueservice.activities.map(
        (activity) => activity.activityName
      );
      setActivityName(activityNames);
    }
    if (uniqueservice?.activities) {
      const ministries = uniqueservice.activities.map((mins) => mins.ministry);
      setMinistry(ministries);
    }
  }, [uniqueservice]);

  useEffect(() => {
    if (uniqueservice?.activities?.length > 0) {
      setForms(uniqueservice.activities);
    }
  }, [uniqueservice]);

  const [visibleFormIndex, setVisibleFormIndex] = useState(null); // Initially, no form is visible

  const toggleFormVisibility = (index) => {
    //console.log(index, "index");
    setVisibleFormIndex(visibleFormIndex === index ? null : index); // Toggle visibility for the clicked form
  };

  const [activityName, setActivityName] = useState([]);
  const [ministry, setMinistry] = useState([]);
  const [values, setValues] = useState({ loading: false });
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [errorx, setErrorx] = useState("");
  const [forms, setForms] = useState(
    uniqueservice?.activities?.length > 0
      ? uniqueservice?.activities
      : [
          {
            activityName: "",
            ministry: "",
            personInCharge: "",
            startTime: "",
            endTime: "",
            note: "",
            attachments: [],
            documentDetails: {},
          },
        ]
  );

  const handleFile = async (e, formIndex, fieldName) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const validTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (!validTypes.includes(file.type)) {
      console.error("Invalid file type.");
      setErrorx(
        "Unsupported file type. Please upload a PDF, DOC, DOCX, or TXT file."
      );
      return;
    }

    // Check if file size exceeds 5MB (5 * 1024 * 1024 bytes)
    if (file.size > 5242880) {
      setErrorx("File size should not exceed 5MB.");
      //  setValues((prevValues) => ({ ...prevValues, loading: false }));
      return;
    }

    if (forms[formIndex]?.attachments?.length > 0) {
      setErrorx("Only one file can be uploaded per activity.");
      return;
    }
    setError("");
    setLoading(true);
    const reader = new FileReader();

    reader.onload = async (event) => {
      const fileUri = event.target.result;
      const uniqueFileId = Date.now() + "-" + file.name;

      setValues((prevValues) => ({ ...prevValues, loading: true }));
      setImage(fileUri);
      try {
        // Post the data URI instead of FormData
        const response = await authFetch.post("/serviceplanner/upload-files", {
          image: fileUri,
        });

        // Assuming the response contains the URL of the uploaded file
        const uploadedFileUrl = response.data;
        setForms((prevForms) => {
          // Copy the previous forms array
          const newForms = [...prevForms];

          // Check if documentDetails exists, if not, initialize it as an object
          if (!newForms[formIndex].documentDetails) {
            newForms[formIndex].documentDetails = {};
          }

          // Update the documentDetails for the specified form
          newForms[formIndex].documentDetails = {
            ...newForms[formIndex].documentDetails,
            // Assuming uploadedFileUrl is an object and you want to store it directly
            ...uploadedFileUrl,
          };

          // Return the updated forms array
          return newForms;
        });

        // Update forms state with the new attachment
        setForms((prevForms) => {
          // Clone the previous state
          const newForms = [...prevForms];

          // Ensure the form field for attachments is an array
          if (!Array.isArray(newForms[formIndex][fieldName])) {
            console.error(
              `Expected ${fieldName} to be an array, but got:`,
              newForms[formIndex][fieldName]
            );
            // Initialize it as an array if it's not
            newForms[formIndex][fieldName] = [];
          }

          const currentAttachments = newForms[formIndex][fieldName];

          // Check if the attachment is already present based on a unique identifier (e.g., name)
          const isExistingAttachment = currentAttachments.some(
            (attachment) => attachment.name === file.name
          );

          // If the attachment isn't already present, add it
          if (!isExistingAttachment) {
            const newAttachment = {
              id: uniqueFileId, // Ensure this ID is unique
              name: file.name,
              // Add other file properties as needed
            };

            // Update the attachments array for the specific form
            newForms[formIndex][fieldName] = [
              ...currentAttachments,
              newAttachment,
            ];
          }

          // Return the updated forms state
          return newForms;
        });

        setValues((prevValues) => ({ ...prevValues, loading: false }));
      } catch (error) {
        console.error("Error uploading document:", error);
        setValues((prevValues) => ({ ...prevValues, loading: false }));
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      setValues((prevValues) => ({ ...prevValues, loading: false }));
    };

    // Read the file as a Data URL to create a data URI
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e, index, fieldName) => {
    const newForms = [...forms];
    newForms[index][fieldName] = e.target.value;
    setForms(newForms);
  };

  const addForm = () => {
    setForms((prevForms) => [
      ...prevForms,
      {
        activityName: "",
        ministry: "",
        personInCharge: "",
        startTime: "",
        endTime: "",
        note: "",
        attachments: [],
        documentDetails: {},
      },
    ]);
  };

  const onSearch = (selectedName, index) => {
    const newForms = [...forms];
    newForms[index].personInCharge = selectedName;
    setForms(newForms);
  };

  const removeForm = (indexToRemove) => {
    setForms(forms.filter((_, index) => index !== indexToRemove));
  };

  const validateActivityNames = (forms) => {
    return forms.every(
      (form) => form.activityName.trim().length <= NAME_LENGTH_LIMIT
    );
  };

  const validateActivityNotes = (forms) => {
    return forms.every((form) => {
      // Check if note is provided and, if so, validate its length
      return (
        form.note.trim() === "" ||
        form.note.trim().length <= DESCRIPTION_LENGTH_LIMIT
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim and prepare forms data
    const trimmedForms = forms.map((form) => ({
      ...form,
      note: form.note.trim(),
      activityName: form.activityName.trim(),
    }));

    setError("");

    // Validate activity names and notes
    if (!validateActivityNames(trimmedForms)) {
      setError(
        `Activity Name must be at least ${NAME_LENGTH_LIMIT} characters.`
      );
      return;
    }

    if (!validateActivityNotes(trimmedForms)) {
      setError(
        `Notes must be at least ${DESCRIPTION_LENGTH_LIMIT} characters.`
      );
      return;
    }

    // Prepare data for service update
    const currentService = {
      activities: trimmedForms, // Use trimmed forms for the update
      serviceId: _id, // Make sure _id is defined and valid
    };

    // Update service and handle related operations
    await updateService({ currentService });
    await getServices();
    await serviceViewById(_id); // Make sure this function is designed to handle the _id appropriately
  };

  function getTimeDifferenceInMinutes(startTime, endTime) {
    if (!startTime || !endTime) {
      return ""; // Handle empty inputs as appropriate
    }

    const convertToDateTime = (time12h) => {
      const [timePart, modifier] = time12h.split(" ");
      let [hours, minutes] = timePart.split(":");

      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }

      return new Date(`1970-01-01T${hours}:${minutes}:00`);
    };

    const startDate = convertToDateTime(startTime);
    const endDate = convertToDateTime(endTime);

    // Ensure endDate is not before startDate to prevent negative durations
    if (endDate < startDate) {
      return "Duration cannot be negative."; // Return an error message or handle as appropriate
    }

    const differenceInMilliseconds = endDate - startDate;
    const minutes = differenceInMilliseconds / 1000 / 60;

    if (minutes < 0) {
      return "Duration cannot be negative."; // Additional check for negative minutes
    } else if (minutes < 60) {
      return `${minutes} minutes`; // Directly return for durations less than an hour
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60; // This should not be negative due to the checks above

      return `${hours} hours ${remainingMinutes} minutes`;
    }
  }

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendOrderOfService = async (uniqueservice) => {
    const data = {
      email,
      message,
      uniqueservice,
      churchName: user.churchName,
    };

    try {
      await authFetch.post("/email/sendorderofservice", data);
      handleClose1();
    } catch (error) {
      if (error.response.status !== 401) return;
    }
  };

  const noticeSMS = async () => {
    try {
      const [fname, lname] = noticeData.personInCharge.split(" ");

      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );
      const [memberIdOnly] = data || [];

      if (!memberIdOnly) {
        toast(<CustomToastContent title="Error" message="Member not found." />);
        return;
      }

      const dataq = {
        noticeMail: memberIdOnly?.memail,
        noticePhone: memberIdOnly?.mmobilePhone,
        noticeData,
        churchName: user.churchName,
      };

      const response = await authFetch.post("/sms/noticesms", dataq);

      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="SMS Sent Successfully!"
          />
        );
        handleClose();
      } else {
        throw new Error("Failed to send SMS");
      }
    } catch (error) {
      console.error("Error sending SMS:", error);
      toast(
        <CustomToastContent
          title="Error"
          message="An error occurred while sending the SMS."
        />
      );
    }
  };

  const deleteAttachment = async (formIndex, attachmentId, form) => {
    // Set loading to true
    setValues((prevValues) => ({ ...prevValues, loading: true }));

    const data = {
      attachmentId: attachmentId,
      activityId: uniqueservice._id,
      formDetails: form.documentDetails,
    };

    try {
      // Make the API call to delete the attachment
      const { status } = await authFetch.post("/serviceplanner/remove-files", {
        data,
      });

      // Check if the deletion was successful
      if (status === 200) {
        setImage(null);
        setForms((prevForms) => {
          // Create a new copy of forms
          const newForms = [...prevForms];

          newForms[formIndex].attachments = []; // if you want to empty the entire attachments array

          newForms[formIndex].attachments = newForms[
            formIndex
          ].attachments.filter((attachment) => attachment.id !== attachmentId);

          // Return the updated forms array to update the state
          return newForms;
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        // Handle unsuccessful deletion response
        console.error("Failed to delete attachment:", status);
      }
    } catch (err) {
      // Handle any errors here
      console.error("Error deleting attachment:", err);
    } finally {
      // Set loading to false
      setValues((prevValues) => ({ ...prevValues, loading: false }));
    }
  };

  return (
    <Container fluid>
      <Row className="fundc" style={{ marginBottom: "2rem" }}>
        <Col className="sahs">{uniqueservice.serviceName}</Col>
      </Row>

      <Row className="fundc2">
        <Col sm={3} md={5} lg={5}>
          <div>
            {error && <p className="inlineerror">{error}</p>}
            {errorx && <p className="inlineerror">{errorx}</p>}
            <p className="formtext">Tell us about the volunteers you need</p>

            {forms?.map((form, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "white",
                  paddingTop: "20px",
                }}
              >
                <div className="viewserviceactivity">
                  <span>
                    {" "}
                    {index + 1}.{" "}
                    {activityName && activityName[index]
                      ? activityName[index]
                      : "Activity"}
                  </span>
                  <button
                    onClick={() => toggleFormVisibility(index)}
                    style={{
                      marginLeft: "10px",
                      background: "none",
                      border: "none",
                    }}
                  >
                    {visibleFormIndex === index ? <FaMinus /> : <FaPlus />}
                  </button>
                </div>
                {visibleFormIndex === index && (
                  <>
                    <Form.Group className="mb-3 mt-4">
                      <Form.Label>
                        Enter Activity<span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={form.activityName}
                        onChange={(e) =>
                          handleInputChange(e, index, "activityName")
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Ministry<span className="myast">*</span>
                      </Form.Label>
                      <Form.Select
                        value={form.ministry}
                        onChange={(e) =>
                          handleInputChange(e, index, "ministry")
                        }
                      >
                        <option value="" disabled selected>
                          Select a Ministry
                        </option>

                        {allTagData
                          ? allTagData.map((tag, idx) => (
                              <option value={tag?.tagName} key={idx}>
                                {tag?.tagName}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Person In Charge<span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={form.personInCharge}
                        onChange={(e) =>
                          handleInputChange(e, index, "personInCharge")
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        {completemembers
                          .filter((item) => {
                            const searchTerm =
                              form?.personInCharge.toLowerCase();
                            const fname = item.mfirstName?.toLowerCase();
                            const lname = item.mlastName?.toLowerCase();

                            const fullname = fname + lname;

                            return (
                              searchTerm &&
                              fullname.includes(searchTerm) &&
                              fullname !== searchTerm
                            );
                          })
                          .slice(0, 10)
                          .map((item) => (
                            <div
                              onClick={() =>
                                onSearch(
                                  item.mfirstName + " " + item.mlastName,
                                  index
                                )
                              }
                              className="dropdownmy-row ewfwefwefwe"
                              key={item.mfirstName}
                            >
                              {item.mfirstName} {item.mlastName}
                            </div>
                          ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        type="time"
                        required
                        value={form.startTime}
                        onChange={(e) =>
                          handleInputChange(e, index, "startTime")
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                        type="time"
                        required
                        value={form.endTime}
                        onChange={(e) => handleInputChange(e, index, "endTime")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Add note</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={form.note}
                        onChange={(e) => handleInputChange(e, index, "note")}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId={`formFile-${index}`}
                      className="mb-3"
                    >
                      <div>
                        {!image && ( // This checks if there is no image set
                          <>
                            <Form.Label>Supporting Document</Form.Label>

                            <Form.Control
                              type="file"
                              name="image"
                              onChange={(e) =>
                                handleFile(e, index, "attachments")
                              }
                              className="lpm"
                              ref={fileInputRef}
                              multiple={false} // Ensures only one file can be uploaded
                              accept=".pdf,.doc,.docx,.txt" // Accepts PDFs, Word documents, and text files
                            />
                            <p style={{ fontSize: "70%", color: "#FE7064" }}>
                              Maximum file size: 5MB | Supported file types:
                              PDF, DOC, DOCX, TXT
                            </p>
                          </>
                        )}
                      </div>
                      {forms[index]?.attachments?.map((attachment) => (
                        <div>
                          <p className="inlinesuccess">Upload successful</p>
                          <p key={attachment.id}>
                            {attachment.name}
                            <FaTimes
                              onClick={() =>
                                deleteAttachment(index, attachment.id, form)
                              }
                              style={{
                                color: "#FE7064",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </p>
                        </div>
                      ))}
                    </Form.Group>
                    <hr></hr>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        justifyContent: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <button
                        className="wefwefkkki"
                        onClick={() => handleShow(form)}
                      >
                        Notify Person
                      </button>

                      <button
                        className="wefwefkkki2"
                        onClick={() => removeForm(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}

            <div className="flex justify-center gap-3 mb-3 mt-5 servicpsss">
              <button className="wefwefkkki3" onClick={addForm}>
                Add New Form
              </button>
              <button
                className="butwwtonner2"
                style={{ width: "30%" }}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </Col>
        <Col sm={9} md={7} lg={7}>
          {uniqueservice?.activities?.length > 0 ? (
            <>
              <div
                style={{
                  position: "fixed",
                  width: "42%",
                }}
              >
                {/* Adjust max-width as needed */}
                <p className="formtext">Order of Service Summary</p>
                <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  <Table
                    hover
                    responsive
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ fontSize: "1em" }}>START</th>
                        <th style={{ fontSize: "1em" }}>END</th>
                        <th style={{ fontSize: "1em" }}>ACTIVITY</th>
                        <th style={{ fontSize: "1em" }}>DURATION</th>
                        <th style={{ fontSize: "1em" }}>RESPONSIBLE</th>
                      </tr>
                    </thead>

                    {uniqueservice?.activities?.map((data, index) => (
                      <tbody>
                        <tr key={data._id}>
                          <td style={{ fontSize: "1em", width: "10%" }}>
                            {data.startTime}
                          </td>
                          <td style={{ fontSize: "1em", width: "10%" }}>
                            {data.endTime}
                          </td>
                          <td style={{ fontSize: "1em", width: "35%" }}>
                            {data.activityName}
                          </td>
                          <td style={{ fontSize: "1em", width: "20%" }}>
                            {getTimeDifferenceInMinutes(
                              data.startTime,
                              data.endTime
                            )}
                          </td>
                          <td style={{ fontSize: "1em", width: "25%" }}>
                            {data.personInCharge}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignContent: "flex-end",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ display: "none" }}>
                    <PrintOrderOfService
                      ref={componentRef}
                      uniqueservice={uniqueservice}
                    />
                  </div>
                  <button className="jiluil" onClick={printMe}>
                    Print
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div
              className="noDataBackup"
              style={{
                height: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Save your first activity for live preview.
            </div>
          )}
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{uniqueservice.serviceName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Dear {noticeData.personInCharge}</p>
            You have been assigned the activity below.
            <br></br>
            <br></br>
            <p>
              <span style={{ fontWeight: "600" }}>Activity Name:</span>{" "}
              {noticeData.activityName}
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Start Time</span>{" "}
              {noticeData.startTime}
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>End Time: </span>
              {noticeData.endTime}
            </p>
            <br></br>
            <p>Contact your coordinator for any modifications</p>
            <p>Thank you</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Close
          </button>

          <button className="jiluil" onClick={noticeSMS}>
            Send SMS
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>{uniqueservice.serviceName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Example textarea</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
            <p>Order of Service is attached</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Close
          </button>
          <button
            className="jiluil"
            onClick={() => sendOrderOfService(uniqueservice)}
          >
            Send Email
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ViewService;
