import React, { useRef, useState, useEffect } from "react";

import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useAppContext } from "../../context/appContext";
import { Form } from "react-bootstrap";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
} from "../../utils/validation";
import Alert from "../Alert/Alert";

const AddTagModal = ({ addTagModal, toggle, name, id }) => {
  const { addTag, getRootFolder, getFolder, user, showAlert } = useAppContext();
  const [tagName, setTagName] = useState("");
  const [campusName, setCampusName] = useState("");
  const [error, setError] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Check if the inputRef is defined before calling focus
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleAdd = async () => {
    const [trimmedTagName] = trimInputs([tagName]);
    setError("");
    if (!validateNotEmpty(trimmedTagName)) {
      setError(" Please provide a Ministry Name to proceed.");
      return;
    }
    if (!validateLength(trimmedTagName, NAME_LENGTH_LIMIT)) {
      setError(`Ministry Name cannot exceed ${NAME_LENGTH_LIMIT} words.`);
      return;
    }
    if (!id && !trimmedTagName) {
      setError(true);
      return <div>Both id and tag are empty.</div>;
    }
    await addTag(id, trimmedTagName, campusName);
    setTagName("");
    setCampusName("");
    toggle();
    if (id) {
      await getFolder(id);
    } else {
      await getRootFolder();
    }
  };
  const [campuses, setCampus] = useState([]);

  useEffect(() => {
    if (user && user.churchCampuses.length > 0) {
      setCampus(user.churchCampuses);
    }
  }, [user]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAdd(e); // Call the submit function when Enter is pressed
    }
  };

  return (
    <Modal isOpen={addTagModal} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        style={{ fontSize: "100%", fontWeight: "600" }}
      >
        Add Ministry
      </ModalHeader>
      <ModalBody className="modal-wrapper__body">
        {showAlert && <Alert />}
        <Row>
          {error && <p className="inlineerror">{error}</p>}
          {/* <Col md="4">
            <Label for="tag-name" className="modal-form-group-label">
              Ministry Name
            </Label>
            <Label for="tag-name" className="modal-form-group-label">
              Campus
            </Label>
          </Col> */}

          <div className="mb-3">
            <Label for="tag-name" className="modal-form-group-label">
              Ministry Name
            </Label>
            <Input
              type="text"
              name="name"
              id="tag-name"
              placeholder="Ministry Name"
              onKeyPress={handleKeyPress}
              required
              ref={inputRef}
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
              }}
            />
            <div className="field-under-message">
              Creating new Ministry in <strong>{name}</strong>
            </div>
          </div>
          <div className="mb-3">
            <Label for="tag-name" className="modal-form-group-label">
              Campus
            </Label>
            <Form.Select
              aria-label="Select Campus"
              value={campusName}
              onChange={(e) => setCampusName(e.target.value)}
            >
              {/* Default non-selectable option */}
              <option value="">Select Campus</option>

              {/* Dynamic options generated from the campus array */}
              {campuses.map((campus) => (
                <option key={campus._id} value={campus._id}>
                  {campus.campusName}
                </option>
              ))}
            </Form.Select>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button onClick={toggle} className="jiluil3">
          Cancel
        </button>
        <button onClick={handleAdd} className="jiluil">
          Add
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTagModal;
