import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import HighLightText from "../HighLightText/HighLightText";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const EditLivestreamRecent = ({
  show,
  setShow,

  pickedRecentLiveStream,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);

  const {
    showAlert,
    logoutUser,
    user,
    getEventByVolunteers,
    eventsByVolunteer,
    createSchedule,
  } = useAppContext();
  const [useEvent, setUseEvent] = useState(false);
  const [livestreamById, setLivestreamById] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await authFetch.get(
          `/livestream/recent/post/${pickedRecentLiveStream?._id}`
        );
        // const [formx] = data;
        setLivestreamById(data);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };
    fetchData();
  }, [pickedRecentLiveStream]);

  useEffect(() => {
    if (livestreamById) {
      setTitle(livestreamById.title);
      setYouTubeLink(livestreamById.youtubeLink);
    }
  }, [livestreamById]);

  useEffect(() => {
    getEventByVolunteers();
  }, []);

  const [useEventData, setUseEventData] = useState("");
  const [youtubeLink, setYouTubeLink] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const searchTerm = useEventData?.toLowerCase().trim();
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (useEvent === false) {
      setUseEventData("");
    }
  }, [useEvent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const handlePlanner = async () => {
    try {
      const data = {
        youtubeLink,

        title,
      };

      const response = await authFetch.put(
        `/livestream/recent/update/${pickedRecentLiveStream._id}`,
        data
      );
      if (response.status === 200 || response.status === 201) {
        handleClose();
        toast(
          <CustomToastContent title="Success" message="Operation Successful" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Livestream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form.Group className="mb-3">
            <Form.Label className="hed">Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Livestream Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter live stream link"
                value={youtubeLink}
                onChange={(e) => setYouTubeLink(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>

        <button className="jiluil" onClick={handlePlanner}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditLivestreamRecent;
