import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Accordion, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import "./main.css";

const FaqFree = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="freqmain">
        <div style={{ paddingBottom: "40px" }}>
          <p className="freqtitle">Frequently Asked Questions</p>
          <p className="freqtitlesub">
            Find quick answers to common questions about our free trial. Still
            have more questions? We're here to help!
          </p>
        </div>

        <div className="freqnm">
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What is included in the free trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                You get full and unrestricted access to all Churchease features
                for 90 days.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Do I need to enter my credit card details?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                No, the free trial does not require any payment information.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can I cancel anytime?</Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, you can cancel your trial at any point with no obligations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What happens after the free trial ends?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                After the trial period, you can choose to subscribe to the
                platform. Subscription is based on your church size and required
                features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Is there any setup required to start the trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                No extensive setup is required. You can get started quickly with
                our intuitive platform.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Will I have access to customer support during the trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, our customer support team is available to assist you
                throughout your trial period.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Can I invite other team members to use Churchease during the
                trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Absolutely! You can add team members and collaborate seamlessly
                during your free trial.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Can I upgrade or downgrade my plan after the trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, you can easily upgrade or downgrade your subscription plan
                based on your needs.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Are there any limitations on the features during the free trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                No, you will have access to all the advanced features and
                functionalities without any limitations.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>
                How secure is my data during the trial?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Your data security is our top priority. All data is encrypted
                and securely stored.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Row>
    </>
  );
};

export default FaqFree;
