import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import QRCode from "qrcode.react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AddShareLink = ({ show, setShow, onSuccessfulDelete }) => {
  const { logoutUser, user } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) logoutUser();
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);

  const [sessionId, setSessionId] = useState(null);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [error, setError] = useState(null);
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [hasActiveSession, setHasActiveSession] = useState(false);

  const baseURL = "https://churchease.com/join";

  const startRegistrationSession = async () => {
    try {
      const response = await authFetch.post("/registration-sessions", {
        churchDomain: user.churchDomain,
        duration: 30,
      });
      const { sessionToken, expiresAt } = response.data;
      setSessionId(sessionToken);
      setIsSessionActive(true);
      updateTimeRemaining(expiresAt);
    } catch (error) {
      setError("Failed to start registration session");
    }
  };

  const endRegistrationSession = async () => {
    if (!sessionId) return;
    try {
      await authFetch.post(`/registration-sessions/${sessionId}/end`);
      setIsSessionActive(false);
      setSessionId(null);
      setHasActiveSession(false);
    } catch (error) {
      setError("Failed to end session");
    }
  };

  const extendSession = async () => {
    try {
      const response = await authFetch.post(
        `/registration-sessions/${sessionId}/extend`,
        { additionalMinutes: 15 }
      );
      const { expiresAt } = response.data;
      updateTimeRemaining(expiresAt);
    } catch (error) {
      setError("Failed to extend session");
    }
  };

  const updateTimeRemaining = (expiresAt) => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const expiry = new Date(expiresAt).getTime();
      const diff = expiry - now;

      if (diff <= 0) {
        setTimeRemaining({ minutes: 0, seconds: 0 });
        setIsSessionActive(false);
        setSessionId(null);
        setHasActiveSession(false);
        return;
      }

      const minutes = Math.floor(diff / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeRemaining({ minutes, seconds });
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);
    return () => clearInterval(timer);
  };

  const checkActiveSession = async () => {
    try {
      const response = await authFetch.get("/registration-sessions/active");
      if (response.data.hasActiveSession) {
        console.log("Active session found");
        setHasActiveSession(true);
        setSessionId(response.data.sessionToken);
      }
    } catch (error) {
      console.error("Failed to check active session");
    }
  };

  const getActiveSessionDetails = async () => {
    try {
      const response = await authFetch.get(
        `/registration-sessions/${sessionId}/status`
      );

      console.log(response.data);
      const { registrationCount, expiresAt, isActive } = response.data.status;

      setRegistrationCount(registrationCount);
      setIsSessionActive(isActive);
      updateTimeRemaining(expiresAt);
    } catch (error) {
      console.error("Failed to fetch session details");
      setError("Failed to load session details");
    }
  };

  useEffect(() => {
    if (show) {
      checkActiveSession();
    }
  }, [show]);

  useEffect(() => {
    if (!sessionId) return;
    const pollCount = setInterval(async () => {
      try {
        const response = await authFetch.get(
          `/registration-sessions/${sessionId}/status`
        );
        setRegistrationCount(response.data.registrationCount);
      } catch (error) {
        console.error("Failed to fetch registration count");
      }
    }, 5000);

    return () => clearInterval(pollCount);
  }, [sessionId]);

  const getRegistrationUrl = () =>
    sessionId ? `${baseURL}/${user.churchDomain}/${sessionId}` : "";

  const handleCopy = () => {
    setCopiedUrl(true);
    setTimeout(() => setCopiedUrl(false), 2000);
  };

  const formatTime = (minutes, seconds) => {
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registration Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isSessionActive ? (
          <div className="text-center">
            <button
              className="jiluil3"
              onClick={
                hasActiveSession
                  ? getActiveSessionDetails
                  : startRegistrationSession
              }
            >
              {hasActiveSession
                ? "View Active Session"
                : "Start Welcome Session"}
            </button>
          </div>
        ) : (
          <>
            {copiedUrl && <div className="inlinesuccess">Copied!</div>}

            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span>
                  Time Remaining:{" "}
                  {formatTime(timeRemaining.minutes, timeRemaining.seconds)}
                </span>
                <button className="jiluil" onClick={extendSession}>
                  +15 Minutes
                </button>
              </div>
              <div>Registrations: {registrationCount}</div>
            </div>

            <Form.Label>Registration Link</Form.Label>
            <div className="flex flex-row gap-3">
              <Form.Control type="text" value={getRegistrationUrl()} disabled />
              <CopyToClipboard text={getRegistrationUrl()} onCopy={handleCopy}>
                <button className="jiluil">Copy</button>
              </CopyToClipboard>
            </div>

            <div className="mt-3 mb-3">
              <QRCode value={getRegistrationUrl()} />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isSessionActive && (
          <button className="jiluil" onClick={endRegistrationSession}>
            End Session
          </button>
        )}
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddShareLink;
