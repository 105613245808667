import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert/Alert";
import "./addtag.css";

const AddMultiple = ({ show, setShow, username }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { quickAddToContainer, getContainers, containers } = useAppContext();
  const [tag, setTag] = useState("");

  useEffect(() => {
    getContainers();
  }, []);

  const fullNames = username.map(
    (user) => `${user.mfirstName} ${user.mlastName}`
  );

  const ids = username.map((user) => `${user._id}`);

  const handleSave = () => {
    const newTag = {
      id: tag,
      data: ids,
    };

    quickAddToContainer(newTag);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Group</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* {notice && <p className="inlinesuccess">Member added to Group!</p>} */}
        <Form.Label htmlFor="inputPassword5">Select Group</Form.Label>
        <Form.Select
          aria-label="Default select example"
          className="my-3"
          onChange={(e) => setTag(e.target.value)}
        >
          <option>Select a Group</option>
          {containers.map((container) => (
            <option value={container._id} key={container._id}>
              {container.name}
            </option>
          ))}
        </Form.Select>

        <p className="my-3 text-bold">{fullNames.length} members selected</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button onClick={handleSave} className="jiluil">
          Add to Group
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMultiple;
