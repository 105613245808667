import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import blankImage from "../../assets/images/blank2.png";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert";
import { SiQuickbooks, SiMailchimp } from "react-icons/si";
import axios from "axios";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import { ChromePicker } from "react-color";
import { FaPlus } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Profilez from "../../assets/images/blank2.png";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const Customize = ({ show, setShow }) => {
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    user,
    logoutUser,
    updateColor,
    primaryColor,
    secondaryColor,
    accentColor,
    churchLogo,
    updateUser,
  } = useAppContext();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
  const [displayColorPicker3, setDisplayColorPicker3] = useState(false);
  const [primary, setPrimary] = useState(user.primaryColor);
  const [secondary, setSecondary] = useState(user.secondaryColor);
  const [accent, setAccent] = useState(user.accentColor);
  const [uploadButtonTextLogo, setUploadButtonTextLogo] =
    useState("Upload Image");
  const [previewLogo, setPreviewLogo] = useState("");
  const [imageLogo, setImageLogo] = useState(user.churchLogo);

  useEffect(() => {
    setPrimary(user.primaryColor);
    setSecondary(user.secondaryColor);
    setAccent(user.accentColor);
  }, [user.primaryColor, user.secondaryColor, user.accentColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClick2 = () => {
    setDisplayColorPicker2(!displayColorPicker2);
  };

  const handleClick3 = () => {
    setDisplayColorPicker3(!displayColorPicker3);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClose2 = () => {
    setDisplayColorPicker2(false);
  };

  const handleClose3 = () => {
    setDisplayColorPicker3(false);
  };

  const handleChange = async (color, type) => {
    const newColor = color.hex;
    // console.log(`Updating ${type} to ${newColor}`);
    // Update the state based on the type
    if (type === "primary") {
      setPrimary(newColor);
    }

    // Update the CSS variable
    document.documentElement.style.setProperty(`--primary-color`, newColor);
  };

  const handleChange2 = async (color, type) => {
    const newColor = color.hex;
    // console.log(`Updating ${type} to ${newColor}`);
    // Update the state based on the type
    if (type === "secondary") {
      setSecondary(newColor);
    }

    // Update the CSS variable
    document.documentElement.style.setProperty(`--secondary-color`, newColor);
  };

  const handleChange3 = async (color, type) => {
    const newColor = color.hex;

    if (type === "accent") {
      setAccent(newColor);
    }

    // Update the CSS variable
    document.documentElement.style.setProperty(`--accent-color`, newColor);
  };
  //console.log(user);
  const handleUpdate = async () => {
    const data = {
      userId: user._id,
      colorType: "primaryColor",
      colorValue: primary,
    };
    updateColor({ data });
  };

  const handleUpdate2 = async () => {
    const data = {
      userId: user._id,
      colorType: "secondaryColor",
      colorValue: secondary,
    };

    updateColor({ data });
  };

  const handleUpdate3 = async () => {
    const data = {
      userId: user._id,
      colorType: "accentColor",
      colorValue: accent,
    };
    updateColor({ data });
  };

  const handleImageLogo = (e) => {
    let file = e.target.files[0];
    setPreviewLogo(window.URL.createObjectURL(file));
    setUploadButtonTextLogo(file.name);
    //setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 520, 300, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await authFetch.post("/members/logo/uploadImageLogo", {
          image: uri,
        });

        setImageLogo(data);
      } catch (err) {}
    });
  };

  const handleImageRemoveLogo = async () => {
    try {
      // Convert blankImage to a file object
      const response = await fetch(blankImage);
      const blob = await response.blob();
      const file = new File([blob], "blank.png", { type: "image/png" });

      // Resize blankImage
      Resizer.imageFileResizer(
        file,
        520,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            // Send both the current imageLogo and the resized blankImage to the backend
            await authFetch.post("/members/remove-image-logo", {
              imageLogo,
              blankImage: uri,
            });

            // Set the default blank image as the new logo
            setImageLogo(uri);
            setPreviewLogo(uri);

            toast(
              <CustomToastContent
                title="Success"
                message="Operation Successful!"
              />
            );
          } catch (err) {
            console.error("Error in handleImageRemoveLogo function:", err);
            toast(
              <CustomToastContent title="Error" message="Operation Failed!" />
            );
          }
        },
        "base64"
      );
    } catch (err) {
      console.error("Error converting blank image:", err);
    }
  };

  const uploadImage = (e) => {
    e.preventDefault();

    const currentUser = {
      churchName: user.churchName,
      churchLogo: imageLogo,
    };

    updateUser({ currentUser });
    toast(
      <CustomToastContent title="Success" message="Operation Successful!" />
    );
  };

  const [fullscreen, setFullscreen] = useState(true);

  const handleClosex = () => setShow(false);
  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClosex}>
      <Modal.Header closeButton>
        <Modal.Title>Church Branding</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <>
          <Container fluid>
            {showAlert && <Alert />}

            <div className="flex justify-center flex-column pt-5"></div>

            <Row className="fundc2 flex items-center justify-center">
              <p style={{ fontSize: "1.7em", marginBottom: "1.2em" }}>
                Church Identity
              </p>
              <Col>
                <div className="cusbb">
                  <div
                    style={{
                      backgroundColor: primary,
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      flexDirection: "column",
                    }}
                    className="btest flex justify-center items-center"
                  >
                    <p style={{ marginBottom: "0px", fontWeight: "600" }}>
                      Primary Color
                    </p>{" "}
                    <p style={{ marginBottom: "0px" }}>{primary}</p>
                  </div>
                  <div className="pt-4 pb-4 flex gap-3">
                    <div>
                      <button
                        className="jiluil"
                        style={{ backgroundColor: primaryColor }}
                        onClick={handleClick}
                      >
                        Select Color
                      </button>
                      {displayColorPicker ? (
                        <div className="popover">
                          <div className="cover" onClick={handleClose} />
                          <ChromePicker
                            color={primary}
                            onChangeComplete={(color) =>
                              handleChange(color, "primary")
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        onClick={handleUpdate}
                        className="jiluil4"
                        style={{ backgroundColor: "#858484", color: "#fff" }}
                      >
                        Add Color
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="cusbb">
                  <div
                    style={{
                      backgroundColor: secondary,
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      flexDirection: "column",
                    }}
                    className="btest flex justify-center items-center"
                  >
                    <p style={{ marginBottom: "0px", fontWeight: "600" }}>
                      Secondary Color
                    </p>{" "}
                    <p style={{ marginBottom: "0px" }}>{secondary}</p>
                  </div>
                  <div className="pt-4 pb-4 flex gap-3">
                    <div>
                      <button
                        className="jiluil4"
                        style={{ backgroundColor: secondaryColor }}
                        onClick={handleClick2}
                      >
                        Select Color
                      </button>
                      {displayColorPicker2 ? (
                        <div className="popover">
                          <div className="cover" onClick={handleClose2}></div>
                          <ChromePicker
                            color={secondary}
                            onChangeComplete={(color) =>
                              handleChange2(color, "secondary")
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        onClick={handleUpdate2}
                        className="jiluil4"
                        style={{ backgroundColor: "#858484", color: "#fff" }}
                      >
                        Add Color
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="cusbb">
                  <div
                    style={{
                      backgroundColor: accent,
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      flexDirection: "column",
                    }}
                    className="btest flex justify-center items-center "
                  >
                    <p style={{ marginBottom: "0px", fontWeight: "600" }}>
                      Accent Color
                    </p>{" "}
                    <p style={{ marginBottom: "0px" }}>{accent}</p>
                  </div>
                  <div className="pt-4 pb-4 flex gap-3">
                    <div>
                      <button
                        className="jiluil5"
                        style={{ backgroundColor: accentColor }}
                        onClick={handleClick3}
                      >
                        Select Color
                      </button>
                      {displayColorPicker3 ? (
                        <div className="popover">
                          <div className="cover" onClick={handleClose3} />

                          <ChromePicker
                            color={accent}
                            onChangeComplete={(color) =>
                              handleChange3(color, "accent")
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        onClick={handleUpdate3}
                        className="jiluil4"
                        style={{ backgroundColor: "#858484", color: "#fff" }}
                      >
                        Add Color
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <hr />
            <Row className="fundc2 flex items-center justify-center mb-5">
              <p style={{ fontSize: "1.7em" }}>Church Logo</p>
              <Col>
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    width={100}
                    height={100}
                    src={
                      user?.churchLogo?.Location
                        ? user?.churchLogo?.Location
                        : Profilez
                    }
                    roundedCircle
                  />
                  <div className="flex flex-row gap-3 mt-4">
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="imageLogoInput"
                        className="hed btn btn-outline-secondary btn-block"
                      >
                        Select Logo
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="imageLogoInput"
                        name="imageLogo"
                        onChange={handleImageLogo}
                        accept="image/*"
                        className="formvalue"
                        hidden
                      />
                    </Form.Group>
                    <button
                      className="hed btn btn-outline-secondary btn-block"
                      onClick={handleImageRemoveLogo}
                    >
                      Remove Logo
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="flex flex-column items-start gap-3">
                  {previewLogo && (
                    <>
                      <p style={{ fontWeight: "600", fontSize: "1.3em" }}>
                        Preview Current Church Logo
                      </p>
                      <div>
                        <Image
                          width={150}
                          height={150}
                          src={previewLogo}
                          roundedCircle
                        />
                      </div>

                      <div className="flex justify-center gap-3">
                        {" "}
                        <button className="jiluil" onClick={uploadImage}>
                          Update
                        </button>
                        <button
                          className="jiluil3"
                          onClick={handleImageRemoveLogo}
                        >
                          Remove Image
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClosex}>
          Close
        </button>
        {/* <button className="jiluil" onClick={handleSubmit}>
          Update
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default Customize;
