import React from "react";
import k13 from "../../assets/images/k13.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import myLogo from "../../assets/images/myLogo.png";
import "./mobile.css";

const MobileInfo = () => {
  const navigate = useNavigate();

  const handleNav = () => {
    navigate("/");
  };
  return (
    <Container>
      <Row className="momo">
        <img src={k13} className="ewennn mb-3" alt="Mobile Info" />
        <p className="plpll mb-4">
          To get the best user experience, we recommend you access the web app
          from a computer. Our mobile app is still in development.
        </p>
        <button className="jiluil3" onClick={handleNav}>
          Start on your computer
        </button>
      </Row>
      <div>
        <img src={myLogo} alt="logo" className="oomojj" />
      </div>
    </Container>
  );
};

export default MobileInfo;
