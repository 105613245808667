import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import moment from "moment-timezone";
import "./profile.css";

import { useAppContext } from "../../context/appContext";
import Modal from "react-bootstrap/Modal";
import Loading from "../../components/Loading/Loading";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

import { Nav, Table, Col } from "react-bootstrap";

const Campuses = ({ show4, setShow4 }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const { getSavedView, savedViews, deleteView, isLoading, logoutUser } =
    useAppContext();

  const [show, setShow] = useState(false);
  const [clickedMember, setClickedMember] = useState([]);
  const [savedView, setSavedView] = useState([]);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [campus, setCampus] = useState([]);

  useEffect(() => {
    getCampus();
  }, []);

  const getCampus = async () => {
    const { data } = await authFetch.get("/auth/getcampus");

    setCampus(data.campuses);
  };

  const handleClose = () => setShow(false);
  const handleClose4 = () => setShow4(false);

  const handleShow = (event, saved) => {
    setShow(true);
    setClickedMember(saved);
  };

  const deleteThisCampus = () => {
    try {
      const deleteCampus = async () => {
        const { data } = await authFetch.delete(
          `/auth/deletecampus/${clickedMember._id}`
        );
        console.log(data);
        handleClose();
        getCampus();
      };
      deleteCampus();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSavedView();
  }, []);

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...savedView].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setSavedView(sorted);
    } else {
      let sorted = [...savedView].reverse();
      setSavedView(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...savedView].sort((a, b) => {
      if (dateFlag) {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      }
    });
    setSavedView(sorted);
    setDateFlag(!dateFlag);
  };

  const createCampus = () => {
    navigate("/auth/churchcampus");
  };
  const [fullscreen, setFullscreen] = useState(true);
  return (
    <>
      <Modal show={show4} fullscreen={fullscreen} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Church Campuses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div
              className="flex align-center justify-center flex-column w-100 pt-5"
              style={{ textAlign: "center" }}
            >
              <p className="qubulkf2">
                Check our{" "}
                <a
                  href="https://churchease.com/knowledgebaseview/66718384c2067200570609ac"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "var(--secondary-color)",
                    textDecoration: "none",
                  }}
                >
                  knowledge base
                </a>{" "}
                to learn more about streamlining multi-location church
                operations. managing your church's monthly sms usage.
              </p>
            </div>
          </Row>
          <Row className="sssssssecwef">
            <div className="rt6hi8aaaa">
              <Button className="wefwefrrg" onClick={createCampus}>
                Add New Campus
              </Button>
            </div>
          </Row>

          {campus && campus.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "100%",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {campus.length ? (
                  <p>
                    {campus.length} record{campus.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Campus Created</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th onClick={handleViewName}>
                      Campus Name{" "}
                      {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th>Address</th>
                    <th className="flex items-center justify-center gap-3">
                      Action
                    </th>
                  </tr>
                </thead>
                {campus.map((campuses, index) => (
                  <tbody>
                    <tr key={index} className="trdata">
                      <td>
                        <p>{campuses.campusName}</p>
                      </td>
                      <td>
                        {campuses.address},<br />
                        {campuses.city}, {campuses.state} {campuses.zipCode}
                      </td>

                      <td>
                        <div className="flex items-center justify-center gap-3">
                          <button
                            className="jiluil"
                            onClick={(event) => handleShow(event, campuses)}
                          >
                            Delete
                          </button>
                          <Link to={"/auth/campusedit/" + campuses._id}>
                            <button className="jiluil3">Edit</button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose4}>
            Close
          </button>
          {/* <button className="jiluil3" onClick={deleteThisCampus}>
            Delete
          </button> */}
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this view. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={deleteThisCampus}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Campuses;
