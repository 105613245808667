import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import axios from "axios";

const DeleteCalendar = ({
  show3,
  setShow3,
  selectedValue,
  refreshWorkflows,
  setShowEventModal,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const { logoutUser, deleteEvent } = useAppContext();

  const handleDelete = async () => {
    const response = await authFetch.delete(`/workflow/${selectedValue._id}`);
    refreshWorkflows();
    handleClose3(); // Assuming this is for closing a modal or similar
  };

  return (
    <Modal show={show3} onHide={handleClose3}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This action will permanently delete the selected members.
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose3}>
          Close
        </button>
        <button
          className="jiluil3"
          onClick={() => {
            deleteEvent(selectedValue._id, {
              alertText: "Event Deleted",
            });
            setShowEventModal(false);
            handleClose3();
          }}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCalendar;
