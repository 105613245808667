import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import CheckoutForm from "../../../components/CheckoutForm/CheckoutForm";
import { useLocation } from "react-router-dom";
import "./pricinglist.css";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import {
  FaBible,
  FaCalculator,
  FaCalendar,
  FaChartPie,
  FaCheck,
  FaChess,
  FaChild,
  FaEnvelope,
  FaFont,
  FaMapMarked,
  FaMicrophone,
  FaMoneyBill,
  FaPen,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
} from "react-icons/fa";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const allFeatures = [
  {
    name: "People",
    cost: 0,
    description: "Manage church member details and records.",
    image: <FaUserAlt />,
  },
  {
    name: "Church Giving",
    cost: 9,
    description: "Facilitate tithes, offerings, and donations digitally.",
    image: <FaMoneyBill />,
  },
  {
    name: "Church Accounting",
    cost: 7,
    description:
      "Manage your church’s finances with ease using our Church Accounting feature",
    image: <FaCalculator />,
  },
  {
    name: "Ministry",
    cost: 6,
    description: "Tools for managing various ministries within the church.",
    image: <FaPeopleArrows />,
  },
  {
    name: "Groups",
    cost: 8,
    description: "Organize and manage small groups for better engagement.",
    image: <FaUserFriends />,
  },
  {
    name: "Follow-Up",
    cost: 6,
    description: "Track and follow up with visitors and members.",
    image: <FaRunning />,
  },
  {
    name: "Events",
    cost: 8,
    description: "Plan and manage church events seamlessly.",
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",
    cost: 5,
    description:
      "Schedule and plan services, including volunteers and resources.",
    image: <FaCheck />,
  },
  {
    name: "Forms",
    cost: 7,
    description: "Create custom forms for surveys, signups, and more.",
    image: <FaFont />,
  },
  {
    name: "Fellowship",
    cost: 6,
    description:
      "Enhance the sense of community and fellowship within the church.",
    image: <FaPray />,
  },
  {
    name: "Learning Center",
    cost: 10,
    description: "Educational tools for discipleship and learning.",
    image: <FaEnvelope />,
  },
  {
    name: "Live Streaming",
    cost: 7,
    description:
      "Broadcast your services and events live to reach a wider audience.",
    image: <FaVideo />,
  },

  {
    name: "Communication",
    cost: 5,
    description:
      "Comprehensive tools for effective communication within the church community.",
    image: <FaTextHeight />,
  },
  {
    name: "Children",
    cost: 9,
    description:
      "Streamline children's management and development with automated, round-the-clock support.",
    image: <FaChild />,
  },
];

const levelWeights = {
  L1: 0.4,
  L2: 0.5,
  L3: 0.65,
  L4: 0.85,
  L5: 1.2,
  L6: 1.4,
  L7: 1.8,
  L8: 2.0,
};

const NewCheckoutPage = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { user, logoutUser } = useAppContext();
  const [userLevel, setUserLevel] = useState("");
  const [billing, setBilling] = useState("");
  const [selectedEntitlements, setSelectedEntitlements] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (user) {
      setSelectedEntitlements(user.entitlement);
      setUserLevel(user.churchSize);
      setBilling(user.billing);
    }
  }, [user, logoutUser]);

  useEffect(() => {
    const totalCost = selectedEntitlements.reduce((acc, entitlement) => {
      const feature = allFeatures.find(
        (feature) => feature.name === entitlement
      );
      return acc + (feature ? feature.cost : 0);
    }, 0);

    const weight = levelWeights[userLevel] || 0; // Use the level from props or state

    if (billing === "monthly") {
      console.log(totalCost, weight);
      setTotalPrice(totalCost * weight);
    }
    if (billing === "annual") {
      setTotalPrice(totalCost * weight * 12 * 0.95);
    }
    if (billing === "twoyears") {
      setTotalPrice(totalCost * weight * 24 * 0.9);
    }
  }, [userLevel]); // Recalculate when userLevel changes

  return (
    <Container fluid>
      <Row>
        <div className="pricingbody22">
          <p className="pricingb">Complete your payment</p>
        </div>
      </Row>
      <Row className="flex items-center justify-center ">
        <div className="flex flex-column checkouta">
          <Elements stripe={stripePromise}>
            <CheckoutForm price={totalPrice.toFixed(2)} />
          </Elements>
        </div>
      </Row>
    </Container>
  );
};

export default NewCheckoutPage;
