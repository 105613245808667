import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CustomAlert from "../../../components/CustomAlert/CustomAlert.js";
import "../../../pages/dashboard/Profile/profile.css";

import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert.js";
import { Form } from "react-bootstrap";

const ChangePassword = () => {
  const { isLoading, showAlert, updatePassword } = useAppContext();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [renewPassword, reNewPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !renewPassword) {
      setIsError(true);
      return;
    }

    if (newPassword !== renewPassword) {
      setError(true);
      return;
    }

    const userPassword = {
      password: currentPassword,
      newPassword: newPassword,
    };

    updatePassword({
      userPassword,
    });

    setCurrentPassword("");
    setNewPassword("");
    reNewPassword("");
  };

  return (
    <>
      <Container fluid className="container2">
        <Row className="headerbanner">
          <div className="bannerbig">Change Password</div>
        </Row>
        <Row className="body">
          <Col>
            <div className="register leftgivediv">
              <Form className="srgerged">
                <br></br>
                {isError && (
                  <CustomAlert
                    alertText="All fields are required."
                    alertType="danger"
                  />
                )}
                {error && (
                  <CustomAlert
                    alertText="Password does not match."
                    alertType="danger"
                  />
                )}
                {showAlert && <Alert />}
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "600" }}>
                    Current Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="forminput"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "600" }}>
                    New Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="forminput"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "600" }}>
                    Reenter Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="renewPassword"
                    name="renewPassword"
                    value={renewPassword}
                    onChange={(e) => reNewPassword(e.target.value)}
                    className="forminput"
                  />
                </Form.Group>

                <button
                  type="button"
                  className="wedweded"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Change Password
                </button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
