import React, { useState, useEffect } from "react";
import "./blog.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";

const Blog = () => {
  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [posts, setPost] = useState([]);

  const fetchPosts = async () => {
    try {
      const { data } = await authFetch.get(`/unblog/getbloglimit`);
      setPost(data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <Row
        className="deliverbg1234x container22"
        style={{ backgroundColor: "white" }}
      >
        <div className="delivermainbgx2x">
          <span style={{ color: "var(--primary-color)" }}>
            Stay Informed with
          </span>{" "}
          <span style={{ color: "var(--primary-color)", fontWeight: "bold" }}>
            Churchease Blog
          </span>
        </div>

        <div
          className="delivermainbg2x2x"
          style={{ fontWeight: "bold", color: "#FE7064" }}
        >
          Explore the Latest Church Tech News and Updates.
        </div>
      </Row>
      <Row className="bloguna">
        {posts &&
          posts.map((post, index) => (
            <Col sm={6} md={4} lg={4} key={index} className="mb-5 bloguss">
              <div className="d-flex flex-row  items-start gap-2 h-100 blogboduu">
                <div className="flex flex-column justify-between gap-4">
                  <Link to={"/blogview/" + post.slug}>
                    <img
                      src={post?.image ? post?.image?.Location : "/course.png"}
                      alt="Blog Description"
                      className="blogImagemn"
                    />
                  </Link>
                  <Link to={"/blogview/" + post.slug} className="yj7y">
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <p className="bpadins">{post?.category?.toUpperCase()}</p>
                      <p>
                        <Card.Title
                          className="bloguna22"
                          style={{
                            wordWrap: "break-word",
                            lineHeight: "1.5",
                          }}
                        >
                          {post.blogTitle}
                        </Card.Title>
                      </p>
                      <p>
                        <Card.Text
                          style={{
                            wordWrap: "break-word",
                            lineHeight: "1.5",
                          }}
                        >
                          {post.homeIntro.substring(0, 150) + "..."}
                        </Card.Text>
                      </p>
                      <p className="smalltextblog mb-5">
                        {(post.blogDescription.length / 2500).toFixed(0)} MINS
                        READ
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
          ))}

        <div
          style={{
            textAlign: "center",
            paddingTop: "2rem",
            marginBottom: "2rem",
            width: "100%",
          }}
        >
          <Link to="/blog">
            <button className="rtyikuy">Churchease Blog</button>
          </Link>
        </div>
      </Row>
    </>
  );
};

export default Blog;
