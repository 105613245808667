import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const PublishLivestreamRecent = ({
  show,
  setShow,
  pickedRecentLiveStream,
  publishLivestreamRecent,
  unpublishLivestreamRecent,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [liveStreams, setLiveStreams] = useState([]);

  //console.log(pickedRecentLiveStream);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/livestream/recent/data`);

    setLiveStreams(data);
  };

  const handlePublish = async () => {
    try {
      const response = await authFetch.put(
        `/livestream/recent/publish/${pickedRecentLiveStream._id}`
      );
      if (response.status === 200 || response.status === 201) {
        publishLivestreamRecent();
        loadCourse();
        handleClose5();
        toast(
          <CustomToastContent title="Success" message="Publish Successful!" />
        );
      }

      //publishLivestream();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      const response = await authFetch.put(
        `/livestream/recent/unpublish/${pickedRecentLiveStream._id}`
      );

      if (response.status === 200 || response.status === 201) {
        unpublishLivestreamRecent();
        loadCourse();
        handleClose5();
        toast(
          <CustomToastContent title="Success" message="Unpublish Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose5 = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          {pickedRecentLiveStream?.published
            ? "Unpublish Livestream"
            : "Publish Livestream"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        {pickedRecentLiveStream?.published
          ? "You are about to unpublish this livestream. Do you want to continue?"
          : "You are about to publish this livestream. Do you want to continue?"}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose5} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            pickedRecentLiveStream.published
              ? handleUnpublish(pickedRecentLiveStream)
              : handlePublish(pickedRecentLiveStream);
            handleClose5();
          }}
          className="jiluil"
        >
          {pickedRecentLiveStream?.published ? "Unpublish" : "Publish"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishLivestreamRecent;
