import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import "./addworkflow.css";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const EditWorkflow = ({ show3, setShow3, refreshWorkflows, selectedValue }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [uniqueWorkflow, setUniuqueWorkFlow] = useState(null);

  const fetchUniqueWorkflow = async () => {
    try {
      const response = await authFetch.get(
        `/workflow/unique/${selectedValue?._id}`
      );

      setUniuqueWorkFlow(response.data);
      refreshWorkflows();
    } catch (err) {
      console.error("Error in deleting workflow:", err);
    }
  };

  useEffect(() => {
    if (selectedValue) {
      fetchUniqueWorkflow();
    }
  }, [selectedValue]);

  useEffect(() => {
    if (uniqueWorkflow) {
      setWorkflow(uniqueWorkflow.name);
      setWorkflowDescription(uniqueWorkflow.description);
    }
  }, [uniqueWorkflow]);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const { addWorkflow, logoutUser } = useAppContext();
  const [workflow, setWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [error, setError] = useState(false);

  const handleEdit = async () => {
    if (!workflow) {
      setError(true);
      return;
    }

    const workflowData = {
      name: workflow,
      description: workflowDescription,
    };

    try {
      const response = await authFetch.patch(`/workflow/${selectedValue._id}`, {
        workflowData,
      });
      if (response.status === 200 || response.status === 201) {
        refreshWorkflows();
        handleClose3();
        toast(
          <CustomToastContent title="Success" message="Update Successful!" />
        );
      }
    } catch (err) {
      console.error("Error in deleting workflow:", err);
    }
  };
  return (
    <Modal show={show3} onHide={handleClose3}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label htmlFor="inputPassword5">Workflow Name</Form.Label>
        <Form.Control
          type="text"
          value={workflow}
          onChange={(e) => setWorkflow(e.target.value)}
          required
        />
        <Form.Label className="mt-3" htmlFor="inputPassword5">
          Description (optional)
        </Form.Label>
        <Form.Control
          type="text"
          value={workflowDescription}
          onChange={(e) => setWorkflowDescription(e.target.value)}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose3}>
          Close
        </button>
        <button className="jiluil" onClick={handleEdit}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditWorkflow;
