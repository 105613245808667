import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaBible } from "react-icons/fa";
import m21 from "../../assets/newi/m21.png";
import m22 from "../../assets/newi/m22.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductMinistry = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Ministry Management | Churchease - Enhance Ministry Effectiveness
        </title>
        <meta
          name="description"
          content="Optimize your ministry activities with Churchease. Schedule, coordinate, and engage your community with our comprehensive tool, ensuring well-managed and impactful operations."
        />
        <link rel="canonical" href="https://churchease.com/product/ministry" />
        <meta
          property="og:title"
          content="Ministry Management | Churchease - Enhance Ministry Effectiveness"
        />
        <meta
          property="og:description"
          content="Optimize your ministry activities with Churchease. Schedule, coordinate, and engage your community with our comprehensive tool, ensuring well-managed and impactful operations."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/ministry"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#F2CA50" }}>
            <div>
              <FaBible size={100} />
              <p className="headssc">Minstry</p>
              <p className="headpp">
                Optimize your ministry activities with our comprehensive tool.
                Schedule, coordinate, and engage your community through
                streamlined operations. This system enhances ministry
                effectiveness, ensuring all activities are well-managed and
                impactful.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for ministry coordination
            </p>
            <p className="product13">
              Your central hub to streamline ministry tasks and enhance
              engagement, ensuring every operation is impactful.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={m21} alt="Ministry" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">MINISTRY ORGANIZATION</p>
              <p className="proddz1">
                Efficiently manage and organize different church ministries,
                providing a structured overview of each ministry's function and
                needs.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MINISTRY LISTING</p>
              <p className="proddz1">
                Maintain a comprehensive list of all ministries and volunteers,
                ensuring every group is accessible and accounted for.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACTIVITY PLANNING</p>
              <p className="proddz1">
                Plan and schedule ministry activities with precision, allowing
                for effective organization and participation.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">LEADERSHIP ASSIGNMENT</p>
              <p className="proddz1">
                Assign and manage ministry leaders, empowering them with the
                tools and information needed to lead their teams successfully.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">TARGETED COMMUNICATIONS</p>
              <p className="proddz1">
                Facilitate direct and effective communication with and between
                ministries to enhance collaboration and information sharing.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">VOLUNTEER MANAGEMENT</p>
              <p className="proddz1">
                Track and manage volunteer participation within ministries,
                aligning skills and availability with ministry needs.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={m22} alt="Ministry" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#F2CA50" />
      <Footer />
    </>
  );
};

export default ProductMinistry;
