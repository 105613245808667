import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert/Alert";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";

const DeleteFolderModal = ({
  showFolderModal,
  setShowFolderModal,
  folderDelete,
  folderId,
}) => {
  const { getRootFolder, getFolder, showAlert, deleteFolder } = useAppContext();
  const handleClose = () => setShowFolderModal(false);
  const deleteItem = async () => {
    try {
      await deleteFolder(folderDelete);
      if (folderId) {
        await getFolder(folderId);
      } else {
        await getRootFolder();
      }
      handleClose();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Modal show={showFolderModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Folder
        </Modal.Title>
      </Modal.Header>
      {showAlert && <Alert />}
      <Modal.Body className="modal-wrapper__body">
        <Row>
          <Col md="12"></Col>
          <div class="jsx-parser">
            Do you really want to remove this folder?
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Cancel
        </button>
        <button
          onClick={deleteItem}
          // toggleDeleteModal();
          // await getRootFolder();

          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFolderModal;
