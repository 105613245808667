import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

import MemberNav from "../../../components/MemberNav/MemberNav";
import Row from "react-bootstrap/Row";
import { Outlet } from "react-router-dom";
import "./sharedlayout.css";

import { useAppContext } from "../../../context/appContext";
import axios from "axios";

import Sidebar from "../../../components/Sidebar/LatestSideBar";
import MemberFooter from "../../../components/MemberNav/MemberFooter";

const SharedLayout = () => {
  const { logoutUser, churchAccount, churchAccountData } = useAppContext();
  const [churchSettings, setChurchSettings] = useState({});

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // ewe
  // ewe
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    churchAccount();
  }, []);

  useEffect(() => {
    if (churchAccountData && churchAccountData?.churchAccount?.churchSettings) {
      setChurchSettings(churchAccountData?.churchAccount?.churchSettings);
    }
  }, [churchAccountData]);

  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

  // Function to toggle the sidebar's minimized status
  const toggleSidebar = () => {
    setIsSidebarMinimized(!isSidebarMinimized);
  };

  return (
    <>
      <MemberNav />
      <Container fluid className="sharcl">
        <Row>
          <Col
            md={isSidebarMinimized ? 1 : 3} // Smaller width when minimized
            className={`fixed-sidebar ${isSidebarMinimized ? "minimized" : ""}`}
          >
            <Sidebar
              onToggle={toggleSidebar}
              isMinimized={isSidebarMinimized}
            />
          </Col>
          <Col
            md={isSidebarMinimized ? 11 : 9}
            className="scrollable-right-content"
            style={{
              marginLeft: isSidebarMinimized ? "8em" : "17em",
              width: "80%", // Adjust based on the sidebar state
            }}
          >
            <Outlet />
            <div>
              <MemberFooter />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SharedLayout;
