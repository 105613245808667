import React from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import k1 from "../../assets/images/k1.jpg";
import k22 from "../../assets/images/k22.jpg";
import k3 from "../../assets/images/k3.jpg";
import "./efficiency.css";
import Col from "react-bootstrap/Col";
import dmockup from "../../assets/newi/dmockup.png";

const Efficiency = () => {
  const navigate = useNavigate();
  const handleDemo = () => {
    navigate("/demo");
  };

  return (
    <Row style={{ backgroundColor: "var(--accent-color)" }}>
      <div className="flex justify-center flex-column">
        <div
          className="flex justify-center align-center"
          style={{
            fontSize: "1.8em",
            color: "var(--primary-color)",
            textAlign: "center",
            paddingTop: "4rem",
            fontWeight: "600",
          }}
        >
          Discover The Convenience And Efficiency Of Churchease For Your
          Church's Success
        </div>
        <div
          className="flex justify-center"
          style={{ paddingBottom: "3rem", paddingTop: "3rem" }}
        >
          <a
            href="https://calendly.com/churchease/demo-churchease"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="rtyikuy"
              style={{
                backgroundColor: "var(--primary-color)",
              }}
            >
              Book a Demo
            </button>
          </a>
        </div>
        <div className="flex justify-center">
          <img src={dmockup} alt="Banner" className="logbann" />
        </div>
      </div>
    </Row>
    // </Container>
  );
};

export default Efficiency;
