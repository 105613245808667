import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";

const EntitlementManually = () => {
  const [churchName, setChurchName] = useState("");
  const [churchDomain, setChurchDomain] = useState("");
  const [entitlements, setEntitlements] = useState([]);
  const [newEntitlement, setNewEntitlement] = useState("");
  const [churchSize, setChurchSize] = useState("");

  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleSubmit = async () => {
    const menuData = {
      churchName,
      churchDomain,
      entitlements,
      churchSize,
    };

    try {
      const response = await authFetch.post(
        "/appadmin/addentitlement",
        menuData
      );
      if (response.status === 201 || response.status === 200) {
        setChurchName("");
        setChurchDomain("");
        setEntitlements([]);
        setChurchSize("");
        alert("Permission Added Successfully");
      }
    } catch (error) {
      logoutUser();
    }
  };

  const addEntitlement = () => {
    if (newEntitlement.trim() !== "") {
      setEntitlements([...entitlements, newEntitlement.trim()]);
      setNewEntitlement("");
    }
  };

  const removeEntitlement = (index) => {
    setEntitlements(entitlements.filter((_, i) => i !== index));
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formChurchName">
        <Form.Label>Church Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Church Name"
          value={churchName}
          onChange={(e) => setChurchName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formChurchDomain">
        <Form.Label>Church Domain</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Church Domain"
          value={churchDomain}
          onChange={(e) => setChurchDomain(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEntitlements">
        <Form.Label>Entitlements</Form.Label>
        <Form.Control
          type="text"
          placeholder="Add Entitlement"
          value={newEntitlement}
          onChange={(e) => setNewEntitlement(e.target.value)}
        />
        <Button variant="secondary" onClick={addEntitlement} className="mt-2">
          Add Entitlement
        </Button>
        <ul className="mt-2">
          {entitlements.map((entitlement, index) => (
            <li key={index}>
              {entitlement}{" "}
              <Button
                variant="danger"
                size="sm"
                onClick={() => removeEntitlement(index)}
              >
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formChurchSize">
        <Form.Label>Church Size</Form.Label>
        <Form.Control
          as="select"
          value={churchSize}
          onChange={(e) => setChurchSize(e.target.value)}
        >
          <option value="">Select Church Size</option>
          <option value="L2">L2</option>
          {/* <option value="medium">Medium</option>
          <option value="large">Large</option> */}
        </Form.Control>
      </Form.Group>

      <Button variant="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
  );
};

export default EntitlementManually;
