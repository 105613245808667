import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import axios from "axios";
import FollowUpx from "../../../components/FollowUp/FollowUpx";
import "./learning.css";

const AssignedCourse = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    uniquememberdetails,
    completemembers,
    getCompleteMembers,
    showAlert,

    deleteFollowUp,
    getAllSettings,
    displayAlert,
    setting,
    user,
    logoutUser,

    followups,
    getAllFollowUp,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
    getAllFollowUp();
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedEdit, setSelectedEdit] = useState([]);
  const handleShow1 = (item) => {
    setShow1(true);
    setSelectedValue(item);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  useEffect(() => {
    if (selectedEdit?.assignedCourses?.assigned) {
      setAssigned(selectedEdit?.assignedCourses?.assigned);
    }
    if (selectedEdit?.assignedCourses?.assignName) {
      setAssignName(selectedEdit?.assignedCourses?.assignName);
    }
    if (selectedEdit?.assignedCourses?.assignedBy) {
      setAssignedBy(selectedEdit?.assignedCourses?.assignedBy);
    }
    if (selectedEdit?.assignedCourses?.assignedDate) {
      setAssignedDate(selectedEdit?.assignedCourses?.assignedDate);
    }
    if (selectedEdit?.assignedCourses?.courseName) {
      setCourseName(selectedEdit?.assignedCourses?.courseName);
    }
    if (selectedEdit?.assignedCourses?.completionStatus) {
      setFollowCompletionStatus(
        selectedEdit?.assignedCourses?.completionStatus
      );
    }
    if (selectedEdit?.assignedCourses?.completed) {
      setCompleted(selectedEdit?.assignedCourses?.completed);
    }
  }, [selectedEdit]);

  const getAssignedCourse = async () => {
    try {
      const { data } = await authFetch(`/learning/get-assign-course`);

      if (data) {
        setSelectedEdit(data.assignedCourses);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const completionPercentage = selectedEdit.map((course) => {
    const percentCompleted =
      (course.lessons.length / course.lessonsLength) * 100;
    return {
      courseId: course.id,
      courseName: course.name,
      percentCompleted: `${percentCompleted}%`,
    };
  });

  // const getCompletionStatus = async () => {
  //   try {
  //     const { data } = await authFetch(`/learning/completionpercentage`);
  //     if (data) {
  //       setCompletionPercentage(data.completionp);
  //     }
  //   } catch (error) {
  //     logoutUser();
  //   }
  // };

  useEffect(() => {
    getAssignedCourse();
  }, []);
  const [assigned, setAssigned] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [assignName, setAssignName] = useState("");
  const [assignedBy, setAssignedBy] = useState("");
  const [assignedDate, setAssignedDate] = useState("");
  const [courseName, setCourseName] = useState("");
  const [completionStatus, setFollowCompletionStatus] = useState("");
  const [completed, setCompleted] = useState();

  const [dateFlag, setDateFlag] = useState(false);
  const [givingfilter, setGivingfilter] = useState([]);
  const [nameFlag, setNameFlag] = useState(true);
  const [viaFlag, setViaFlag] = useState(true);
  const [reasonFlag, setReasonFlag] = useState(true);
  const [assignedFlag, setAssignedFlag] = useState(true);
  const [targetDateFlag, setTargetDateFlag] = useState(true);
  const [statusFlag, setStatusFlag] = useState(true);

  // useEffect(() => {
  //   const filtered = selectedEdit.filter(
  //     (item) => item.assignedBy === `${user.mfirstName} ${user.mlastName}`
  //   );
  //   setFilteredData(filtered);
  // }, [followups, user.mfirstName, user.mlastName]);

  // console.log(filtered);

  // const myHandleSubmit = async (e) => {
  //   e.preventDefault();
  //   const [fname, lname] = followName?.split(" ");
  //   const { data } = await authFetch(
  //     `/members/getmemberidonly/${fname}/${lname}`
  //   );
  //   const [memberIdOnly] = data;

  //   const checkIt = {
  //     followUpName: followName,
  //     followUpAssignTo: followDesc,
  //     followupDate: followDate,
  //     churchName: user?.churchName,
  //     mfname: fname,
  //     mlname: lname,
  //     mId: memberIdOnly?._id,
  //     followUpCompleteUpdate: followComplete,
  //     followMedia: followHow,
  //     followReason: followReason,
  //     followNote: followNote,
  //     assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
  //   };

  //   try {
  //     await authFetch.post("/followup/assignto", checkIt);
  //     getAllFollowUp();
  //     setFollowName("");
  //     setFollowDesc("");
  //     setFollowDate("");
  //     setFollowComplete("");
  //     setFollowHow("");
  //     setFollowReason("");
  //     setFollowNote("");
  //     handleClose();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleDateSort = (e) => {
    let sorted = selectedEdit.sort((a, b) => {
      if (dateFlag) {
        return moment(b.postDate).unix() - moment(a.postDate).unix();
      } else {
        return moment(a.postDate).unix() - moment(b.postDate).unix();
      }
    });
    setGivingfilter(sorted);
    setDateFlag(!dateFlag);
  };

  const handleTargetDateSort = (e) => {
    let sorted = selectedEdit.sort((a, b) => {
      if (targetDateFlag) {
        return moment(b.followupDate).unix() - moment(a.followupDate).unix();
      } else {
        return moment(a.followupDate).unix() - moment(b.followupDate).unix();
      }
    });
    setGivingfilter(sorted);
    setTargetDateFlag(!targetDateFlag);
  };

  const handleNameSort = (e) => {
    if (nameFlag) {
      let sorted = selectedEdit.sort(function (a, b) {
        return a.assignName.localeCompare(b.assignName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setNameFlag(!nameFlag);
  };

  const handleViaSort = (e) => {
    if (viaFlag) {
      let sorted = selectedEdit.sort(function (a, b) {
        return a.assignedBy.localeCompare(b.assignedBy);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setViaFlag(!viaFlag);
  };

  const handleStatusSort = (e) => {
    if (statusFlag) {
      let sorted = selectedEdit.sort(function (a, b) {
        return a.courseName.localeCompare(b.courseName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setStatusFlag(!statusFlag);
  };

  // const handleStatusSort = (e) => {
  //   if (statusFlag) {
  //     let sorted = selectedEdit.sort(function (a, b) {
  //       return a.completed === b.completed ? 0 : a.completed ? -1 : 1;
  //     });
  //     setGivingfilter(sorted);
  //   } else {
  //     let sorted = givingfilter.reverse();
  //     setGivingfilter(sorted);
  //   }
  //   setStatusFlag(!statusFlag);
  // };

  const handleReasonSort = (e) => {
    if (reasonFlag) {
      let sorted = selectedEdit.sort(function (a, b) {
        return (a.followReason[0] || "").localeCompare(b.followReason[0] || "");
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setReasonFlag(!reasonFlag);
  };

  const handleAssignedSort = (e) => {
    if (assignedFlag) {
      let sorted = selectedEdit.sort(function (a, b) {
        return a.followUpAssignTo.localeCompare(b.followUpAssignTo);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setAssignedFlag(!assignedFlag);
  };
  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col>
            <p className="sahs">Assign Courses</p>
          </Col>
        </Row>

        <Row className="fundc2">
          {selectedEdit && selectedEdit.length > 0 ? (
            <Table hover responsive style={{ backgroundColor: "#F1F1F1" }}>
              <thead>
                <tr className="trdata">
                  <th onClick={handleDateSort} className="rwewwww">
                    Assigned Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleStatusSort} className="rwewwww">
                    Course Name {statusFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  {/* <th className="rwewwww">Assigned</th> */}
                  <th onClick={handleNameSort} className="rwewwww">
                    Assigned To {nameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>

                  <th onClick={handleViaSort} className="rwewwww">
                    Assigned By {viaFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th className="rwewwww">Completed</th>
                  <th className="rwewwww">Completion Status</th>
                  {/* <th onClick={handleReasonSort} className="rwewwww">
                    Reason {reasonFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleAssignedSort} className="rwewwww">
                    Assigned To {assignedFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleTargetDateSort} className="rwewwww">
                    Target Date{" "}
                    {targetDateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th>Note</th> */}

                  {/* <th>Actions</th> */}
                </tr>
              </thead>

              {selectedEdit &&
                selectedEdit?.map((item) => (
                  <tbody>
                    <tr key={item._id} className="trdata">
                      <td className="rwewwww">
                        {moment(item.createdAt).format("MMM DD, YYYY")}
                      </td>{" "}
                      <td className="rwewwww">{item.courseName}</td>
                      {/* <td className="rwewwww">{item.Type}</td> */}
                      <td className="rwewwww">{item.assignName}</td>
                      <td className="rwewwww">{item.assignedBy}</td>
                      <td className="rwewwww">
                        {(item.lessons.length / item.lessonsLength) * 100 ===
                        100
                          ? "Completed"
                          : "Not Completed"}
                      </td>
                      <td className="rwewwww">
                        {(item.lessons.length / item.lessonsLength) * 100}%
                      </td>
                      {/* <td className="rwewwww">
                        <button
                          className="wqwdttyqw3r4"
                          // onClick={() => handleShow2(item._id)}
                        >
                          Edit
                        </button>{" "}
                        <button
                          className="wqwdttyqw3r5"
                          onClick={() => handleShow1(item)}
                        >
                          Delete
                        </button>
                      </td> */}
                    </tr>
                  </tbody>
                ))}
            </Table>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
              {/* <button className="noDataButton" onClick={handleShow}>
                Create Follow Up
              </button> */}
            </div>
          )}{" "}
        </Row>
      </Container>

      {/* <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Edit Follow Up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Followup with</Form.Label>
              <Form.Control
                type="text"
                value={followName1}
                onChange={(e) => setFollowName1(e.target.value)}
                name="followName1"
                style={{ fontSize: "1em" }}
              />
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = followName1?.toLowerCase();
                    const fname = item.mfirstName?.toLowerCase();
                    const lname = item.mlastName?.toLowerCase();

                    const fullname = fname + lname;

                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        setFollowName1(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Assign to</Form.Label>
              <Form.Control
                type="text"
                value={followDesc1}
                onChange={(e) => setFollowDesc1(e.target.value)}
                name="followDesc1"
                style={{ fontSize: "1em" }}
              />
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = followDesc1?.toLowerCase();
                    const fname = item.mfirstName?.toLowerCase();
                    const lname = item.mlastName?.toLowerCase();

                    const fullname = fname + lname;

                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        setFollowDesc1(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check // prettier-ignore
                type="radio"
                label="Email"
                name="followHow1"
                value="Email"
                checked={followHow1 === "Email"}
                aria-label="radio 1"
                required
              />
              <Form.Check // prettier-ignore
                type="radio"
                label="Phone Call"
                name="followHow1"
                value="Phone"
                checked={followHow1 === "Phone"}
                aria-label="radio 1"
                required
              />

              <Form.Check // prettier-ignore
                type="radio"
                label="Visit"
                name="followHow1"
                value="Visit"
                checked={followHow1 === "Visit"}
                aria-label="radio 1"
                required
              />
            </Form.Group> */}

      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Reason</Form.Label>
             
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>When</Form.Label>

              <Form.Control
                type="date"
                value={followDate1}
                // onChange={(e) => setFundId(e.target.value)}
                onChange={(e) => setFollowDate1(e.target.value)}
                name="followDate1"
                style={{ fontSize: "1em" }}
              />
              <p>
                <span style={{ fontWeight: "600" }}>Current date:</span>{" "}
                {moment(followDate1).format("YYYY-MM-DD")}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={followNote1}
                // onChange={(e) => setFundId(e.target.value)}
                onChange={(e) => setFollowNote1(e.target.value)}
                name="followNote1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Confirm Completion"
                name="defaultFund"
                checked={followComplete1}
                onChange={(e) => setFollowComplete1(e.target.checked)}
                // onChange={(e) => setDefaultFund(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="but-delete" onClick={handleClose2}>
            Cancel
          </button> */}
      {/* <button className="wqwqw3r3" onClick={handleSubmit1}>
            Add
          </button> */}
      {/* </Modal.Footer> */}
      {/* </Modal> */}

      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Delete Follow Up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          This action cannot be reversed.
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose1} className="jiluil">
            Close
          </button>
          <button
            onClick={() => {
              deleteFollowUp(selectedValue._id, {
                alertText: "Follow Up Deleted",
              });
              handleClose1();
            }}
            className="jiluil2"
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal> */}

      {/* <FollowUpx
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myHandleSubmit={myHandleSubmit}
        uniquememberdetails={uniquememberdetails}
        setting={setting}
        followName={followName}
        setFollowName={setFollowName}
        followDesc={followDesc}
        setFollowDesc={setFollowDesc}
        followDate={followDate}
        setFollowDate={setFollowDate}
        followHow={followHow}
        setFollowHome={setFollowHow}
        followComplete={followComplete}
        setFollowComplete={setFollowComplete}
        handleRadioChange={handleRadioChange}
        checkField={checkField}
        setCheckField={setCheckField}
        followReason={followReason}
        setFollowReason={setFollowReason}
        handleCheckboxChange={handleCheckboxChange}
        followNote={followNote}
        setFollowNote={setFollowNote}
      /> */}
    </>
  );
};

export default AssignedCourse;
