import React, { useEffect, useState } from "react";
import "./pricinglist.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PricingCard from "../../../components/PriceCard/PricingCard";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import PricingCardUser from "../../../components/PriceCard/PricingCardUser";
function PricingList() {
  const { prices, getPricing, user, logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getPricing();
  }, []);

  const handleClick = async (e, price) => {
    e.preventDefault();

    if (user) {
      navigate("/auth/paymentpage", { state: { price } });
    } else {
      navigate("/signup");
    }
  };

  const handleFreeClick = async (e, price) => {
    e.preventDefault();

    if (user && price?.id) {
      // Check if user and price.id are available
      try {
        await authFetch.post("/payment/create-free-subscription", {
          priceId: price.id,
        });
        //navigate("/auth/dashboard");
        window.location.href = "/auth/dashboard";
      } catch (error) {
        console.error("Error creating free subscription:", error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      // Handle cases when user or price.id is not available
      console.error("User or price details are unavailable.");
    }
  };

  const handleFreeTrialClick = async (e, price) => {
    e.preventDefault();

    if (user && price?.id) {
      // Check if user and price.id are available
      try {
        await authFetch.post("/payment/create-free-trial-subscription", {
          priceId: price.id,
        });
        //navigate("/auth/dashboard");
        window.location.href = "/auth/dashboard";
      } catch (error) {
        console.error("Error creating free subscription:", error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      // Handle cases when user or price.id is not available
      console.error("User or price details are unavailable.");
    }
  };

  const [plan, setPlan] = useState("monthly");

  const filteredAndMappedPrices = prices
    ? prices
        .filter(
          (price) =>
            (plan === "monthly" && price?.recurring?.interval !== "year") ||
            (plan === "annual" && price?.recurring?.interval !== "month")
        )
        .sort((a, b) => a.unit_amount - b.unit_amount) // Fallback to 0 if unit_amount is undefined
        .map((price) => (
          <PricingCardUser
            key={price?.priceId || "missing-id"} // Fallback key if id is undefined
            price={price}
            color="blue"
            handleSubscription={handleClick}
            handleFreeSubscription={handleFreeClick}
            handleFreeTrialSubscription={handleFreeTrialClick}
          />
        ))
    : []; // Fallback to an empty array if prices is null/undefined

  return (
    <>
      <Container fluid className="mb-5" style={{ margin: "0", padding: "0" }}>
        <Row>
          <div className="pricingbody">
            <p className="pricingb">
              Experience Seamless{" "}
              <span style={{ color: "#FE7064" }}>Church Management</span>
            </p>
            <p className="pricingb2">
              100% Service Guaranteed on Every Package
            </p>
          </div>
        </Row>
        <Row>
          <div className="text-center d-flex justify-content-center align-items-center pb-5">
            <span
              style={{
                marginRight: "10px",
                cursor: "pointer",
                textDecoration: "none",
                fontWeight: plan === "monthly" ? "bold" : "normal",
                color: plan === "monthly" ? "#FE7064" : "black",
                fontSize: "18px",
              }}
              onClick={() => setPlan("monthly")}
            >
              Monthly
            </span>
            |
            <span
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                textDecoration: "none",
                fontWeight: plan === "annual" ? "bold" : "normal",
                color: plan === "annual" ? "#FED970" : "black",
                fontSize: "18px",
              }}
              onClick={() => setPlan("annual")}
            >
              Yearly
            </span>
          </div>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            {filteredAndMappedPrices}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PricingList;
