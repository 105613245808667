import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import "./steps.css";
import moment from "moment-timezone";
import currencyCodes from "currency-codes";

const statesUS = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const provincesCanada = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" },
];

const Step2 = ({ formData, handleChange, handleCompletionChange }) => {
  const { user } = useAppContext();
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    user?.timeZone || "America/New_York"
  );
  const [timeFormat, setTimeFormat] = useState(user?.timeFormat || "24-hour");
  const [country, setCountry] = useState(user?.country || "US");
  const stripeSupportedCurrencies = ["USD", "CAD", "EUR", "NGN", "GBP", "JPY"];
  const currencyOptions = stripeSupportedCurrencies.map((code) => ({
    code,
    name: currencyCodes.code(code)?.currency || code,
  }));
  const [currency, setCurrency] = useState(user?.currency || "");

  useEffect(() => {
    const getTimeZones = () => {
      const usCanadaZones = moment.tz
        .names()
        .filter((zone) => zone.startsWith("America/"))
        .map((zone) => {
          const offset = moment.tz(zone).format("Z");
          const abbr = moment.tz(zone).format("z");
          return {
            value: zone,
            label: `(GMT${offset}) ${abbr} (${zone})`,
          };
        });
      setTimeZones(usCanadaZones);
    };

    getTimeZones();
  }, []);

  const handleSelect = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  const handleStateChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    handleChange({
      target: {
        name: "state",
        value: selectedOption.value,
      },
    });
  };

  return (
    <div className="pt-4">
      {/* <p className="text-lg text-bold pb-3">Church Location</p> */}
      <form>
        <Form.Group className="mb-3" controlId="timeZoneSelect">
          <Form.Label>Time Zone</Form.Label>
          <Form.Select value={selectedTimeZone} onChange={handleSelect}>
            {timeZones.map((zone, index) => (
              <option key={index} value={zone.value}>
                {zone.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCountry">
          <Form.Label>Country</Form.Label>
          <Form.Select
            value={country}
            name="country"
            onChange={(e) => {
              setCountry(e.target.value);
              handleChange(e);
            }}
          >
            <option>Select</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            {/* Add other countries if necessary */}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicState">
          <Form.Label>State/Province</Form.Label>
          <Form.Select
            value={formData.state}
            name="state"
            onChange={handleStateChange}
          >
            {country === "US" &&
              statesUS.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            {country === "CA" &&
              provincesCanada.map((province) => (
                <option key={province.value} value={province.value}>
                  {province.label}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            name="city"
            value={formData.city || user?.city || ""}
            onChange={handleChange}
            className="forminput"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type="text"
            name="streetAddress"
            value={formData.streetAddress || user?.streetAddress || ""}
            onChange={handleChange}
            className="forminput"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Zip/Postal Code</Form.Label>
          <Form.Control
            type="text"
            name="zipCode"
            value={formData.zipCode || user?.zipCode || ""}
            onChange={handleChange}
            className="forminput"
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Form.Check
            type="checkbox"
            id="step2Complete"
            label="Mark as Completed"
            checked={formData.step2Completed}
            onChange={(e) => handleCompletionChange(e.target.checked)}
          />
        </div>
      </form>
    </div>
  );
};

export default Step2;
