import React from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AnnualPlan = ({ totalPrice, selectedFeatures, size }) => {
  const displayedPrice = Number(totalPrice).toFixed(2) * 12 * 0.95;

  const navigate = useNavigate();
  const handleNav = () => {
    const featuresParam = encodeURIComponent(JSON.stringify(selectedFeatures));
    const sizeParam = encodeURIComponent(size);
    const billingParam = encodeURIComponent("annual");
    navigate(`/signup/regular/${sizeParam}/${featuresParam}/${billingParam}`);
  };
  return (
    <div>
      <div className="price" style={{ borderTop: "4px solid #ed1b1b" }}>
        <div className="topfea">Save 5%</div>
        <p className="subp">Annual</p>
        <div>
          <p className="subprice">
            {displayedPrice.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            /<span className="noino222">billed annually</span>
          </p>
        </div>
        <button className="buttoncssx" onClick={handleNav}>
          {/* {price.nickname === "Free" ? "Subscribe" : "Buy Plan"} */}
          Sign up
        </button>
        <div className="subplan">
          <div className="cardlie">
            <div className="my-3">
              <p style={{ fontWeight: "600" }} className="noino">
                Also includes
              </p>
              <p className="noino">
                <FaCheck className="okjyb" aria-label="Included" /> 700 texts
                per month -{" "}
                {/* <a
                  href="#"
                  data-toggle="tooltip"
                  title="Increase text limits by upgrading to a premium plan."
                  className="smalltext"
                  style={{ color: "var(--secondary-color)" }}
                >
                  Text limit can be increased after sign up.
                </a> */}
              </p>

              <p className="noino">
                <FaCheck className="okjyb" /> 5GB storage space
              </p>

              <p className="noino">
                <FaCheck className="okjyb" /> Unlimited emails
              </p>
              <p className="noino">
                <FaCheck className="okjyb" /> Unlimited support
              </p>
              <p className="noino">
                <FaCheck className="okjyb" /> Free data transfer
              </p>
              <p className="noino">
                <FaCheck className="okjyb" /> Cancel anytime
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualPlan;
