import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import axios from "axios";

import { Link } from "react-router-dom";

const VolunteerCenter = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    deleteSchedule,
    logoutUser,

    getSchedules,
    schedule,
  } = useAppContext();

  // Extract users older than 30 and add them to a new object

  useEffect(() => {
    schedule && getSchedules();
  }, []);
  const [show, setShow] = useState(false);
  const [schedules, setSchedules] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (prayer) => {
    setShow(true);
    setSchedules(prayer);
  };

  const handleDelete = () => {
    deleteSchedule(schedules._id, {
      alertText: "Schedule Deleted",
    });

    handleClose();
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">Volunteer Center</Col>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>SCHEDULE NAME</th>
                <th style={{ fontSize: "1em" }}>SCHEDULE DESCRIPTION</th>
                <th style={{ fontSize: "1em" }}>REPEAT</th>
                <th style={{ fontSize: "1em" }}>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((data, index) => (
                <tr key={index}>
                  <td style={{ fontSize: "1em" }}>{data.schedule}</td>
                  <td style={{ fontSize: "1em", width: "40%" }}>
                    {data.scheduleDescription}
                  </td>
                  <td style={{ fontSize: "1em" }}>
                    {data.repeat ? data.repeat : "No"}
                  </td>
                  <td>
                    <Link
                      to={"/auth/volunteersignup/" + data._id}
                      style={{ marginRight: "10px" }}
                    >
                      <button className="jiluil">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleted Schedules cannot be recovered!</Modal.Body>
            <Modal.Footer>
              <Button className="wewecwe" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="wewecwe1" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default VolunteerCenter;
