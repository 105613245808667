import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./membernav.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Tooltip } from "antd";
import { useAppContext } from "../../context/appContext";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import avatar from "./../../assets/images/blank2.png";

import { FaBell, FaBolt, FaQuestion, FaInfo } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import CreateSubscription from "../ManageSubscription/CreateSubscription";
import EditSubscription from "../ManageSubscription/EditSubscription";
import OnboardingWizard from "../OnboardTour/OnboardingWizard";
import { differenceInDays, parseISO, format, formatISO } from "date-fns";

function MemberNav() {
  const { logoutUser, user, uniquememberdetails, getMemberById } =
    useAppContext();
  const [name, setName] = useState("...Loading!");

  const [admin, setAdmin] = useState(false);
  const [appAdmin, setAppAdmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [churchName, setChurchName] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [support, setSupport] = useState("");
  const [supportDescription, setSupportDescription] = useState("");
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [level, setLevel] = useState("");

  const handleClose = () => setShow(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user && user._id) {
      getMemberById(user._id);
    }
  }, []);

  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [OnboardingWizardstatus, setOnboardingWizardstatus] = useState(false);
  const [myfirstname, setMyfirstname] = useState("...Loading!");

  useEffect(() => {
    if (uniquememberdetails) {
      setMyfirstname(user.mfirstName);
    }
  }, [uniquememberdetails]);
  useEffect(() => {
    if (user && user.churchName) {
      setChurchName(user.churchName);
      setName(user?.mfirstName);
      setDaysUntilExpiry(user?.freeExpiryDays);
    }
  }, [user.churchName]);

  useEffect(() => {
    if (user && user.percentageCompleted) {
      setPercentageCompleted(user.percentageCompleted);
      // setOnboardingWizardstatus(user.onboardingWizardstatus);
    }
  }, [user.percentageCompleted]);

  const changeWizardOnboarding = async () => {
    setOnboardingWizardstatus(true);
  };

  useEffect(() => {
    const roles = ["Super Admin", "Application Admin", "Admin"];
    const hasRequiredRole = roles.some((role) =>
      user?.role[0]?.roleName?.includes(role)
    );

    if (hasRequiredRole) {
      setSuperAdmin(true);
      setAdmin(true);
      setAppAdmin(true);
    }
  }, [user.role]);

  const profile = () => {
    navigate("/auth/profile");
  };

  const userManagement = () => {
    navigate("/auth/usersmanagement");
  };

  const ChangePassword = () => {
    navigate("/auth/changepassword");
  };

  const accountManagement = () => {
    navigate("/auth/accountmanagement");
  };

  const bulkAction = () => {
    navigate("/auth/bulkaction");
  };

  const integrations = () => {
    navigate("/auth/integrations");
  };

  const messageCenter = () => {
    navigate("/auth/messagecenter");
  };

  const workflow = () => {
    navigate("/auth/workflow");
  };

  const [quickUpgrade, setQuickUpgrade] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [editUpgrade, setEditUpgrade] = useState(false);

  const handleModalx = () => {
    setShowModal(true);
    setQuickUpgrade(true);
  };

  const editMemberPlan = () => {
    setShowModal2(true);
    setEditUpgrade(true);
  };

  useEffect(() => {
    if (user.memberShip === "Free" || user.memberShip === undefined) {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  }, [user.memberShip]);

  const showPowerQuery = () => {
    navigate("/auth/powerquery");
  };
  const [daysUntilExpiry, setDaysUntilExpiry] = useState("...loading");

  const handleMember = async (e, church) => {
    try {
      const response = await authFetch.get(`/auth/churchbyid/${user._id}`);

      if (response.status === 200 || response.status === 201) {
        setChurchDta(response.data.church);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching church:", error);
    }
  };
  const [remainingDays, setRemainingDays] = useState(0);
  const [churchDta, setChurchDta] = useState({});

  useEffect(() => {
    handleMember();
  }, []);

  useEffect(() => {
    const expiryDate = churchDta.freeTrialExpiry
      ? parseISO(churchDta.freeTrialExpiry) // Parse the ISO date string
      : null;

    const currentDate = new Date(); // Get the current date

    if (expiryDate) {
      // Ensure both dates are set to 12 AM to avoid timezone issues
      expiryDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const calculatedDays = differenceInDays(expiryDate, currentDate); // Calculate remaining days
      setRemainingDays(calculatedDays); // Update state with the calculated days

      if (
        calculatedDays <= 0 &&
        user?.role?.some((role) => role.roleName !== "App Admin")
      ) {
        setShowModal(true); // Show the modal
        navigate("/auth/accountmanagement"); // Navigate to account management
      }
    }
  }, [churchDta]); // Re-run the effect when churchDta changes

  const [showWizard, setShowWizard] = useState(false);

  const handleShowWizard = () => {
    setShowWizard(true);
  };

  return (
    <Navbar expand="lg" className="group">
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav
            className="my-2 my-lg-0 me-4"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "2rem",
            }}
            navbarScroll
          >
            {user?.role?.some((role) => role.roleName === "App Admin") ? (
              <div
                style={{
                  fontSize: "1em",
                  fontWeight: "600",
                  color: daysUntilExpiry <= 0 ? "red" : "var(--primary-color)",
                }}
              >
                Welcome, App Admin!
              </div>
            ) : (
              <>
                {remainingDays > 0 ? (
                  <div
                    style={{ fontSize: "1em", fontWeight: "600", color: "red" }}
                  >
                    Trial ends in {remainingDays} days
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "1em",
                      fontWeight: "600",
                      color:
                        daysUntilExpiry <= 0 ? "red" : "var(--primary-color)",
                    }}
                  >
                    Free trial ended
                  </div>
                )}
              </>
            )}

            {showModal && (
              <div className="modal">
                {/* Your modal content goes here */}
                <p>Your free trial has ended. Please manage your account.</p>
              </div>
            )}

            {(admin || appAdmin || superAdmin) && (
              <>
                <button className="jiluil6" onClick={showPowerQuery}>
                  <FaBolt /> Power Query
                </button>
                {/* <button className="jiluil" onClick={showSupport}>
                  <FaQuestion /> Contact Support
                </button> */}
              </>
            )}

            {(admin || appAdmin || superAdmin) && (
              <Tooltip title="Message Center">
                <Nav.Link onClick={messageCenter}>
                  <FaBell
                    style={{ color: "var(--primary-color)", fontSize: "1.8em" }}
                  />
                </Nav.Link>
              </Tooltip>
            )}

            {percentageCompleted < 100 &&
              OnboardingWizardstatus === false &&
              (admin || appAdmin || superAdmin) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleShowWizard}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "var(--secondary-color)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "8px",
                    }}
                  >
                    <FaInfo className="vibrate" style={{ color: "white" }} />
                  </div>
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {`${percentageCompleted}%`}
                  </span>
                </div>
              )}

            <div className="ilui" onClick={profile}>
              <Image
                width={45}
                height={45}
                src={
                  uniquememberdetails?.mProfilePicture?.Location
                    ? uniquememberdetails?.mProfilePicture?.Location
                    : avatar
                }
                roundedCircle
                style={{ border: "1px solid gray", objectFit: "cover" }}
              />
            </div>
            <NavDropdown title={myfirstname} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={ChangePassword} className="dropdd">
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Divider />

              {(admin || appAdmin || superAdmin) && (
                <>
                  <NavDropdown.Item
                    onClick={userManagement}
                    className={
                      admin || appAdmin || superAdmin ? "dropdd" : "disabled"
                    }
                  >
                    Access Management
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={bulkAction} className="dropdd">
                    Bulk Operations
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={workflow}
                    className={
                      admin || appAdmin || superAdmin ? "dropdd" : "disabled"
                    }
                  >
                    WorkFlow
                  </NavDropdown.Item>
                  <NavDropdown.Divider />

                  {!isFree && (
                    <NavDropdown.Item
                      onClick={accountManagement}
                      className="dropdd"
                    >
                      Account Management
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />

                  {(admin || appAdmin || superAdmin) &&
                  user.memberShip === "Free" ? (
                    <NavDropdown.Item onClick={handleModalx} className="dropdd">
                      Upgrade Subscription
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item
                      onClick={editMemberPlan}
                      className="dropdd"
                    >
                      Upgrade Subscription
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                </>
              )}

              <NavDropdown.Item onClick={logoutUser} className="dropdd">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>

      <CreateSubscription
        show={showModal}
        onHide={() => setShowModal(false)}
        quickUpgrade={quickUpgrade}
      />
      <EditSubscription
        show={showModal2}
        onHide={() => setShowModal2(false)}
        quickUpgrade={editUpgrade}
      />
      <OnboardingWizard
        showWizard={showWizard}
        setShowWizard={setShowWizard}
        setPercentageCompleted={setPercentageCompleted}
        changeWizardOnboarding={changeWizardOnboarding}
      />
    </Navbar>
  );
}

export default MemberNav;
