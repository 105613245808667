import React, { useState } from "react";
import { FaStar } from "react-icons/fa"; // Importing star icons from react-icons

const StarRating = ({ onRating }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0); // State to handle hover effect for visual feedback

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => {
                setRating(ratingValue);
                onRating(ratingValue); // Pass the rating value up to the parent component
              }}
              style={{ display: "none" }} // Hide the radio input visually
            />
            <FaStar
              className="star"
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"} // Change color based on hover or selected rating
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(rating)}
              size={20}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
