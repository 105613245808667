import React from "react";
import { FaCheck } from "react-icons/fa";

const FreeTrialPlan = () => {
  return (
    <div>
      <div>
        <p className="colbb cardli noino">All Product Features:</p>
        <p className="noino">
          <FaCheck className="okjyb" /> Member Management
        </p>
        <p className="noino">
          <FaCheck className="okjyb " /> Group Management
        </p>

        <p className="noino">
          <FaCheck className="okjyb " /> Attendance Tracking
        </p>
        <p className="noino">
          <FaCheck className="okjyb " /> Event Management
        </p>

        <p className="noino">
          <FaCheck className="okjyb " /> Volunteer Management
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Giving and Donations
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Service Planner
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Thirdparty Integrations
        </p>
        <p className="noino">
          {" "}
          <FaCheck className="okjyb" /> Prayers
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> FollowUp
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Check In/Out
        </p>
      </div>
    </div>
  );
};

export default FreeTrialPlan;
