import React from "react";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { currencyFormatter } from "../../../utils/helpers";
import { useAppContext } from "../../../context/appContext";

const PrintBatchView = React.forwardRef((props, ref) => {
  const { user } = useAppContext();
  const { giving } = props;

  return (
    <>
      <Container fluid>
        <div ref={ref} style={{ padding: "3rem" }}>
          <div>
            <p>{user.churchName}</p>
            <div className="mb-4">
              {user.maddress && user.mcity && user.mpostal && user.mstate ? (
                <div>
                  {user.maddress} <br></br>
                  {user.mcity},{user.mpostal}
                  <br></br>
                  {user.mstate}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Table hover>
            <thead>
              <tr style={{ cursor: "pointer" }}>
                <th>Date</th>
                <th>Fund</th>
                <th>Method</th>
                <th>Members</th>
                <th>Batch</th>
                <th>Amount</th>
              </tr>
            </thead>
            {giving?.map((batchess, index) => (
              <tbody key={index}>
                <tr>
                  <td>{moment(batchess.postDate).format("MMM Do, YYYY")}</td>
                  <td>{batchess.category}</td>
                  <td>{batchess.paymentMethod}</td>
                  <td>{batchess.giverName}</td>
                  <td>{batchess.batch}</td>
                  <td>
                    {currencyFormatter({
                      amount: batchess.amount,
                      currency: "usd",
                    })}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
        ;
      </Container>
    </>
  );
});

export default PrintBatchView;
