import React, { useState } from "react";
import { Modal, Button, Carousel } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import "./onboardinmodal.css";
import members2 from "../../assets/newi/members2.png";
import support3 from "../../assets/newi/support3.png";
import devotional2 from "../../assets/newi/devotional2.png";
import giving2 from "../../assets/newi/giving2.png";
import calendar2 from "../../assets/newi/calendar2.png";
import welcome from "../../assets/newi/welcome.jpg";
import desktop from "../../assets/newi/desktop.png";
import resources from "../../assets/newi/resources.jpg";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import OnboardingWizard from "./OnboardingWizard";

const FreeOnboarding = ({ isOpen, onRequestClose }) => {
  const { logoutUser, user } = useAppContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSkipTour, setShowSkipTour] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate
  const [showWizard, setShowWizard] = useState(false);

  const totalSlides = 8; // Update this to reflect the total number of slides

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleNext = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      onRequestClose();
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSkip = async () => {
    try {
      const response = await authFetch.patch(
        `/members/onboardingfree/${user._id}`
      );
      if (response.status === 200 || response.status === 201) {
        onRequestClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const startNowHandle = async () => {
    setShowSkipTour(false);
    handleNext();
  };

  const handleGetStartedWizard = () => {
    handleSkip();
    onRequestClose(); // Close the onboarding modal
    setShowWizard(true); // Show the wizard modal
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onRequestClose}
        size="lg"
        backdrop="static"
        centered
      >
        <Modal.Body style={{ padding: "0" }}>
          <Carousel
            activeIndex={currentIndex}
            onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)}
            interval={null}
          >
            <Carousel.Item>
              <div>
                <img
                  src={welcome}
                  alt="Dashboard Overview"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Welcome to Churchease</p>
                  <p className="caroleheadersub">
                    Let’s Get Started with Your Free 90-Day Trial
                  </p>
                  <p className="carolebody">
                    Thank you for choosing Churchease to help manage your
                    church’s activities. To make the most of your trial, let’s
                    take a quick tour and set up your account.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={support3}
                  alt="Setting Up Your Church"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Setting Up Your Church</p>
                  <p className="carolebody">
                    Begin by setting up your church profile. This includes basic
                    information about your church. Accurate details ensure
                    smooth communication and proper scheduling for your church
                    events.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={members2}
                  alt="Adding Members"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Adding Members</p>
                  <p className="carolebody">
                    Add your church members to your church profile to start
                    managing your community efficiently. This helps in
                    maintaining an organized member database.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={calendar2}
                  alt="Scheduling Events"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Scheduling Events</p>
                  <p className="carolebody">
                    Easily schedule and manage church events with our intuitive
                    calendar feature. Set up regular services, special events,
                    and meetings to keep everyone informed and engaged.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={giving2}
                  alt="Managing Contributions"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Managing Contributions</p>
                  <p className="carolebody">
                    Track and manage contributions effortlessly. Our Church
                    Giving and Accounting modules allows you to record
                    donations, generate reports, and provide transparent
                    financial management.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={devotional2}
                  alt="Daily Devotionals"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Daily Devotionals</p>
                  <p className="carolebody">
                    Inspire and engage your members with daily devotionals.
                    Admins can add content and schedule publication times,
                    making devotionals available to members at the right moment.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={resources}
                  alt="Support and Resources"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Support and Resources</p>
                  <p className="carolebody">
                    Need help? Our support team is here for you. Access our
                    extensive knowledge base, and customer support to get the
                    assistance you need.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <img
                  src={desktop}
                  alt="Get Started with Churchease"
                  className="carousel-itemss"
                />
                <div style={{ padding: "2rem" }}>
                  <p className="caroleheader">Get Started with Churchease</p>
                  <p className="carolebody">
                    You're all set! Explore Churchease and start managing your
                    church today. If you need any assistance, don't hesitate to
                    reach out to our support team. Thank you for choosing
                    Churchease!
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {currentIndex > 0 && (
              <button className="startbnnnddpx" onClick={handleBack}>
                Back
              </button>
            )}
            <div style={{ display: "flex", gap: "10px" }}>
              {showSkipTour && (
                <button className="startbnnnddpskip" onClick={handleSkip}>
                  Skip Tour
                </button>
              )}
              {currentIndex === totalSlides - 1 ? (
                <button
                  className="startbnnnddpnext"
                  onClick={handleGetStartedWizard}
                >
                  Get Started Wizard
                </button>
              ) : (
                <button
                  className="startbnnnddpnext"
                  onClick={currentIndex === 0 ? startNowHandle : handleNext}
                >
                  {currentIndex === 0 ? "Start Now" : "Next"}
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <OnboardingWizard showWizard={showWizard} setShowWizard={setShowWizard} />
    </>
  );
};

export default FreeOnboarding;
