import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import he from "he";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./knowledgebasexx.css";

const KnowledgeBaseView = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, showAlert } = useAppContext();

  const { id } = useParams();

  const [resourceDetails, setResourceDetails] = useState({});
  const [yesCount, setYesCount] = useState(0);
  const [noCount, setNoCount] = useState(0);

  useEffect(() => {
    fetchResourceDetails();
    getRandomTitles();
  }, []);

  const fetchResourceDetails = async () => {
    try {
      const { data } = await authFetch(`/generalknowledge/${id}`);
      setResourceDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleYesClick = () => {
    const newCount = yesCount + 1;
    setYesCount(newCount);
    sendFeedbackToServer({ yes: newCount });
  };

  const handleNoClick = () => {
    const newCount = noCount + 1;
    setNoCount(newCount);
    sendFeedbackToServer({ no: newCount });
  };
  useEffect(() => {
    getFeedback();
  }, []);

  const sendFeedbackToServer = async (feedback) => {
    try {
      const file = {
        ...feedback,
      };
      const { data } = await authFetch.post(
        `/generalknowledge/feedback/${id}`,
        feedback
      );

      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      // // Handle success
      // console.log("Feedback sent successfully", await response.json());
    } catch (error) {
      // Handle errors
      console.error("Failed to send feedback", error);
    }
  };

  const getFeedback = async () => {
    try {
      const { data } = await authFetch(`/generalknowledge/feedback/${id}`);

      if (data) {
        setYesCount(data.yesCount);
        setNoCount(data.noCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [randomTitles, setRandomTitles] = useState([]); // Add this line
  const getRandomTitles = async () => {
    try {
      const { data } = await authFetch(
        "/generalknowledge/getrandomtitlesx/test"
      );

      if (data) {
        setRandomTitles(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log("resourceDetails", resourceDetails);

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="knowledtit">
          <Col md={12} xs={12} sm={12}>
            {resourceDetails ? (
              <div>
                <p className="knowledtit2">{resourceDetails?.title}</p>
                <Col>
                  {resourceDetails.blocks?.map((block, index) => (
                    <div key={index}>
                      {block.type === "text" ? (
                        <div
                          style={{
                            color: "var(--primary-color)",
                            lineHeight: "2.5",
                            fontFamily: "Open Sans",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: he.decode(block.content),
                          }}
                        />
                      ) : block.type === "image" ? (
                        <img
                          src={block.imageUrl.Location}
                          alt={resourceDetails.title}
                          className="imads"
                        />
                      ) : null}
                    </div>
                  ))}
                </Col>
              </div>
            ) : (
              <div>No resource details available.</div>
            )}
            <div
              className="flex justify-center flex-col"
              style={{ paddingBottom: "6rem" }}
            >
              <p className="text-center text-bold pt-4">Was this helpful?</p>
              <div className="flex justify-center gap-3">
                <button onClick={handleYesClick} className="ibub">
                  Yes ({yesCount && yesCount})
                </button>

                <button onClick={handleNoClick} className="ibub">
                  No ({noCount && noCount})
                </button>
              </div>
            </div>
          </Col>
          {/* <Col md={5} xs={12} sm={12} className="knlssws">
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                height: "100%",
              }}
            >
              <p className="sahs">Other Articles</p>
              <div className="mainsss">
                {randomTitles.map((title) => (
                  <p key={title._id}>{title.title}</p>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default KnowledgeBaseView;
