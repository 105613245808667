import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import "./usermanagement.css";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";

const RolesAdd = () => {
  const {
    logoutUser,
    getMenu,
    allMenu,
    setAllMenu,
    addPermission,
    showAlert,

    implementPermission,
  } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getMenu();
    implementPermission();
  }, []);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  const handleAdd = async () => {
    const data = allMenu?.map((menu) => {
      return {
        ...menu,
        options: menu?.options?.filter((opt) => opt?.selected),
      };
    });

    const filteredRoles = data?.filter(
      (role) =>
        Array.isArray(role?.options) &&
        role.options.some((permission) => permission?.selected)
    );
    const selectedPermissions = filteredRoles?.flatMap((role) =>
      role?.options?.filter((permission) => permission?.selected)
    );

    const selectedPermissionsx = filteredRoles?.flatMap((role) => role?.label);

    const newRole = {
      roleName: roleName,
      roleDescription: roleDescription,
      permissions: selectedPermissions,
      label: selectedPermissionsx,
    };

    addPermission({ newRole });
    navigate("/auth/rolesmanagement");
  };

  const handleSelectAll = (e) => {
    let ar = [];
    for (let idx = 0; idx < allMenu?.length; idx++) {
      const element = {
        ...allMenu[idx],
        selected: e?.target?.checked,
        options: allMenu[idx]?.options?.map((item) => ({
          ...item,
          selected: e?.target?.checked,
        })),
      };
      ar.push(element);
    }
    setAllMenu(ar);
    setIsAllSelected(e?.target?.checked);
  };

  const handleChange = (e, idx) => {
    let ar = [...allMenu];
    ar[idx].selected = e.target.checked;
    let opt = ar[idx].options.map((item) => ({
      ...item,
      selected: e.target.checked,
    }));
    ar[idx].options = opt;
    setAllMenu(ar);
  };

  const handleSelectOpt = (e, ind, idx) => {
    let ar = [...allMenu];
    ar[ind].options[idx].selected = e.target.checked;
    if (e.target.checked) {
      ar[ind].selected = true;
    } else {
      let checkOpt = ar[ind].options.map((item) => item.selected);
      let check = checkOpt.every((item) => item === false);
      if (check) ar[ind].selected = false;
    }
    setAllMenu(ar);
  };

  return (
    <Container fluid>
      {showAlert && <Alert />}
      <Row className="courseheader" style={{ marginBottom: "3rem" }}>
        <Col className="sahs">Add Role</Col>
      </Row>
      <Row className="qr3wrq3r">
        <div className="bnmjo">
          <Button variant="danger" className="jiluil3" onClick={handleAdd}>
            Save
          </Button>
        </div>
      </Row>
      <Row
        style={{
          marginBottom: "3rem",
          paddingRight: "2rem",
          paddingLeft: "2rem",
        }}
      >
        <Col>
          <div>
            <Form.Label htmlFor="inputPassword5" style={{ fontSize: "100%" }}>
              Role Name
            </Form.Label>
            <Form.Control
              type="text"
              aria-describedby="passwordHelpBlock"
              placeholder="Enter Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              style={{ fontSize: "1em" }}
            />
          </div>
        </Col>
        <Col>
          <div>
            <Form.Label htmlFor="inputPassword5" style={{ fontSize: "100%" }}>
              Role Description
            </Form.Label>
            <Form.Control
              type="text"
              aria-describedby="passwordHelpBlock"
              placeholder="Enter Role Description"
              value={roleDescription}
              onChange={(e) => setRoleDescription(e.target.value)}
              style={{ fontSize: "1em" }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Col sm={3}>
          {allMenu?.map((menu, idx) => (
            <div className="roleleft" key={idx}>
              <Form.Check // prettier-ignore
                type="checkbox"
                label={menu?.label}
                checked={menu?.selected}
                onChange={(e) => handleChange(e, idx)}
                style={{ fontSize: "1em" }}
              />
            </div>
          ))}
        </Col>
        <Col>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr style={{ fontSize: "100%" }}>
                <th>
                  <Form.Check
                    aria-label="option 8"
                    onClick={handleSelectAll}
                    checked={isAllSelected}
                    label={"Permission"}
                  />
                </th>
                <th>Description</th>
              </tr>
            </thead>
            {allMenu?.map((permission, ind) => (
              <tbody key={ind}>
                {permission?.options?.map((opt, idx) => (
                  <tr key={`${ind}-${idx}`} style={{ fontSize: "1em" }}>
                    <td>
                      <Form.Check
                        label={opt?.label}
                        aria-label={`option ${idx}`}
                        checked={opt?.selected}
                        onChange={(event) => handleSelectOpt(event, ind, idx)}
                        type="checkbox"
                      />
                    </td>
                    <td>{opt?.desc}</td>
                  </tr>
                ))}
              </tbody>
            ))}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default RolesAdd;
