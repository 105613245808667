import React from "react";
import "./statsitem.css";
import { Container, Row } from "react-bootstrap";

const StatsItem = ({ count, title, icon, color, bcg }) => {
  return (
    <Container fluid>
      <div className="mainitem" style={{ backgroundColor: color }}>
        <Row>
          <div className="membersact">
            {title} {icon}
          </div>
        </Row>

        <Row>
          <div className="titlea">{count}</div>
        </Row>
      </div>
    </Container>
  );
};

export default StatsItem;
