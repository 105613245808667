import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

const StandardForm = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, user } = useAppContext();
  const [uniqueGrowthPlan, setUniqueGrowthPlan] = useState({});
  const [allForms, setAllForms] = useState([]);
  const [forms, setForms] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms`);

    setForms(data.forms);
  };

  const loadTaskByAll = async () => {
    const datax = await authFetch.get(`/forms/formbyall/${user._id}`);

    const data = datax.data;

    setAllForms(data);
  };

  useEffect(() => {
    loadCourse();

    loadTaskByAll();
  }, []);

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">My Form</p>
        </Col>
      </Row>

      <Row>
        <div>
          {forms && forms.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "100%",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {forms.length ? (
                  <p>
                    {forms.length} record{forms.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Saved View</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th>PLAN NAME</th>
                    <th>PUBLISHED DATE</th>

                    <th style={{ textAlign: "right" }}>ACTIONS</th>
                  </tr>
                </thead>
                {forms.map((plan, index) => {
                  return (
                    <tr key={index} className="trdata">
                      <td>{plan.title}</td>
                      <td>{moment(plan.updatedAt).format("D MMMM YYYY")}</td>

                      <td className="rightco">
                        <Link to={`/auth/standardformedit/${plan._id}`}>
                          <button className="jiluil">View Form</button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default StandardForm;
