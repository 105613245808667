import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Alert from "../../../components/Alert/Alert";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
const Testimony = () => {
  const { showAlert, user, createPrayerRequest, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [testimonySubject, setTestimonySubject] = useState("");
  const [testimonyRequest, setTestimonyRequest] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [allSettings, setAllSettings] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [error, setError] = useState("");
  const [anonymousPost, setAnonymousPost] = useState(false);

  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = async () => {
    const data = await authFetch.get(`/testimony/category`);
    if (data.statusText === "OK") {
      setAllSettings(data.data.categories);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for required fields
    if (!testimonySubject || !testimonyRequest || !selectedCategory) {
      setShow1(true); // Show missing field error message
      return;
    }

    // Check for testimonyRequest length
    if (testimonyRequest.length > 500) {
      setShow(true); // Show length limit error message
      return;
    }

    // Prepare the data for the API call
    const prayerRequestData = {
      testimonySubject,
      testimony: testimonyRequest,
      selectedCategory,
      churchName: user.churchName,
      mfirstName: anonymousPost ? "anonymous" : user.mfirstName,
      mlastName: anonymousPost ? "anonymous" : user.mlastName,
      mId: user._id,
      anonymousPost,
    };

    try {
      // Make the API call and await the response
      const response = await authFetch.post("/testimony", prayerRequestData);

      // Check if the request was successful
      if (response.status === 201) {
        // Reset form fields on successful submission
        //setError("Testimony Posted Successfully!");
        setTestimonySubject("");
        setTestimonyRequest("");
        setSelectedCategory("");
        setAnonymousPost(false);
        toast(
          <CustomToastContent
            title="Success"
            message="Testimony Posted Successfully!"
          />
        );
      } else {
        // Handle other statuses or general API call failure
        setError("Failed to post testimony. Please try again."); // Error message for the user
      }
    } catch (error) {
      // Handle errors from the API call
      if (error.response && error.response.status === 401) {
        setError("You are not authorized. Please log in."); // Unauthorized access
      } else {
        setError("An error occurred. Please try again."); // General error message
        toast(
          <CustomToastContent
            title="Error"
            message="An error occurred. Please try again."
          />
        );
      }
    }
  };

  const maxChars = 500;
  return (
    <Container fluid>
      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <p className="sahs" style={{ display: "flex", justifyContent: "left" }}>
          Share Your Testimony
        </p>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showAlert && <Alert />}
        {show && (
          <CustomAlert
            alertText="Testimonies cannot be more than 500 characters"
            alertType="danger"
          />
        )}
        {show1 && (
          <CustomAlert
            alertText="Please fill in all fields"
            alertType="danger"
          />
        )}

        {error && <p className="inlinesuccess">{error}</p>}

        <form
          className="registerform "
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Testimony Subject"
              value={testimonySubject}
              onChange={(e) => setTestimonySubject(e.target.value)}
              name="testimonySubject"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label className="hed">Category</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option>Select Category</option>
              {allSettings
                .filter((setting) => setting.selectedOption === "Testimony") // Filter settings for "Prayer" category
                .map((setting) => (
                  <option key={setting._id}>{setting.categoryName}</option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label className="hed">Write your testimony</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter your testimony here"
              rows={10}
              value={testimonyRequest}
              name="testimonyRequest"
              onChange={(e) => setTestimonyRequest(e.target.value)}
            />
            <div className="character-count flex items-center justify-end">
              {`${testimonyRequest.length} / ${maxChars}`} characters
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Check
              type="checkbox"
              value={anonymousPost}
              onChange={(e) => setAnonymousPost(e.target.checked)}
              label="Make Testimony Anonymous"
            />
          </Form.Group>

          <Button className="wewewe" onClick={handleSubmit}>
            Send Testimony Request
          </Button>
        </form>
      </Row>
    </Container>
  );
};

export default Testimony;
