import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import Header from "../../components/Navbar/Navbar";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";

const AddToGroup = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const churchUrl = useParams();
  const containerName = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [churchName, setChurchName] = useState("");

  const [birthday, setBirthday] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [anniversaryMonth, setAnniversaryMonth] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [gender, setGender] = useState("");

  const [maritalStatus, setMaritalStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [familyRole, setFamilyRole] = useState("");
  const unique_id = uuid();
  const unique_id_2 = uuid();
  const navigate = useNavigate();
  const familyId = unique_id.slice(0, 8);
  const familySecCode = unique_id_2.slice(0, 8);

  const { isLoading, showAlert, logoutUser, user, createMember } =
    useAppContext();
  const [scroll, setScroll] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [groupId, setGroupId] = useState("");

  useEffect(() => {
    if (churchUrl && churchUrl.churchurl) {
      let originalChurchName = decodeURIComponent(churchUrl.churchurl);

      setChurchName(originalChurchName);
    }
  }, [churchUrl]);

  useEffect(() => {
    if (containerName) {
      let originalGroupId = decodeURIComponent(containerName.groupid);

      setGroupId(originalGroupId);
    }
  }, [churchUrl]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}]))$/;
    return re.test(String(email).toLowerCase());
  };

  const [familyData, setFamilyData] = useState([]);
  const [existMembers, setExistMembers] = useState([]);
  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      // Handle invalid email
      setEmailError(true);
      setScroll(true);
      return;
    }

    familyData.forEach((member) => {
      member.familyId = familyId;
      member.familySecCode = familySecCode;
    });

    let demoUser = {
      memail: email,
      mfirstName: firstName,
      mlastName: lastName,
      mmobilePhone: mobilePhone,
      churchName: churchName,
      mgender: gender,

      mmarital: maritalStatus,
      mworkPhone: workPhone,
      mhomePhone: homePhone,
      maddress: address,
      maddress2: address2,
      mcity: city,
      mstate: state,
      mpostal: postal,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      linkedin: linkedin,

      mBirthday: birthday,
      mBirthMonth: birthMonth,
      mAnniversaryDay: anniversary,
      mAnniversaryMonth: anniversaryMonth,
      FamilyRole: familyRole,
      mfamily: [...familyData, ...existMembers],
      familySecCode: familySecCode,
      familyId: familyId,
      role: [{ roleName: "Standard" }],
    };

    demoUser.familyId = familyId;
    demoUser.familySecCode = familySecCode;

    existMembers.forEach((member) => {
      member.familyId = familyId;
      member.familySecCode = familySecCode;
    });

    let allFamilyData = [...familyData, ...existMembers, demoUser];

    let currentMember = { ...demoUser, mfamily: allFamilyData };

    for (let i = 0; i < familyData?.length; i++) {
      createMemberFromFamily(familyData[i], allFamilyData);
    }

    const newAddGroup = {
      churchName: churchName,
      groupId: groupId,
      memail: email,
    };

    try {
      let { data } = authFetch.post("/members/createfromform", currentMember);
      if (data) {
        navigate("/redirect/success");
      }
    } catch (err) {
      console.error(err);
    }

    setTimeout(async () => {
      try {
        const { data } = await authFetch.post(
          `/members/dynamicadd`,
          newAddGroup
        );
        authFetch.post("/email/currentmember", currentMember);
        // Handle the successful response here, if needed
      } catch (error) {
        console.log(error);
      }
    }, 4000); // 2000 milliseconds = 2 seconds

    navigate("/redirect/success");
    setFirstName("");
    setLastName("");
    setEmail("");
    setGender("");

    setMaritalStatus("");
    setBirthday("");
    setBirthMonth("");
    setAnniversary("");
    setAnniversaryMonth("");
    setMobilePhone("");
    setHomePhone("");
    setAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setPostal("");
    setWorkPhone("");
    setFacebook("");
    setTwitter("");
    setInstagram("");
    setLinkedin("");
    setFamilyRole("");

    setFamilyData([]);
    setExistMembers([]);

    setScroll(true);
  };

  const createMemberFromFamily = (e, allFamilyData) => {
    const currentMember = {
      mfirstName: e?.mfirstName || "",
      mlastName: e?.mlastName || "",
      memail: e?.memail || "",
      mgender: e?.mgender || "",

      mmarital: e?.mmarital || "",
      mage: e?.mage || "",
      manniversary: e?.manniversary || "",

      campus: e?.campus || "",
      mfamily: allFamilyData || [],
      tags: e?.tags || [],
      mhomePhone: e?.mhomePhone || "",
      mworkPhone: e?.mworkPhone || "",
      memployer: e?.memployer || "",
      mmobilePhone: e?.mmobilePhone || "",
      mcity: e?.mcity || "",
      maddress: e?.maddress || "",
      maddress2: e?.maddress2 || "",
      mpostal: e?.mpostal || "",
      mstate: e?.mstate || "",

      mnotes: e?.mnotes || "",
      mAnniversaryDay: e?.mAnniversaryDay || "",
      mAnniversaryMonth: e?.mAnniversaryMonth || "",
      mBirthMonth: e?.mBirthMonth || "",
      mBirthdayCont: e?.mBirthMonth + " " + e?.mBirthday || "",
      mAnniversaryCont: e?.mAnniversaryMonth + " " + e?.mAnniversaryDay || "",
      mBirthday: e?.mBirthday || "",
      churchName: churchName || "",
      familyId: familyId || "",
      familySecCode: familySecCode || "",
      familyRole: e?.familyRole || "",
      facebook: e?.facebook || "",
      twitter: e?.twitter || "",
      instagram: e?.instagram || "",
      linkedin: e?.linkedin || "",
      role: "Standard",
    };

    createMember({ currentMember });
    navigate("/redirect/success");
    setFirstName("");
    setLastName("");
    setEmail("");
    setGender("");

    setMaritalStatus("");
    setBirthday("");
    setBirthMonth("");
    setAnniversary("");
    setAnniversaryMonth("");
    setMobilePhone("");
    setHomePhone("");
    setAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setPostal("");
    setWorkPhone("");
    setFacebook("");
    setTwitter("");
    setInstagram("");
    setLinkedin("");
    setFamilyRole("");

    setFamilyData([]);
    setExistMembers([]);

    //navigate("/auth/people");
  };

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  });

  return (
    <>
      <Helmet>
        <title>New Member Enrollment</title>
        <meta
          name="description"
          content="Sign up for a live demo of Churchease and see how our church management software can simplify administration, enhance community engagement, and streamline giving."
        />
      </Helmet>
      <Header />
      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", margin: "0" }}>
          <div className="flex items-center flex-column wrrer">
            <Form className="formdivfdss">
              {showAlert && <Alert />}
              {emailError && (
                <p
                  className="mb-5"
                  style={{ textAlign: "center", color: "red" }}
                >
                  Invalid Email. Please try again!{" "}
                </p>
              )}
              <p className="awwqx mb-5">Join {groupId}</p>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>General Information</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">
                        Church Name <span style={{ color: "#FE7064" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="name"
                        placeholder="Church Name"
                        value={churchName}
                        onChange={(e) => setChurchName(e.target.value)}
                        className="forminput cnm"
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">
                        First Name <span style={{ color: "#FE7064" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="firstname"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="forminput cnm"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">
                        Last Name <span style={{ color: "#FE7064" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="lastname"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="forminput cnm"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="hed">
                        Gender <span className="myast">*</span>
                      </p>

                      <Form.Check
                        type="radio"
                        label="Male"
                        name="gender"
                        aria-label="radio 1"
                        value="Male"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />

                      <Form.Check
                        type="radio"
                        label="Female"
                        name="gender"
                        aria-label="radio 2"
                        value="Female"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <div>
                      <Form.Label className="hed">Birth Date</Form.Label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="number"
                              min="1"
                              max="31"
                              value={birthday}
                              className="formvalue"
                              //handleChange={handleMemberInput}
                              onChange={(e) => setBirthday(e.target.value)}
                              name="birthday"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Select
                              value={birthMonth}
                              //handleChange={handleMemberInput}
                              onChange={(e) => setBirthMonth(e.target.value)}
                              name="birthMonth"
                              aria-label="Default select example"
                              className="formvalue"
                              style={{ width: 150 }}
                            >
                              <option>Month</option>

                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Form.Label className="hed">
                        Wedding Anniversary
                      </Form.Label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="number"
                              min="1"
                              max="31"
                              value={anniversary}
                              className="formvalue"
                              //handleChange={handleMemberInput}
                              onChange={(e) => setAnniversary(e.target.value)}
                              name="anniversary"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Select
                              value={anniversaryMonth}
                              // handleChange={handleMemberInput}
                              onChange={(e) =>
                                setAnniversaryMonth(e.target.value)
                              }
                              name="anniversaryMonth"
                              aria-label="Default select example"
                              className="formvalue"
                              style={{ width: 150 }}
                            >
                              <option>Month</option>

                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <Form.Group className="mb-3">
                      <p className="hed">Marital Status</p>
                      <Form.Check
                        type="radio"
                        label="Single"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Single"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Engaged"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Engaged"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Married"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Married"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Divorced"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Divorced"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Widowed"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Widowed"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Separated"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Separated"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Other"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Other"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Contact Information</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Mobile Phone <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter Mobile Phone"
                        value={mobilePhone}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMobilePhone(e.target.value)}
                        name="mobilePhone"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Home Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Home Phone"
                        value={homePhone}
                        className="formvalue"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setHomePhone(e.target.value)}
                        name="homePhone"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Email Address <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="formvalue"
                        placeholder="Enter Email Address"
                        value={email}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Address <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        className="formvalue"
                        value={address}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setAddress(e.target.value)}
                        name="address"
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Address Line 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address line 2"
                        className="formvalue"
                        value={address2}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setAddress2(e.target.value)}
                        name="address2"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        City <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter City"
                        value={city}
                        className="formvalue"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setCity(e.target.value)}
                        name="city"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        State <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter State"
                        value={state}
                        //handleChange={handleMemberInput}
                        onChange={(e) => setState(e.target.value)}
                        name="state"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Postal Code <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter Postal Code"
                        value={postal}
                        //handleChange={handleMemberInput}
                        onChange={(e) => setPostal(e.target.value)}
                        name="postal"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Work Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Work Phone"
                        className="formvalue"
                        value={workPhone}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setWorkPhone(e.target.value)}
                        name="workPhone"
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Social Media</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Facebook</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Facebook"
                        value={facebook}
                        name="facebook"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setFacebook(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Twitter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Twitter"
                        value={twitter}
                        name="twitter"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setTwitter(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Instagram</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Instagram"
                        value={instagram}
                        name="instagram"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setInstagram(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">LinkedIn</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter LinkedIn"
                        value={linkedin}
                        name="linkedin"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setLinkedin(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <p className="custb">
                <button
                  className="jiluil"
                  onClick={HandleSubmit}
                  disabled={isLoading}
                  style={{ width: "100%" }}
                >
                  Submit
                </button>
              </p>
              <p
                style={{
                  color: "#FE7064",
                  paddingTop: "2rem",
                  textAlign: "center",
                }}
              >
                Fields marked with an asterisk (*) are required.
              </p>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                By completing and submitting the form, you will receive
                information, tips, and promotions from Churchease. To learn more
                about how Churchease uses your information, see our{" "}
                <Link to="/privacy" style={{ textDecoration: "none" }}>
                  Privacy Policy
                </Link>
                .
              </p>
            </Form>
          </div>
          {/* <Col sm={5} md={5} className="demodiv">
            <p className="demotextmessage">
              Request a <span style={{ color: "#FED970" }}>Demo</span>
            </p>
            <p className="redivwwww" style={{ color: "#fff" }}>
              Fill out your information and a Churchease representative will
              reach out to you.
            </p>
          </Col>
          <Col sm={7} md={7} className="logindiv1"></Col> */}
        </Row>
      </Container>
    </>
  );
};

export default AddToGroup;
