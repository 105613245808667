import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaCalculator, FaMoneyBill } from "react-icons/fa";
import r1 from "../../assets/newi/r1.png";
import r2 from "../../assets/newi/r2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductAccounting = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Church Accounting | Churchease - Manage Church Finances with Ease
        </title>
        <meta
          name="description"
          content="Manage your church’s finances with ease using Churchease's Church Accounting feature. Track donations, oversee expenses, and generate detailed financial reports to ensure accuracy and transparency."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/church-accounting"
        />
        <meta
          property="og:title"
          content="Church Accounting | Churchease - Manage Church Finances with Ease"
        />
        <meta
          property="og:description"
          content="Manage your church’s finances with ease using Churchease's Church Accounting feature. Track donations, oversee expenses, and generate detailed financial reports to ensure accuracy and transparency."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/church-accounting"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#3F98AB" }}>
            <div>
              <FaCalculator size={100} />
              <p className="headssc">Church Accounting</p>
              <p className="headpp">
                Manage your church’s finances with ease using our Church
                Accounting feature. Track donations, oversee expenses, and
                generate detailed financial reports. This robust tool ensures
                accuracy and transparency, aiding strategic financial decisions
                and stewardship.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">Everything you need for church finances</p>
            <p className="product13">
              Your robust system for managing donations, expenses, and financial
              reporting with precision and transparency.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={r1} alt="Accounting" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">DONATION TRACKING</p>
              <p className="proddz1">
                Keep precise records of all donations and tithes, ensuring
                accuracy and accountability in financial management.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">EXPENSE MONITORING</p>
              <p className="proddz1">
                Monitor and categorize church expenses effortlessly, enabling
                better budget planning and financial oversight.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">FINANCIAL REPORTING</p>
              <p className="proddz1">
                Generate comprehensive financial reports instantly, providing
                clear insights into the church's financial health.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">BUDGET FORECASTING</p>
              <p className="proddz1">
                Utilize advanced tools for accurate budget forecasting, helping
                to strategize and plan for future needs and projects.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">FUND MANAGEMENT</p>
              <p className="proddz1">
                Manage different church funds with dedicated accounts to ensure
                proper allocation and usage of financial resources.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">AUDIT TRAILS</p>
              <p className="proddz1">
                Maintain detailed audit trails for all financial transactions,
                enhancing transparency and facilitating audits.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={r2} className="opls" alt="Accounting" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#3F98AB" />
      <Footer />
    </>
  );
};

export default ProductAccounting;
