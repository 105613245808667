import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./pricingcard.css";
import { useNavigate } from "react-router-dom";
import "./pricingcard.css";
import FreePlan from "./FreePlan";
import BasicPlan from "./BasicPlan";
import StandardPlan from "./StandardPlan";
import ProPlan from "./ProPlan";
import FreeTrialPlan from "./FreeTrialPlan";

const PricingCardUser = ({
  icon,
  price,
  handleSubscription,
  handleFreeSubscription,
  handleFreeTrialSubscription,
}) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    if (price?.nickname === "Free") {
      handleFreeSubscription(e, price);
      //console.log("Free Subscription", price);
    } else if (price?.nickname === "Free Trial") {
      handleFreeTrialSubscription(e, price);
      //console.log("Free Trial Subscription", price);
    } else {
      handleSubscription(e, price);
      //console.log("Subscription", price);
    }
  };
  return (
    <div className="price">
      <p className="subp">{price?.nickname || "Unknown Plan"}</p>
      <div>
        <p className="subprice">
          {((price?.unit_amount || 0) / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </p>
        {"/"}
        {price.nickname === "Free"
          ? "Forever"
          : price.nickname === "Free Trial"
          ? "60 days"
          : price.recurring.interval}
      </div>
      <button
        className={
          price.nickname === "Free" ||
          price.nickname === "Basic" ||
          price.nickname === "Standard" ||
          price.nickname === "Free Trial"
            ? "buttoncssx"
            : "buttoncssx" // This will still be "buttoncssx", but you can specify a different class for other plans if needed in the future
        }
        onClick={handleSubmit}
      >
        {price.nickname === "Free" || price.nickname === "Free Trial"
          ? "Subscribe"
          : "Buy Plan"}
      </button>
      <div className="subplan">
        <p className="cardlie">
          {price.nickname === "Free" ? (
            <FreePlan />
          ) : price.nickname === "Free Trial" ? (
            <FreeTrialPlan />
          ) : price.nickname === "Basic" ? (
            <BasicPlan />
          ) : price.nickname === "Standard" ? (
            <StandardPlan />
          ) : (
            <ProPlan />
          )}
        </p>
      </div>
    </div>
  );
};

export default PricingCardUser;
