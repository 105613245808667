import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddDevotional = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [publishTime, setPublishTime] = useState("");

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const maxChars = 5000;

  const handleQuillChange = (content) => {
    setDescription(content);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const publishLater = async (e) => {
    e.preventDefault();
    try {
      if (description.length > maxChars) {
        toast(
          <CustomToastContent
            title="Error"
            message="Description is above the required length"
          />
        );
        return;
      }

      if (!publishDate || !publishTime || !title || !description) {
        toast(
          <CustomToastContent
            title="Error"
            message="Please complete the required fields to proceed"
          />
        );
        return;
      }

      const dataxx = {
        title,
        description,
        publishDate,
        publishTime,
        published: false,
        churchName: user.churchName,
      };

      const response = await authFetch.post("/devotional/later", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setTitle("");
        setDescription("");
        setPublishDate("");
        setPublishTime("");

        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Form Created Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const publishNow = async (e) => {
    e.preventDefault();
    try {
      // if (!title) return setError(true);

      // if (description.length > maxChars) return setError2(true);

      if (description.length > maxChars) {
        toast(
          <CustomToastContent
            title="Error"
            message="Description is above the required length"
          />
        );
        return;
      }

      if (!publishDate || !publishTime || !title || !description) {
        toast(
          <CustomToastContent
            title="Error"
            message="Please complete the required fields to proceed"
          />
        );
        return;
      }

      const dataxx = {
        title,
        description,
        publishDate,
        publishTime,
        published: true,
        churchName: user.churchName,
      };

      const response = await authFetch.post("/devotional/now", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setTitle("");
        setDescription("");
        setPublishDate("");
        setPublishTime("");
        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Form Created Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Devotional</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Topic <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicDescription" className="mb-5">
              <Form.Label>
                Devotion Details <span className="myast">*</span>
              </Form.Label>

              <ReactQuill
                theme="snow"
                placeholder="Enter Devotion Details"
                className="lpmw"
                style={{ height: "200px" }}
                value={description}
                onChange={handleQuillChange}
                required
              />
              <div className="character-count flex items-center justify-end">
                {`${description.length} / ${maxChars}`} characters.
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Publish Date <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Select Date"
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Publish Time <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="Select Time"
                value={publishTime}
                onChange={(e) => setPublishTime(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil4" onClick={publishLater}>
          Save and Publish Later
        </button>
        <button className="jiluil" onClick={publishNow}>
          Publish Now
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDevotional;
