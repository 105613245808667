import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../../calendarcontext/GlobalContext";
import Day from "../../pages/dashboard/Calendar/Day";
import Month from "../../pages/dashboard/Calendar/Month";
import { getMonth } from "../../utils";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import "./printcalendar.css";
import Image from "react-bootstrap/Image";
import dayjs from "dayjs";
import avatar from "../../assets/images/blank2.png";
const PrintCalendar = React.forwardRef((props, ref) => {
  const { logoutUser, events, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getEventById();
  }, [events]);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(`/events`);

      if (data) {
        setEventxs(data.events);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const formattedDate = dayjs(new Date(dayjs().year(), monthIndex)).format(
    "MMMM YYYY"
  );

  const [currenMonth, setCurrentMonth] = useState(getMonth());

  const [lables, setLable] = useState([]);
  const [filterEvents, setEvents] = useState([]);
  const [events1, setEventxs] = useState([]);

  useEffect(() => {
    if (events1?.length) {
      setEvents(events1);
      let uniqueLabels = events1.map((e) => e.eventLabel);
      let filterLbl = Array.from(new Set(uniqueLabels));
      let filt = filterLbl.map((e) => ({ lbl: e, checked: true }));
      setLable(filt);
    }
  }, [events1]);

  // Rest of your component logic
  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const handleChange = (newObj, idx) => {
    let ar = [...lables];
    ar[idx] = newObj;
    setLable(ar);
    let selectedLbls = ar.filter((e) => e.checked);
    let filterEvts = events1.filter((e) =>
      selectedLbls.find((j) => j.lbl === e.eventLabel)
    );
    setEvents(filterEvts);
  };

  return (
    <div ref={ref} className="my-5 mx-5">
      <header className="header-container">
        <div className="logo-container">
          <Image
            className="logo"
            width={30}
            height={30}
            src={
              user?.mProfilePicture?.Location
                ? user?.mProfilePicture?.Location
                : avatar
            }
            roundedCircle
          />
        </div>
        <div className="text-container">
          <p className="text-bold text-xl my-3">
            {user.churchName} - {formattedDate}
          </p>
        </div>
        {/* Add other header elements here if needed */}
      </header>
      <Month month={currenMonth} events={filterEvents} />
    </div>
  );
});

export default PrintCalendar;
