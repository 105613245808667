import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player"; // Import ReactPlayer

const ViewContent = ({ show, setShow, data }) => {
  const handleClose = () => setShow(false);
  const [contentData, setContentData] = useState(null);
  const [fileLocation, setFileLocation] = useState(null);

  useEffect(() => {
    if (data) {
      setContentData(data);
      setFileLocation(data?.url?.Location);
    }
  }, [data]); // Include data in dependency array

  const determineContent = () => {
    // Correct the function name
    if (!fileLocation) return <p>No file found</p>;

    const fileExtension = fileLocation.split(".").pop().toLowerCase();

    switch (fileExtension) {
      case "mp3":
      case "wav":
      case "ogg":
        return <ReactAudioPlayer src={fileLocation} controls />;
      case "mp4":
      case "mov":
      case "avi":
        return (
          <ReactPlayer
            url={fileLocation}
            playing
            controls
            width="100%"
            height="100%"
          />
        );
      case "pdf":
      case "doc":
      case "docx":
        return (
          <iframe
            src={fileLocation}
            width="100%"
            height="500px"
            title="Document Viewer"
          />
        );
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{contentData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex items-center justify-center my-5 text-center">
        {determineContent()}
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewContent;
