import React, { useState, useEffect } from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import { useAppContext } from "../../context/appContext";
import Modal from "react-bootstrap/Modal";

const mobileFeatures = [
  "Devotional",
  "Announcements",
  "Events",
  "Fellowship",
  "Check-in",
  "Livestream",
  "Forms",
  "Volunteers",
  "Learning Center",
];

const EmailSettings = ({ show, setShow }) => {
  const { user, logoutUser } = useAppContext();

  const [newUser, setNewUser] = useState(false);
  const [coursePublish, setCoursePublish] = useState(false);
  const [newevent, setNewEvent] = useState(false);
  const [newAudio, setNewAudio] = useState(false);
  const [newGrowthPlan, setNewGrowthPlan] = useState(false);
  const [addedToGroup, setAddedToGroup] = useState(false);
  const [alert, setAlert] = useState("");
  const [enableWeb, setEnableWeb] = useState(false);
  const [enableMobile, setEnableMobile] = useState(false);
  const [entitlements, setEntitlements] = useState([]);
  const [checkedWebFeatures, setCheckedWebFeatures] = useState({});
  const [checkedMobileFeatures, setCheckedMobileFeatures] = useState({});

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user && user.entitlement) {
      setEntitlements(user.entitlement);
    }
  }, [user]);

  useEffect(() => {
    if (user.emailSettings) {
      setNewUser(user.emailSettings.sendEmailToNewUser);
      setCoursePublish(user.emailSettings.sendEmailForVideoPublished);
      setNewEvent(user.emailSettings.sendEmailForNewEvent);
      setNewAudio(user.emailSettings.sendEmailForNewAudio);
      setNewGrowthPlan(user.emailSettings.sendEmailForNewGrowthPlan);
      setAddedToGroup(user.emailSettings.sendEmailWhenAddedToGroup);
    }

    if (user.mobileFeatures) {
      setCheckedMobileFeatures(user.mobileFeatures);
      if (Object.values(user.mobileFeatures).some((value) => value)) {
        setEnableMobile(true);
      }
    }

    if (user.webFeatures) {
      setCheckedWebFeatures(user.webFeatures);
      if (Object.values(user.webFeatures).some((value) => value)) {
        setEnableWeb(true);
      }
    }
  }, [user]);

  const handleCheckboxChange = (feature, type) => {
    if (type === "web") {
      setCheckedWebFeatures((prevState) => ({
        ...prevState,
        [feature]: !prevState[feature],
      }));
    } else if (type === "mobile") {
      setCheckedMobileFeatures((prevState) => ({
        ...prevState,
        [feature]: !prevState[feature],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentUser = {
      sendEmailToNewUser: newUser,
      sendEmailForVideoPublished: coursePublish,
      sendEmailForNewEvent: newevent,
      sendEmailForNewAudio: newAudio,
      sendEmailForNewGrowthPlan: newGrowthPlan,
      sendEmailWhenAddedToGroup: addedToGroup,
      webFeatures: checkedWebFeatures,
      mobileFeatures: checkedMobileFeatures,
    };

    try {
      const data = await authFetch.patch(
        "/auth/updateEmailSettings",
        currentUser
      );
      if (data.status === 200 || data.status === 201) {
        toast(
          <CustomToastContent title="Success" message="Update Successful!" />
        );
      }
    } catch (error) {
      setAlert("Error updating email settings", "danger");
    }
  };

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  const filteredFeatures = mobileFeatures.filter((feature) =>
    entitlements.includes(feature)
  );

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Church Member Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="flex justify-center mt-5">
          <form className="srgergesss ">
            {alert && <p className="inlinesuccess">{alert}</p>}

            <Form.Group
              controlId="formBasicDescription"
              className="mb-3 flex items-start gap-3 flex-column"
            >
              <p className="text-bold text-lg m-bottom">Access Management</p>
              <Form.Check
                type="checkbox"
                label="Allow members access their account using Churchease website."
                checked={enableWeb}
                onChange={(e) => setEnableWeb(e.target.checked)}
              />
              <Form.Check
                type="checkbox"
                label="Allow members access their account using Churchease mobile."
                checked={enableMobile}
                onChange={(e) => setEnableMobile(e.target.checked)}
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicDescription"
              className="mb-3 flex items-start gap-3 flex-column"
            >
              <p className="text-bold text-lg m-bottom">Manage Web Access</p>
              {filteredFeatures.map((feature, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={`Enable ${feature} `}
                  checked={checkedWebFeatures[feature] || false}
                  onChange={() => handleCheckboxChange(feature, "web")}
                  disabled={!enableWeb}
                  style={{ color: !enableWeb ? "gray" : "inherit" }}
                />
              ))}
            </Form.Group>

            <Form.Group
              controlId="formBasicDescription"
              className="mb-3 flex items-start gap-3 flex-column"
            >
              <p className="text-bold text-lg m-bottom">Manage Mobile Access</p>
              {filteredFeatures.map((feature, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={`Enable ${feature} `}
                  checked={checkedMobileFeatures[feature] || false}
                  onChange={() => handleCheckboxChange(feature, "mobile")}
                  disabled={!enableMobile}
                  style={{ color: !enableMobile ? "gray" : "inherit" }}
                />
              ))}
            </Form.Group>
          </form>
        </Row>
        <div className="leftgivediv">
          <form className="srgergesss">
            {alert && <p className="inlinesuccess">{alert}</p>}

            <Form.Group
              controlId="formBasicDescription"
              className="mb-3 flex items-start gap-3 flex-column"
            >
              <p className="text-bold text-lg m-bottom">
                Manage Email Settings
              </p>
              <Form.Check
                type="checkbox"
                label="Send an email when a new user is created."
                checked={newUser}
                onChange={(e) => setNewUser(e.target.checked)}
              />
              <Form.Check className="flex gap-3 flex-column pb-3 mb-3">
                <Form.Check.Label className="mb-3">
                  Send an email when a new course video is published.{" "}
                  <span style={{ color: "red" }}>(In Review)</span>
                </Form.Check.Label>

                <Form.Check.Label className="mb-3">
                  Send email when a new growth plan is published.{" "}
                  <span style={{ color: "red" }}>(In Review)</span>
                </Form.Check.Label>
                <Form.Check.Label className="mb-3">
                  Send email when a new audio is published.{" "}
                  <span style={{ color: "red" }}>(In Review)</span>
                </Form.Check.Label>
                <Form.Check.Label className="mb-3">
                  Send email when a new event is created.{" "}
                  <span style={{ color: "red" }}>(In Review)</span>
                </Form.Check.Label>
                <Form.Check.Label className="mb-3">
                  Send email when a member is added to a group.{" "}
                  <span style={{ color: "red" }}>(In Review)</span>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleSubmit}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailSettings;
