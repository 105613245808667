import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PriceFaq.css";
import frames from "../../assets/images/Frames.png";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";

const PriceFaq = () => {
  return (
    <Container fluid>
      <Row className="faqbody">
        <Col xs={12} md={6} sm={6}>
          <Accordion flush style={{ textAlign: "left" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  Is there a free version of churchease.com?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                churchease.com has a free 14-day trial which is ideal for all
                churches. Click here to get started with the free trial.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  What is your refund policy?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                We do not offer refunds. If you cancel your plan before the next
                renewal cycle, you will retain access to paid features until the
                end of your subscription period. When your subscription expires,
                you will lose access to paid features and all data associated
                with those features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>Can i change my plan?</span>
              </Accordion.Header>
              <Accordion.Body>
                You can make changes to your plan at any time by changing your
                plan type. However, past the refund period, we offer no refunds
                for downgrades. To change your plan, simply go into the Admin
                section and click on the Billing option. From there, click
                'change plan' and choose your desired plan.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  How much does churchease.com cost?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                churchease.com starts from $60 per month for all features and
                $648 for the annual plan.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  What if i change my mind?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                If you’ve paid for a yearly subscription in the last 30 days,
                and wish to cancel your account, you are entitled to a partial
                refund. Meaning, we will automatically refund you the remaining
                balance from your original purchase. Simply cancel the account
                from the Admin section, and your account will be automatically
                refunded. If you have any questions, you can always reach out to
                payment@churchease.com
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  How secure is churchease.com?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                The security of our customer’s data is our top priority. We use
                advanced security measures to preserve the integrity and privacy
                of your data, and adhere to strict data protection laws.
                monday.com is built with internationally recognized security
                standards and protocols in place, such as ISO/IEC 27001 and
                ISO/IEC 27018.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <span style={{ fontWeight: "700" }}>
                  Do you have mobile apps?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                Yes, the platform is available on both IOS and Android devices.
                You can download them on the Apple or Google Play Stores.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <Image fluid src={frames} className="frames" />
        </Col>
      </Row>
    </Container>
  );
};

export default PriceFaq;
