import React from "react";

import SmallCalendar from "./SmallCalendar";
import Labels from "./Labels";
import "./calendar.css";
import Container from "react-bootstrap/Container";

export default function Sidebar({ lables, handleChange }) {
  return (
    <Container fluid className="sidebarv">
      <SmallCalendar />
      <Labels lables={lables} handleChange={handleChange} />
    </Container>
  );
}
