import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./growthtask.css";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../../utils/validation";

const ReviewPlan = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();
  const [errorMessage1, setErrorMessage1] = useState("");
  const [error, setError] = useState("");
  const [note, setNote] = useState("");

  const [planner, setPlanner] = useState({
    tasks: [
      {
        question: "Example Question 1",
        type: "text",
        text: "",
        response: "",
        submitted: false,
        status: "pending", // Initial status can be "pending", "in progress", "completed", etc.
      },
      // Add more tasks as needed
    ],
  });

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/viewresponse/${id}`);

    const [dataxx] = data;

    setPlanner(dataxx);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  //console.log(planner);

  const handleTaskTextChange = (newValue, taskIndex) => {
    const updatedTasks = [...planner.tasks];
    updatedTasks[taskIndex].response = newValue; // Update the response field
    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const handleRadioChange = (taskIndex, selectedOption) => {
    const updatedTasks = [...planner.tasks];
    updatedTasks[taskIndex].response = selectedOption; // Set the selected option as the response
    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const handleCheckboxChange = (taskIndex, optionValue) => {
    const updatedTasks = [...planner.tasks];
    const selectedOptions = updatedTasks[taskIndex].response || [];

    if (selectedOptions.includes(optionValue)) {
      updatedTasks[taskIndex].response = selectedOptions.filter(
        (option) => option !== optionValue
      );
    } else {
      updatedTasks[taskIndex].response = [...selectedOptions, optionValue];
    }

    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleFeedback = async () => {
    const [trimmedTagDescription] = trimInputs([note]);

    if (!validateLength(trimmedTagDescription, DESCRIPTION_LENGTH_LIMIT)) {
      setError(`Feedback cannot exceed ${DESCRIPTION_LENGTH_LIMIT} words.`);
      return;
    }
    // Prepare the planner object with new elements
    const plannerToSave = {
      ...planner,
      notes: note,
      reviewed: true,
      memberId: id,
    };

    try {
      await authFetch.patch("/learning/updatewithnote", plannerToSave);
      setErrorMessage("Feedback sent successfully");
      // navigate("/auth/growthplan");
      // Optional: Update the planner state if the backend returns the updated planner
      // setPlanner(response.data);
    } catch (error) {
      console.error("Error saving planner:", error);
      setErrorMessage("Failed to save");
    }
  };
  console.log(planner);

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">My Growth Plan</p>
        </Col>
      </Row>
      <Row className="sssssssecwef">
        <div>{planner.title}</div>
        <div className="rt6hi8">
          {planner.percentageCompleted > 0
            ? `${planner.percentageCompleted}% Complete`
            : "Not Started"}
        </div>
      </Row>
      <Row>
        {errorMessage && <p className="inlinesuccess">{errorMessage}</p>}
        {errorMessage1 && <p className="inlineerror">{errorMessage1}</p>}
        {error && <p className="inlineerror">{error}</p>}
        {planner.tasks && planner.tasks.length > 0 ? (
          <div className="taskmain" style={{ paddingBottom: "4rem" }}>
            {planner.tasks.map((plan, index) => (
              <div key={index} className="mb-3">
                <Form.Label htmlFor={`taskInput-${index}`}>
                  {plan.question}
                </Form.Label>

                {plan.type === "text" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    type="text"
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled
                  />
                )}

                {plan.type === "textarea" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    as="textarea"
                    rows={10}
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled
                  />
                )}

                {plan.type === "radio" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="radio"
                      id={`radio-${index}-${optionIndex}`}
                      label={option}
                      name={`radioGroup-${index}`}
                      checked={option === plan.response}
                      onChange={() => handleRadioChange(index, option)}
                      disabled
                    />
                  ))}

                {plan.type === "checkbox" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="checkbox"
                      id={`checkbox-${index}-${optionIndex}`}
                      label={option}
                      name={`checkboxGroup-${index}`}
                      checked={plan.response?.includes(option)}
                      onChange={() => handleCheckboxChange(index, option)}
                      disabled
                    />
                  ))}
              </div>
            ))}
            <hr />
            <div className="flex flex-column justify-start mt-3 items-start gap-3">
              <Form.Label>Review Feedback</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>

            <div className="flex justify-center mt-5 items-center gap-3">
              <button
                className="jiluil"
                onClick={handleFeedback}
                style={{ width: "30%" }}
              >
                Send Review Feedback
              </button>
            </div>
          </div>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ReviewPlan;
