import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import "./invalidsession.css";

const SessionExpired = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate("/", { replace: true });
  };

  const handleNewSession = () => {
    navigate("/registration/new", { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Session Expired | ChurchEase</title>
        <meta
          name="description"
          content="Registration session has expired - ChurchEase"
        />
      </Helmet>

      <Container fluid className="container">
        <Row className="align-items-center min-vh-100">
          <Col xs={12} className="py-5">
            <div className="card">
              <div className="text-center">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faClock} className="icon" />
                </div>
                <h1 className="title">Session Expired</h1>
                <p className="subtitle">
                  The registration session has expired for security purposes.
                  Please request a new session from your church administrator or
                  return to the home page.
                </p>
                <button
                  onClick={handleNewSession}
                  className="button d-flex align-items-center justify-content-center gap-2"
                >
                  <FontAwesomeIcon icon={faRotateLeft} />
                  Request New Session
                </button>
                <button
                  onClick={handleReturn}
                  className="secondary-button d-flex align-items-center justify-content-center gap-2 mt-3"
                >
                  Return to Home
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SessionExpired;
