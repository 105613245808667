import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Table, Badge } from "react-bootstrap";
import { FaBox, FaDownload } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import Loading from "../Loading/Loading";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import { CSVLink } from "react-csv";

import FollowUpx from "../FollowUp/FollowUpAssign";
import axios from "axios";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddMultiple from "../ContainerGroup/AddMultiple";
import Select from "react-select";
import "./peopleheader.css";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const PeopleContact = ({
  isChecked,
  setIsChecked,
  onLoadingChange,
  members,
}) => {
  const quillRef = useRef(null);
  const smsMessageRef = useRef(null);
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const modules = {
    toolbar: {
      container: "#toolbar",
      "custom-dropdown": ["option1", "option2", "option3"],

      // Linking to our custom toolbar
    },
  };

  const handleBgColorChange = (selectedOption) => {
    setEditorBgColor(selectedOption.value);
  };
  const [bgColor, setBgColor] = useState("");
  const [editorBgColor, setEditorBgColor] = useState("white"); // State for the editor's background color

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "purple", label: " Purple" },
    { value: "orange", label: " Orange" },
    { value: "brown", label: " Brown" },
    { value: "black", label: "Black" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightcoral", label: "Light Coral" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
    { value: "lightsalmon", label: "Light Salmon" },
    { value: "lightseagreen", label: "Light Sea Green" },
    { value: "lightcyan", label: "Light Cyan" },

    // Add more colors as needed
  ];

  const insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill.getSelection()?.index ?? 0;
      quill.insertText(cursorPosition, placeholder);
      quill.setSelection(cursorPosition + placeholder.length);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value,
      color: state.data.value === "white" ? "black" : "white",
    }),
    // Add more custom styles if needed
  };

  const [showx, setShowx] = useState(false);
  const handleClosex = () => {
    setShowx(false);
    setMessage("");
    setEmailSubject("");
  };
  const handleShowx = () => setShowx(true);
  useEffect(() => {
    if (user && user.primaryPhoneNumber) {
      getCurrentNumber();
    }
  }, []);

  const [show, setShow] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const handleCloseSMS = () => setShowSMS(false);
  const handleShowSMS = () => {
    setShowSMS(true);
  };
  const [smsMessage, setSMSMessage] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPop, setShowPop] = useState(false);
  const handleShowPop = () => setShowPop(true);
  const handleClosePop = () => setShowPop(false);
  const handleDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [followName, setFollowName] = useState("");
  const [followDesc, setFollowDesc] = useState("");
  const [followDate, setFollowDate] = useState("");
  const [followHow, setFollowHow] = useState("");
  const [followComplete, setFollowComplete] = useState(false && false);
  const [followReason, setFollowReason] = useState([]);
  const [followNote, setFollowNote] = useState("");
  const [checkField, setCheckField] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");

  useEffect(() => {
    if (quillRef.current && message !== null) {
      const quill = quillRef.current.getEditor();
      quill.root.style.backgroundColor = bgColor; // Apply background color directly to Quill's content area

      // Check if the content is different before updating
      if (quill.root.innerHTML !== message) {
        const range = quill.getSelection(); // Save the current cursor position
        quill.clipboard.dangerouslyPasteHTML(message);

        if (range) {
          // Restore the saved cursor position
          quill.setSelection(range.index, range.length);
        }
      }
    }
  }, [message, bgColor]);

  const {
    isLoading,
    deleteMember,
    savedView,
    bulkEmailtoMembers,
    sendBulkSMS,
    updateMember,
    user,
    logoutUser,
    displayAlert,
    setting,
    getAllFollowUp,
    uniquememberdetails,
    getCompleteMembers,
    showAlert,
    getAllSettings,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
    getAllFollowUp();
  }, []);

  const checkedItems = isChecked?.length
    ? isChecked?.map((e) => ` ${e.memail}`)
    : "";

  const userPhone = isChecked?.length
    ? isChecked?.map((e) => ` ${e.mmobilePhone}`)
    : "";

  const handleRadioChange = (e) => {
    setFollowHow(e.target.value);
  };

  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      setFollowReason([...followReason, value]);
    } else {
      setFollowReason(followReason.filter((reason) => reason !== value));
    }
  };

  const userNames =
    isChecked && isChecked.length > 0
      ? `${isChecked[0].mfirstName} ${isChecked[0].mlastName}`
      : "";

  // need to continue from here

  const [churchnumber, setChurchNumber] = useState("");
  // useEffect(() => {
  //   if (user && user.primaryPhoneNumber) {
  //     setChurchNumber(user.primaryPhoneNumber);
  //   }
  // }, [user, user.primaryPhoneNumber]);
  const getCurrentNumber = async () => {
    try {
      const response = await authFetch.get("auth/churchaccount");

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        // console.log("data", churchSettings);
        setChurchNumber(data.churchSettings.primaryPhoneNumber);
      }

      // setChurchNumber(data?.primaryPhoneNumber);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const deleteMany = async () => {
    onLoadingChange(true); // Start loading indicator

    for (const member of isChecked) {
      try {
        await deleteMember(member._id); // Call deleteMember for each member
        handleDelete(); // Handle UI or state update
      } catch (error) {
        console.error("Error in deleteMany:", error);
        onLoadingChange(false); // Stop loading indicator on error
        break; // Exit the loop on error
      }
    }

    setIsChecked([]); // Reset selection
    onLoadingChange(false); // Stop loading indicator when done
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // setEmail(checkedItems);
    if (!message || !emailSubject) {
      setIsError(true);
      return;
    }

    const userMessage = {
      emails: checkedItems,
      message: message,
      subject: emailSubject,
      editorBgColor: editorBgColor,
      userId: user._id,
      senderEmail: user?.memail,
    };

    bulkEmailtoMembers({ userMessage });
    handleClosex();
    //setSuccess(true);
  };

  const handleSMSMessageChange = (e) => {
    setSMSMessage(e.target.value);
  };
  const [isError, setIsError] = useState(false);
  const [isSMSSent, setSMSSent] = useState(false);
  const sendSMS = (e) => {
    e.preventDefault();
    // setSMSNumber(userPhone);

    if (!smsMessage) {
      setIsError(true);
      return;
    }
    const smsData = {
      recipients: userPhone,
      textmessage: smsMessage,
    };

    sendBulkSMS({ smsData });
    setSMSMessage("");
    setSMSSent("Text message sent to selected members");
    setTimeout(() => {
      setSMSSent("");
    }, 3000);
  };

  const isChecked2 = isChecked?.map((obj) => ({
    mfirstName: obj.mfirstName,
    mlastName: obj.mlastName,
    memail: obj.memail,
    mmobile: obj.mmobile,
    mgender: obj.mgender,
    mstatus: obj.mstatus,
    accountStatus: obj.accountStatus,
    mmartial: obj.mmartial,
    mage: obj.mage,
    mgrade: obj.mgrade,
    mschool: obj.mschool,
    memployer: obj.memployer,
    mworkPhone: obj.mworkPhone,
    mhomePhone: obj.mhomePhone,
    mpostal: obj.mpostal,
    mstate: obj.mstate,
    mcity: obj.mcity,
    mBirthday: obj.mBirthday,
    mAnniversary: obj.mAnniversary,
    maddress: obj.maddress,
    familyId: obj.familyId,
    familyRole: obj.familyRole,
  }));

  const handleCSVLinkClick = () => {
    return isChecked2;
  };
  const [isFree, setIsFree] = useState(false);
  useEffect(() => {
    if (user.subscription && user.subscription.length > 0) {
      if (user.subscription[0].plan.nickname === "Free") {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    }
  }, [user.subscription]);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [viewName, setViewName] = useState();

  const maxChars = 2500; // Example maximum

  const insertAtCursorPosition = (content) => {
    // Check if content and smsMessageRef.current are defined
    if (!content || !smsMessageRef.current) {
      console.error("Content or smsMessageRef is undefined");
      return;
    }

    const cursorPosition = smsMessageRef.current.selectionStart;
    const textBeforeCursor = smsMessage
      ? smsMessage.slice(0, cursorPosition)
      : "";
    const textAfterCursor = smsMessage ? smsMessage.slice(cursorPosition) : "";
    const newText = textBeforeCursor + content + textAfterCursor;

    if (newText.length <= maxChars) {
      setSMSMessage(newText);
      setTimeout(() => {
        // Check if smsMessageRef.current is still defined
        if (smsMessageRef.current) {
          smsMessageRef.current.selectionStart =
            smsMessageRef.current.selectionEnd =
              cursorPosition + content.length;
        }
      }, 0);
    }
  };

  const handleVariableInsert = (event) => {
    const variable = event.target.value;
    if (variable === "Select Variables") return; // Ignore default select option
    insertAtCursorPosition(variable);
    event.target.value = "Select Variables"; // Reset dropdown
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const saveView = {
      viewName: viewName,
      viewMembers: isChecked,
      userId: user._id,
    };

    savedView(saveView, { alertText: "View Saved" });
    handleClose1();
    setViewName("");
  };

  const myHandleSubmit = async (e) => {
    e.preventDefault();

    if (!followDate || !followDesc || !followHow || !followReason) {
      displayAlert();
      handleClose();
      return;
    }
    const [fname, lname] = userNames?.split(" ");
    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;

    const checkIt = {
      followUpName: userNames,
      followUpAssignTo: followDesc,
      followupDate: followDate,
      churchName: user?.churchName,
      mfname: fname,
      mlname: lname,
      mId: memberIdOnly?._id,
      followUpCompleteUpdatepDate: followComplete || false,
      followMedia: followHow,
      followReason: followReason,
      followNote: followNote,
      completed: false,
      assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
    };

    try {
      const response = await authFetch.post("/followup/assignto", checkIt);
      if (response.status === 200 || response.status === 201) {
        getAllFollowUp();
        setFollowName("");
        setFollowDesc("");
        setFollowDate("");
        setFollowComplete("");
        setFollowHow("");
        setFollowReason("");
        setFollowNote("");
        handleClose();
        toast(
          <CustomToastContent message="Follow-Up Assigned" title="Success" />
        );
      }
      //navigate("/auth/blogmain");
    } catch (err) {
      console.log(err);
    }
  };
  const [showAddContainer, setShowAddContainer] = useState(false);
  const addToContainer = () => {
    if (isChecked?.length >= 1) {
      setShowAddContainer(true);
    } else {
      handleShowPop();
    }
  };

  const handleDescriptionChange = useCallback(
    debounce((value) => {
      setMessage(value);
    }, 500),
    []
  );

  return (
    <>
      <Col>
        {" "}
        <p className="flex items-center text-center text-bold">QUICK ACTION</p>
        <Table hover responsive>
          <tbody>
            <tr
              onClick={
                isChecked.length > 0 ? handleShow1 : () => handleShowPop()
              }
              className="tabPointer"
            >
              <td>
                <FaEye className="colwh" />
              </td>
              <td className="colwh">
                Save View{" "}
                <Badge bg="secondary">
                  {isChecked.length >= 1 ? isChecked.length : ""}
                </Badge>
              </td>
            </tr>
            <tr
              onClick={
                isChecked.length > 0 ? handleShowx : () => handleShowPop()
              }
              className="tabPointer"
            >
              <td>
                <FaMailBulk className="colwh" />
              </td>
              <td className="colwh">
                Email Members{" "}
                <Badge bg="secondary">
                  {isChecked.length >= 1 ? isChecked.length : ""}
                </Badge>
              </td>
            </tr>

            <tr
              className={isFree ? "disabled" : "tabPointer"}
              onClick={
                isChecked.length > 0 ? handleShowSMS : () => handleShowPop()
              }
            >
              <td>
                <FaMobileAlt className="colwh" />
              </td>
              <td className=" colwh">
                Text Members{" "}
                <Badge bg="secondary">
                  {isChecked.length >= 1 ? isChecked.length : ""}
                </Badge>
              </td>
            </tr>

            <tr
              className="tabPointer"
              onClick={
                isChecked.length > 0
                  ? () => handleCSVLinkClick()
                  : () => handleShowPop()
              }
            >
              <td>
                <FaDownload className="colwh" />
              </td>
              <td>
                {isChecked.length > 0 ? (
                  <CSVLink
                    data={isChecked2} // Data prepared outside this function
                    filename="churchease.csv"
                    onClick={handleCSVLinkClick} // Function to handle click before download
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Export Members{" "}
                    <Badge bg="secondary">
                      {isChecked.length >= 1 ? isChecked.length : ""}
                    </Badge>
                  </CSVLink>
                ) : (
                  <>
                    <span style={{ color: "black" }}>Export Members</span>
                    <Badge bg="secondary">
                      {isChecked.length >= 1 ? isChecked.length : ""}
                    </Badge>
                  </>
                )}
              </td>
            </tr>

            <tr
              className="tabPointer"
              onClick={
                isChecked.length > 0 ? handleShowDelete : () => handleShowPop()
              }
            >
              <td>
                <FaTrash className="colwh" />
              </td>
              <td className="colwh">
                Delete Members{" "}
                <Badge bg="secondary">
                  {isChecked.length >= 1 ? isChecked.length : ""}
                </Badge>
              </td>
            </tr>

            <tr
              className={isFree ? "disabled" : "tabPointer"}
              onClick={
                isChecked.length > 0 ? handleShow : () => handleShowPop()
              }
            >
              <td>
                <FaRunning className="colwh" />
              </td>
              <td className="colwh">
                Follow-Up{" "}
                <OverlayTrigger
                  key="left"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-left`}>
                      Only one member can be selected for follow up.
                    </Tooltip>
                  }
                >
                  <Badge bg="secondary">
                    {isChecked.length >= 1 ? isChecked.length : ""}
                  </Badge>
                </OverlayTrigger>
              </td>
            </tr>
            <tr
              className={isFree ? "disabled" : "tabPointer"}
              onClick={addToContainer}
            >
              <td>
                <FaBox className="colwh" />
              </td>
              <td className="colwh">
                Add to Group{" "}
                <Badge bg="secondary">
                  {isChecked.length > 0 ? isChecked.length : ""}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Modal show={showx} onHide={handleClosex} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Email Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isError && (
            <p className="inlineerror">
              Email subject and a message is required to proceed!
            </p>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                disabled
                value={checkedItems}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <div id="toolbar" className="flex items-center">
              <button className="ql-bold">Bold</button>
              <button className="ql-italic">Italic</button>
              <button className="ql-underline">Underline</button>
              <button className="ql-header" value="1">
                Header 1
              </button>
              <button className="ql-header" value="2">
                Header 2
              </button>
              <button className="ql-list" value="ordered">
                Ordered List
              </button>
              <button className="ql-list" value="bullet">
                Bullet List
              </button>
              <button className="ql-align" value=""></button>
              <button className="ql-align" value="center"></button>
              <button className="ql-align" value="right"></button>
              <button className="ql-align" value="justify"></button>
              <select className="ql-color">
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                <option value="white">White</option>
                {/* Add more colors as needed */}
              </select>

              <select
                className="ql-background"
                onChange={handleBgColorChange}
                placeholder="test"
              >
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
                <option value="pink">Pink</option>
                <option value="gray">Gray</option>
                <option value="pink">Pink</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                {/* Add more colors as needed */}
              </select>
              <Select
                options={colorOptions}
                styles={customStyles}
                onChange={handleBgColorChange}
                placeholder="Background"
              />

              <select className="ql-size">
                <option value="small">Small</option>
                <option selected>Normal</option>
                <option value="large">Large</option>
                <option value="huge">Huge</option>
              </select>
              <button className="ql-link">Link</button>
              <button className="ql-image">Image</button>
            </div>
            <Form.Group
              className="mb-3 mt-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Subject:</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Form.Group>

            <ReactQuill
              ref={quillRef}
              value={message}
              defaultValue={message}
              onChange={handleDescriptionChange}
              theme="snow"
              className="react-quill-editor"
              placeholder="Enter a message here..."
              modules={modules}
              style={{
                backgroundColor: editorBgColor,
                minHeight: "400px",
                marginBottom: "0",
              }}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-start justify-start gap-3 flex-row w-100">
            <button
              onClick={() => insertPlaceholder(`[FirstName]`)}
              className="q3rq3r23"
            >
              First Name
            </button>
            <button
              type="button"
              onClick={() => insertPlaceholder(`[LastName]`)}
              className="q3rq3r23"
            >
              Last Name
            </button>
            <button
              type="button"
              onClick={() => insertPlaceholder(`[Email]`)}
              className="q3rq3r23"
            >
              Email
            </button>
            <button
              type="button"
              onClick={() => insertPlaceholder(`[PhoneNumber]`)}
              className="q3rq3r23"
            >
              Phone Number
            </button>
          </div>
          <div className="flex items-end justify-end gap-3 flex-row ">
            <button onClick={handleClosex} className="jiluil3">
              Close
            </button>
            <button type="button" onClick={sendEmail} className="jiluil">
              Send Email
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected members.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleDelete}>
            Cancel
          </button>
          <button className="jiluil" onClick={deleteMany}>
            Delete Members
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Save View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="View Name"
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
                name="viewName"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex gap-3">
            <button onClick={handleClose1} className="jiluil3">
              Cancel
            </button>
            <button onClick={handleSubmit1} className="jiluil">
              Save View
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showSMS} onHide={handleCloseSMS} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Send SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isError && (
              <p className="inlineerror">
                Text Message is required to proceed!
              </p>
            )}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {isSMSSent && <p className="inlinesuccess">{isSMSSent}</p>}
              <div className="character-count flex items-center justify-end">
                {`${smsMessage.length} / ${maxChars}`} characters. For better
                delivery, use a maximum of 2500 characters.
              </div>
              <Form.Label>From</Form.Label>
              {churchnumber && (
                <Form.Control
                  type="text"
                  autoFocus
                  disabled
                  value={churchnumber ? churchnumber : ""}
                  // onChange={(e) => setSMSNumber(e.target.value)}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Member SMS</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                disabled
                value={userPhone.length >= 1 ? userPhone : ""}
                // onChange={(e) => setSMSNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message:</Form.Label>
              <Form.Control
                as="textarea"
                ref={smsMessageRef}
                rows={6}
                required
                value={smsMessage}
                onChange={handleSMSMessageChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Insert Variable:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleVariableInsert}
                style={{ width: "30%" }}
              >
                <option>Select Variables</option>
                <option value="[FirstName]">First Name</option>
                <option value="[LastName]">Last Name</option>
                <option value="[Email]">Email</option>
                <option value="[PhoneNumber]">Phone Number</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseSMS}>
            Close
          </button>
          <button className="jiluil" onClick={sendSMS}>
            Send SMS
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPop} onHide={handleClosePop}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "600", fontSize: "1em" }}>
            Notification!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          Select an item for this action.
        </Modal.Body>
        <Modal.Footer>
          <button
            className="jiluil"
            style={{ fontSize: "1em" }}
            onClick={handleClosePop}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <AddMultiple
        show={showAddContainer}
        setShow={setShowAddContainer}
        username={isChecked}
      />

      <FollowUpx
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myHandleSubmit={myHandleSubmit}
        uniquememberdetails={uniquememberdetails}
        setting={setting}
        followName={followName}
        setFollowName={setFollowName}
        followDesc={followDesc}
        setFollowDesc={setFollowDesc}
        followDate={followDate}
        setFollowDate={setFollowDate}
        followHow={followHow}
        setFollowHome={setFollowHow}
        followComplete={followComplete}
        setFollowComplete={setFollowComplete}
        handleRadioChange={handleRadioChange}
        checkField={checkField}
        setCheckField={setCheckField}
        followReason={followReason}
        setFollowReason={setFollowReason}
        handleCheckboxChange={handleCheckboxChange}
        followNote={followNote}
        setFollowNote={setFollowNote}
        username={userNames}
      />
    </>
  );
};

export default PeopleContact;
