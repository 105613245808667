import React, { useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import PeopleContainer from "../../../components/People/PeopleContainer";
import PeopleHeader from "../../../components/People/PeopleHeader";
import "./people.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const People = () => {
  const { logoutUser, user } = useAppContext();
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    // Check if the user's role array includes an object with roleName "Standard"
    const hasStandardRole = user.role.some(
      (roleObj) => roleObj.roleName === "Standard"
    );

    if (hasStandardRole) {
      navigate(`/auth/peopleview/${user._id}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [user.role, user._id]); // Ensure the effect depends on user.role and user._id

  return (
    <>
      <PeopleHeader />
      <PeopleContainer />
    </>
  );
};

export default People;
