import React, { useState, useEffect } from "react";
import "../Pricing/pricing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/Navbar/Navbar";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useAppContext } from "../../context/appContext";
import PricingCardUser from "../../components/PriceCard/PricingCardUser";
import DataMigration from "../../components/DataMigration/DataMigration";
import PriceFaq from "../../components/PriceFaq/PriceFaq";
import { Helmet } from "react-helmet";
function GetStartedHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { prices, getPricing } = useAppContext();

  useEffect(() => {
    getPricing();
  }, []);

  useEffect(() => {
    if (prices.length && prices) {
      setPriceValue(prices);
    }
  }, [prices]);

  const [priceValue, setPriceValue] = useState([]);

  return (
    <>
      <Helmet>
        <title>
          Churchease | Affordable Church Management Software Pricing
        </title>
        <meta
          name="description"
          content="Explore affordable pricing plans for Churchease.com, the comprehensive church management software. Flexible options for every church size and budget. Get started today!"
        />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="pricingbody">
            <p className="pricingb">
              Experience Seamless{" "}
              <span style={{ color: "#FE7064" }}>Church Management</span>
            </p>
            <p className="pricingb2">
              We provide 100% service for any package.
            </p>

            <Link to="/signup">
              <Button className="pricebutton">Get Started</Button>
            </Link>
          </div>
        </Row>
        <Row>
          <Col className="classnll" xs={12} sm={12} md xl>
            {priceValue &&
              priceValue?.map((price) => {
                return (
                  <PricingCardUser
                    price={price}
                    color="blue"
                    // handleSubscription={handleClick}
                  />
                );
              })}
          </Col>
        </Row>
        <DataMigration />
        <PriceFaq />
      </Container>
      <Footer />
    </>
  );
}

export default GetStartedHome;
