import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";

const AddCustomPayment = ({ showx, setShowx, handleShowAddCustomClose }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => {
    setShowx(false);
    setUpdate(false);
    setError(false);
    setPaymentName("");
    setPaymentDescription("");
    setPaymentAmount("");
  };
  //const handleShow = () => setShowx(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [paymentName, setPaymentName] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [funds, setFunds] = useState([]);
  const [category, setCategory] = useState("");

  const getFundUnAuth = async () => {
    try {
      const response = await authFetch.get(`/givingunauth/${user.churchName}`, {
        churchName: user.churchName,
      });
      setFunds(response.data);
      // console.log(response.data); // Assuming you want to log the response data
    } catch (err) {
      console.error(err); // Using console.error for logging errors
    }
  };

  useEffect(() => {
    getFundUnAuth();
  }, []);

  const handleAudio = async (e) => {
    e.preventDefault();

    if (!paymentName || !paymentDescription || !paymentAmount || !category) {
      toast(
        <CustomToastContent
          message="Please check all fields and try again!"
          title="error"
        />
      );

      return;
    }

    if (paymentAmount < 1) {
      toast(
        <CustomToastContent
          message="Please enter a valid amount"
          title="error"
        />
      );
      return;
    }
    try {
      const response = await authFetch.post(
        "/payment/createFixedPricePaymentLink",
        {
          paymentName,
          paymentDescription,
          paymentAmount,
          category,
          churchName: user.churchName,
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data);

        setUpdate(true);
        handleShowAddCustomClose();
        handleClose();

        setPaymentName("");
        setPaymentDescription("");
        setPaymentAmount("");
        setCategory("");
        toast(
          <CustomToastContent
            message="Custom Payment Created!"
            title="success"
          />
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={showx} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Custom Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <p className="inlineerror">Please check all fields and try again!</p>
        )}

        {update && <p className="inlinesuccess">Custom Payment Created!</p>}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Name</Form.Label>
          <Form.Control
            type="text"
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="description"
            onChange={(e) => setPaymentDescription(e.target.value)}
            value={paymentDescription}
            className="lpmw"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Amount</Form.Label>
          <Form.Control
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            required
            min={1}
          />
        </Form.Group>
        <Form.Select
          aria-label="Floating label select example"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          style={{ height: "43px" }}
          className="cnm"
        >
          <option value="" style={{ fontSize: "1em" }}>
            Select a giving category
          </option>
          {funds?.map((fundv) => (
            <option value={fundv.fundName} style={{ fontSize: "1em" }}>
              {fundv.fundName}
            </option>
          ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleAudio}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomPayment;
