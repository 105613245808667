import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import nodd from "../../../assets/newi/nodd.png";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loading/Loading";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SavedView = () => {
  const { getSavedView, savedViews, deleteView, isLoading, logoutUser } =
    useAppContext();

  const [show, setShow] = useState(false);
  const [clickedMember, setClickedMember] = useState([]);
  const [savedView, setSavedView] = useState([]);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);

  useEffect(() => {
    if (savedViews && savedViews.length > 0) {
      setSavedView(savedViews);
    }
  }, [savedViews]);

  const handleClose = () => setShow(false);

  const handleShow = (event, saved) => {
    setShow(true);
    setClickedMember(saved);
  };

  const deleteThisMember = () => {
    deleteView(clickedMember._id, {
      alertText: "View Deleted",
    });
    let filt = savedView.filter((item) => item._id !== clickedMember._id);
    setSavedView(filt);
    handleClose();
  };

  useEffect(() => {
    getSavedView();
  }, []);

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...savedView].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setSavedView(sorted);
    } else {
      let sorted = [...savedView].reverse();
      setSavedView(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...savedView].sort((a, b) => {
      if (dateFlag) {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      }
    });
    setSavedView(sorted);
    setDateFlag(!dateFlag);
  };

  return (
    <>
      <Container fluid>
        {savedView && savedView.length > 0 ? (
          <Row className="tabw">
            <p
              style={{
                fontWeight: "700",
                fontSize: "1em",
                color: "var(--primary-color)",
                paddingTop: "2rem",
              }}
            >
              {savedView.length ? (
                <p>
                  {savedView.length} record{savedView.length > 1 ? "s" : ""}{" "}
                  found
                </p>
              ) : (
                <p>No Saved View</p>
              )}
            </p>
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr className="trdataheader">
                  <th onClick={handleDateSort}>
                    SAVED DATE {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleViewName}>
                    VIEW NAME {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th style={{ textAlign: "right" }}>ACTIONS</th>
                </tr>
              </thead>
              {savedView.length > 0 &&
                savedView.map((saved, index) => (
                  <tbody>
                    <tr key={index} className="trdata">
                      <td>{moment(`${saved.createdAt}`).format("LL")}</td>
                      <td>{saved.viewName}</td>

                      <td className="rightco">
                        <Link to={"/auth/savedInfo/" + saved._id}>
                          <button className="jiluil3">View</button>
                        </Link>

                        {/* <FaTrash onClick={(event) => handleShow(event, saved)} /> */}
                        <button
                          className="jiluil"
                          onClick={(event) => handleShow(event, saved)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this view. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil" onClick={deleteThisMember}>
            Delete View
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SavedView;
