import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaRunning } from "react-icons/fa";
import e1 from "../../assets/newi/e1.png";
import e2 from "../../assets/newi/e2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductFollowUp = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Follow Up | Churchease - Streamline Your Follow-Up Process
        </title>
        <meta
          name="description"
          content="Streamline your follow-up process with Churchease. Automate reminders, customize communications, and track interactions effortlessly to keep your community connected."
        />
        <link rel="canonical" href="https://churchease.com/product/followup" />
        <meta
          property="og:title"
          content="Follow Up | Churchease - Streamline Your Follow-Up Process"
        />
        <meta
          property="og:description"
          content="Streamline your follow-up process with Churchease. Automate reminders, customize communications, and track interactions effortlessly to keep your community connected."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/followup"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#243060" }}>
            <div>
              <FaRunning size={100} />
              <p className="headssc">Follow Up</p>
              <p className="headpp">
                Streamline your follow-up process with precision. Automate
                reminders, customize communications, and track interactions
                effortlessly. This tool ensures that every engagement is
                meaningful and efficiently managed, keeping your community
                tightly connected.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for effective follow-ups
            </p>
            <p className="product13">
              Your all-encompassing tool to automate, personalize, and track
              communications, keeping your community engaged and connected.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={e1} alt="Follow Up" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">AUTOMATED REMINDERS</p>
              <p className="proddz1">
                Set up automated reminders for follow-up tasks to ensure no
                engagement opportunity is missed.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CUSTOMIZABLE TEMPLATES</p>
              <p className="proddz1">
                Use customizable communication templates for consistent and
                effective follow-up.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACTIVITY TRACKING</p>
              <p className="proddz1">
                Track follow-up activities and interactions to analyze
                effectiveness and make improvements.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">INTEGRATED COMMUNICATION TOOLS</p>
              <p className="proddz1">
                Leverage integrated tools for email, SMS, and social media to
                streamline all follow-up communications.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">TARGETED OUTREACH</p>
              <p className="proddz1">
                Focus your follow-up efforts with targeted outreach
                capabilities, enhancing individual member attention.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">REPORTS AND INSIGHTS</p>
              <p className="proddz1">
                Utilize detailed reports to gain insights into follow-up impact
                and refine strategies.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={e2} alt="follow Up" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#243060" />
      <Footer />
    </>
  );
};

export default ProductFollowUp;
