import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const DeleteAudio = ({ show, setShow, selectedValue, handleReload }) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/audio`);
    setAudio(data);
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(
        `/learning/audio/${selectedValue._id}`
      );
      if (response.status === 200 || response.status === 201) {
        loadCourse();
        handleClose();
        handleReload();

        toast(
          <CustomToastContent
            message="Audio Deleted Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      toast(
        <CustomToastContent
          message="An error occurred while deleting audio"
          type="error"
        />
      );
    }
  };

  const [audio, setAudio] = useState([]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Audio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        This action cannot be reversed.
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            handleDelete(selectedValue);
            handleClose();
          }}
          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAudio;
