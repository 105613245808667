import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import "../../components/SearchContainer/searchcontainer.css";
import Button from "react-bootstrap/Button";
import "./coursenav.css";
import { AssignedCourse } from "../../pages/dashboard";

const CourseNav = () => {
  const navigate = useNavigate();

  const createCourse = () => {
    navigate("/auth/addcourse");
  };

  const learningMain = () => {
    navigate("/auth/learningmain");
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Learning Dashboard</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8" style={{ gap: "10px" }}>
            <Button className="wefwefrrg" onClick={createCourse}>
              Create Learning
            </Button>
            <Button className="t45rg54" onClick={learningMain}>
              Public View
            </Button>
            <Button className="s3fw3f23" onClick={AssignedCourse}>
              Assigned Courses
            </Button>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CourseNav;
