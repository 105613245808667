import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

import axios from "axios";
const EditOnlineGiving = ({ show, setShow, refreshData }) => {
  const { user, getOnlineGiving, OnlineGiving } = useAppContext();
  const [error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const [pk, setPublicKey] = useState("");
  const [sk, setSecretKey] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setSecretKey(e.target.value);
  };

  useEffect(() => {
    if (user) {
      setEmail(user?.churchStripeEmail);
      setPublicKey(user?.pk);
      setSecretKey(user?.sk);
    }
  }, [user]);

  const handleEdit = async (e) => {
    e.preventDefault();

    // Prepare your donation data
    const donationData = {
      pk,
      sk,
      memail: email,
      type: "stripe",
      subscribers: [],
      churchName: user?.churchName,
      churchId: user?._id,
    };

    // Make an HTTP PUT request to submit the donation data
    // const { data } = await authFetch.put(
    //   "/payment/online-giving",
    //   donationData
    // );

    OnlineGiving(donationData);
    getOnlineGiving();
    handleClose();
    toast(
      <CustomToastContent body="Payment Setup Updated" variant="success" />
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Payment Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="inlineerror">Please enter a workflow name</p>}
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="labfont">Stripe Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="labfont">Public Key</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Public Key"
              value={pk}
              onChange={(e) => setPublicKey(e.target.value)}
              name="publicKey"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="labfont">Secret Key</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Secret Key"
              value={sk || ""}
              onChange={handleChange}
              name="secretKey"
            />
          </Form.Group>

          {/* <Button className="wedweded mt-3" onClick={handleEdit}>
            {isLoading ? <Loading /> : "Submit"}
          </Button> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleEdit}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOnlineGiving;
