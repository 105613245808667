import React from "react";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import "./steps.css";

const Step1 = ({ formData, handleChange, handleCompletionChange }) => {
  const { user } = useAppContext();

  return (
    <div className="pt-4">
      <p className="text-lg text-bold pb-4">Church Information</p>
      <Form.Group className="mb-3" controlId="formFirstName">
        <Form.Label style={{ fontWeight: "600" }}>First Name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
          className="forminput"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formLastName">
        <Form.Label style={{ fontWeight: "600" }}>Last Name</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          className="forminput"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formChurchName">
        <Form.Label style={{ fontWeight: "600" }}>Church Name</Form.Label>
        <Form.Control
          type="text"
          name="churchName"
          placeholder="Church Name"
          value={formData.churchName}
          onChange={handleChange}
          className="forminput"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formWebsite">
        <Form.Label style={{ fontWeight: "600" }}>Website</Form.Label>
        <Form.Control
          type="text"
          name="website"
          placeholder="Website"
          value={formData.website}
          onChange={handleChange}
          className="forminput"
        />
      </Form.Group>

      <div className="d-flex justify-content-end">
        <Form.Check
          type="checkbox"
          id="step1Complete"
          label="Mark as Completed"
          checked={formData.step1Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Step1;
