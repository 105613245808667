import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./error.css";
import { Link } from "react-router-dom";

const Error = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <Row className="container2">
          <div className="error">
            <p className="text1">OOPS!</p>
            <p className="text2">
              We can't seem to find the page you're looking for.
            </p>
            <p className="text3">Error Code: 404</p>
            <p className="text4">Here are some helpful links instead:</p>
            <p>
              <Link to="/" className="single5">
                Home
              </Link>
            </p>
            <p>
              <Link to="contact us" className="single5">
                Contact us
              </Link>
            </p>
            <p>
              <Link to="pricing" className="single5">
                Pricing
              </Link>
            </p>
            <p>
              <Link to="login" className="single5">
                Sign In
              </Link>
            </p>
            <p>
              <Link to="register" className="single5">
                Sign up
              </Link>
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Error;
