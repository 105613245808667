import React, { useState, useEffect } from "react";
import "./pricing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useAppContext } from "../../context/appContext";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PricingCard from "../../components/PriceCard/PricingCard";
import DataMigration from "../../components/DataMigration/DataMigration";
import PriceFaq from "../../components/PriceFaq/PriceFaq";
import { Helmet } from "react-helmet";
import axios from "axios";
import PriceDetails from "../../components/Price/PriceDetails";
import {
  FaBible,
  FaCalculator,
  FaCalendar,
  FaChartPie,
  FaCheck,
  FaChess,
  FaChild,
  FaEnvelope,
  FaFont,
  FaMapMarked,
  FaMicrophone,
  FaMoneyBill,
  FaPen,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
} from "react-icons/fa";

const allFeatures = [
  {
    name: "People",
    cost: 0,
    description: (
      <span>
        Manage church member details and records. <br />
        <a
          href="https://churchease.com/product/people"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaUserAlt />,
  },
  {
    name: "Church Giving",
    cost: 9,

    description: (
      <span>
        Facilitate tithes, offerings, and donations digitally. <br />
        <a
          href="https://churchease.com/product/church-giving"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaMoneyBill />,
  },
  {
    name: "Church Accounting",
    cost: 7,

    description: (
      <span>
        Manage your church’s finances with ease using our Church Accounting
        feature.
        <br />
        <a
          href="https://churchease.com/product/church-accounting"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaCalculator />,
  },
  {
    name: "Ministry",
    cost: 6,

    description: (
      <span>
        Tools for managing various ministries within the church.
        <br />
        <a
          href="https://churchease.com/product/ministry"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaBible />,
  },
  {
    name: "Groups",
    cost: 8,

    description: (
      <span>
        Organize and manage small groups for better engagement.
        <br />
        <a
          href="https://churchease.com/product/groups"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaPeopleArrows />,
  },
  {
    name: "Follow-Up",
    cost: 6,
    description: (
      <span>
        Track and follow up with visitors and members.
        <br />
        <a
          href="https://churchease.com/product/followup"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaRunning />,
  },
  {
    name: "Events",
    cost: 8,

    description: (
      <span>
        Plan and manage church events seamlessly.
        <br />
        <a
          href="https://churchease.com/product/events"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",
    cost: 5,

    description: (
      <span>
        Schedule and plan services, including volunteers and resources.
        <br />
        <a
          href="https://churchease.com/product/service-planner"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaMapMarked />,
  },
  {
    name: "Forms",
    cost: 7,

    description: (
      <span>
        Create custom forms for surveys, signups, and more.
        <br />
        <a
          href="https://churchease.com/product/forms"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaFont />,
  },
  {
    name: "Fellowship",
    cost: 6,

    description: (
      <span>
        Enhance the sense of community and fellowship within the church.
        <br />
        <a
          href="https://churchease.com/product/fellowship"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaPray />,
  },
  {
    name: "Learning Center",
    cost: 10,

    description: (
      <span>
        Educational tools for discipleship and learning.
        <br />
        <a
          href="https://churchease.com/product/learning-center"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaPen />,
  },
  {
    name: "Live Streaming",
    cost: 7,

    description: (
      <span>
        Broadcast your services and events live to reach a wider audience.
        <br />
        <a
          href="https://churchease.com/product/livestream"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaVideo />,
  },

  {
    name: "Communication",
    cost: 5,

    description: (
      <span>
        Comprehensive tools for effective communication within the church
        community.
        <br />
        <a
          href="https://churchease.com/product/messaging"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaChartPie />,
  },
  {
    name: "Children",
    cost: 9,

    description: (
      <span>
        Streamline children's management and development with automated,
        round-the-clock support.
        <br />
        <a
          href="https://churchease.com/product/children"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FE7064", marginTop: "1.3rem", fontSize: "0.8em" }} // Changed from paddingTop to marginTop
        >
          Learn more
        </a>
      </span>
    ),
    image: <FaChild />,
  },
];

const levelWeights = {
  L1: 0.4,
  L2: 0.5,
  L3: 0.65,
  L4: 0.85,
  L5: 1.2,
  L6: 1.4,
  L7: 1.8,
  L8: 2.0,
};

function Pricing() {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [size, setSize] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState(["People"]); // 'People' selected by default as it's free
  const [totalPrice, setTotalPrice] = useState(0);
  const [show, setShow] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [hideRow, setHideRow] = useState(true);

  useEffect(() => {
    if (size) {
      setSizeShow(true);
    }
  }, [size]);

  useEffect(() => {
    const sumProductCost = selectedFeatures.reduce((total, featureName) => {
      const feature = allFeatures.find((f) => f.name === featureName);
      return total + (feature ? feature.cost : 0);
    }, 0);

    const weight = levelWeights[size] || 0;
    setTotalPrice(sumProductCost * weight);
  }, [selectedFeatures, size]);

  const handleFeatureChange = (feature, isChecked) => {
    setSelectedFeatures((prev) =>
      isChecked
        ? [...prev, feature.name]
        : prev.filter((f) => f !== feature.name)
    );
  };

  const handlePriceDetails = () => {
    setShow(true);
    setSizeShow(false);
    setHideRow(false);
  };

  return (
    <>
      <Helmet>
        <title>Pricing | Churchease - Affordable Church Management Plans</title>
        <meta
          name="description"
          content="Explore affordable pricing plans for Churchease.com, the comprehensive church management software. Flexible options for every church size and budget. Get started today with 100% service guaranteed on every package!"
        />
        <link rel="canonical" href="https://churchease.com/pricing" />
        <meta
          property="og:title"
          content="Pricing | Churchease - Affordable Church Management Plans"
        />
        <meta
          property="og:description"
          content="Explore affordable pricing plans for Churchease.com, the comprehensive church management software. Flexible options for every church size and budget. Get started today with 100% service guaranteed on every package!"
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta property="og:url" content="https://churchease.com/pricing" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Churchease, contact Churchease, support, church management software support, church software, customer service"
        />
      </Helmet>
      <Header />
      <Container fluid>
        {hideRow && (
          <Row>
            <div className="pricingbody">
              <p className="pricingb">
                Unlock Effortless{" "}
                <span style={{ color: "#FE7064" }}>Church Management</span> with
                Our Packages
              </p>
              <p className="pricingb2">
                Your Satisfaction is 100% Guaranteed – Choose the Perfect Plan
                for Your Church
              </p>

              {/* <Link to="/signup">
                <Button className="pricebutton">Get Started</Button>
              </Link> */}
            </div>
          </Row>
        )}
        {hideRow && (
          <Row className="flex justify-center">
            <div className="flex flex-column items-center justify-center pb-5">
              <div className="prwis">
                <Form.Label className="label-feature">
                  Select Church Size
                </Form.Label>
                <Form.Select
                  className="select mb-4"
                  aria-label="Select church size"
                  onChange={(e) => setSize(e.target.value)}
                  value={size}
                  style={{ height: "60px", fontSize: "1.1em" }}
                >
                  <option value="">Church Size</option>
                  <option value="L1">1 - 50 members</option>
                  <option value="L2">51 - 100 members</option>
                  <option value="L3">101 - 200 members</option>
                  <option value="L4">201 - 300 members</option>
                  <option value="L5">301 - 400 members</option>
                  <option value="L6">401 - 500 members</option>
                  <option value="L7">501 - 1000 members</option>
                  <option value="L8">1001 - 2000 members</option>
                </Form.Select>
              </div>

              {sizeShow && (
                <div className="feature-selection">
                  {/* <div className="flex justify-end">
                    <h5 className="mt-5 text-bold">
                      Total Price: ${totalPrice.toFixed(2)}
                    </h5>
                  </div> */}

                  <Row className="princpass">
                    <Form.Label className="label-feature22">
                      Select Features
                    </Form.Label>
                    {allFeatures.map((feature, index) => (
                      <Col
                        key={index}
                        className="feature-item gap-3"
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                      >
                        <div className="flex justify-between flex-row">
                          <Form.Check
                            type="checkbox"
                            id={`feature-${feature.name}`}
                            label={feature.name}
                            onChange={(e) =>
                              handleFeatureChange(feature, e.target.checked)
                            }
                            checked={selectedFeatures.includes(feature.name)}
                            disabled={feature.name === "People"} // 'People' feature disabled by default
                            className="feature-checkbox"
                          />
                          <div
                            style={{
                              fontSize: "2.5em",
                              color: "var(--primary-color)",
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            {feature.image}
                          </div>
                        </div>
                        <p className="detpriss">{feature.description}</p>
                      </Col>
                    ))}
                  </Row>
                  <div className="feature-footer">
                    Total Price: ${totalPrice.toFixed(2)}
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="pricebutton"
                      onClick={handlePriceDetails}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Row>
        )}
        {show && (
          <PriceDetails
            totalPrice={totalPrice}
            selectedFeatures={selectedFeatures}
            size={size}
          />
        )}
      </Container>
    </>
  );
}

export default Pricing;
