import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import Alert from "../Alert/Alert";

const DeleteTag = ({ show4, setShow4, tagId, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const { logoutUser, deleteContainer, showAlert, getContainers } =
    useAppContext();

  const handleDelete = async () => {
    // deleteContainer(tagId);
    // handleReload();
    // handleClose4(); // Assuming this is for closing a modal or similar
    try {
      const response = await authFetch.delete(`/container/${tagId}`);
      if (response.status === 200 || response.status === 201) {
        getContainers();
        handleReload();
        handleClose4();
        toast(
          <CustomToastContent title="Success" message="Delete Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={show4} onHide={handleClose4}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Group</Modal.Title>
      </Modal.Header>
      {showAlert && <Alert />}
      <Modal.Body>
        This action will permanently delete the selected members.
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose4}>
          Close
        </button>
        <button className="jiluil" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTag;
