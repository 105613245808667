import React from "react";
import { Table } from "react-bootstrap";
import ActionDropdown from "./ActionDropdown";

const FormRow = ({ form, handlers }) => (
  <tr className="trdata">
    <td style={{ width: "60%" }}>{form.title}</td>
    <td style={{ width: "40%", textAlign: "right" }}>
      <ActionDropdown form={form} handlers={handlers} />
    </td>
  </tr>
);

const UpdatedFormsTable = ({ forms, handlers }) => (
  <Table hover style={{ backgroundColor: "#f7f7f7" }}>
    <tbody>
      {forms.map((form, index) => (
        <FormRow key={index} form={form} handlers={handlers} />
      ))}
    </tbody>
  </Table>
);

export default UpdatedFormsTable;
