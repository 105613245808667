import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const StandardUserGiving = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();
  const [category, setCategory] = useState("");
  const [response, setResponse] = useState({});
  const [scroll, setScroll] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [date, setDate] = useState("");
  const [sureData, setSureData] = useState({ url: "none" });
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("generate-payment-link");
  const [usrData, setUsrData] = useState({});
  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [interval, setInterval] = useState("");
  const [funds, setFunds] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [churchAccountData, setChurchAccountData] = useState({});

  const churchAccount = async () => {
    try {
      const response = await authFetch.get(`auth/churchaccount`);

      setChurchAccountData(response.data.churchSettings);
      // console.log(response.data); // Assuming you want to log the response data
    } catch (err) {
      console.error(err); // Using console.error for logging errors
    }
  };

  const getFundUnAuth = async () => {
    try {
      const response = await authFetch.get(`/givingunauth/${user.churchName}`, {
        churchName: user.churchName,
      });
      setFunds(response.data);
      // console.log(response.data); // Assuming you want to log the response data
    } catch (err) {
      console.error(err); // Using console.error for logging errors
    }
  };

  useEffect(() => {
    getFundUnAuth();
    churchAccount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!amount || !date || !interval || !category) {
      setError(true);
      return;
    }
    setLoading(true);

    if (frequency === "reoccurring") {
      navigate("/auth/standardsubpayment", {
        state: {
          pk: churchAccountData.pk, // Assign key for pk
          sk: churchAccountData.sk, // Assign key for sk
          churchName: churchAccountData.churchName, // Assign key for churchName
          email: user.memail, // Assign key for email
          amount,
          interval,
          date,
          category,
          currency: churchAccountData?.currency, // Use optional chaining for user currency
        },
      });
    }
  };

  const handleSubmitOneTime = async (e) => {
    e.preventDefault();

    if (!amount || !category) {
      setError(true);
      return;
    }

    setLoading(true);

    const Fdata = {
      amount: amount,
      email: user.memail,
      userId: user?._id,
      category: category,
      churchName: churchAccountData.churchName,
      currency: churchAccountData.currency,
    };

    try {
      // First Axios GET request
      // const response = await authFetch.get(`/payment/custom-one-time`);

      // Second Axios PUT request with data from the first request
      const { data } = await authFetch.post(
        `/payment/custom/create-checkout-session-one-time`,
        {
          Fdata,
        }
      );

      const clientSecret = data.clientSecret;
      const currency = data.currency;
      const amount = data.amount;

      if (data) {
        navigate("/auth/standardpayelements", {
          state: {
            clientSecret,
            pkValue: churchAccountData.pk,
            skValue: churchAccountData.sk,
            currency: churchAccountData.currency,
            amount,
            churchName: churchAccountData.churchName,
          },
        });
      }

      if (data.error) {
        window.alert(data.error);
        setLoading(false);
        return;
      }

      // setResponse((prevState) => ({ ...prevState, url: data.sessionUrl }));
      setLoading(false);
    } catch (error) {
      //console.error("Error in handleSubmitOneTime:", error);
      setLoading(false);
      // Optionally, you might want to handle the error in a user-friendly way
    }
  };

  useEffect(() => {
    setSureData(response);
  }, [response]);
  return (
    <>
      <Helmet>
        <title>Contribution Page</title>
        <meta name="description" content="Contribution Page - Churchease" />
      </Helmet>

      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", margin: "0" }}>
          <div
            className="flex items-center flex-column wrrerx"
            style={{ marginBottom: "5rem" }}
          >
            <Form className="formdivfdssx">
              {emailError && (
                <p className="mb-5 inlineerror">
                  Invalid Email. Please try again!{" "}
                </p>
              )}
              <div className="flex flex-column">
                <p className="awwq1 mb-4">Step 1</p>
                <p className="awwq mb-4">Contribution Preference</p>
              </div>
              <Form.Group className="mb-3">
                {error && (
                  <p className="inlineerror">
                    Please complete all fields to proceed!
                  </p>
                )}
                <Form.Label className="text-black">
                  Select Payment Frequency{" "}
                  <span style={{ color: "#454843" }}>*</span>
                </Form.Label>
                <Form.Select
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  className="cnm"
                  style={{ height: "43px" }}
                >
                  <option value="" selected="selected">
                    Select
                  </option>
                  <option value="one-time">Immediately - One-Time</option>
                  <option value="reoccurring">Reoccurring</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Email <span style={{ color: "#454843" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={user.memail}
                  onChange={(e) => setEmail(e.target.value)}
                  className="forminput cnm"
                  disabled
                />
              </Form.Group>
              {frequency === "reoccurring" && (
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-black">
                      Select Plan <span style={{ color: "#454843" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      value={interval}
                      onChange={(e) => setInterval(e.target.value)}
                      className="cnm"
                      style={{ height: "43px" }}
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      <option value="day">Daily</option>
                      <option value="week">Weekly</option>
                      <option value="month">Monthly</option>
                      <option value="year">Yearly</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-black">
                      Select Start Date{" "}
                      <span style={{ color: "#454843" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      type="date"
                      id="date"
                      placeholder="Start Date"
                      value={moment(date)
                        .tz(user?.timeZone || moment.tz.guess())
                        .format("YYYY-MM-DD")}
                      onChange={(e) => {
                        const localDate = moment.tz(
                          e.target.value,
                          "YYYY-MM-DD",
                          user?.timeZone || moment.tz.guess()
                        );
                        setDate(localDate.toDate());
                      }}
                      min={moment()
                        .tz(user?.timeZone || moment.tz.guess())
                        .format("YYYY-MM-DD")}
                      className="forminput cnm"
                    />
                  </Form.Group>
                </div>
              )}

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Amount <span style={{ color: "#454843" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  id="amount"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="forminput cnm"
                  min="1"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="text-black">
                  Contribution Information{" "}
                  <span style={{ color: "#454843" }}>*</span>
                </Form.Label>

                <Form.Select
                  aria-label="Floating label select example"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  style={{ height: "43px" }}
                  className="cnm"
                >
                  <option value="" style={{ fontSize: "1em" }}>
                    Select a giving category
                  </option>
                  {funds?.map((fundv) => (
                    <option value={fundv.fundName} style={{ fontSize: "1em" }}>
                      {fundv.fundName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <div className="space-y-2 text-clip  pt-4 flex justify-center">
                {sureData.url === undefined ? (
                  <button
                    className="jiluil"
                    onClick={
                      frequency === "one-time"
                        ? handleSubmitOneTime
                        : handleSubmit
                    }
                    style={{ width: "100%" }}
                  >
                    {loading ? <Loading /> : "NEXT"}
                  </button>
                ) : (
                  <a
                    className="jiluil"
                    style={{ textDecoration: "none" }}
                    href={sureData.url}
                  >
                    Process payment
                  </a>
                )}
              </div>
            </Form>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StandardUserGiving;
