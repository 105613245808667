import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useAppContext } from "../../../context/appContext";
import Loading from "../../../components/Loading/Loading";
import { Row, Col, Modal } from "react-bootstrap";
import moment from "moment";
import "./standard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { currencyFormatter } from "../../../utils/helpers";
const StandardAnnouncements = () => {
  const {
    showStats,
    isLoading,
    getEvents,
    events,
    followups,
    user,
    logoutUser,
    schedule,
  } = useAppContext();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    getAnnouncement();
  }, []);

  const getAnnouncement = async () => {
    try {
      const { data } = await authFetch.get(`/announcement`);
      setAnnouncement(data);
    } catch (err) {
      console.error(err);
    }
  };

  const [announcementDetails, setAnnouncementDetails] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const showAnnonoucement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setAnnouncementDetails(true);
  };

  const handleClose = () => {
    setAnnouncementDetails(false);
  };

  return (
    <>
      <Container fluid>
        <Row className="dashRowxx">
          <div className="cardTit33">Announcements</div>
          {announcement.length > 0 ? (
            announcement.map((announcement, index) => (
              <Col
                key={index}
                sm={12}
                className="colsm"
                style={{ paddingBottom: "1rem" }}
              >
                <div className="dicy">
                  <div className="cardsrolldicy">
                    <div
                      key={index}
                      className="upcoming pb-1 cardCoke flex flex-column"
                      style={{
                        backgroundColor: announcement?.color,
                        color: "#fff",
                      }}
                    >
                      <div
                        className="cardTixx"
                        onClick={() => showAnnonoucement(announcement)}
                      >
                        <img
                          src={announcement?.annoucementImage?.Location}
                          alt="Announcement"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                        <div className="calsdd">
                          <p
                            style={{ marginBottom: "0px" }}
                            className="smalltext"
                          >
                            {moment(announcement?.createdAt).format(
                              "MMM D, YYYY"
                            )}
                          </p>
                          <p className="annosu">
                            {announcement?.annoucementName}
                          </p>
                          <p style={{ lineHeight: 2 }}>
                            {announcement?.announcementDescription?.substring(
                              0,
                              150
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </Row>
      </Container>

      <Modal show={announcementDetails} onHide={() => handleClose()} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex items-center justify-center my-5 text-center">
          <div className="flex flex-col items-start justify-center yyyanno">
            <div className="flex justify-center w-100">
              <img
                src={selectedAnnouncement?.annoucementImage?.Location}
                alt="Announcement"
                className="viewam"
              />
            </div>
            <div className="mt-2 viewheader">
              {selectedAnnouncement?.annoucementName}
            </div>
            <div className="mt-2 viewbody">
              {selectedAnnouncement?.announcementDescription}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={() => handleClose()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StandardAnnouncements;
