import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripeTestPromise = loadStripe(
  "pk_test_PWc1aQQpnU41HrNFMxgKXDjw00wLp9s0uD"
);

const CardInfo = ({ values }) => {
  return (
    <>
      <Elements stripe={stripeTestPromise}>
        <CheckoutForm values={values} />
      </Elements>
    </>
  );
};

export default CardInfo;
