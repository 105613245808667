import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const AssignCourse = ({
  show5,
  setShow5,
  assigned,
  setAssigned,

  memberIdOnly,
  setMemberIdOnly,
  course,
  setAssignedResponse,
  assignedResponse,
}) => {
  const {
    logoutUser,
    completemembers,
    user,
    uniquecontainer,
    getContainers,
    containers,
    getCompleteMembers,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [allTag, setAllTag] = useState([]);
  const [group, setGroup] = useState("");
  const [giverName, setGiverName] = useState("");
  const [selectedName, setSelectedName] = useState([]);
  const [groupValue, setGroupValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [notice, setNotice] = useState(false);

  useEffect(() => {
    if (course) {
      setSelectedValue(course);
    }
  }, [course]);
  const allTags = async () => {
    const { data } = await authFetch.get(`/tags/all-tags`);
    setAllTag(data);
  };

  useEffect(() => {
    allTags();
  }, []);

  useEffect(() => {
    getContainers();
  }, []);

  const handleClose5 = () => setShow5(false);
  // const handleChange5 = (value) => {
  //   // console.log(`selected ${value}`);
  //   setGroup(value);
  // };

  const handleChangex = (value) => {
    //console.log(`selected ${value}`);
    setGroup(value);
  };

  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
    setSelectedName([...selectedName, searchTerm]);
  };

  const transformedContainers = containers.map((container) => ({
    ...container,
    label: container.name, // Setting the label equal to the name field
    value: container._id, // Setting the id equal to the _id field
  }));
  // const handleShareWithMember = async () => {
  //   try {
  //     const [fname, lname] = giverName.split(" ");
  //     const { data } = await authFetch(
  //       `/members/getmemberidonly/${fname}/${lname}`
  //     );
  //     const [memberIdOnly] = data;
  //     const sharedAudio = {
  //       mid: memberIdOnly._id,
  //     };

  //     await authFetch.put(
  //       `/learning/share-audio/${selectedValue._id}`,
  //       sharedAudio
  //     );
  //     setNotice(true);
  //     setGiverName("");
  //     setSelectedName([]);
  //     getContainers();
  //     getCompleteMembers();
  //     // handleClose6();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const handleTest = (value) => {
  //   setGroupValue(value);
  // };

  const handleAssign = async () => {
    const [fname, lname] = giverName.split(" ");

    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;
    setMemberIdOnly(memberIdOnly);
    const assignData = {
      assignName: giverName,
      assignedDate: new Date(),
      courseName: course.name,
      courseId: course._id,
      assignedBy: user.mfirstName + " " + user.mlastName,
      mId: memberIdOnly._id,
      churchName: user.churchName,
      lessonsLength: course.lessons.length,
      assigned: user._id,
    };

    await authFetch.patch(
      `/members/updatemembercourse/${fname}/${lname}/${course._id}`,
      assignData
    );

    const assignedCourse = await authFetch.post(
      `/learning/assign-course`,
      assignData
    );

    setAssignedResponse(assignedCourse.data.msg);
    setSelectedName([]);
    setGiverName("");
    setNotice(true);
  };

  const handleShareGroup = async () => {
    //console.log(groupValue);
    try {
      const assignedData = {
        group: groupValue,
        selectedValue: selectedValue._id,
      };
      const assignedCoursec = {
        assigned,
        assignName: giverName,
        assignedDate: new Date(),
        assignedBy: user.mfirstName + " " + user.mlastName,
        courseId: selectedValue._id,
        courseName: selectedValue.name,
        lessonslength: selectedValue.lessons.length,
        group: groupValue,
        churchName: user.churchName,
      };

      await authFetch.patch(`/learning/assign-course-group`, assignedData);
      const assignedCourse = await authFetch.post(
        `/learning/assign-course`,
        assignedCoursec
      );
      setGroupValue([]);
      getContainers();
      getCompleteMembers();
      setNotice(true);
      //handleClose5();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title="Share Courses"
      open={show5}
      onOk={handleClose5}
      onCancel={handleClose5}
    >
      {notice && <p className="inlinesuccess">Course Assign Successful!</p>}
      <p>You share this audio with members or groups of your community.</p>
      <div className="flex flex-column w-100">
        <div className="flex flex-column w-100">
          <Select
            style={{ width: "100%" }}
            placeholder="Select Group"
            onChange={handleChangex}
            value={group}
            options={[
              {
                value: "member",
                label: "Members",
              },
              {
                value: "group",
                label: "Groups",
              },
            ]}
          />

          {group === "group" && (
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                onChange={(e) => setGroupValue(e)}
                options={transformedContainers}
                value={groupValue}
                className="
                  my-3"
              />
              <button
                disabled={groupValue.length === 0}
                className="jiluil3"
                onClick={handleShareGroup}
              >
                <PlusOutlined classID="mccccc" /> Share with Groups
              </button>
            </div>
          )}

          {group === "member" && (
            <div>
              <div className="flex items-start flex-column justify-between  ">
                <Form.Control
                  type="text"
                  value={giverName}
                  onChange={onChange}
                  required
                  placeholder="Enter Member Name"
                  className="my-3 "
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = giverName.toLowerCase();
                    const fname = item.mfirstName.toLowerCase();
                    const lname = item.mlastName.toLowerCase();
                    const fullname = fname + lname;
                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        onSearch(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
              <button
                disabled={!giverName}
                className="jiluil3"
                onClick={handleAssign}
              >
                <PlusOutlined classID="mccccc" /> Share with Member
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignCourse;
