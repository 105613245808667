import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import he from "he";
import ReactMarkdown from "react-markdown";
import Efficiency from "../../components/Efficiency/Efficiency";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

const BlogView = () => {
  const { slug } = useParams();

  const [post, setPost] = useState({});
  const { logoutUser } = useAppContext();
  const [markdownContent, setMarkdownContent] = useState("");

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const fetchPosts = async () => {
    const test = slug;
    try {
      const encodedSlug = encodeURIComponent(test);
      const response = await authFetch.get(`/unblog/${encodedSlug}`);

      setPost(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    if (slug) fetchPosts();
  }, [slug]);
  const [decodedHtml, setDecodedHtml] = useState("");

  useEffect(() => {
    if (post && post.blogDescription) {
      const decoded = he.decode(post.blogDescription);
      setDecodedHtml(decoded);
    }
  }, [post]);
  return (
    <>
      <Helmet>
        <title>{post.blogTitle}</title>
        <meta name="description" content={post.homeIntro} />
        <link
          rel="canonical"
          href={`https://churchease.com/blogview/${slug}`}
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={post.blogTitle} />
        <meta property="og:description" content={post.homeIntro} />
        <meta
          property="og:image"
          content={post.image ? post.image.Location : "/course.png"}
        />
        <meta
          property="og:url"
          content={`https://churchease.com/blogview/${slug}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Churchease" />
        <meta property="og:locale" content="en_US" />
      </Helmet>

      <Header />
      <Container fluid>
        <Row>
          <div
            className="postheader newpoblog"
            style={{
              marginBottom: "3rem",
            }}
          >
            <div
              className="smalltext2 flex gap-3 mb-3"
              style={{ minWidth: "100%" }}
            >
              <span className="bpadins">{post?.category?.toUpperCase()}</span> |
              <span className="bpadins22">
                {(post.blogDescription?.length / 2500).toFixed(0)} mins read
              </span>
            </div>

            <div className="posttttbb">
              <p className="postttt">{post.blogTitle}</p>
              <p className="bpadins22 mt-5">Summary</p>
              <p className="postttt2">{post.homeIntro}</p>
            </div>
            <div className="yjyj">
              <img
                src={post.image ? post.image.Location : "/course.png"}
                className="bisaaa"
              />
            </div>

            <div
              className="postttt2"
              dangerouslySetInnerHTML={{
                __html: decodedHtml,
              }}
            ></div>
          </div>
        </Row>
      </Container>
      <Efficiency style={{ width: "100.5%" }} />
      <Footer />
    </>
  );
};

export default BlogView;
