import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./faq.css";
import Efficiency from "../../components/Efficiency/Efficiency";
import { Helmet } from "react-helmet";

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>
          Frequently Asked Questions | Churchease - Your Church Management
          Solution
        </title>
        <meta
          name="description"
          content="Find answers to common questions about Churchease. Learn how to get started, explore features, pricing, security, and more. Discover how Churchease can streamline your church management."
        />
        <link rel="canonical" href="https://churchease.com/faq" />
        <meta
          property="og:title"
          content="Frequently Asked Questions | Churchease - Your Church Management Solution"
        />
        <meta
          property="og:description"
          content="Find answers to common questions about Churchease. Learn how to get started, explore features, pricing, security, and more. Discover how Churchease can streamline your church management."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta property="og:url" content="https://churchease.com/faq" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <p className="fewte">Frequently asked questions</p>
        </Row>
        <Row className="faqbodyss">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is Churchease?</Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease is a unified platform that simplifies church
                administration and operations. From member management to event
                scheduling, our platform provides an intuitive interface,
                ensuring every process is efficient and user-friendly. With our
                highly customizable system, you have the flexibility to mold the
                software to align perfectly with your church's operations and
                vision.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                How do I get started with Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Setting up Churchease involves creating an account, configuring
                your church's profile, and customizing the settings to suit your
                church’s specific needs, such as member management, financial
                tracking, and event scheduling.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Is Churchease suitable for small and large churches?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, Churchease is scalable and can be effectively used by both
                small churches and large multi-site congregations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                How does Churchease's customizable pricing model work?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease allows churches to select only the features they
                require and pay for them, helping to save costs and tailor the
                software to specific needs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                What are the terms of the free trial offered by Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease offers a 30-day free trial that allows churches to
                explore the full capabilities of the software. Churches can
                upgrade to a paid subscription at any time during or after the
                trial period.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                How does the SMS feature work in Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease includes a default package of 500 SMS messages with
                its subscriptions, but churches can purchase additional SMS
                packages to better align with their communication needs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                How can Churchease assist in compliance with data protection and
                privacy laws?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease ensures compliance with data protection and privacy
                laws by implementing rigorous data security measures, regular
                audits, and providing features that help manage consent and data
                access according to legal requirements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                What features does Churchease offer for member management?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Features include member registration, profile management,
                attendance tracking, and communication tools for newsletters and
                announcements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Can Churchease handle online giving and donation management?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, the software supports online giving, tracks donations,
                generates donation receipts, and provides detailed financial
                reports.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                How does Churchease help with event planning and management?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                The software includes tools for scheduling events, managing
                registrations, sending reminders, and coordinating volunteers.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                Is there a mobile app available for Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, Churchease offers a mobile app that allows members and
                staff to access information, communicate, and manage their tasks
                on the go.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                How secure is the data in Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease uses advanced security measures including encryption,
                secure servers, and regular backups to protect all stored data.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                Can Churchease integrate with other software?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, it integrates with various platforms such as financial
                accounting software, email marketing tools, and more to enhance
                its functionality.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                How does Churchease support communication within the church?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                The software provides tools for email campaigns, social media
                integration, and customized group communications to facilitate
                effective outreach and engagement.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                What training and support does Churchease offer?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Churchease provides online tutorials, user guides, and customer
                support through email, phone, and live chat to assist with setup
                and ongoing management.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>
                Are there any volunteer management features in Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, the software includes volunteer scheduling, tracking of
                hours, and communication tools specifically for managing
                volunteers.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                Can Churchease track attendance at services and events?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                Yes, attendance tracking is a core feature, allowing churches to
                record and analyze attendance patterns for services and events.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>
                What reporting capabilities does Churchease offer?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                It offers a range of reports on membership demographics,
                financials, event success, volunteer activity, and much more to
                help with strategic planning and decision-making.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>
                How can I get started with a trial of Churchease?
              </Accordion.Header>
              <Accordion.Body className="faqtext">
                You can sign up for a free trial on the Churchease website,
                allowing you to explore the features and determine if it’s the
                right fit for your church before committing to a subscription.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Efficiency />
      </Container>
      <Footer />
    </>
  );
};

export default Faq;
