import { Button, Switch } from "antd";

import MarkdownCheatsheet from "../Modalmy/MarkdownCheatsheet";
import { Container } from "react-bootstrap";

const UpdateLessonForm = ({
  current,
  setCurrent,
  handleUpdateLesson,
  uploading,

  markdownCheatsheetModal,
  setMarkdownCheatsheetModal = (f) => f,
}) => {
  return (
    <Container fluid>
      <form onSubmit={handleUpdateLesson}>
        <input
          type="text"
          className="form-control mt-3"
          onChange={(e) => setCurrent({ ...current, title: e.target.value })}
          value={current.title}
          placeholder="Title"
          autoFocus
          required
        />

        <div
          onClick={() => setMarkdownCheatsheetModal(!markdownCheatsheetModal)}
          className="text-center pt-2 pb-3 pointer"
          style={{ height: "10px" }}
        >
          <b>write in </b> <code>markdown</code>
        </div>
        <MarkdownCheatsheet
          markdownCheatsheetModal={markdownCheatsheetModal}
          setMarkdownCheatsheetModal={setMarkdownCheatsheetModal}
        />

        <textarea
          className="form-control mt-3 mb-3"
          onChange={(e) => setCurrent({ ...current, content: e.target.value })}
          value={current.content}
          placeholder="Description"
          rows="4"
        ></textarea>

        <div>
          {!uploading && current.video && current.video.Location && (
            <div className="pt-2 d-flex justify-content-center">
              <video controls width="410" height="240">
                <source type="video/mp4" src={current?.video?.Location} />
                something went wrong
              </video>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <p className="pt-3">Preview</p>
          <Switch
            className="float-right mt-2"
            disabled={uploading}
            checked={current.free_preview}
            name="free_preview"
            onChange={(v) => setCurrent({ ...current, free_preview: v })}
          />
        </div>

        <Button
          onClick={handleUpdateLesson}
          className="buttonner1111"
          size="large"
          loading={uploading}
          disabled={uploading}
        >
          Save
        </Button>
      </form>
    </Container>
  );
};

export default UpdateLessonForm;
