import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const DeleteForm = ({ show, setShow, selectedForm, uploadSuccessFunc }) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms`);
    setForm(data);
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(`/forms/${selectedForm._id}`);
      if (response.status === 200 || response.status === 204) {
        uploadSuccessFunc();
        loadCourse();
        handleClose();
        toast(
          <CustomToastContent title="Success" message="Delete Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [form, setForm] = useState([]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        This action cannot be reversed.
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            handleDelete(selectedForm);
            handleClose();
          }}
          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteForm;
