import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const PieChartAccountStatus = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [data, setData] = useState([]);

  useEffect(() => {
    getStat();
  }, []);

  const getStat = async () => {
    try {
      const response = await authFetch.get("/members/getaccountstatusstat");

      if (response.status === 200 || response.status === 201) {
        // Extract account status counts
        const active = response.data.accountStatusStats.Active;
        const inactive = response.data.accountStatusStats.Inactive;
        const undefined = response.data.accountStatusStats.Undefined;

        // Transform the data to fit the PieChart format
        const statusData = [
          { name: "Active", value: active },
          { name: "Inactive", value: inactive },
          { name: "Undefined", value: undefined },
        ];
        setData(statusData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const COLORS = ["#243060", "#FE7064", "#888888"]; // Colors for the pie slices

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          //   label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartAccountStatus;
