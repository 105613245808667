import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactAudioPlayer from "react-audio-player";

const AudioLink = ({ show, setShow, selectedAudio }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedAudio?.audioName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex items-center justify-center my-5 text-center">
        <ReactAudioPlayer src={selectedAudio?.audioLink} controls />
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AudioLink;
