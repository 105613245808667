import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Accordion, Button } from "react-bootstrap";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <p
          className="contactus1"
          style={{
            textAlign: "center",
            paddingBottom: "1rem",
            paddingTop: "5rem",
          }}
        >
          Frequently asked questions
        </p>
      </Row>
      <Row className="faqbodyss2">
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What is Churchease?</Accordion.Header>
            <Accordion.Body className="faqtext">
              Churchease is a unified platform that simplifies church
              administration and operations. From member management to event
              scheduling, our platform provides an intuitive interface, ensuring
              every process is efficient and user-friendly. With our highly
              customizable system, you have the flexibility to mold the software
              to align perfectly with your church's operations and vision.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How do I get started with Churchease?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Setting up Churchease involves creating an account, configuring
              your church's profile, and customizing the settings to suit your
              church’s specific needs, such as member management, financial
              tracking, and event scheduling.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Is Churchease suitable for small and large churches?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Yes, Churchease is scalable and can be effectively used by both
              small churches and large multi-site congregations.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How does Churchease's customizable pricing model work?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Churchease allows churches to select only the features they
              require and pay for them, helping to save costs and tailor the
              software to specific needs.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What are the terms of the free trial offered by Churchease?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Churchease offers a 30-day free trial that allows churches to
              explore the full capabilities of the software. Churches can
              upgrade to a paid subscription at any time during or after the
              trial period.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              How does the SMS feature work in Churchease?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Churchease includes a default package of 500 SMS messages with its
              subscriptions, but churches can purchase additional SMS packages
              to better align with their communication needs.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              How can Churchease assist in compliance with data protection and
              privacy laws?
            </Accordion.Header>
            <Accordion.Body className="faqtext">
              Churchease ensures compliance with data protection and privacy
              laws by implementing rigorous data security measures, regular
              audits, and providing features that help manage consent and data
              access according to legal requirements.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="flex justify-center faqbodyss2">
        <button
          className="rtyikuy"
          style={{
            backgroundColor: "var(--primary-color)",
          }}
          onClick={() => navigate("/faq")}
        >
          More FAQ
        </button>
      </Row>
    </>
  );
};

export default Faq;
