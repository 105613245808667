import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import { useAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCreateForm from "../../../components/BlogCreateForm/BlogCreateForm";
import ReactMarkdown from "react-markdown";

const BlogMain = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, user } = useAppContext();

  const [values, setValues] = useState({
    blogTitle: "",
    blogDescription: "",
    uploading: false,
    loading: false,
    churchName: user.churchName,
    category: "",
    homeIntro: "",
  });

  const [markdownContent, setMarkdownContent] = useState(`
  `);
  const navigate = useNavigate();

  const [image, setImage] = useState({});
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [checkField, setCheckField] = useState(false);
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleQuillChange = (content) => {
    setValues({ ...values, blogDescription: content });
  };
  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };
  const maxSizeInBytes = 1024 * 1024; // 1 MB

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 1208, 698, "JPEG", 100, 0, async (uri) => {
      try {
        // Calculate the current size of the image in bytes
        const currentSizeInBytes = uri.length;

        // If the image size exceeds the maximum size, calculate a new quality value to meet the size constraint
        if (currentSizeInBytes > maxSizeInBytes) {
          const newQuality = Math.floor(
            (maxSizeInBytes / currentSizeInBytes) * 100
          );

          // Resize the image again with the new quality value
          Resizer.imageFileResizer(
            file,
            1208,
            698,
            "JPEG",
            newQuality,
            0,
            async (newUri) => {
              // Now, 'newUri' contains the resized image with the adjusted quality to meet the size constraint
              try {
                let { data } = await authFetch.post("/blog/upload-image", {
                  image: newUri,
                });
                console.log("IMAGE UPLOADED", data);
                // set image in the state
                setImage(data);
                setValues({ ...values, loading: false });
              } catch (err) {
                console.log(err);
                setValues({ ...values, loading: false });
              }
            }
          );
        } else {
          // If the image size is already within the size constraint, upload it without further resizing
          let { data } = await authFetch.post("/blog/upload-image", {
            image: uri,
          });
          //console.log("IMAGE UPLOADED", data);
          // set image in the state
          setImage(data);
          setValues({ ...values, loading: false });
        }
      } catch (err) {
        console.log(err);
        setValues({ ...values, loading: false });
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      // console.log(values);
      setValues({ ...values, loading: true });
      const res = await authFetch.post("/blog/remove-image", { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Image");
      setValues({ ...values, loading: false });
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !values.blogTitle ||
      !image || // Assuming image is required
      !values.blogDescription ||
      !values.category ||
      !values.homeIntro
    ) {
      setCheckField(true);
      return;
    }

    const data = {
      ...values,
      blogContent: markdownContent, // Assuming blogContent should be sent
      image,
      blogTitle: values.blogTitle,
      homeIntro: values.homeIntro,
    };

    try {
      await authFetch.post("/blog", { data });
      navigate("/auth/blogmain");
      setValues({
        blogTitle: "",
        homeIntro: "",
        blogDescription: "",
        uploading: false,
        loading: false,
        category: "",
      });
      setMarkdownContent("");
      setImage({});
      setPreview("");
    } catch (err) {
      console.error(err);
      // Consider setting an error state and displaying it in the UI
    }
  };

  return (
    <>
      <Container fluid>
        {user.mfirstName}
        <Row className="courseheader">
          <p className="sahs">Create Blog Post</p>
        </Row>
        <Row>
          <div className="pt-5 pb-5">
            {checkField && (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "red",
                  paddingBottom: "50px",
                }}
              >
                Check fields and try again!
              </p>
            )}
            <BlogCreateForm
              handleSubmit={handleSubmit}
              handleImage={handleImage}
              handleChange={handleChange}
              values={values}
              setValues={setValues}
              setMarkdownContent={setMarkdownContent}
              markdownContent={markdownContent}
              preview={preview}
              uploadButtonText={uploadButtonText}
              handleImageRemove={handleImageRemove}
              handleQuillChange={handleQuillChange}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BlogMain;
