import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";

const MemberContact = ({ isChecked }) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { members } = useAppContext();

  const sendEmail = () => {};

  return (
    <>
      <Col>
        <ListGroup defaultActiveKey="#link1">
          <ListGroup.Item action onClick={handleShow}>
            <FaMailBulk />
            Email Members {isChecked.length >= 1 ? isChecked.length : ""}
          </ListGroup.Item>

          <ListGroup.Item action>
            <FaMobileAlt />
            Text Members {isChecked.length >= 1 ? isChecked.length : ""}
          </ListGroup.Item>
          <ListGroup.Item action>
            <FaDownload />
            Export Members List {isChecked.length >= 1 ? isChecked.length : ""}
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Email Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                placeholder={isChecked ? isChecked : members}
                autoFocus
                disabled
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                required
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="button" variant="primary" onClick={sendEmail}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemberContact;
