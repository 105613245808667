import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaChartPie, FaMoneyBill } from "react-icons/fa";
import d1 from "../../assets/newi/d1.png";
import d2 from "../../assets/newi/d2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductMessaging = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Two-Way Messaging | Churchease - Enhance Community Engagement
        </title>
        <meta
          name="description"
          content="Enhance engagement with Churchease's 2-way Messaging feature. Facilitate immediate and interactive communication between church staff and members, keeping your community closely knit and actively participating."
        />
        <link rel="canonical" href="https://churchease.com/product/messaging" />
        <meta
          property="og:title"
          content="Two-Way Messaging | Churchease - Enhance Community Engagement"
        />
        <meta
          property="og:description"
          content="Enhance engagement with Churchease's 2-way Messaging feature. Facilitate immediate and interactive communication between church staff and members, keeping your community closely knit and actively participating."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/messaging"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#5D79DA" }}>
            <div>
              <FaChartPie size={100} />
              <h1>Two-Way Messaging</h1>
              <p className="headpp">
                Enhance engagement with our 2-way Messaging feature. Facilitate
                immediate and interactive communication between church staff and
                members, fostering an environment where feedback is encouraged
                and everyone stays connected. This tool is designed to keep your
                community closely knit and actively participating, no matter
                where they are.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for church donations
            </p>
            <p className="product13">
              Your all-inclusive solution to manage and track all giving
              activities
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={d1} alt="Communications" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">INSTANT MESSAGING</p>
              <p className="proddz1">
                Send and receive messages instantly, enabling real-time
                communication between church staff and members.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">GROUP CHATS</p>
              <p className="proddz1">
                Create group chats for different ministries and interest groups,
                facilitating targeted discussions and announcements.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MESSAGE BROADCASTING</p>
              <p className="proddz1">
                Broadcast messages to large segments of your congregation or
                specific groups, ensuring everyone receives important updates
                simultaneously.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">FEEDBACK COLLECTION</p>
              <p className="proddz1">
                Collect feedback directly through messages, making it easy for
                members to share their thoughts and for staff to gather valuable
                insights.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">PERSONALIZED COMMUNICATION</p>
              <p className="proddz1">
                Tailor messages to meet the needs of individual church members
                or specific groups, enhancing personal connections.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACCESS CONTROL</p>
              <p className="proddz1">
                Manage who can send messages and to whom, ensuring communication
                remains appropriate and within church guidelines.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={d2} alt="Communications" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#5D79DA" />
      <Footer />
    </>
  );
};

export default ProductMessaging;
