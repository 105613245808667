import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import axios from "axios";
import { currencyFormatter } from "../../../utils/helpers";
import "./standard.css";

const StandardGiving = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser } = useAppContext();

  const [givingByMemberx, setGivingByMemberx] = useState([]);
  const [dateFlag, setDateFlag] = useState(false);
  const [givingfilter, setGivingfilter] = useState([]);
  const [nameFlag, setNameFlag] = useState(true);
  const [viaFlag, setViaFlag] = useState(true);
  const [reasonFlag, setReasonFlag] = useState(true);
  const [assignedFlag, setAssignedFlag] = useState(true);
  const [targetDateFlag, setTargetDateFlag] = useState(true);
  const [statusFlag, setStatusFlag] = useState(true);
  const [givingTotal, setGivingTotal] = useState("");

  const givingByMember = async () => {
    try {
      const response = await authFetch.get(
        "/giving/give/totalgivingbymember/" + user._id
      );

      setGivingTotal(response?.data?.totalAmount);
      setGivingByMemberx(response?.data?.givingDetails);
    } catch (error) {
      console.error("Error fetching prayer count:", error);
    }
  };

  useEffect(() => {
    givingByMember();
  }, []);

  const handleDateSort = (e) => {
    let sorted = givingByMemberx.sort((a, b) => {
      if (dateFlag) {
        return moment(b.givingDate).unix() - moment(a.givingDate).unix();
      } else {
        return moment(a.givingDate).unix() - moment(b.givingDate).unix();
      }
    });
    setGivingfilter(sorted);
    setDateFlag(!dateFlag);
  };

  const handleTargetDateSort = (e) => {
    let sorted = givingByMemberx.sort((a, b) => {
      if (targetDateFlag) {
        return moment(b.amount) - moment(a.amount);
      } else {
        return moment(a.amount) - moment(b.amount);
      }
    });
    setGivingfilter(sorted);
    setTargetDateFlag(!targetDateFlag);
  };

  const handleNameSort = (e) => {
    if (nameFlag) {
      let sorted = givingByMemberx.sort(function (a, b) {
        return a.paymentMethod.localeCompare(b.paymentMethod);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setNameFlag(!nameFlag);
  };

  const handleViaSort = (e) => {
    if (viaFlag) {
      let sorted = givingByMemberx.sort(function (a, b) {
        return a.category.localeCompare(b.category);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setViaFlag(!viaFlag);
  };

  const handleAssignedSort = (e) => {
    let sorted = givingByMemberx.sort((a, b) => {
      return assignedFlag ? b.amount - a.amount : a.amount - b.amount;
    });
    setGivingfilter(sorted);
    setAssignedFlag(!assignedFlag);
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="courseheader">
          <Col>
            <p className="sahs">My Givings (current month)</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div style={{ fontWeight: "600" }}>
            Total giving ={" "}
            {currencyFormatter({
              amount: givingTotal,
              currency: "usd",
            })}
          </div>
        </Row>
        <Row className="fundc2">
          {givingByMemberx && givingByMemberx.length > 0 ? (
            <Table hover responsive style={{ backgroundColor: "#F1F1F1" }}>
              <thead>
                <tr className="trdata">
                  <th onClick={handleDateSort} className="rwewwww">
                    Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleNameSort} className="rwewwww">
                    Giving Method {nameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleViaSort} className="rwewwww">
                    Giving Category {viaFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>

                  <th onClick={handleAssignedSort} className="rwewwww">
                    Amount {assignedFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                </tr>
              </thead>

              {givingByMemberx &&
                givingByMemberx?.map((item) => (
                  <tbody>
                    <tr key={item._id} className="trdata">
                      <td className="rwewwww">
                        {moment(item.givingDate).format("MMM DD, YYYY")}
                      </td>
                      <td className="rwewwww">{item.paymentMethod}</td>
                      <td className="rwewwww">{item.category}</td>

                      <td className="rwewwww">
                        {currencyFormatter({
                          amount: item.amount,
                          currency: "usd",
                        })}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
              <button className="noDataButton">Create Follow Up</button>
            </div>
          )}{" "}
        </Row>
      </Container>
    </>
  );
};

export default StandardGiving;
