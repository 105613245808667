import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import CustomPay from "./CustomPay";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CustomElement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [searchParams] = useSearchParams();
  const priceId = searchParams.get("price_id");
  const id = searchParams.get("id");
  const [loading, setLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [price_id, setPrice_id] = useState("");
  const [userId, setUserId] = useState("");
  const [pkValue, setPkValue] = useState("");
  const [userData, setUserData] = useState({});
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("usd");

  // useEffect(() => {
  //   if (pkValue) {
  //     const stripe = loadStripe(pkValue);
  //     setStripePromise(stripe);
  //   }
  // }, [pkValue]);

  console.log("priceId: ", pkValue);

  useEffect(() => {
    if (priceId && id) {
      setPrice_id(priceId);
      setUserId(id);
    }
  }, []);

  // Fetch price details from the backend or Stripe

  useEffect(() => {
    const fetchPriceDetails = async () => {
      try {
        const response = await authFetch.get("/members/getcustom", {
          params: { price_id, id: userId },
        });
        setPkValue(response.data.user);
        // Other state updates...
        setLoading(false);
        // Initialize Stripe after pkValue is set
        setStripePromise(await loadStripe(response.data.user));
      } catch (error) {
        console.error("Error fetching price details:", error);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };

    if (price_id && userId) {
      fetchPriceDetails();
    }
  }, [price_id, userId]);

  return (
    <Elements stripe={stripePromise}>
      <CustomPay />
    </Elements>
  );
};

export default CustomElement;
