import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AddTag from "../../../components/ContainerGroup/AddTag";
import { useAppContext } from "../../../context/appContext";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ManageTags from "../../../components/ContainerGroup/ManageTags";
import EditTag from "../../../components/ContainerGroup/EditTag";
import SetupTag from "../../../components/ContainerGroup/SetupTag";
import Alert from "../../../components/Alert/Alert";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
const GrowthCenter = () => {
  const { user, getContainers, containers, deleteContainer, editContainer } =
    useAppContext();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [showxx, setShowxx] = useState(false);

  const handleClosexx = () => setShowxx(false);
  const handleShowxx = () => setShowxx(true);

  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [tagId, setTagId] = useState("");
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const editContainers = (id) => {
    setShow1(true);
    setTagId(id);
  };
  const manageTags = (id) => {
    setShow2(true);
    setTagId(id);
  };

  const setupContainer = (id) => {
    setShow3(true);
    setTagId(id);
  };

  useEffect(() => {
    getContainers();
  }, []);

  const handleVideo = () => {
    navigate("/auth/learninghome");
  };

  const handleAudio = () => {
    navigate("/auth/audiodashboard");
  };

  const handleText = () => {
    navigate("/dashboard/learning/growthcenter/addtext");
  };

  const handleDocument = () => {
    navigate("/auth/documentdashboard");
  };

  const handleViewName = (viewNameFlag) => {
    // if (!viewNameFlag) {
    //   let sorted = [...savedView].sort(function (a, b) {
    //     return a.viewName.localeCompare(b.viewName);
    //   });
    //   setSavedView(sorted);
    // } else {
    //   let sorted = [...savedView].reverse();
    //   setSavedView(sorted);
    // }
    // setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    // let sorted = [...savedView].sort((a, b) => {
    //   if (dateFlag) {
    //     return moment(b.createdAt).unix() - moment(a.createdAt).unix();
    //   } else {
    //     return moment(a.createdAt).unix() - moment(b.createdAt).unix();
    //   }
    // });
    // setSavedView(sorted);
    // setDateFlag(!dateFlag);
  };
  return (
    <Container fluid>
      <Row className="courseheader">
        <Col sm={8}>
          <p className="sahs">Growth Center</p>
        </Col>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo">
          <Button
            className="wdq"
            onClick={handleShowxx}
            style={{ fontSize: "1em" }}
          >
            Add Content
          </Button>
        </div>
      </Row>
      <Row>
        <Alert />
        <div className="parentContainer w-100">
          <div className="w-100">
            <Table hover responsive>
              <thead>
                <tr className="trdataheader">
                  <th onClick={handleViewName}>
                    View Name {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleDateSort}>
                    Members{dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th style={{ textAlign: "center" }}>WorkFlows</th>
                  <th style={{ textAlign: "center" }}>Keyword</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              {containers ? (
                containers.map((container, index) => (
                  <tbody key={index}>
                    {" "}
                    {/* Unique key for each mapped item */}
                    <tr className="trdata">
                      <td>{container.name}</td>
                      <td>{container.name.length}</td>

                      <td className="rightco">{container.name.length}</td>
                      <td className="rightco">{container.name.length}</td>
                      <td className="rightco flex gap-3 items-end justify-end">
                        <Button
                          className="btn btn-primary"
                          onClick={() => manageTags(container._id)}
                        >
                          Add
                        </Button>
                        <Button
                          className="btn btn-primary"
                          onClick={() => editContainers(container._id)}
                        >
                          Edit
                        </Button>

                        <Button
                          className="btn btn-primary"
                          onClick={() => setupContainer(container._id)}
                        >
                          Setup
                        </Button>
                        <Button
                          className="btn btn-danger"
                          onClick={() => deleteContainer(container._id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <div>No containers</div>
              )}
            </Table>
          </div>
        </div>
      </Row>
      <Modal show={showxx} onHide={handleClosexx}>
        <Modal.Header closeButton>
          <Modal.Title>Start Creating Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-column items-center gap-3 my-3">
            <button className="rtreef" onClick={handleVideo}>
              Video Content
            </button>
            <button className="rtreef" onClick={handleAudio}>
              Audio Content
            </button>
            <button className="rtreef" onClick={handleText}>
              Text Content
            </button>
            <button className="rtreef" onClick={handleDocument}>
              Document Content
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClosexx} className="jiluil">
            Close
          </button>
          <button onClick={handleClosexx} className="jiluil3">
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <AddTag show={show} setShow={setShow} />
      <EditTag
        show={show1}
        setShow={setShow1}
        tagId={tagId}
        setTagId={setTagId}
      />
      <ManageTags
        show={show2}
        setShow={setShow2}
        tagId={tagId}
        setTagId={setTagId}
      />
      <SetupTag
        show={show3}
        setShow={setShow3}
        tagId={tagId}
        setTagId={setTagId}
      />
    </Container>
  );
};

export default GrowthCenter;
