import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "./register.css";
import { Link, useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import LoginGrap from "../../assets/images/LoginGrap.png";
import Button from "react-bootstrap/Button";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import frame from "../../assets/images/myLogo.png";
import Form from "react-bootstrap/Form";
import Loading from "../../components/Loading/Loading";
import { useLocation } from "react-router-dom";

const MultiFactorAuth = () => {
  const location = useLocation();
  const email = location.state?.email;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const inputRef = useRef(null);
  useEffect(() => {
    // Focus on the input element when the component is mounted
    inputRef.current.focus();
  }, []);

  const { showAlert, isLoading, logoutUser } = useAppContext();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      verifyToken(event);
    }
  };

  const verifyToken = async (e) => {
    e.preventDefault();

    if (!token || !email) {
      console.error("Token and email are required.");
      return; // Exit the function if token or email is missing
    }

    const data = { token, email };

    try {
      const response = await authFetch.post(
        "/multifactorauth/verify-token",
        data
      );

      if (response.data.token && response.data.user) {
        setIsVerified(true);
        // You might want to handle the new token and user data here
      } else {
        // Provide user feedback for failed verification
        console.error(
          "Verification failed. Please check your token and try again."
        );
      }
    } catch (error) {
      // Provide user feedback for network or server errors
      console.error("Error verifying token:", error);
    }
  };

  useEffect(() => {
    if (isVerified) {
      setTimeout(() => {
        window.location.href = "/auth/dashboard";
      }, 1000);
    }
  }, [isVerified, navigate]);

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <>
      <Container fluid style={{ padding: "0" }}>
        <Row className="contaiwner2">
          <Col xs={12} sm={12} md={12} lg={8} className="logindiv2">
            <Image fluid src={LoginGrap} className="imgsizex" />
            <p className="redi">
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="logindiv1">
            <Image fluid src={frame} className="imgsizex22" />
            <form className="formdiv">
              <p className="logintextmessagexx">Enter Authentication Token</p>
              {showAlert && <Alert />}
              <div className="label">
                <Form.Label
                  htmlFor="inputPassword5"
                  style={{ fontWeight: "600", fontSize: "1em" }}
                >
                  Enter Verification Code
                </Form.Label>
                <input
                  type="token"
                  id="token"
                  placeholder="Enter token from Authenticator App"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  className="forminput"
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <p>
                <Button
                  type="button"
                  className="buttonner"
                  onClick={verifyToken}
                >
                  Send
                </Button>
              </p>
              <br></br>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MultiFactorAuth;
