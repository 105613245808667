import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { currencyFormatter } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../components/Loading/Loading";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const OnlineTransactions = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { getSavedView, savedViews, user, isLoading, logoutUser } =
    useAppContext();

  const [show, setShow] = useState(false);
  const [clickedMember, setClickedMember] = useState([]);
  const [savedView, setSavedView] = useState([]);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [allPayments, setAllPayments] = useState([]);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (allPayments.length) {
      setAllPayments(allPayments);
    }
  }, []);

  useEffect(() => {
    getAllPayments();
    setCurrency(user.currency);
  }, []);

  const getAllPayments = async () => {
    try {
      const response = await authFetch.get("/payment/custom/getallpayment");
      setAllPayments(response.data.payments);
      // console.log(response.data.payments); // Assuming you want to log the response data
    } catch (err) {
      console.error(err); // Using console.error for logging errors
    }
  };

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...allPayments].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setAllPayments(sorted);
    } else {
      let sorted = [...allPayments].reverse();
      setAllPayments(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...allPayments].sort((a, b) => {
      if (dateFlag) {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      }
    });
    setAllPayments(sorted);
    setDateFlag(!dateFlag);
  };

  const copiedArray = allPayments?.map((obj) => ({
    Payment_Date: moment(`${obj.paymentDetails.paymentDate}`).format("LL"),
    Giver_Name:
      obj.paymentDetails.mfirstName + " " + obj.paymentDetails.mlastName,
    Category: obj.paymentDetails.category,
    Amount: obj.paymentDetails.amount * 100,
    Currency: obj.paymentDetails.currency,
    status: obj.paymentDetails.status,
  }));

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [notFound, setNotFound] = useState([]);
  const [notFoundArray, setNotFoundArray] = useState([]);

  const handleMatchTransactions = () => {
    handleShowModal();
  };

  useEffect(() => {
    matchTransactions();
  }, []);

  const matchTransactions = async () => {
    try {
      const response = await authFetch.post(
        "/payment/custom/matchtransactions",
        { allPayments: allPayments }
      );

      if (response.status === 200) {
        setNotFound(response.data.notFoundEmails);
        handleCloseModal();
        getAllPayments();
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return <Loading center />;
  }
  return (
    <>
      <Container fluid>
        <Row className="headerbanner">
          <div className="bannerbig">Web Transactions</div>
          <div className="bannersmall">
            Access the Web Transaction Page to view all web transactions at a
            glance. Churches can utilize this view for reconciliation purposes
            and to track web payments with ease.
          </div>
        </Row>

        <>
          {allPayments && allPayments.length > 0 ? (
            <Row className="tabw">
              <div className="flex justify-between items-center flex-row pb-5">
                {" "}
                <p
                  style={{
                    color: "var(--primary-color)",
                    fontWeight: "600",
                    margin: "0",
                  }}
                >
                  {allPayments.length ? (
                    <p>
                      {allPayments.length} record
                      {allPayments.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>{" "}
                <div className="flex gap-3">
                  <button className="jiluil3" onClick={handleMatchTransactions}>
                    Bulk Matching
                  </button>
                  <button className="jiluil">
                    <CSVLink
                      data={copiedArray}
                      filename="AllWebPayment-churchease.csv"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Download
                    </CSVLink>
                  </button>
                </div>
              </div>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th onClick={handleDateSort}>
                      Payment Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th onClick={handleViewName}>
                      Giver Name{" "}
                      {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                    </th>
                    <th>Category</th>
                    <th>Frequency</th>
                    <th>Interval</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {allPayments.map((payment, index) => (
                  <tbody>
                    <tr key={index} className="trdata">
                      <td>
                        {moment(`${payment.paymentDetails.paymentDate}`).format(
                          "LL"
                        )}
                      </td>
                      <td>
                        <div className="flex items-center gap-1">
                          {payment.paymentDetails.payerEmail}{" "}
                          {notFound.includes(
                            payment.paymentDetails.payerEmail
                          ) ? (
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "red",
                                fontWeight: "600",
                              }}
                            >
                              P
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td> {payment.paymentDetails.category}</td>
                      <td> {payment.paymentDetails.frequency}</td>
                      <td> {payment.paymentDetails.interval}</td>
                      <td>
                        {currencyFormatter({
                          amount: payment.paymentDetails.amount, // Assuming 'amount' is directly under 'paymentDetails'
                          currency: currency, // Assuming 'currency' is directly under 'paymentDetails'
                        })}
                      </td>
                      <td>{payment.paymentDetails.currency}</td>
                      <td> {payment.paymentDetails.status}</td>
                      <td>
                        <button className="jiluil">Match Transaction</button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Match Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This action will match Giver Email with Registered emails in the
            member directory. Giver's Email that does not match can be pushed
            manually.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleCloseModal}>
            No
          </button>
          <button className="jiluil3" onClick={matchTransactions}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OnlineTransactions;
