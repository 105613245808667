import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import "./followup.css";

const FollowUpSettings = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setSelectedId(id);
  };

  const [selectedId, setSelectedId] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setSelectedId(id);
  };

  const {
    displayAlert,
    showAlert,
    clearValues,
    deleteSettings,
    getAllSettings,
    addSettings,
    setting,
    user,
    updatedSetting,
  } = useAppContext();

  useEffect(() => {
    const handleFind = async () => {
      const foundObject = setting?.find((obj) => obj?._id === selectedId);
      setSingleData(foundObject);
    };
    handleFind();
  }, [selectedId, setting]);

  useEffect(() => {
    getAllSettings();
  }, []);

  const [settingName, setSettingName] = useState("");
  const [settingDesc, setSettingDesc] = useState("");
  const [settingName1, setSettingName1] = useState("");
  const [settingDesc1, setSettingDesc1] = useState("");
  const [singleData, setSingleData] = useState("");
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const maxlength = 500;

  const handleDesc = (e) => {
    setSettingDesc(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!settingName || settingDesc === "") {
      setError(true);
      return;
    }

    if (settingDesc.length > maxlength) {
      setError2(true);
      return;
    }
    const followUpDetails = {
      followUpName: settingName,
      followUpDesc: settingDesc,
      churchName: user.churchName,
    };

    addSettings({ followUpDetails });
    handleClose();
    getAllSettings();
    setSettingName();
    setSettingDesc();
  };

  useEffect(() => {
    if (singleData && singleData?.followUpName) {
      setSettingName1(singleData?.followUpName);
    }
    if (singleData && singleData?.followUpDesc) {
      setSettingDesc1(singleData?.followUpDesc);
    }
  }, [singleData, setting, setSettingDesc, setSettingName]);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    if (settingDesc1.length > maxlength) {
      setError2(true);
      return;
    }
    const updatedSettingx = {
      followUpName: settingName1,
      followUpDesc: settingDesc1,
      selectedId: selectedId,
    };

    updatedSetting({ updatedSettingx });
    getAllSettings();
    handleClose1();
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col>
            <p className="sahs">Create Follow-Up Reason</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <button className="jiluil" onClick={handleShow}>
              New Follow-Up Reason
            </button>
          </div>
        </Row>
        {setting && setting.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Reason</th>
                  <th style={{ fontSize: "1em" }}>Description </th>
                  <th style={{ fontSize: "1em" }}>Actions</th>
                </tr>
              </thead>
              {setting &&
                setting?.map((settin) => (
                  <tbody key={settin._id}>
                    <tr>
                      <td style={{ fontSize: "1em" }}>{settin.followUpName}</td>
                      <td style={{ fontSize: "1em" }}>{settin.followUpDesc}</td>
                      <td>
                        <div className="flex flex-row gap-3">
                          <button
                            className="jiluil3"
                            onClick={() => handleShow1(settin._id)}
                          >
                            Edit
                          </button>

                          <button
                            className="jiluil"
                            onClick={() => handleShow2(settin._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
            {/* <button className="noDataButton" onClick={handleShow}>
              New Follow-Up Reason
            </button> */}
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add Follow-Up Reason Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error2 && (
              <p className="inlineerror">
                The text exceeds the maximum limit of 500 characters.
              </p>
            )}
            {error && <p className="ty67">Reason Name is a required field.</p>}
            <Form.Group className="mb-3">
              <Form.Label className="wppp">Follow-Up Reason</Form.Label>
              <Form.Control
                type="text"
                value={settingName}
                onChange={(e) => setSettingName(e.target.value)}
                name="settingName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="wppp">Description</Form.Label>
              <Form.Control
                as="textarea"
                row={8}
                value={settingDesc}
                onChange={handleDesc}
                name="settingDesc"
                style={{ fontSize: "1em", height: "150px" }}
              />
              <p className="character-count">
                {settingDesc?.length} / {maxlength} Maximum Characters.
              </p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Edit Reason Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error2 && (
              <p className="inlineerror">
                The text exceeds the maximum limit of 500 characters.
              </p>
            )}
            {error && <p className="ty67">Reason Name is a required field.</p>}
            <Form.Group className="mb-3">
              <Form.Label className="wppp">Reason</Form.Label>
              <Form.Control
                type="text"
                value={settingName1}
                onChange={(e) => setSettingName1(e.target.value)}
                name="settingName"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="wppp">Description</Form.Label>
              <Form.Control
                as="textarea"
                row={8}
                value={settingDesc1}
                onChange={(e) => setSettingDesc1(e.target.value)}
                name="settingDesc1"
                style={{ fontSize: "1em", height: "150px" }}
              />
              <p className="character-count">
                {settingDesc1?.length} / {maxlength} Maximum Characters.
              </p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleSubmit1}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this view. This action cannot be
          reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose2}>
            Close
          </button>
          <button
            className="jiluil3"
            onClick={() => {
              deleteSettings(selectedId, {
                alertText: "Setting Deleted",
              });
              handleClose2();
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FollowUpSettings;
