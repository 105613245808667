export const NAME_LENGTH_LIMIT = 150;
export const DESCRIPTION_LENGTH_LIMIT = 700;
export const DESCRIPTION_LENGTH_LIMIT_SMALL = 100;

export const trimInputs = (inputs) => {
  return inputs.map((input) => input.trim());
};

export const validateLength = (input, maxWords) => {
  const words = input.trim().split(/[\s,.!?;:()'"“”‘’]+|[–—-]+(?=\w)/);

  return words.length <= maxWords;
};

export const validateNotEmpty = (input) => {
  return input.trim().length > 0;
};
