import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import HighLightText from "../HighLightText/HighLightText";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddLivestream = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    showAlert,
    logoutUser,
    user,
    getEventByVolunteers,
    eventsByVolunteer,
    createSchedule,
  } = useAppContext();
  const [useEvent, setUseEvent] = useState(false);

  useEffect(() => {
    getEventByVolunteers();
  }, []);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [publishTime, setPublishTime] = useState("");
  const [useEventData, setUseEventData] = useState("");
  const [youtubeLink, setYouTubeLink] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const searchTerm = useEventData?.toLowerCase().trim();
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [schedule, setSchedule] = useState("");
  const maxChars = 5000;
  useEffect(() => {
    if (useEvent === false) {
      setUseEventData("");
    }
  }, [useEvent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const publishLater = async (e) => {
    e.preventDefault();

    if (!schedule || !youtubeLink) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please complete the required fields to proceed!!"
        />
      );
      return;
    }
    try {
      const dataxx = {
        eventTitle: schedule,
        youtubeLink,
        startTime,
        endTime,
        published: false,
        churchName: user.churchName,
        churchId: user._id,
      };

      const response = await authFetch.post("/livestream/later", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setSchedule("");
        setYouTubeLink("");
        setStartTime("");
        setEndTime("");

        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Livestream Saved Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const publishNow = async (e) => {
    e.preventDefault();

    if (!schedule || !youtubeLink) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please complete the required fields to proceed!!"
        />
      );
      return;
    }
    try {
      const dataxx = {
        eventTitle: schedule,
        youtubeLink,
        startTime,
        endTime,
        published: true,
        churchName: user.churchName,
        churchId: user._id,
      };

      const response = await authFetch.post("/livestream/now", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setSchedule("");
        setYouTubeLink("");
        setStartTime("");
        setEndTime("");
        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Livestream Published Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Publish Livestream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && (
            <p className="inlineerror">
              Please complete the required fields to proceed!!
            </p>
          )}

          {error2 && (
            <p className="inlineerror">
              Description is above the required length!!
            </p>
          )}
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Update service using existing events"
              name="defaultFund"
              value="defaultFund"
              onChange={(e) => setUseEvent(e.target.checked)}
            />
          </Form.Group>

          {useEvent && (
            <Form.Group
              className="mb-5"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="input-container">
                <Form.Control
                  type="text"
                  value={useEventData}
                  onChange={(e) => {
                    setUseEventData(e.target.value);
                    setShowDropdown(true);
                  }}
                  placeholder="Type the event name"
                  className="formvalue"
                  required
                />

                {useEventData && (
                  <span
                    className="close-icon"
                    onClick={() => {
                      setSchedule("");
                      setUseEventData("");
                      setShowDropdown(false);
                    }}
                    style={{ color: "red" }}
                  >
                    X
                  </span>
                )}
              </div>
              {showDropdown && (
                <div className="dropdown-container">
                  {eventsByVolunteer &&
                    eventsByVolunteer
                      .filter((item) => {
                        if (!searchTerm) return false;
                        const eventTitle = item.eventTitle?.toLowerCase();
                        return eventTitle.includes(searchTerm);
                      })
                      .sort(
                        (a, b) => new Date(a.eventDay) - new Date(b.eventDay)
                      )
                      .slice(0, 10)
                      .map((item) => (
                        <div
                          onClick={() => {
                            const eventDetail = `${item.eventTitle} - ${moment(
                              item.eventDay
                            ).format("MMMM Do YYYY")}`;
                            setSchedule(eventDetail);
                            setUseEventData(eventDetail); // Set the selected event detail to the useEventData
                            setShowDropdown(false); // Hide the dropdown after selecting an item
                          }}
                          className="clickable-event"
                          key={item.eventTitle}
                        >
                          <HighLightText
                            text={item.eventTitle}
                            highlight={useEventData}
                          />{" "}
                          -{moment(item.eventDay).format("MMMM Do YYYY")}
                        </div>
                      ))}
                </div>
              )}
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="hed">
              Service Name <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={schedule}
              name="schedule"
              onChange={(e) => setSchedule(e.target.value)}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Livestream Link <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter live stream link"
                value={youtubeLink}
                onChange={(e) => setYouTubeLink(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Publish Start Time</Form.Label>
              <Form.Control
                type="time"
                placeholder="Select Time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Publish End Time</Form.Label>
              <Form.Control
                type="time"
                placeholder="Select Time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil4" onClick={publishLater}>
          Save and Publish Later
        </button>
        <button className="jiluil" onClick={publishNow}>
          Publish Now
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLivestream;
