import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { useAppContext } from "../../../../context/appContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loading from "../../../../components/Loading/Loading";
import { currencyFormatter } from "../../../../utils/helpers";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#ffffff",
      fontFamily: '"popins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1em",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "40px", // Adjust line height to increase the element's height
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid red", // Add a border to the element
      borderRadius: "4px", // Optional: if you want rounded corners
      padding: "0.375rem 0.75rem",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StandardPaySubscription = ({
  secretKey,
  churchName,
  email,
  amount,
  interval,
  date,
  category,

  currency,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [amountx, setAmount] = useState("");
  const [intervalx, setInterval] = useState("month"); // Default to monthly
  const [emailx, setEmail] = useState("");
  const [churchNamex, setChurchName] = useState("");
  const [datex, setDate] = useState("");
  const [categoryx, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currencyxx, setCurrency] = useState(null);
  const [message, setMessage] = useState(null);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, user } = useAppContext();

  useEffect(() => {
    if (
      secretKey &&
      amount &&
      interval &&
      email &&
      churchName &&
      date &&
      category
    ) {
      setAmount(amount);
      setInterval(interval);
      setEmail(email);
      setCategory(category);
      setDate(date);
      setChurchName(churchName);
      setCurrency(currency);
      setCategory(category);
    }
  }, [
    amount,
    interval,
    email,
    currency,
    churchName,
    date,
    category,
    secretKey,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      //console.log("Stripe.js has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Use Stripe's createPaymentMethod to securely collect card details
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    //console.log("result: ", result);
    if (error) {
      console.log("[error]", error);
      // Handle errors here (e.g., show error message to the user)
    } else {
      setIsLoading(true);

      try {
        const response = await authFetch.post("/payment/paysubscription", {
          paymentMethodId: paymentMethod.id,
          sk: secretKey,
          amount: amount,
          currency: currency,
          interval: interval,
          email: user.memail,
          category: category,
          churchName: churchName,
          // Include any other relevant information
        });
        // Handle server response here
        if (response.data.status === "active") {
          window.location.href = "/stripe/success";
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex justify-center rre332">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <Form.Label className="text-black">
            Email <span style={{ color: "#454843" }}>*</span>
          </Form.Label>
          <Form.Control value={emailx} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="text-black">
            Amount <span style={{ color: "#454843" }}>*</span>
          </Form.Label>
          <Form.Control value={`${currency} ${amountx}`} disabled />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="text-black">
            Start Date <span style={{ color: "#454843" }}>*</span>
          </Form.Label>
          <Form.Control
            value={moment(`${datex}`).format("LL")}
            className="forminput cnm"
            disabled
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="text-black">
            Billing Intervale <span style={{ color: "#454843" }}>*</span>
          </Form.Label>
          <Form.Control
            value={`Every ${intervalx}`}
            className="forminput cnm"
            disabled
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>
        <div className="flex justify-center">
          <button
            disabled={isLoading || !stripe || !elements}
            type="submit"
            className="jiluil inline-block px-5 py-2" // Added padding and set inline-block
            style={{ width: "100%" }} // Ensures the button takes full width
          >
            <span className="flex flex-row items-center justify-center gap-3">
              {isLoading ? (
                <>
                  <Loading /> Loading...
                </>
              ) : (
                <span id="button-text">
                  Pay {currency}{" "}
                  {currencyFormatter({
                    amount: amountx, // Assuming 'amount' is directly under 'paymentDetails'
                    currency: currency, // Assuming 'currency' is directly under 'paymentDetails'
                  })}
                </span>
              )}
            </span>
          </button>

          {message && (
            <CustomAlert
              alertType="danger"
              alerttext={message && <div id="payment-message">{message}</div>}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default StandardPaySubscription;
