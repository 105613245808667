import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import EditCustomPayment from "./EditCustomPayment";

const ManageCustomPayment = ({ show, setShow }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [paymentName, setPaymentName] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [error, setError] = useState(false);
  const [customPayment, setCustomPayment] = useState([]);

  useEffect(() => {
    // Call the pullData function
    pullData();
  }, []);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseDelete = () => setShowDeleteModal(false);

  //const handleShowDelete = () => setShowDeleteModal(true);
  const [payment, setPayment] = useState({});

  const pullData = async () => {
    try {
      const response = await authFetch.get("/members/getallcustompayment");
      const customPaymentData = response.data
        .map((member) => member.customPaymentData)
        .flat();

      if (customPaymentData) {
        setCustomPayment(customPaymentData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (payment) {
      setPaymentAmount(payment.paymentAmount);
      setPaymentDescription(payment.paymentDescription);
      setPaymentName(payment.paymentName);
    }
  }, [payment]);

  const handleShowDelete = (payment) => {
    setShowDeleteModal(true);
    setPayment(payment);
  };

  const handleShowEdit = (payment) => {
    setShowEditModal(true);
    setPayment(payment);
  };

  const handleDelete = async (payment) => {
    handleShowDelete(payment);
  };

  const handleDeleteNow = async () => {
    try {
      const { data } = await authFetch.delete(
        `/payment/deletepricepaymentlink/${payment.priceId}`
      );

      const updatedCustomPayment = customPayment.filter(
        (p) => p.priceId !== payment.priceId
      );
      setCustomPayment(updatedCustomPayment);
      handleCloseDelete();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = async (payment) => {
    handleShowEdit(payment);
    pullData();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Manage Custom Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <p className="inlineerror">
              Please check all fields and try again!
            </p>
          )}
          <Table hover responsive>
            <thead>
              <tr>
                <th>Category</th>
                <th>Payment Name</th>
                <th>Payment Description</th>
                <th>Payment Amount</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customPayment?.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.category}</td>
                  <td>{payment.paymentName}</td>
                  <td>{payment.paymentDescription}</td>
                  <td>{payment.paymentAmount}</td>

                  <td>
                    <div className="flex  justify-center gap-3">
                      <button
                        className="jiluil3"
                        onClick={() => handleEdit(payment)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil"
                        onClick={() => handleDelete(payment)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>{" "}
      {/* ////////Delete Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Custom Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this payment ?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseDelete}>
            No
          </button>
          <button className="jiluil" onClick={handleDeleteNow}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/* ////////Edit Modal */}
      <EditCustomPayment
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        payment={payment}
        setPayment={setPayment}
        pullData={pullData}
      />
    </>
  );
};

export default ManageCustomPayment;
