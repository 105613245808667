import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "./adddocument.css";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const AddDocument = ({ show, setShow, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [documentName, setDocumentName] = useState("");

  const [documentDescription, setDocumentDescription] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Document");
  const [error, setError] = useState("");

  const handleDocumentUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setDocumentFile(file);
      if (!file) {
        //toast("No file selected");
        return;
      }

      if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const documentData = new FormData();
      documentData.append("document", file); // Changed key to "document"

      // Sending document as form data to the backend
      try {
        const { data } = await authFetch.post(
          "/learning/document/upload-document", // Changed endpoint to handle documents
          documentData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setDocumentFile(data); // Changed state setter to reflect documents
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleDocument = async (e) => {
    e.preventDefault();
    if (!documentName || !documentName.trim() || !documentFile) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill all the fields"
          type="error"
        />
      );
      return;
    }

    try {
      const response = await authFetch.post("/learning/document", {
        documentName,
        documentDescription,
        documentFile,
        churchName: user.churchName,
      });

      if (response.status === 201 || response.status === 200) {
        handleReload();
        setDocumentName("");
        setDocumentDescription("");
        setDocumentFile(null);
        setProgress(0);
        setUploading(false);
        setUploadButtonText("Upload another audio");
        setShow(false);
        toast(
          <CustomToastContent
            title="Success"
            message="Document added successfully"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Document Name <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Audio Description <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="documentDescription"
            onChange={(e) => setDocumentDescription(e.target.value)}
            value={documentDescription}
            className="lpmw"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Upload Document <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="file"
            onChange={handleDocumentUpload}
            accept="application/pdf" // Restrict to PDF files only
            className="lpm"
          />
          <p className="ww233">Note: only PDF can be uploaded.</p>
          <p className="ww233">{error && error}</p>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleDocument}>
          Save Document
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDocument;
