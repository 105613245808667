import React from "react";
import { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import "./knowledgebasexx.css";
import { Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./knowledgebasexx.css";
import { Helmet } from "react-helmet";
import {
  FaBook,
  FaLaptop,
  FaChartPie,
  FaUsers,
  FaArrowRight,
} from "react-icons/fa"; // Example using FontAwesome icons

const KnowledgeBaseDetails = () => {
  const { logoutUser, user } = useAppContext();
  const { category } = useParams();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [resourceData, setResourceData] = useState([]);
  useEffect(() => {
    if (category) {
      //loadKnowledgeBase();
      setCategoryi(category);
    }
  }, [category]);
  console.log("resourceData", category);
  const [loading, setLoading] = useState(false);
  const [categoryi, setCategoryi] = useState("");
  const [error, setError] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    loadKnowledgeBase();
  }, []);

  const loadKnowledgeBase = async () => {
    try {
      setLoading(true); // Set loading state to true before fetching data
      const { data } = await authFetch.get(
        `/generalknowledge/knowledgedetails/${category}`
      );

      setResourceData(data);
    } catch (error) {
      console.error("Error loading course resources:", error);
      // Optionally, set an error state here to display an error message to the user
      setError("Failed to load resources. Please try again later.");
    } finally {
      setLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };
  console.log("resourceData", resourceData);
  return (
    <>
      <Helmet>
        <title>
          Knowledge Base | Churchease - Your Church Management Solution
        </title>
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="upperse">
            <p className="blogkk1">{categoryi}</p>
          </div>
        </Row>
        <Row className="lowsee231">
          {resourceData.map((categoryItem) => (
            <Col key={categoryItem.category} className="maincik">
              <div>
                <p className="maintexttxx2 text-center pb-3">
                  {categoryItem.titleSection}
                </p>
                {/* <p className="maintexttxxdata">Articles</p> */}
                {categoryItem.items.map((item) => (
                  <div
                    key={item.id}
                    className="course-card"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {/* Display titleSection above the "Articles" label */}

                    <div className="maintexttxx21">
                      <Link
                        to={`/knowledgebaseview/${item.id}`}
                        style={{ textDecoration: "none" }}
                        className="flex flex-row justify-between items-center"
                      >
                        <p className="maintexttxx">{item.title}</p>
                        <p>
                          <FaArrowRight
                            style={{ color: "black", fontSize: "0.6em" }}
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default KnowledgeBaseDetails;
