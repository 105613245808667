import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
const GrowthResponses = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const { logoutUser, user } = useAppContext();
  const [uniqueGrowthPlan, setUniqueGrowthPlan] = useState({});
  const [allPlan, setAllPlan] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/plannerbychurch/${id}`);

    setPlanner(data);
  };

  const [planner, setPlanner] = useState([]);

  useEffect(() => {
    loadCourse();
  }, []);

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">My Growth Plan</p>
        </Col>
      </Row>

      <Row>
        <div>
          {planner && planner.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "100%",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {planner.length ? (
                  <p>
                    {planner.length} record{planner.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Saved View</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th>PLAN NAME</th>
                    <th>ASSIGNED DATE</th>
                    <th>STATUS</th>
                    <th>PERCENTAGE COMPLETED</th>
                    <th>REVIEWED</th>
                    <th>NOTES</th>
                    <th style={{ textAlign: "center" }}>ACTIONS</th>
                  </tr>
                </thead>
                {planner.map((plan, index) => {
                  const matchingPlan = allPlan.find(
                    (ap) => ap.title === plan.title && ap.status
                  );
                  return (
                    <tr key={index} className="trdata">
                      <td>
                        <Link to={`/auth/peopleview/${plan.memberId}`}>
                          {plan.memberFirstName} {plan.memberLastName}
                        </Link>
                      </td>
                      <td>{moment(plan.createdAt).format("D MMMM YYYY")}</td>
                      <td>{plan.status}</td>
                      <td>{plan.percentageCompleted}%</td>
                      <td>{plan.reviewed === true ? "Yes" : "No"}</td>
                      <td style={{ width: "25%", maxWidth: "25%" }}>
                        {plan.notes}
                      </td>

                      <td className="rightco">
                        {
                          <Link to={`/auth/reviewplan/${plan._id}`}>
                            <button className="jiluil">Review Plan</button>
                          </Link>
                        }
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default GrowthResponses;
