import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaAutoprefixer, FaMoneyBill, FaPlane } from "react-icons/fa";
import o1 from "../../assets/newi/o1.png";
import o2 from "../../assets/newi/o2.png";
import MoreProduct from "../../components/Product/MoreProduct";

const ProductWorkflow = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#F23865" }}>
            <FaPlane size={100} />
            <h1>WorkFlow</h1>
            <p className="headpp">
              Streamline routine church tasks with our Workflow feature.
              Automate birthday and anniversary notices, and efficiently
              integrate new members into groups, reducing administrative efforts
              and boosting member engagement.
            </p>
            <button className="headerintro-button" onClick={handleDemoClick}>
              Book a demo
            </button>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">Everything you need for task automation</p>
            <p className="product13">
              Your all-encompassing solution to streamline daily operations,
              automate routine tasks, and ensure every church function runs
              smoothly.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={o1} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">TASK AUTOMATION</p>
              <p className="proddz1">
                Automatically handle repetitive tasks such as sending birthday
                and anniversary messages, freeing up staff for other duties.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MEMBER INTEGRATION</p>
              <p className="proddz1">
                Streamline the integration of new members into church life by
                automatically assigning them to relevant groups and programs.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CUSTOMIZABLE TEMPLATES</p>
              <p className="proddz1">
                Use customizable templates for communications to maintain a
                consistent and personal touch.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">SCHEDULE MANAGEMENT</p>
              <p className="proddz1">
                Plan and schedule tasks with an easy-to-use interface, ensuring
                nothing is overlooked.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">NOTIFICATION SYSTEM</p>
              <p className="proddz1">
                Receive notifications about upcoming tasks and deadlines to keep
                everyone on track.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">EFFICIENCY INSIGHTS</p>
              <p className="proddz1">
                Gain insights into workflow efficiency and task completion
                rates, allowing for continuous improvement.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={o2} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#F23865" />
      <Footer />
    </>
  );
};

export default ProductWorkflow;
