import React, { useState, useEffect } from "react";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import "./careers.css";
import { Helmet } from "react-helmet";
import { Col } from "react-bootstrap";
import k7 from "../../assets/images/k7.jpg";
import k8 from "../../assets/images/k8.jpg";
import { FaAdjust } from "react-icons/fa";
import {
  FcCheckmark,
  FcClock,
  FcDepartment,
  FcLock,
  FcReadingEbook,
  FcWorkflow,
  IconName,
} from "react-icons/fc";

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };
  return (
    <>
      <Helmet>
        <title>Careers | Join the Churchease Team</title>
        <meta
          name="description"
          content="Join our amazing team at Churchease. We are more than just a team - we're a community united by a passion for innovation and dedication to empowering church communities."
        />
        <link rel="canonical" href="https://churchease.com/careers" />
        <meta
          property="og:title"
          content="Careers | Join the Churchease Team"
        />
        <meta
          property="og:description"
          content="Join our amazing team at Churchease. We are more than just a team - we're a community united by a passion for innovation and dedication to empowering church communities."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta property="og:url" content="https://churchease.com/careers" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row className="career">
          <p className="abouttext">Careers</p>
          <p className="abouttext2">
            Join our amazing team at Churchease. We are more than just a team -
            we're a community united by a passion for innovation and dedication
            to empowering church communities.
          </p>
          <div style={{ width: "110%" }} className="my-4">
            <Button className="yjyj1" onClick={handleClick}>
              Browse for Open Positions
            </Button>
          </div>

          {clicked && (
            <p style={{ fontWeight: "600", color: "var(--primary-color)" }}>
              We currently have no open positions, but we welcome you to send
              your resume for future opportunities. Kindly send your resume to
              info@churchease.com
            </p>
          )}
        </Row>
        <Row className="about11">
          <Col md={6} lg={6} sm={12} xs={12}>
            <div className="imgh">
              <img className="aboutimg" src={k7} alt="about" />
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <div className="imgh">
              <img className="aboutimg" src={k8} alt="about" />
            </div>
          </Col>
        </Row>
        <Row className="uppercareer">
          <p className="text-center abouttextxx my-5 ">
            Values that defines us
          </p>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcClock className="careericon" />
              <p className="careerheader">Faith-Centered Innovation</p>
              <p className="careerbody">
                We are driven by a passion for blending faith with cutting-edge
                technology.
              </p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcLock className="careericon" />
              <p className="careerheader">Integrity and Stewardship</p>
              <p className="careerbody">
                Our commitment to integrity goes hand-in-hand with responsible
                stewardship.
              </p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcDepartment className="careericon" />
              <p className="careerheader">Ministry Support</p>
              <p className="careerbody">
                We believe in the power of collaboration to uplift ministries
                and communities.
              </p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcWorkflow className="careericon" />
              <p className="careerheader">Inclusivity in Worship</p>
              <p className="careerbody">
                Our tools are designed to make church management and
                participation accessible.
              </p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcCheckmark className="careericon" />
              <p className="careerheader">Excellence in Service</p>
              <p className="careerbody">
                Our mission is to provide outstanding, quality service and
                superior CHMS solutions.
              </p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="carerr">
            <div className="careermain">
              <FcReadingEbook className="careericon" />
              <p className="careerheader">Educational Empowerment</p>
              <p className="careerbody">
                We believe in empowering church staff and volunteers through
                education.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Careers;
