import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./formssss.css";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddForm = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [dynamicForm, setDynamicForm] = useState("text");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Form");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [required, setRequired] = useState(false);
  //const [tasks, setTasks] = useState([""]);
  // const [notifyMe, setNotifyMe] = useState(false);
  // const [assign, setAssign] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [radioOptions, setRadioOptions] = useState([{ id: 1, value: "" }]);
  const [checkboxOptions, setCheckboxOptions] = useState([
    { id: 1, value: "" },
  ]);
  const maxChars = 800;

  const [forms, setForms] = useState([
    { question: "", type: "text", options: [], order: 1, checked: false }, // Initial form structure
  ]);

  // Function to handle question text change
  const handleTaskChange = (index, value) => {
    const newForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, question: value };
      }
      return form;
    });
    setForms(newForms);
  };

  const handleCheckChange = (index, isChecked) => {
    const newForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, checked: isChecked };
      }
      return form;
    });
    setForms(newForms);
  };

  // Function to change the type of response for each task
  const handleTypeChange = (index, value) => {
    const newTasks = [...forms];
    newTasks[index].type = value;
    setForms(newTasks);
  };

  // Function to add a new task
  const addForm = () => {
    const newOrder = forms.length > 0 ? forms[forms.length - 1].order + 1 : 1;
    setForms([
      ...forms,
      { question: "", type: "text", options: [], order: newOrder },
    ]);
  };

  const removeForm = (index) => {
    const updatedForms = forms
      .filter((_, formIndex) => formIndex !== index)
      .map((form, i) => ({ ...form, order: i + 1 }));
    setForms(updatedForms);
  };

  // Function to add an option to a task
  const addOptionToTask = (index) => {
    const newTasks = [...forms];
    newTasks[index].options.push("");
    setForms(newTasks);
  };

  // Function to handle option text change
  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const newTasks = [...forms];
    newTasks[taskIndex].options[optionIndex] = value;
    setForms(newTasks);
  };

  // Function to remove an option from a task
  const removeOption = (taskIndex, optionIndex) => {
    const newTasks = [...forms];
    newTasks[taskIndex].options.splice(optionIndex, 1);
    setForms(newTasks);
  };
  const handleQuillChange = (content) => {
    setDescription(content);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      if (!title) return setError(true);

      if (description.length > maxChars) return setError2(true);

      const dataxx = {
        title,
        description,
        required,
        forms,

        churchName: user.churchName,
      };

      const response = await authFetch.post("/forms", dataxx);
      //console.log(data);
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        setTitle("");
        setDescription("");
        setRequired(false);
        setForms([""]);
        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Form Created Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>New Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && (
            <p className="inlineerror">
              Please complete the required fields to proceed!!
            </p>
          )}

          {error2 && (
            <p className="inlineerror">
              Description is above the required length!!
            </p>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Form Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicDescription" className="mb-5">
              <Form.Label>Form Description</Form.Label>

              <ReactQuill
                theme="snow"
                placeholder="Enter description"
                className="lpmw"
                style={{ height: "200px" }}
                value={description}
                onChange={handleQuillChange}
              />
              <div className="character-count flex items-center justify-end">
                {`${description.length} / ${maxChars}`} characters.
              </div>
            </Form.Group>

            <div className="mt-3">
              <Form.Label>Form Questions</Form.Label>
              {forms.map((task, index) => (
                <Row key={index} className="mb-3">
                  <Col>{task.order}</Col>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      placeholder="Question"
                      value={task.question}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Select
                      aria-label="Default select example"
                      value={task.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                    >
                      <option>Choose...</option>
                      <option value="text">Short answer</option>
                      <option value="textarea">Paragraph</option>
                      <option value="radio">Multiple Choice</option>
                      <option value="checkbox">Checkboxes</option>
                    </Form.Select>
                  </Col>
                  <Col
                    md={2}
                    className="flex flex-row justify-between "
                    style={{ alignItems: "center" }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={task.isChecked} // Use `checked` for checkboxes instead of `value`
                      label="Required"
                      onChange={(e) =>
                        handleCheckChange(index, e.target.checked)
                      } // Pass `e.target.checked` to get the boolean state
                    />

                    {index > 0 && (
                      <p
                        onClick={() => removeForm(index)}
                        style={{
                          marginBottom: "0px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Remove
                      </p>
                    )}
                  </Col>

                  {task.type === "radio" || task.type === "checkbox" ? (
                    <>
                      {task.options.map((option, optionIndex) => (
                        <Form.Group
                          key={optionIndex}
                          className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                        >
                          <Form.Check
                            type={task.type}
                            name={`group-${index}`}
                          />
                          <Form.Control
                            type="text"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                e.target.value
                              )
                            }
                          />
                          <FaTimes
                            onClick={() => removeOption(index, optionIndex)}
                            style={{ cursor: "pointer" }}
                          />
                        </Form.Group>
                      ))}
                      <button
                        className="jiluil3 mt-3 mb-3"
                        onClick={() => addOptionToTask(index)}
                      >
                        Add Option
                      </button>
                      <hr></hr>
                    </>
                  ) : null}
                </Row>
              ))}
              <hr></hr>
              <button className="jiluil mb-3s" onClick={addForm}>
                Add Field
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleForm}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddForm;
