// export const currencyFormatter = (data) => {
//   return ((data.amount * 100) / 100).toLocaleString(data.currency, {
//     style: "currency",
//     currency: data.currency,
//   });
// };

export const currencyFormatter = (data) => {
  try {
    // Ensure that data.currency is a valid currency code (e.g., "USD", "EUR")
    if (
      data.amount != null &&
      typeof data.currency === "string" &&
      data.currency.length === 3
    ) {
      return data.amount.toLocaleString(undefined, {
        style: "currency",
        currency: data.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      // Handle invalid currency code
      return "Invalid currency code";
    }
  } catch (error) {
    // Handle any other errors that might occur
    return "Error formatting currency";
  }
};
