import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
const AccessSecurity = ({ show, setShow }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const { user, logoutUser } = useAppContext();
  useEffect(() => {
    // Check if the user's role array includes an object with roleName "Standard"
    const hasStandardRole = user.role.some(
      (roleObj) => roleObj.roleName === "Standard"
    );

    if (hasStandardRole) {
      navigate(`/auth/peopleview/${user._id}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [user.role, user._id]); // Ensure the effect depends on user.role and user._id

  const handleClose = () => setShow(false);
  useEffect(() => {
    userAccessLog();
  }, []);
  const [accessLog, setAccessLog] = useState([]);
  const userAccessLog = async () => {
    try {
      const response = await authFetch.get(`/members/useraccesslog`);

      if (response.status === 200) {
        setAccessLog(response.data.accessData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [fullscreen, setFullscreen] = useState(true);

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Access Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
          <thead>
            <tr>
              <th style={{ fontSize: "1em" }}>Last Login</th>
              <th style={{ fontSize: "1em" }}>Full Name</th>
              <th style={{ fontSize: "1em" }}>Email</th>
              <th style={{ fontSize: "1em" }}>Role</th>
            </tr>
          </thead>
          {accessLog.map((log) => (
            <tbody>
              <tr>
                <td style={{ fontSize: "1em" }}>
                  <td>
                    {log.lastLogin
                      ? moment(log.lastLogin).format("LL, LT")
                      : "Date is not available"}
                  </td>
                </td>
                <td style={{ fontSize: "1em" }}>
                  <Link
                    to={"/auth/peopleview/" + log.id}
                    className="pointer"
                    style={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "black",
                      }}
                    >
                      {log.fullName}
                    </a>
                  </Link>
                </td>
                <td style={{ fontSize: "1em" }}>{log.email}</td>
                <td style={{ fontSize: "1em" }}>{log.role}</td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3 " onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessSecurity;
