import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./register.css";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import LoginGrap from "../../assets/images/LoginGrap.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../context/appContext";
import { Link, useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import sss from "../../assets/images/sss.png";

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [email, setEmail] = useState("");
  const [churchDomain, setChurchDomain] = useState("");

  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const { user, displayAlert, logoutUser } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/auth");
      }, 3000);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if email is empty or not provided
    if (!email || email.trim() === "") {
      setError(true);
      return;
    }

    if (!churchDomain || churchDomain.trim() === "") {
      setError(true);
      return;
    }

    try {
      const userEmail = { email: email, churchDomain: churchDomain };

      const response = await authFetch.post("/email/forgotpassword", userEmail);

      if (response.status === 200 || response.status === 201) {
        navigate("/resetpasswordsent");
      } else if (response.status === 204 || response.status === 404) {
      }
    } catch (error) {
      console.error("Error during password reset request:", error);
      setErrorCode(
        "There was an error processing your request. Check the email address and try again."
      );
      // Display a generic error message
      displayAlert("Unable to process request", "error");
    }
  };

  return (
    <>
      <Container fluid style={{ padding: "0" }}>
        <Row className="container2">
          <Col sm={6} md={6} className="logindiv2xx">
            <Image fluid src={sss} className="imgsizexs" />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingTop: "2rem",
                fontSize: "1.9rem",
                width: "65%",
              }}
            >
              Transform Your Church Management with Churchease
            </p>
            {/* <Image fluid src={LoginGrap} className="imgsizex" /> */}
            <p className="redi">
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
          <Col className="signupcolumn">
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {errorCode && <p className="inlineerror">{errorCode}</p>}
              {error && (
                <CustomAlert
                  alertType="danger"
                  alertText="Church Domain and Email Address is required to identify your account."
                />
              )}
              <p className="welcometssext">Reset Password</p>
              <p className="welcometssextsmall">
                We'll email you instructions on how to reset your password.
              </p>

              <Form.Group className="mb-3" controlId="formDomain">
                <Form.Label style={{ fontWeight: "600", fontSize: "1em" }}>
                  Chuch Domain
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter church name"
                  className="forminput"
                  onChange={(e) => setChurchDomain(e.target.value)}
                  value={churchDomain}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontWeight: "600", fontSize: "1em" }}>
                  Email Address{" "}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="forminput"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>

              <Button type="submit" className="buttonner">
                Submit
              </Button>

              <br></br>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
