import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import Modal from "react-bootstrap/Modal";
import nodd from "../../../assets/newi/nodd.png";
import { useAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";

const Children = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const { logoutUser, showAlert } = useAppContext();
  const [childData, setChildData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllChild();
  }, []);

  const getAllChild = async () => {
    try {
      setIsLoading(true);
      const { data } = await authFetch.get(`/children/getallchildren`);

      if (data) {
        setChildData(data);
        setIsLoading(false);
      } else {
        console.error("Unexpected response structure:", data);
      }
    } catch (error) {
      console.error("Error fetching classroom data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewProfile = (e, childD) => {
    e.preventDefault();
    navigate(`/auth/peopleview/${childD._id}`);
  };

  return (
    <Container fluid>
      {showAlert && <Alert />}

      <Row className="headerbanner">
        <div className="bannerbig">Children</div>
      </Row>

      {isLoading ? (
        <Loading />
      ) : childData.length > 0 ? (
        <Row className="fundc2">
          <p
            style={{
              fontWeight: "700",
              fontSize: "1em",
              color: "var(--primary-color)",
              paddingTop: "2rem",
            }}
          >
            {childData.length} record{childData.length > 1 ? "s" : ""} found
          </p>
          <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>NAME</th>
                <th style={{ fontSize: "1em" }}>ASSIGNED</th>
                <th style={{ fontSize: "1em" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {childData
                .sort((a, b) => {
                  if (a.mlastName.toLowerCase() < b.mlastName.toLowerCase())
                    return -1;
                  if (a.mlastName.toLowerCase() > b.mlastName.toLowerCase())
                    return 1;
                  return 0;
                })
                .map((childD) => (
                  <tr key={childD._id}>
                    <td style={{ fontSize: "1em" }}>
                      {childD.mfirstName} {childD.mlastName}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {childD.childClassName
                        ? childD.childClassName
                        : "Not Assigned"}
                    </td>
                    <td
                      style={{ display: "flex", gap: "10px", fontSize: "1em" }}
                    >
                      <button
                        className="jiluil"
                        onClick={(e) => viewProfile(e, childD)}
                      >
                        View Profile
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      ) : (
        <div className="noData">
          <img src={nodd} alt="no data" />
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}
    </Container>
  );
};

export default Children;
