import React, { useState, useContext, useEffect } from "react";
import "./calendar.css";
import { getMonth } from "../../../utils";
import CalendarHeader from "./CalendarHeader";
import Sidebar from "./Sidebar";
import Month from "./Month";
import EventModal from "./EventModal";
import Container from "react-bootstrap/Container";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";

const Calendar = () => {
  const { logoutUser, events } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [events1, setEventxs] = useState([]);
  useEffect(() => {
    getEventById();
  }, [events]);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(`/events`);

      if (data) {
        setEventxs(data.events);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [lables, setLable] = useState([]);
  const [filterEvents, setEvents] = useState([]);

  useEffect(() => {
    if (events1?.length) {
      setEvents(events1);
      let uniqueLabels = events1.map((e) => e.eventLabel);
      let filterLbl = Array.from(new Set(uniqueLabels));
      let filt = filterLbl.map((e) => ({ lbl: e, checked: true }));
      setLable(filt);
    }
  }, [events1]);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const handleChange = (newObj, idx) => {
    let ar = [...lables];
    ar[idx] = newObj;
    setLable(ar);
    let selectedLbls = ar.filter((e) => e.checked);
    let filterEvts = events1.filter((e) =>
      selectedLbls.find((j) => j.lbl === e.eventLabel)
    );
    setEvents(filterEvts);
  };

  return (
    <>
      {showEventModal && <EventModal />}
      <Container fluid className="calendarmain">
        <CalendarHeader />
        <div className="calendarsidebar">
          <Sidebar lables={lables} handleChange={handleChange} />
          <Month month={currenMonth} events={filterEvents} />
        </div>
      </Container>
    </>
  );
};

export default Calendar;
