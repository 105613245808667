import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import nodd from "../../../assets/newi/nodd.png";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";

const Teachers = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, showAlert, completemembers, user, getCompleteMembers } =
    useAppContext();

  useEffect(() => {
    getCompleteMembers();
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showxx, setShowxx] = useState(false);
  const handleClosexx = () => setShowxx(false);
  const handleShowxx = () => setShowxx(true);

  const handleClose1 = () => setShow1(false);

  const [teacherName, setTeacherName] = useState("");
  const [teacherBackgroundCheck, setTeacherBackgroundCheck] = useState(false);

  const [error, setError] = useState(false);

  const [show1, setShow1] = useState(false);
  const [clicked, setClicked] = useState("");

  const [teacherNameEdit, setTeacherNameEdit] = useState("");
  const [teacherBackgroundCheckEdit, setTeacherBackgroundCheckEdit] =
    useState(false);
  const [selectedClassroomEdit, setSelectedClassroomEdit] = useState("");

  useEffect(() => {
    if (clicked) {
      setTeacherNameEdit(clicked.teacherName);
      setTeacherBackgroundCheckEdit(clicked.teacherBackground);
      setSelectedClassroomEdit(clicked.className);
    }
  }, [clicked]);
  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!teacherName) {
      setError(true);
      return;
    }

    const data = {
      teacherName: teacherName,
      teacherBackground: teacherBackgroundCheck,
      className: selectedClassroom,
    };

    await authFetch.post("/children/addteacher", data);
    setTeacherName("");
    setTeacherBackgroundCheck(false);
    setSelectedClassroom("");
    fetchTeacherData();
    handleClose();
  };

  const handleSubmitxx = async (e) => {
    e.preventDefault();
    if (!teacherNameEdit) {
      setError(true);
      return;
    }

    const data = {
      teacherName: teacherNameEdit,
      teacherBackgroundCheck: teacherBackgroundCheckEdit,
      className: selectedClassroomEdit,
    };

    await authFetch.patch(`/children/reassignteacher/${clicked._id}`, data);
    setTeacherName("");
    setTeacherBackgroundCheck(false);
    setSelectedClassroom("");
    fetchTeacherData();
    handleClosexx();
  };

  useEffect(() => {
    fetchTeacherData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await authFetch.get("/children/getclassrooms");
      // Assuming the actual data is in `response.data.result` based on typical Axios response structure

      if (response && response.data) {
        setClassrooms(response.data);
      } else {
        // Handle the case where the response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // Handle errors from the fetch operation
      console.error("Error fetching classroom data:", error);
    } finally {
      setIsLoading(false); // Ensure loading is turned off whether there's an error or not
    }
  };

  const [teacherData, setTeacherData] = useState([]);

  const fetchTeacherData = async () => {
    try {
      const response = await authFetch.get("/children/getteachers");
      // Assuming the actual data is in `response.data.result` based on typical Axios response structure

      if (response && response.data) {
        setTeacherData(response.data);
      } else {
        // Handle the case where the response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // Handle errors from the fetch operation
      console.error("Error fetching classroom data:", error);
    }
  };

  const [classrooms, setClassrooms] = useState([]);
  const getClassroomByID = async (e, id) => {};

  const handleShow1 = (e, fundss) => {
    e.preventDefault();
    setShow1(true);
    setClicked(fundss);
  };

  const handleShowrr = (e, fundss) => {
    e.preventDefault();
    setShowxx(true);
    setClicked(fundss);
  };
  const handleDelete = async () => {
    try {
      await authFetch.delete(`/children/deleteteacher/${clicked._id}`);
      fetchData();
      handleClose1();
      fetchTeacherData();
    } catch (error) {
      console.error("Error deleting classroom:", error);
    }
  };
  const [selectedClassroom, setSelectedClassroom] = useState("");

  const handleSelectChange = (event) => {
    setSelectedClassroom(event.target.value);
  };

  const handleSelectChangeEdit = (event) => {
    setSelectedClassroomEdit(event.target.value);
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="sssssssecwef mt-5">
          <div className="bnmjo">
            <Button className="wedweded" onClick={handleShow}>
              Assign Teacher
            </Button>
          </div>
        </Row>
        {isLoading ? (
          <Loading />
        ) : teacherData && teacherData.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>TEACHER NAME</th>
                  <th style={{ fontSize: "1em" }}>ASSIGNED CLASS</th>
                  <th style={{ fontSize: "1em" }}>BACKGROUNDCHECK</th>
                  <th style={{ fontSize: "1em" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {teacherData
                  ?.sort((a, b) => a.teacherName.localeCompare(b.teacherName))
                  .map((teacherD) => (
                    <tr key={teacherD._id}>
                      <td style={{ fontSize: "1em" }}>
                        {teacherD.teacherName}
                      </td>
                      <td style={{ fontSize: "1em" }}>{teacherD.className}</td>
                      <td style={{ fontSize: "1em" }}>
                        {" "}
                        {teacherD.teacherBackground ? "Yes" : "No"}{" "}
                      </td>
                      <td
                        style={{
                          gap: "10px",
                          display: "flex",
                          fontSize: "1em",
                        }}
                      >
                        <button
                          className="jiluil3"
                          onClick={(e) => handleShowrr(e, teacherD)}
                        >
                          Change Class
                        </button>

                        <Link to={"/auth/teachersdesk/" + teacherD._id}>
                          <button className="jiluil">
                            Go to Teacher's desk
                          </button>
                        </Link>
                        <button
                          className="jiluil"
                          onClick={(e) => handleShow1(e, teacherD)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add Teacher
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">Teacher Name is required to proceed</p>
            )}
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              style={{ position: "relative" }}
            >
              <Form.Control
                type="text"
                placeholder="Type Name"
                value={teacherName}
                onChange={(e) => setTeacherName(e.target.value)}
                name="className"
                style={{ fontSize: "1em" }}
                required
              />
              {completemembers.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    transform: "translateY(10px)", // Adjust this value to move the div up or down as needed
                    zIndex: 1000,
                    backgroundColor: "white", // Ensure dropdown is visible on all backgrounds
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {completemembers
                      .filter((item) => {
                        const searchTerm = teacherName?.toLowerCase();
                        const fname = item.mfirstName?.toLowerCase();
                        const lname = item.mlastName?.toLowerCase();

                        const fullname = fname + lname;

                        return (
                          searchTerm &&
                          fullname.includes(searchTerm) &&
                          fullname !== searchTerm
                        );
                      })
                      .slice(0, 10)
                      .map((item) => {
                        const fullName = item.mfirstName + " " + item.mlastName;
                        return (
                          <div
                            onClick={() => setTeacherName(fullName)}
                            className="dropdownmy-row ewfwefwefwe"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              padding: "8px",
                            }}
                            key={item.mfirstName}
                          >
                            {fullName}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="classroomSelect">
              <Form.Label>Select Classroom</Form.Label>
              <Form.Select
                value={selectedClassroom}
                onChange={handleSelectChange}
              >
                <option value="">Select a classroom</option>
                {classrooms.map((classroom) => (
                  <option key={classroom._id} value={classroom.className}>
                    {classroom.className}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Completed background check" // You can change this to the appropriate label
                checked={teacherBackgroundCheck} // Assuming classDescription is a boolean
                onChange={(e) => setTeacherBackgroundCheck(e.target.checked)}
                name="teacherBackgroundCheck"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Add Teacher
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected teacher.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showxx} onHide={handleClosexx}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Reassign Teacher
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">Teacher Name is required to proceed</p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Type Name"
                value={teacherNameEdit}
                onChange={(e) => setTeacherNameEdit(e.target.value)}
                name="className"
                style={{ fontSize: "1em" }}
                required
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  position: "absolute",
                }}
              >
                {completemembers
                  .filter((item) => {
                    const searchTerm = teacherNameEdit?.toLowerCase();
                    const fname = item.mfirstName?.toLowerCase();
                    const lname = item.mlastName?.toLowerCase();

                    const fullname = fname + lname;

                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => {
                    const fullName = item.mfirstName + " " + item.mlastName;
                    return (
                      <div
                        onClick={() => setTeacherNameEdit(fullName)}
                        className="dropdownmy-row ewfwefwefwe"
                        style={{ width: "50%" }}
                        key={item.mfirstName}
                      >
                        {fullName}
                      </div>
                    );
                  })}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="classroomSelect">
              <Form.Label>Select Classroom</Form.Label>
              <Form.Select
                value={selectedClassroomEdit}
                onChange={handleSelectChangeEdit}
              >
                <option value="">Select a classroom</option>
                {classrooms.map((classroom) => (
                  <option key={classroom._id} value={classroom.className}>
                    {classroom.className}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Completed background check" // You can change this to the appropriate label
                checked={teacherBackgroundCheckEdit} // Assuming classDescription is a boolean
                onChange={(e) =>
                  setTeacherBackgroundCheckEdit(e.target.checked)
                }
                name="teacherBackgroundCheck"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClosexx}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleSubmitxx}>
            Add Teacher
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Teachers;
