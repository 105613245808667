import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import moment from "moment/moment";
import { FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { CSVLink } from "react-csv";

const TestimonyAchieve = () => {
  const {
    getPrayerRequest,

    logoutUser,
    isLoading,
    deletePrayer,
    updatePrayer,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [allSettings, setAllSettings] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [show, setShow] = useState(false);
  const [prayer, setPrayer] = useState();
  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = async () => {
    const data = await authFetch.get(`/testimony`);
    console.log(data);
    if (data.statusText === "OK") {
      setAllSettings(data.data.testimony);
    }
  };
  console.log(allSettings);
  useEffect(() => {
    getPrayerRequest();
  }, []);

  const prayerCompleted = allSettings.filter(
    (prayer) => prayer.status === false
  );

  const handleClose = () => setShow(false);
  const handleShow = (prayer) => {
    setShow(true);
    setPrayer(prayer);
  };

  const handleMouseEnter = (prayerId) => {
    setHoveredItem(prayerId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch.delete(`/testimony/${prayer._id}`);
      handleClose(); // Assuming this function is defined elsewhere and closes a modal or popup
      // You might want to add additional logic here to handle the successful deletion, like updating the UI
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error specifically, for example, redirect to a login page or show an error message
      } else {
        // Handle other types of errors, possibly showing a notification or error message to the user
      }
    }
  };

  const [status, setStatus] = useState(true);

  // const handleCheckboxChange = (e, prayer) => {
  //   setStatus(true);
  //   const prayerRequestUpdate = {
  //     status: status,
  //     prayerId: prayer._id,
  //   };

  //   let prayerId = prayer._id;

  //   updatePrayer(prayerRequestUpdate, prayerId);
  //   getPrayerRequest();
  // };

  const handleCheckboxChange = async (e, prayer) => {
    e.stopPropagation();

    setStatus(true); // Assuming you want to toggle the status

    // Prepare the data for updating
    const prayerRequestUpdate = {
      status: status, // This toggles the status based on its previous value
      prayerId: prayer._id,
    };

    const prayerId = prayer._id;

    try {
      const response = await authFetch.put(
        `/testimony/${prayerId}`,
        prayerRequestUpdate
      );

      if (response.status === 200) {
        getCategory(); // Assuming this function fetches the updated list of categories/prayers
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error specifically, maybe redirect to login
      } else {
        // Handle other errors, maybe show a notification to the user
      }
    }
  };

  const extractedData = prayerCompleted.map(
    ({ testimonySubject, testimony, createdAt }) => ({
      testimonySubject,
      testimony,
      createdAt,
    })
  );
  <div className="flex justify-center items-center w-full h-screen">
    {isLoading && <Loading />}
  </div>;
  return (
    <Container fluid>
      <Row className="prayerhead">
        <p className="sahs" style={{ display: "flex", justifyContent: "left" }}>
          Testimony Achieve
        </p>
      </Row>
      {/* <Row className="sssssssecwef">
        <div
          className="bnmjo"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button className="buttonner1ss">
            <CSVLink
              data={extractedData}
              filename="achievedTestimonies.csv"
              style={{ textDecoration: "none", color: "white" }}
              className="buttoner"
            >
              Download
            </CSVLink>
          </Button>
        </div>
      </Row> */}
      <Row>
        <div className="prayerwall">
          {prayerCompleted.length === 0 ? (
            <div
              style={{
                fontWeight: "600",
                fontSize: "1.5em",
                marginTop: "250px",
              }}
            >
              No Achieved Prayer Request
            </div>
          ) : (
            prayerCompleted.map((prayer) => (
              <div
                className={`prayer2 ${
                  hoveredItem === prayer._id ? "hovered" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(prayer._id)}
                onMouseLeave={handleMouseLeave}
              >
                <p className="praydate">
                  {moment(prayer.createdAt).format("MMMM Do YYYY")}
                </p>
                <p className="prayersubject">
                  {prayer?.testimonySubject?.substring(0, 18) + "..."}
                </p>
                <p style={{ flex: 1 }}>
                  {prayer?.testimony?.substring(0, 75) + "..."}
                </p>
                {hoveredItem === prayer._id && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="jiluil"
                      onClick={(e) => handleCheckboxChange(e, prayer)}
                    >
                      Restore
                    </button>
                    <button
                      className="jiluil3"
                      onClick={() => handleShow(prayer)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em", fontWeight: "600" }}>
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Deleted prayer requests cannot be recovered!</Modal.Body>
        <Modal.Footer>
          <button
            className="jiluil"
            style={{ fontSize: "1em" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="jiluil3"
            style={{ fontSize: "1em" }}
            onClick={handleDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TestimonyAchieve;
