import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import { Row, Col, Table, Form, Modal } from "react-bootstrap";
import "./appadmin.css";
import Loading from "../../../components/Loading/Loading";
import nodd from "../../../assets/newi/nodd.png";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import { differenceInDays, parseISO, format, formatISO } from "date-fns";

const AppAdminDashboard = () => {
  const { logoutUser, user } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getChurch();
    getChurchNames();
  }, []);

  const [churchCount, setChurchCount] = useState({});
  const [churchName, setChurchName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [church, setChurch] = useState(null);
  const [mfirstName, setMfirstName] = useState("");
  const [mlastName, setMlastName] = useState("");
  const [churchNames, setChurchNames] = useState("");
  const [churchDta, setChurchDta] = useState({});
  const [freeTrialExpiry, setExpiryDate] = useState("");
  const [memberShipType, setMemberShipType] = useState("");

  const getChurch = async () => {
    try {
      const response = await authFetch.get("/appadmin/getchurches");
      if (response && response.data) {
        setChurchCount(response.data);
      }
    } catch (error) {
      console.error("Error fetching campus:", error);
    }
  };

  const getChurchNames = async () => {
    try {
      setIsLoading(true);
      const response = await authFetch.get("/appadmin/getchurchnames");
      if (response && response.data) {
        setChurchName(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching campus:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (e, church) => {
    e.preventDefault();
    setShow1(true);
    setChurch(church);
  };

  const deleteChurch = async (e) => {
    e.preventDefault();
    try {
      const response = await authFetch.delete(
        `/appadmin/deletechurch/${church.churchId}`
      );
      if (response && response.data) {
        getChurchNames();
        getChurch();
        setShow1(false);
        toast(
          <CustomToastContent title="Success" message="Delete Successful!" />
        );
      }
    } catch (error) {
      console.error("Error deleting church:", error);
    }
  };

  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);

  const handleMember = async (e, church) => {
    e.preventDefault();
    e.stopPropagation();
    setShow2(true);
    try {
      const response = await authFetch.get(
        `/auth/churchbyid/${church.churchId}`
      );
      if (response.status === 200 || response.status === 201) {
        setChurchDta(response.data.church);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching church:", error);
    }
  };

  useEffect(() => {
    if (churchDta) {
      setMfirstName(churchDta.mfirstName || "");
      setChurchNames(churchDta.churchName || "");
      setMlastName(churchDta.mlastName || "");
      setExpiryDate(churchDta.freeTrialExpiry || "");
      setMemberShipType(churchDta.memberShip || "");
    }
  }, [churchDta]);

  const updateExpiry = async (e) => {
    e.preventDefault();

    try {
      const response = await authFetch.patch(
        `/auth/updatechurch/${churchDta._id}`,
        { freeTrialExpiry: new Date(freeTrialExpiry).setHours(0, 0, 0, 0) }
      );

      if (response.status === 200 || response.status === 201) {
        getChurchNames();
        getChurch();
        setShow2(false);
        toast(
          <CustomToastContent title="Success" message="Update Successful!" />
        );
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error updating church:", error);
    }
  };

  return (
    <Container fluid>
      <Row className="adminmain">
        <Col className="churchcount">
          <p className="churchcount2">Churches</p>
          <p className="churchcount3">{churchCount.uniqueChurchCount}</p>
        </Col>
        <Col className="membercount">
          <p className="churchcount2">Total Members</p>
          <p className="churchcount3">{churchCount.uniqueMemberCount}</p>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : churchName?.length > 0 ? (
        <Row className="fundc2">
          <p
            style={{
              fontWeight: "700",
              fontSize: "1em",
              color: "var(--primary-color)",
              paddingTop: "2rem",
            }}
          >
            {churchName?.length} record{churchName?.length > 1 ? "s" : ""} found
          </p>
          <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>CREATED</th>
                <th style={{ fontSize: "1em" }}>NAME</th>
                <th style={{ fontSize: "1em" }}>TYPE</th>
                <th style={{ fontSize: "1em" }}>STATUS</th>
                <th style={{ fontSize: "1em" }}>FREE TRIAL ENDS</th>
                <th style={{ fontSize: "1em" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {churchName.map((church) => {
                const expiryDate = church.freeTrialExpiry
                  ? parseISO(church.freeTrialExpiry) // Parse the ISO date string
                  : null;
                const currentDate = new Date(); // Current date

                let remainingDays = 0;

                if (expiryDate) {
                  // Ensure both dates are set to 12 AM to avoid timezone issues
                  expiryDate.setHours(0, 0, 0, 0);
                  currentDate.setHours(0, 0, 0, 0);

                  const daysUntilExpiry = differenceInDays(
                    expiryDate,
                    currentDate
                  );
                  remainingDays = daysUntilExpiry;
                }

                return (
                  <tr key={church._id}>
                    <td>
                      {format(new Date(church.createdAt), "MMMM dd, yyyy")}
                    </td>
                    <td style={{ fontSize: "1em" }}>{church.churchName}</td>
                    <td style={{ fontSize: "1em" }}>{church.memberShip}</td>
                    <td style={{ fontSize: "1em" }}>
                      {church.freeAccountStatus}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {church.memberShip === "Free" &&
                        expiryDate &&
                        remainingDays > 0 && (
                          <>
                            {format(expiryDate, "yyyy-MM-dd")} ({remainingDays}{" "}
                            days left)
                          </>
                        )}
                      {church.memberShip === "Free" &&
                        expiryDate &&
                        remainingDays <= 0 && (
                          <span style={{ color: "red" }}>
                            {" "}
                            (Free trial ended)
                          </span>
                        )}
                      {church.memberShip === "Free" && !expiryDate && (
                        <span style={{ color: "orange" }}>
                          {" "}
                          (Free trial not set)
                        </span>
                      )}
                    </td>
                    <td
                      style={{ display: "flex", gap: "10px", fontSize: "1em" }}
                    >
                      <button
                        className="jiluil"
                        onClick={(e) => handleMember(e, church)}
                      >
                        Manage Members
                      </button>
                      <button
                        className="jiluil2"
                        onClick={(e) => handleDelete(e, church)}
                      >
                        Delete Church
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      ) : (
        <div className="noData">
          <img src={nodd} alt="no data" />
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}

      <Modal show={show2} onHide={handleClose2} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Manage Church</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              First Name<span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={mfirstName}
              name="mfirstName"
              onChange={(e) => setMfirstName(e.target.value)}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              Last Name<span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={mlastName}
              name="mlastName"
              onChange={(e) => setMlastName(e.target.value)}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              Church Name<span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={churchNames}
              name="churchNames"
              onChange={(e) => setChurchNames(e.target.value)}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              Expiry Date<span className="myast">*</span>
            </Form.Label>
            <div className="flex flex-row justify-between gap-4">
              <Form.Control
                type="date"
                value={
                  freeTrialExpiry
                    ? formatISO(parseISO(freeTrialExpiry), {
                        representation: "date",
                      })
                    : ""
                }
                name="freeTrialExpiry"
                onChange={(e) => setExpiryDate(e.target.value)}
                className="formvalue"
                required
              />
              {memberShipType === "Free" && (
                <button className="jiluil" onClick={updateExpiry}>
                  Set Expiry
                </button>
              )}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose2}>
            Close
          </button>
          <button className="jiluil3" onClick={(e) => updateExpiry(e)}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete this church and its member. This
          action cannot be reversed.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Close
          </button>
          <button className="jiluil3" onClick={(e) => deleteChurch(e)}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AppAdminDashboard;
