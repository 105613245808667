import { useEffect, useState } from "react";
import axios from "axios";

import { SyncOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { useAppContext } from "../../../context/appContext";
import Button from "react-bootstrap/Button";
const UserDashboard = () => {
  const { logoutUser } = useAppContext();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const loadCourses = async () => {
    try {
      setLoading(true);
      const { data } = await authFetch.get("/learning/user-courses");
      setCourses(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCourses();
  }, []);

  return (
    <>
      {loading && (
        <SyncOutlined
          spin
          className="d-flex justify-content-center display-1 text-danger p-5"
        />
      )}
      <Container>
        <Row className="courseheader">
          <p
            className="courseHeaderPadding"
            style={{ display: "flex", justifyContent: "center" }}
          >
            User Dashboard
          </p>
        </Row>
        <Row style={{ padding: "2rem" }}>
          {courses &&
            courses.map((course) => (
              <div
                key={course?._id}
                className="usercard"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                alt="Image"
              >
                <img
                  src={course?.image ? course?.image?.Location : "/course.png"}
                  style={{ height: "200px", objectFit: "cover" }}
                  className="courseimagepreview"
                />

                <div
                  className="media-body pl-2"
                  style={{ paddingRight: "10rem" }}
                >
                  <div className="row">
                    <div className="col">
                      <Link
                        to={`/auth/singlecourseview/${course?.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <a>
                          <h5 className="courseTitle">{course?.name}</h5>
                        </a>
                      </Link>
                      <p style={{ marginTop: "-10px" }}>
                        {course?.lessons?.length} lessons
                      </p>

                      <Link to={`/auth/singlecourseview/${course?.slug}`}>
                        <Button className="buttonner">
                          <a>Play Course</a>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default UserDashboard;
