import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";

const AddMenu = () => {
  const [menu, setMenu] = useState("");
  const [permission, setPermission] = useState("");
  const [description, setDescription] = useState("");
  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
 
  const handleSubmit = async () => {
    const menuData = {
      label: menu,
      permission: permission,
      description: description,
      generatedby: "System",
    };

    try {
      await authFetch.post("/menu", menuData);

      setPermission("");
      setDescription("");
    } catch (error) {
      logoutUser();
    }
  };
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Menu Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Menu"
          value={menu}
          onChange={(e) => setMenu(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Permission</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Permission Name"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
  );
};

export default AddMenu;
