import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "./people.css";
import { Button } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Alert from "../../../components/Alert/Alert";
import { useAppContext } from "../../../context/appContext";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import FollowUp from "../../../components/FollowUp/FollowUp";
import axios from "axios";

const PeopleView = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { _id } = useParams();

  const {
    showAlert,
    getMemberById,
    uniquememberdetails,
    completemembers,
    getCompleteMembers,
    getAllSettings,
    setting,
    user,
    logoutUser,
    getAllFollowUp,
    followups,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
  }, []);

  useEffect(() => {
    getDynamicFields();
  }, []);
  const [dynamicFields, setDynamicFields] = useState([]);
  const getDynamicFields = async () => {
    try {
      const response = await authFetch.get("/dynamicfields");

      // Check if the response is in the expected format
      if (response.status === 200 || response.status === 201) {
        setDynamicFields(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch dynamic fields:", error);
      // Handle the UI update for error state here, if necessary
      // e.g., setErrorState(true) or similar
    }
  };

  const combinedFields = dynamicFields.map((assignment) => {
    // For each dynamic field, map through its fields
    const updatedFields = assignment.fields.map((field) => {
      // Find the response that corresponds to this field based on fieldId
      const matchingResponse = uniquememberdetails.responses.find(
        (response) => response.fieldId === field._id
      );

      // Return the field with the response attached
      return {
        ...field,
        response: matchingResponse ? matchingResponse.response : null, // Attach the response or null if not found
      };
    });

    return {
      ...assignment,
      fields: updatedFields, // Attach the updated fields array with responses
    };
  });

  const [newFamilyId, setNewFamilyId] = useState(uniquememberdetails?.familyId);
  const [familyValue, setFamilyValue] = useState([]);
  const [isFamilyLoading, setIsFamilyLoading] = useState(false);

  useEffect(() => {
    if (uniquememberdetails) {
      getFamilyId();
    }
  }, [uniquememberdetails]);

  useEffect(() => {
    if (uniquememberdetails && uniquememberdetails?.familyId) {
      setNewFamilyId(uniquememberdetails?.familyId);
    }
  }, [uniquememberdetails]);

  const getFamilyId = async () => {
    try {
      // Start loading before the API call
      setIsFamilyLoading(true);

      const response = await authFetch(
        `/members/getfamilyidx/${uniquememberdetails?.familyId}`
      );

      if (response.status === 200 || response.status === 201) {
        setFamilyValue(response.data);
      }

      // Stop loading after data is loaded
      setIsFamilyLoading(false);
    } catch (error) {
      console.error("Error fetching family ID:", error);
      setIsFamilyLoading(false); // Stop loading even if there's an error
      // Handle the error appropriately (e.g., display an error message)
    }
  };

  const navigate = useNavigate();
  const peopleEdit = () => {
    navigate("/auth/peopleedit/" + _id);
  };

  useEffect(() => {
    getMemberById(_id);
    getAllFollowUp();
  }, []);

  useEffect(() => {
    const filtered = followups?.filter((item) => {
      return item.assignedTo?.some(
        (assignment) =>
          assignment.followUpAssignTo === user.mfirstName + " " + user.mlastName
      );
    });
    setFilteredData(filtered);
  }, [followups, user.mfirstName, user.mlastName]);

  const uname = user?.mfirstName + " " + user?.mlastName;
  const fullname =
    uniquememberdetails?.mfirstName + " " + uniquememberdetails?.mlastName;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [followHow, setFollowHow] = useState("");
  const [followName, setFollowName] = useState(fullname);
  const [followDesc, setFollowDesc] = useState(uname);
  const [followDate, setFollowDate] = useState();
  const [followComplete, setFollowComplete] = useState(false);
  const [checkField, setCheckField] = useState(false);
  const [followReason, setFollowReason] = useState([]);
  const [followNote, setFollowNote] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const handleRadioChange = (e) => {
    setFollowHow(e.target.value);
  };

  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      // If checkbox is checked, add the value to the selectedReasons array
      setFollowReason([...followReason, value]);
    } else {
      // If checkbox is unchecked, remove the value from the selectedReasons array
      setFollowReason(followReason.filter((reason) => reason !== value));
    }
  };

  const followUps = () => {
    handleShow();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [fname, lname] = fullname.split(" ");
    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;

    if (!followDesc || !followDate || !followHow || !followReason) {
      setCheckField(true);
      return;
    }

    const checkIt = {
      followUpName: fullname,
      followUpAssignTo: followDesc,
      followupDate: followDate,
      churchName: uniquememberdetails?.churchName,
      mfname: fname,
      mlname: lname,
      mId: memberIdOnly?._id,
      followUpCompleteUpdate: followComplete,
      followMedia: followHow,
      followReason: followReason,
      followNote: followNote,
      assignedBy: user.mfirstName + " " + user.mlastName,
    };

    try {
      await authFetch.post("/followup/assignto", checkIt);
      setFollowName("");
      setFollowDesc("");
      setFollowDate("");
      setFollowComplete("");
      setFollowHow("");
      setFollowReason("");
      setFollowNote("");
      handleClose();
    } catch (err) {}
  };

  const isStandard = user.role.some((r) => r.roleName === "Standard");
  return (
    <>
      <Container fluid>
        <Row className="fundc">
          <Col className="alignright">
            <h3>View Members</h3>
          </Col>
          <Col className="reddd" style={{ gap: "10px" }}>
            {!isStandard && (
              <Button className="batchonneraaxx" onClick={followUps}>
                Assign Follow-Up
              </Button>
            )}
            <Button className="batchonneraa" onClick={peopleEdit}>
              {isStandard ? "Edit my profile" : "Edit member"}
            </Button>
          </Col>
        </Row>
        <Row className="memberc">
          {showAlert && <Alert />}

          <>
            <Col sm={3} style={{ backgroundColor: "#f7f7f7" }}>
              <div className="imgdddd">
                <Image
                  className="pointer"
                  style={{ paddingBottom: "2rem", objectFit: "cover" }}
                  width={150}
                  src={
                    uniquememberdetails?.mProfilePicture?.Location
                      ? uniquememberdetails?.mProfilePicture?.Location
                      : require("../../../assets/images/blank2.png")
                  }
                />
                <p className="profile2" style={{ textAlign: "center" }}>
                  Member since{" "}
                  {moment(`${uniquememberdetails?.createdAt}`).format("LL")}
                </p>
                <p className="profile3">
                  {uniquememberdetails?.mfirstName}{" "}
                  {uniquememberdetails?.mlastName}
                </p>
                <p className="profile3"> {uniquememberdetails?.memail}</p>
                <div className="ersfrew">
                  <FaFacebook /> <FaTwitter /> <FaInstagram /> <FaLinkedin />
                </div>
              </div>
            </Col>

            <Col sm={5}>
              <div>
                <p className="formtext">General Information</p>
              </div>

              <Table hover className="tabwi">
                <tbody>
                  <tr>
                    <td className="profile1">Full Name</td>
                    <td style={{ fontSize: "1em" }}>{fullname}</td>
                  </tr>
                  <tr>
                    <td className="profile1">Middle Name</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.middleName}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1" style={{ width: "30%" }}>
                      Preferred Name
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.preferredName}
                    </td>
                  </tr>

                  <tr>
                    <td className="profile1">Gender</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mgender}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Account Status</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.accountStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Member Status</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mstatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Service Area</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.moffice}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Birthday</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mBirthdayCont &&
                      uniquememberdetails.mBirthdayCont.trim() !== "undefined"
                        ? uniquememberdetails.mBirthdayCont
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1" style={{ width: "30%" }}>
                      Wedding Anniversary
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mAnniversaryCont &&
                      !uniquememberdetails.mAnniversaryCont.includes(
                        "undefined"
                      )
                        ? uniquememberdetails.mAnniversaryCont
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Marital Status</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mmarital}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Address</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.maddress}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Address 2</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.maddress2}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">City</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mcity}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">State</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mstate}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Postal Code</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mpostal}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Country</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.country}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Notes</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mnotes}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Additional Notes</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mnotes2}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div>
                <p className="formtext">Contact Information</p>
              </div>

              <Table hover className="tabwi">
                <tbody>
                  <tr>
                    <td className="profile1">Email Address</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.memail}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Work Email Address</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mworkEmail}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Home Phone</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mhomePhone}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Mobile Phone</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mmobilePhone}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Work Phone</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mworkPhone}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Other Phone Number</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.otherPhoneNumber}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div>
                <p className="formtext">Education and Work</p>
              </div>

              <Table hover className="tabwi mb-5">
                <tbody>
                  <tr>
                    <td className="profile1">School</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mschool}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Grade</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.mgrade}
                    </td>
                  </tr>
                  <tr>
                    <td className="profile1">Employer</td>
                    <td style={{ fontSize: "1em" }}>
                      {uniquememberdetails?.memployer}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col sm={4}>
              <div>
                <div>
                  <p className="formtext">Family</p>
                </div>

                <Table hover className="tabwi">
                  {isFamilyLoading ? ( // Strictly display loading only if it's loading
                    <tbody>
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          Loading...
                        </td>
                      </tr>
                    </tbody>
                  ) : familyValue.length === 0 ? ( // Once loading is done, show no family members if empty
                    <tbody>
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No family members found.
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    // Finally, if there are family members, display them
                    familyValue.map((n, idx) => (
                      <tbody key={n._id}>
                        <tr>
                          <td className="profile1" style={{ fontSize: "1em" }}>
                            {idx + 1}
                          </td>
                          <td style={{ fontSize: "1em" }}>
                            <a
                              href={`/auth/peopleview/${n._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                              }}
                              className="pointer"
                            >
                              {n?.mfirstName} {n?.mlastName}
                            </a>
                          </td>
                          <td style={{ fontSize: "1em" }}>{n?.familyRole}</td>
                        </tr>
                      </tbody>
                    ))
                  )}
                </Table>
              </div>

              {followups > 0 && (
                <>
                  <div>
                    <p className="formtext">Follow Up</p>
                  </div>
                  <Table hover className="tabwi">
                    <thead>
                      <tr className="profile1">
                        <th>Assigned To</th>
                        <th>What To Do</th>
                        <th>Target</th>

                        <th> Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {followups?.map((assignment, index) => (
                        <tr key={index} className="eweddddd">
                          <td>{assignment.followUpAssignTo}</td>
                          <td>{assignment.followMedia}</td>

                          <td>
                            {moment(assignment.followupDate).format(
                              "MMM D, YYYY"
                            )}
                          </td>
                          <td>
                            {assignment?.followReason?.map((reason, index) => (
                              <p key={index}>{reason}</p>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {uniquememberdetails?.tagsName?.length === 0 ||
              uniquememberdetails?.tagsName === null ? (
                ""
              ) : (
                <div className="pb-3">
                  <div>
                    <p className="formtext">Tags</p>
                  </div>

                  <div
                    className="flex flex-row gap-4 py-2 px-2"
                    style={{ backgroundColor: "white" }}
                  >
                    {uniquememberdetails?.tagsName?.map((assignment, index) => (
                      <tr key={index} className="eweddddd">
                        <td>{assignment}</td>
                      </tr>
                    ))}
                  </div>
                </div>
              )}

              {/* {uniquememberdetails?.mnotes?.length > 0 && (
                <div className="pb-3">
                  <div>
                    <p className="formtext">Additional Note</p>
                  </div>

                  <div
                    className="flex flex-row gap-4 px-2"
                    style={{ backgroundColor: "white" }}
                  >
                    {uniquememberdetails && uniquememberdetails?.mnotes2}
                  </div>
                </div>
              )} */}

              {combinedFields?.length === 0 ? (
                ""
              ) : (
                <>
                  <div className="custom-fields-header">
                    <p className="formtext">Custom Fields</p>
                  </div>
                  <table style={{ backgroundColor: "white" }}>
                    <tbody>
                      {combinedFields.flatMap((assignment) =>
                        assignment.fields.map((field, fieldIndex) => (
                          <tr
                            key={`${assignment.category}-${fieldIndex}`}
                            className="eweddddd"
                          >
                            <td
                              className="field-title"
                              style={{ paddingBottom: "10px" }}
                            >
                              <strong>{field.fieldName}</strong>
                            </td>
                            <td
                              className="field-response"
                              style={{ paddingBottom: "10px" }}
                            >
                              {Array.isArray(field.response)
                                ? field.response.length > 0
                                  ? field.response.join(", ") // Join array values into a comma-separated string
                                  : "N/A"
                                : field.response || "N/A"}{" "}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </Col>
          </>
        </Row>
        <FollowUp
          show={show}
          handleClose={handleClose}
          setShow={setShow}
          handleSubmit={handleSubmit}
          uniquememberdetails={uniquememberdetails}
          setting={setting}
          followName={followName}
          setFollowName={setFollowName}
          followDesc={followDesc}
          setFollowDesc={setFollowDesc}
          followDate={followDate}
          setFollowDate={setFollowDate}
          followHow={followHow}
          setFollowHome={setFollowHow}
          followComplete={followComplete}
          setFollowComplete={setFollowComplete}
          handleRadioChange={handleRadioChange}
          checkField={checkField}
          setCheckField={setCheckField}
          followReason={followReason}
          setFollowReason={setFollowReason}
          handleCheckboxChange={handleCheckboxChange}
          followNote={followNote}
          setFollowNote={setFollowNote}
        />
      </Container>
    </>
  );
};

export default PeopleView;
