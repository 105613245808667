import React from "react";
import { Button } from "react-bootstrap";
import "./sharedbutton.css";

import { useNavigate } from "react-router-dom";

const SharedButton = ({ title, style, link, ...rest }) => {
  const navigate = useNavigate();
  const navigateToPage = () => {
    navigate(link);
  };
  return (
    <Button {...rest} onClick={navigateToPage} className={style}>
      {title}
    </Button>
  );
};

export default SharedButton;
