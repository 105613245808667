import { useAppContext } from "../../context/appContext";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import { currencyFormatter } from "../../utils/helpers";
import "./singlecoursejumbotron.css";
import Loading from "../Loading/Loading";

const SingleCourseJumbotron = ({
  course,
  handlePaidEnrollment,
  handleFreeEnrollment,
  enrolled,
  loading,
}) => {
  const { user, logoutUser } = useAppContext();
  const [enrolledStatus, setEnrolledStatus] = useState(false);
  const { name, description, updatedAt, price, paid } = course;
  const [coursex, setCoursex] = useState(course.lessons);
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { slug } = useParams();

  const fetchCourse = async () => {
    let { data } = await authFetch.get(`/learning/course/${slug}`);
    setCoursex(data);
  };

  useEffect(() => {
    if (slug) fetchCourse();
  }, [slug]);

  useEffect(() => {
    const enrolledState = user.courses && user.courses.includes(course._id);

    setEnrolledStatus(enrolledState);
  }, [user, course]);

  const handleGoToCourse = () => {
    if (coursex && coursex._id) {
      navigate(`/auth/singlecourseview/${coursex?.slug}`);
    }

    //navigate(`/auth/singlecourseview/${enrolled?.course?.slug}`);
  };

  return (
    <>
      <Row className="singleCourseHeader">
        <div className="secondlayer">
          <div>
            <p className="singleCourseName">{name}</p>
            {/* description */}
            <p className="singleCourseDescription">
              {description && description.substring(0, 500)}..
            </p>

            <p className="singleCourseDate">
              Last updated {new Date(updatedAt).toLocaleDateString()}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="mian2">
              <div className="pklkm2">
                <img
                  src={
                    coursex?.image?.Location ? coursex?.image?.Location : "name"
                  }
                  alt={name}
                  className="img img-fluid"
                />
              </div>
            </div>

            {enrolledStatus ? (
              <>
                <Button
                  className="rgrhj"
                  style={{
                    fontWeight: "700",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                  onClick={handleGoToCourse}
                >
                  Go to Course
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="rgrhj"
                  style={{
                    fontWeight: "700",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                  onClick={paid ? handlePaidEnrollment : handleFreeEnrollment}
                >
                  Enroll
                </Button>
              </>
            )}
            {/* {loading ? (
              <div className="d-flex justify-content-center mt-3">
                <Loading />
              </div>
            ) : (
              <Button
                className="mb-3 mt-3"
                type="danger"
                disabled={loading}
                onClick={paid ? handlePaidEnrollment : handleFreeEnrollment}
              >
                {user
                  ? enrolled.status
                    ? "Go to course"
                    : "Enroll"
                  : "Login to enroll"}
              </Button>
            )} */}
          </div>
        </div>
      </Row>
    </>
  );
};

export default SingleCourseJumbotron;
