import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";
import { FaTimes } from "react-icons/fa";

const EditContent = ({ show, setShow, handleReload, data }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceDescription, setResourceDescription] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [resourceLink, setResourceLink] = useState("");
  const [resourceFile, setResourceFile] = useState(null);
  const [resourceTags, setResourceTags] = useState("");
  const [resourceCategory, setReourceCategory] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Content");
  const [uploadLink, setUploadLink] = useState(false);

  const [error, setError] = useState("");
  const [resourceName, setResourceName] = useState("");

  const handleResourceUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setResourceFile(file); // Assuming you have a state setter for the resource file
      if (!file) {
        // toast("No file selected");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const resourceData = new FormData();
      resourceData.append("resource", file); // 'resource' should match the field name expected by your backend

      try {
        const { data } = await authFetch.patch(
          `/children/resource/uploadfileupdate/${resourceId}`, // Adjust the API endpoint as needed
          resourceData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );
        console.log("Uploaded resource data:", data);
        setResourceFile(data); // Assuming you want to store the uploaded resource data in the state
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleResourceSubmit = async (e) => {
    e.preventDefault();

    const [trimmedName, trimmedDescription] = trimInputs([
      resourceTitle,
      resourceDescription,
    ]);

    setError("");

    if (!validateNotEmpty(trimmedName)) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please provide all required information to proceed."
        />
      );

      return;
    }

    // Validate name length
    if (!validateLength(trimmedName, NAME_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Resource Name cannot exceed ${NAME_LENGTH_LIMIT} characters.`}
        />
      );
      return;
    }

    // Validate description length
    if (!validateLength(trimmedDescription, DESCRIPTION_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} characters.`}
        />
      );
      return;
    }

    const resourceData = {
      title: trimmedName,
      description: trimmedDescription,
      link: resourceLink,
      churchName: user.churchName,
      url: resourceFile, // Assuming you have a state for the file like `resourceFile`
      type: resourceType, // Assuming you have a way to set the type of the resource
      tags: resourceTags,
      subject: resourceCategory,
      fileName: resourceName,
    };

    try {
      const response = await authFetch.patch(
        `/children/resource/updateallcontent/${resourceId}`,
        resourceData
      );
      if (response.status === 200 || response.status === 201) {
        handleReload();
        setResourceTitle("");
        setResourceDescription("");
        setResourceFile(null);
        setResourceLink("");
        setResourceType("");
        setProgress(0);
        setUploading(false);
        setResourceName("");
        setUploadButtonText("Upload another resource");
        handleClose();
        toast(
          <CustomToastContent title="Success" message="Resource Updated!" />
        );
      }
    } catch (err) {
      console.error(err);
      toast(
        <CustomToastContent
          title="Error"
          message="Failed to update resource. Please try again."
        />
      );

      setUploading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setResourceTitle(data.title);
      setResourceDescription(data.description);
      setResourceType(data.type);
      setResourceLink(data.link);
      setReourceCategory(data.subject);
      setResourceTags(data.tags);
      setResourceFile(data.url);
      setResourceName(data.fileName);
      setResourceId(data._id);
    }
  }, [data]);

  const handleDeleteResource = async (e, resourceId) => {
    e.preventDefault(); // Prevent default action (not necessary if the trigger is not a submit button or link)
    try {
      const data = await authFetch.delete(
        `/children/removeresourcefile/${resourceId}`
      );

      console.log(data);
      if (data.status === 200) handleReload(); // Refresh the list or state after deletion
    } catch (err) {
      console.error(err);
      setError("Failed to delete resource. Please try again."); // Assume setError is a function to handle error messages in the UI
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>Resource Title</Form.Label>
          <Form.Control
            type="text"
            value={resourceTitle}
            onChange={(e) => setResourceTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>Resource Category</Form.Label>
          <Form.Control
            type="text"
            value={resourceCategory}
            onChange={(e) => setReourceCategory(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="resourceDescription">
          <Form.Label>Resource Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={resourceDescription}
            onChange={(e) => setResourceDescription(e.target.value)}
            required
          />
        </Form.Group>

        {!resourceFile && (
          <>
            <Form.Group className="mb-3" controlId="resourceType">
              <Form.Label>Resource Type</Form.Label>
              <Form.Select
                value={resourceType}
                onChange={(e) => setResourceType(e.target.value)}
                required
              >
                <option>Select an option</option>
                <option value="audio">Audio</option>
                <option value="book">Book</option>
                <option value="video">Video</option>
                <option value="document">Document</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="useLink">
              <Form.Check
                type="checkbox"
                label="Use link instead of uploading"
                checked={uploadLink}
                onChange={(e) => setUploadLink(e.target.checked)}
              />
            </Form.Group>
            {uploadLink ? (
              <Form.Group className="mb-3" controlId="resourceLink">
                <Form.Label>Resource Link</Form.Label>
                <Form.Control
                  type="text"
                  value={resourceLink}
                  onChange={(e) => setResourceLink(e.target.value)}
                  placeholder="https://example.com/resource"
                  required
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3" controlId="uploadResource">
                <Form.Label>Upload Resource</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleResourceUpload}
                  accept="audio/*, video/*, application/pdf" // Adapt based on your allowed types
                />
              </Form.Group>
            )}
          </>
        )}

        {resourceFile && (
          <div
            className="flex justify-between p-3 mb-3"
            style={{
              backgroundColor: "var(--primary-color)",
              marginBottom: "0px",
            }}
          >
            <p
              style={{
                marginBottom: "0px",
                color: "white",
              }}
            >
              {resourceName}
            </p>
            <FaTimes
              onClick={(e) => handleDeleteResource(e, resourceId)}
              style={{ cursor: "pointer", color: "white" }} // Adding cursor pointer for better user experience
            />
          </div>
        )}
        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            type="text"
            value={resourceTags}
            onChange={(e) => setResourceTags(e.target.value)}
          />
        </Form.Group>
        <p className="smalltext">Seperate tags using comma</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleResourceSubmit}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContent;
