import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Tracker from "./Asset/Tracker";
import Step1 from "./Asset/Step1";
import Step2 from "./Asset/Step2";
import Step3 from "./Asset/Step3";
import Step4 from "./Asset/Step4";
import Step5 from "./Asset/Step5";
import Step6 from "./Asset/Step6";
import Step7 from "./Asset/Step7";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const OnboardingWizard = ({
  showWizard,
  setShowWizard,
  setPercentageCompleted,
  changeWizardOnboarding,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, updateUser, logoutUser } = useAppContext();

  const calculateTotalCompletedSteps = (user) => {
    return [
      user?.step1Completed,
      user?.step2Completed,
      user?.step3Completed,
      user?.step4Completed,
      user?.step5Completed,
      user?.step6Completed,
      // user?.step7Completed,
    ].filter(Boolean).length;
  };

  const calculateCurrentStep = (user) => {
    const steps = [
      user?.step1Completed,
      user?.step2Completed,
      user?.step3Completed,
      user?.step4Completed,
      user?.step5Completed,
      user?.step6Completed,
      //  user?.step7Completed,
    ];
    return steps.lastIndexOf(true) + 2; // +2 to move to the next step or start from step 1 if none are completed
  };

  const initialTotalCompletedSteps = calculateTotalCompletedSteps(user);
  const initialCurrentStep = calculateCurrentStep(user);

  const [currentStep, setCurrentStep] = useState(initialCurrentStep);

  const [formData, setFormData] = useState({
    firstName: user?.mfirstName || "",
    lastName: user?.mlastName || "",
    churchName: user?.churchName || "",
    website: user?.website || "",
    timeZone: user?.timeZone || "",
    timeFormat: user?.timeFormat || "",
    country: user?.country || "",
    currency: user?.currency || "",
    streetAddress: user?.maddress || "",
    aptUnitBox: user?.aptUnitBox || "",
    city: user?.mcity || "",
    state: user?.mstate || "",
    zipCode: user?.mpostal || "",
    churchLogo: user?.churchLogo || "",
    primaryColor: user?.primaryColor || "",
    secondaryColor: user?.secondaryColor || "",
    accentColor: user?.accentColor || "",
    primaryPhoneNumber: user?.primaryPhoneNumber || "",
    step1Completed: user?.step1Completed || false,
    step2Completed: user?.step2Completed || false,
    step3Completed: user?.step3Completed || false,
    step4Completed: user?.step4Completed || false,
    step5Completed: user?.step5Completed || false,
    step6Completed: user?.step6Completed || false,
    //step7Completed: user?.step7Completed || false,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      firstName: user?.mfirstName || "",
      lastName: user?.mlastName || "",
      churchName: user?.churchName || "",
      website: user?.website || "",
      timeZone: user?.timeZone || "",
      timeFormat: user?.timeFormat || "",
      country: user?.country || "",
      currency: user?.currency || "",
      streetAddress: user?.maddress || "",
      aptUnitBox: user?.aptUnitBox || "",
      city: user?.mcity || "",
      state: user?.mstate || "",
      zipCode: user?.mpostal || "",
      churchLogo: user?.churchLogo || "",
      primaryColor: user?.primaryColor || "",
      secondaryColor: user?.secondaryColor || "",
      accentColor: user?.accentColor || "",
      primaryPhoneNumber: user?.primaryPhoneNumber || "",
    }));
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCompletionChange = (step, isCompleted) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`step${step}Completed`]: isCompleted,
    }));
  };

  const handleUserData = async () => {
    const percentageCompleted = Math.round(calculatePercentageCompleted());

    const currentUser = {
      mfirstName: formData.firstName,
      mlastName: formData.lastName,
      churchName: formData.churchName,
      website: formData.website,
      timeZone: formData.timeZone,
      timeFormat: formData.timeFormat,
      country: formData.country,
      currency: formData.currency,
      maddresss: formData.streetAddress,
      aptUnitBox: formData.aptUnitBox,
      mcity: formData.city,
      mstate: formData.state,
      mpostal: formData.zipCode,
      churchLogo: formData.churchLogo,
      primaryColor: formData.primaryColor,
      secondaryColor: formData.secondaryColor,
      accentColor: formData.accentColor,
      primaryPhoneNumber: formData.primaryPhoneNumber,
      step1Completed: formData.step1Completed,
      step2Completed: formData.step2Completed,
      step3Completed: formData.step3Completed,
      step4Completed: formData.step4Completed,
      step5Completed: formData.step5Completed,
      step6Completed: formData.step6Completed,
      // step7Completed: formData.step7Completed,
      percentageCompleted: percentageCompleted,
    };

    updateUser({ currentUser });
  };

  const handleFinishOnboarding = async () => {
    const percentageCompleted = Math.round(calculatePercentageCompleted());
    try {
      const response = await authFetch.patch(
        `/members/onboarding-completion/${user._id}`,
        { percentageCompleted }
      );
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="You have successfully completed the onboarding process."
          />
        );
        setShowWizard(false);
        changeWizardOnboarding();
      } else {
        console.error("Error saving progress");
      }
    } catch (error) {
      console.error("Error saving progress", error);
    }
  };

  const nextStep = async () => {
    if (currentStep === 6) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        step7Completed: true,
      }));
    }

    await handleUserData();

    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowWizard(false);
      handleFinishOnboarding();
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const calculatePercentageCompleted = () => {
    const completedSteps = Object.keys(formData).filter(
      (key) =>
        key.startsWith("step") && key.endsWith("Completed") && formData[key]
    ).length;
    return (completedSteps / 6) * 100; // Total steps contributing to percentage is 7
  };

  const handleCompleteLater = async () => {
    const percentageCompleted = Math.round(calculatePercentageCompleted());

    const data = {
      currentStep,
      percentageCompleted,
      step1Completed: formData.step1Completed,
      step2Completed: formData.step2Completed,
      step3Completed: formData.step3Completed,
      step4Completed: formData.step4Completed,
      step5Completed: formData.step5Completed,
      step6Completed: formData.step6Completed,
      // step7Completed: formData.step7Completed,
    };

    try {
      const response = await authFetch.patch(
        `/members/onboarding-progress/${user._id}`,
        {
          data,
        }
      );
      if (response.status === 200 || response.status === 201) {
        setPercentageCompleted(percentageCompleted); // Update the percentageCompleted state
        setShowWizard(false);
      } else {
        console.error("Error saving progress");
      }
    } catch (error) {
      console.error("Error saving progress", error);
      setShowWizard(false); // Ensure the modal is closed even if an error occurs
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(1, isCompleted)
            }
          />
        );
      case 2:
        return (
          <Step2
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(2, isCompleted)
            }
          />
        );
      case 3:
        return (
          <Step3
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(3, isCompleted)
            }
          />
        );
      case 4:
        return (
          <Step4
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(4, isCompleted)
            }
          />
        );
      case 5:
        return (
          <Step5
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(5, isCompleted)
            }
          />
        );
      case 6:
        return (
          <Step6
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(6, isCompleted)
            }
          />
        );
      case 7:
        return (
          <Step7
            formData={formData}
            handleChange={handleChange}
            handleCompletionChange={(isCompleted) =>
              handleCompletionChange(7, isCompleted)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      show={showWizard}
      onHide={() => setShowWizard(false)}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Onboarding Wizard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tracker currentStep={currentStep} totalSteps={7} />
        {renderStep()}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        >
          {currentStep > 1 && (
            <button className="startbnnnddpx" onClick={prevStep}>
              Back
            </button>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            {currentStep < 7 && (
              <button
                className="startbnnnddpnext232"
                onClick={handleCompleteLater}
              >
                Complete Later
              </button>
            )}
            {currentStep < 7 ? (
              <button
                variant="primary"
                onClick={nextStep}
                className="startbnnnddpnext"
              >
                Next
              </button>
            ) : (
              <button
                className="startbnnnddpnext232c"
                onClick={handleFinishOnboarding}
              >
                Finish
              </button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingWizard;
