import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";

const CheckoutForm = ({ values }) => {
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState("");
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setIsPaymentSuccessful(false);
    }

    //Create payment intent on the server

    const {
      error: backend,
      status,
      clientSecret,
    } = await fetch("/api/v1/payment/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethod.id,
        amount: 2000,
      }),
    }).then((res) => res.json());

    if (backend) {
      return;
    }

    if (status === "succeeded") {
      setIsPaymentSuccessful(true);
    } else {
      setIsPaymentSuccessful(false);
    }

    const { error: stripeError } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

    if (stripeError) {
      return;
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div>
      {isPaymentSuccessful ? (
        <p>Payment Successful!</p>
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <AddressElement options={{ mode: "billing" }} />
          <label>ss</label>
          <CardElement id="card-element" options={cardStyle} />
          <button
            type="submit"
            disabled={processing || succeeded}
            className="buttsign"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </form>
      )}
    </div>
  );
};

export default CheckoutForm;
