import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
const FormResponses = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const { logoutUser, user } = useAppContext();
  const [form, setForm] = useState([]);
  const copiedArray = form?.flatMap((obj) =>
    obj.forms.map((formObj) => ({
      Created_Date: moment(obj.updatedAt).format("LL"),
      First_Name: obj.memberFirstName,
      Last_Name: obj.memberLastName,
      // Uncomment and adjust if you need to include Giver_Name using data from obj.paymentDetails
      // Giver_Name: obj.paymentDetails.mfirstName + " " + obj.paymentDetails.mlastName,
      Question: formObj.question,
      Response: formObj.response,
      Type: formObj.type,
    }))
  );

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms/formbychurch/${id}`);

    setForm(data);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">Responses</p>
        </Col>
      </Row>

      <Row>
        <div>
          {form && form.length > 0 ? (
            <Row className="tabw">
              <div className="flex justify-between flex-row mt-5 mb-5">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "100%",
                    color: "var(--primary-color)",
                    marginBottom: "0px",
                  }}
                >
                  {form.length ? (
                    <p>
                      {form.length} record{form.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <button className="jiluil">
                  <CSVLink
                    data={copiedArray}
                    filename="FormResponses-churchease.csv"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Download
                  </CSVLink>
                </button>
              </div>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th>PLAN NAME</th>

                    <th style={{ textAlign: "end" }}>ACTIONS</th>
                  </tr>
                </thead>
                {form.map((plan, index) => {
                  return (
                    <tr key={index} className="trdata">
                      <td>
                        <Link to={`/auth/peopleview/${plan.memberId}`}>
                          {plan.memberFirstName} {plan.memberLastName}
                        </Link>
                      </td>

                      <td className="rightco">
                        <div className="flex items-center gap-3">
                          {
                            <Link
                              to={`/auth/reviewform/${plan._id}`}
                              className="flex gap-3"
                              style={{ textDecoration: "none" }}
                            >
                              <button className="jiluil">
                                Individual Response
                              </button>
                            </Link>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default FormResponses;
