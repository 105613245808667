import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Form from "react-bootstrap/Form";
import "./checkoutform.css";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import CustomAlert from "../CustomAlert/CustomAlert";

import { currencyFormatter } from "../../utils/helpers";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#ffffff",
      fontFamily: '"popins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1em",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "40px", // Adjust line height to increase the element's height
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid red", // Add a border to the element
      borderRadius: "4px", // Optional: if you want rounded corners
      padding: "0.375rem 0.75rem",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutFormTrial = ({ price, selectedFeatures, size }) => {
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, user } = useAppContext();
  const stripe = useStripe();
  const elements = useElements();
  const [priceId, setPriceId] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [currency, setCurrency] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js or Elements have not loaded.");
      setMessage("Payment cannot be processed at this time.");
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      console.error("CardNumberElement was not found.");
      setMessage("Payment cannot be processed at this time.");
      return;
    }

    setIsLoading(true);
    setMessage(""); // Clear any previous messages

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error) {
        console.error("Error in creating giving method:", error);
        setMessage(error.message);
        return;
      }

      // Assuming `authFetch` is set up for authorized requests to your backend
      const response = await authFetch.post("/payment/checkouttrial", {
        paymentMethodId: paymentMethod.id,
        price: price, // Dynamic price in the smallest currency unit (e.g., cents)
        currency: "usd", // Currency code (e.g., 'usd')
        interval: "month", // Subscription duration (e.g., 'month', 'year')
        customerId: user.customerId,
        selectedFeatures: selectedFeatures,
        size: size,
      });

      //const responseData = await response.json(); // Assuming the response is JSON

      if (response.status !== 200) {
        throw new Error("Subscription creation failed.");
      }
      window.location.href = "/auth/dashboard";
      // Subscription created successfully

      // Redirect on success
    } catch (error) {
      console.error("Error in subscription process:", error);
      setMessage(
        error.message || "An error occurred during payment processing."
      );
    } finally {
      setIsLoading(false); // Reset loading state regardless of outcome
    }
  };

  return (
    <div className="flex justify-center rre332">
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <div className="card-element-container">
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </Form.Group>

        <div className="flex justify-center">
          <button
            disabled={isLoading || !stripe || !elements}
            type="submit"
            className="jiluil inline-block px-5 py-2" // Added padding and set inline-block
            style={{ width: "100%" }} // Ensures the button takes full width
          >
            <span className="flex flex-row items-center justify-center gap-3">
              {isLoading ? (
                <>
                  <Loading /> Loading...
                </>
              ) : (
                <span id="button-text">
                  Pay{" "}
                  {currencyFormatter({
                    amount: price, // Assuming 'amount' is directly under 'paymentDetails'
                    currency: "USD", // Assuming 'currency' is directly under 'paymentDetails'
                  })}
                </span>
              )}
            </span>
          </button>

          {message && (
            <CustomAlert
              alertType="danger"
              alerttext={message && <div id="payment-message">{message}</div>}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default CheckoutFormTrial;
