import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./people.css";

const CreatePeopleLeft = ({
  agex,
  showAge,
  familyx,
  showFamily,
  gradex,
  showGrade,
  schoolx,
  showSchool,
  employerx,
  showEmployer,
  showWork,
  showMobile,
  showAnniversary,
  anniversaryx,
  showNotes,
  showAdditionalNotes,
  notex,
  additionalNotesx,
  showFacebook,
  showTwitter,
  showInstagram,
  showLinkedin,
  showHome,
  showMiddleName,
  showOtherPhone,
  showWorkEmail,

  middleNamex,

  workPhonex,
  homePhonex,
  otherPhonex,
  workEmailx,
  facebookx,
  twitterx,
  instagramx,
  linkedinx,
}) => {
  return (
    <>
      <p className="hedss">Create Member</p>
      <Col className="dwdq">
        <div>
          <p className="padt2">General Information</p>

          <Form.Check
            label="Middle Name"
            aria-label="option 50"
            checked={middleNamex} // Dynamically checked based on visibilitySettings
            onChange={showMiddleName} // onChange is better for checkboxes
            className="wedwe"
          />

          <Form.Check
            label="Birth Date"
            aria-label="option 3"
            checked={agex} // Dynamically checked
            onChange={showAge}
            className="wedwe"
          />
          <Form.Check
            label="Anniversary Date"
            aria-label="option 3"
            checked={anniversaryx} // Dynamically checked
            onChange={showAnniversary}
            className="wedwe"
          />

          <Form.Check
            label="Note"
            aria-label="option 2"
            checked={notex} // Dynamically checked
            onChange={showNotes}
            className="wedwe"
          />
          <Form.Check
            label="Additional Notes"
            aria-label="option 3"
            checked={additionalNotesx} // Dynamically checked
            onChange={showAdditionalNotes}
            className="wedwe"
          />
          <Form.Check
            label="Family"
            aria-label="option 2"
            checked={familyx} // Dynamically checked
            onChange={showFamily}
            className="wedwe"
          />
        </div>

        <div className="padt">
          <p className="padt2">Contact</p>

          <Form.Check
            label="Work Phone"
            aria-label="option 7"
            checked={workPhonex} // Dynamically checked
            onChange={showWork}
            className="wedwe"
          />
          <Form.Check
            label="Home Phone"
            aria-label="option 13"
            checked={homePhonex} // Dynamically checked
            onChange={showHome}
            className="wedwe"
          />
          <Form.Check
            label="Other Phone Number"
            aria-label="option 51"
            checked={otherPhonex} // Dynamically checked
            onChange={showOtherPhone}
            className="wedwe"
          />
          <Form.Check
            label="Work Email"
            aria-label="option 52"
            checked={workEmailx} // Dynamically checked
            onChange={showWorkEmail}
            className="wedwe"
          />
        </div>

        <div className="padt">
          <p className="padt2">Social Media</p>

          <Form.Check
            label="Facebook"
            aria-label="option 9"
            checked={facebookx} // Dynamically checked
            onChange={showFacebook}
            className="wedwe"
          />
          <Form.Check
            label="Twitter"
            aria-label="option 10"
            checked={twitterx} // Dynamically checked
            onChange={showTwitter}
            className="wedwe"
          />
          <Form.Check
            label="Instagram"
            aria-label="option 11"
            checked={instagramx} // Dynamically checked
            onChange={showInstagram}
            className="wedwe"
          />
          <Form.Check
            label="LinkedIn"
            aria-label="option 12"
            checked={linkedinx} // Dynamically checked
            onChange={showLinkedin}
            className="wedwe"
          />
        </div>

        <div className="padt">
          <p className="padt2">Education & Work</p>
          <Form.Check
            label="School"
            aria-label="option 6"
            checked={schoolx} // Dynamically checked
            onChange={showSchool}
            className="wedwe"
          />
          <Form.Check
            label="Grade"
            aria-label="option 7"
            checked={gradex} // Dynamically checked
            onChange={showGrade}
            className="wedwe"
          />
          <Form.Check
            label="Employer"
            aria-label="option 8"
            checked={employerx} // Dynamically checked
            onChange={showEmployer}
            className="wedwe"
          />
        </div>
      </Col>
    </>
  );
};

export default CreatePeopleLeft;
