import React from "react";
import "./contactus.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bac from "../../assets/images/bac.png";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import caltwo from "../../assets/newi/caltwo.png";
const ContactUs = () => {
  return (
    <Row className="contactus">
      <Col xs={12} sm={12} md={12} lg={6}>
        <div className="rer">
          <p className="contactus1">Empowering Ministry Through Technology</p>
          <p className="contactus1a">
            Equipping churches with advanced technology solutions, we aim to
            simplify administrative tasks for church staff, allowing them to
            focus on their essential ministry work with ease.
          </p>

          {/* <Link to="/signup">
              <Button className="yjyj1">Get Started</Button>
            </Link> */}
        </div>
      </Col>
      <Col className="contactus2" xs={12} sm={12} md={12} lg={6}>
        <img src={caltwo} className="imgnewxxx" alt="Image" />
      </Col>
    </Row>
  );
};

export default ContactUs;
