import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Alert from "../Alert/Alert";
import { useAppContext } from "../../context/appContext";
import { Form } from "react-bootstrap";
const EditTagModal = ({
  editTagModal,
  toggleEditTag,
  name,
  id,
  folder_id,
  campusName,
}) => {
  const { editTag, getRootFolder, getFolder, user, showAlert } =
    useAppContext();
  const [tagName, setTagName] = useState("");
  const [campusNamex, setCampusNamex] = useState("");

  const handleUpdate = async () => {
    await editTag(id, tagName, campusNamex);
    setTagName("");
    setCampusNamex("");
    toggleEditTag();
    if (folder_id) {
      await getFolder(folder_id);
    } else {
      await getRootFolder();
    }
  };
  const [campuses, setCampus] = useState([]);
  useEffect(() => {
    if (user && user.churchCampuses.length > 0) {
      setCampus(user.churchCampuses);
    }
  }, [user]);

  useEffect(() => {
    setTagName(name);
  }, [name]);

  useEffect(() => {
    setCampusNamex(campusName);
  }, [campusName]);
  return (
    <Modal isOpen={editTagModal} toggle={toggleEditTag}>
      {showAlert && <Alert />}
      <ModalHeader toggle={toggleEditTag}>Edit Tag</ModalHeader>
      <ModalBody className="modal-wrapper__body">
        <Row>
          <div>
            <Label for="tag-name" className="modal-form-group-label">
              Ministry Name
            </Label>

            <Input
              type="text"
              name="name"
              id="tag-name"
              placeholder="Ministry Name"
              required
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label for="tag-name" className="modal-form-group-label">
              Campus
            </Label>
            <Form.Select
              aria-label="Select Campus"
              value={campusNamex}
              onChange={(e) => setCampusNamex(e.target.value)}
            >
              {/* Default non-selectable option */}
              <option value="">Select Campus</option>

              {/* Dynamic options generated from the campus array */}
              {campuses.map((campus) => (
                <option key={campus._id} value={campus._id}>
                  {campus.campusName}
                </option>
              ))}
            </Form.Select>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="jiluil3" onClick={toggleEditTag}>
          Cancel
        </button>
        <button onClick={handleUpdate} className="jiluil">
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTagModal;
