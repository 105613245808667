import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaMoneyBill, FaPen } from "react-icons/fa";
import m1 from "../../assets/newi/m1.png";
import m2 from "../../assets/newi/m2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductLearningCenter = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>Learning Center | Churchease - Enhance Church Education</title>
        <meta
          name="description"
          content="Easily manage and enhance your educational offerings with Churchease's Learning Center. Design engaging curricula, streamline content delivery, and monitor educational outcomes for impactful learning experiences."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/learning-center"
        />
        <meta
          property="og:title"
          content="Learning Center | Churchease - Enhance Church Education"
        />
        <meta
          property="og:description"
          content="Easily manage and enhance your educational offerings with Churchease's Learning Center. Design engaging curricula, streamline content delivery, and monitor educational outcomes for impactful learning experiences."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/learning-center"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#9D4072" }}>
            <div>
              <FaPen size={100} />
              <p className="headssc">Learning Center</p>
              <p className="headpp">
                Easily manage and enhance your educational offerings with our
                Learning Center. Design curricula that engage and educate,
                streamline content delivery, and monitor educational outcomes.
                Our intuitive platform ensures seamless updates and impactful
                learning experiences.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for church education
            </p>
            <p className="product13">
              Your comprehensive suite to craft, manage, and enhance educational
              experiences for all ages.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={m1} alt="Learning Center" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">COURSE CREATION</p>
              <p className="proddz1">
                Design and publish engaging educational courses tailored to your
                community's needs, with easy-to-use creation tools.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">LEARNING TRACKS</p>
              <p className="proddz1">
                Offer structured learning paths to guide learners through
                comprehensive spiritual and educational growth.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">QUIZZES AND ASSESSMENTS</p>
              <p className="proddz1">
                Incorporate quizzes and assessments to evaluate progress and
                reinforce learning objectives effectively.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">RESOURCE LIBRARY</p>
              <p className="proddz1">
                Provide learners with access to a rich library of resources,
                supporting a wide range of topics and interests.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">INTERACTIVE FORUMS</p>
              <p className="proddz1">
                Foster community learning and interaction through forums,
                allowing learners to discuss topics and share insights.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACCESS CONTROL</p>
              <p className="proddz1">
                Manage who accesses courses with controls that can assign
                learning materials to specific groups, individuals, or the
                entire church.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={m2} alt="Learning Center" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#9D4072" />
      <Footer />
    </>
  );
};

export default ProductLearningCenter;
