import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./foreveryone.css";
import { CiSettings, CiSatellite1, CiMoneyBill, CiBank } from "react-icons/ci";
import { Link } from "react-router-dom";
import {
  FaComments,
  FaFolder,
  FaPeopleArrows,
  FaPrayingHands,
} from "react-icons/fa";

const Pillars = () => {
  return (
    <>
      <Row className="deliverbg1234 container22">
        <div className="delivermainbgx2">
          <span style={{ fontWeight: "bold", color: "var(--primary-color)" }}>
            Everything
          </span>{" "}
          <span style={{ color: "var(--primary-color)" }}>you need for </span>
          <span style={{ fontWeight: "bold", color: "#FE7064" }}>
            church management
          </span>
        </div>

        <div className="delivermainbg2x2">
          Your All-Inclusive Solution to manage all Church Operations.
        </div>
      </Row>
      <Row className="everyone">
        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaComments className="sssssss" />
                <p className="everyone1a">Engage</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  Boost community engagement with Churchease, from member
                  management to dynamic groups and messaging.
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaPeopleArrows className="sssssss" />
                <p className="everyone1a">Give</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  Streamline giving with Churchease, making donations easy and
                  secure, and supporting your church’s mission.
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaFolder className="sssssss" />
                <p className="everyone1a">Care</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  With Churchease, ensure every member feels cared for, from
                  personalized follow-ups to engaging children's programs.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="everyone " style={{ paddingBottom: "6rem" }}>
        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaPrayingHands className="sssssss" />
                <p className="everyone1a">Fellowship</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  Foster a sense of community with Churchease by sharing prayers
                  and testimonies, strengthening spiritual bonds.
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaPrayingHands className="sssssss" />
                <p className="everyone1a">Manage</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  Simplify church operations with Churchease, from service
                  planning to event management and livestreaming.
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={2} className="pillma">
          <div className="flip-container">
            <div className="flipper">
              <div className="front everyone1">
                <FaPrayingHands className="sssssss" />
                <p className="everyone1a">Grow</p>
              </div>
              <div className="back everyone1">
                <p className="everyone1b">
                  Encourage spiritual growth with Churchease's Learning Center,
                  offering resources for learning and development.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Pillars;
