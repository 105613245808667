import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useAppContext } from "../../../context/appContext";
import Loading from "../../../components/Loading/Loading";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import "./standard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { currencyFormatter } from "../../../utils/helpers";
const StandardHome = () => {
  const {
    showStats,
    isLoading,
    getEvents,
    events,
    followups,
    user,
    logoutUser,
    schedule,
  } = useAppContext();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [prayc, setPrayc] = useState(0);
  const [givingByMembers, setGivingByMember] = useState(0);
  const [singleCheckin, setSingleCheckin] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    prayerCount();
    givingByMember();
  }, []);

  const prayerCount = async () => {
    try {
      const response = await authFetch.get("/prayers/prayercount");

      setPrayc(response.data);
    } catch (error) {
      console.error("Error fetching prayer count:", error);
    }
  };

  const givingByMember = async () => {
    try {
      const response = await authFetch.get(
        "/giving/give/totalgivingbymember/" + user._id
      );

      setGivingByMember(response.data.totalAmount);
    } catch (error) {
      console.error("Error fetching prayer count:", error);
    }
  };

  const getCheckinByUser = async (e) => {
    e?.preventDefault();

    try {
      const { data } = await authFetch.get(
        `/checkin/checkingbyuser/${user._id}`
      );
      if (data) {
        setSingleCheckin(data.lastCheckin);
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);

        if (error.response.status === 404) {
          // Handle 404 Not Found specifically

          setError("No check-in information found for this user");
        } else {
          // Handle other types of errors (e.g., 500 Internal Server Error)
          console.log("An error occurred:", error.response.statusText);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
        alert("Error: " + error.message);
      }
    }
  };

  useEffect(() => {
    getCheckinByUser();
  }, []);

  useEffect(() => {
    showStats();
    getEvents();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   memberbyMonth();
  //   RecentMembers();
  // }, []);

  const dataGreaterThanToday = events.filter((item) => {
    return moment(`${item.eventDay}`).isSameOrAfter(
      moment().startOf("day"),
      "day"
    );
  });

  const sortedData = dataGreaterThanToday?.sort((a, b) => {
    return moment(a.eventDay).diff(moment(b.eventDay));
  });

  const [courses, setCourses] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const fetchCourse = async () => {
    let { data } = await authFetch.get(`/learning/mylearning`);
    setCourses(data.length);
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  const showCourses = () => {
    navigate("/auth/mylearning");
  };

  const showGiving = () => {
    navigate("/auth/standardgiving");
  };

  const showVolunteer = () => {
    navigate("/auth/volunteerhome");
  };

  const showChecking = () => {
    navigate("/auth/standardcheckin");
  };

  const showFollowUp = () => {
    navigate("/auth/myfollowup");
  };

  useEffect(() => {
    const filtered = followups.filter(
      (item) =>
        item.followUpAssignTo === `${user.mfirstName} ${user.mlastName}` &&
        (item.completed === false || item.completed === undefined)
    );
    setFilteredData(filtered.length);
  }, [followups, user.mfirstName, user.mlastName]);

  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    getAnnouncement();
  }, []);

  const getAnnouncement = async () => {
    try {
      const { data } = await authFetch.get(`/announcement`);
      setAnnouncement(data);
    } catch (err) {
      console.error(err);
    }
  };
  const topAnnouncements = announcement.slice(0, 3);

  const gotoAnnouncements = () => {
    navigate("/auth/standardannouncements");
  };
  console.log("topAnnouncements", user);
  return (
    <>
      <Container fluid>
        <Row className="dashRow">
          {topAnnouncements.length > 0 ? (
            topAnnouncements.map((announcement, index) => (
              <Col key={index} sm={12} className="colsm">
                <div className="cardTit33">Announcements</div>
                <div className="dicy">
                  <div className="cardsrolldicy">
                    <div
                      key={index}
                      className="upcoming pb-1 cardCoke flex flex-column"
                      style={{
                        backgroundColor: announcement?.color,
                        color: "#fff",
                      }}
                    >
                      <div className="cardTixx">
                        <img
                          src={announcement?.annoucementImage?.Location}
                          alt="Announcement"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                        <div className="calsdd">
                          <p
                            style={{ marginBottom: "0px" }}
                            className="smalltext"
                          >
                            {moment(announcement?.createdAt).format(
                              "MMM D, YYYY"
                            )}
                          </p>
                          <p className="annosu">
                            {announcement?.annoucementName}
                          </p>
                          <p style={{ lineHeight: 2 }}>
                            {announcement?.announcementDescription?.substring(
                              0,
                              150
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <div className="anndif">
                <div className="cardTit33 ">
                  Stay tuned to this space for all the latest updates and
                  happenings at our church!
                </div>
              </div>
            </Col>
          )}

          {topAnnouncements.length > 0 && (
            <div className="colsmxxx">
              <button className="kkkk3sss" onClick={gotoAnnouncements}>
                View All Announcements
              </button>
            </div>
          )}
        </Row>

        <Row className="dashRow">
          <Col sm={4} className="colsm">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="diic2"
            >
              <div className="cardTit">Upcoming's Events</div>

              {sortedData.length < 1 && (
                <p
                  style={{
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    fontStyle: "italic",
                  }}
                >
                  There are no upcoming events
                </p>
              )}

              <div className="cardsroll">
                {sortedData?.length
                  ? sortedData?.map((item, index) => {
                      const isToday = moment(item.eventDay).isSame(
                        moment(),
                        "day"
                      );
                      // const cardCoStyle = {
                      //   backgroundColor: isToday ? "red" : "",
                      // };
                      return (
                        <div key={index} className="upcoming pb-1">
                          <div
                            className="cardCo flex flex-column"
                            style={{
                              backgroundColor: isToday
                                ? `var(--primary-color)`
                                : "",
                              color: isToday ? "#fff" : "",
                              paddingLeft: "1rem", // Decreased left padding
                            }}
                          >
                            <div className="cardDa flex justify-end">
                              <div
                                style={{
                                  backgroundColor: item?.eventLabel,
                                  width: "3%",
                                  height: "8%",
                                  padding: "1%",
                                }}
                              ></div>
                              <div
                                className="eventInfo flex justify-between items-center gap-5"
                                style={{
                                  fontSize: "0.9em",
                                  paddingLeft: "0.8rem",
                                  paddingTop: "0.5rem",
                                  width: "100%", // Ensure full width to allow justify-end to work
                                }}
                              >
                                <p style={{ marginBottom: "0px" }}>
                                  {moment(item?.eventDay).format("MMM D, YYYY")}
                                </p>
                                <p style={{ marginBottom: "0px" }}>
                                  {item?.eventLink ? (
                                    <button
                                      onClick={() => {
                                        const link = item?.eventLink;
                                        if (link) {
                                          window.open(
                                            link,
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        }
                                      }}
                                      className="jiluil3"
                                      style={{
                                        fontSize: "1em",
                                      }}
                                    >
                                      Join
                                    </button>
                                  ) : (
                                    "No Link"
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className="cardTi"
                              style={{ paddingLeft: "1.3rem" }}
                            >
                              {item?.eventTitle}
                            </div>
                          </div>
                          <p
                            style={{
                              width: "calc(100% - 4%)",
                              paddingLeft: "0.5rem",
                              fontSize: "0.9em",
                              borderBottom: "1px solid #ccc",
                              marginBottom: "0",
                            }}
                          ></p>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">Total Givings (current month)</div>
              <div
                className="praydas"
                style={{ color: "var(--primary-color)" }}
              >
                {currencyFormatter({
                  amount: givingByMembers,
                  currency: "usd",
                })}
              </div>
              <div className="dasf">
                <button className="kkkk" onClick={showGiving}>
                  View
                </button>
              </div>
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">Volunteering Opportunities</div>
              <div
                className="praydas"
                style={{ color: "var(--primary-color)" }}
              >
                {schedule && schedule.length}
              </div>
              <div className="dasf">
                <button
                  className="kkkk"
                  style={{ backgroundColor: "var(--primary-color)" }}
                  onClick={showVolunteer}
                >
                  View
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="dashRow">
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">Last Checked In</div>
              <div className="praydas" style={{ color: "#FED970" }}>
                {error ? (
                  <span className="kldkd">{error}</span>
                ) : (
                  moment(`${singleCheckin?.createdAt}`).format("LL")
                )}
              </div>
              <div className="dasf">
                <button
                  className="kkkk"
                  style={{ backgroundColor: "var(--secondary-color)" }}
                  onClick={showChecking}
                >
                  Go to checking
                </button>
              </div>
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">FollowUp Assigned To Me</div>
              <div className="praydas" style={{ color: "#FE7064" }}>
                {filteredData ? filteredData : 0}
              </div>
              <div className="dasf">
                <button className="kkkk" onClick={showFollowUp}>
                  View
                </button>
              </div>
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">Currently enrolled courses</div>
              <div className="praydas jyuyu">{courses ? courses : 0}</div>
              <div className="dasf">
                <button
                  className="kkkk"
                  style={{ backgroundColor: "var(--secondary-color)" }}
                  onClick={showCourses}
                >
                  View
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="bottomSpacer"></Row>
      </Container>
    </>
  );
};

export default StandardHome;
