import { useEffect, useState } from "react";
import AddFolderModal from "../../../components/Tags/AddFolderModal";
import AddTagModal from "../../../components/Tags/AddTagModal";
import EditFolderModal from "../../../components/Tags/EditFolderModal";
import EditTagModal from "../../../components/Tags/EditTagModal";
import { useAppContext } from "../../../context/appContext";
import { useParams } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Container, Col } from "react-bootstrap";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { FaStar } from "react-icons/fa";

const TagDetail = (props) => {
  const params = useParams();
  let id = params?.id;

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const {
    folderDetail,
    getFolder,
    deleteTag,
    deleteFolder,
    completemembers,
    getCompleteMembers,
    addToGroup,
    listofGroupMembers,
    allgroupmembers,
    removeFromGroup,
    logoutUser,
  } = useAppContext();
  const [addTagModal, setAddTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [editTagID, setEditTagID] = useState("");
  const [delete_id, setDelete_id] = useState("");
  const [editTagName, setEditTagName] = useState("");
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [editFolderID, setEditFolderID] = useState("");
  const [editFolderName, setEditFolderName] = useState("");
  const [addFolderModal, setAddFolderModal] = useState(false);
  const [giverName, setGiverName] = useState("");
  const [selectedName, setSelectedName] = useState([]);
  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    listofGroupMembers(id);
  }, []);
  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const toggle = () => {
    setAddTagModal(!addTagModal);
  };

  const toggleEditTag = () => {
    setEditTagID("");
    setEditTagName("");
    setEditTagModal(false);
  };
  const toggleDeleteModal = () => {
    setDeleteItem("");
    setDelete_id("");
    setDeleteModal(false);
  };
  const toggleEditFolder = () => {
    setEditFolderID("");
    setEditFolderName("");
    setEditFolderModal(false);
  };
  const toggleFolder = () => {
    setAddFolderModal(!addFolderModal);
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
    setSelectedName([...selectedName, searchTerm]);

    // const addtoGroup = {
    //   groupId: id,
    //   selectedName: searchTerm,
    //   fname: fname,
    //   lname: lname,
    // };

    // addToGroup({ addtoGroup });
    // listofGroupMembers(id);
  };
  const addUserToGroup = () => {
    const [fname, lname] = giverName.split(" ");

    const addtoGroup = {
      groupId: id,
      selectedName: selectedName,
      fname: fname,
      lname: lname,
    };

    addToGroup({ addtoGroup });
    listofGroupMembers(id);
  };

  const [teamLeader, setTeamLeader] = useState(false);

  const makeLead = async (id, groupMember) => {
    const data = {
      teamLeader: true,
      groupMemberId: groupMember._id,
    };
    try {
      const response = await authFetch.put(`/members/make-lead/${id}`, {
        data,
      });

      if (response.status === 200) {
        listofGroupMembers(id);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const unMakeLead = async (id, groupMember) => {
    const data = {
      teamLeader: false,
      groupMemberId: groupMember._id,
    };
    try {
      const response = await authFetch.put(`/members/unmake-lead/${id}`, {
        data,
      });

      if (response.status === 200) {
        listofGroupMembers(id);
      }
    } catch (error) {
      logoutUser();
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader" style={{ marginBottom: "3rem" }}>
          <Col sm={8}>
            <p className="sahs">{id}</p>
          </Col>
        </Row>

        <Row className="sssssssecwef">
          <div className="yukukuykuyk">
            <InputGroup>
              <Form.Control
                placeholder="Start Typing"
                aria-label="Start Typing"
                aria-describedby="basic-addon2"
                value={giverName}
                onChange={onChange}
                name="giverName"
                style={{ fontSize: "1em" }}
              />
              <button
                style={{
                  backgroundColor: "var(--primary-color)",
                  borderColor: "var(--primary-color)",
                  fontSize: "1em",
                  color: "white",
                  width: "30%",
                }}
                onClick={addUserToGroup}
                id="button-addon2"
              >
                Add to Group
              </button>
            </InputGroup>

            {/* <div className="dropdownmytag"> */}
            <div className="w-100">
              {completemembers
                .filter((item) => {
                  const searchTerm = giverName.toLowerCase();
                  const fname = item.mfirstName.toLowerCase();
                  const lname = item.mlastName.toLowerCase();

                  const fullname = fname + lname;

                  return (
                    searchTerm &&
                    fullname.includes(searchTerm) &&
                    fullname !== searchTerm
                  );
                })
                .slice(0, 10)
                .map((item) => (
                  <div
                    onClick={() =>
                      onSearch(item.mfirstName + " " + item.mlastName)
                    }
                    className="dropdownmy-row ewfwefwefwe"
                    key={item.mfirstName}
                  >
                    {item.mfirstName} {item.mlastName}
                  </div>
                ))}
            </div>
          </div>
        </Row>

        <Row className="fundc2">
          <p style={{ fontWeight: "600", fontSize: "1em" }}>
            {allgroupmembers.length} Members Found
          </p>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>NAME</th>
                <th
                  style={{
                    textAlign: "end",
                    fontSize: "1em",
                    paddingRight: "92px",
                  }}
                >
                  ACTION
                </th>
              </tr>
            </thead>
            {allgroupmembers.map((groupMember) => (
              <tbody>
                <tr>
                  <td style={{ fontSize: "1em", verticalAlign: "middle" }}>
                    {groupMember.mfirstName} {groupMember.mlastName}{" "}
                    {groupMember.teamLeader === "true" ? <FaStar /> : ""}
                  </td>

                  <td>
                    <div className="flex justify-end items-center gap-3">
                      {groupMember.teamLeader === "true" ? (
                        <button
                          className="jiluil3"
                          onClick={() => unMakeLead(id, groupMember)}
                        >
                          Remove Leader
                        </button>
                      ) : (
                        <button
                          className="jiluil"
                          onClick={() => makeLead(id, groupMember)}
                        >
                          Make Leader
                        </button>
                      )}

                      <button
                        className="jiluil3"
                        onClick={() =>
                          removeFromGroup(id, groupMember._id, {
                            alertText: "Member Removed Successfully",
                          })
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Row>

        <AddTagModal
          addTagModal={addTagModal}
          toggle={toggle}
          name={folderDetail.folderName}
          id={id}
        />
        <AddFolderModal
          addFolderModal={addFolderModal}
          toggleFolder={toggleFolder}
          name={folderDetail.folderName}
          id={id}
        />
        <EditTagModal
          editTagModal={editTagModal}
          toggleEditTag={toggleEditTag}
          name={editTagName}
          id={editTagID}
        />
        <EditFolderModal
          editFolderModal={editFolderModal}
          toggleEditFolder={toggleEditFolder}
          name={editFolderName}
          id={editFolderID}
        />

        <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Delete Folder</ModalHeader>
          <ModalBody className="modal-wrapper__body">
            <Row>
              <Col md="12"></Col>
              <div class="jsx-parser">
                {deleteItem === "tag"
                  ? "Do you really want to remove this ministry?"
                  : " Do you really want to remove this folder? All ministries and folders within this folder will also be deleted."}
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (deleteItem === "tag") {
                  await deleteTag(delete_id);
                } else if (deleteItem === "folder") {
                  await deleteFolder(delete_id);
                }
                toggleDeleteModal();
                await getFolder(id);
              }}
              color="primary"
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Delete Folder</ModalHeader>
          <ModalBody className="modal-wrapper__body">
            <Row>
              <Col md="12"></Col>
              <div class="jsx-parser">
                {deleteItem === "tag"
                  ? "Do you really want to remove this ministry?"
                  : " Do you really want to remove this folder? All ministries and folders within this folder will also be deleted."}
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (deleteItem === "tag") {
                  await deleteTag(delete_id);
                } else if (deleteItem === "folder") {
                  await deleteFolder(delete_id);
                }
                toggleDeleteModal();
                await getFolder(id);
              }}
              color="primary"
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default TagDetail;
