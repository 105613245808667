import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaNetworkWired } from "react-icons/fa";
import l1 from "../../assets/newi/l1.png";
import n2 from "../../assets/newi/n2.png";
import MoreProduct from "../../components/Product/MoreProduct";

const ProductCampus = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#77C2BE" }}>
            <FaNetworkWired size={100} />
            <h1>Church Campus</h1>
            <p className="headpp">
              Seamlessly manage multiple church locations with our Campus
              feature. This tool simplifies operations and fosters unity across
              all sites, enhancing communication and centralized control for a
              cohesive church network.
            </p>
            <button className="headerintro-button" onClick={handleDemoClick}>
              Book a demo
            </button>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for multi-campus management
            </p>
            <p className="product13">
              Your comprehensive system to oversee and synchronize activities
              across all church locations, enhancing unity and operational
              efficiency.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={l1} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">CAMPUS PROFILES</p>
              <p className="proddz1">
                Create and manage unique profiles for each campus, tailoring
                information and resources to local needs.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">UNIFIED CALENDAR</p>
              <p className="proddz1">
                Share a centralized calendar across campuses to coordinate
                events and services, ensuring alignment and participation.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RESOURCE SHARING</p>
              <p className="proddz1">
                Facilitate the sharing of resources and personnel between
                campuses, optimizing utilization and support.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">CROSS-CAMPUS COMMUNICATION</p>
              <p className="proddz1">
                Enable seamless communication channels between campuses to
                foster collaboration and community spirit.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CONSISTENT BRANDING</p>
              <p className="proddz1">
                Maintain consistent branding and messaging across all locations
                to strengthen the church's identity.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">PERFORMANCE METRICS</p>
              <p className="proddz1">
                Track and compare performance metrics across campuses to
                identify strengths and areas for improvement.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={n2} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#77C2BE" />
      <Footer />
    </>
  );
};

export default ProductCampus;
