import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAppContext } from "../../../context/appContext";
import "./steps.css";
import Image from "react-bootstrap/Image";
import Profilez from "../../../assets/images/blank2.png";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import blankImage from "../../../assets/images/blank2.png";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loading indication

const Step3 = ({ formData, handleChange, handleCompletionChange }) => {
  const { user, logoutUser, updateUser } = useAppContext(); // use the context here
  const [previewLogo, setPreviewLogo] = useState("");
  const [imageLogo, setImageLogo] = useState(user.churchLogo);
  const [uploadButtonTextLogo, setUploadButtonTextLogo] =
    useState("Upload Logo");
  const [loading, setLoading] = useState(false); // State for loading indicator

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleImageLogo = (e) => {
    let file = e.target.files[0];
    setPreviewLogo(window.URL.createObjectURL(file));
    setUploadButtonTextLogo(file.name);
    // resize
    Resizer.imageFileResizer(file, 520, 300, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await authFetch.post("/members/logo/uploadImageLogo", {
          image: uri,
        });

        setImageLogo(data);
        handleChange({
          target: {
            name: "churchLogo",
            value: data,
          },
        });
      } catch (err) {}
    });
  };

  const handleImageRemoveLogo = async () => {
    setLoading(true); // Start loading indicator
    try {
      // Convert blankImage to a file object
      const response = await fetch(blankImage);
      const blob = await response.blob();
      const file = new File([blob], "blank.png", { type: "image/png" });

      // Resize blankImage
      Resizer.imageFileResizer(
        file,
        520,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            // Send both the current imageLogo and the resized blankImage to the backend
            await authFetch.post("/members/remove-image-logo", {
              imageLogo,
              blankImage: uri,
            });

            // Set the default blank image as the new logo
            setImageLogo(uri);
            setPreviewLogo(uri);
            handleChange({
              target: {
                name: "churchLogo",
                value: uri,
              },
            });
            setLoading(false); // Stop loading indicator
          } catch (err) {
            console.error("Error in handleImageRemoveLogo function:", err);
            setLoading(false); // Stop loading indicator on error
          }
        },
        "base64"
      );
    } catch (err) {
      console.error("Error converting blank image:", err);
      setLoading(false); // Stop loading indicator on error
    }
  };

  const uploadImage = (e) => {
    e.preventDefault();

    const currentUser = {
      churchName: user.churchName,
      churchLogo: imageLogo,
    };

    updateUser({ currentUser });
  };

  return (
    <div className="pt-4 ">
      {" "}
      {/* Center the content */}
      <p className="text-lg text-bold pb-3">Church Logo</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Change to column for vertical alignment
          alignItems: "center", // Center horizontally
          justifyContent: "center", // Center vertically
        }}
      >
        <Image
          width={100}
          height={100}
          src={
            previewLogo
              ? previewLogo
              : user?.churchLogo?.Location
              ? user?.churchLogo?.Location
              : Profilez
          } // Use previewLogo if it exists
          roundedCircle
        />
        <div className="flex flex-row gap-3 mt-4">
          <Form.Group className="mb-3">
            <Form.Label
              htmlFor="imageLogoInput"
              className="hed btn btn-outline-secondary btn-block"
            >
              Select Logo
            </Form.Label>
            <Form.Control
              type="file"
              id="imageLogoInput"
              name="churchLogo"
              onChange={handleImageLogo}
              accept="image/*"
              className="formvalue"
              hidden
            />
          </Form.Group>
          <button
            className="hed btn btn-outline-secondary btn-block"
            onClick={handleImageRemoveLogo}
          >
            Remove Logo
          </button>
        </div>
      </div>
      <div className="flex justify-end">
        <Form.Check
          type="checkbox"
          id="step3Complete"
          label="Mark as Completed"
          checked={formData.step3Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
      {loading && <Spinner animation="border" variant="primary" />}{" "}
      {/* Loading indicator */}
    </div>
  );
};

export default Step3;
