import React from "react";
import AreaChart from "../../components/AreaChart/AreaChart";
import { useAppContext } from "../../context/appContext";

const ChartsContainer = () => {
  const { monthlyNewMembers: data } = useAppContext();

  return <div>{<AreaChart data={data} />}</div>;
};

export default ChartsContainer;
