import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "react-toastify/dist/ReactToastify.css";
import "./need.css";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import Header from "../../components/Navbar/Navbar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FaCalendar,
  FaCheck,
  FaChess,
  FaEnvelope,
  FaFont,
  FaMicrophone,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
} from "react-icons/fa";
import Footer from "../../components/Footer/Footer.js";

const features = [
  {
    name: "People",
    cost: 0,
    description: "Manage church member details and records.",
    image: <FaUserAlt />,
  },
  {
    name: "Ministry",
    cost: 10,
    description: "Tools for managing various ministries within the church.",
    image: <FaPeopleArrows />,
  },
  {
    name: "Groups",
    cost: 11,
    description: "Organize and manage small groups for better engagement.",
    image: <FaUserFriends />,
  },
  {
    name: "Follow Up",
    cost: 10,
    description: "Track and follow up with visitors and members.",
    image: <FaRunning />,
  },
  {
    name: "Event",
    cost: 20,
    description: "Plan and manage church events seamlessly.",
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",
    cost: 10,
    description:
      "Schedule and plan services, including volunteers and resources.",
    image: <FaCheck />,
  },
  {
    name: "Forms",
    cost: 10,
    description: "Create custom forms for surveys, signups, and more.",
    image: <FaFont />,
  },
  {
    name: "Fellowship",
    cost: 10,
    description:
      "Enhance the sense of community and fellowship within the church.",
    image: <FaPray />,
  },
  {
    name: "Learning Center",
    cost: 22,
    description: "Educational tools for discipleship and learning.",
    image: <FaEnvelope />,
  },
  {
    name: "Live Streaming",
    cost: 13,
    description:
      "Broadcast your services and events live to reach a wider audience.",
    image: <FaVideo />,
  },
  {
    name: "Giving",
    cost: 30,
    description: "Facilitate tithes, offerings, and donations digitally.",
    image: <FaChess />,
  },
  {
    name: "Communication",
    cost: 20,
    description:
      "Comprehensive tools for effective communication within the church community.",
    image: <FaTextHeight />,
  },
  {
    name: "Chatbot",
    cost: 14,
    description:
      "Automate responses and engage with members 24/7 through an intelligent chatbot.",
    image: <FaMicrophone />,
  },
];
const NeedSubmitted = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [churchName, setChurchName] = useState();
  const [aboutUs, setAboutUs] = useState();
  const [country, setCountry] = useState();
  const [churchSize, setChurchSize] = useState();
  const [churchSMS, setChurchSMS] = useState();
  const [churchContent, setChurchContent] = useState();
  const { isLoading, showAlert, displayAlert, userNeed } = useAppContext();
  const [scroll, setScroll] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [churchWebsite, setChurchWebsite] = useState();
  const [emailError, setEmailError] = useState(false);

  function handleFeatureSelect(feature, isChecked) {
    setSelectedFeatures((prev) =>
      isChecked
        ? [...prev, feature.name]
        : prev.filter((f) => f !== feature.name)
    );
  }

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !country ||
      !lastName ||
      !churchName ||
      !phoneNumber ||
      !email ||
      !churchSize ||
      !churchSMS ||
      !churchContent ||
      !churchWebsite ||
      !country ||
      selectedFeatures.length === 0
    ) {
      displayAlert();
      return;
    }

    const demoUser = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      churchName: churchName,
      country: country,
      churchWebsite: churchWebsite,
      mmobilePhone: phoneNumber,

      churchSize: churchSize,
      churchSMS: churchSMS,
      churchContent: churchContent,
      selectedFeatures: selectedFeatures,
    };

    userNeed({
      demoUser,
      alertText: `
      Thank you for your demo request submission. A team member will be in touch with you soon. We appreciate your interest.`,
    });
    navigate("/thankyou");

    setScroll(true);

    setFirstName("");
    setLastName("");
    setChurchName("");
    setCountry("");
    setAboutUs("");
    setPhoneNumber("");
    setEmail("");
    setChurchSize("");
    setChurchSMS("");
    setChurchContent("");
    setSelectedFeatures([]);
    setChurchWebsite("");
  };

  return (
    <>
      <Helmet>
        <title>Success - Churchease</title>
        <meta
          name="description"
          content="Success - Page for successful form submission"
        />
      </Helmet>
      <Header />
      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", marginBottom: "10rem" }}>
          <div className="flex items-center flex-column wrrer">
            <Form className="formdivfdss">
              <p className="awwqx mb-3" style={{ fontSize: "2em" }}>
                Thank you for your submission!
              </p>
              <p className="mb-4 text-center text-xl">
                We will be in touch soon.
              </p>
            </Form>
          </div>
        </Row>
        <Footer />
      </Container>
    </>
  );
};

export default NeedSubmitted;
