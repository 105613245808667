import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { useAppContext } from "../../../context/appContext";
import "./bulkaction.css";
import Alert from "../../../components/Alert/Alert";
import { Modal, Spinner } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import BulkValidation from "../../../components/Bulk/BulkValidation";

const BulkAction = () => {
  const { showAlert, logoutUser, uploadMemberMany, isLoading } =
    useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [file, setFile] = useState(null);
  const [loading, setIsLoading] = useState(false); // To track the loading state

  const memberFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFile(selectedFile);
  };

  const groupFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFile(selectedFile);
  };

  const memberUpload = async () => {
    if (!file) {
      toast(<CustomToastContent title="No file selected" type="error" />);

      return;
    }

    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append("csv", file);

    try {
      const response = await authFetch.post("/bulk/bulkmember", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFile(null); // Clear the file from state

      if (response.ok || response.status === 201 || response.status === 200) {
        // Checks for 2xx status codes

        toast(<CustomToastContent title="Upload Successful" type="success" />);
      } else {
        const errorMsg = await response.text();
        toast(<CustomToastContent title={errorMsg} type="error" />);
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          toast(
            <CustomToastContent title="You are not authorized" type="error" />
          );
        } else if (data.message === "Validation errors") {
          if (data.duplicates && data.duplicates.length > 0) {
            const duplicatesList = data.duplicates.join(", ");

            toast(<CustomToastContent title="Validation error" type="error" />);
            setFile(null);
          } else {
            toast(<CustomToastContent title="Validation error" type="error" />);
          }
        } else {
          const duplicatesList = data.duplicates.join(", ");

          toast(<CustomToastContent title="Validation error" type="error" />);
          setFile(null);
        }
      } else {
        toast(
          <CustomToastContent
            title="Member already exists. Please check the file and try again."
            type="error"
          />
        );
      }
    } finally {
      setIsLoading(false); // Ensure loading is stopped in case of error
    }
  };

  const groupUpload = async () => {
    if (!file) {
      toast(<CustomToastContent title="No file selected" type="error" />);
      return;
    }

    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append("csv", file);

    try {
      const response = await authFetch.post("/bulk/bulkgroup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFile(null); // Clear the file from state

      if (response.status >= 200 && response.status < 300) {
        // Checks for 2xx status codes

        toast(
          <CustomToastContent
            title="Group created successfully."
            type="success"
          />
        );
      } else {
        const errorMsg = await response.text();

        toast(<CustomToastContent title={errorMsg} type="error" />);
      }
    } catch (error) {
      Sentry.captureException(error);
      // Log the full error response for debugging

      if (error?.response?.status === 401) {
        toast(
          <CustomToastContent title="You are not authorized" type="error" />
        );
      } else {
        toast(
          <CustomToastContent
            title="Group already exists. Please check the file and try again."
            type="error"
          />
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Extract users older than 30 and add them to a new object

  const memberdownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/People_Sample.csv"; // Adjust the path to your CSV file
    link.download = "People_Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const memberdownloadTemplateCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/People_Template.csv"; // Adjust the path to your CSV file
    link.download = "People_Template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const groupdownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Group_Sample.csv"; // Adjust the path to your CSV file
    link.download = "Group_Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const groupdownloadTemplateCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Group_Template.csv"; // Adjust the path to your CSV file
    link.download = "Group_Template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [showValidation, setShowValidation] = useState(false);
  const runValidationShow = () => {
    setShowValidation(true);
  };

  const ministriesdownloadCSV = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Ministries_Template_Download.csv"; // Adjust the path to your CSV file
    link.download = "Ministries_Template_Download.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Extract users older than 30 and add them to a new object

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="headerbanner">
          <div className="bannerbig">Bulk Operations</div>
          <div className="bannersmall">
            Streamline your administrative tasks with Bulk Operations, allowing
            you to efficiently manage large-scale actions like uploading member
            information in bulk, saving time and effort.
          </div>
        </Row>

        <Row className="bnmjo mb-5">
          <button className="jiluil" onClick={runValidationShow}>
            Member Validation
          </button>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>OPERATION </th>
                <th style={{ fontSize: "1em" }}> DESCRIPTION</th>
                <th style={{ fontSize: "1em" }}>ACTION</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Import members</td>
                <td>
                  Download the member template, update the file with your church
                  member details and upload it.
                  <p
                    onClick={memberdownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={memberFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil3" onClick={memberUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={memberdownloadTemplateCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                  {/* <FaFileDownload
                    onClick={memberdownloadCSV}
                    style={{ fontSize: "1.5em", color: "var(--primary-color)" }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td>Import Groups</td>
                <td>
                  Download the group template, update the file with your groups
                  and upload.
                  <p
                    onClick={groupdownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={groupFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil3" onClick={groupUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={groupdownloadTemplateCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                  {/* <FaFileDownload
                    onClick={groupdownloadCSV}
                    style={{ fontSize: "1.5em", color: "var(--primary-color)" }}
                  /> */}
                </td>
              </tr>
              {/* <tr>
                <td>Import Ministries</td>
                <td>
                  Download the Ministries template, update the file with your
                  groups and upload.
                  <p
                    onClick={ministriesdownloadCSV}
                    style={{
                      color: "var(--secondary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See sample file
                  </p>
                </td>

                <td>
                  <input
                    type="file"
                    id="file"
                    onChange={ministriesFileChange}
                    className="inputfile"
                    accept=".csv"
                  />
                </td>
                <td>
                  <button className="jiluil3" onClick={ministriesUpload}>
                    Upload
                  </button>
                </td>
                <td>
                  <p
                    onClick={ministriesdownloadCSV}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Template
                  </p>
                 
                </td>
              </tr> */}
            </tbody>
          </Table>
          <div
            className="flex align-center justify-center flex-column w-100"
            style={{ textAlign: "center" }}
          >
            <p className="qubulkf">Learn More</p>
            <p className="qubulkf2">
              Check our{" "}
              {/* <Link
                to="/knowledge-base"
                style={{ color: "var(--secondary-color)" }}
              >
                knowledge base
              </Link>{" "} */}
              <a
                href="https://churchease.com/knowledgebaseview/6662855af3968a28d13ad1f5"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "var(--secondary-color)",
                  textDecoration: "none",
                }}
              >
                knowledge base
              </a>{" "}
              for more information on how to use the bulk operations feature.
            </p>
          </div>
          <Modal show={loading} centered backdrop="static" keyboard={false}>
            <Modal.Body>
              <div className="text-center">
                <Loading />
                <p className="mt-3">Uploading Members...</p>
              </div>
            </Modal.Body>
          </Modal>
        </Row>

        <BulkValidation show={showValidation} setShow={setShowValidation} />
      </Container>
    </>
  );
};

export default BulkAction;
