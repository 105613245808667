import React from "react";
import Day from "./Day";
import "./calendar.css";

export default function Month({ month, events }) {
  return (
    <div
      className="monthgrid"
      style={{ marginBottom: "4rem", marginTop: "4rem" }}
    >
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <Day day={day} key={idx} rowIdx={i} events={events} />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
