import React from "react";
import { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import "./knowledgebasexx.css";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./knowledgebasexx.css";
import { Helmet } from "react-helmet";
import {
  FaBook,
  FaLaptop,
  FaChartPie,
  FaUsers,
  FaMoneyBill,
  FaUserAlt,
  FaPeopleArrows,
  FaRunning,
  FaBible,
} from "react-icons/fa"; // Example using FontAwesome icons

const KnowledgeBase = () => {
  const { logoutUser, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [resourceData, setResourceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    loadKnowledgeBase();
  }, []);

  const loadKnowledgeBase = async () => {
    try {
      setLoading(true); // Set loading state to true before fetching data
      const { data } = await authFetch.get("/generalknowledge");

      setResourceData(data);
    } catch (error) {
      console.error("Error loading course resources:", error);
      // Optionally, set an error state here to display an error message to the user
      setError("Failed to load resources. Please try again later.");
    } finally {
      setLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };

  // const groupedByCategory = resourceData?.reduce((acc, resource) => {
  //   const { category, title, _id, blocks } = resource;
  //   if (!acc[category]) {
  //     acc[category] = [];
  //   }
  //   acc[category].push({ title, _id, blocks });
  //   return acc;
  // }, {});

  const gotKnowledgeBase = (e, category) => {
    e.preventDefault();
    console.log("category", category);
    navigate(`/knowledgebase-details/${category}`);
  };

  console.log("resourceData", resourceData);
  return (
    <>
      <Helmet>
        <title>
          Knowledge Base | Churchease - Your Church Management Solution
        </title>
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="upperse">
            <p className="blogkk1">Knowledge Base</p>
          </div>
        </Row>
        <Row className="lowsee23 ">
          {resourceData.map((categoryItem) => (
            <Col
              key={categoryItem.category}
              md={3}
              lg={3}
              sm={6}
              className="maiddd23 cudknow"
              onClick={(e) => gotKnowledgeBase(e, categoryItem.category)}
            >
              <div
                className="d-flex flex-column justify-content-center align-items-start pt-5"
                // Ensures the content takes the full width of the column
              >
                {/* Conditionally render the icon based on the category */}
                <div className="category-icon mb-3">
                  {categoryItem.category === "Getting Started" && (
                    <FaBook className="knicin" />
                  )}
                  {categoryItem.category === "Children" && (
                    <FaLaptop className="knicin" />
                  )}
                  {categoryItem.category === "Church Giving" && (
                    <FaMoneyBill className="knicin" />
                  )}
                  {categoryItem.category === "Fellowship" && (
                    <FaUsers className="knicin" />
                  )}

                  {categoryItem.category === "People" && (
                    <FaUserAlt className="knicin" />
                  )}

                  {categoryItem.category === "Groups" && (
                    <FaPeopleArrows className="knicin" />
                  )}

                  {categoryItem.category === "Follow-Up" && (
                    <FaRunning className="knicin" />
                  )}

                  {categoryItem.category === "Ministry" && (
                    <FaBible className="knicin" />
                  )}
                </div>
                <p className="kclasn">{categoryItem?.items?.length} Articles</p>
                <p className="kclasn2">{categoryItem?.category}</p>
                {/* <p className="kclasn3">{categoryItem?.titleSection}</p> */}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default KnowledgeBase;
