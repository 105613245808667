import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const BarChartComponent = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [data, setData] = useState([]);
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);

  useEffect(() => {
    getStat();
  }, []);

  const getStat = async () => {
    try {
      const response = await authFetch.get("/members/getgenderstat");

      if (response.status === 200 || response.status === 201) {
        // Extract and set counts for legend
        const male = response.data.genderStats.Male;
        const female = response.data.genderStats.Female;

        setMaleCount(male);
        setFemaleCount(female);

        // Transform the data to fit the BarChart format
        const genderData = [
          {
            category: "Gender", // Using a common category for both bars
            Male: male,
            Female: female,
          },
        ];
        setData(genderData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 50 }}
        barCategoryGap="20%" // Adding space between groups of bars
        barGap={50} // Adding space between individual bars
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend
          formatter={(value) => {
            if (value === "Male") {
              return `Male (${maleCount})`;
            }
            if (value === "Female") {
              return `Female (${femaleCount})`;
            }
            return value;
          }}
        />
        {/* Separate Bars for Male and Female with different colors */}
        <Bar dataKey="Male" fill="#243060" barSize={75} />
        <Bar dataKey="Female" fill="#FED970" barSize={75} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
