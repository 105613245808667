import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";

import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import moment from "moment/moment";
import axios from "axios";

const StandardCheckin = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,

    logoutUser,
    events,
    getEvents,

    user,
    getCompleteMembers,
    completemembers,

    createCheckin,
  } = useAppContext();

  const [event, setEvent] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [lastName, setLastName] = useState("");
  const [selectedName, setSelectedName] = useState([]);

  const [singleEvent, setSingleEvent] = useState("");
  const [singleCheckin, setSingleCheckin] = useState([]);
  const [familyDataxx, setFamilyDataxx] = useState([]);

  const onSearch = async (searchTerm) => {
    try {
      const currentTime = new Date().toISOString();
      const [fname, lname] = searchTerm.split(" ");
      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );

      const [memberIdOnly] = data || []; // Ensure memberIdOnly is an array

      const {
        churchName = "",
        eventTitle = "",
        _id = "",
        eventId = "",
        eventStartDate = "",
      } = singleEvent || {}; // Ensure singleEvent is an object

      const checkin = {
        selectedName: searchTerm,
        fname,
        lname,
        churchName,
        eventTitle,
        event_id: _id,
        eventId,
        eventDate: eventStartDate,
        checkIn: currentTime,
        mId: memberIdOnly ? memberIdOnly._id : "",
        familyId: user?.familyId,
      };

      setSelectedName((prevNames) => [...prevNames, searchTerm]); // Using callback function to ensure we get the most recent state

      setCheckinData(checkin);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  const getFamilyById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(`/members/getbyfamilyid`);

      if (data) {
        setFamilyDataxx(data);
      }
    } catch (error) {
      logoutUser();
    }
  };

  useEffect(() => {
    if (user) {
      getFamilyById();
    }
  }, [user]);

  const data = completemembers.filter(
    (item) => item?.familyId === user?.familyId
  );

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(
        `/events/getattendancebyid/${event}`
      );
      if (data) {
        setSingleEvent(data.singleEvent);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const getCheckinById = async (e) => {
    e?.preventDefault();

    try {
      const { data } = await authFetch.get(
        `/checkin/standard/${singleEvent.eventId}/${user?.familyId}`
      );
      if (data) {
        setSingleCheckin(data.checkin);
      }
    } catch (error) {
      logoutUser();
    }
  };
  const [myAlert, setMyAlert] = useState(false);
  const [checkedin, setCheckedin] = useState(false);
  useEffect(() => {
    if (myAlert) {
      const timer = setTimeout(() => {
        setMyAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [myAlert]);

  useEffect(() => {
    if (checkedin) {
      const timer = setTimeout(() => {
        setCheckedin(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [checkedin]);

  useEffect(() => {
    if (singleEvent) {
      getCheckinById();
    }
  }, []);

  useEffect(() => {
    if (event) {
      getEventById();
    }
  }, [event]);
  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    events && getEvents();
  }, []);
  // Extract users older than 30 and add them to a new object

  const changeEvent = async () => {
    setShowForm(!showForm);
  };

  const [checkinData, setCheckinData] = useState(null);

  useEffect(() => {
    if (!checkinData) return;

    const checkValue = singleCheckin.some(
      (item) => item.selectedName === checkinData.selectedName // use checkinData instead of singleEvent for accuracy
    );

    if (checkValue) {
      setMyAlert(true);
    } else {
      createCheckin({ checkin: checkinData });
      setCheckedin(true);
      setSingleCheckin((prevCheckins) => [...prevCheckins, checkinData]); // Update the state directly
      getCheckinById();
    }
  }, [checkinData, singleCheckin]);
  const [myText, setMyText] = useState("");
  const [myCheck, setMyCheck] = useState(false);
  const testt = (e) => {
    setMyText(e.target.value);
    setMyCheck(e.target.checked);
    onSearch2();
  };

  const onSearch2 = async (searchTerm) => {
    try {
      const currentTime = new Date().toISOString();
      const [fname, lname] = myText.split(" ");

      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );
      const [memberIdOnly] = data || [];

      const {
        churchName = "",
        eventTitle = "",
        _id = "",
        eventId = "",
        eventStartDate = "",
      } = singleEvent || {};

      if (myCheck && !selectedName.includes(myText)) {
        setSelectedName((prevNames) => [...prevNames, myText]);
      } else if (!myCheck && selectedName.includes(myText)) {
        setSelectedName((prevNames) =>
          prevNames.filter((name) => name !== myText)
        );
      }

      const checkin = {
        selectedName: myText,
        fname,
        lname,
        churchName,
        eventTitle,
        event_id: _id,
        eventId,
        eventDate: eventStartDate,
        checkIn: currentTime,
        mId: memberIdOnly ? memberIdOnly._id : "",
        familyId: user?.familyId,
      };

      setCheckinData(checkin);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        {myAlert && <div className="interte">Member is already checked.</div>}

        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs flex items-center">Family Check In</Col>
        </Row>

        <Row className="fundc2">
          <div>
            <Form.Group
              className="mb-3 form-centerfffff"
              controlId="formBasicCheckbox"
            >
              <Form.Select
                aria-label="Default select example"
                value={event}
                onChange={(e) => {
                  setEvent(e.target.value);
                  changeEvent();
                }}
                className="select-widthssss"
              >
                <option value="">Select an event to Check In</option>
                {events
                  .filter((e) => new Date(e.eventDay) >= new Date()) // Filter out past events
                  .sort((a, b) => new Date(a.eventDay) - new Date(b.eventDay))
                  .slice(0, 10)
                  .map((event, index) => (
                    <option key={event._id} value={event._id}>
                      {event.eventTitle} -{" "}
                      {moment(event.eventDay).format("MMMM Do YYYY")}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            {/* {showForm && (
              <Form className="form-center">
                <div className="mb-3">
                  {familyDataxx?.map((data, index) => (
                    <div key={index} className="checkbox-group">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={`${data.mfirstName} ${data.mlastName}`}
                        value={`${data.mfirstName} ${data.mlastName}`}
                        checked={selectedName.includes(
                          `${data.mfirstName} ${data.mlastName}`
                        )}
                        onChange={testt} // Assuming `testt` is a function for handling the change
                      />
                    </div>
                  ))}
                </div>
              </Form>
            )} */}
            {showForm && (
              <Form.Group
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                controlId="formBasicEmail"
              >
                <Form.Label className="hed"></Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  name="lastName"
                  //handleChange={handleMemberInput}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Type the last name of the member"
                  className="formvalue"
                  required
                  style={{ width: "50%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {familyDataxx
                    .filter((item) => {
                      const searchTerm = lastName?.toLowerCase();
                      const fname = item.mfirstName?.toLowerCase();
                      const lname = item.mlastName?.toLowerCase();

                      const fullname = fname + lname;

                      return (
                        searchTerm &&
                        fullname.includes(searchTerm) &&
                        fullname !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item) => (
                      <div
                        onClick={() =>
                          onSearch(item.mfirstName + " " + item.mlastName)
                        }
                        className="dropdownmy-row ewfwefwefwe"
                        style={{ width: "50%" }}
                        key={item.mfirstName}
                      >
                        {item.mfirstName} {item.mlastName}
                      </div>
                    ))}
                </div>
              </Form.Group>
            )}
          </div>
          <div class="check-in-instructions pt-4">
            <h5 className="pb-3">Family Check-In Instructions:</h5>
            <ol>
              <li>
                <strong>Select the Event:</strong> From the event list, choose
                the event you want members to check in to.
              </li>
              <li>
                <strong>Enter Family Name:</strong> In the check-in section,
                type the family name of the member and submit.
              </li>
            </ol>
            <p>
              Members will be checked in once their family name is submitted.
            </p>
          </div>
        </Row>

        {singleCheckin.length > 0 && (
          <Row className="fundc2">
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Name</th>
                  <th style={{ fontSize: "1em" }}>Status</th>
                  <th style={{ fontSize: "1em" }}>Time</th>
                </tr>
              </thead>

              <tbody>
                {singleCheckin?.map((data, index) => (
                  <tr>
                    <td style={{ fontSize: "1em" }}>{data.selectedName}</td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {data.checkOut ? "Checked Out" : "Checked In"}
                    </td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {data.checkOut
                        ? moment(data.checkOut).format("h:mm:ss a")
                        : moment(data.checkIn).format("h:mm:ss a")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

export default StandardCheckin;
