import React from "react";
import "./feature.css";
import AnnualPlan from "./AnnualPlan";
import Monthlyplan from "./Monthlyplan";
import TwoYearsPlan from "./TwoYearsPlan";
import { Container, Row, Col } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import CheckoutFormTrial from "../CheckoutForm/checkoutFormTrial";

const PricePageForTrial = ({ totalPrice, selectedFeatures, size }) => {
  //const displayedPrice = Number(totalPrice).toFixed(2);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Container fluid>
      <Row>
        <div className="pricingbody22">
          <p className="pricingb">Complete your payment</p>
        </div>
      </Row>
      <Row className="flex items-center justify-center w-100">
        <div className="flex flex-column checkouta w-100">
          <Elements stripe={stripePromise}>
            <CheckoutFormTrial
              price={totalPrice.toFixed(2)}
              selectedFeatures={selectedFeatures}
              size={size}
            />
          </Elements>
        </div>
      </Row>
    </Container>
  );
};

export default PricePageForTrial;
