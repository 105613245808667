import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./buttonmodal.css";
import Form from "react-bootstrap/Form";
import blank2 from "../../assets/images/blank2.png";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { useAppContext } from "../../context/appContext";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";
import Accordion from "react-bootstrap/Accordion";
const ButtonModal = ({ labels, style, handler, closemodal }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, mProfilePicture } = useAppContext();
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [familyRole, setFamilyRole] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [mfamily, setMfamily] = useState(null);

  const [birthday, setBirthday] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [anniversaryMonth, setAnniversaryMonth] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [facebook, setFacebook] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [linkedin, setLinkedin] = useState(null);

  const [state, setState] = useState(null);
  const [image, setImage] = useState(mProfilePicture);
  const [preview, setPreview] = useState(blank2); // Replace with your placeholder image URL

  const handleClose = () => setShow(false);
  const showModal = (breakpoint) => {
    setShow(true);
  };
  const [mydata, setMydata] = useState([]);

  const createPersons = (e) => {
    e.preventDefault();

    if (!lastName) {
      window.alert("Please enter Family Last Name");
      return;
    }

    const currentMember = {
      mfirstName: firstName,

      mlastName: lastName,
      memail: email,
      mgender: gender,
      mstatus: status,
      mmarital: maritalStatus,
      mProfilePicture: image,
      mfamily: mfamily,
      mhomePhone: homePhone,
      mworkPhone: workPhone,
      mmobilePhone: mobilePhone,
      mcity: city,
      maddress: address,
      mpostal: postal,
      familyRole: familyRole,
      mBirthday: birthday,
      mBirthMonth: birthMonth,
      mAnniversaryDay: anniversary,
      mAnniversaryMonth: anniversaryMonth,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      linkedin: linkedin,
      mstate: state,
    };

    setMydata([...mydata, currentMember]);
    handler([...mydata, currentMember]);
    handleClose();
    closemodal();
  };

  const [genderx, setGenderx] = useState();
  const showGender = () => {
    setGenderx(!genderx);
  };

  const [anniversaryx, setAnniversaryx] = useState();
  const showAnniversary = () => {
    setAnniversaryx(!anniversaryx);
  };

  // const [notesx, setNotesx] = useState();
  // const showNotes = () => {
  //   setNotesx(!notesx);
  // };

  const [familyx, setFamilyx] = useState(true);
  const showFamily = () => {
    setFamilyx(!familyx);
  };

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const handleImagez = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // Maximum dimensions
        const maxWidth = 100;
        const maxHeight = 120;
        let newWidth = img.width;
        let newHeight = img.height;

        // Calculate the width and height, maintaining aspect ratio
        if (newWidth > maxWidth) {
          newHeight = (maxWidth / newWidth) * newHeight;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth = (maxHeight / newHeight) * newWidth;
          newHeight = maxHeight;
        }

        // Resize the image
        Resizer.imageFileResizer(
          file,
          newWidth,
          newHeight,
          "JPEG",
          100,
          0,
          async (uri) => {
            try {
              let { data } = await authFetch.post("/members/upload-image", {
                image: uri,
              });

              setImage(data);
            } catch (err) {
              console.error(err);
            }
          }
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <button className={style} onClick={showModal}>
        {labels}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Main</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid>
            <Row>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>General Information</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group
                      className="flex items-center justify-center flex-column"
                      style={{ paddingBottom: "3rem", paddingTop: "1rem" }}
                    >
                      <div style={{ paddingBottom: 20 }}>
                        {preview && (
                          <img
                            src={preview}
                            alt="Uploaded image"
                            width="150"
                            style={{
                              borderRadius: "50%",
                              paddingBottom: "1rem",
                            }}
                          />
                        )}
                      </div>
                      <Form.Label className="hed btn addfambut2 btn-block">
                        Upload Picture
                      </Form.Label>

                      <Form.Control
                        type="file"
                        value={mProfilePicture}
                        name="image"
                        onChange={handleImagez}
                        accept="image/*"
                        className="formvalue"
                        hidden
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">
                        First Name <span style={{ color: "#FE7064" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="firstname"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="forminput cnm"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">
                        Last Name <span style={{ color: "#FE7064" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="lastname"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="forminput cnm"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="hed">
                        Gender <span className="myast">*</span>
                      </p>

                      <Form.Check
                        type="radio"
                        label="Male"
                        name="gender"
                        aria-label="radio 1"
                        value="Male"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />

                      <Form.Check
                        type="radio"
                        label="Female"
                        name="gender"
                        aria-label="radio 2"
                        value="Female"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <p className="hed">
                        Status <span className="myast">*</span>
                      </p>
                      <Form.Check
                        type="radio"
                        label="Member"
                        name="status"
                        aria-label="radio 1"
                        value="Member"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                      />

                      <Form.Check
                        type="radio"
                        label="Visitor"
                        name="status"
                        aria-label="radio 1"
                        value="Visitor"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <div>
                      <Form.Label className="hed">Birth Date</Form.Label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="number"
                              min="1"
                              max="31"
                              value={birthday}
                              className="formvalue"
                              //handleChange={handleMemberInput}
                              onChange={(e) => setBirthday(e.target.value)}
                              name="birthday"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Select
                              value={birthMonth}
                              //handleChange={handleMemberInput}
                              onChange={(e) => setBirthMonth(e.target.value)}
                              name="birthMonth"
                              aria-label="Default select example"
                              className="formvalue"
                              style={{ width: 150 }}
                            >
                              <option>Month</option>

                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Form.Label className="hed">
                        Wedding Anniversary
                      </Form.Label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="number"
                              min="1"
                              max="31"
                              value={anniversary}
                              className="formvalue"
                              //handleChange={handleMemberInput}
                              onChange={(e) => setAnniversary(e.target.value)}
                              name="anniversary"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Select
                              value={anniversaryMonth}
                              // handleChange={handleMemberInput}
                              onChange={(e) =>
                                setAnniversaryMonth(e.target.value)
                              }
                              name="anniversaryMonth"
                              aria-label="Default select example"
                              className="formvalue"
                              style={{ width: 150 }}
                            >
                              <option>Month</option>

                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <Form.Group className="mb-3">
                      <p className="hed">Marital Status</p>
                      <Form.Check
                        type="radio"
                        label="Single"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Single"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Engaged"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Engaged"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Married"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Married"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Divorced"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Divorced"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Widowed"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Widowed"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Separated"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Separated"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Other"
                        name="maritalStatus"
                        aria-label="radio 1"
                        value="Other"
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Contact Information</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Mobile Phone <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter Mobile Phone"
                        value={mobilePhone}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setMobilePhone(e.target.value)}
                        name="mobilePhone"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Home Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Home Phone"
                        value={homePhone}
                        className="formvalue"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setHomePhone(e.target.value)}
                        name="homePhone"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Email Address <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="formvalue"
                        placeholder="Enter Email Address"
                        value={email}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Address <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        className="formvalue"
                        value={address}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setAddress(e.target.value)}
                        name="address"
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        City <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter City"
                        value={city}
                        className="formvalue"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setCity(e.target.value)}
                        name="city"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        State <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter State"
                        value={state}
                        //handleChange={handleMemberInput}
                        onChange={(e) => setState(e.target.value)}
                        name="state"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">
                        Postal Code <span className="myast">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="formvalue"
                        placeholder="Enter Postal Code"
                        value={postal}
                        //handleChange={handleMemberInput}
                        onChange={(e) => setPostal(e.target.value)}
                        name="postal"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Work Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Work Phone"
                        className="formvalue"
                        value={workPhone}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setWorkPhone(e.target.value)}
                        name="workPhone"
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Family</Accordion.Header>
                  <Accordion.Body>
                    {familyx ? <p className="formtext mt-5">Family</p> : null}
                    {familyx && (
                      <Form.Group className="mb-3">
                        <Form.Label className="hed">Family</Form.Label>
                        <Form.Select
                          value={mfamily}
                          name="mfamily"
                          onChange={(e) => setMfamily(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option>Select an Option</option>
                          <option value="Head of HouseHold">
                            Head of HouseHold
                          </option>
                          <option value="Spouse">Spouse</option>
                          <option value="Adult">Adult</option>
                          <option value="Youth">Youth</option>
                          <option value="Teenager">Teenager</option>
                          <option value="Child">Child</option>
                          <option value="Unassigned">Unassigned</option>
                        </Form.Select>
                      </Form.Group>
                    )}
                    {/* <div>
                        <Table striped bordered hover>
                          {!![...familyData, ...existMembers]?.length &&
                            [...familyData, ...existMembers]?.map(
                              (familyd, index) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{familyd.mfirstName}</td>
                                      <td>{familyd.mlastName}</td>
                                      <td>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Select
                                            value={familyd.mfamily}
                                            name="mfamily"
                                            //onChange={handleMemberInput}
                                            aria-label="Default select example"
                                            className="formvalue"
                                          >
                                            <option>{familyd.mfamily}</option>
                                            <option value="1">
                                              Head of Household
                                            </option>
                                            <option value="2">Spouse</option>
                                            <option value="3">Adult</option>
                                            <option value="4">Child</option>
                                            <option value="5">
                                              Unassigned
                                            </option>
                                          </Form.Select>
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Tooltip title="Delete">
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            className="dashicon"
                                            //onClick={() => removefam(index)}
                                          />
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              }
                            )}
                        </Table>
                      </div> */}
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Button
                          className="addfambut"
                          onClick={handleShow}
                          style={{ marginBottom: "1px" }}
                        >
                          Add Family Member
                        </Button>
                      </Form.Group> */}
                    {/* <Modal
                        show={show}
                        onHide={handleClose}
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title
                            style={{ fontSize: "100%", fontWeight: "600" }}
                          >
                            Add Family Members
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="flex flex-column items-center justify-center my-5">
                            <ButtonModal
                              labels="Add Family Member"
                              handler={handleFamilyData}
                              closemodal={handleClose}
                              className="mt-5"
                              style="dereerre"
                            />
                            <AddMemberModal
                              labels="Add Family Member"
                              handler={handleFamilyData}
                              closemodal={handleClose}
                              className="mt-5"
                              style="dereerre"
                            />
                            <p
                              className="text-center mt-3"
                              style={{ fontSize: "1em" }}
                            >
                              Add a new person to the database as a member of
                              this family
                            </p>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button className="dsfwfff" onClick={handleClose}>
                            Close
                          </button>
                        </Modal.Footer>
                      </Modal> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Social Media</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Facebook</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Facebook"
                        value={facebook}
                        name="facebook"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setFacebook(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Twitter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Twitter"
                        value={twitter}
                        name="twitter"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setTwitter(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">Instagram</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Instagram"
                        value={instagram}
                        name="instagram"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setInstagram(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="hed">LinkedIn</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter LinkedIn"
                        value={linkedin}
                        name="linkedin"
                        //handleChange={handleMemberInput}
                        onChange={(e) => setLinkedin(e.target.value)}
                        className="formvalue"
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => createPersons({ members })}>
            Create Member
          </Button> */}
          <Button onClick={createPersons}>Create Member</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ButtonModal;
