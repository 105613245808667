import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import "./addworkflow.css";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const DeleteWorkflow = ({
  show2,
  setShow2,
  selectedValue,
  refreshWorkflows,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const { logoutUser } = useAppContext();

  const handleDelete = async () => {
    const response = await authFetch.delete(`/workflow/${selectedValue._id}`);

    if (response.status === 200 || response.status === 201) {
      refreshWorkflows();
      handleClose2();
      toast(
        <CustomToastContent
          title="Success"
          message="Workflow Deleted."
          type="success"
        />
      );
    }
  };

  return (
    <Modal show={show2} onHide={handleClose2}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This action will permanently delete the selected workflow.
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose2}>
          Close
        </button>
        <button className="jiluil" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteWorkflow;
