import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { ChromePicker } from "react-color";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";

const Step4 = ({ formData, handleChange, handleCompletionChange }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser, updateColor, updateUser } =
    useAppContext();

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorPicker2, setDisplayColorPicker2] = useState(false);
  const [displayColorPicker3, setDisplayColorPicker3] = useState(false);
  const [primary, setPrimary] = useState(user.primaryColor);
  const [secondary, setSecondary] = useState(user.secondaryColor);
  const [accent, setAccent] = useState(user.accentColor);

  useEffect(() => {
    setPrimary(user.primaryColor);
    setSecondary(user.secondaryColor);
    setAccent(user.accentColor);
  }, [user.primaryColor, user.secondaryColor, user.accentColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClick2 = () => {
    setDisplayColorPicker2(!displayColorPicker2);
  };

  const handleClick3 = () => {
    setDisplayColorPicker3(!displayColorPicker3);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClose2 = () => {
    setDisplayColorPicker2(false);
  };

  const handleClose3 = () => {
    setDisplayColorPicker3(false);
  };

  const handleColorChange = (color, type) => {
    const newColor = color.hex;
    if (type === "primary") {
      setPrimary(newColor);
    } else if (type === "secondary") {
      setSecondary(newColor);
    } else if (type === "accent") {
      setAccent(newColor);
    }
    document.documentElement.style.setProperty(`--${type}-color`, newColor);
    handleChange({ target: { name: `${type}Color`, value: newColor } });
  };

  const handleUpdate = async (type, color) => {
    const data = {
      userId: user._id,
      colorType: `${type}Color`,
      colorValue: color,
    };
    updateColor({ data });
  };

  return (
    <div className="pt-4">
      <p className="text-lg text-bold pb-3">Church Branding</p>

      <div className="cusbb">
        <div
          style={{
            backgroundColor: primary,
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
          className="btest"
        >
          <div>
            <p style={{ marginBottom: "0px", fontWeight: "600" }}>
              Primary Color
            </p>
            <p style={{ marginBottom: "0px" }}>{primary}</p>
          </div>
          <div className="flex gap-3">
            <button
              className="jiluil"
              style={{ backgroundColor: primary }}
              onClick={handleClick}
            >
              Select Color
            </button>
            <button
              onClick={() => handleUpdate("primary", primary)}
              className="jiluil4"
              style={{ backgroundColor: "#858484", color: "#fff" }}
            >
              Add Color
            </button>
          </div>
        </div>
        {displayColorPicker && (
          <div className="popover">
            <div className="cover" onClick={handleClose} />
            <ChromePicker
              color={primary}
              onChangeComplete={(color) => handleColorChange(color, "primary")}
            />
          </div>
        )}
      </div>

      <div className="cusbb">
        <div
          style={{
            backgroundColor: secondary,
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
          className="btest"
        >
          <div>
            <p style={{ marginBottom: "0px", fontWeight: "600" }}>
              Secondary Color
            </p>
            <p style={{ marginBottom: "0px" }}>{secondary}</p>
          </div>
          <div className="flex gap-3">
            <button
              className="jiluil4"
              style={{ backgroundColor: secondary }}
              onClick={handleClick2}
            >
              Select Color
            </button>
            <button
              onClick={() => handleUpdate("secondary", secondary)}
              className="jiluil4"
              style={{ backgroundColor: "#858484", color: "#fff" }}
            >
              Add Color
            </button>
          </div>
        </div>
        {displayColorPicker2 && (
          <div className="popover">
            <div className="cover" onClick={handleClose2} />
            <ChromePicker
              color={secondary}
              onChangeComplete={(color) =>
                handleColorChange(color, "secondary")
              }
            />
          </div>
        )}
      </div>

      <div className="cusbb">
        <div
          style={{
            backgroundColor: accent,
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
          className="btest"
        >
          <div>
            <p style={{ marginBottom: "0px", fontWeight: "600" }}>
              Accent Color
            </p>
            <p style={{ marginBottom: "0px" }}>{accent}</p>
          </div>
          <div className="flex gap-3">
            <button
              className="jiluil5"
              style={{ backgroundColor: accent }}
              onClick={handleClick3}
            >
              Select Color
            </button>
            <button
              onClick={() => handleUpdate("accent", accent)}
              className="jiluil4"
              style={{ backgroundColor: "#858484", color: "#fff" }}
            >
              Add Color
            </button>
          </div>
        </div>
        {displayColorPicker3 && (
          <div className="popover">
            <div className="cover" onClick={handleClose3} />
            <ChromePicker
              color={accent}
              onChangeComplete={(color) => handleColorChange(color, "accent")}
            />
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <Form.Check
          type="checkbox"
          id="step4Complete"
          label="Mark as Completed"
          checked={formData.step4Completed}
          onChange={(e) => handleCompletionChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Step4;
