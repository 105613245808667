import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import frame from "../../assets/images/icon.png";
import SharedButton from "../../components/SharedButton/SharedButton";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="container2">
          <Col className="logindiv1">
            <form>
              <p className="welcometext">Reset Password</p>
              <p className="welcometext2">
                Enter your new password in the fields below
              </p>
              <div className="label">
                <input
                  type="password"
                  id="name"
                  placeholder="Name"
                  className="forminput"
                />
                <span className="myspanner">New Password</span>
              </div>
              <div className="label">
                <input
                  type="password"
                  id="password"
                  placeholder="Pas"
                  className="forminput"
                />
                <span className="myspanner"> Confirm Password</span>
              </div>

              <SharedButton
                style={{ className: "butonner" }}
                type="button"
                title="Update Password"
              />

              <br></br>
            </form>
          </Col>
          <Col className="logindiv2">
            <Image fluid src={frame} className="imgsizex" />
            <p>
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
