import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import axios from "axios";
import FollowUpx from "../../../components/FollowUp/FollowUpx";
import "./followup.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const AssignedFollowUp = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    uniquememberdetails,
    completemembers,
    getCompleteMembers,
    showAlert,

    getAllSettings,
    displayAlert,
    setting,
    user,
    logoutUser,

    followups,
    getAllFollowUp,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
    getAllFollowUp();
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedEdit, setSelectedEdit] = useState("");
  const handleShow1 = (item) => {
    setShow1(true);
    setSelectedValue(item);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  useEffect(() => {
    if (selectedEdit?.singleFollowUp?.followUpName) {
      setFollowName1(selectedEdit?.singleFollowUp?.followUpName);
    }
    if (selectedEdit?.singleFollowUp?.followUpAssignTo) {
      setFollowDesc1(selectedEdit?.singleFollowUp?.followUpAssignTo);
    }
    // if (selectedEdit?.singleFollowUp?.followupDate) {
    //   setFollowDate1(
    //     moment(selectedEdit.singleFollowUp.followupDate).format("YYYY-MM-DD")
    //   );
    // }
    if (selectedEdit?.singleFollowUp?.followupDate) {
      setFollowDate1(
        moment
          .utc(selectedEdit.singleFollowUp.followupDate)
          .format("YYYY-MM-DD")
      );
    }
    if (selectedEdit?.singleFollowUp?.followMedia) {
      setFollowHow1(selectedEdit?.singleFollowUp?.followMedia);
    }
    if (selectedEdit?.singleFollowUp?.followUpCompleteUpdatepDate) {
      setFollowComplete1(
        selectedEdit?.singleFollowUp?.followUpCompleteUpdatepDate
      );
    }
    if (selectedEdit?.singleFollowUp?.followReason) {
      setFollowReason1(selectedEdit?.singleFollowUp?.followReason);
    }
    if (selectedEdit?.singleFollowUp?.followNote) {
      setFollowNote1(selectedEdit?.singleFollowUp?.followNote);
    }
  }, [selectedEdit]);

  const getFollowUpById = async (followupId) => {
    try {
      const { data } = await authFetch(
        `/followup/getfollowupbyid/${followupId}`
      );
      if (data && followupId) {
        setSelectedEdit(data);
      }
    } catch (error) {
      logoutUser();
    }
  };

  const handleCheckboxChange1 = (e, value) => {
    if (e.target.checked) {
      setFollowReason1([...followReason1, value]);
    } else {
      setFollowReason1(followReason1.filter((item) => item !== value));
    }
  };

  const handleRadioChange1 = (e) => {
    setFollowHow1(e.target.value);
  };
  const handleShow2 = (followupId) => {
    setShow2(true);
    getFollowUpById(followupId);
  };

  const [filteredData, setFilteredData] = useState([]);
  const [followName, setFollowName] = useState();
  const [followDesc, setFollowDesc] = useState();
  const [followDate, setFollowDate] = useState();
  const [followHow, setFollowHow] = useState();
  const [followComplete, setFollowComplete] = useState();
  const [followReason, setFollowReason] = useState([]);
  const [followNote, setFollowNote] = useState();
  const [checkField, setCheckField] = useState(false);
  const [followName1, setFollowName1] = useState("");
  const [followDesc1, setFollowDesc1] = useState("");
  const [followDate1, setFollowDate1] = useState("");
  const [followHow1, setFollowHow1] = useState("");
  const [followComplete1, setFollowComplete1] = useState("");
  const [followReason1, setFollowReason1] = useState([]);
  const [followNote1, setFollowNote1] = useState("");
  const [memberIdOnly, setMemberIdOnly] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const [givingfilter, setGivingfilter] = useState([]);
  const [nameFlag, setNameFlag] = useState(true);
  const [viaFlag, setViaFlag] = useState(true);
  const [reasonFlag, setReasonFlag] = useState(true);
  const [assignedFlag, setAssignedFlag] = useState(true);
  const [targetDateFlag, setTargetDateFlag] = useState(true);
  const [statusFlag, setStatusFlag] = useState(true);
  const [error, setError] = useState(false);

  const handleRadioChange = (e) => {
    setFollowHow(e.target.value);
  };

  const handleCheckboxChange = (e, value) => {
    if (e.target.checked) {
      // If checkbox is checked, add the value to the selectedReasons array
      setFollowReason([...followReason, value]);
    } else {
      // If checkbox is unchecked, remove the value from the selectedReasons array
      setFollowReason(followReason.filter((reason) => reason !== value));
    }
  };

  useEffect(() => {
    const filtered = followups.filter(
      (item) => item.assignedBy === `${user.mfirstName} ${user.mlastName}`
    );
    setFilteredData(filtered);
  }, [followups, user.mfirstName, user.mlastName]);

  const myHandleSubmit = async (e) => {
    e.preventDefault();

    if (
      !followName ||
      !followDate ||
      !followDesc ||
      !followHow ||
      !followReason
    ) {
      toast(
        <CustomToastContent
          message="Please fill all required fields"
          title="error"
        />
      );
      return;
    }
    const [fname, lname] = followName?.split(" ");
    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;

    const checkIt = {
      followUpName: followName,
      followUpAssignTo: followDesc,
      followupDate: followDate,
      churchName: user?.churchName,
      mfname: fname,
      mlname: lname,
      mId: memberIdOnly?._id,
      followUpCompleteUpdate: followComplete,
      followMedia: followHow,
      followReason: followReason,
      followNote: followNote,
      assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
    };

    try {
      const response = await authFetch.post("/followup/assignto", checkIt);
      if (response.status === 200 || response.status === 201) {
        getAllFollowUp();
        setFollowName("");
        setFollowDesc("");
        setFollowDate("");
        setFollowComplete("");
        setFollowHow("");
        setFollowReason("");
        setFollowNote("");
        handleClose();
        toast(
          <CustomToastContent message="Follow-Up Assigned" title="Success" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    if (
      !followName1 ||
      !followDate1 ||
      !followDesc1 ||
      !followHow1 ||
      !followReason1
    ) {
      displayAlert();
      handleClose2();
      return;
    }
    const [fname, lname] = followName1?.split(" ");
    const { data } = await authFetch(
      `/members/getmemberidonly/${fname}/${lname}`
    );
    const [memberIdOnly] = data;
    setMemberIdOnly(memberIdOnly);

    const checkIt = {
      followUpName: followName1,
      followUpAssignTo: followDesc1,
      followupDate: followDate1,
      churchName: user?.churchName,
      mId: selectedEdit.singleFollowUp._id,
      followUpCompleteUpdatepDate: followComplete1 || false,
      followMedia: followHow1,
      followReason: followReason1,
      followNote: followNote1,
      completed: false,
      assignedBy: `${user?.mfirstName} ${user?.mlastName}`,
    };

    try {
      const response = await authFetch.patch(
        `/followup/assigntoupdate/${selectedEdit?.singleFollowUp?._id}`,
        checkIt
      );
      if (response.status === 200 || response.status === 201) {
        getAllFollowUp();
        setFollowName1("");
        setFollowDesc1("");
        setFollowDate1("");
        setFollowComplete1("");
        setFollowHow1("");
        setFollowReason1("");
        setFollowNote1("");
        handleClose2();
        toast(
          <CustomToastContent message="Follow-Up Updated" title="Success" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateSort = (e) => {
    let sorted = filteredData.sort((a, b) => {
      if (dateFlag) {
        return moment(b.postDate).unix() - moment(a.postDate).unix();
      } else {
        return moment(a.postDate).unix() - moment(b.postDate).unix();
      }
    });
    setGivingfilter(sorted);
    setDateFlag(!dateFlag);
  };

  const handleTargetDateSort = (e) => {
    let sorted = filteredData.sort((a, b) => {
      if (targetDateFlag) {
        return moment(b.followupDate).unix() - moment(a.followupDate).unix();
      } else {
        return moment(a.followupDate).unix() - moment(b.followupDate).unix();
      }
    });
    setGivingfilter(sorted);
    setTargetDateFlag(!targetDateFlag);
  };

  const handleNameSort = (e) => {
    if (nameFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followUpName.localeCompare(b.followUpName);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setNameFlag(!nameFlag);
  };

  const handleStatusSort = (e) => {
    if (statusFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.completed === b.completed ? 0 : a.completed ? -1 : 1;
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setStatusFlag(!statusFlag);
  };

  const handleViaSort = (e) => {
    if (viaFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followMedia.localeCompare(b.followMedia);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setViaFlag(!viaFlag);
  };

  const handleReasonSort = (e) => {
    if (reasonFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return (a.followReason[0] || "").localeCompare(b.followReason[0] || "");
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setReasonFlag(!reasonFlag);
  };

  const handleAssignedSort = (e) => {
    if (assignedFlag) {
      let sorted = filteredData.sort(function (a, b) {
        return a.followUpAssignTo.localeCompare(b.followUpAssignTo);
      });
      setGivingfilter(sorted);
    } else {
      let sorted = givingfilter.reverse();
      setGivingfilter(sorted);
    }
    setAssignedFlag(!assignedFlag);
  };

  const deleteFollowUp = async (followupId, alertText) => {
    try {
      const response = await authFetch.delete(
        `/followup/deletesinglefollowup/${followupId}`
      );
      if (response.status === 200 || response.status === 201) {
        getAllFollowUp();
        toast(
          <CustomToastContent message="Follow-Up Deleted" title="Success" />,
          {
            onClose: () => {
              handleClose1();
            },
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col>
            <p className="sahs">Assigned By Me</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          {setting && setting.length > 0 && (
            <button className="jiluil" onClick={handleShow}>
              Add Follow-Up
            </button>
          )}

          {setting && setting.length > 0 && (
            <div style={{ fontWeight: "600" }}>
              Total Follow-Ups Assigned By Me = {filteredData.length}
            </div>
          )}
        </Row>
        <Row className="fundc2">
          {setting && setting.length < 1 && (
            <div className="notification-prompt">
              To access this feature, create a reason for follow-up in the
              settings menu. Click{" "}
              <Link className="wqe44" to="/auth/followupsettings">
                here
              </Link>{" "}
              to get started.
            </div>
          )}
          {filteredData && filteredData.length > 0 ? (
            <Table hover responsive style={{ backgroundColor: "#F1F1F1" }}>
              <thead>
                <tr className="trdata">
                  <th onClick={handleDateSort} className="rwewwww">
                    Assigned Date {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleNameSort} className="rwewwww">
                    Member Name {nameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleViaSort} className="rwewwww">
                    Via {viaFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleReasonSort} className="rwewwww">
                    Reason {reasonFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleAssignedSort} className="rwewwww">
                    Assigned To {assignedFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>

                  <th onClick={handleStatusSort} className="rwewwww">
                    Status {statusFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>

              {filteredData &&
                filteredData?.map((item) => (
                  <tbody>
                    <tr key={item._id} className="trdata">
                      <td className="rwewwww">
                        {moment(item.createdAt).format("MMM DD, YYYY")}
                      </td>
                      <td className="rwewwww">{item.followUpName}</td>
                      <td className="rwewwww">{item.followMedia}</td>
                      <td className="rwewwww">
                        {item.followReason.join(", ")}
                      </td>
                      <td className="rwewwww">{item.followUpAssignTo}</td>

                      <td className="rwewwww">
                        {item.completed === true
                          ? "Closed"
                          : item.completed === false
                          ? "Opened"
                          : "Not applicable"}
                      </td>
                      <td className="rwewwww ">
                        <div className="flex flex-row gap-3">
                          <button
                            className="jiluil"
                            onClick={() => handleShow2(item._id)}
                          >
                            View
                          </button>{" "}
                          <button
                            className="jiluil3"
                            onClick={() => handleShow1(item)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          ) : (
            <div className="noDatax">
              {setting && setting > 0 && (
                <button className="noDataButton" onClick={handleShow}>
                  Create Follow-Up
                </button>
              )}
            </div>
          )}{" "}
        </Row>
      </Container>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Edit Follow-Up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Follow-up with</Form.Label>
              <Form.Control
                type="text"
                value={followName1}
                onChange={(e) => setFollowName1(e.target.value)}
                name="followName1"
                style={{ fontSize: "1em" }}
              />
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = followName1?.toLowerCase();
                    const fname = item.mfirstName?.toLowerCase();
                    const lname = item.mlastName?.toLowerCase();

                    const fullname = fname + lname;

                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        setFollowName1(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Assign to</Form.Label>
              <Form.Control
                type="text"
                value={followDesc1}
                onChange={(e) => setFollowDesc1(e.target.value)}
                name="followDesc1"
                style={{ fontSize: "1em" }}
              />
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = followDesc1?.toLowerCase();
                    const fname = item.mfirstName?.toLowerCase();
                    const lname = item.mlastName?.toLowerCase();

                    const fullname = fname + lname;

                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        setFollowDesc1(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check // prettier-ignore
                type="radio"
                label="Email"
                name="followHow1"
                value="Email"
                checked={followHow1 === "Email"}
                onChange={handleRadioChange1}
                aria-label="radio 1"
                required
              />
              <Form.Check // prettier-ignore
                type="radio"
                label="Phone Call"
                name="followHow1"
                value="Phone"
                checked={followHow1 === "Phone"}
                onChange={handleRadioChange1}
                aria-label="radio 1"
                required
              />

              <Form.Check // prettier-ignore
                type="radio"
                label="Visit"
                name="followHow1"
                value="Visit"
                checked={followHow1 === "Visit"}
                onChange={handleRadioChange1}
                aria-label="radio 1"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Reason</Form.Label>
              {setting &&
                setting.map((item, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={item.followUpName}
                    name="followReason"
                    value={item.followUpName} // Use the item's value as the checkbox value
                    checked={followReason1.includes(item.followUpName)}
                    onChange={(e) =>
                      handleCheckboxChange1(e, item.followUpName)
                    } // Pass the value
                  />
                ))}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>When</Form.Label>

              <Form.Control
                type="date"
                value={followDate1}
                onChange={(e) => setFollowDate1(e.target.value)}
                name="followDate1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={followNote1}
                // onChange={(e) => setFundId(e.target.value)}
                onChange={(e) => setFollowNote1(e.target.value)}
                name="followNote1"
                style={{ fontSize: "1em" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Confirm Completion"
                name="defaultFund"
                checked={followComplete1}
                onChange={(e) => setFollowComplete1(e.target.checked)}
                // onChange={(e) => setDefaultFund(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit1}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Delete Follow-Up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          This action cannot be reversed.
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose1} className="jiluil3">
            Close
          </button>
          <button
            // onClick={() => {
            //   deleteFollowUp(selectedValue._id, {
            //     alertText: "Follow-Up Deleted",
            //   });
            //   handleClose1();
            // }}
            className="jiluil"
            onClick={() => deleteFollowUp(selectedValue._id)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <FollowUpx
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myHandleSubmit={myHandleSubmit}
        uniquememberdetails={uniquememberdetails}
        setting={setting}
        followName={followName}
        setFollowName={setFollowName}
        followDesc={followDesc}
        setFollowDesc={setFollowDesc}
        followDate={followDate}
        setFollowDate={setFollowDate}
        followHow={followHow}
        setFollowHome={setFollowHow}
        followComplete={followComplete}
        setFollowComplete={setFollowComplete}
        handleRadioChange={handleRadioChange}
        checkField={checkField}
        setCheckField={setCheckField}
        followReason={followReason}
        setFollowReason={setFollowReason}
        handleCheckboxChange={handleCheckboxChange}
        followNote={followNote}
        setFollowNote={setFollowNote}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default AssignedFollowUp;
