import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const PublishAudio = ({
  show,
  setShow,
  selectedPlanner,
  publishedPlan,
  unpublishedPlan,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [planner, setPlanner] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/planner`);
    setPlanner(data);
  };

  const handlePublish = async () => {
    try {
      const response = await authFetch.put(
        `/learning/planner/publish/${selectedPlanner._id}`
      );
      if (response.status === 200 || response.status === 201) {
        loadCourse();
        handleClose5();
        publishedPlan();
        toast(
          <CustomToastContent
            message="Planner Published Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      const response = await authFetch.put(
        `/learning/planner/unpublish/${selectedPlanner._id}`
      );
      if (response.status === 200 || response.status === 201) {
        unpublishedPlan();
        loadCourse();
        handleClose5();

        toast(
          <CustomToastContent
            message="Planner Unpublished Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleClose5 = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          {selectedPlanner?.published ? "Unpublish Planner" : "Publish Planner"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        {selectedPlanner?.published
          ? "You are about to unpublish this planner. This planner will be removed from members view. Do you want to continue?"
          : "You are about to publish this planner. This planner will be visible by all members. Do you want to continue?"}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose5} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            selectedPlanner.published
              ? handleUnpublish(selectedPlanner)
              : handlePublish(selectedPlanner);
            handleClose5();
          }}
          className="jiluil"
        >
          {selectedPlanner?.published ? "Unpublish" : "Publish"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishAudio;
