import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import PaySubscription from "./PaySubscription";
import { Container, Row } from "react-bootstrap";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const SubPayment = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, logoutUser } = useAppContext();
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const {
    pkValue,
    skValue,
    churchName,
    email,
    amount,
    interval,
    date,
    category,
    currency,
  } = location.state || {};
  const [secretKey, setSecretKey] = useState(null);
  const [currencyx, setCurrency] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (pkValue) {
      // Initialize Stripe only when pkValue is available
      const stripe = loadStripe(pkValue);
      setStripePromise(stripe);
    }
  }, [pkValue]); // Re-initialize Stripe when pkValue changes

  useEffect(() => {
    if (skValue && currency) {
      // Initialize Stripe only when pkValue is available
      setSecretKey(skValue);
    }
  }, [skValue]); // Re-initialize Stripe when pkValue changes

  useEffect(() => {
    if (churchName) {
      getUser();
    }
  }, [churchName]);
  const getUser = async () => {
    try {
      const response = await authFetch.get(`/members/getuser/${churchName}`, {
        churchName: churchName,
      });

      setUserData(response.data);
      setCurrency(response.data.currency);
      // console.log(response.data); // Assuming you want to log the response data
    } catch (err) {
      console.error(err); // Using console.error for logging errors
    }
  };

  return (
    <Container fluid className="contWwainer2">
      <Row style={{ padding: "0", margin: "0" }}>
        <div className="flex items-center flex-column wrrerx">
          <div className="formdivfdssx">
            <div className="flex flex-column">
              <p className="awwq1 mb-4">Step 2</p>
              <p className="awwq mb-4">Secure Payment</p>
            </div>
            {stripePromise ? (
              <Elements stripe={stripePromise}>
                <PaySubscription
                  churchName={churchName}
                  email={email}
                  amount={amount}
                  interval={interval}
                  date={date}
                  category={category}
                  secretKey={skValue}
                  currency={currencyx}
                />
              </Elements>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default SubPayment;
