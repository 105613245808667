import React from "react";
import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./addtag.css";

const SetupTag = ({ show, setShow, tagId }) => {
  const quillRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getContainerById, uniquecontainer, SetupContainer } = useAppContext();
  const [containerPattern, setContainerPattern] = useState("");
  const [containerOccurence, setContainerOccurrence] = useState("");
  const [containerEndAfter, setContainerEndAfter] = useState("");
  const [containerEndBy, setContainerEndBy] = useState("");
  const [containerDay, setStartDate] = useState("");
  const [reStartTime, setReStartTime] = useState("");
  const [containerPreference, setContainerPreference] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (uniquecontainer) {
      setReStartTime(uniquecontainer?.reStartTime || "");
      setContainerPattern(uniquecontainer?.containerPattern || "");
      setContainerOccurrence(uniquecontainer?.containerOccurence || "");
      setContainerEndAfter(uniquecontainer?.containerEndAfter || "");
      setContainerEndBy(uniquecontainer?.containerEndBy || "");
      setStartDate(uniquecontainer?.containerDay || "");
      setContainerPreference(uniquecontainer?.containerPreference || "");
      setMessage(uniquecontainer.message || "");
    }
  }, [uniquecontainer]);

  useEffect(() => {
    if (quillRef.current && message) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.dangerouslyPasteHTML(message);
    }
  }, [message]);

  useEffect(() => {
    getContainerById(tagId);
  }, [tagId]);

  const handleSave = () => {
    const containerData = {
      containerPattern: containerPattern,
      containerOccurence: containerOccurence,
      containerEndAfter: containerEndAfter,
      containerEndBy: containerEndBy,
      containerDay: containerDay,
      containerPreference: containerPreference,
      reStartTime: reStartTime,
      message: message,
    };

    SetupContainer(tagId, containerData);
    setContainerPattern("");
    setContainerOccurrence("");
    setContainerEndAfter("");
    setContainerEndBy("");
    setContainerPreference("");
    setReStartTime("");
    setMessage("");
    handleClose();
  };

  const handleContainerPreference = (e) => {
    setContainerPreference(e.target.value);
  };

  const handleContainerPattern = (e) => {
    setContainerPattern(e.target.value);
  };

  const handleOccurence = (e) => {
    setContainerOccurrence(e.target.value);
  };
  const modules = {
    toolbar: {
      container: "#toolbar",
      "custom-dropdown": ["option1", "option2", "option3"],
      // Linking to our custom toolbar
    },
  };
  const handleDescriptionChange = (value) => {
    setMessage(value);
  };

  //console.log(uniquecontainer);
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{uniquecontainer?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modallsss">
          <Form.Label className="text-bold">Send</Form.Label>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Email"
            value="Email"
            onChange={handleContainerPreference}
            checked={containerPreference === "Email"}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Text"
            value="Text"
            onChange={handleContainerPreference}
            checked={containerPreference === "Text"}
          />
        </div>
        <div className="modallsss mt-3">
          <Form.Label className="text-bold">Container Pattern</Form.Label>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check // prettier-ignore
              type="radio"
              label="Daily"
              name="containerPattern"
              value="Daily"
              checked={containerPattern === "Daily"}
              onChange={handleContainerPattern}
              aria-label="radio 1"
              required
            />
            <Form.Check // prettier-ignore
              type="radio"
              label="Weekly"
              name="containerPattern"
              value="Weekly"
              checked={containerPattern === "Weekly"}
              onChange={handleContainerPattern}
              aria-label="radio 1"
              required
            />

            <Form.Check // prettier-ignore
              type="radio"
              label="Monthly"
              name="containerPattern"
              value="Monthly"
              checked={containerPattern === "Monthly"}
              onChange={handleContainerPattern}
              aria-label="radio 1"
              required
            />

            <Form.Check // prettier-ignore
              type="radio"
              label="Yearly"
              name="containerPattern"
              value="Yearly"
              checked={containerPattern === "Yearly"}
              onChange={handleContainerPattern}
              aria-label="radio 1"
              required
            />
          </Form.Group>
        </div>
        <div className="modallsss mt-3">
          <Form.Group className="mb-3 eeesss" controlId="formBasicEmail">
            <Form.Label className="text-bold">Start Time:</Form.Label>
            <Form.Control
              type="time"
              value={reStartTime}
              onChange={(e) => setReStartTime(e.target.value)}
              name="reStartTime"
              style={{ fontSize: "1em" }}
            />
          </Form.Group>
        </div>

        <div className="modallsss mt-3">
          <Form.Label className="text-bold">Event Occurrences</Form.Label>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Start Date</Form.Label>

            <Form.Control
              type="date"
              name="containerDay"
              value={containerDay}
              required
              onChange={(e) => setStartDate(e.target.value)}
              className="nmdw2"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check // prettier-ignore
              type="radio"
              label="No end date"
              name="containerOccurence"
              value="No end date"
              checked={containerOccurence === "No end date"}
              onChange={handleOccurence}
              aria-label="radio 1"
              required
            />

            <Form.Check // prettier-ignore
              type="radio"
              label="End after"
              name="containerOccurence"
              value="End after"
              checked={containerOccurence === "End after"}
              onChange={handleOccurence}
              aria-label="radio 1"
              required
            />

            <Form.Check // prettier-ignore
              type="radio"
              label="End by"
              name="containerOccurence"
              value="End by"
              checked={containerOccurence === "End by"}
              onChange={handleOccurence}
              aria-label="radio 1"
              required
            />
          </Form.Group>
        </div>
        <div>
          {" "}
          {containerOccurence === "No end date" ? (
            ""
          ) : (
            <div>
              {containerOccurence === "End after" && (
                <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                  <Form.Label>End after</Form.Label>
                  <Form.Control
                    type="text"
                    value={containerEndAfter}
                    onChange={(e) => setContainerEndAfter(e.target.value)}
                    name="containerEndAfter"
                    style={{ fontSize: "1em" }}
                  />
                  <p>occurrences</p>
                </Form.Group>
              )}
              {containerOccurence === "End by" && (
                <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                  <Form.Label>End By:</Form.Label>
                  <Form.Control
                    type="date"
                    value={containerEndBy}
                    onChange={(e) => setContainerEndBy(e.target.value)}
                    name="containerEndBy"
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
              )}
            </div>
          )}
        </div>

        {containerOccurence === "End by" &&
          containerDay &&
          reStartTime &&
          containerEndBy &&
          containerPattern && (
            <p className="mt-3">
              Events starts on {containerDay} at {reStartTime}. It will occur{" "}
              {containerPattern} and will end by {containerEndBy}
            </p>
          )}

        {containerOccurence === "End after" &&
          containerDay &&
          reStartTime &&
          containerEndAfter &&
          containerPattern && (
            <p className="mt-3">
              Events starts on {containerDay} at {reStartTime}. It will occur{" "}
              {containerPattern} and will end after {containerEndAfter}{" "}
              occurrences
            </p>
          )}

        {containerOccurence === "No end date" &&
          containerDay &&
          reStartTime &&
          containerPattern &&
          containerOccurence === "No end date" && (
            <p className="mt-3">
              Events starts on {containerDay} at {reStartTime}. It will occur{" "}
              {containerPattern} with no end date{" "}
            </p>
          )}

        <div className="modallsss mt-3">
          <div id="toolbar" className="flex items-center">
            <button className="ql-bold">Bold</button>
            <button className="ql-italic">Italic</button>
            <button className="ql-underline">Underline</button>
            <button className="ql-header" value="1">
              Header 1
            </button>
            <button className="ql-header" value="2">
              Header 2
            </button>
            <button className="ql-list" value="ordered">
              Ordered List
            </button>
            <button className="ql-list" value="bullet">
              Bullet List
            </button>
            <button className="ql-link">Link</button>
            <button className="ql-image">Image</button>
          </div>
          <ReactQuill
            ref={quillRef}
            value={message}
            onChange={handleDescriptionChange}
            theme="snow"
            className="react-quill-editor"
            placeholder="Enter a message here..."
            modules={modules}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetupTag;
