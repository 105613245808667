import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import { useState } from "react";

import ViewDocumentCard from "./ViewDocumentCard";
const DocumentCard = ({ document }) => {
  const { documentName, documentFile, slug, createdAt } = document;
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [show, setShow] = useState(false);

  const handleView = (e, document) => {
    setSelectedDocument(document);
    setShow(true);
  };

  return (
    <div
      onClick={(e) => handleView(e, document)}
      className="pointer"
      style={{ textDecoration: "none" }}
    >
      <button
        style={{
          textDecoration: "none",
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
      >
        <Card className="mian">
          {/* <div className="pklkm">
            <img
              src={image?.Location}
              alt={name}
              style={{ height: "166px", objectFit: "cover" }}
              className="p-1"
            />
          </div> */}
          <Card.Body>
            <Card.Title className="courseTitle">
              {document.documentName.length > 50
                ? `${document.documentName.substring(0, 50)}...`
                : document.documentName}
            </Card.Title>
            <small className="text-muted">
              posted in {moment(createdAt).format("MMMM YYYY")}
            </small>
          </Card.Body>
        </Card>
      </button>
      <ViewDocumentCard
        selectedDocument={selectedDocument}
        setShow={setShow}
        show={show}
        handleClose={() => setShow(false)}
      />
    </div>
  );
};

export default DocumentCard;
