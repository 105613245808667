import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./register.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PasswordUpdateSuccess = () => {
  const navigate = useNavigate();
  const loginHandle = () => {
    navigate("/login");
  };
  return (
    <>
      <Header />
      <Container fluid className="passwordset">
        <Row>
          <p className="passwordresetheader">Password Change Successful!</p>
          <p>
            Your password has been successfully updated. A confirmation email
            has been sent to your inbox. If it’s not in your inbox, please check
            your spam folder. If problems persist, it’s possible you registered
            with a different email address.
          </p>
        </Row>
        <Row className="flex justify-center">
          <Button
            type="button"
            className="buttonner mt-5"
            onClick={loginHandle}
          >
            Back to Login
          </Button>
          {/* <img
            src="https://emailtemplateimageease.s3.amazonaws.com/mailsent.png"
            alt="Check Inbox"
            height="500"
          /> */}
        </Row>
      </Container>
    </>
  );
};

export default PasswordUpdateSuccess;
