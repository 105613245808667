import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./register.css";

const ResetPasswordSent = () => {
  return (
    <>
      <Header />
      <Container fluid className="passwordset">
        <Row>
          <p className="passwordresetheader">Check your inbox!</p>
          <p>
            We've sent a password reset notice to your email. If you don't see
            it, please check your spam folder. If you still encounter issues,
            it's possible you registered with a different email address.
          </p>
        </Row>
        <Row className="flex justify-center">
          <img
            src="https://emailtemplateimageease.s3.amazonaws.com/mailsent.png"
            alt="Check Inbox"
            height="500"
          />
        </Row>
      </Container>
    </>
  );
};

export default ResetPasswordSent;
