import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./main.css";
import { useNavigate } from "react-router-dom";
import {
  FaLockOpen,
  FaLink,
  FaMobile,
  FaBook,
  FaNetworkWired,
  FaSearch,
  FaAlignJustify,
  FaDatabase,
  FaMoneyBill,
} from "react-icons/fa";

const Offerings = () => {
  const navigate = useNavigate();
  const handleStartFreeTrial = () => {
    navigate("/signuptrial");
  };
  return (
    <Row className="offerings">
      <div>
        <p className="offering-text">
          Here’s what you get with Churchease Free Trial:
        </p>
        <p className="offering-textrrr">
          Empower every role in your church with a unified system that
          streamlines and enhances your ministry.
        </p>
      </div>
      <div className="offerings-container">
        <Col md={4} className="offering">
          <FaLockOpen className="metriccheck" />
          <p className="offering-titlexx">Unrestricted Access</p>
          <p>
            Enjoy full access to all features and tools without any limitations
            during the trial period.
          </p>
        </Col>
        <Col md={4} className="offering">
          <FaLink className="metriccheck" />
          <p className="offering-titlexx">Workflow Automation</p>
          <p>
            Access inspiring daily devotionals directly within the app,
            scheduled and published seamlessly.
          </p>
        </Col>

        <Col md={4} className="offering">
          <FaMobile className="metriccheck" />
          <p className="offering-titlexx">Mobile App Access</p>
          <p>
            Provide your members with a user-friendly mobile app to stay
            connected and engaged on the go.
          </p>
        </Col>
      </div>
      <div className="offerings-container">
        <Col md={4} className="offering">
          <FaBook className="metriccheck" />
          <p className="offering-titlexx">Daily Devotional</p>
          <p>
            Access inspiring daily devotionals directly within the app,
            scheduled and published seamlessly.
          </p>
        </Col>
        <Col md={4} className="offering">
          <FaNetworkWired className="metriccheck" />
          <p className="offering-titlexx">Integrated Communication</p>
          <p>
            Achieve faster communication with your congregation through
            integrated SMS and email.
          </p>
        </Col>
        <Col md={4} className="offering">
          <FaSearch className="metriccheck" />
          <p className="offering-titlexx">Advanced Search Capability</p>
          <p>
            Quickly find members, events, and contributions with powerful search
            functionality.
          </p>
        </Col>
      </div>

      <div className="offerings-container">
        <Col md={4} className="offering">
          <FaMoneyBill className="metriccheck" />
          <p className="offering-titlexx">Contribution Management</p>
          <p>
            Track and manage donations efficiently, ensuring transparency and
            accuracy.
          </p>
        </Col>
        <Col md={4} className="offering">
          <FaAlignJustify className="metriccheck" />
          <p className="offering-titlexx">Customizable Reports</p>
          <p>
            Generate reports on attendance, contributions, and more to make
            informed decisions.
          </p>
        </Col>
        <Col md={4} className="offering">
          <FaDatabase className="metriccheck" />
          <p className="offering-titlexx">Secure Data Storage</p>
          <p>
            Keep your church’s data safe with robust security measures and
            reliable cloud storage.
          </p>
        </Col>
      </div>
      {/* <div>
        <p
          style={{
            textAlign: "center",
            paddingTop: "50px",
            fontSize: "1.5em",
            textDecoration: "underline",
            color: "var(--primary-color)",
            cursor: "pointer",
          }}
          onClick={handleStartFreeTrial}
        >
          Much More
        </p>
      </div> */}
    </Row>
  );
};

export default Offerings;
