import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { currencyFormatter } from "../../../utils/helpers";
import nodd from "../../../assets/newi/nodd.png"; // Adjust the import path according to your project structure
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";

const AccountStatement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const componentRef = useRef();
  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Account Statement",
  });

  const { logoutUser, user } = useAppContext();
  const [aggregatedData, setAggregatedData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [dateFlag, setDateFlag] = useState(false);
  const [amountFlag, setAmountFlag] = useState(false);

  useEffect(() => {
    setCurrency(user.currency);
  }, [user]);

  const getSummaryFinancials = async () => {
    try {
      const response = await authFetch.get("/giving/income-summary");
      console.log(response.data);
      setAggregatedData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSummaryExpense = async () => {
    try {
      const response = await authFetch.get("/giving/expense-summary");
      console.log(response.data);
      setExpenseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const totalAmount = aggregatedData.reduce((accumulator, transaction) => {
    return accumulator + transaction.totalAmount;
  }, 0);

  const totalExpense = expenseData.reduce((accumulator, expense) => {
    return accumulator + expense.totalAmount;
  }, 0);

  useEffect(() => {
    getSummaryFinancials();
    getSummaryExpense();
    setCurrency(user.currency);
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleFilter = async () => {
    const response = await authFetch.get("/giving/income-summary", {
      params: { startDate, endDate },
    });
    setAggregatedData(response.data);
  };

  const handlePrint = () => {
    printView();
  };
  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Account Statement</div>
        <div className="bannersmall">
          Simplify the task of overseeing your church's finances with our
          Account Statement module. Access a consolidated view of contributions,
          expenses, and your church's financial standing for effective and
          informed financial management.
        </div>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo" style={{ gap: "10px" }}>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
            />
            <button onClick={handleFilter}>Apply Filters</button>
          </div>
          <button className="jiluil">
            <CSVLink
              data={filteredData}
              filename="account-statement.csv"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Export
            </CSVLink>
          </button>
          <button className="jiluil3" onClick={handlePrint}>
            Print
          </button>
        </div>
      </Row>
      {/* Contributions Summary */}
      <Row className="accountmain">
        <p className="accounttext">Contributions Summary</p>
        {aggregatedData.length > 0 ? (
          <Table bordered className="accounttable">
            <thead>
              <tr>
                <th className="err34">Category</th>
                <th className="err34">Contributions</th>
                <th className="err34">Year</th>
                <th className="err34">Month</th>
              </tr>
            </thead>
            <tbody>
              {aggregatedData.map((data, index) => (
                <tr key={index}>
                  <td>{data._id.category}</td>
                  <td>
                    {currencyFormatter({
                      amount: data.totalAmount,
                      currency: currency,
                    })}
                  </td>
                  <td>{data._id.year}</td>
                  <td>{data._id.month}</td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>
                  {currencyFormatter({
                    amount: totalAmount,
                    currency: currency,
                  })}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div className="noData">
            <img src={nodd} alt="No data available" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>

      {/* Expense Summary */}
      <Row className="accountmain">
        <p className="accounttext">Expense Summary</p>
        {expenseData.length > 0 ? (
          <Table bordered className="accounttable">
            <thead>
              <tr>
                <th className="err34">Category</th>
                <th className="err34">Expense</th>
                <th className="err34">Year</th>
                <th className="err34">Month</th>
              </tr>
            </thead>
            <tbody>
              {expenseData.map((data, index) => (
                <tr key={index}>
                  <td>{data._id.expenseCategory}</td>
                  <td>
                    {currencyFormatter({
                      amount: data.totalAmount,
                      currency: currency,
                    })}
                  </td>
                  <td>{data._id.year}</td>
                  <td>{data._id.month}</td>
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>
                  {currencyFormatter({
                    amount: totalExpense,
                    currency: currency,
                  })}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div className="noData">
            <img src={nodd} alt="No data available" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>

      {/* Account Total */}
      <Row className="accountmain">
        <div className="flex flex-row accounttext gap-5">
          <p>Account Total:</p>
          <p>
            {currencyFormatter({
              amount: totalAmount - totalExpense,
              currency: currency,
            })}
          </p>
        </div>
      </Row>
      <Row className="bottomSpacer"></Row>
    </Container>
  );
};

export default AccountStatement;
