import { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const MyLearning = () => {
  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [courses, setCourses] = useState([]);

  const fetchCourse = async () => {
    let { data } = await authFetch.get(`/learning/mylearning`);

    setCourses(data);
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  return (
    <Container fluid className="courseViewAllCourses">
      <Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
        <div className="row pt-5">
          <p className="coursetext">My Courses</p>
          {courses && courses.length > 0 ? (
            <div className="courseContainer">
              {courses.map((course) => (
                <Link
                  key={course?._id}
                  to={`/auth/singlecourseview/${course?.slug}`}
                  className="courseLink"
                >
                  <div className="col-md-4 courseCardContainer">
                    <Card className="mian">
                      <div className="pklkm">
                        <img
                          src={
                            course.image ? course.image.Location : "/course.png"
                          }
                          alt="Course Image"
                          className="courseImage"
                        />
                      </div>
                      <Card.Body>
                        <Card.Title className="courseTitle">
                          {course.name.length > 50
                            ? `${course.name.substring(0, 50)}...`
                            : course.name}
                        </Card.Title>
                        <p className="lessonCount">
                          {course?.lessons?.length} lessons
                        </p>
                      </Card.Body>
                    </Card>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default MyLearning;
