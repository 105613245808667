import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "./people.css";
import CreatePeopleLeft from "./CreatePeopleLeft";
import CreatePeopleMiddle from "./CreatePeopleMiddle";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";

const CreatePeople = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, logoutUser } = useAppContext();
  const [visibilitySettings, setVisibilitySettings] = useState({});

  useEffect(() => {
    fetchVisibilitySettings();
  }, []);

  const fetchVisibilitySettings = async () => {
    try {
      const response = await authFetch(`/members/fieldsvisibility/${user._id}`);
      if (response.status === 200 || response.status === 201) {
        const settings = response.data;
        setVisibilitySettings(settings);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Toggle function to update the visibility state and send to the backend
  const toggleField = async (fieldName) => {
    // Toggle the value locally first
    const newValue = !visibilitySettings[fieldName];

    // Update the local state
    setVisibilitySettings({
      ...visibilitySettings,
      [fieldName]: newValue,
    });

    // Send the update to the backend
    try {
      await authFetch.put(`/members/fieldsvisibility/${user._id}`, {
        field: fieldName,
        value: newValue,
      });
    } catch (error) {
      console.error("Failed to update field visibility on the server", error);
    }
  };

  // Dynamic rendering of fields based on visibilitySettings
  const fields = {
    middleNamex: visibilitySettings.middleName || false,
    otherPhonex: visibilitySettings.otherPhoneNumber || false,
    workEmailx: visibilitySettings.mworkEmail || false,
    additionalNotesx: visibilitySettings.additionalNotes || false,
    notex: visibilitySettings.firstNotes || false,
    statusx: visibilitySettings.status || false,
    maritalx: visibilitySettings.maritalStatus || false,

    familyx: visibilitySettings.mfamily || false,
    agex: visibilitySettings.mBirthday || false,
    anniversaryx: visibilitySettings.anniversary || false,
    gradex: visibilitySettings.mgrade || false,
    schoolx: visibilitySettings.mschool || false,
    employerx: visibilitySettings.memployer || false,
    workPhonex: visibilitySettings.mworkPhone || false,
    homePhonex: visibilitySettings.mhomePhone || false,
    // mobilePhonex: visibilitySettings.mobilePhone || false,
    facebookx: visibilitySettings.facebook || false,
    twitterx: visibilitySettings.twitter || false,
    instagramx: visibilitySettings.instagram || false,
    linkedinx: visibilitySettings.linkedin || false,
  };

  return (
    <Container fluid className="create-container flex flex-row">
      <Col className="dashboard-left" sm={3}>
        <CreatePeopleLeft
          {...fields} // Spread the fields dynamically to CreatePeopleLeft
          showMiddleName={() => toggleField("middleName")}
          showOtherPhone={() => toggleField("otherPhoneNumber")}
          showWorkEmail={() => toggleField("mworkEmail")}
          showAdditionalNotes={() => toggleField("additionalNotes")}
          showNotes={() => toggleField("firstNotes")}
          showFamily={() => toggleField("mfamily")}
          showAge={() => toggleField("mBirthday")}
          showAnniversary={() => toggleField("anniversary")}
          showGrade={() => toggleField("mgrade")}
          showSchool={() => toggleField("mschool")}
          showEmployer={() => toggleField("memployer")}
          showWork={() => toggleField("mworkPhone")}
          showHome={() => toggleField("mhomePhone")}
          showFacebook={() => toggleField("facebook")}
          showTwitter={() => toggleField("twitter")}
          showInstagram={() => toggleField("instagram")}
          showLinkedin={() => toggleField("linkedin")}
          middleNamex={fields.middleNamex} // Make sure this is passed
          additionalNotesx={fields.additionalNotesx} // Make sure this is passed
          notex={fields.notex} // Make sure this is passed
          workPhonex={fields.workPhonex} // Make sure this is passed
          homePhonex={fields.homePhonex} // Make sure this is passed
          otherPhonex={fields.otherPhonex} // Make sure this is passed
          workEmailx={fields.workEmailx} // Make sure this is passed
          facebookx={fields.facebookx} // Make sure this is passed
          twitterx={fields.twitterx} // Make sure this is passed
          instagramx={fields.instagramx} // Make sure this is passed
          linkedinx={fields.linkedinx}
          employerx={fields.employerx}
          schoolx={fields.schoolx}
          gradex={fields.gradex}
          anniversaryx={fields.anniversaryx}
          familyx={fields.familyx}
          agex={fields.agex}
        />
      </Col>
      <Col className="create-middle" sm={8}>
        <CreatePeopleMiddle {...fields} />
      </Col>
    </Container>
  );
};

export default CreatePeople;
