import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaCalendar, FaMoneyBill } from "react-icons/fa";
import l1 from "../../assets/newi/l1.png";
import l2 from "../../assets/newi/l2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductEvents = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Event Management | Churchease - Organize and Manage Church Events
        </title>
        <meta
          name="description"
          content="Effortlessly organize and manage church events with Churchease. From registrations to logistics, our Events module simplifies every step, enhancing participant engagement and ensuring every event is memorable and well-coordinated."
        />
        <link rel="canonical" href="https://churchease.com/product/events" />
        <meta
          property="og:title"
          content="Event Management | Churchease - Organize and Manage Church Events"
        />
        <meta
          property="og:description"
          content="Effortlessly organize and manage church events with Churchease. From registrations to logistics, our Events module simplifies every step, enhancing participant engagement and ensuring every event is memorable and well-coordinated."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/events"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#FE7064" }}>
            <div>
              <FaCalendar size={100} />
              <p className="headssc">Events</p>
              <p className="headpp">
                Effortlessly organize and manage church events. From
                registrations to logistics, our Events module simplifies every
                step. The intuitive system enhances participant engagement and
                ensures every event is memorable and well-coordinated.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for event management
            </p>
            <p className="product13">
              Your ultimate planner for organizing, executing, and enhancing
              church events, ensuring every gathering is a success.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={l1} alt="Events" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">EVENT SCHEDULING</p>
              <p className="proddz1">
                Plan and schedule church events with an easy-to-use calendar
                that ensures every detail is covered.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">REGISTRATION MANAGEMENT</p>
              <p className="proddz1">
                Manage event registrations smoothly with tools that handle
                attendee data, payments, and confirmations.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RESOURCE ALLOCATION</p>
              <p className="proddz1">
                Allocate and manage resources effectively, ensuring that every
                event is well-equipped and runs smoothly.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">VOLUNTEER COORDINATION</p>
              <p className="proddz1">
                Coordinate volunteer roles and schedules seamlessly, enhancing
                the event experience for all involved.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">FEEDBACK COLLECTION</p>
              <p className="proddz1">
                Collect and analyze feedback from events to continuously improve
                and cater to community needs.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">REAL-TIME UPDATES</p>
              <p className="proddz1">
                Keep participants informed with real-time updates before,
                during, and after events.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={l2} alt="Events" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#FE7064" />
      <Footer />
    </>
  );
};

export default ProductEvents;
