import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../../context/appContext";
import YouTube from "react-youtube";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddLivestream from "../../../components/Livestream/AddLivestream";
import axios from "axios";
import { Table, ToastBody } from "react-bootstrap";
import nodd from "../../../assets/newi/nodd.png";
import DeleteLivestream from "../../../components/Livestream/DeleteLivestream";
import EditLivestream from "../../../components/Livestream/EditLivestream";
import PublishLivestream from "../../../components/Livestream/PublishLivestream";
import ViewLivestream from "../../../components/Livestream/ViewLivestream";
import AddThumbnail from "../../../components/Livestream/AddThumbnail";
import AddRecentMessage from "../../../components/Livestream/AddRecentMessage";
import OnboardingTour from "../../../components/OnboardTour/OnboardTour";
import ViewLivestreamRecent from "../../../components/Livestream/ViewLivestreamRecent";
import DeleteLivestreamRecent from "../../../components/Livestream/DeleteLivestreamRecent";
import EditLivestreamRecent from "../../../components/Livestream/EditLivestreamRecent";
import PublishLivestreamRecent from "../../../components/Livestream/PublishLivestreamRecent";

const Livestream = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  const [show22, setShow22] = useState(false);

  const [show33, setShow33] = useState(false);

  const [show44, setShow44] = useState(false);

  const [show55, setShow55] = useState(false);
  const [selectedLivestream, setSelectedLivestream] = useState(null);
  const { logoutUser } = useAppContext();
  const [selectedRecentLiveStream, setSelectedRecentLiveStream] = useState([]);

  const [pickedRecentLiveStream, setPickedRecentLiveStream] = useState({});

  const [liveStream, setLiveStream] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/livestream`);
    setLiveStream(data);
  };

  const loadLiveStream = async () => {
    const { data } = await authFetch.get(`/livestream/recent/data`);
    setSelectedRecentLiveStream(data);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  useEffect(() => {
    loadLiveStream();
  }, []);

  // Event handling

  const addLiveProgram = () => {
    setShow(true);
  };

  const uploadSuccessFunc = () => {
    loadCourse();
  };
  const uploadSuccessFuncxx = () => {
    loadLiveStream();
  };
  const today = new Date();
  const handleShow = async (event, live) => {
    setShow2(true);
    setSelectedLivestream(live);
  };

  const handleEdit = async (event, live) => {
    setShow3(true);
    setSelectedLivestream(live);
  };

  const handleShow5 = async (event, live) => {
    setShow4(true);
    setSelectedLivestream(live);
  };

  const handleView = async (event, live) => {
    setShow5(true);
    setSelectedLivestream(live);
  };

  const handleViewRecent = async (event, live) => {
    setShow55(true);
    setPickedRecentLiveStream(live);
  };

  const handleEditRecent = async (event, live) => {
    setShow33(true);
    setPickedRecentLiveStream(live);
  };

  const handleShow5Recent = async (event, live) => {
    setShow44(true);
    setPickedRecentLiveStream(live);
  };

  const handleDeleteRecent = async (event, live) => {
    setShow22(true);
    setPickedRecentLiveStream(live);
  };

  const publishedLivestream = () => {
    loadCourse();
  };

  const unpublishedLivestream = () => {
    loadCourse();
  };

  const publishLivestreamRecent = () => {
    loadLiveStream();
  };

  const unpublishLivestreamRecent = () => {
    loadLiveStream();
  };

  const addThumbnail = () => {
    setShow6(true);
  };

  const addRecentMessage = () => {
    setShow7(true);
  };

  // Format date to "month day, year"
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Convert string to sentence case
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
  };

  return (
    <Container fluid>
      <OnboardingTour />
      <Row className="courseheader">
        <Col>
          <p className="sahs">Livestream</p>
        </Col>
      </Row>
      <Row className="sssssssecwef" style={{ gap: "10px" }}>
        <button className="jiluil5" onClick={addRecentMessage}>
          Post Recent Message
        </button>
        <button className="jiluil4" onClick={addThumbnail}>
          Add Livestream Thumbnail
        </button>

        <button className="jiluil" onClick={addLiveProgram}>
          Start a Livestream
        </button>
      </Row>
      <Row>
        <div>
          {liveStream && liveStream.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1em",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {liveStream.length ? (
                  <p>
                    {liveStream.length} record
                    {liveStream.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Saved View</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                {liveStream.map((live, index) => (
                  <tbody>
                    <tr key={index} className="trdata">
                      <td>{live.eventTitle}</td>
                      <td>{live.startTime}</td>
                      <td>{live.endTime}</td>
                      <td>
                        {live.startTime && new Date(live.endTime) <= today
                          ? "Livestream ended"
                          : "Livestream in progress"}
                      </td>
                      <td className="rightco">
                        <button
                          className="q3rq3r23"
                          onClick={(event) => handleView(event, live)}
                        >
                          View
                        </button>
                        <button
                          className="q3rq3r23"
                          onClick={(event) => handleEdit(event, live)}
                        >
                          Edit
                        </button>

                        <button
                          className={live.published ? "jiluil3" : "jiluil"}
                          onClick={(event) => handleShow5(event, live)}
                        >
                          {live.published ? "Unpublish" : "Publish"}
                        </button>

                        <button
                          className="jiluil2"
                          onClick={(event) => handleShow(event, live)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </Row>
          ) : (
            <div>
              <p
                className="noData "
                style={{
                  paddingTop: "40px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                In Progress Livestreams
              </p>
              <Row className="tabw">
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  <tbody>
                    <tr>
                      <td colSpan="5" className="noData">
                        There are no ongoing livestreams
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </div>
          )}
        </div>
      </Row>

      <Row>
        <div>
          {selectedRecentLiveStream && selectedRecentLiveStream.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1em",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {selectedRecentLiveStream.length ? (
                  <p>
                    {selectedRecentLiveStream.length} record
                    {selectedRecentLiveStream.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Saved View</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr>
                    <th>Posted Date</th>
                    <th>Title</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRecentLiveStream.map((live, index) => (
                    <tr key={index} className="trdata">
                      <td>{formatDate(live.createdAt)}</td>
                      <td>{toTitleCase(live.title)}</td>

                      <td className="rightco">
                        <button
                          className="q3rq3r23"
                          onClick={(event) => handleViewRecent(event, live)}
                        >
                          View
                        </button>
                        <button
                          className="q3rq3r23"
                          onClick={(event) => handleEditRecent(event, live)}
                        >
                          Edit
                        </button>
                        <button
                          className={live.published ? "jiluil3" : "jiluil"}
                          onClick={(event) => handleShow5Recent(event, live)}
                        >
                          {live.published ? "Unpublish" : "Publish"}
                        </button>
                        <button
                          className="jiluil2"
                          onClick={(event) => handleDeleteRecent(event, live)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          ) : (
            <div>
              <p
                className="noData "
                style={{
                  paddingTop: "40px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                Recent Livestreams
              </p>
              <Row className="tabw">
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  <tbody>
                    <tr>
                      <td colSpan="5" className="noData">
                        There are no recent livestream listings.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </div>
          )}
        </div>
      </Row>

      <AddLivestream
        show={show}
        setShow={setShow}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <DeleteLivestream
        show={show2}
        setShow={setShow2}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />
      <EditLivestream
        show={show3}
        setShow={setShow3}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />

      <PublishLivestream
        show={show4}
        setShow={setShow4}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
        publishLivestream={publishedLivestream}
        unpublishLivestream={unpublishedLivestream}
      />
      <ViewLivestream
        show={show5}
        setShow={setShow5}
        uploadSuccessFunc={uploadSuccessFunc}
        selectedLivestream={selectedLivestream}
      />
      <AddThumbnail
        show={show6}
        setShow={setShow6}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <AddRecentMessage
        show={show7}
        setShow={setShow7}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
      />

      <ViewLivestreamRecent
        show={show55}
        setShow={setShow55}
        selectedRecentLiveStream={pickedRecentLiveStream}
      />

      <DeleteLivestreamRecent
        show={show22}
        setShow={setShow22}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
      />
      <EditLivestreamRecent
        show={show33}
        setShow={setShow33}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
      />
      <PublishLivestreamRecent
        show={show44}
        setShow={setShow44}
        uploadSuccessFuncxx={uploadSuccessFuncxx}
        pickedRecentLiveStream={pickedRecentLiveStream}
        publishLivestreamRecent={publishLivestreamRecent}
        unpublishLivestreamRecent={unpublishLivestreamRecent}
      />
    </Container>
  );
};
export default Livestream;
