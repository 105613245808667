import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faEdit,
  faCopy,
  faTrash,
  faEye,
  faChartBar,
  faUserPlus,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./actiondropdown.css";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="action-btn"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
  </button>
));

const ActionDropdown = ({ form, handlers }) => {
  const { handleView, handleCopy, handleShow5, handleShow7, handleShow } =
    handlers;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-actions" />
      <Dropdown.Menu className="dropdown-menu-custom">
        <Dropdown.Item
          onClick={(event) => handleView(event, form)}
          className="dropdown-item-custom"
        >
          <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
        </Dropdown.Item>

        <Dropdown.Item
          onClick={(event) => handleCopy(event, form)}
          className="dropdown-item-custom"
        >
          <FontAwesomeIcon icon={faCopy} className="me-2" /> Copy
        </Dropdown.Item>

        <Dropdown.Item
          onClick={(event) => handleShow5(event, form)}
          className="dropdown-item-custom"
          style={{ color: form.published ? "#FE7064" : "#243060" }}
        >
          <FontAwesomeIcon
            icon={form.published ? faToggleOff : faToggleOn}
            className="me-2"
          />
          {form.published ? "Unpublish" : "Publish"}
        </Dropdown.Item>

        {form.published && (
          <Dropdown.Item
            onClick={(event) => handleShow7(event, form)}
            className="dropdown-item-custom"
          >
            <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Assign
          </Dropdown.Item>
        )}

        <Dropdown.Divider />

        <Link
          to={`/auth/formresponses/${form._id}`}
          className="dropdown-item dropdown-item-custom"
        >
          <FontAwesomeIcon icon={faEye} className="me-2" /> View Response
        </Link>

        <Link
          to={`/auth/summaryformresponse/${form._id}`}
          className="dropdown-item dropdown-item-custom"
        >
          <FontAwesomeIcon icon={faChartBar} className="me-2" /> Summary
          Response
        </Link>

        <Dropdown.Divider />

        <Dropdown.Item
          onClick={(event) => handleShow(event, form)}
          className="dropdown-item-custom"
          style={{ color: "#dc3545" }}
        >
          <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionDropdown;
