import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import "./chatbot.css";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { useAppContext } from "../../../context/appContext";
import moment from "moment";
import { Modal } from "react-bootstrap";

const ChatBot = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, alertType, logoutUser } = useAppContext();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [sessionId, setSessionId] = useState(null);

  // const appendMessage = (message, sender) => {
  //   setMessages((messages) => [...messages, { text: message, sender }]);
  // };

  const [sessions, setSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(sessionId);

  // Fetch chat sessions for the current user
  const fetchSessions = async () => {
    try {
      const response = await authFetch.get(`/chatbot/sessions/${user._id}`);
      setSessions(response.data);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  // Call fetchSessions when the component mounts or the userId changes
  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchMessages = async (sessionId) => {
    try {
      const response = await authFetch.get(`/chatbot/messages/${sessionId}`);

      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (currentSessionId) {
      fetchMessages(currentSessionId);
    }
  }, [currentSessionId]);

  const appendMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(() => {
    handleStartSession();
  }, []);

  // const sendMessage = async () => {
  //   const messageContent = input.trim();
  //   if (!messageContent) return;

  //   try {
  //     const response = await authFetch.post("/chatbot", {
  //       prompt: messageContent,
  //       sessionId: sessionId === "" ? currentSessionId : sessionId, // Include the current session ID
  //       userId: user._id,
  //     });
  //     // Update the messages state with the new message and response
  //     setSessionId(response.data.sessionId);
  //     setMessages([
  //       ...messages,
  //       { content: messageContent, sender: "user" },
  //       { content: response.data.message, sender: "bot" },
  //     ]);
  //     setInput(""); // Clear input after sending
  //   } catch (error) {
  //     console.error("Error sending message:", error);
  //   }
  // };
  const [isChatBotTyping, setIsChatBotTyping] = useState(false); // Add this state to track chatbot 'typing' status
  const [isSending, setIsSending] = useState(false); // State to track when a message is being sent

  const sendMessage = async () => {
    const messageContent = input.trim();
    if (!messageContent) return;

    try {
      setIsChatBotTyping(true); // Set chatbot to 'typing' before sending the message

      const response = await authFetch.post("/chatbot", {
        prompt: messageContent,
        sessionId: sessionId === "" ? currentSessionId : sessionId, // Include the current session ID
        userId: user._id,
      });

      // Update the messages state with the new message and response
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: messageContent, sender: "user" },
        { content: response.data.message, sender: "bot" },
      ]);
      setInput(""); // Clear input after sending
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
      setIsChatBotTyping(false); // Set chatbot to not 'typing' after receiving response or in case of an error
    }
  };
  const chatboxRef = useRef(null);
  useEffect(() => {
    // Scrolls to the bottom of the chatbox whenever messages change
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent form submission or adding a new line
      if (!isSending) {
        // Only send if not already in the process of sending
        setIsSending(true); // Indicate that a message is being sent
        sendMessage();
      }
    }
  };

  const handleSessionSelect = (sessionId) => {
    setCurrentSessionId(sessionId);
    fetchMessages(sessionId); // Implement this function to fetch messages for the selected session
  };

  const handleStartSession = async () => {
    try {
      // If there's any initialization logic on the backend, call it
      //await authFetch.post("/chatbot/startsession");

      // Clear current chat state to give the appearance of a new session
      setCurrentSessionId(""); // Assuming this clears the session ID in your state
      setSessionId(""); // Assuming this clears the session ID in your state
      setMessages([]); // Clear existing messages to start fresh

      // If you have any UI state that indicates an active session, reset it as well
      // For example, if you're showing a session indicator, hide it or set its value to indicate a new session
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  const categorizeSessions = (sessions) => {
    const categorized = {
      today: [],
      yesterday: [],
      last7Days: [],
      previousMonth: [],
      older: [],
    };

    sessions.forEach((session) => {
      const sessionDate = moment(session.createdAt);
      if (sessionDate.isSame(moment(), "day")) {
        categorized.today.push(session);
      } else if (sessionDate.isSame(moment().subtract(1, "days"), "day")) {
        categorized.yesterday.push(session);
      } else if (sessionDate.isAfter(moment().subtract(7, "days"))) {
        categorized.last7Days.push(session);
      } else if (sessionDate.isSame(moment(), "month")) {
        categorized.previousMonth.push(session);
      } else {
        categorized.older.push(session);
      }
    });

    return categorized;
  };

  const categorizedSessions = categorizeSessions(sessions);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleModal = (e, session) => {
    e.stopPropagation();
    setCurrentSessionId(session._id);
    setShowModal(true);
  };

  const deleteChat = async (e) => {
    try {
      e.preventDefault();

      await authFetch.delete(`/chatbot/${currentSessionId}`);
      fetchSessions();
      setCurrentSessionId("");
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  return (
    <Container
      fluid
      className="flex flex-column justify-content-center"
      style={{ height: "100vh" }}
    >
      <Row>
        <div className="flex justify-center mt-5">
          <h2 className="text-center">FaithMate Chatbot</h2>
        </div>
      </Row>
      <Row className="mt-3">
        <div className="flex justify-end">
          <button onClick={handleStartSession} className="wedweded">
            New Chat
          </button>
        </div>
      </Row>
      <Row className="w-100">
        <Col md={9}>
          <div
            id="chatbox"
            ref={chatboxRef}
            className="border p-3 mb-4"
            style={{ height: "500px", overflowY: "auto" }}
          >
            {messages.map((message, index) => (
              <React.Fragment key={index}>
                <strong
                  className={
                    message.sender === "user" ? "sender-right" : "sender-left"
                  }
                >
                  {message.sender === "user" ? "You" : "FaithMate Chatbot"}
                </strong>
                <div className={`message ${message.sender}-message`}>
                  {message.content}
                </div>
              </React.Fragment>
            ))}
            {isChatBotTyping && (
              <div className="loading-indicator">
                FaithMate Chatbot is typing...
              </div>
            )}
          </div>
          <InputGroup>
            <FormControl
              id="inputbox"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={isSending ? "Sending..." : "Type a message..."} // Change placeholder text
              aria-label="Type your message"
              disabled={isSending} // Disable input box when sending
            />

            <Button
              variant="primary"
              style={{ backgroundColor: "var(--primary-color)" }}
              onClick={sendMessage}
            >
              Send
            </Button>
          </InputGroup>
        </Col>
        <Col md={3}>
          <>
            <div>
              {categorizedSessions.today.length > 0 && (
                <div className="session-category">
                  <h5>Today</h5>
                  <ul className="session-list">
                    {categorizedSessions.today.map((session) => (
                      <li
                        key={session._id}
                        onClick={() => handleSessionSelect(session._id)}
                        className="flex justify-between"
                      >
                        <span className="session-prompt">{session.prompt}</span>{" "}
                        <span>
                          <FaTrash onClick={(e) => handleModal(e, session)} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {categorizedSessions.yesterday.length > 0 && (
                <div className="session-category">
                  <h5>Yesterday</h5>
                  <ul className="session-list  ">
                    {categorizedSessions.yesterday.map((session) => (
                      <li
                        key={session._id}
                        onClick={() => handleSessionSelect(session._id)}
                        className="flex justify-between"
                      >
                        <span className="session-prompt">{session.prompt}</span>{" "}
                        {/* -{" "}
                        <span className="session-time">
                          {moment(session.createdAt).format("LT")}
                        </span> */}
                        <span>
                          <FaTrash onClick={(e) => handleModal(e, session)} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Last 7 Days */}
              {categorizedSessions.last7Days.length > 0 && (
                <div className="session-category">
                  <h5>Last 7 Days</h5>
                  <ul className="session-list">
                    {categorizedSessions.last7Days.map((session) => (
                      <li
                        key={session._id}
                        onClick={() => handleSessionSelect(session._id)}
                        className="flex justify-between"
                      >
                        <span className="session-prompt">{session.prompt}</span>{" "}
                        <span>
                          <FaTrash onClick={(e) => handleModal(e, session)} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Previous Month */}
              {categorizedSessions.previousMonth.length > 0 && (
                <div className="session-category">
                  <h5>Previous Month</h5>
                  <ul className="session-list">
                    {categorizedSessions.previousMonth.map((session) => (
                      <li
                        key={session._id}
                        onClick={() => handleSessionSelect(session._id)}
                      >
                        <span className="session-prompt">{session.prompt}</span>{" "}
                        -{" "}
                        <span className="session-time">
                          {moment(session.createdAt).format("LT")}
                        </span>
                        <span>
                          <FaTrash />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* 

            
          

            {categorizedSessions.older.length > 0 && (
              <>
                <h5>Older</h5>
                <ul>
                  {categorizedSessions.older.map((session) => (
                    <li
                      key={session._id}
                      onClick={() => handleSessionSelect(session._id)}
                    >
                      {session.prompt} -{" "}
                      {moment(session.createdAt).format("LT")}
                    </li>
                  ))}
                </ul>
              </>
            )} */}
          </>
        </Col>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <button className="jiluil" onClick={handleClose}>
              Close
            </button>
            <button className="jiluil3" onClick={(e) => deleteChat(e)}>
              Okay
            </button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
};

export default ChatBot;
