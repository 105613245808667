import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import axios from "axios";
import "./usermanagement.css";
import Modal from "react-bootstrap/Modal";

const MessageCenter = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    getCompleteMembers,
    showAlert,
    getAllSettings,
    user,
    logoutUser,
    getAllFollowUp,
  } = useAppContext();

  const [birthdaySelected, setBirthdaySelected] = useState("");
  const [anniversarySelected, setAnniversarySelected] = useState("");
  const [birthdayChecked, setBirthdayChecked] = useState(false);
  const [birthdayName, setBirthdayName] = useState("");
  const [anniversaryChecked, setAnniversaryChecked] = useState("");
  const [anniversaryName, setAnniversaryName] = useState("");

  const [messageCenter, setMessageCenter] = useState([]);
  const [NewMemberChecked, setNewMemberChecked] = useState(false);
  const [NewMemberSelected, setNewMemberSelected] = useState("");
  const [NewMemberName, setNewMemberName] = useState("");
  const [attendanceChecked, setAttendanceChecked] = useState(false);
  const [attendanceSelected, setAttendanceSelected] = useState("");
  const [attendanceName, setAttendanceName] = useState("");
  const [followUpChecked, setFollowUpChecked] = useState(false);
  const [followUpSelected, setFollowUpSelected] = useState("");
  const [followUpName, setFollowUpName] = useState("");
  const [givingChecked, setGivingChecked] = useState(false);
  const [givingSelected, setGivingSelected] = useState("");
  const [givingName, setGivingName] = useState("");
  const [prayerRequestChecked, setPrayerRequestChecked] = useState(false);
  const [prayerRequestSelected, setPrayerRequestSelected] = useState("");
  const [prayerRequestName, setPrayerRequestName] = useState("");
  const [error, setError] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [preferredBirthdayDay, setPreferredBirthdayDay] = useState("");
  const [preferredBirthdayTime, setPreferredBirthdayTime] = useState("");

  const [preferredAnniversaryDay, setPreferredAnniversaryDay] = useState("");
  const [preferredAnniversaryTime, setPreferredAnniversaryTime] = useState("");

  const [preferredNewMembersDay, setPreferredNewMembersDay] = useState("");
  const [preferredNewMembersTime, setPreferredNewMembersTime] = useState("");

  const [preferredGivingDay, setPreferredGivingDay] = useState("");
  const [preferredGivingTime, setPreferredGivingTime] = useState("");

  const [preferredPrayerDay, setPreferredPrayerDay] = useState("");
  const [preferredPrayerTime, setPreferredPrayerTime] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (user.subscription && user.subscription.length > 0) {
      if (user.subscription[0].plan.nickname === "Free") {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    } else {
      console.log("The user does not have any active subscriptions.");
    }
  }, [user.subscription]);

  useEffect(() => {
    getCompleteMembers();
    getAllSettings();
    getAllFollowUp();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await authFetch.get("/messagecenter/messagecenter");
      setMessageCenter(response.data.data);
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    if (messageCenter) {
      setBirthdayChecked(messageCenter[0]?.birthdayChecked);
      setBirthdaySelected(messageCenter[0]?.birthdaySelected);
      setBirthdayName(messageCenter[0]?.birthdayName);
      setAnniversaryChecked(messageCenter[0]?.anniversaryChecked);
      setAnniversarySelected(messageCenter[0]?.anniversarySelected);
      setAnniversaryName(messageCenter[0]?.anniversaryName);
      setNewMemberChecked(messageCenter[0]?.NewMemberChecked);
      setNewMemberSelected(messageCenter[0]?.NewMemberSelected);
      setNewMemberName(messageCenter[0]?.NewMemberName);
      setAttendanceChecked(messageCenter[0]?.attendanceChecked);
      setAttendanceSelected(messageCenter[0]?.attendanceSelected);
      setAttendanceName(messageCenter[0]?.attendanceName);
      setFollowUpChecked(messageCenter[0]?.followUpChecked);
      setFollowUpSelected(messageCenter[0]?.followUpSelected);
      setFollowUpName(messageCenter[0]?.followUpName);
      setGivingChecked(messageCenter[0]?.givingChecked);
      setGivingSelected(messageCenter[0]?.givingSelected);
      setGivingName(messageCenter[0]?.givingName);
      setPrayerRequestChecked(messageCenter[0]?.prayerRequestChecked);
      setPrayerRequestSelected(messageCenter[0]?.prayerRequestSelected);
      setPrayerRequestName(messageCenter[0]?.prayerRequestName);
      setPreferredBirthdayDay(messageCenter[0]?.preferredBirthdayDay);
      setPreferredBirthdayTime(messageCenter[0]?.preferredBirthdayTime);
      setPreferredAnniversaryDay(messageCenter[0]?.preferredAnniversaryDay);
      setPreferredAnniversaryTime(messageCenter[0]?.preferredAnniversaryTime);
      setPreferredNewMembersDay(messageCenter[0]?.preferredNewMembersDay);
      setPreferredNewMembersTime(messageCenter[0]?.preferredNewMembersTime);
      setPreferredGivingDay(messageCenter[0]?.preferredGivingDay);
      setPreferredGivingTime(messageCenter[0]?.preferredGivingTime);
      setPreferredPrayerDay(messageCenter[0]?.preferredPrayerDay);
      setPreferredPrayerTime(messageCenter[0]?.preferredPrayerTime);
    }
  }, [messageCenter]);

  const handleBirthdatCheckboxChange = (e) => {
    setBirthdayChecked(e.target.checked);
    setBirthdayName(e.target.name);
  };

  const handlePrayerRequestCheckboxChange = (e) => {
    setPrayerRequestChecked(e.target.checked);
    setPrayerRequestName(e.target.name);
  };

  const handleAnniversaryCheckboxChange = (e) => {
    setAnniversaryChecked(e.target.checked);
    setAnniversaryName(e.target.name);
  };
  const handleNewMembersCheckboxChange = (e) => {
    setNewMemberChecked(e.target.checked);
    setNewMemberName(e.target.name);
  };

  // const handleNewAttendanceCheckboxChange = (e) => {
  //   setAttendanceChecked(e.target.checked);
  //   setAttendanceName(e.target.name);
  // };

  // const handleNewFollowupCheckboxChange = (e) => {
  //   setFollowUpChecked(e.target.checked);
  //   setFollowUpName(e.target.name);
  // };

  const handleNewGivingCheckboxChange = (e) => {
    setGivingChecked(e.target.checked);
    setGivingName(e.target.name);
  };

  const myHandleSubmit = async (e) => {
    e.preventDefault();

    if (
      !birthdayChecked &&
      !anniversaryChecked &&
      !NewMemberChecked &&
      !attendanceChecked &&
      !followUpChecked &&
      !givingChecked &&
      !prayerRequestChecked
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please select an option to proceed!"
        />
      );
      setShow(false);
      return;
    }

    if (birthdayChecked && !preferredBirthdayDay) {
      toast(
        <CustomToastContent
          title="Error"
          message="Enter your preferred day and time to proceed!"
        />
      );
      setShow(false);
      return;
    }

    if (anniversaryChecked && !preferredAnniversaryDay) {
      toast(
        <CustomToastContent
          title="Error"
          message="Enter your preferred day and time to proceed!"
        />
      );
      setShow(false);
      return;
    }

    if (NewMemberChecked && !preferredNewMembersDay) {
      toast(
        <CustomToastContent
          title="Error"
          message="Enter your preferred day and time to proceed!"
        />
      );
      setShow(false);
      return;
    }

    if (givingChecked && !preferredGivingDay) {
      toast(
        <CustomToastContent
          title="Error"
          message="Enter your preferred day and time to proceed!"
        />
      );
      setShow(false);
      return;
    }

    if (prayerRequestChecked && !preferredPrayerDay) {
      toast(
        <CustomToastContent
          title="Error"
          message="Enter your preferred day and time to proceed!"
        />
      );
      setShow(false);
      return;
    }

    const data = {
      birthdayChecked,
      birthdaySelected,
      birthdayName,
      preferredBirthdayDay,
      anniversaryChecked,
      anniversarySelected,
      anniversaryName,
      preferredAnniversaryDay,
      NewMemberChecked,
      NewMemberSelected,
      NewMemberName,
      preferredNewMembersDay,
      preferredGivingTime,
      preferredNewMembersTime,
      preferredAnniversaryTime,
      preferredBirthdayTime,
      preferredPrayerTime,
      givingChecked,
      givingSelected,
      givingName,
      preferredGivingDay,
      churchNamex: user.churchName,
      prayerRequestChecked,
      prayerRequestSelected,
      prayerRequestName,
      preferredPrayerDay,
      memberId: user._id,
    };

    try {
      await authFetch.post("/messagecenter", data);
      toast(
        <CustomToastContent
          title="Success"
          message="Message Center settings updated successfully!"
        />
      );
      setShow(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container fluid style={{ height: "28vh" }}>
        {showAlert && <Alert />}

        <Row className="headerbanner">
          <div className="bannerbig">Message Center</div>
          <div className="bannersmall">
            Set up automatic email and SMS reminders for specific tasks on
            chosen days each month to streamline communication and save time on
            reporting tasks.
          </div>
        </Row>

        <Row className="fundc2" style={{ paddingBottom: "0rem" }}>
          <>
            <Table hover responsive style={{ backgroundColor: "#F1F1F1" }}>
              <thead>
                <tr className="trdata">
                  <th>NAME</th>
                  <th>DESCRIPTION</th>
                  <th>SETTINGS</th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr className={isFree ? "disabled" : "trdata"}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label="Birthday"
                        name="Birthday"
                        value="defaultFund"
                        onChange={handleBirthdatCheckboxChange}
                        checked={birthdayChecked}
                      />
                    </td>
                    <td>Send a monthly email with next month's birthdays.</td>
                    <td style={{ width: "40%" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={preferredBirthdayDay}
                            onChange={(e) =>
                              setPreferredBirthdayDay(e.target.value)
                            }
                            placeholder="Enter Preferred Day"
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="time"
                            value={preferredBirthdayTime}
                            onChange={(e) =>
                              setPreferredBirthdayTime(e.target.value)
                            }
                            placeholder="Select Preferred Time"
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr className={isFree ? "disabled" : "trdata"}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label="Wedding Anniversary"
                        name="weddingAnniversary"
                        value="defaultFund"
                        onChange={handleAnniversaryCheckboxChange}
                        checked={anniversaryChecked}
                        // onChange={(e) => setDefaultFund(e.target.checked)}
                      />
                    </td>
                    <td>
                      Send a monthly email with next month's wedding
                      anniversaries.
                    </td>

                    <td style={{ width: "40%" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={preferredAnniversaryDay}
                            onChange={(e) =>
                              setPreferredAnniversaryDay(e.target.value)
                            }
                            placeholder="Enter Preferred Day"
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="time"
                            value={preferredAnniversaryTime}
                            onChange={(e) =>
                              setPreferredAnniversaryTime(e.target.value)
                            }
                            placeholder="Select Preferred Time"
                          />
                        </Col>
                      </Row>
                    </td>

                    {/* Join array elements */}
                  </tr>
                  <tr className={isFree ? "disabled" : "trdata"}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label="New Members"
                        name="NewMemberChecked"
                        value="defaultFund"
                        onChange={handleNewMembersCheckboxChange}
                        checked={NewMemberChecked}
                      />
                    </td>
                    <td>
                      Send a weekly email with new members from the past week.
                    </td>
                    <td style={{ width: "40%" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            as="select"
                            value={preferredNewMembersDay}
                            onChange={(e) =>
                              setPreferredNewMembersDay(e.target.value)
                            }
                            placeholder="Select Preferred Day"
                          >
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Control
                            type="time"
                            value={preferredNewMembersTime}
                            onChange={(e) =>
                              setPreferredNewMembersTime(e.target.value)
                            }
                            placeholder="Select Preferred Time"
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>

                  <tr className={isFree ? "disabled" : "trdata"}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label="Giving"
                        name="givingChecked"
                        value="defaultFund"
                        onChange={handleNewGivingCheckboxChange}
                        checked={givingChecked}
                      />
                    </td>
                    <td>
                      Send a weekly email with contributions from the past week.
                    </td>
                    <td style={{ width: "40%" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            as="select"
                            value={preferredGivingDay}
                            onChange={(e) =>
                              setPreferredGivingDay(e.target.value)
                            }
                            placeholder="Select Preferred Day"
                          >
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Control
                            type="time"
                            value={preferredGivingTime}
                            onChange={(e) =>
                              setPreferredGivingTime(e.target.value)
                            }
                            placeholder="Select Preferred Time"
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>

                  <tr className={isFree ? "disabled" : "trdata"}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label="Prayer Requests"
                        name="prayerRequestChecked"
                        value="defaultFund"
                        onChange={handlePrayerRequestCheckboxChange}
                        checked={prayerRequestChecked}
                      />
                    </td>
                    <td>
                      Send a weekly email with prayer requests from the past
                      week.
                    </td>
                    <td style={{ width: "40%" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            as="select"
                            value={preferredPrayerDay}
                            onChange={(e) =>
                              setPreferredPrayerDay(e.target.value)
                            }
                            placeholder="Select Preferred Day"
                          >
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Control
                            type="time"
                            value={preferredPrayerTime}
                            onChange={(e) =>
                              setPreferredPrayerTime(e.target.value)
                            }
                            placeholder="Select Preferred Time"
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </>
              </tbody>
            </Table>
            <p style={{ fontSize: "1em", fontFamily: "Open Sans" }}>
              <span style={{ fontWeight: "600", fontFamily: "Open Sans" }}>
                Note:
              </span>{" "}
              Newly enabled report will be sent 12 hours after the selected
              time.
            </p>
          </>
        </Row>
        <Row className="sssssssecwef">
          <div className="wwwef">
            {/* <Button className="hjujuj" onClick={handleShow}>
              Cancel
            </Button> */}
            <Button
              className="hjujuj1"
              onClick={handleShow}
              style={{ borderRadius: "30px" }}
            >
              Update
            </Button>
          </div>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Message Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>This action will enable the selected settings.</Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={myHandleSubmit}>
            Enable
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MessageCenter;
