import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import "./digitalmain.css";
import { FaFile, FaFileAudio, FaVideo } from "react-icons/fa";
import { Table } from "react-bootstrap";
import moment from "moment"; // Import moment
import PlayAudio from "../../../components/Audio/PlayAudio";
import ViewDocument from "../../../components/Document/ViewDocument";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import nodd from "../../../assets/newi/nodd.png";

const DigitalLibrary = () => {
  const { logoutUser } = useAppContext();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    loadCourse();
    loadDocument();
    loadAudio();
  }, []);
  const [audio, setAudio] = useState([]);
  const [document, setDocument] = useState([]);
  const [course, setCourse] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadAudio = async () => {
    setIsLoading(true); // Set loading true at the beginning
    try {
      const { data } = await authFetch.get(`/learning/audio`);
      setAudio(data);
    } catch (error) {
      console.error("Failed to load audio", error);
      // Optionally, handle error in UI state here
    }
    setIsLoading(false); // Set loading false in finally block
  };

  const loadDocument = async () => {
    setIsLoading(true); // Apply consistent loading state management
    try {
      const { data } = await authFetch.get(`/learning/document`);
      setDocument(data);
    } catch (error) {
      console.error("Failed to load document", error);
    }
    setIsLoading(false); // Ensure loading state is reset
  };

  const loadCourse = async () => {
    setIsLoading(true); // Same pattern for each loading function
    try {
      const { data } = await authFetch.get(`/learning/course`);
      setCourse(data);
    } catch (error) {
      console.error("Failed to load course", error);
    }
    setIsLoading(false);
  };

  // const loadCourse = async () => {
  //   const { data } = await authFetch.get(`/learning/course`);
  //   setCourse(data);
  // };
  const [show3, setShow3] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [show4, setShow4] = useState(false);
  useEffect(() => {
    const consolidatedData = [
      ...audio.map((item) => ({
        ...item,
        type: "Audio",
        name: item.audioName,
        description: item.audioDescription,
      })),
      ...document.map((item) => ({
        ...item,
        type: "Document",
        name: item.documentName,
        description: item.documentDescription,
      })),
      ...course.map((item) => ({
        ...item,
        type: "Course",
        name: item.name,
        description: item.description,
      })),
    ];

    setItems(consolidatedData);
  }, [audio, document, course]);

  const preview = (e, item) => {
    if (item.type === "Audio") {
      setShow3(true);
      setSelectedAudio(item);
    } else if (item.type === "Document") {
      setSelectedDocument(document);
      setShow4(true);
    } else if (item.type === "Course") {
      navigate(`/auth/courseview/${item.slug}`);
    }
  };
  return (
    <Container fluid className="maindigital">
      <Row className="courseheader course-header">
        <h1>Digital Library</h1>
      </Row>
      <Row className="flex gap-3">
        <Col className="audio-col">
          <div className="itemstandard2">
            Audio <FaFileAudio style={{ color: "#36D7FF" }} />
          </div>
          <div className="itemstandard">{audio.length}</div>
        </Col>
        <Col className="document-col">
          <div className="itemstandard2">
            Documents <FaFile style={{ color: "#3E46B6" }} />
          </div>
          <div className="itemstandard">{document.length}</div>
        </Col>
        <Col className="video-col">
          <div className="itemstandard2">
            Video <FaVideo style={{ color: "#2AA836" }} />
          </div>
          <div
            className="itemstandard"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {course.length}
          </div>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : items && items.length > 0 ? (
        <Row className="courseheader course-header">
          <Table striped hover>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Posted Date</th>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "20%" }}>Content Title</th>
                <th>Description</th>
                <th style={{ width: "5%" }}>Preview</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.createdAt).format("LL")}</td>{" "}
                  {/* Format date using moment */}
                  <td>{item.type}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td
                    onClick={(e) => preview(e, item)}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Preview
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      ) : (
        <div className="noData" style={{ paddingBottom: "6rem" }}>
          <img src={nodd} alt="no data" />
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}
      <PlayAudio
        show={show3}
        setShow={setShow3}
        selectedAudio={selectedAudio}
      />

      <ViewDocument
        show={show4}
        setShow={setShow4}
        selectedDocument={selectedDocument}
        handleClose={() => setShow4(false)}
      />
    </Container>
  );
};

export default DigitalLibrary;
