import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { Container, Col } from "react-bootstrap";
import { Form, Modal, Button } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";

import axios from "axios";

const WorkFlowView = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id, name } = useParams();

  const navigate = useNavigate();
  const [workflowId, setWorkflowId] = useState("");
  const [workflowName, setWorkflowName] = useState("");
  const [trigger, setTrigger] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  const handleClose = () => setShowPrompt(false);

  useEffect(() => {
    setWorkflowId(id);
    setWorkflowName(name);
    setTrigger(trigger);
  }, [id, name]);
  const { getContainers, logoutUser } = useAppContext();

  useEffect(() => {
    const getUniqueWorkflow = async () => {
      try {
        setLoading(true);
        const { data } = await authFetch.get(`/workflow/unique/${workflowId}`);

        // const [uniqueschedule] = data;
        setUniqueWorkflow(data);

        //console.log(data);

        setError(null);
      } catch (err) {
        console.log(err);
        setError(err.message); // Set error state for displaying in UI
      } finally {
        setLoading(false);
      }
    };

    if (workflowId) {
      getUniqueWorkflow();
    }
  }, [workflowId]);

  const [uniqueWorkflow, setUniqueWorkflow] = useState(null);

  const [selectedOption, setSelectedOption] = useState("");
  const [newSelectedOption, setNewSelectedOption] = useState("");

  useEffect(() => {
    getContainers();
  }, []);

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  useEffect(() => {
    if (uniqueWorkflow) {
      setSelectedOption(uniqueWorkflow.trigger);
    }
  }, [uniqueWorkflow]);

  function isActive(value) {
    return uniqueWorkflow?.trigger === value ? "active" : "";
  }

  // Assuming `containers` is your state or props data

  const handleData = async (e) => {
    e.preventDefault();
    if (!selectedOption) {
      toast(
        <CustomToastContent
          title="Error"
          message="Select a trigger to proceed"
          type="error"
        />
      );
      return;
    }
    if (uniqueWorkflow?.trigger !== selectedOption) {
      setNewSelectedOption(selectedOption);
      setShowPrompt(true);
      return;
    }
  };

  const handleReset = async () => {
    try {
      // Remove all steps associated with the current workflow
      let response = await authFetch.delete(
        `/workflow/removesteps/${uniqueWorkflow._id}`
      );

      if (response.status === 200 || response.status === 201) {
        // Update the workflow with the new trigger
        await updateAndNavigate();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const updateAndNavigate = async () => {
    try {
      // Remove all steps associated with the current workflow

      // Update the workflow with the new trigger
      const workflowData = {
        trigger: newSelectedOption,
      };

      const response = await authFetch.patch(
        `/workflow/updatenew/${workflowId}`,
        workflowData
      );

      if (response.status === 200 || response.status === 201) {
        handleClose();
        navigate(
          `/auth/workflowdetails/${id}/${workflowName}/${selectedOption}`
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container fluid className="flex flex-column">
      <Row
        className="courseheader flex items-start justify-start"
        style={{ marginBottom: "2rem" }}
      >
        <Col sm={8}>
          <p className="sahs">Workflow</p>
        </Col>
      </Row>

      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <div>
          <p className="sahs">Trigger Settings</p>
          <p>
            Workflow can be triggered by various events, such as when an
            individual joins a group, when a specific calendar date is reached,
            or when a date recorded in a contact field occurs.
          </p>
        </div>
        <div>
          <Form>
            <div className="mb-3">
              <div
                className={`custom-form-check ${
                  selectedOption === "calendarTrigger" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("calendarTrigger")}
              >
                <Form.Check
                  className={` ${isActive("calendarTrigger")}`}
                  label="Scheduled Date Trigger"
                  name="group1"
                  type="radio"
                  onChange={handleOptionChange}
                  value="calendarTrigger"
                  checked={uniqueWorkflow?.trigger === "calendarTrigger"}
                />
              </div>

              <div
                className={`custom-form-check ${
                  selectedOption === "eventTrigger" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("eventTrigger")}
              >
                <Form.Check
                  className={` ${isActive("eventTrigger")}`}
                  label="Personal Event Trigger"
                  name="group1"
                  type="radio"
                  onChange={handleOptionChange}
                  value="eventTrigger"
                  checked={uniqueWorkflow?.trigger === "eventTrigger"}
                />
              </div>

              <div
                className={`custom-form-check ${
                  selectedOption === "groupTrigger" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("groupTrigger")}
              >
                <Form.Check
                  className={` ${isActive("groupTrigger")}`}
                  label="Group Event Trigger"
                  name="group1"
                  type="radio"
                  onChange={handleOptionChange}
                  value="groupTrigger"
                  checked={uniqueWorkflow?.trigger === "groupTrigger"}
                />
              </div>
            </div>
          </Form>
          <div>
            {selectedOption === "calendarTrigger" && (
              <div>
                <p className="kldkds">
                  This trigger is set to activate on a specific calendar date
                  for everyone in a group.
                </p>
                <p>
                  <span style={{ fontWeight: "600" }}>Use Case:</span> Ideal for
                  group-wide announcements or events that need to be
                  communicated on a specific date, such as a holiday or a
                  significant milestone.
                </p>
              </div>
            )}
            {selectedOption === "eventTrigger" && (
              <div>
                <p className="kldkds">
                  This trigger activates on an individual's specific event date,
                  such as a birthday or anniversary.
                </p>
                <p>
                  <span style={{ fontWeight: "600" }}>Use Case:</span> Perfect
                  for sending personalized messages or notifications on special
                  dates for individuals within a group.
                </p>
              </div>
            )}
            {selectedOption === "groupTrigger" && (
              <div>
                <p className="kldkds">
                  This trigger is activated for everyone in a specific group.
                </p>
                <p>
                  <span style={{ fontWeight: "600" }}>Use Case:</span> Useful
                  for sending notifications or updates to all members of a
                  specific group whenever there's a change in membership.
                </p>
              </div>
            )}
          </div>
          <div
            className="  flex items-center justify-end my-3"
            style={{ float: "right" }}
          >
            <button
              onClick={(e) => handleData(e)}
              className="jiluil  flex items-end justify-end "
            >
              Next
            </button>
          </div>
        </div>
      </Row>
      <Modal show={showPrompt} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Workflow Trigger</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Changing the workflow trigger will reset the schedule for all steps.
          You will need to review and adjust each step in the workflow.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleReset}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default WorkFlowView;
