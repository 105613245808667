import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert/Alert";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";

const DeleteTagModal = ({
  showTagModal,
  setShowTagModal,
  tagDelete,
  folderId,
}) => {
  const { deleteTag, getRootFolder, getFolder, showAlert, deleteFolder } =
    useAppContext();

  const handleClose = () => setShowTagModal(false);
  const deleteItem = async () => {
    try {
      await deleteTag(tagDelete);
      if (folderId) {
        await getFolder(folderId);
      } else {
        await getRootFolder();
      }
      handleClose();
    } catch (error) {
      Sentry.captureException(error);

      // Optionally, handle the error by displaying a message to the user
      // setError("Failed to delete the item. Please try again.");
    }
  };

  return (
    <Modal show={showTagModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Tag
        </Modal.Title>
      </Modal.Header>
      {showAlert && <Alert />}
      <Modal.Body className="modal-wrapper__body">
        <Row>
          <Col md="12"></Col>
          <div class="jsx-parser">
            Do you really want to remove this ministry?
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Cancel
        </button>
        <button
          onClick={deleteItem}
          // toggleDeleteModal();
          // await getRootFolder();

          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTagModal;
