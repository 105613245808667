import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "./people.css";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Alert from "../../../components/Alert/Alert";
import { useAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import ButtonModal from "../../../components/ButtonModal/ButtonModal";
import { useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import { Select, Space } from "antd";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const PeopleEdit = () => {
  const { _id } = useParams();

  const {
    user,
    isLoading,
    showAlert,
    logoutUser,
    mgrade,
    campus,
    mschool,
    mnotes,
    getMemberById,
    memployer,
    createMember,
    mProfilePicture,
    uniquememberdetails,
    editMember,
    completemembers,
    getCompleteMembers,
    getContainers,
    containers,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [visibilitySettings, setVisibilitySettings] = useState({});

  useEffect(() => {
    fetchVisibilitySettings();
  }, []);

  const fetchVisibilitySettings = async () => {
    try {
      const response = await authFetch(`/members/fieldsvisibility/${user._id}`);
      if (response.status === 200 || response.status === 201) {
        const settings = response.data;
        setVisibilitySettings(settings);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleField = async (fieldName) => {
    // Toggle the value locally first
    const newValue = !visibilitySettings[fieldName];

    // Update the local state
    setVisibilitySettings({
      ...visibilitySettings,
      [fieldName]: newValue,
    });

    // Send the update to the backend
    try {
      await authFetch.put(`/members/fieldsvisibility/${user._id}`, {
        field: fieldName,
        value: newValue,
      });
    } catch (error) {
      console.error("Failed to update field visibility on the server", error);
    }
  };

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    getContainers();
  }, []);

  function handleFamilyData(data) {
    setFamilyData((currentFamily) => [...currentFamily, ...data]);
  }
  // const [selectedName, setSelectedName] = useState("");
  // const [showSaveFamily, setSaveFamily] = useState(false);
  // const [oldfamilyName, setOldfamilyName] = useState("");
  // const handleShowSaveFamily = () => {
  //   setSaveFamily(true);
  //   setShow(false);
  // };
  const [searchData, setSearchData] = useState("");
  const onSearch = (searchTerm) => {
    setSearchData(searchTerm);
  };
  const [dynamicFields, setDynamicFields] = useState([]);
  useEffect(() => {
    getDynamicFields();
  }, []);

  const getDynamicFields = async () => {
    try {
      const response = await authFetch.get("/dynamicfields");

      // Check if the response is in the expected format
      if (response.status === 200 || response.status === 201) {
        setDynamicFields(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch dynamic fields:", error);
      // Handle the UI update for error state here, if necessary
      // e.g., setErrorState(true) or similar
    }
  };

  const [mycombinedFields, setMyCombinedFields] = useState([]);

  useEffect(() => {
    if (dynamicFields.length > 0 && uniquememberdetails.responses) {
      const combinedFields = dynamicFields.map((assignment) => {
        // For each dynamic field, map through its fields
        const updatedFields = assignment.fields.map((field) => {
          // Find the response that corresponds to this field based on fieldId
          const matchingResponse = uniquememberdetails.responses.find(
            (response) => response.fieldId === field._id
          );

          // Return the field with the response attached
          return {
            ...field,
            response: matchingResponse ? matchingResponse.response : null, // Attach the response or null if not found
          };
        });

        return {
          ...assignment,
          fields: updatedFields, // Attach the updated fields array with responses
        };
      });

      // Set the combinedFields to the state variable mycombinedFields
      setMyCombinedFields(combinedFields);
    }
  }, [dynamicFields, uniquememberdetails]);

  const [selectedUserId, setSelectedUserId] = useState("");

  const [maritalx, setMaritalx] = useState();
  const showMartial = () => {
    setMaritalx(!maritalx);
  };

  const [agex, setAgex] = useState();
  const showAge = () => {
    setAgex(!agex);
  };

  const [anniversaryx, setAnniversaryx] = useState();
  const showAnniversary = () => {
    setAnniversaryx(!anniversaryx);
  };

  const [notex, setNotex] = useState();
  const showNotes = () => {
    setNotex(!notex);
  };

  const [familyx, setFamilyx] = useState();
  const showFamily = () => {
    setFamilyx(!familyx);
  };
  const [gradex, setGradex] = useState();
  const showGrade = () => {
    setGradex(!gradex);
  };
  const [schoolx, setSchoolx] = useState();
  const showSchool = () => {
    setSchoolx(!schoolx);
  };

  const [employerx, setEmployerx] = useState();
  const showEmployer = () => {
    setEmployerx(!employerx);
  };

  const [workPhonex, setworkPhonex] = useState();
  const showWork = () => {
    setworkPhonex(!workPhonex);
  };
  const [homePhonex, sethomePhonex] = useState();
  const showHome = () => {
    sethomePhonex(!homePhonex);
  };

  const [mobilePhonex, setmobilePhonex] = useState();
  const showMobile = () => {
    setmobilePhonex(!mobilePhonex);
  };

  const [facebookx, setFacebookx] = useState("");
  const showFacebook = () => {
    setFacebookx(!facebookx);
  };

  const [twitterx, setTwitterx] = useState("");
  const showTwitter = () => {
    setTwitterx(!twitterx);
  };

  const [instagramx, setInstagramx] = useState("");
  const showInstagram = () => {
    setInstagramx(!instagramx);
  };

  const [linkedinx, setLinkedinx] = useState("");
  const showLinkedin = () => {
    setLinkedinx(!linkedinx);
  };

  const [additionalNotesx, setAdditionalNotesx] = useState("");
  const showAdditionalNotes = () => {
    setAdditionalNotesx(!additionalNotesx);
  };

  const [otherPhonex, setOtherPhonex] = useState("");
  const showOtherPhone = () => {
    setOtherPhonex(!otherPhonex);
  };

  const [workEmailx, setWorkEmailx] = useState("");
  const showWorkEmail = () => {
    setWorkEmailx(!workEmailx);
  };

  const [middleNamex, setMiddleNamex] = useState("");
  const showMiddleName = () => {
    setMiddleNamex(!middleNamex);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);

  const showAddMember = () => {
    setShow2(true);
    setShow(false);
  };

  const [search, setSearch] = useState("");
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const navigate = useNavigate();
  const cancel = () => {
    navigate("/auth/people");
  };
  const [familyInfo, setFamilyInfo] = useState("");
  const onChange = (event) => {
    setSearchData(event.target.value);
  };
  function handleShow() {
    setShow(true);
  }

  const getFamData = (item) => {
    setExistMembers((currentFamily) => [...currentFamily, item]);
  };
  const [existMembers, setExistMembers] = useState([]);
  const [image, setImage] = useState({});
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [status, setStatus] = useState();
  const [accountStatus, setAccountStatus] = useState();
  const [office, setOffice] = useState();
  const [age, setAge] = useState();
  const [marital, setMarital] = useState();
  const [school, setSchool] = useState();
  const [grade, setGrade] = useState();
  const [campusx, setCampusx] = useState();
  const [notes, setNotes] = useState();
  const [familyId, setFamilyId] = useState();

  const [title, setTitle] = useState("");
  const [fields, setFields] = useState([]);

  //const [family, setFamily] = useState();
  const [homePhone, setHomePhone] = useState();
  const [workPhone, setWorkPhone] = useState();
  const [employer, setEmployer] = useState();
  const [mobilePhone, setMobilePhone] = useState();
  const [city, setCity] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [instagram, setInstagram] = useState();
  const [linkedin, setLinkedin] = useState();
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState("");
  const [postal, setPostal] = useState();
  const [state, setState] = useState();
  const [anniversary, setAnniversary] = useState();
  const [anniversaryMonth, setAnniversaryMonth] = useState();
  const [email, setEmail] = useState();
  const [familyData, setFamilyData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [birthMonth, setBirthMonth] = useState();
  const [birthday, setBirthday] = useState("");
  const [fieldsData, setFieldsData] = useState([]);
  const [preferredName, setPreferredName] = useState("");
  const [familySecCode, setFamilySecCode] = useState("");
  const [familyRole, setFamilyRole] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [notes2, setNotes2] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [otherPhoneNumber, setOtherPhoneNumber] = useState("");

  useEffect(() => {
    if (visibilitySettings) {
      setMaritalx(visibilitySettings.midd);
      setAgex(visibilitySettings.mBirthday);
      setAnniversaryx(visibilitySettings.anniversary);
      setNotex(visibilitySettings.firstNotes);
      setFamilyx(visibilitySettings.mfamily);
      setGradex(visibilitySettings.mgrade);
      setSchoolx(visibilitySettings.mschool);
      setEmployerx(visibilitySettings.memployer);
      setworkPhonex(visibilitySettings.mworkPhone);
      sethomePhonex(visibilitySettings.mhomePhone);
      setFacebookx(visibilitySettings.facebook);
      setTwitterx(visibilitySettings.twitter);
      setInstagramx(visibilitySettings.instagram);
      setLinkedinx(visibilitySettings.linkedin);
      setAdditionalNotesx(visibilitySettings.additionalNotes);
      setMiddleNamex(visibilitySettings.middleName);
      setOtherPhonex(visibilitySettings.otherPhoneNumber);
      setWorkEmailx(visibilitySettings.mworkEmail);
    }
  }, [visibilitySettings]);

  useEffect(() => {
    if (uniquememberdetails) {
      let usr = uniquememberdetails;
      if (usr?.mfirstName) setFirstName(usr?.mfirstName);
      if (usr?.mlastName) setLastName(usr?.mlastName);
      if (usr?.memail) setEmail(usr?.memail);
      if (usr?.mgender) setGender(usr?.mgender);
      if (usr?.mstatus) setStatus(usr?.mstatus);
      if (usr?.accountStatus) setAccountStatus(usr?.accountStatus);
      if (usr?.moffice) setOffice(usr?.moffice);
      if (usr?.mmarital) setMarital(usr?.mmarital);
      if (usr?.mage) setAge(usr?.mage);
      if (usr?.mnotes) setNotes(usr?.mnotes);
      if (usr?.mschool) setSchool(usr?.mschool);
      if (usr?.mgrade) setGrade(usr?.mgrade);
      if (usr?.campus) setCampusx(usr?.campus);
      if (usr?.familyRole) setFamilyRole(usr?.familyRole);
      if (usr?.preferredName) setPreferredName(usr?.preferredName);
      if (usr?.familyId) setFamilyId(usr?.familyId);
      if (usr?.familySecCode) setFamilySecCode(usr?.familySecCode);
      // if (usr?.dynamicFields) setFieldsData(usr?.dynamicFields);
      // if (usr?.response) setFieldsData(usr?.response);
      //if (usr?.mfamily) setFamilyData(usr?.mfamily);
      if (usr?.mhomePhone) setHomePhone(usr?.mhomePhone);
      if (usr?.mworkPhone) setWorkPhone(usr?.mworkPhone);
      if (usr?.facebook) setFacebook(usr?.facebook);
      if (usr?.twitter) setTwitter(usr?.twitter);
      if (usr?.instagram) setInstagram(usr?.instagram);
      if (usr?.linkedin) setLinkedin(usr?.linkedin);
      if (usr?.memployer) setEmployer(usr?.memployer);

      if (usr?.mmobilePhone) setMobilePhone(usr?.mmobilePhone);
      if (usr?.mcity) setCity(usr?.mcity);
      if (usr?.maddress) setAddress(usr?.maddress);
      if (usr?.maddress2) setAddress2(usr?.maddress2);
      if (usr?.mstate) setState(usr?.mstate);
      if (usr?.mAnniversaryDay) setAnniversary(usr?.mAnniversaryDay);
      if (usr?.mAnniversaryMonth) setAnniversaryMonth(usr?.mAnniversaryMonth);
      if (usr?.mBirthMonth) setBirthMonth(usr?.mBirthMonth);
      if (usr?.mBirthday) setBirthday(usr?.mBirthday);
      if (usr?.mProfilePicture) setImage(usr?.mProfilePicture);
      if (usr?.tags) setTagData(usr?.tags);
      if (usr?.middleName) setMiddleName(usr?.middleName);
      if (usr?.mnotes2) setNotes2(usr?.mnotes2);
      if (usr?.mworkEmail) setWorkEmail(usr?.mworkEmail);
      if (usr?.otherPhoneNumber) setOtherPhoneNumber(usr?.otherPhoneNumber);
    } else {
      getMemberById(_id);
    }
  }, [uniquememberdetails]);

  useEffect(() => {
    getMemberById(_id);
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    const responses = mycombinedFields.flatMap((field) =>
      field.fields.map((task) => ({
        fieldId: task._id, // Get field ID from task
        response: task.response || "", // Set response or default to empty string
      }))
    );

    const editMemberx = {
      mfirstName: firstName,
      mlastName: lastName,
      memail: email,
      mgender: gender,
      mstatus: status,
      accountStatus: accountStatus,
      moffice: office,
      mmarital: marital,
      mage: age,
      mschool: school,
      churchDomain: user.churchDomain,
      mgrade: grade,
      campus: campusx,
      mfamily: familyData,
      mhomePhone: homePhone,
      mworkPhone: workPhone,
      memployer: employer,
      mmobilePhone: mobilePhone,
      maddress2: address2,
      maddress: address,
      mcity: city,
      mnotes: notes,
      responses: responses,
      preferredName: preferredName,
      familyRole: familyRole,
      mfamilyId: familyId,
      tags: tagData,
      mpostal: postal,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      linkedin: linkedin,
      mstate: state,
      mAnniversaryMonth: anniversaryMonth,
      mAnniversaryDay: anniversary,
      mBirthMonth: birthMonth,
      mBirthday: birthday,
      mAnniversaryCont: anniversaryMonth + " " + anniversary,
      mBirthdayCont: birthMonth + " " + birthday,
      mProfilePicture: image,
      _id: _id,
      churchName: user.churchName,
      familyId: uniquememberdetails.familyId,
    };

    editMember({ editMemberx }, _id);
    navigate("/auth/peopleview/" + _id);
  }

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    //setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 520, 300, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await authFetch.post("/members/upload-image", {
          image: uri,
        });

        // set image in the state
        setImage(data);
        //setValues({ ...values, loading: false });
      } catch (err) {
        //setValues({ ...values, loading: false });
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      // setValues({ ...values, loading: true });
      await authFetch.post("/members/remove-image", { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Removed");
      //setValues({ ...values, loading: false });
    } catch (err) {
      //setValues({ ...values, loading: false });
    }
  };

  const transformedContainers = containers.map((container) => ({
    ...container,
    label: container.name, // Setting the label equal to the name field
    value: container.name, // Setting the id equal to the _id field
  }));

  // useEffect(() => {
  //   // Check if fieldsData is not empty and has a title in the first object
  //   if (mycombinedFields.length > 0 && fieldsData[0].category) {
  //     setTitle(fieldsData[0].category);
  //   }

  //   // Check if fieldsData is not empty and has a 'fields' array in the first object
  //   if (mycombinedFields.length > 0 && fieldsData[0].fields) {
  //     setFields(fieldsData[0].fields);
  //   }
  // }, [fieldsData]);

  const [returnedResponse, setReturnedResponse] = useState("");

  const handleAddFamilyMember = async () => {
    const userData = {
      familySecCode: uniquememberdetails.familySecCode,
      familyId: uniquememberdetails.familyId,
      familyRole: familyInfo,
    };

    try {
      const { data } = await authFetch.patch(
        `/members/updatememberbyidbyid/${selectedUserId}`,
        userData
      );

      if (data) {
        getFamilyId();
        handleClose2();
        setShow2(false);
        setFamilyInfo("");
        setSelectedUserId("");
        setReturnedResponse("Family Member Added Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (uniquememberdetails) {
      getFamilyId();
      getDynamicData();
    }
  }, [uniquememberdetails]);
  const [newFamilyId, setNewFamilyId] = useState("");
  const [mainFamilydata, setMainFamilyData] = useState([]);
  const getFamilyId = async () => {
    if (uniquememberdetails && uniquememberdetails?.familyId) {
      setNewFamilyId(uniquememberdetails?.familyId);
    }

    try {
      const { data } = await authFetch(`/members/getfamilyidx/${newFamilyId}`);

      if (data && data.length > 0) {
        setFamilyData(data);
        setMainFamilyData(data);
      }
      //  return datax; // Optional: return the data if needed elsewhere
    } catch (error) {
      console.error("Error fetching family ID:", error);
      // Handle the error appropriately
    }
  };

  const [newDynamicFields, setNewDynamicFields] = useState([]);
  const getDynamicData = async () => {
    try {
      const response = await authFetch.get("auth/churchaccount");

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setNewDynamicFields(data.churchSettings.dynamicFields);
      }

      // setChurchNumber(data?.primaryPhoneNumber);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFieldChange = (fieldIndex, taskIndex, value) => {
    setMyCombinedFields((currentFields) => {
      // Clone the current combined fields
      const updatedFields = [...currentFields];

      // Update the specific task's response in the cloned array
      updatedFields[fieldIndex].fields[taskIndex].response = value;

      // Return the updated array to state
      return updatedFields;
    });
  };

  const handleNewFieldChange = (dynamicIndex, fieldIndex, value) => {
    setNewDynamicFields((currentFields) => {
      const updatedFields = [...currentFields];
      updatedFields[dynamicIndex].fields[fieldIndex] = {
        ...updatedFields[dynamicIndex].fields[fieldIndex],
        response: value,
      };
      return updatedFields;
    });
  };

  const removeFamilyMember = async (id) => {
    try {
      const response = await authFetch.put(
        `/members/removefamilymemberbyid/${id}`
      );
      if (response.status === 200 || response.status === 201) {
        <CustomToastContent
          message="Family Member Removed Successfully"
          title="Success"
        />;
        getFamilyId();
        setReturnedResponse("Family Member Removed Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Container fluid>
        <Row className="memberc">
          <h3 className="memt">Edit Members</h3>
          {showAlert && <Alert />}
          {returnedResponse && (
            <p className="inlinesuccesss">{returnedResponse}</p>
          )}
          <>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className="dwdq">
                <div className="padt">
                  <p className="padt2">General Information</p>

                  <Form.Check
                    label="Middle Name"
                    aria-label="option 50"
                    checked={middleNamex} // Dynamically checked based on visibilitySettings
                    onChange={showMiddleName} // onChange is better for checkboxes
                    className="wedwe"
                  />

                  <Form.Check
                    label="Birth Date"
                    aria-label="option 3"
                    checked={agex} // Dynamically checked
                    onChange={showAge}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Anniversary Date"
                    aria-label="option 3"
                    checked={anniversaryx} // Dynamically checked
                    onChange={showAnniversary}
                    className="wedwe"
                  />

                  <Form.Check
                    label="Note"
                    aria-label="option 2"
                    checked={notex} // Dynamically checked
                    onChange={showNotes}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Additional Notes"
                    aria-label="option 3"
                    checked={additionalNotesx} // Dynamically checked
                    onChange={showAdditionalNotes}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Family"
                    aria-label="option 2"
                    checked={familyx} // Dynamically checked
                    onChange={showFamily}
                    className="wedwe"
                  />
                </div>

                <div className="padt">
                  <p className="padt2">Contact</p>
                  <Form.Check
                    label="Work Phone"
                    aria-label="option 7"
                    checked={workPhonex} // Dynamically checked
                    onChange={showWork}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Home Phone"
                    aria-label="option 13"
                    checked={homePhonex} // Dynamically checked
                    onChange={showHome}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Other Phone Number"
                    aria-label="option 51"
                    checked={otherPhonex} // Dynamically checked
                    onChange={showOtherPhone}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Work Email"
                    aria-label="option 52"
                    checked={workEmailx} // Dynamically checked
                    onChange={showWorkEmail}
                    className="wedwe"
                  />
                </div>

                <div className="padt">
                  <p className="padt2">Social Media</p>
                  <Form.Check
                    label="Facebook"
                    aria-label="option 9"
                    checked={facebookx} // Dynamically checked
                    onChange={showFacebook}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Twitter"
                    aria-label="option 10"
                    checked={twitterx} // Dynamically checked
                    onChange={showTwitter}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Instagram"
                    aria-label="option 11"
                    checked={instagramx} // Dynamically checked
                    onChange={showInstagram}
                    className="wedwe"
                  />
                  <Form.Check
                    label="LinkedIn"
                    aria-label="option 12"
                    checked={linkedinx} // Dynamically checked
                    onChange={showLinkedin}
                    className="wedwe"
                  />
                </div>

                <div className="padt">
                  <p className="padt2">Education & Work</p>
                  <Form.Check
                    label="School"
                    aria-label="option 6"
                    checked={schoolx} // Dynamically checked
                    onChange={showSchool}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Grade"
                    aria-label="option 7"
                    checked={gradex} // Dynamically checked
                    onChange={showGrade}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Employer"
                    aria-label="option 8"
                    checked={employerx} // Dynamically checked
                    onChange={showEmployer}
                    className="wedwe"
                  />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8}>
              <p className="formtext">General Information</p>
              <div className="creaaaa">
                <Form.Group
                  className="flex items-center justify-center flex-column"
                  style={{ paddingBottom: "3rem", paddingTop: "1rem" }}
                  controlId="formBasicEmail"
                >
                  <div>
                    <Image
                      className="pointer"
                      width={150}
                      height={150}
                      style={{
                        borderRadius: "50%",
                        paddingBottom: "1rem",
                        objectFit: "cover",
                      }}
                      src={
                        uniquememberdetails?.mProfilePicture?.Location
                          ? uniquememberdetails?.mProfilePicture?.Location
                          : require("../../../assets/images/blank2.png")
                      }
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "2rem",

                      display: "contents",
                    }}
                  >
                    <Form.Label className="hed btn addfambut2 btn-outline-secondary btn-block">
                      Upload Picture
                    </Form.Label>
                    <Form.Control
                      type="file"
                      value={mProfilePicture}
                      name="image"
                      handleChange={handleImage}
                      onChange={handleImage}
                      accept="image/*"
                      className="formvalue"
                      hidden
                    />
                  </div>

                  <div>
                    {preview && (
                      <Badge
                        count="X"
                        onClick={handleImageRemove}
                        className="pointer"
                      >
                        <Image width={200} height={150} src={preview} />
                      </Badge>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={firstName}
                    name="firstName"
                    className="formvalue"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="formvalue"
                    placeholder="Enter Last Name"
                    value={lastName}
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Preferred Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="formvalue"
                    placeholder="Enter Preferred Name"
                    value={preferredName}
                    name="preferredName"
                    onChange={(e) => setPreferredName(e.target.value)}
                  />
                </Form.Group>
                {middleNamex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="formvalue"
                      placeholder="Enter Middle Name"
                      value={middleName}
                      name="middleName"
                      //handleChange={handleMemberInput}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className="hed">Gender</p>

                  <Form.Check
                    type="radio"
                    label="Male"
                    name="mgender"
                    aria-label="radio 1"
                    value="Male"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setGender(e.target.value)}
                    checked={gender === "Male"}
                    style={{ fontSize: "1em" }}
                  />

                  <Form.Check
                    type="radio"
                    label="Female"
                    name="mgender"
                    aria-label="radio 1"
                    value="Female"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setGender(e.target.value)}
                    checked={gender === "Female"}
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className="hed">Account Status</p>

                  <Form.Check
                    type="radio"
                    label="Active"
                    name="accountStatus"
                    aria-label="radio 1"
                    value="Active"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setAccountStatus(e.target.value)}
                    checked={accountStatus === "Active"}
                    style={{ fontSize: "1em" }}
                  />

                  <Form.Check
                    type="radio"
                    label="Inactive"
                    name="accountStatus"
                    aria-label="radio 1"
                    value="Inactive"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setAccountStatus(e.target.value)}
                    checked={accountStatus === "Inactive"}
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Status</Form.Label>
                  <Form.Select
                    value={status}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setStatus(e.target.value)}
                    name="status"
                    aria-label="Default select example"
                    className="formvalue"
                    required
                  >
                    {/* <option>{status ? status : "Select an Option"}</option> */}

                    <option value="Member">Member</option>
                    <option value="Visitor">Visitor</option>

                    <option value="Inactive">Inactive</option>
                    <option value="Membership In Progress">
                      Membership In Progress
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Service Area</Form.Label>
                  <Form.Select
                    value={office}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setOffice(e.target.value)}
                    name="office"
                    aria-label="Default select example"
                    className="formvalue"
                    required
                  >
                    <option>{office ? status : "Select an Option"}</option>

                    <option value="Leader">Leader</option>
                    <option value="Elder">Elder</option>
                    <option value="Deacon">Deacon</option>
                    <option value="Worker">Worker</option>
                    <option value="Minister">Minister</option>
                    <option value="Pastor">Pastor</option>
                    <option value="Volunteer">Volunteer</option>
                  </Form.Select>
                </Form.Group>

                {agex && (
                  <div>
                    <Form.Label className="hed">Birth Date</Form.Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={birthday}
                            className="formvalue"
                            //handleChange={handleMemberInput}
                            onChange={(e) => setBirthday(e.target.value)}
                            name="birthday"
                            placeholder="Day"
                            style={{ width: 100 }}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Select
                            value={birthMonth}
                            //handleChange={handleMemberInput}
                            onChange={(e) => setBirthMonth(e.target.value)}
                            name="birthMonth"
                            aria-label="Default select example"
                            className="formvalue"
                            style={{ width: 150 }}
                          >
                            <option>Month</option>

                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}

                {anniversaryx && (
                  <div>
                    <Form.Label className="hed">Wedding Anniversary</Form.Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={anniversary}
                            className="formvalue"
                            //handleChange={handleMemberInput}
                            onChange={(e) => setAnniversary(e.target.value)}
                            name="anniversary"
                            placeholder="Day"
                            style={{ width: 100 }}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Select
                            value={anniversaryMonth}
                            // handleChange={handleMemberInput}
                            onChange={(e) =>
                              setAnniversaryMonth(e.target.value)
                            }
                            name="anniversaryMonth"
                            aria-label="Default select example"
                            className="formvalue"
                            style={{ width: 150 }}
                          >
                            <option>Month</option>

                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className="hed">Marital Status</p>
                  <Form.Check
                    type="radio"
                    label="Single"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Single"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Single"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Engaged"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Engaged"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Engaged"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Married"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Married"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Married"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Divorced"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Divorced"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Divorced"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Widowed"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Widowed"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Widowed"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Separated"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Separated"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Separated"}
                    style={{ fontSize: "1em" }}
                  />
                  <Form.Check
                    type="radio"
                    label="Other"
                    name="mmarital"
                    aria-label="radio 1"
                    value="Other"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMarital(e.target.value)}
                    checked={marital === "Other"}
                    style={{ fontSize: "1em" }}
                  />
                </Form.Group>
                {notex && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="hed">Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={notes}
                      name="notes"
                      onChange={(e) => setNotes(e.target.value)}
                      style={{ fontSize: "1em" }}
                    />
                  </Form.Group>
                )}

                {additionalNotesx && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Additional Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={notes2}
                      name="notes2"
                      onChange={(e) => setNotes2(e.target.value)}
                    />
                  </Form.Group>
                )}
              </div>
              {familyx && (
                <>
                  <p className="formtext">Family</p>
                  <div className="creaaaa">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="hed">Family</Form.Label>
                      <Form.Select
                        value={familyRole}
                        // handleChange={handleMemberInput}
                        onChange={(e) => setFamilyRole(e.target.value)}
                        name="familyRole"
                        aria-label="Default select example"
                        className="formvalue"
                        required
                      >
                        <option>Select an Option</option>
                        <option value="Head of HouseHold">
                          Head of HouseHold
                        </option>
                        <option value="Spouse">Spouse</option>
                        <option value="Adult">Adult</option>
                        <option value="Youth">Youth</option>
                        <option value="Teenager">Teenager</option>
                        <option value="Child">Child</option>
                        <option value="Unassigned">Unassigned</option>
                      </Form.Select>
                    </Form.Group>
                    {familyx ? <p className="formtexft hed">Family</p> : null}
                    <div>
                      <Table hover className="tabwi">
                        {mainFamilydata?.map((n, idx) => (
                          <tbody>
                            <tr>
                              <td
                                className="profile1"
                                style={{ fontSize: "1em" }}
                              >
                                {idx + 1}
                              </td>
                              <td style={{ fontSize: "1em" }}>
                                {n?.mfirstName} {n?.mlastName}
                              </td>
                              <td style={{ fontSize: "1em" }}>
                                {n?.familyRole}
                              </td>
                              <td style={{ fontSize: "1em", color: "red" }}>
                                <FaTimes
                                  onClick={() => removeFamilyMember(n._id)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <button className="addfambut2" onClick={handleShow}>
                        Add Family Member
                      </button>
                    </Form.Group>

                    <Modal
                      show={show}
                      onHide={handleClose}
                      centered
                      aria-labelledby="contained-modal-title-vcenter"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ fontSize: "100%", fontWeight: "600" }}
                        >
                          Add Family Members
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="flex justify-center flex-column w-100 mb-3">
                          <ButtonModal
                            labels="Add New Family Member"
                            handler={handleFamilyData}
                            closemodal={handleClose}
                            style="dereerre"
                          />
                          <p className="text-center spdm">
                            Add a new person to the database as a member of this
                            family.
                          </p>
                        </div>

                        <div className="flex justify-center flex-column w-100">
                          <button className="famils" onClick={showAddMember}>
                            Add To Existing Family
                          </button>

                          <p className="text-center spdm">
                            Add an existing members to this family.
                          </p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button className="jiluil" onClick={handleClose}>
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )}

              {facebookx || twitterx || instagramx || linkedinx ? (
                <p className="formtext">Social Media</p>
              ) : null}

              {facebookx && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">Facebook</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Facebook"
                    value={facebook}
                    name="facebook"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setFacebook(e.target.value)}
                    className="formvalue"
                  />
                </Form.Group>
              )}

              {twitterx && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">Twitter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Twitter"
                    value={twitter}
                    name="twitter"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setTwitter(e.target.value)}
                    className="formvalue"
                  />
                </Form.Group>
              )}
              {instagramx && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">Instagram</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Instagram"
                    value={instagram}
                    name="instagram"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setInstagram(e.target.value)}
                    className="formvalue"
                  />
                </Form.Group>
              )}
              {linkedinx && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">LinkedIn</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter LinkedIn"
                    value={linkedin}
                    name="linkedin"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setLinkedin(e.target.value)}
                    className="formvalue"
                  />
                </Form.Group>
              )}

              <p className="formtext mt-5">Contact Information</p>
              <div className="creaaaa">
                {" "}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">Mobile Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Home Phone"
                    value={
                      mobilePhone
                        ? mobilePhone
                        : uniquememberdetails?.mmobilePhone
                    }
                    className="formvalue"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setMobilePhone(e.target.value)}
                    name="mhomePhone"
                  />
                </Form.Group>
                {homePhonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Home Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Home Phone"
                      className="formvalue"
                      value={
                        homePhone ? homePhone : uniquememberdetails?.mhomePhone
                      }
                      //handleChange={handleMemberInput}
                      onChange={(e) => setHomePhone(e.target.value)}
                      name="mhomePhone"
                    />
                  </Form.Group>
                )}
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    className="formvalue"
                    placeholder="Enter Email Address"
                    value={email ? email : uniquememberdetails?.memail}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setEmail(e.target.value)}
                    name="memail"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    className="formvalue"
                    value={address ? address : uniquememberdetails?.maddress}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setAddress(e.target.value)}
                    name="maddress"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Address Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address Line 2"
                    className="formvalue"
                    value={address2 ? address2 : uniquememberdetails?.maddress2}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setAddress2(e.target.value)}
                    name="maddress2"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    value={city ? city : uniquememberdetails?.mcity}
                    className="formvalue"
                    //handleChange={handleMemberInput}
                    onChange={(e) => setCity(e.target.value)}
                    name="mcity"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">State</Form.Label>
                  <Form.Control
                    type="text"
                    className="formvalue"
                    placeholder="Enter State Name"
                    value={state ? state : uniquememberdetails?.mstate}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setState(e.target.value)}
                    name="mstate"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    className="formvalue"
                    placeholder="Enter Postal Code"
                    value={postal ? postal : uniquememberdetails?.mpostal}
                    //handleChange={handleMemberInput}
                    onChange={(e) => setPostal(e.target.value)}
                    name="mpostal"
                  />
                </Form.Group>
                {workPhonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Work Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Work Phone"
                      className="formvalue"
                      value={
                        workPhone ? workPhone : uniquememberdetails?.mworkPhone
                      }
                      //handleChange={handleMemberInput}
                      onChange={(e) => setWorkPhone(e.target.value)}
                      name="mworkPhone"
                    />
                  </Form.Group>
                )}
                {otherPhonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Other Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Other Phone"
                      className="formvalue"
                      value={otherPhoneNumber}
                      // handleChange={handleMemberInput}
                      onChange={(e) => setOtherPhoneNumber(e.target.value)}
                      name="otherPhoneNumber"
                    />
                  </Form.Group>
                )}
                {workEmailx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Work Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Work Email"
                      className="formvalue"
                      value={workEmail}
                      // handleChange={handleMemberInput}
                      onChange={(e) => setWorkEmail(e.target.value)}
                      name="workEmail"
                    />
                  </Form.Group>
                )}
                {/* {mobilePhonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Mobile Phone</Form.Label>
                    <Form.Control
                      type="text"
                      className="formvalue"
                      placeholder="Enter Mobile Phone"
                      value={
                        mobilePhone
                          ? mobilePhone
                          : uniquememberdetails?.mmobilePhone
                      }
                      //handleChange={handleMemberInput}
                      onChange={(e) => setMobilePhone(e.target.value)}
                      name="mmobilePhone"
                    />
                  </Form.Group>
                )} */}
                {mycombinedFields.length > 0
                  ? mycombinedFields?.map((field, fieldIndex) => (
                      <React.Fragment key={fieldIndex}>
                        <p className="formtext">{field.category}</p>
                        {field.fields.map((task, taskIndex) => (
                          <Form.Group key={taskIndex} className="mb-3">
                            <Form.Label>{task.fieldName}</Form.Label>

                            {/* Handle text input */}
                            {task.fieldType === "text" && (
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${task.fieldName}`}
                                value={task.response || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    fieldIndex,
                                    taskIndex,
                                    e.target.value
                                  )
                                }
                              />
                            )}

                            {/* Handle textarea input */}
                            {task.fieldType === "textarea" && (
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={`Enter ${task.fieldName}`}
                                value={task.response || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    fieldIndex,
                                    taskIndex,
                                    e.target.value
                                  )
                                }
                              />
                            )}

                            {/* Handle number input */}
                            {task.fieldType === "number" && (
                              <Form.Control
                                type="number"
                                placeholder={`Enter ${task.fieldName}`}
                                value={task.response || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    fieldIndex,
                                    taskIndex,
                                    e.target.value
                                  )
                                }
                              />
                            )}

                            {/* Handle date input */}
                            {task.fieldType === "date" && (
                              <Form.Control
                                type="date"
                                value={task.response || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    fieldIndex,
                                    taskIndex,
                                    e.target.value
                                  )
                                }
                              />
                            )}

                            {/* Handle radio input */}
                            {task.fieldType === "radio" &&
                              task.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={`${fieldIndex}-${taskIndex}-${optionIndex}`}
                                  type="radio"
                                  label={option}
                                  value={option}
                                  checked={task.response === option}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      fieldIndex,
                                      taskIndex,
                                      option
                                    )
                                  }
                                />
                              ))}

                            {/* Handle checkbox input */}
                            {task.fieldType === "checkbox" &&
                              task.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={`${fieldIndex}-${taskIndex}-${optionIndex}`}
                                  type="checkbox"
                                  label={option}
                                  checked={task.response?.includes(option)}
                                  onChange={(e) => {
                                    const updatedOptions = e.target.checked
                                      ? [...(task.response || []), option]
                                      : task.response.filter(
                                          (o) => o !== option
                                        );
                                    handleFieldChange(
                                      fieldIndex,
                                      taskIndex,
                                      updatedOptions
                                    );
                                  }}
                                />
                              ))}
                          </Form.Group>
                        ))}
                      </React.Fragment>
                    ))
                  : newDynamicFields?.map((dynamicField, dynamicIndex) => (
                      <div key={dynamicIndex}>
                        <p className="formtext">{dynamicField.category}</p>
                        {dynamicField.fields.map((field, fieldIndex) => (
                          <Form.Group key={fieldIndex} className="mb-3">
                            <Form.Label>{field.fieldName}</Form.Label>

                            {field.fieldType === "text" && (
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${field.fieldName}`}
                                value={field.response || ""}
                                onChange={(e) =>
                                  handleNewFieldChange(
                                    dynamicIndex,
                                    fieldIndex,
                                    e.target.value
                                  )
                                }
                                required
                              />
                            )}

                            {field.fieldType === "textarea" && (
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={`Enter ${field.fieldName}`}
                                value={field.response || ""}
                                onChange={(e) =>
                                  handleNewFieldChange(
                                    dynamicIndex,
                                    fieldIndex,
                                    e.target.value
                                  )
                                }
                                required
                              />
                            )}

                            {field.fieldType === "radio" &&
                              field.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={`${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                                  type="radio"
                                  label={option}
                                  value={option}
                                  checked={option === field.response}
                                  onChange={(e) =>
                                    handleNewFieldChange(
                                      dynamicIndex,
                                      fieldIndex,
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              ))}

                            {field.fieldType === "checkbox" &&
                              field.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={`${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                                  type="checkbox"
                                  label={option}
                                  checked={field.response?.includes(option)}
                                  onChange={(e) => {
                                    const updatedOptions = e.target.checked
                                      ? [...(field.response || []), option]
                                      : field.response.filter(
                                          (o) => o !== option
                                        );
                                    handleNewFieldChange(
                                      dynamicIndex,
                                      fieldIndex,
                                      updatedOptions
                                    );
                                  }}
                                  required
                                />
                              ))}

                            {field.fieldType === "number" && (
                              <Form.Control
                                type="number"
                                placeholder={`Enter ${field.fieldName}`}
                                value={field.response || ""}
                                onChange={(e) =>
                                  handleNewFieldChange(
                                    dynamicIndex,
                                    fieldIndex,
                                    e.target.value
                                  )
                                }
                                required
                              />
                            )}

                            {field.fieldType === "date" && (
                              <Form.Control
                                type="date"
                                value={field.response || ""}
                                onChange={(e) =>
                                  handleNewFieldChange(
                                    dynamicIndex,
                                    fieldIndex,
                                    e.target.value
                                  )
                                }
                                required
                              />
                            )}
                          </Form.Group>
                        ))}
                      </div>
                    ))}
                {mschool || mgrade || memployer ? (
                  <p className="formtext">Education and Work</p>
                ) : null}
                {schoolx && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">School</Form.Label>
                    <Form.Select
                      value={school ? school : uniquememberdetails?.mschool}
                      //handleChange={handleMemberInput}
                      onChange={(e) => setSchool(e.target.value)}
                      name="mschool"
                      aria-label="Default select example"
                      className="formvalue"
                    >
                      <option>Select an Option</option>

                      <option value="No formal education">
                        No formal education
                      </option>
                      <option value="Primary education">
                        Primary education
                      </option>
                      <option value="Secondary education">
                        Secondary education or high school
                      </option>
                      <option value="GED">GED</option>
                      <option value="Vocational qualification">
                        Vocational qualification
                      </option>
                      <option value="Bachelor's degree">
                        Bachelor's degree
                      </option>
                      <option value="Master's degree">Master's degree</option>
                      <option value="Doctorate or higher">
                        Doctorate or higher
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
                {gradex && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">Grade</Form.Label>
                    <Form.Select
                      value={grade ? grade : uniquememberdetails?.mgrade}
                      className="formvalue"
                      //handleChange={handleMemberInput}
                      aria-label="Default select example"
                      onChange={(e) => setGrade(e.target.value)}
                      name="mgrade"
                    >
                      <option>Select an Option</option>
                      <option value="nograde">No Grade</option>
                      <option value="JK">JK</option>
                      <option value="SK">SK</option>
                      <option value="g1">Grade 1</option>
                      <option value="g2">Grade 2</option>
                      <option value="g3">Grade 3</option>
                      <option value="g4">Grade 4</option>
                      <option value="g5">Grade 5</option>
                      <option value="g6">Grade 6</option>
                      <option value="g7">Grade 7</option>
                      <option value="g8">Grade 8</option>
                      <option value="g9">Grade 9</option>
                      <option value="g10">Grade 10</option>
                      <option value="g11">Grade 11</option>
                      <option value="g12">Grade 12</option>
                      <option value="graduate">Graduated</option>
                    </Form.Select>
                  </Form.Group>
                )}
                {employerx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Employer</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name of Employee"
                      className="formvalue"
                      value={
                        employer ? employer : uniquememberdetails?.memployer
                      }
                      // handleChange={handleMemberInput}
                      onChange={(e) => setEmployer(e.target.value)}
                      name="memployer"
                    />
                  </Form.Group>
                )}
              </div>

              <p className="formtext">Assign To Groups</p>
              <div className="creaaaa">
                <Form.Label className="hed">Group</Form.Label>
                <Space style={{ width: "100%" }} direction="vertical">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={tagData}
                    onChange={setTagData} // Directly set the selected values
                    name="tags"
                    options={transformedContainers}
                    className="formvalue"
                  />
                </Space>
                {user.churchCampuses && (
                  <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                    <Form.Label className="hed">Campus</Form.Label>
                    <Form.Select
                      value={campusx}
                      className="formvalue"
                      //  handleChange={handleMemberInput}
                      aria-label="Default select example"
                      onChange={(e) => setCampusx(e.target.value)}
                      name="campus"
                    >
                      <option>Select an Option</option>
                      {user?.churchCampuses?.map((campus) => (
                        <option value={campus?.campusName}>
                          {campus?.campusName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
              </div>

              <Form.Group
                className="mb-3 mt-5"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <button className="jiluil3" onClick={cancel}>
                  Cancel
                </button>
                <button
                  className="jiluil"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  style={{ fontSize: "1em" }}
                >
                  Save Changes
                </button>
              </Form.Group>
            </Col>
          </>
        </Row>
      </Container>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add Existing Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Type Member Name"
                autoFocus
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                name="searchData"
              />
              <div className="dropdownmy">
                {completemembers
                  ?.filter((item) => {
                    const searchTerm = searchData?.toLowerCase();

                    const fullname =
                      item.mfirstName?.toLowerCase() +
                      item.mlastName?.toLowerCase();
                    return (
                      searchTerm &&
                      fullname?.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() => {
                        onSearch(`${item.mfirstName} ${item.mlastName}`);
                        setSelectedUserId(item._id);
                      }}
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select
                value={familyInfo}
                name="familyInfo"
                onChange={(e) => setFamilyInfo(e.target.value)}
                aria-label="Default select example"
                className="formvalue"
              >
                <option>Select Family Role</option>
                <option value="Head of Household">Head of Household</option>
                <option value="Spouse">Spouse</option>
                <option value="Adult">Adult</option>
                <option value="Child">Child</option>
                <option value="Unassigned">Unassigned</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose2}>
            Close
          </button>
          <button className="jiluil" onClick={handleAddFamilyMember}>
            Add Family Member
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PeopleEdit;
