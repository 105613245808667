import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AddExpense from "../../../components/Expenses/AddExpenses";
import { FaCaretDown, FaDochub } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import AddWorkFlow from "../../../components/WorkFlow/AddWorkFlow";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import ViewExpense from "../../../components/Expenses/ViewExpense";
const Expenses = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const {
    getExpenseDetails,
    expensedetails,
    logoutUser,
    user,
    getExpense,
    expenses,
  } = useAppContext();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [expenseName, setExpenseName] = useState("");
  const [error, setError] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Expenses");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expenseDescription, setExpenseDescription] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [amount, setAmount] = useState("");
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleShow3 = () => setShow3(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [handleRefresh, setHandleRefresh] = useState(false);
  const handleClose1 = () => setShow2(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleClose5 = () => setShow5(false);
  const [expenseData, setExpenseData] = useState([]);
  const [documentFile, setDocumentFile] = useState({});

  const [expenseNamex, setExpenseNamex] = useState("");

  const [expenseDescriptionx, setExpenseDescriptionx] = useState("");
  const [expenseCategoryx, setExpenseCategoryx] = useState("");
  const [expenseDatex, setExpenseDatex] = useState("");
  const [amountx, setAmountx] = useState("");
  const [documentFilex, setDocumentFilex] = useState(null);

  const [newExpenseDocument, setNewExpenseDocument] = useState(null);

  const handleView = (e, expense) => {
    e.stopPropagation();
    setSelectedDocument(expense);
    setShow3(true);
  };

  //console.log(selectedDocument);

  useEffect(() => {
    getExpenseDetails();
  }, []);

  useEffect(() => {
    if (expenses && expenses.length > 0) {
      setExpenseData(expenses);
    }
  }, [expenses]);

  const refreshWorkflows = () => {
    getExpenseDetails();
    setHandleRefresh(true);
  };
  const [getTheExpense, setGetTheExpense] = useState(null);
  const getExpenseById = async (e, expense) => {
    e.preventDefault();
    e.stopPropagation();
    setGetTheExpense(expense);
    try {
      const response = await authFetch(
        `/giving/expense/selected/${expense._id}`
      );

      if (response.status === 200 || response.status === 201) {
        setExpenseNamex(response.data.expenseName);
        setExpenseDescriptionx(response.data.expenseDescription);
        setExpenseCategoryx(response.data.expenseCategory);
        setAmountx(response.data.amount);
        const formattedDate = new Date(response.data.expenseDate)
          .toISOString()
          .split("T")[0];
        setExpenseDatex(formattedDate);
        setDocumentFilex(response.data.documentFile);
        setSelectedValue(expense);
        setShow5(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleShow1 = (e, expense) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedValue(expense);

    setShow2(true);
  };

  const handleDelete = async () => {
    try {
      console.log(selectedValue._id);
      const response = await authFetch.delete(
        `/giving/expense/selected/${selectedValue._id}`
      );
      if (response) {
        getExpenseDetails();
        handleClose1();
        toast(
          <CustomToastContent
            message="Expense Deleted Successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // const response = deleteMethod(clicked._id, {
    //   alertText: "Method Deleted",
    // });
    // if (response) {
    //   getMethod();
    //   handleClose1();
    //   toast(
    //     <CustomToastContent
    //       message="Method Deleted Successfully"
    //       type="success"
    //     />
    //   );
    // }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!expenseNamex || !expenseCategoryx || !amountx || !expenseDatex) {
      toast(
        <CustomToastContent
          message="Please fill in all required fields"
          type="error"
        />
      );
      return;
    }

    const expenseData = {
      expenseName: expenseNamex,
      expenseDescription: expenseDescriptionx,
      expenseCategory: expenseCategoryx,
      amount: amountx,
      expenseDate: expenseDatex,
      documentFile: newExpenseDocument,
    };

    try {
      const response = await authFetch.patch(
        `/giving/expense/selected/${selectedValue._id}`,
        expenseData
      );

      if (response.status === 200 || response.status === 201) {
        setExpenseName("");
        setExpenseDescription("");
        setExpenseCategory("");
        setAmount("");
        setExpenseDate("");
        setNewExpenseDocument(null);
        refreshWorkflows();
        handleClose5();
        toast(
          <CustomToastContent
            message="Expense Updated Successfully"
            type="success"
          />
        );
      } else {
        toast(
          <CustomToastContent
            message="An error occurred while updating the expense"
            type="error"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // const response = await authFetch.patch(

  const handleDocumentUploadewefwefwee = async (e) => {
    try {
      console.log("File selected");
      const file = e.target.files[0];
      setNewExpenseDocument(file);
      if (!file) {
        console.log("No file selected");
        return;
      }

      if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        console.log("File is not a PDF");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const documentData = new FormData();
      documentData.append("document", file);

      try {
        console.log("Uploading document");
        const response = await authFetch.post(
          "/giving/upload-new-expense-report",
          documentData,
          {
            headers: {
              Accept: "application/json",
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
              console.log(
                `Upload progress: ${Math.round((100 * e.loaded) / e.total)}%`
              );
            },
          }
        );

        console.log("Document uploaded", response.data);
        setNewExpenseDocument(response.data);
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setUploading(false);
    }
  };

  const openFile = (e) => {
    e.preventDefault();

    const selectedDocument = {
      documentFile: documentFilex,
    };

    setSelectedDocument(selectedDocument);
    setShow3(true);
  };

  const removeDocument = () => {
    try {
      const response = authFetch.patch(
        `/giving/expense/remove-expense-document/${selectedValue._id}`,
        { documentFilex }
      );

      if (response.status === 200 || response.status === 201) {
        setDocumentFile(null);
        refreshWorkflows();
        getExpenseDetails();
        toast(
          <CustomToastContent
            message="Document Removed Successfully"
            type="success"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);

      toast(
        <CustomToastContent
          message="An error occurred while removing the document"
          type="error"
        />
      );
    }
  };

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Expense Tracker</div>
        <div className="bannersmall">
          Simplify the management of church expenses, providing accurate
          tracking and comprehensive financial oversight for your church.
        </div>
      </Row>

      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <div className="bnmjo">
          <button
            className="jiluil"
            onClick={handleShow}
            style={{ fontSize: "1em" }}
          >
            Add Expense
          </button>
        </div>
      </Row>

      <Row style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
          {expensedetails && expensedetails.length > 0 ? (
            <>
              <thead>
                <tr className="trdataheader">
                  <th>Posted Date</th>
                  <th>
                    Expense Name{" "}
                    {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>

                  <th>Description</th>
                  <th>Amount</th>
                  <th>View Attachment</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {expensedetails.map((expense, index) => (
                  <tr key={index} className="trdata">
                    <td>{moment(expense.postDate).format("MMM Do, YYYY")}</td>
                    <td>{expense.expenseName}</td>
                    <td>{expense.expenseDescription}</td>
                    <td>{expense.amount}</td>
                    <td onClick={(e) => handleView(e, expense)}>
                      {expense.documentFile ? (
                        <p
                          style={{
                            color: "var(--primary-color)",
                            marginBottom: "0px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          View
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "var(--secondary-color)",
                            marginBottom: "0px",
                          }}
                        >
                          No Attachment
                        </p>
                      )}
                    </td>
                    <td
                      style={{
                        gap: "10px",
                        display: "flex",
                        fontSize: "1em",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => getExpenseById(e, expense)}
                      >
                        Edit
                      </button>

                      <button
                        className="jiluil"
                        onClick={(e) => handleShow1(e, expense)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <div className="flex items-center justify-center noData">
              No Workflow Found
            </div>
          )}
        </Table>
      </Row>

      <AddExpense
        show={show}
        setShow={setShow}
        refreshWorkflows={refreshWorkflows}
      />

      <ViewExpense
        show3={show3}
        setShow3={setShow3}
        selectedDocument={selectedDocument}
        refreshWorkflows={refreshWorkflows}
        handleClose3={() => setShow3(false)}
      />

      <Modal show={show2} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected Expense.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Update Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label htmlFor="inputPassword5">
              Name <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={expenseNamex}
              onChange={(e) => setExpenseNamex(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="mt-3" htmlFor="inputPassword5">
              Description (optional)
            </Form.Label>
            <Form.Control
              type="text"
              value={expenseDescriptionx}
              onChange={(e) => setExpenseDescriptionx(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-3" htmlFor="inputPassword5">
              Category <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              className="mr-sm-2"
              id="inlineFormCustomSelect"
              custom
              value={expenseCategoryx}
              onChange={(e) => setExpenseCategoryx(e.target.value)}
            >
              <option value="0">Select a Category</option>
              {expenseData.map((data, index) => (
                <option key={index} value={data.expenseName}>
                  {data.expenseName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-3" htmlFor="inputPassword5">
              Expense Date <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              id="date"
              placeholder="Start Date"
              value={expenseDatex}
              className="forminput cnm"
              onChange={(e) => setExpenseDatex(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-3" htmlFor="inputPassword5">
              Amount ({user?.currency}) <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={amountx}
              onChange={(e) => setAmountx(e.target.value)}
              required
            />
          </Form.Group>

          {!documentFilex && (
            <Form.Group>
              <Form.Label className="mt-3" htmlFor="inputPassword5">
                Attachment
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleDocumentUploadewefwefwee}
                accept="application/pdf"
                className="lpm"
              />
              <p className="ww233">Note: only PDF can be uploaded.</p>
            </Form.Group>
          )}

          {documentFilex && (
            <div className="flex flex-row justify-between mt-4">
              <p>
                {documentFilex ? (
                  <p
                    style={{
                      cursor: "pointer",
                      color: "var(--secondary-color)",
                    }}
                    onClick={(e) => openFile(e, documentFilex)}
                  >
                    {documentFilex.key}
                  </p>
                ) : (
                  <p>No Attachment</p>
                )}
              </p>
              <p
                style={{ cursor: "pointer", color: "var(--secondary-color)" }}
                onClick={removeDocument}
              >
                Remove
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose5}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleUpdate}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Expenses;
