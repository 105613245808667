import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import Spinner from "react-bootstrap/Spinner";
import { useAppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PaymentSuccess = () => {
  const { logoutUser } = useAppContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  // useEffect(() => {
  //   const statusSubscription = async () => {
  //     setIsLoading(true);
  //     try {
  //       const data = await authFetch.get("/payment/status-subscription");

  //       if (data && data.length === 0) {
  //         setIsLoading(false);
  //         navigate("/auth/pricinglist");
  //       } else {
  //         // Navigate and force reload
  //         window.location.href = "/auth/dashboard";
  //       }
  //     } catch (error) {
  //       console.error("Error fetching subscription status:", error);
  //       setIsLoading(false);
  //       // Handle error appropriately
  //     }
  //   };

  //   statusSubscription();
  // }, [authFetch, navigate]);

  useEffect(() => {
    const statusSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await authFetch.get("/payment/status-subscription");
        const data = response.data; // Assuming the response follows this structure

        setIsLoading(false); // Set loading to false as soon as the response is received

        if (data && data.status === "cancelled") {
          // Redirect to the pricing list if the status is 'cancelled'
          navigate("/auth/pricinglist");
        } else if (data && data.length === 0) {
          // Handle case where data might be an empty array
          navigate("/auth/pricinglist");
        } else {
          // If data is present and status is not 'cancelled', navigate to the dashboard
          // Using 'window.location.href' for a hard reload, consider using 'navigate' for SPA behavior
          window.location.href = "/auth/dashboard";
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setIsLoading(false); // Ensure loading is set to false in case of an error
        // Handle error appropriately, maybe navigate to an error page or display a message
      }
    };

    statusSubscription();
  }, [authFetch, navigate]); // Ensure 'navigate' is included in your dependency array if it's a hook or derived from one

  if (isLoading) {
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "85vh",
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className="ccxds">Creating your account...</p>
    </div>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "85vh",
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p>Creating your account...</p>
    </div>
  );
};

export default PaymentSuccess;
