import React, { useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helpers";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import nodd from "../../../assets/newi/nodd.png";
import axios from "axios";

import "./giving.css";
const Batches = () => {
  const { batchSummary, batchsummary, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    batchsummary && batchSummary();
  }, []);
  console.log(batchsummary);
  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <p className="sahs">Batch Summary</p>
        </Row>

        {batchsummary && batchsummary.length > 0 ? (
          <Row className="fundc2" style={{ paddingTop: "3rem" }}>
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>Batch ID</th>
                  <th style={{ fontSize: "1em" }}>Batch Name</th>
                  <th style={{ fontSize: "1em" }}>Batch Status</th>
                  <th style={{ fontSize: "1em" }}>Entries</th>
                  <th style={{ fontSize: "1em" }}>Batch Total</th>
                </tr>
              </thead>
              {batchsummary.map((batchess) => (
                <tbody>
                  <tr>
                    <td style={{ fontSize: "1em" }}>
                      <Link
                        to={"/auth/batchview/" + batchess._id}
                        className="pointer"
                        style={{
                          textDecoration: "underline",
                          color: "black",
                        }}
                      >
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "black",
                          }}
                        >
                          <h5>{batchess._id} </h5>
                        </a>
                      </Link>
                    </td>

                    <td style={{ fontSize: "1em" }}>{batchess.batchName}</td>
                    <td style={{ fontSize: "1em" }}>
                      {batchess.batchStatus === "true" ? "Closed" : "Open"}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {batchess.entries} entries
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {currencyFormatter({
                        amount: batchess.totalAmount,
                        currency: "usd",
                      })}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
    </>
  );
};

export default Batches;
