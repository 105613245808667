import { useEffect, useState, useCallback } from "react";
import CourseEditForm from "../../../components/CourseEditForm/CourseEditForm";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import UpdateLessonForm from "../../../components/UpdateLessonForm/UpdateLessonForm";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { List } from "antd";

import Table from "react-bootstrap/Table";

const { Item } = List;

const CourseEdit = () => {
  // state
  const [values, setValues] = useState({
    _id: "",
    name: "",
    description: "",
    loading: false,
    image: {},
    lessons: [],
    instructor: "",
  });
  const navigate = useNavigate();
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload");
  const { logoutUser } = useAppContext();
  const [selectedItem, setSelectedItem] = useState();

  /**
   * LESSON UPDATE
   */
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [noVideo, setNoVideo] = useState(false);
  const [current, setCurrent] = useState({});
  const [uploadVideoButtonText, setUploadVideoButtonText] =
    useState("Upload video");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  // markdown cheetsheet modal
  const [markdownCheetsheetModal, setMarkdownCheetsheetModal] = useState(false);

  const { slug } = useParams();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    loadCourse();
  }, [slug]);

  const loadCourse = useCallback(async () => {
    try {
      const { data } = await authFetch.get(`/learning/course/${slug}`);
      setValues(data);
    } catch (error) {
      // Handle any errors here
    }
  }, [authFetch, setValues, slug]);

  useEffect(() => {
    loadCourse(); // Call the function inside the useEffect
    // Rest of your useEffect code

    return () => {
      // Cleanup or remove any event listeners if needed
    };
  }, []); // Include loadCourse as a dependency

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authFetch.put(`/learning/course/${values._id}`, {
        ...values,
      });
      navigate("/auth/courseview/" + values.slug);
    } catch (err) {
      toast(err.response.data);
    }
  };

  const handleImage = async (e) => {
    // remove previous image
    if (values.image && values.image.Location) {
      await authFetch.post(`/learning/remove-image`, {
        image: values.image,
      });
    }

    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });

    Resizer.imageFileResizer(
      file, // file
      720, // maxWidth
      500, // maxHeight
      "JPEG", // compressionFormat
      100, // quality
      0, // rotation
      async (uri) => {
        // post to s3
        try {
          let { data } = await authFetch.post("/learning/upload-image", {
            image: uri,
          });

          setValues({ ...values, image: data, loading: false });
          setUploadButtonText("Upload image");
        } catch (err) {
          setValues({ ...values, loading: false });
          setUploadButtonText("Upload image");
          toast("Image upload failed. Try again.");
        }
      },
      "base64"
    );
  };

  const handleDeleteLesson = async (index) => {
    let allLessons = values.lessons;
    const removed = allLessons.splice(index2, 1);
    // remove previous video
    if (removed && removed.length && removed[0].video) {
      await authFetch.post(
        `/learning/course/remove-video/${values._id}`,
        removed[0].video
      );
      handleClose1();
    }

    setValues({ ...values, lessons: allLessons });

    const { data } = await authFetch.post(
      `/learning/course/${values._id}/${removed[0]._id}`
    );
    if (data.ok) toast("Deleted");
    handleClose1();
  };

  const handleVideo = async (e) => {
    // remove previous
    if (current.video && current.video.Location) {
      await authFetch.post(
        `/learning/course/remove-video/${values._id}`,
        current.video
      );
    }
    // upload
    const file = e.target.files[0];

    setUploadButtonText(file.name);
    setUploading(true);
    // send video as form data
    const videoData = new FormData();
    videoData.append("video", file);
    videoData.append("courseId", values._id);
    // save progress bar and send video as form data to backend
    const { data } = await authFetch.post(
      `/learning/course/upload-video/${values._id}`,
      videoData,
      {
        onUploadProgress: (e) =>
          setProgress(Math.round((100 * e.loaded) / e.total)),
      }
    );
    // once response is received

    setCurrent({ ...current, video: data });
    setUploading(false);
    handleClose();
  };

  const handleUpdateLesson = async (e) => {
    e.preventDefault();

    let { data } = await authFetch.put(
      `/learning/course/lesson/${values._id}/${current._id}`,
      current
    );

    handleClose();
    setUploadButtonText("Upload video");
    setProgress(0);
    setVisible(false);
    // update lessons
    if (data.ok) {
      let arr = values.lessons;
      const index = arr.findIndex((el) => el._id === current._id);
      arr[index] = current;
      setValues({ ...values, lessons: arr });

      handleClose();
    }
  };
  const [index2, setIndex2] = useState();

  const deleteThisLesson = (index, item) => {
    setShow1(true);
    setIndex2(index);
    setSelectedItem(item);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="pt-5 pb-5">
            <CourseEditForm
              values={values}
              handleChange={handleChange}
              handleImage={handleImage}
              handleSubmit={handleSubmit}
              preview={preview}
              uploadButtonText={uploadButtonText}
              markdownCheetsheetModal={markdownCheetsheetModal}
              setMarkdownCheetsheetModal={setMarkdownCheetsheetModal}
            />
          </div>
        </Row>
        <Row>
          <hr />
          <div className="row pb-5">
            <div className="col lesson-list">
              <p style={{ fontSize: "100%", fontWeight: "600" }}>
                {values && values.lessons && values.lessons.length} Lessons
                Uploaded{" "}
                <span style={{ fontSize: "60%", fontStyle: "italic" }}>
                  (Click on Lesson Name to update)
                </span>
              </p>

              <Table responsive hover style={{ backgroundColor: "#f8f8f8" }}>
                {values &&
                  values.lessons &&
                  values.lessons.map((item, index) => (
                    <tbody>
                      <tr className="trdata">
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>
                          <button
                            onClick={() => {
                              setShow(true);
                              setCurrent(item);
                            }}
                            className="ewfwefh"
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => deleteThisLesson(index, item)}
                            className="iukyuk"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            </div>
          </div>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%" }}>
              Update Lesson{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {noVideo && (
              <span
                style={{ color: "red", fontSize: "72%", textAlign: "center" }}
              >
                Video is not uploaded.
              </span>
            )}

            <UpdateLessonForm
              current={current}
              setCurrent={setCurrent}
              uploadVideoButtonText={uploadVideoButtonText}
              progress={progress}
              handleVideo={handleVideo}
              handleUpdateLesson={handleUpdateLesson}
              uploading={uploading}
              markdownCheetsheetModal={markdownCheetsheetModal}
              setMarkdownCheetsheetModal={setMarkdownCheetsheetModal}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil3" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
              {selectedItem && selectedItem.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "1em" }}>
            This action will permanently delete this lesson. This action cannot
            be reversed.
          </Modal.Body>
          <Modal.Footer>
            <button className="w443y4q" onClick={handleClose1}>
              Close
            </button>
            <button className="w443sy4q" onClick={handleDeleteLesson}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default CourseEdit;
