import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaMoneyBill, FaUserLock } from "react-icons/fa";
import p11 from "../../assets/newi/p11.png";
import p12 from "../../assets/newi/p12.png";
import MoreProduct from "../../components/Product/MoreProduct";

const ProductUser = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#383CA3" }}>
            <FaUserLock size={100} />
            <h1>User Permissions</h1>
            <p className="headpp">
              Secure and manage access within your church network with our User
              Permission feature. Control visibility and protect sensitive
              information, supported by robust audit trails for enhanced
              accountability and security.
            </p>
            <button className="headerintro-button" onClick={handleDemoClick}>
              Book a demo
            </button>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for effective church communication
            </p>
            <p className="product13">
              Your all-inclusive solution to configure, manage, and send
              automated reports, enhancing decision-making and administrative
              efficiency.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={p11} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">ACCESS LEVELS</p>
              <p className="proddz1">
                Define multiple access levels within the church management
                system to control who can view and manage specific data.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ROLE-BASED PERMISSIONS</p>
              <p className="proddz1">
                Assign permissions based on roles, ensuring that individuals
                only access information necessary for their tasks.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">AUDIT TRAILS</p>
              <p className="proddz1">
                Maintain a comprehensive audit trail for all system activity to
                monitor usage and changes over time.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">SECURITY SETTINGS</p>
              <p className="proddz1">
                Customize security settings to protect sensitive information and
                prevent unauthorized access.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">DATA ENCRYPTION</p>
              <p className="proddz1">
                Ensure that all sensitive data is encrypted, providing an
                additional layer of security against breaches.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">COMPLIANCE MONITORING</p>
              <p className="proddz1">
                Monitor and ensure compliance with legal and organizational data
                protection standards.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={p12} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#383CA3" />
      <Footer />
    </>
  );
};

export default ProductUser;
