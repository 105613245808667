import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./moreproduct.css";
import {
  FaAngleRight,
  FaMoneyBill,
  FaUserAlt,
  FaChild,
  FaPeopleArrows,
  FaMapMarked,
  FaFont,
  FaCalculator,
  FaPen,
  FaEnvelope,
  FaPrayingHands,
  FaPray,
  FaRunning,
  FaVideo,
  FaCalendar,
  FaBible,
  FaChartPie,
  FaNetworkWired,
  FaAutoprefixer,
  FaUserLock,
  FaCentercode,
  FaPlane,
  FaBuilding,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const MoreProduct = ({ color }) => {
  return (
    <Container fluid style={{ backgroundColor: color }} className="moreapp">
      <Row className="moreproduct1">
        <Col md={4} className="prodci">
          <FaUserAlt className="moreic" />
          <p className="moreip">People</p>
          <p>
            Effortlessly manage member data, tracking engagement and personal
            details to strengthen community ties. Our intuitive interface
            ensures seamless updates and easy accessibility, making every member
            feel valued and well-connected.
          </p>
          <Link to="/product/productpeople" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaMoneyBill className="moreic" />
          <p className="moreip">Church Giving</p>
          <p>
            Streamline your donation processes effortlessly. Our intuitive
            system allows easy contributions and provides robust tools to manage
            donations effectively, enhancing transparency and fostering
            generosity.
          </p>
          <Link to="/product/productgiving" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaChild className="moreic" />
          <p className="moreip">Children</p>
          <p>
            Navigate children's details smoothly, from participation to
            milestones. Our intuitive system fosters a supportive environment,
            ensuring easy updates and making each child feel integral to the
            community.
          </p>
          <Link to="/product/productchildren" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaPeopleArrows className="moreic" />
          <p className="moreip">Group</p>
          <p>
            Optimize group interactions with ease. Our system enhances
            collaboration by managing member roles and activities effectively.
            The intuitive interface ensures seamless updates, fostering a
            dynamic and cohesive group environment.
          </p>
          <Link to="/product/productgroup" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaMapMarked className="moreic" />
          <p className="moreip">Service Planner</p>
          <p>
            Efficiently plan and execute services with our sophisticated tool.
            It simplifies scheduling and resource management, ensuring smooth
            operations. The intuitive design enhances community engagement and
            service delivery.
          </p>
          <Link to="/product/productservice" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaFont className="moreic" />
          <p className="moreip">Forms</p>
          <p>
            Easily create and manage forms with our versatile tool. Tailor forms
            to gather essential information, engage users, and streamline data
            processing. The intuitive system ensures quick updates and effective
            insights collection.
          </p>
          <Link to="/product/productforms" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaPen className="moreic" />
          <p className="moreip">Learning Center</p>
          <p>
            Easily manage and enhance your educational offerings with our
            Learning Center. Design curricula that engage and educate,
            streamline content delivery, and monitor educational outcomes. Our
            intuitive platform ensures a impactful learning experiences.
          </p>
          <Link to="/product/productlearning" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaCalculator className="moreic" />
          <p className="moreip">Church Accounting</p>
          <p>
            Manage your church’s finances with ease using our Church Accounting
            feature. Track donations, oversee expenses, and generate detailed
            financial reports. This robust tool ensures accuracy and
            transparency, aiding strategic financial decisions.
          </p>
          <Link to="/product/productaccounting" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaPray className="moreic" />
          <p className="moreip">Fellowship</p>
          <p>
            Strengthen community ties with our Fellowship module. Facilitate and
            manage prayer requests and testimonies, enhancing spiritual support
            and engagement. This feature is designed to foster deeper
            connections and spiritual growth within your congregation.
          </p>
          <Link to="/product/productfellowship" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaRunning className="moreic" />
          <p className="moreip">Follow Up</p>
          <p>
            Streamline your follow-up process with precision. Automate
            reminders, customize communications, and track interactions
            effortlessly. This tool ensures that every engagement is meaningful
            and efficiently managed.
          </p>
          <Link to="/product/productfollowup" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaChartPie className="moreic" />
          <p className="moreip">Two-Way Messaging</p>
          <p>
            Enhance engagement with our 2-way Messaging feature. Facilitate
            immediate and interactive communication between church staff and
            members, fostering an environment where feedback is encouraged and
            everyone stays connected.
          </p>
          <Link to="/product/productmessaging" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaVideo className="moreic" />
          <p className="moreip">Livestream</p>
          <p>
            Expand your reach with our Livestream feature. Broadcast your
            services and events in real-time, ensuring high-quality streaming
            and interactive participation. This tool connects your community,
            wherever they are, making every session accessible and engaging.
          </p>
          <Link to="/product/productlivestream" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaCalendar className="moreic" />
          <p className="moreip">Events</p>
          <p>
            Effortlessly organize and manage church events. From registrations
            to logistics, our Events module simplifies every step. The intuitive
            system enhances participant engagement and ensures every event is
            memorable and well-coordinated.
          </p>
          <Link to="/product/productevents" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaBible className="moreic" />
          <p className="moreip">Ministry</p>
          <p>
            Optimize your ministry activities with our comprehensive tool.
            Schedule, coordinate, and engage your community through streamlined
            operations. This system enhances ministry effectiveness, ensuring
            all activities are well-managed.
          </p>
          <Link to="/product/productmessaging" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaNetworkWired className="moreic" />
          <p className="moreip">Church Campus</p>
          <p>
            Seamlessly manage multiple church locations with our Campus feature.
            This tool simplifies operations and fosters unity across all sites,
            enhancing communication and centralized control for a cohesive
            church network.
          </p>
          <Link to="/product/productcampus" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaPlane className="moreic" />
          <p className="moreip">Workflow</p>
          <p>
            Streamline routine church tasks with our Workflow feature. Automate
            birthday and anniversary notices, and efficiently integrate new
            members into groups, reducing administrative efforts and boosting
            member engagement.
          </p>
          <Link to="/product/productworkflow" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaUserLock className="moreic" />
          <p className="moreip">User Permissions</p>
          <p>
            Secure and manage access within your church network with our User
            Permission feature. Control visibility and protect sensitive
            information, supported by robust audit trails for enhanced
            accountability and security.
          </p>
          <Link to="/product/productuser" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
        <Col md={4} className="prodci">
          <FaBuilding className="moreic" />
          <p className="moreip">Message Center</p>
          <p>
            Improve administrative efficiency with our Message Center. Configure
            automated reports for strategic planning and decision-making,
            ensuring church leaders are well-informed and equipped to manage
            effectively.
          </p>
          <Link to="/product/productmessagecenter" className="moreiv">
            View Product <FaAngleRight />
          </Link>
        </Col>
      </Row>

      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default MoreProduct;
