import React from "react";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import HighLightText from "../HighLightText/HighLightText";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Image, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

const AddThumbnail = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFunc,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const fileInputRef = useRef(null);

  const handleClose = () => setShow(false);

  const { logoutUser, user, getEventByVolunteers } = useAppContext();
  const [useEvent, setUseEvent] = useState(false);

  useEffect(() => {
    getEventByVolunteers();
  }, []);

  const [useEventData, setUseEventData] = useState("");

  const maxChars = 5000;
  useEffect(() => {
    if (useEvent === false) {
      setUseEventData("");
    }
  }, [useEvent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // onFormSubmit({ title, description, tasks });
  };
  const [values, setValues] = useState({
    name: "",
    description: "",
    uploading: false,
    loading: false,
    churchName: user.churchName,
  });

  const [uploaded, setUploaded] = useState(false);
  const [image, setImage] = useState({});
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const maxSizeInBytes = 1024 * 1024; // 1 MB

  const handleImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 700, 500, "JPEG", 100, 0, async (uri) => {
      try {
        // Calculate the current size of the image in bytes
        const currentSizeInBytes = uri.length;

        // If the image size exceeds the maximum size, calculate a new quality value to meet the size constraint
        if (currentSizeInBytes > maxSizeInBytes) {
          const newQuality = Math.floor(
            (maxSizeInBytes / currentSizeInBytes) * 100
          );

          // Resize the image again with the new quality value
          Resizer.imageFileResizer(
            file,
            700,
            500,
            "JPEG",
            newQuality,
            0,
            async (newUri) => {
              // Now, 'newUri' contains the resized image with the adjusted quality to meet the size constraint

              try {
                let { data } = await authFetch.post("/learning/upload-image", {
                  image: newUri,
                });

                // set image in the state
                setImage(data);
                setValues({ ...values, loading: false });
                setUploaded(true);
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
              } catch (err) {
                setValues({ ...values, loading: false });
              }
            }
          );
        } else {
          // If the image size is already within the size constraint, upload it without further resizing
          let { data } = await authFetch.post("/livestream/upload-image", {
            image: uri,
          });

          // set image in the state
          setImage(data);
          setValues({ ...values, loading: false });
          setUploaded(true);
        }
      } catch (err) {
        setValues({ ...values, loading: false });
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      await authFetch.post(`/livestream/remove-image`, { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Image");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setValues({ ...values, loading: false });
      handleClose();
    } catch (err) {
      setValues({ ...values, loading: false });
    }
  };

  const publishNow = async (e) => {
    e.preventDefault();

    if (!image) {
      setErrorMessage("Please upload an image");
      return;
    }

    try {
      const dataxx = {
        image,
      };

      const response = await authFetch.post(
        "/livestream/postimagedata",
        dataxx
      );

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        handleClose();
        setValues({ ...values, loading: false });
        setUploaded(true);
      }
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }
  };

  const handleImageRemoveAll = async () => {
    try {
      setValues({ ...values, loading: true });
      await authFetch.post(`/livestream/remove-image-all`, {
        image: user.thumbnail,
      });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Image");
      setValues({ ...values, loading: false });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      handleClose();
    } catch (err) {
      setValues({ ...values, loading: false });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add/Update Livestream Thumbnail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form onSubmit={handleSubmit}>
            {user && !user.thumbnail && (
              <Form.Group controlId="formFile" className="mb-3">
                {values.loading ? (
                  <p className="corelabel">Uploading</p>
                ) : (
                  <p className="corelabel">Image Upload</p>
                )}
                <Form.Control
                  type="file"
                  name="image"
                  onChange={handleImage}
                  accept="image/*"
                  className="lpm"
                  ref={fileInputRef}
                />
                <p
                  style={{
                    fontSize: "70%",
                    color: "#FE7064",
                  }}
                >
                  For the best quality, image dimension should be 700 by 500.
                </p>
              </Form.Group>
            )}

            {preview && (
              <>
                <p className="corelabel">Image Preview</p>
                <div className="flex gap-5 items-center mb-5 mt-3">
                  <Image width={240} src={preview} />
                  <FaTimes onClick={handleImageRemove} />
                </div>
              </>
            )}
          </Form>
          <div>
            {user && user.thumbnail ? (
              <div>
                <p className="corelabel">Current Thumbnail</p>
                <div className="flex gap-5 items-center mb-5 mt-3">
                  <Image width={240} src={user.thumbnail.Location} />
                  <FaTimes onClick={handleImageRemoveAll} />
                </div>
              </div>
            ) : (
              <div>
                <p className="corelabel">No Current Thumbnail</p>
                {/* You can add any placeholder or message here */}
              </div>
            )}
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>

        <button className="jiluil" onClick={publishNow}>
          Upload
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddThumbnail;
