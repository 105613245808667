import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const ShareAudio = ({ show, setShow, selectedValue }) => {
  const {
    logoutUser,
    completemembers,
    user,
    uniquecontainer,
    getContainers,
    containers,
    getCompleteMembers,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [group, setGroup] = useState("");
  const [giverName, setGiverName] = useState("");
  const [selectedName, setSelectedName] = useState([]);
  const [groupValue, setGroupValue] = useState([]);
  const [notice, setNotice] = useState(false);

  useEffect(() => {
    getContainers();
    getCompleteMembers();
  }, []);

  const handleClose6 = () => setShow(false);
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    setGroup(value);
  };

  const handleChangex = (value) => {
    //console.log(`selected ${value}`);
    setGroup(value);
  };

  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
    setSelectedName([...selectedName, searchTerm]);
  };

  const transformedContainers = containers.map((container) => ({
    ...container,
    label: container.name, // Setting the label equal to the name field
    value: container._id, // Setting the id equal to the _id field
  }));
  const handleShareWithMember = async () => {
    try {
      const [fname, lname] = giverName.split(" ");
      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );

      const [memberIdOnly] = data;
      const sharedAudio = {
        mid: memberIdOnly._id,
      };

      const response = await authFetch.patch(
        `/learning/share-audio-member/${selectedValue._id}`,
        sharedAudio
      );

      if (response.status === 200 || response.status === 201) {
        setNotice(true);
        setGiverName("");
        setSelectedName([]);
        getContainers();
        getCompleteMembers();
        toast(
          <CustomToastContent
            message="Audio Shared Successfully"
            title="Success"
          />
        );
      } else if (
        response.status === 400 &&
        response.data.message === "Audio already shared with this member"
      ) {
        toast(
          <CustomToastContent
            message="Audio already shared with this member"
            title="Error"
          />
        );
      } else {
        toast(
          <CustomToastContent
            message="An error occurred while sharing audio"
            title="Error"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
      toast(
        <CustomToastContent
          message="An error occurred while sharing audio"
          title="Error"
        />
      );
    }
  };

  // const handleTest = (value) => {
  //   setGroupValue(value);
  // };

  const handleShareGroup = async () => {
    //console.log(groupValue);
    try {
      const sharedAudio = {
        group: groupValue,
        selectedValue: selectedValue._id,
      };

      const response = await authFetch.patch(
        `/learning/share-audio`,
        sharedAudio
      );
      if (response.status === 200 || response.status === 201) {
        setGroupValue([]);
        getContainers();
        getCompleteMembers();
        handleClose6();
        toast(
          <CustomToastContent
            message="Audio Shared Successfully"
            title="Success"
          />
        );
      } else {
        toast(
          <CustomToastContent
            message="An error occurred while sharing audio"
            title="Error"
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <Modal
      title="Share Audio"
      open={show}
      onOk={handleClose6}
      onCancel={handleClose6}
    >
      <p>You share this audio with members or groups of your community.</p>
      <div className="flex flex-column w-100">
        <div className="flex flex-column w-100">
          <Select
            style={{ width: "100%" }}
            placeholder="Select Group"
            onChange={handleChangex}
            value={group}
            options={[
              {
                value: "member",
                label: "Members",
              },
              {
                value: "group",
                label: "Groups",
              },
            ]}
          />

          {group === "group" && (
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                onChange={(e) => setGroupValue(e)}
                options={transformedContainers}
                value={groupValue}
                className="
                  my-3"
              />
              <button
                disabled={groupValue.length === 0}
                className="jiluil3"
                onClick={handleShareGroup}
              >
                <PlusOutlined classID="mccccc" /> Share with Groups
              </button>
            </div>
          )}

          {group === "member" && (
            <div>
              <div className="flex items-start flex-column justify-between  ">
                <Form.Control
                  type="text"
                  value={giverName}
                  onChange={onChange}
                  required
                  placeholder="Enter Member Name"
                  className="my-3 "
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = giverName.toLowerCase();
                    const fname = item.mfirstName.toLowerCase();
                    const lname = item.mlastName.toLowerCase();
                    const fullname = fname + lname;
                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        onSearch(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
              <button
                disabled={!giverName}
                className="jiluil3"
                onClick={handleShareWithMember}
              >
                <PlusOutlined classID="mccccc" /> Share with Member
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShareAudio;
