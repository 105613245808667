import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";

import axios from "axios";

const EditCustomPayment = ({
  showEditModal,
  setShowEditModal,
  payment,
  setPayment,
  pullData,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => {
    setUpdate(false);
    setError(false);
    setPaymentName("");
    setPaymentDescription("");
    setPaymentAmount("");
  };

  useEffect(() => {
    if (payment) {
      setPaymentAmount(payment.paymentAmount);
      setPaymentDescription(payment.paymentDescription);
      setPaymentName(payment.paymentName);
    }
  }, [payment]);
  //const handleShow = () => setShowx(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [paymentName, setPaymentName] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const handleCloseEdit = () => setShowEditModal(false);

  const handleAudio = async (e) => {
    e.preventDefault();

    if (!paymentName || !paymentDescription || !paymentAmount) {
      setError(true);
      return;
    }

    if (paymentAmount < 1) {
      setError(true);
      return;
    }
    try {
      const { data } = await authFetch.put(
        `/payment/updatefixedpricepaymentlink/${user._id}`,
        {
          paymentName,
          paymentDescription,
          paymentAmount,
          priceId: payment?.priceId,
        }
      );

      pullData();

      handleCloseEdit();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={showEditModal} onHide={handleCloseEdit}>
      <Modal.Header closeButton>
        <Modal.Title>Update Custom Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {error && (
            <p className="inlineerror">
              Please check all fields and try again!
            </p>
          )}

          {update && <p className="inlinesuccess">Custom Payment Created!</p>} */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Name</Form.Label>
          <Form.Control
            type="text"
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="description"
            onChange={(e) => setPaymentDescription(e.target.value)}
            value={paymentDescription}
            className="lpmw"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Payment Amount</Form.Label>
          <Form.Control
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            required
            min={1}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleCloseEdit}>
          Close
        </button>
        <button className="jiluil" onClick={handleAudio}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCustomPayment;
