import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../../../context/appContext";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import AddFolderModal from "../../../components/Tags/AddFolderModal";
import AddTagModal from "../../../components/Tags/AddTagModal";
import EditFolderModal from "../../../components/Tags/EditFolderModal";
import EditTagModal from "../../../components/Tags/EditTagModal";
import "./tags.css";
import Alert from "../../../components/Alert/Alert";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";
import DeleteTagModal from "../../../components/Tags/DeleteTagModal";
import DeleteFolderModal from "../../../components/Tags/DeleteFolderModal";

const TagBody = () => {
  const {
    rootFolder,
    getRootFolder,
    deleteTag,
    deleteFolder,
    logoutUser,
    showAlert,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: "process.env.REACT_APP_BACKEND_URL",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [addTagModal, setAddTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [editTagID, setEditTagID] = useState("");
  const [delete_id, setDelete_id] = useState("");
  const [editTagName, setEditTagName] = useState("");
  const [editCampusName, setEditCampusName] = useState("");
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [editFolderID, setEditFolderID] = useState("");
  const [editFolderName, setEditFolderName] = useState("");
  const [addFolderModal, setAddFolderModal] = useState(false);
  const toggle = () => {
    setAddTagModal(!addTagModal);
  };
  const editModal = (id, name, campusName) => {
    setEditTagID(id);
    setEditTagName(name);
    setEditCampusName(campusName);
    setEditTagModal(true);
  };
  const handleEditFolderModal = (id, name) => {
    setEditFolderID(id);
    setEditFolderName(name);
    setEditFolderModal(true);
  };
  const toggleEditTag = () => {
    setEditTagID("");
    setEditTagName("");
    setEditCampusName("");
    setEditTagModal(false);
  };

  const [showTagModal, setShowTagModal] = useState(false);
  const [tagDelete, setTagDelete] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [folderDelete, setFolderDelete] = useState(null);
  const deleteTagData = (e, tag) => {
    e.preventDefault();
    setShowTagModal(true);
    setTagDelete(tag?._id);
  };

  const deleteFolderData = (e, folder) => {
    e.preventDefault();
    //console.log(tag);
    setShowFolderModal(true);
    setFolderDelete(folder?._id);
  };
  const toggleEditFolder = () => {
    setEditFolderID("");
    setEditFolderName("");
    setEditFolderModal(false);
  };
  const toggleFolder = () => {
    setAddFolderModal(!addFolderModal);
  };
  useEffect(() => {
    getRootFolder();
  }, []);

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Ministries</div>
        <div className="bannersmall">
          Streamline your church's workforce management by centralizing the
          organization of ministries. Easily coordinate and assign leaders to
          maintain smooth operations and foster a well-organized community.
        </div>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo flex gap-3">
          <button
            className="jiluil"
            onClick={() => {
              setAddTagModal(!addTagModal);
            }}
            style={{ fontSize: "1em" }}
          >
            Add Ministry
          </button>
          <button
            className="jiluil3"
            onClick={() => {
              setAddFolderModal(!addFolderModal);
            }}
            style={{ fontSize: "1em" }}
          >
            Add Folder
          </button>
        </div>
      </Row>

      <Row style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Table hover style={{ backgroundColor: "#f7f7f7" }}>
          <tbody>
            {rootFolder?.folders
              ?.sort(function (a, b) {
                if (a.folderName.toLowerCase() < b.folderName.toLowerCase()) {
                  return -1;
                }
                if (a.folderName.toLowerCase() > b.folderName.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map((folder, idx) => {
                return (
                  <tr key={idx} className="tag-row draggable " draggable="true">
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width={20}
                        height={15}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="folder"
                        className="svg-inline--fa fa-folder fa-w-16 folder-component__icon"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z"
                        ></path>
                      </svg>
                      <span className="tag__link wqjuy">
                        <Link
                          to={`/auth/folder-detail/${folder?._id}`}
                          draggable="false"
                          className="tav2"
                        >
                          {folder?.folderName}
                        </Link>
                      </span>
                    </td>

                    <td
                    // style="min-width: 8%;"
                    >
                      <div className="flex items-center gap-3 justify-end">
                        <button
                          className="jiluil3"
                          onClick={() =>
                            handleEditFolderModal(
                              folder._id,
                              folder?.folderName
                            )
                          }
                        >
                          Edit
                        </button>

                        <button
                          className="jiluil"
                          onClick={(e) => deleteFolderData(e, folder)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {rootFolder?.tags
              ?.sort(function (a, b) {
                if (a.tagName.toLowerCase() < b.tagName.toLowerCase()) {
                  return -1;
                }
                if (a.tagName.toLowerCase() > b.tagName.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map((tag, idx) => {
                return (
                  <tr key={idx} className="tag-row draggable" draggable="true">
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width={20}
                        height={15}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="tag"
                        className="svg-inline--fa fa-tag fa-w-16 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"
                        ></path>
                      </svg>
                      <span className="tag__link wqjuy">
                        <Link
                          to={`/auth/tag-detail/${tag?.tagName}`}
                          draggable="false"
                          className="tav"
                        >
                          {tag?.tagName}
                        </Link>
                      </span>
                    </td>

                    <td
                    // style="min-width: 8%;"
                    >
                      <div className="justify-end flex items-end gap-3">
                        <button
                          className="jiluil3"
                          onClick={() =>
                            editModal(tag._id, tag?.tagName, tag.campusName)
                          }
                        >
                          Edit
                        </button>

                        <button
                          className="jiluil"
                          onClick={(e) => deleteTagData(e, tag)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
      <AddTagModal
        addTagModal={addTagModal}
        toggle={toggle}
        name={"All Ministries"}
        id={""}
      />
      <AddFolderModal
        addFolderModal={addFolderModal}
        toggleFolder={toggleFolder}
        name={"All Ministries"}
        id={""}
      />
      <EditTagModal
        editTagModal={editTagModal}
        toggleEditTag={toggleEditTag}
        name={editTagName}
        campusName={editCampusName}
        id={editTagID}
      />
      <EditFolderModal
        editFolderModal={editFolderModal}
        toggleEditFolder={toggleEditFolder}
        name={editFolderName}
        id={editFolderID}
      />

      <DeleteTagModal
        showTagModal={showTagModal}
        setShowTagModal={setShowTagModal}
        tagDelete={tagDelete}
      />

      <DeleteFolderModal
        showFolderModal={showFolderModal}
        setShowFolderModal={setShowFolderModal}
        folderDelete={folderDelete}
      />

      {/* <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Folder</ModalHeader>
        {showAlert && <Alert />}
        <ModalBody className="modal-wrapper__body">
          <Row>
            <Col md="12"></Col>
            <div class="jsx-parser">
              {deleteItem === "tag"
                ? "Do you really want to remove this ministry?"
                : " Do you really want to remove this folder? All ministries and folders within this folder will also be deleted."}
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button className="jiluil3" onClick={toggleDeleteModal}>
            Cancel
          </button>
          <button
            onClick={async () => {
              if (deleteItem === "tag") {
                await deleteTag(delete_id);
              } else if (deleteItem === "folder") {
                await deleteFolder(delete_id);
              }
              toggleDeleteModal();
              await getRootFolder();
            }}
            className="jiluil"
          >
            Delete
          </button>
        </ModalFooter>
      </Modal> */}
    </Container>
  );
};

export default TagBody;
