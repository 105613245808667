import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";

const AddLessonPlanner = ({ show, setShow, handleReload, teacherDetails }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceDescription, setResourceDescription] = useState("");
  const [resourceType, setResourceType] = useState("audio");
  const [resourceLink, setResourceLink] = useState("");
  const [resourceFile, setResourceFile] = useState(null);
  const [resourceTags, setResourceTags] = useState("");
  const [resourceCategory, setReourceCategory] = useState("");

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Audio");
  const [uploadLink, setUploadLink] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [error, setError] = useState("");
  const [additionalResources, setAdditionalResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [libraryResources, setLibraryResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resourceData, setResourceData] = useState([]);

  const initialLessonPlanState = {
    title: "",
    dateLessonDate: "",
    topic: "",
    file: null,
    resourceType: "",
    resourceLink: "",
    lessonFocus: "",
    learningObjectives: "",
    classActivities: "",
  };

  const resetLessonPlanForm = () => {
    setLessonPlan({
      title: "",
      dateLessonDate: "",
      topic: "",
      file: null,
      resourceType: "",
      resourceLink: "",
      lessonFocus: "",
      learningObjectives: "",
      classActivities: "",
    });
  };

  const [lessonPlan, setLessonPlan] = useState(initialLessonPlanState);

  const handleChange = (e) => {
    setLessonPlan({ ...lessonPlan, [e.target.id]: e.target.value });
  };

  const handleDateChange = (e) => {
    // Ensure the date is handled correctly
    setLessonPlan({ ...lessonPlan, dateLessonDate: e.target.value });
  };

  useEffect(() => {
    loadCourse();
  }, []);

  const loadCourse = async () => {
    try {
      setLoading(true); // Set loading state to true before fetching data
      const { data } = await authFetch.get("/children/resource");
      setResourceData(data);
    } catch (error) {
      console.error("Error loading course resources:", error);
      // Optionally, set an error state here to display an error message to the user
      setError("Failed to load resources. Please try again later.");
    } finally {
      setLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };

  const handleResourceUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        // toast("No file selected");
        return;
      }

      setResourceFile(file); // Assuming you have a state setter for the resource file
      setUploadButtonText(file.name);
      setUploading(true);

      const resourceData = new FormData();
      resourceData.append("resource", file); // 'resource' should match the field name expected by your backend

      try {
        const { data } = await authFetch.post(
          "/children/resource/uploadfile", // Adjust the API endpoint as needed
          resourceData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );
        console.log("Uploaded resource data:", data);

        // Correct way to update the lessonPlan state assuming it's an object and file is a property
        setLessonPlan((prevState) => ({
          ...prevState,
          file: data, // Assuming `data` contains the file info you need
        }));
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
        setUploading(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleResourceSubmit = async (e) => {
    e.preventDefault();

    const [trimmedName, trimmedDescription] = trimInputs([
      lessonPlan.title,
      resourceDescription,
    ]);

    setError("");

    if (!validateNotEmpty(trimmedName)) {
      toast(
        <CustomToastContent title="Error" message="Select a resource to add." />
      );
      return;
    }

    if (lessonPlan.resourceType === "") {
      toast(
        <CustomToastContent
          title="Error"
          message="Resource Type cannot be empty."
        />
      );
      return;
    }

    // Validate name length
    if (!validateLength(trimmedName, NAME_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Resource Name cannot exceed ${NAME_LENGTH_LIMIT} characters.`}
        />
      );

      return;
    }

    // Validate description length
    if (!validateLength(trimmedDescription, DESCRIPTION_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} characters.`}
        />
      );
      return;
    }

    const resourceDataxx = {
      title: trimmedName,
      description: trimmedDescription,
      topic: lessonPlan.topic,
      dateLessonDate: lessonPlan.dateLessonDate,
      teacherId: teacherDetails,
      churchName: user.churchName,
      additionalResources: additionalResources,
      type: lessonPlan.resourceType, // Assuming you have a way to set the type of the resource

      link: lessonPlan.resourceLink,
      files: lessonPlan.file, // Assuming you have a state for the file like `resourceFile`
      lessonFocus: lessonPlan.lessonFocus,
      learningObjectives: lessonPlan.learningObjectives,
      classActivities: lessonPlan.classActivities,
    };

    try {
      const response = await authFetch.post(
        "/children/lessonplanner",
        resourceDataxx
      );
      if (response.status === 200 || response.status === 201) {
        resetLessonPlanForm();
        // searchTerm("");
        handleClose();
        handleReload();
        setLessonPlan(initialLessonPlanState);
        setAdditionalResources([]);

        setProgress(0);
        setUploading(false);
        setUploadButtonText("Upload another resource");
        setShow(false);
        toast(
          <CustomToastContent
            title="Success"
            message="Lesson Plan added successfully."
          />
        );
      }
    } catch (err) {
      toast(
        <CustomToastContent
          title="Error"
          message="Failed to upload resource. Please try again."
        />
      );
      setUploading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Lesson Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>
              Lesson Plan Title <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={lessonPlan.title}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="topic">
            <Form.Label>
              Topic <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={lessonPlan.topic}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="lessonFocus">
            <Form.Label>Lesson Focus</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={lessonPlan.lessonFocus}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="learningObjectives">
            <Form.Label>Learning Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={lessonPlan.learningObjectives}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="classActivities">
            <Form.Label>Class Activities</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={lessonPlan.classActivities}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search by topic or subject"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ fontSize: "1em" }}
              required
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                position: "absolute",
              }}
            >
              {resourceData
                .filter((resource) => {
                  const lowercaseSearchTerm = searchTerm.toLowerCase();
                  const topic = resource.title.toLowerCase();
                  const subject = resource.description.toLowerCase();

                  return (
                    lowercaseSearchTerm &&
                    (topic.includes(lowercaseSearchTerm) ||
                      subject.includes(lowercaseSearchTerm))
                  );
                })
                .slice(0, 10)
                .map((resource) => (
                  <div
                    onClick={() => {
                      setAdditionalResources([
                        ...additionalResources,
                        resource,
                      ]);
                      setSearchTerm(""); // Clear search term after selection
                    }}
                    className="dropdownmy-row ewfwefwefwe"
                    style={{ width: "50%" }}
                    key={resource.id} // Assume each resource has a unique 'id' property
                  >
                    {/* {resource.title} - {resource.topic}/{resource.subject} */}
                    {resource.title} - {resource.description}
                  </div>
                ))}
            </div>
          </Form.Group>

          <div>
            {additionalResources.map((resource) => (
              <div
                key={resource.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "10px", // Add some space between the items
                }}
              >
                <div>
                  {resource.title} - {resource.description}
                </div>
                <div>
                  <button
                    className="jiluil"
                    onClick={() =>
                      setAdditionalResources(
                        additionalResources.filter((r) => r.id !== resource.id)
                      )
                    }
                    style={{ marginRight: "5px" }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>

          <Form.Group className="mb-3" controlId="dateLessonDate">
            <Form.Label>Lesson Date</Form.Label>
            <Form.Control
              type="date"
              value={lessonPlan.dateLessonDate}
              onChange={handleDateChange}
              required
            />
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="ageGroup">
            <Form.Label>Age Group</Form.Label>
            <Form.Control
              type="text"
              value={lessonPlan.ageGroup}
              onChange={handleChange}
            />
          </Form.Group> */}

          {/* <Form.Group className="mb-3" controlId="textContent">
            <Form.Label>Text Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={lessonPlan.textContent}
              onChange={handleChange}
            />
          </Form.Group> */}

          <Form.Group className="mb-3" controlId="resourceType">
            <Form.Label>
              Resource Type <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              value={lessonPlan.resourceType}
              onChange={handleChange}
            >
              <option value="">Select Type</option>
              <option value="document">Document</option>
              <option value="video">Video</option>
              <option value="audio">Audio</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>

          {lessonPlan.resourceType === "other" && (
            <Form.Group className="mb-3" controlId="resourceLink">
              <Form.Label>Resource Link</Form.Label>
              <Form.Control
                type="text"
                value={lessonPlan.resourceLink}
                onChange={handleChange}
                placeholder="https://example.com/resource"
              />
            </Form.Group>
          )}

          {/* Upload field appears only if 'document', 'video', or 'audio' is selected */}
          {["document", "video", "audio"].includes(lessonPlan.resourceType) && (
            <Form.Group className="mb-3" controlId="uploadResource">
              <Form.Label>Upload Resource</Form.Label>
              <Form.Control
                type="file"
                onChange={handleResourceUpload}
                accept="audio/*, video/*, application/pdf" // Adapt based on your allowed types
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleResourceSubmit}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLessonPlanner;
