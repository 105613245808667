import React from "react";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { useAppContext } from "../../context/appContext";

const PrintOrderOfService = React.forwardRef((props, ref) => {
  const { user } = useAppContext();
  const { uniqueservice } = props;

  function getTimeDifferenceInMinutes(startTime, endTime) {
    const startDate = new Date(`1970-01-01 ${startTime}:00`);
    const endDate = new Date(`1970-01-01 ${endTime}:00`);

    const differenceInMilliseconds = endDate - startDate;
    const minutes = differenceInMilliseconds / 1000 / 60;

    if (minutes < 60) {
      return `${minutes} minutes`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hours ${remainingMinutes} minutes`;
    }
  }

  return (
    <>
      <Container fluid>
        <div ref={ref} style={{ padding: "3rem" }}>
          <div>
            <p>{user.churchName}</p>
            <p>
              {user.maddress}, {user.mcity}, {user.mpostal}{" "}
            </p>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th style={{ fontSize: "1em" }}>Start Time</th>
                <th style={{ fontSize: "1em" }}>End Time</th>
                <th style={{ fontSize: "1em" }}>Activity</th>
                <th style={{ fontSize: "1em" }}>Duration</th>
                <th style={{ fontSize: "1em" }}>Person InCharge</th>
              </tr>
            </thead>
            {uniqueservice?.activities?.map((data, index) => (
              <tbody>
                <tr key={data._id}>
                  <td style={{ fontSize: "1em", width: "10%" }}>
                    {data.startTime}
                  </td>
                  <td style={{ fontSize: "1em", width: "10%" }}>
                    {data.endTime}
                  </td>
                  <td style={{ fontSize: "1em", width: "35%" }}>
                    {data.activityName}
                  </td>
                  <td style={{ fontSize: "1em", width: "20%" }}>
                    {getTimeDifferenceInMinutes(data.startTime, data.endTime)}
                  </td>
                  <td style={{ fontSize: "1em", width: "25%" }}>
                    {data.personInCharge}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
        ;
      </Container>
    </>
  );
});

export default PrintOrderOfService;
