import React, { useEffect } from "react";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./about.css";
import k55 from "../../assets/images/k55.jpg";
import k6 from "../../assets/images/k6.jpg";
import GetStarted from "../../components/Why/GetStarted";
import Blog from "../../components/Blog/Blog";
import { Helmet } from "react-helmet";
import { Col } from "react-bootstrap";
import Discover from "../../components/Efficiency/Discover";
import Efficiency from "../../components/Efficiency/Efficiency";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          About Us | Churchease - Comprehensive Church Management Software
        </title>
        <meta
          name="description"
          content="Discover Churchease, a comprehensive church management platform designed to streamline church administration, enhance member engagement, and efficiently manage church activities. Experience seamless church management with Churchease."
        />
        <link rel="canonical" href="https://www.churchease.com/aboutus" />
        <meta
          property="og:title"
          content="About Us | Churchease - Comprehensive Church Management Software"
        />
        <meta
          property="og:description"
          content="Discover Churchease, a comprehensive church management platform designed to streamline church administration, enhance member engagement, and efficiently manage church activities. Experience seamless church management with Churchease."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta property="og:url" content="https://www.churchease.com/aboutus" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Churchease, church management software, about Churchease, church administration, member engagement, church activities, church software"
        />
      </Helmet>

      <Header />
      <Container fluid>
        <Row className="about">
          <p className="abouttext">About Churchease</p>
        </Row>
        <Row className="about11">
          <Col md={6} lg={6} sm={12} xs={12}>
            <div className="imgh">
              <img className="aboutimg" src={k55} alt="about" />
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <div className="imgh">
              <img className="aboutimg" src={k6} alt="about" />
            </div>
          </Col>
        </Row>
        <Row className="about1">
          <div style={{ paddingTop: "3rem" }}>
            <div>
              <p style={{ marginBottom: "0" }}>
                <span className="eee2">ABOUT US</span>
              </p>
              <p className="aboutheader">Bridging Faith And Technology</p>
            </div>
            <p className="aboutbody">
              At Churchease, we believe that technology has the power to bring
              positive change to every corner of society, including places of
              worship. Our journey began with a deep passion for both faith and
              innovation, leading us to create a church management app that
              bridges the gap between the spiritual and the digital.
            </p>
          </div>
        </Row>
        <Row className="about1">
          <div>
            <div>
              <p style={{ marginBottom: "0" }}>
                <span className="eee2">OUR INSPIRTRATION</span>
              </p>
              <p className="aboutheader">Empowering Communities of Faith</p>
            </div>
            <p className="aboutbody">
              As active members of our own church communities, we witnessed the
              dedication and tireless efforts of clergy members, volunteers, and
              leaders who work diligently to strengthen their congregations.
              Yet, managing the administrative tasks of a church can be
              overwhelming, taking precious time away from building connections
              and fostering spiritual growth.
              <br></br> This is where our inspiration ignited. We saw an
              opportunity to enhance the operational efficiency of churches,
              enabling them to focus on what truly matters: nurturing
              connections, spreading love, and fostering a sense of belonging
              within their congregations.
            </p>
          </div>
        </Row>
        <Row className="about1">
          <div>
            <div>
              <p style={{ marginBottom: "0" }}>
                <span className="eee2">OUR VISION</span>
              </p>
              <p className="aboutheader">Strengthening The Bonds Of Faith </p>
            </div>

            <p className="aboutbody">
              Our church management app is more than just software; it's a tool
              that empowers churches to thrive. By seamlessly integrating
              essential tasks such as attendance tracking, event management,
              communication, and donation tracking, we're creating a platform
              that allows faith communities to flourish.
              <br></br> We envision a world where church leaders can dedicate
              more time to meaningful interactions, where members can stay
              connected and engaged effortlessly, and where the administrative
              burden is eased so that the spiritual journey remains the central
              focus.
            </p>
          </div>
        </Row>
        <Row className="about1">
          <div>
            <div>
              <p style={{ marginBottom: "0" }}>
                <span className="eee2">OUR COMMITMENT</span>
              </p>
              <p className="aboutheader">Excellence And Empathy</p>
            </div>

            <p className="aboutbody">
              At Churchease, we are driven by a dual commitment: excellence in
              technology and empathy in service. Our team comprises individuals
              who are not only skilled in software development but who also
              understand the unique needs and challenges faced by faith-based
              organizations.
              <br></br>
              With our roots firmly planted in faith, we approach our work with
              a genuine understanding of the profound impact a church can have
              on individuals and communities. This fuels our dedication to
              creating a church management app that is not only functional but
              deeply resonant with the values of compassion, unity, and service.
            </p>
          </div>
        </Row>
        <Row className="about1" style={{ paddingBottom: "3rem" }}>
          <div>
            <div>
              <p style={{ marginBottom: "0" }}>
                <span className="eee2">LET'S GO</span>
              </p>
              <p className="aboutheader">Join Us On This Journey</p>
            </div>

            <p className="aboutbody">
              We invite you to join us on this transformative journey where
              technology and faith converge. Whether you're a church leader
              seeking efficient management solutions or a member yearning for
              stronger connections, we're here to provide a platform that
              supports your aspirations.
              <br></br>
              Together, we are building bridges—bridges between faith and
              technology, between churches and their communities, and between
              the spiritual and the practical. Welcome to the future of church
              management, where innovation meets devotion.
            </p>
          </div>
        </Row>
        <Row>
          {/* <GetStarted /> */}
          <Efficiency />
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default About;
