import React from "react";
import "./feature.css";
import AnnualPlan from "./AnnualPlan";
import Monthlyplan from "./Monthlyplan";
import TwoYearsPlan from "./TwoYearsPlan";
import { Container, Row, Col } from "react-bootstrap";

const PriceDetails = ({ totalPrice, selectedFeatures, size }) => {
  const displayedPrice = Number(totalPrice).toFixed(2);

  return (
    <Row className="pridest">
      <Col xs={12} md={4} className=" prqir">
        <Monthlyplan
          totalPrice={displayedPrice}
          selectedFeatures={selectedFeatures}
          size={size}
        />
      </Col>
      <Col xs={12} md={4} className=" prqir">
        <AnnualPlan
          totalPrice={displayedPrice}
          selectedFeatures={selectedFeatures}
          size={size}
        />
      </Col>
      <Col xs={12} md={4} className="prqir">
        <TwoYearsPlan
          totalPrice={displayedPrice}
          selectedFeatures={selectedFeatures}
          size={size}
        />
      </Col>
    </Row>
  );
};

export default PriceDetails;
