import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import {
  FaBible,
  FaCalculator,
  FaCalendar,
  FaChartPie,
  FaCheck,
  FaChess,
  FaChild,
  FaEnvelope,
  FaFont,
  FaMapMarked,
  FaMicrophone,
  FaMoneyBill,
  FaPen,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
} from "react-icons/fa";

const allFeatures = [
  {
    name: "People",
    cost: 0,
    description: "Manage church member details and records.",
    image: <FaUserAlt />,
  },
  {
    name: "Church Giving",
    cost: 9,
    description: "Facilitate tithes, offerings, and donations digitally.",
    image: <FaMoneyBill />,
  },
  {
    name: "Church Accounting",
    cost: 7,
    description:
      "Manage your church’s finances with ease using our Church Accounting feature",
    image: <FaCalculator />,
  },
  {
    name: "Ministry",
    cost: 6,
    description: "Tools for managing various ministries within the church.",
    image: <FaPeopleArrows />,
  },
  {
    name: "Groups",
    cost: 8,
    description: "Organize and manage small groups for better engagement.",
    image: <FaUserFriends />,
  },
  {
    name: "Follow-Up",
    cost: 6,
    description: "Track and follow up with visitors and members.",
    image: <FaRunning />,
  },
  {
    name: "Events",
    cost: 8,
    description: "Plan and manage church events seamlessly.",
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",
    cost: 5,
    description:
      "Schedule and plan services, including volunteers and resources.",
    image: <FaCheck />,
  },
  {
    name: "Forms",
    cost: 7,
    description: "Create custom forms for surveys, signups, and more.",
    image: <FaFont />,
  },
  {
    name: "Fellowship",
    cost: 6,
    description:
      "Enhance the sense of community and fellowship within the church.",
    image: <FaPray />,
  },
  {
    name: "Learning Center",
    cost: 10,
    description: "Educational tools for discipleship and learning.",
    image: <FaEnvelope />,
  },
  {
    name: "Live Streaming",
    cost: 7,
    description:
      "Broadcast your services and events live to reach a wider audience.",
    image: <FaVideo />,
  },

  {
    name: "Communication",
    cost: 5,
    description:
      "Comprehensive tools for effective communication within the church community.",
    image: <FaTextHeight />,
  },
  {
    name: "Children",
    cost: 9,
    description:
      "Streamline children's management and development with automated, round-the-clock support.",
    image: <FaChild />,
  },
];

const levelWeights = {
  L1: 0.4,
  L2: 0.5,
  L3: 0.65,
  L4: 0.85,
  L5: 1.2,
  L6: 1.4,
  L7: 1.8,
  L8: 2.0,
};

const EditSubscription = ({ show, setShow, onHide, uploadSuccessFunc }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //const [tasks, setTasks] = useState([""]);
  const [notifyMe, setNotifyMe] = useState(false);
  const [assign, setAssign] = useState(false);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [size, setSize] = useState("");
  const [entitlement, setEntitlement] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (user && user.churchSize && user.entitlement) {
      setSize(user.churchSize);
      setEntitlement(user.entitlement);
    }
  }, [user]);

  // const handleAddfield = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (!title) return setError(true);

  //     const dataxx = {
  //       title,
  //     };

  //     const { data } = await authFetch.post("/dynamicfields", dataxx);
  //     if (data.message === "Dynamic fields added successfully") {
  //       onHide();
  //       setTitle("");

  //       handleClose();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    // Ensure both entitlement and allFeatures are truthy and arrays before proceeding
    if (Array.isArray(entitlement) && Array.isArray(allFeatures)) {
      // Initialize selectedFeatures based on entitlement
      const initialSelectedFeatures = allFeatures
        .filter((feature) => entitlement.includes(feature.name))
        .map((feature) => feature.name);

      setSelectedFeatures(initialSelectedFeatures);
    }
  }, [entitlement, allFeatures]); // Include allFeatures in the dependency array if it can change

  useEffect(() => {
    // Calculate total price based on selected features and size
    const sumProductCost = selectedFeatures.reduce((total, featureName) => {
      const feature = allFeatures.find((f) => f.name === featureName);
      return total + (feature ? feature.cost : 0);
    }, 0);

    const weight = levelWeights[size] || 0;
    setTotalPrice(sumProductCost * weight);
  }, [selectedFeatures, size]);

  const handleFeatureChange = (featureName, isChecked) => {
    setSelectedFeatures((prev) => {
      if (isChecked) {
        // Add the feature name if checked
        return [...prev, featureName];
      } else {
        // Remove the feature name if unchecked
        return prev.filter((name) => name !== featureName);
      }
    });
  };

  const handlePriceUpdate = (e) => {
    e.preventDefault();
    const data = {
      entitlement: selectedFeatures,
      churchSize: size,
      currency: "usd",
      interval: "month",
      price: totalPrice,
    };

    const updateSubscription = async () => {
      try {
        const response = await authFetch.put(
          "/payment/updatesubscription",
          data
        );
        if (response.status === 200 || response.status === 201) {
          onHide();
          window.location.reload();
          toast(
            <CustomToastContent
              title="Success"
              message="Plan updated successfully."
            />
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateSubscription();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {error && (
            <p className="inlineerror">
              Please complete the required fields to proceed!!
            </p>
          )}

          {error2 && (
            <p className="inlineerror">
              Description is above the required length!!
            </p>
          )}
          <Form>
            <div>
              <Form.Label className="label-feature">
                Select Church Size
              </Form.Label>
              <Form.Select
                className="select mb-4"
                aria-label="Select church size"
                onChange={(e) => setSize(e.target.value)}
                value={size}
              >
                <option value="">Church Size</option>
                <option value="L1">1 - 50 members</option>
                <option value="L2">51 - 100 members</option>
                <option value="L3">101 - 200 members</option>
                <option value="L4">201 - 300 members</option>
                <option value="L5">301 - 400 members</option>
                <option value="L6">401 - 500 members</option>
                <option value="L7">501 - 1000 members</option>
                <option value="L8">1001 - 2000 members</option>
              </Form.Select>
            </div>

            {size && (
              <div className="feature-selection">
                <div className="flex justify-end">
                  <h5 className="mt-5 text-bold">
                    Total Price: ${totalPrice.toFixed(2)}
                  </h5>
                </div>
                <Form.Label className="label-feature">
                  Select Features
                </Form.Label>
                {allFeatures.map((feature, index) => (
                  <div key={index} className="feature-item">
                    <div className="flex justify-between flex-row">
                      <Form.Check
                        type="checkbox"
                        id={`feature-${feature.name}`}
                        label={feature.name}
                        onChange={(e) =>
                          handleFeatureChange(feature.name, e.target.checked)
                        }
                        checked={selectedFeatures.includes(feature.name)} // Controlled by selectedFeatures state
                        disabled={feature.name === "People"} // Optionally disable certain checkboxes
                        className="feature-checkbox"
                      />
                      <div
                        style={{
                          fontSize: "2em",
                          color: "var(--primary-color)",
                        }}
                      >
                        {feature.image}
                      </div>
                    </div>
                    <p style={{ marginLeft: "78px", paddingTop: "1rem" }}>
                      {feature.description}
                    </p>
                  </div>
                ))}
                <div className="flex justify-end">
                  <h5 className="mt-5">
                    Total Price: ${totalPrice.toFixed(2)}
                  </h5>
                </div>
                <div className="flex justify-center">
                  <button className="pricebutton" onClick={handlePriceUpdate}>
                    Update Account
                  </button>
                </div>
              </div>
            )}
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSubscription;
