import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import { Container, Col } from "react-bootstrap";

import { Avatar } from "antd";

import Profile from "../../../assets/images/blank2.png";
import { Link } from "react-router-dom";

import "./savedview.css";
import { CSVLink } from "react-csv";
const SavedInfo = () => {
  const { _id } = useParams();
  const { getViewById, uniqueviewdetails } = useAppContext();

  useEffect(() => {
    getViewById(_id);
  }, [_id]);

  const [fnamex, setFnamex] = useState(true);

  const [photox, setPhotox] = useState(true);

  const [lnamex, setLnamex] = useState(true);

  const [emailx, setEmailx] = useState(true);

  const [genderx, setGenderx] = useState(false);

  const [statusx, setStatusx] = useState(true);

  const [maritalx, setMaritalx] = useState(false);

  const [agex, setAgex] = useState(false);

  const [familyx, setFamilyx] = useState(false);

  const [gradex, setGradex] = useState(false);

  const [schoolx, setSchoolx] = useState(false);

  const [employerx, setEmployerx] = useState(false);

  const [workPhonex, setworkPhonex] = useState(false);

  const [mobilex, setmobilePhonex] = useState(true);

  const [postalx, setPostalx] = useState(false);

  const [statex, setStatex] = useState(false);

  const [homephonex, sethomePhonex] = useState(false);

  const [cityx, setCityx] = useState(false);

  let dataSpread = uniqueviewdetails.viewMembers;

  const copiedArray = dataSpread?.map((obj) => ({
    mfirstName: obj.mfirstName,
    mlastName: obj.mlastName,
    memail: obj.memail,
    mmobile: obj.mmobile,
    mgender: obj.mgender,
    mstatus: obj.mstatus,

    mmartial: obj.mmartial,
    mage: obj.mage,
    mgrade: obj.mgrade,
    mschool: obj.mschool,
    memployer: obj.memployer,
    mworkPhone: obj.mworkPhone,
    mhomePhone: obj.mhomePhone,
    mpostal: obj.mpostal,
    mstate: obj.mstate,
    mcity: obj.mcity,
    mBirthday: obj.mBirthday,
    mAnniversary: obj.mAnniversary,
    maddress: obj.maddress,
  }));

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">{uniqueviewdetails?.viewName}</p>
      </Row>
      <Row className="tabw">
        {!uniqueviewdetails?.viewMembers?.length &&
          "Retrieving member records...."}

        <Col>
          <p
            style={{
              fontWeight: "700",
              fontSize: "1em",
              color: "var(--primary-color)",
              paddingTop: "2rem",
            }}
          >
            {uniqueviewdetails?.viewMembers?.length} record
            {uniqueviewdetails?.viewMembers?.length > 1 && "s"} found
          </p>
        </Col>
        <Col>
          {!!uniqueviewdetails?.viewMembers?.length && (
            <div className="flex justify-end">
              <button
                className="jiluil3"
                style={{
                  textDecoration: "none",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                }}
              >
                <CSVLink
                  data={copiedArray}
                  filename="savedview-churchease.csv"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download
                </CSVLink>
              </button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="tabw">
        <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
          <thead>
            <tr className="trdataheader">
              {!!photox && <th>PHOTO</th>}
              {!!fnamex && <th>FIRST NAME</th>}
              {!!lnamex && <th>LAST NAME</th>}
              {!!emailx && <th>EMAIL</th>}
              {!!mobilex && <th>MOBILE #</th>}
              {!!statusx && <th>STATUS</th>}
              {/* {!!genderx && <th>GENDER</th>} */}

              {/* {!!maritalx && <th>MARITAL STATUS</th>}
              {!!agex && <th>AGE</th>}
              {!!schoolx && <th>SCHOOL</th>}
              {!!gradex && <th>GRADE</th>}
              {!!homephonex && <th>HOME PHONE</th>}
              {!!workPhonex && <th>WORK PHONE</th>}
              {!!cityx && <th>CITY</th>}
              {!!postalx && <th>POSTAL CODE</th>}
              {!!employerx && <th>EMPLOYER</th>} */}
            </tr>
          </thead>
          {!!uniqueviewdetails?.viewMembers?.length &&
            uniqueviewdetails?.viewMembers?.map((member, index) => {
              return (
                <tbody>
                  <tr key={index} className="trdata">
                    {photox && (
                      <td>
                        <Avatar
                          size={30}
                          src={
                            member?.mProfilePicture?.Location
                              ? member?.mProfilePicture?.Location
                              : Profile
                          }
                        />
                      </td>
                    )}
                    {fnamex && (
                      <td style={{ fontSize: "1em" }}>
                        <Link
                          to={"/auth/peopleview/" + member._id}
                          className="pointer"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <a
                            className="text-primary"
                            style={{
                              textDecoration: "underline",
                              color: "black",
                              textDecorationColor: "black",
                            }}
                          >
                            <p style={{ color: "black" }}>
                              {member.mfirstName}
                            </p>
                          </a>
                        </Link>
                      </td>
                    )}

                    {lnamex && (
                      <td style={{ fontSize: "1em" }}>
                        <Link
                          to={"/auth/peopleview/" + member._id}
                          className="pointer"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <a
                            className="text-primary"
                            style={{
                              textDecoration: "underline",
                              color: "black",
                              textDecorationColor: "black",
                            }}
                          >
                            <p style={{ color: "black" }}>{member.mlastName}</p>
                          </a>
                        </Link>
                      </td>
                    )}
                    {/* {fnamex && <td>{member?.mfirstName}</td>}
                    {lnamex && <td>{member?.mlastName}</td>} */}
                    {emailx && <td>{member?.memail}</td>}
                    {mobilex && (
                      <td>
                        {member?.mmobilePhone ? member?.mmobilePhone : "NA"}
                      </td>
                    )}

                    {statusx && (
                      <td>{member?.mstatus ? member?.mstatus : "NA"}</td>
                    )}
                  </tr>
                </tbody>
              );
            })}
        </Table>
      </Row>
    </Container>
  );
};

export default SavedInfo;
