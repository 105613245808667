import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaMoneyBill } from "react-icons/fa";
import m21 from "../../assets/newi/m21.png";
import m22 from "../../assets/newi/m22.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductGiving = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Church Giving | Churchease - Streamline Your Donation Process
        </title>
        <meta
          name="description"
          content="Effortlessly manage church donations with Churchease. Our intuitive system simplifies contributions, manages donor data, and enhances transparency."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/church-giving"
        />

        <meta
          property="og:title"
          content="Church Giving | Churchease - Streamline Your Donation Process"
        />
        <meta
          property="og:description"
          content="Effortlessly manage church donations with Churchease. Our intuitive system simplifies contributions, manages donor data, and enhances transparency."
        />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta
          property="og:url"
          content="https://churchease.com/product/church-giving"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#B53BE0" }}>
            <div>
              <FaMoneyBill size={100} />
              <p className="headssc">Church Giving</p>
              <p className="headpp">
                Streamline your donation processes effortlessly. Our intuitive
                system allows easy contributions and provides robust tools to
                manage donations effectively, enhancing transparency and
                fostering generosity.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for church donations
            </p>
            <p className="product13">
              Your all-inclusive solution to manage and track all giving
              activities
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={m21} alt="Giving" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">SIMPLIFIED DONATION PROCESS</p>
              <p className="proddz1">
                Enable easy and quick online giving with a streamlined donation
                form that minimizes hassle for donors.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">AUTOMATED RECEIPT GENERATION</p>
              <p className="proddz1">
                Automatically send customized donation receipts via email
                immediately after each contribution is made.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">COMPREHENSIVE DONOR MANAGEMENT</p>
              <p className="proddz1">
                Track and manage donor information effectively, allowing for
                targeted communications and relationship building.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">REPORTS AND ANALYTICS</p>
              <p className="proddz1">
                Access detailed reports and analytics to understand giving
                patterns and optimize fundraising strategies.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RECURRING DONATIONS SETUP</p>
              <p className="proddz1">
                Allow donors to set up recurring donations easily, ensuring
                steady income streams and donor engagement over time.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">SECURE PAYMENT INTEGRATION</p>
              <p className="proddz1">
                Ensure safe and secure transactions with integrated payment
                solutions that protect both church and donor data.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={m22} alt="Giving" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#B53BE0" />
      <Footer />
    </>
  );
};

export default ProductGiving;
