import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import "./printcalendar.css";
import { Container, Row, Table } from "react-bootstrap";

const PrintCalendarList = React.forwardRef((props, ref) => {
  const { logoutUser, events, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [event, setEvent] = useState([]);
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const { data } = await authFetch.get(`/events`);

      if (data) {
        setEvent(data.events);
      }
      // console.log(response.data.payments); // Assuming you want to log the response data
    } catch (error) {
      // Using console.error for logging errors
    }
  };

  return (
    <Container fluid ref={ref}>
      {event && event.length > 0 ? (
        <Row className="tabw" style={{ paddingTop: "3rem" }}>
          <div>
            <h3 className="text-center">Calendar View</h3>
            <p className="text-center">
              {moment(new Date()).format("dddd, MMMM Do YYYY")}
            </p>
            <p className="text-center">
              {user.churchName ? user.churchName : "Church Name"}
            </p>
          </div>
          <div className="flex justify-between items-center flex-row pb-5 pt-3">
            {" "}
            <p
              style={{
                color: "var(--primary-color)",
                fontWeight: "600",
                margin: "0",
              }}
            >
              {event.length ? (
                <p>
                  {event.length} record
                  {event.length > 1 ? "s" : ""} found
                </p>
              ) : (
                <p>No Saved View</p>
              )}
            </p>{" "}
          </div>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr className="trdataheader">
                <th>Event Name</th>
                <th>Event Date</th>

                <th>Start Time</th>
                <th>End Time</th>
                <th>Interval</th>
                <th>Volunteers need</th>
                <th>Visible to members</th>
                <th>Event Mode</th>
              </tr>
            </thead>
            {event
              .sort((a, b) => moment(a.eventDay) - moment(b.eventDay)) // Sort events by dashBoardDay in descending order
              .map((events, index) => (
                <tbody key={index}>
                  {" "}
                  {/* Moved the key to <tbody> from <tr> */}
                  <tr className="trdata">
                    <td>{events.eventTitle}</td>
                    <td>{moment(events.eventDay).format("LL")}</td>
                    <td>{events.reStartTime}</td>
                    <td>{events.reEndTime}</td>
                    <td>
                      {events.eventPattern
                        ? events.eventPattern
                        : "Single Event"}
                    </td>
                    <td>{events.eventVolunteers ? "Yes" : "No"}</td>
                    <td>{events.eventVisible ? "Yes" : "No"}</td>
                    <td>
                      {events.eventMode === "OnlineOnly"
                        ? "Online"
                        : events.eventMode === "InPerson"
                        ? "In Person"
                        : "Hybrid"}
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </Row>
      ) : (
        <div className="noDatax">
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}
    </Container>
  );
});

export default PrintCalendarList;
