import React from "react";
import "./pagebtn.css";

const PageBtn = ({ currentPage, totalPages, handlePageChange }) => {
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handlePageSelect = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const getVisiblePages = () => {
    const visiblePages = [];
    const maxVisibleButtons = 5; // Change this value to control how many buttons are shown

    if (totalPages <= maxVisibleButtons) {
      // If total pages are less than or equal to the max buttons, show all pages
      for (let i = 1; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      let startPage = Math.max(
        currentPage - Math.floor(maxVisibleButtons / 2),
        1
      );
      let endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages);

      if (endPage - startPage < maxVisibleButtons - 1) {
        startPage = Math.max(endPage - maxVisibleButtons + 1, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }
    }

    return visiblePages;
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="pagination-buttons">
      <button onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous
      </button>
      {visiblePages.map((num) => (
        <button
          key={num}
          onClick={() => handlePageSelect(num)}
          className={currentPage === num ? "active" : ""}
        >
          {num}
        </button>
      ))}
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default PageBtn;
