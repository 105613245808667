import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./steps.css";

const Tracker = ({ currentStep, totalSteps }) => {
  const percentage = ((currentStep - 1) / (totalSteps - 1)) * 100;

  return (
    <div className="tracker">
      <ProgressBar
        now={percentage}
        label={`${percentage.toFixed(0)}%`}
        style={{
          height: "50px !important",
        }}
      />
    </div>
  );
};

export default Tracker;
