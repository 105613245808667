import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";

import moment from "moment";
const Outbox = () => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [sentSms, setSentSms] = useState([]);
  const handleFetchSms = async () => {
    try {
      const response = await authFetch.get("/sms");
      setSentSms(response.data.messages);
    } catch (err) {
      console.error("Error fetching SMS:", err);
    }
  };

  useEffect(() => {
    handleFetchSms();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [clickedMember, setClickedMember] = useState(null);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [statusFlag, setStatusFlag] = useState(false);
  const showMessage = (e, saved) => {
    e.preventDefault();
    setShow(true);
    setClickedMember(saved);
  };

  const handleViewName = (viewNameFlag) => {
    if (!viewNameFlag) {
      let sorted = [...sentSms].sort(function (a, b) {
        return a.viewName.localeCompare(b.viewName);
      });
      setSentSms(sorted);
    } else {
      let sorted = [...sentSms].reverse();
      setSentSms(sorted);
    }
    setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    let sorted = [...sentSms].sort((a, b) => {
      if (dateFlag) {
        return moment(b.dateSent).unix() - moment(a.dateSent).unix();
      } else {
        return moment(a.dateSent).unix() - moment(b.dateSent).unix();
      }
    });
    setSentSms(sorted);
    setDateFlag(!dateFlag);
  };

  const handleSortStatus = (e) => {
    let sorted = [...sentSms].sort((a, b) => {
      if (statusFlag) {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });
    setSentSms(sorted);
    setStatusFlag(!statusFlag);
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">OutBox</p>
      </Row>
      {sentSms && sentSms.length > 0 ? (
        <Row className="tabw">
          <p
            style={{
              fontWeight: "700",
              fontSize: "100%",
              color: "var(--primary-color)",
              paddingTop: "3rem",
            }}
          >
            {sentSms.length ? (
              <p>
                {sentSms.length} record{sentSms.length > 1 ? "s" : ""} found
              </p>
            ) : (
              <p>No Saved View</p>
            )}
          </p>
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            <thead>
              <tr className="trdataheader">
                <th onClick={handleDateSort}>
                  Date Sent {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th onClick={handleViewName}>
                  Recipient Name{" "}
                  {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th>Phone Number</th>
                <th>Delivery Time</th>
                <th onClick={handleSortStatus}>
                  {" "}
                  Status {statusFlag ? <FaCaretDown /> : <FaCaretUp />}
                </th>
                <th></th>
              </tr>
            </thead>
            {sentSms.map((saved, index) => (
              <tbody>
                <tr key={index} className="trdata">
                  <td>{moment(`${saved.dateSent}`).format("LL")}</td>
                  <td>{saved.memberName}</td>

                  <td>{saved.to}</td>
                  <td>{moment(`${saved.dateSent}`).format("LTS")}</td>
                  <td>{saved.status}</td>

                  <td className="rightco">
                    <button
                      className="jiluil3"
                      onClick={(e) => showMessage(e, saved)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Row>
      ) : (
        <div className="noDatax">
          <p className="noDataBackup">No data available for display.</p>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Date Sent</span> <br />
              {moment(`${clickedMember?.dateSent}`).format("LL")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Sent To</span>
              <br />
              {clickedMember?.memberName}
            </p>

            <p>
              <span style={{ fontWeight: "bold" }}>Status</span>
              <br />
              {clickedMember?.status}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Status</span>
              <br />
              {clickedMember?.body}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Outbox;
