import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import Main from "../../components/Free/Main";
import Offerings from "../../components/Free/Offerings";
import Faq from "../../components/Faq/Faq";
import Metrics from "../../components/Free/Metrics";
import Footer from "../../components/Footer/Footer";
import FaqFree from "../../components/Free/FaqFree";
import { Container } from "react-bootstrap";

const Hero = () => {
  return (
    <>
      <Helmet>
        <title>Churchease - Free Trial</title>
        <meta
          name="description"
          content="Experience Churchease free for 180 days. Sign up now for a free trial and see how Churchease can help manage your church's operations more efficiently."
        />
        <meta
          name="keywords"
          content="Churchease, church management software, free trial, church software, online giving, membership management"
        />
      </Helmet>
      <Navbar />
      <Container fluid>
        <Main
          showButton={true}
          buttonLabel="Get Started"
          buttonLink="/signuptrial"
        />
        <Offerings />
        <Metrics />
        <FaqFree />
      </Container>
      <Footer />
    </>
  );
};

export default Hero;
