import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import "./addworkflow.css";

const AddWorkFlow = ({ show, setShow, refreshWorkflows }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { addWorkflow, user } = useAppContext();
  const [workflow, setWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [error, setError] = useState(false);

  const handleSave = () => {
    if (!workflow) {
      setError(true);
      return;
    }
    const newWorkflow = {
      name: workflow,
      description: workflowDescription,
      churchName: user.churchName,
    };

    addWorkflow(newWorkflow);
    setWorkflow("");
    setWorkflowDescription("");
    refreshWorkflows();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="inlineerror">Please enter a workflow name</p>}
        <Form.Label htmlFor="inputPassword5">Workflow Name</Form.Label>
        <Form.Control
          type="text"
          value={workflow}
          onChange={(e) => setWorkflow(e.target.value)}
          required
        />
        <Form.Label className="mt-3" htmlFor="inputPassword5">
          Description (optional)
        </Form.Label>
        <Form.Control
          type="text"
          value={workflowDescription}
          onChange={(e) => setWorkflowDescription(e.target.value)}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddWorkFlow;
