import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import HighLightText from "../HighLightText/HighLightText";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddRecentMessage = ({
  show,
  setShow,
  tagId,
  setTagId,
  onFormSubmit,
  uploadSuccessFuncxx,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { logoutUser, user, getEventByVolunteers } = useAppContext();

  useEffect(() => {
    getEventByVolunteers();
  }, []);

  const [youtubeLink, setYouTubeLink] = useState("");

  const publishLater = async (e) => {
    e.preventDefault();
    if (!youtubeLink) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please enter a valid YouTube link"
        />
      );
      return;
    }

    const videoId = youtubeLink.split("v=")[1];
    // Fetch video metadata from YouTube
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet`
    );

    const videoData = response.data.items[0].snippet;
    try {
      const dataxx = {
        youtubeLink: youtubeLink,
        title: videoData.title,
        thumbnail: videoData.thumbnails,

        published: false,
        churchName: user.churchName,
        churchId: user._id,
      };

      const response = await authFetch.post("/livestream/recent/later", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFuncxx();

        setYouTubeLink("");

        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Livestream Published Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const publishNow = async (e) => {
    e.preventDefault();
    if (!youtubeLink) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please enter a valid YouTube link"
        />
      );
      return;
    }

    const videoId = youtubeLink.split("v=")[1];
    // Fetch video metadata from YouTube
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet`
    );

    const videoData = response.data.items[0].snippet;
    try {
      const dataxx = {
        youtubeLink: youtubeLink,
        title: videoData.title,
        thumbnail: videoData.thumbnails,

        published: true,
        churchName: user.churchName,
        churchId: user._id,
      };

      const response = await authFetch.post("/livestream/recent/now", dataxx);

      if (response.status === 200 || response.status === 201) {
        uploadSuccessFuncxx();

        setYouTubeLink("");

        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Livestream Published Successfully"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Publish Recent Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Livestream Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter live stream link"
                value={youtubeLink}
                onChange={(e) => setYouTubeLink(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil4" onClick={publishLater}>
          Save and Publish Later
        </button>
        <button className="jiluil" onClick={publishNow}>
          Publish Now
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRecentMessage;
