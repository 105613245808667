import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import "./membercontainer.css";
import MemberContact from "../MemberContact/MemberContact";
import Alert from "../Alert/Alert";
import PageBtn from "../Pagebtn/PageBtn";

const MemberContainer = () => {
  const [isChecked, setIsChecked] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setIsChecked((pre) => [...pre, value]);
    } else {
      setIsChecked((pre) => {
        return [...pre.filter((id) => id !== value)];
      });
    }
  };

  const {
    getAllMembers,
    setEditMember,
    members,
    page,
    numOfPages,
    deleteMember,
    showAlert,
    sort,
    search,
    searchAge,
    searchGender,
    searchStatus,
    searchCampus,
    searchMarital,
    searchBirthMonth,
    searchAnniversaryMonth,
  } = useAppContext();

  useEffect(() => {
    getAllMembers();
  }, [
    page,
    search,
    searchAge,
    searchGender,
    searchCampus,
    searchMarital,
    searchBirthMonth,
    searchAnniversaryMonth,
    searchStatus,
    sort,
    getAllMembers,
  ]);

  if (members?.length === 0) {
    return <h2>No jobs to display...</h2>;
  }

  return (
    <>
      <Container fluid className="neww">
        <Row>
          {showAlert && <Alert />}
          <h5>
            {members.length} member{members.length > 1 && "s"} found
          </h5>
        </Row>
        <Row>
          <Col sm="9">
            <Table striped hover>
              {members.map((member) => {
                return (
                  <tbody>
                    <tr key={member._id}>
                      <td>
                        <Form.Check
                          aria-label="option 1"
                          checked={isChecked[member._id]}
                          value={member.memail}
                          onChange={handleChange}
                          type="checkbox"
                          name={member._id}
                        />
                      </td>
                      <td>
                        <div className="mstat">
                          <p className="bmo">
                            {member.mfirstName} {member.mlastName}
                          </p>
                          <p className="mstat2 bmo">status:{member.mstatus}</p>
                        </div>
                      </td>
                      <td>
                        <p className="mstat2">email</p>
                        <p className="bmo">{member.memail}</p>
                      </td>
                      <td>
                        <p className="mstat2">mobile number</p>
                        <p className="bmo">
                          {member.mmobilePhone
                            ? member.mmobilePhone
                            : "Not Available"}
                        </p>
                      </td>
                      <td>
                        <Link
                          to="/auth/createMember"
                          onClick={() => setEditMember(member._id)}
                        >
                          <FaEdit style={{ color: "black" }} />
                        </Link>
                      </td>
                      <td>
                        <FaTrash
                          onClick={() =>
                            deleteMember(member._id, {
                              alertText: "Delete Successful",
                            })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </Col>
          <Col sm={3}>
            <MemberContact isChecked={isChecked} />;
          </Col>
        </Row>
        <Row>
          <Col>{numOfPages > 1 && <PageBtn />}</Col>
        </Row>
      </Container>
    </>
  );
};

export default MemberContainer;
