import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { currencyFormatter } from "../../../utils/helpers";
import { useAppContext } from "../../../context/appContext";
import "./giving.css";

const PrintStatement = React.forwardRef((props, ref) => {
  const { reports } = props;
  const { user, statementStartDate, statementEndDate } = useAppContext();

  const currentDateTime = moment().format("MMMM Do YYYY, h:mm:ss a");

  const formattedStatementStartDate = statementStartDate
    ? moment(statementStartDate).format("MMMM D, YYYY")
    : "";
  const formattedStatementEndDate = statementEndDate
    ? moment(statementEndDate).format("MMMM D, YYYY")
    : "";

  const groupedTransactions = reports.reduce((result, transaction) => {
    const { mId, giverName, category, paymentMethod, amount } = transaction;
    const group = result.find((group) => group.mId === mId);

    if (group) {
      group.transactions.push({ giverName, category, paymentMethod, amount });
      group.total += amount;
    } else {
      result.push({
        mId,
        transactions: [{ giverName, category, paymentMethod, amount }],
        total: amount,
        giverName: giverName,
      });
    }

    return result;
  }, []);

  return (
    <Container fluid>
      <div ref={ref} className="printable-content">
        <div className="reporthome">
          <p className="reportheadersize">Contribution Statement</p>
          <p className="reportheadersize">
            From {formattedStatementStartDate} to {formattedStatementEndDate}
          </p>
          <p className="reportheadersize">Generated by {user.churchName}</p>
        </div>
        {groupedTransactions.map((group, index) => (
          <div key={index} style={{ padding: "3rem" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>{currentDateTime}</p>
            </div>

            <div style={{ paddingTop: "1rem" }}>
              <p>{user.churchName}</p>
              <p>18 Sage</p>
            </div>
            <div style={{ paddingTop: "1rem" }}>
              <p>Dear {group.giverName}</p>
              <p>
                We would like to express our sincere gratitude for your generous
                contribution of{" "}
                {currencyFormatter({
                  amount: group.total,
                  currency: "usd",
                })}{" "}
                to {user.churchName}. Your support is truly appreciated and has
                made a meaningful impact on our organization.
              </p>
              <p>
                Please note that no goods or services were provided in exchange
                for your contributions. Your selfless act of giving is a
                testament to your commitment to our mission and the values we
                uphold.
              </p>

              <p>
                We are deeply grateful for your ongoing support and want to
                acknowledge the contributions you have made{" "}
                {statementStartDate && !statementEndDate
                  ? `from ${formattedStatementStartDate} till today`
                  : statementStartDate && statementEndDate
                  ? `between ${formattedStatementStartDate} and ${formattedStatementEndDate}`
                  : ""}
                . Your dedication and generosity have helped us further our
                mission and make a difference in the lives of those we serve.
              </p>

              <p>
                Thank you once again for your kind contribution and for being a
                valued supporter of {user.churchName}.
              </p>

              <p>With heartfelt appreciation,</p>

              <p>{user.churchName}</p>
            </div>
            <Table hover>
              <thead>
                <tr style={{ cursor: "pointer" }}>
                  <th>Date</th>
                  <th>Fund</th>
                  <th>Method</th>
                  <th>Members</th>
                  <th>Batch</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {group.transactions.map((transaction, idx) => (
                <tbody key={idx}>
                  <tr>
                    <td>
                      {moment(transaction.givingDate).format("MMM Do, YYYY")}
                    </td>
                    <td>{transaction.category}</td>
                    <td>{transaction.paymentMethod}</td>
                    <td>{transaction.giverName}</td>
                    <td>{transaction.batch}</td>
                    <td>
                      {currencyFormatter({
                        amount: transaction.amount,
                        currency: "usd",
                      })}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
            <div className="reportfooter"></div>
          </div>
        ))}
      </div>
    </Container>
  );
});

export default PrintStatement;
