import { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
} from "../../utils/validation";
import Alert from "../Alert/Alert";
import { useAppContext } from "../../context/appContext";

const AddFolderModal = ({ addFolderModal, toggleFolder, name, id }) => {
  const { addFolder, getRootFolder, getFolder, showAlert } = useAppContext();
  const [folderName, setFolderName] = useState("");
  const [error, setError] = useState(false);

  const handleAdd = async () => {
    const [trimmedFolderName] = trimInputs([folderName]);
    setError("");
    if (!validateNotEmpty(trimmedFolderName)) {
      setError(" Please provide a Folder Name to proceed.");
      return;
    }
    if (!validateLength(trimmedFolderName, NAME_LENGTH_LIMIT)) {
      setError(`Folder Name cannot exceed ${NAME_LENGTH_LIMIT} words.`);
      return;
    }
    if (!folderName) {
      setError(true);
      return;
    }
    await addFolder(id, folderName);
    setFolderName("");
    toggleFolder();
    if (id) {
      await getFolder(id);
    } else {
      await getRootFolder();
    }
  };
  return (
    <Modal isOpen={addFolderModal} toggle={toggleFolder}>
      <ModalHeader toggle={toggleFolder}>Add Folder</ModalHeader>
      <ModalBody className="modal-wrapper__body">
        {showAlert && <Alert />}
        <Row>
          {error && <p className="inlineerror">{error}</p>}
          <Col md="4">
            <Label for="tag-name" className="modal-form-group-label">
              Folder Name
            </Label>
          </Col>
          <Col>
            <Input
              type="text"
              name="name"
              placeholder="Folder Name"
              required
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
            <div className="field-under-message">
              Creating new folder in <strong>{name}</strong>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="jiluil" onClick={toggleFolder}>
          Cancel
        </button>
        <button onClick={handleAdd} className="jiluil3">
          Add
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddFolderModal;
