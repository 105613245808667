import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useAppContext } from "../../../../context/appContext";
import nodd from "../../../../assets/newi/nodd.png";
// import "../learning.css";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import CustomToastContent from "../../../../components/Toast/CustomToastContent";
import AddDevotional from "../../../../components/Devotional/AddDevotional";
import DeleteDevotional from "../../../../components/Devotional/DeleteDevotional";
import PublishDevotional from "../../../../components/Devotional/PublishDevotional";
import EditDevotional from "../../../../components/Devotional/EditDevotional";

const Devotional = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [devotionals, setDevotionals] = useState([]);
  const loadCourse = async () => {
    const { data } = await authFetch.get(`/devotional`);

    setDevotionals(data);
  };

  const [selectedDevotional, setSelectedDevotional] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  useEffect(() => {
    loadCourse();
  }, []);

  const uploadSuccessFunc = () => {
    loadCourse();
  };

  const handleShow = (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedDevotional(devotional);
  };

  const handleShow5 = (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedDevotional(devotional);
  };

  const handleShow6 = (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedDevotional(devotional);
    setShow6(true);
  };

  const handleShow7 = (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedDevotional(devotional);
    setShow7(true);
  };

  const publishedDevotional = () => {
    loadCourse();
  };

  const unpublishedDevotional = () => {
    loadCourse();
  };

  const addPlanner = (id) => {
    setShow2(true);
  };

  const handleView = (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();
    setShow3(true);
    setSelectedDevotional(devotional);
  };

  const handleCopy = async (event, devotional) => {
    event.preventDefault();
    event.stopPropagation();

    const copyForm = { ...devotional };
    delete copyForm._id;
    copyForm.title += " (copy)";
    try {
      const response = await authFetch.post("/devotional/later", copyForm);
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            message="Devotional Copied Successfully"
            title="Success"
          />
        );
        loadCourse();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error copying form:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Devotional</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addPlanner}>
              New devotional
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {devotionals && devotionals.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {devotionals.length ? (
                    <p>
                      {devotionals.length} record
                      {devotionals.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {devotionals.map((devotional, index) => (
                    <tbody>
                      <tr key={index} className="trdata">
                        <td>{devotional.title}</td>
                        <td className="rightco">
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleView(event, devotional)}
                          >
                            Edit
                          </button>
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleCopy(event, devotional)}
                          >
                            Copy
                          </button>
                          <button
                            className={
                              devotional.published ? "jiluil3" : "jiluil"
                            }
                            onClick={(event) => handleShow5(event, devotional)}
                          >
                            {devotional.published ? "Unpublish" : "Publish"}
                          </button>

                          <button
                            className="jiluil2"
                            onClick={(event) => handleShow(event, devotional)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noData">
                <img src={nodd} alt="no data" />
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <AddDevotional
        show={show2}
        setShow={setShow2}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <DeleteDevotional
        show={show}
        setShow={setShow}
        selectedDevotional={selectedDevotional}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <PublishDevotional
        show={show5}
        AssignPlanner
        setShow={setShow5}
        selectedDevotional={selectedDevotional}
        uploadSuccessFunc={uploadSuccessFunc}
        publishedDevotional={publishedDevotional}
        unpublishedDevotional={unpublishedDevotional}
      />

      <EditDevotional
        show={show3}
        setShow={setShow3}
        selectedDevotional={selectedDevotional}
        uploadSuccessFunc={uploadSuccessFunc}
      />
    </>
  );
};

export default Devotional;
