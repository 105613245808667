import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./growthtask.css";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../../utils/validation";

const GrowthDisabled = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();
  const [errorMessage1, setErrorMessage1] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [planner, setPlanner] = useState({
    tasks: [
      {
        question: "Example Question 1",
        type: "text",
        text: "",
        response: "",
        submitted: false,
        status: "pending", // Initial status can be "pending", "in progress", "completed", etc.
      },
      // Add more tasks as needed
    ],
  });

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/plannerxx/${id}`);

    const [dataxx] = data;

    setPlanner(dataxx);
  };

  const [plannerx, setPlannerx] = useState({});

  const loadCoursex = async () => {
    const { data } = await authFetch.get(`/learning/planneruid/${user._id}`);
    setPlannerx(data);
  };

  useEffect(() => {
    loadCourse();
    loadCoursex();
  }, []);

  const validateTasks = () => {
    for (let i = 0; i < planner.tasks.length; i++) {
      const task = planner.tasks[i];

      // Ensure task.response is a string before calling trim()
      const trimmedResponse =
        typeof task.response === "string" ? task.response.trim() : "";

      const lengthLimit =
        task.type === "text" ? NAME_LENGTH_LIMIT : DESCRIPTION_LENGTH_LIMIT;

      if (!validateLength(trimmedResponse, lengthLimit)) {
        setError(
          `Response for "${task.question}" cannot exceed ${lengthLimit} words.`
        );
        return false; // Indicate that validation failed
      }
    }

    setError(""); // Clear any previous error if all validations pass
    return true; // Indicate that all validations passed
  };

  //console.log(planner);

  const handleTaskTextChange = (newValue, taskIndex) => {
    const updatedTasks = [...planner.tasks];
    updatedTasks[taskIndex].response = newValue; // Update the response field
    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const handleRadioChange = (taskIndex, selectedOption) => {
    const updatedTasks = [...planner.tasks];
    updatedTasks[taskIndex].response = selectedOption; // Set the selected option as the response
    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const handleCheckboxChange = (taskIndex, optionValue) => {
    const updatedTasks = [...planner.tasks];
    const selectedOptions = updatedTasks[taskIndex].response || [];

    if (selectedOptions.includes(optionValue)) {
      updatedTasks[taskIndex].response = selectedOptions.filter(
        (option) => option !== optionValue
      );
    } else {
      updatedTasks[taskIndex].response = [...selectedOptions, optionValue];
    }

    setPlanner({ ...planner, tasks: updatedTasks });
  };

  const [errorMessage, setErrorMessage] = useState("");
  const savePlanner = async () => {
    if (!validateTasks()) {
      // If validation fails, stop here. The error message is already set.
      return;
    }
    // Calculate percentage of completed tasks

    const memberId = user._id;

    // Prepare the planner object with new elements
    const plannerToSave = {
      ...planner,
      status: "In Progress", // Example status logic
      percentageCompleted: completionPercentage,
      memberId: memberId,
    };

    try {
      await authFetch.post("/learning/growth-responses", plannerToSave);
      setErrorMessage("Saved successfully");
      // Optional: Update the planner state if the backend returns the updated planner
      // setPlanner(response.data);
    } catch (error) {
      console.error("Error saving planner:", error);
      setErrorMessage("Failed to save");
    }
  };

  const submitPlanner = async () => {
    if (!validateTasks()) {
      // If validation fails, stop here. The error message is already set.
      return;
    }
    // Calculate percentage of completed tasks

    if (completionPercentage < 100) {
      setErrorMessage1("You must complete all tasks before submitting");
      setTimeout(() => {
        setErrorMessage1("");
      }, 3000);
      return;
    }

    // Prepare the planner object with new elements
    const plannerToSave = {
      ...planner,
      status: "Completed", // Example status logic
      percentageCompleted: completionPercentage,
    };

    try {
      await authFetch.patch("/learning/growth-responses-submit", plannerToSave);
      setErrorMessage("Saved successfully");
      navigate("/auth/growthplan");
      // Optional: Update the planner state if the backend returns the updated planner
      // setPlanner(response.data);
    } catch (error) {
      console.error("Error saving planner:", error);
      setErrorMessage("Failed to save");
    }
  };

  const [completionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    const calculateCompletionPercentage = () => {
      let tasksWithResponse = 0;
      const totalTasks = planner.tasks.length;

      planner.tasks.forEach((task) => {
        if (task.response && task.response.trim() !== "") {
          tasksWithResponse += 1;
        }
      });

      return totalTasks > 0 ? (tasksWithResponse / totalTasks) * 100 : 0;
    };

    // Calculate and set the completion percentage
    const percentage = calculateCompletionPercentage();
    setCompletionPercentage(percentage);
  }, [planner.tasks]);

  console.log(planner);
  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">My Growth Plan</p>
        </Col>
      </Row>
      <Row className="sssssssecwef">
        <div>{planner.title}</div>
        <div className="rt6hi8">
          {completionPercentage
            ? `${completionPercentage.toFixed(0)}% Complete`
            : "Not Started"}
        </div>
      </Row>
      <Row>
        {errorMessage && <p className="inlinesuccess">{errorMessage}</p>}
        {errorMessage1 && <p className="inlineerror">{errorMessage1}</p>}
        {error && <p className="inlineerror">{error}</p>}
        {planner.tasks && planner.tasks.length > 0 ? (
          <div className="taskmain" style={{ paddingBottom: "4rem" }}>
            {planner.tasks.map((plan, index) => (
              <div key={index} className="mb-3">
                <Form.Label htmlFor={`taskInput-${index}`}>
                  {plan.question}
                </Form.Label>

                {plan.type === "text" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    type="text"
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled={plannerx.status === "Completed"}
                    required
                  />
                )}

                {plan.type === "textarea" && (
                  <Form.Control
                    id={`taskInput-${index}`}
                    as="textarea"
                    rows={10}
                    value={plan.response || ""}
                    onChange={(e) =>
                      handleTaskTextChange(e.target.value, index)
                    }
                    disabled={plannerx.status === "Completed"}
                    required
                  />
                )}

                {plan.type === "radio" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="radio"
                      id={`radio-${index}-${optionIndex}`}
                      label={option}
                      name={`radioGroup-${index}`}
                      checked={option === plan.response}
                      onChange={() => handleRadioChange(index, option)}
                      disabled={plannerx.status === "Completed"}
                    />
                  ))}

                {plan.type === "checkbox" &&
                  plan.options.map((option, optionIndex) => (
                    <Form.Check
                      key={`${index}-${optionIndex}`}
                      type="checkbox"
                      id={`checkbox-${index}-${optionIndex}`}
                      label={option}
                      name={`checkboxGroup-${index}`}
                      checked={plan.response?.includes(option)}
                      onChange={() => handleCheckboxChange(index, option)}
                      disabled={plannerx.status === "Completed"}
                    />
                  ))}
              </div>
            ))}
          </div>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default GrowthDisabled;
