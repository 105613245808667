import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, ProgressBar, Tooltip, OverlayTrigger } from "react-bootstrap";
import Loading from "../Loading/Loading";
import Container from "react-bootstrap/Container";
import "./addlesson.css";
const AddLessonForm = ({
  values,
  setValues,
  handleAddLesson,
  uploading,
  uploadButtonText,
  handleVideo,
  progress,
  handleVideoRemove,
  uploaded,
  handleClose2,
}) => {
  return (
    <Container fluid>
      <Form onSubmit={handleAddLesson} className="pt-4">
        {!uploading && values.video && values.video.Location && (
          <p className="inlinesuccess">Video upload successful!</p>
        )}
        <input
          type="text"
          className="form-control square"
          onChange={(e) => setValues({ ...values, title: e.target.value })}
          value={values.title}
          placeholder="Lesson Title"
          autoFocus
          required
        />

        <div className="d-flex justify-content-center items-center gap-3">
          <label className="btn buttonner11112 adcsdc btn-block text-left mt-3 mb-3 flex items-center justify-center">
            {/* {uploading
              ? "Uploading..."
              : "uploadButtonText"
              ? "File Uploaded"
              : "Upload Video"} */}

            {uploading
              ? "Uploading..."
              : uploaded === true
              ? "File Uploaded"
              : "Upload Video"}

            <input onChange={handleVideo} type="file" accept="video/*" hidden />
          </label>

          {uploading && progress > 0 && <Loading />}
          {!uploading && values.video && values.video.Location && (
            <p
              onClick={handleVideoRemove}
              style={{
                marginBottom: "0px",
                textDecoration: "underline",
                cursor: "pointer",
                color: "red",
              }}
            >
              Remove
            </p>
          )}
        </div>
        <p>
          {uploadButtonText === "Upload Video" ||
          uploadButtonText === "Upload video"
            ? ""
            : uploadButtonText}
        </p>
        <div className="flex justify-end w-100 flex-row pt-5 gap-3">
          <button
            onClick={handleClose2}
            className="jiluil"
            disabled={uploading}
            style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
          >
            Close
          </button>
          <button
            onClick={handleAddLesson}
            className="jiluil4"
            disabled={uploading}
            style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
          >
            {uploading ? "Uploading..." : "Save"}
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default AddLessonForm;
