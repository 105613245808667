import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import "./usermanagement.css";

import Form from "react-bootstrap/Form";

const RolesEdit = () => {
  const handleSave = () => {};
  return (
    <Container fluid>
      <Row className="courseheader" style={{ marginBottom: "3rem" }}>
        <Col className="alignright">Edit Role</Col>

        <Col className="reddd">
          <Button
            variant="danger"
            className="buttonner1"
            onClick={handleSave}
            style={{ marginRight: "1rem" }}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Form.Label htmlFor="inputPassword5">Role Name</Form.Label>
            <Form.Control
              type="password"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
            />
          </div>
        </Col>
        <Col>
          <div>
            <Form.Label htmlFor="inputPassword5">Role Description</Form.Label>
            <Form.Control
              type="password"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div></div>
        </Col>
      </Row>
    </Container>
  );
};

export default RolesEdit;
