import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./main.css";
import { FaCar, FaClock, FaLaptop, FaMoneyBill } from "react-icons/fa";

const Metrics = () => {
  return (
    <Row className="metrics">
      <div className="metrics-container">
        <Col md={3} sm={12} className="metricbody">
          <p className="metrictitle">
            30<span className="metricmini">hrs/month</span>
          </p>
          <p className="offering-title">Time Savings</p>
        </Col>
        <Col md={3} sm={12} className="metricbody">
          <p className="metrictitle">50%</p>
          <p className="offering-title">Cost Savings</p>
        </Col>
        <Col md={3} sm={12} className="metricbody">
          <p className="metrictitle">40%</p>
          <p className="offering-title">Efficiency</p>
        </Col>
        <Col md={3} sm={12} className="metricbody">
          <p className="metrictitle">60%</p>
          <p className="offering-title">Enhanced Member Engagement</p>
        </Col>
      </div>
    </Row>
  );
};

export default Metrics;
