import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AddTag from "../../../components/ContainerGroup/AddTag";
import { useAppContext } from "../../../context/appContext";
import "./containergroup.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ManageTags from "../../../components/ContainerGroup/ManageTags";
import EditTag from "../../../components/ContainerGroup/EditTag";
import SetupTag from "../../../components/ContainerGroup/SetupTag";
import DeleteTag from "../../../components/ContainerGroup/DeleteTag";
import Alert from "../../../components/Alert/Alert";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import AddShareLink from "../../../components/ContainerGroup/AddShareLink";
import {
  FaCaretDown,
  FaEdit,
  FaPlus,
  FaToolbox,
  FaTools,
  FaTrash,
} from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";

const ContainerGroup = () => {
  const { user, getContainers, containers, tagsCount } = useAppContext();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [containerName, setContainerName] = useState("");

  const [show5, setShow5] = useState(false);
  const handleShow5 = (e, container) => {
    e.preventDefault(); // Optional, based on use-case
    setShow5(true);
    if (container) {
      setContainerName(container.name);
    }
  };

  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [tagId, setTagId] = useState("");
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const editContainers = (id) => {
    setShow1(true);
    setTagId(id);
  };
  const manageTags = (id) => {
    setShow2(true);
    setTagId(id);
  };

  const deleteTags = (id) => {
    setShow4(true);
    setTagId(id);
  };

  const setupContainer = (id, containername) => {
    navigate(`/auth/containerview/${id}/${containername}`);
  };

  useEffect(() => {
    getContainers();
  }, []);

  const handleReload = () => {
    getContainers();
  };

  const handleViewName = (viewNameFlag) => {
    // if (!viewNameFlag) {
    //   let sorted = [...savedView].sort(function (a, b) {
    //     return a.viewName.localeCompare(b.viewName);
    //   });
    //   setSavedView(sorted);
    // } else {
    //   let sorted = [...savedView].reverse();
    //   setSavedView(sorted);
    // }
    // setViewNameFlag(!viewNameFlag);
  };

  const handleDateSort = (e) => {
    // let sorted = [...savedView].sort((a, b) => {
    //   if (dateFlag) {
    //     return moment(b.createdAt).unix() - moment(a.createdAt).unix();
    //   } else {
    //     return moment(a.createdAt).unix() - moment(b.createdAt).unix();
    //   }
    // });
    // setSavedView(sorted);
    // setDateFlag(!dateFlag);
  };

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Groups</div>
        <div className="bannersmall">
          Create and manage dynamic groups effortlessly. Communicate via email
          or SMS, and assign courses, videos, and more, fostering a connected
          and engaged community.
        </div>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo">
          <button className="jiluil" onClick={handleShow}>
            <div className="flex items-center justify-between gap-3">
              <FaPlus /> Create Group
            </div>
          </button>
        </div>
      </Row>
      <Row>
        <Alert />
        <div className="parentContainer w-100">
          <div className="w-100">
            <Table hover responsive>
              <thead>
                <tr className="trdataheader">
                  <th onClick={handleViewName}>
                    NAME {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th onClick={handleDateSort}>
                    MEMBERS {dateFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                  <th style={{ textAlign: "center" }}>WORKFLOW</th>
                </tr>
              </thead>
              {containers ? (
                containers.map((container, index) => {
                  // Find the corresponding tag count for this container
                  const tagCountEntry = tagsCount?.find(
                    (tag) => tag._id === container._id
                  );
                  const tagCount = tagCountEntry ? tagCountEntry.count : 0;

                  return (
                    <tbody key={index}>
                      <tr className="trdata">
                        <td
                          className="w-25"
                          style={{ cursor: "pointer" }}
                          onClick={() => editContainers(container._id)}
                        >
                          {container.name}
                        </td>
                        <td className="w-25">{tagCount}</td>{" "}
                        {/* Display the tag count here */}
                        <td className="text-md flex items-center justify-end">
                          <div className="flex items-center justify-end gap-3">
                            <button
                              className="q3rq3r23"
                              onClick={() => manageTags(container._id)}
                            >
                              Add Member
                            </button>

                            <button
                              className="jiluil"
                              onClick={(e) => handleShow5(e, container)}
                            >
                              Share
                            </button>

                            <button
                              className="jiluil3"
                              onClick={() =>
                                setupContainer(container._id, container.name)
                              }
                            >
                              Manage Groups
                            </button>

                            <FaTrash
                              onClick={() => deleteTags(container._id)}
                              className="iconfa"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <div>No containers</div>
              )}
            </Table>
          </div>
        </div>
      </Row>

      <AddTag show={show} setShow={setShow} />
      <EditTag
        show={show1}
        setShow={setShow1}
        tagId={tagId}
        setTagId={setTagId}
        handleReload={handleReload}
      />
      <ManageTags
        show={show2}
        setShow={setShow2}
        tagId={tagId}
        setTagId={setTagId}
        handleReload={handleReload}
      />
      <SetupTag
        show={show3}
        setShow={setShow3}
        tagId={tagId}
        setTagId={setTagId}
        handleReload={handleReload}
      />
      <DeleteTag
        show4={show4}
        setShow4={setShow4}
        tagId={tagId}
        setTagId={setTagId}
        handleReload={handleReload}
      />
      <AddShareLink
        show5={show5}
        setShow5={setShow5}
        containerName={containerName}
        // shortenedUrl={shortenedUrl}
      />
    </Container>
  );
};

export default ContainerGroup;
