import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./prayers.css";
import moment from "moment/moment";
import { FaTimes, FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const PrayerWall = () => {
  const { getPrayerRequest, logoutUser, prayers, deletePrayer, updatePrayer } =
    useAppContext();

  useEffect(() => {
    getPrayerRequest();
  }, []);

  const navigate = useNavigate();

  const notPrayed = prayers.filter(
    (prayer) => prayer.status === undefined || prayer.status === true
  );

  const [hoveredItem, setHoveredItem] = useState(null);
  const [show, setShow] = useState(false);
  const [prayer, setPrayer] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (prayer) => {
    setShow(true);
    setPrayer(prayer);
  };

  const handleMouseEnter = (prayerId) => {
    setHoveredItem(prayerId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const [status, setStatus] = useState(false);
  const [clickedPrayer, setClickedPrayer] = useState(null);
  const [viewPrayer, setViewPrayer] = useState(false);
  const handleShowDetails = (e, prayer) => {
    e.stopPropagation();
    setClickedPrayer(prayer);
    setViewPrayer(!viewPrayer);
  };

  const handleCheckboxChange = async (e, prayer) => {
    // const { name, checked } = e.target;

    e.stopPropagation();
    setClickedPrayer(false);

    setStatus(false);
    const prayerRequestUpdate = {
      status: status,
      prayerId: prayer._id,
    };

    let prayerId = prayer._id;

    const response = await updatePrayer(prayerRequestUpdate, prayerId);

    if (response.status === 200 || response.status === 201) {
      getPrayerRequest();
      toast(
        <CustomToastContent
          title="Success"
          message="Prayer Request Archived!"
        />
      );
    }
  };

  const extractedData = notPrayed.map(
    ({ prayerSubject, prayerRequest, createdAt }) => ({
      prayerSubject,
      prayerRequest,
      createdAt,
    })
  );

  const handleAchieve = (e) => {
    e.stopPropagation();
    navigate("/auth/prayerachieve");
  };
  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Prayer Wall</div>
        <div className="bannersmall">
          Prayer Wall offers a collective space for sharing and managing prayer
          requests, fostering a prayerful atmosphere within our church
          community.
        </div>
      </Row>
      <Row className="sssssssecwef">
        <div className="bnmjo">
          {notPrayed.length > 0 && (
            <button
              className="jiluil"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <CSVLink
                data={extractedData}
                filename="achievedPrayer.csv"
                style={{ textDecoration: "none", color: "#fff" }}
                className="buttoner"
              >
                Download
              </CSVLink>
            </button>
          )}
          <button className="jiluil3" onClick={handleAchieve}>
            Archive
          </button>
        </div>
      </Row>
      <Row>
        <div className="prayerwall">
          {notPrayed.length < 1 ? (
            <div className="noDataBackup">No Prayer Request</div>
          ) : (
            notPrayed.map((prayer) => (
              <>
                {" "}
                <div
                  className={`prayer ${
                    hoveredItem === prayer._id ? "hovered" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(prayer._id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={(e) => handleShowDetails(e, prayer)}
                >
                  <p className="praydate">
                    {moment(prayer.createdAt).format("MMMM Do YYYY")}
                  </p>
                  <p className="prayersubject">
                    {prayer?.prayerSubject.length > 25
                      ? prayer?.prayerSubject.substring(0, 18) + "..."
                      : prayer?.prayerSubject}
                  </p>
                  <p
                    style={{
                      flex: 1,
                      fontSize: "1em",
                      height: "1.5rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    {prayer.prayerRequest.length > 75
                      ? prayer.prayerRequest.substring(0, 75) + "..."
                      : prayer.prayerRequest}
                  </p>
                  {hoveredItem === prayer._id && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "2rem",
                      }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => handleCheckboxChange(e, prayer)}
                      >
                        Move to Archive
                      </button>
                    </div>
                  )}
                </div>
              </>
            ))
          )}
          {viewPrayer && (
            <>
              <div className="overlay-background">
                <div className="ewwrew">
                  <FaTimes
                    className="close-button"
                    onClick={() => setViewPrayer(false)}
                  />
                  <p className="praydate11 mb-3">
                    {moment(clickedPrayer.createdAt).format("MMMM Do YYYY")}
                  </p>
                  <p className="prayersubject11 mb-3">
                    {clickedPrayer.prayerSubject}
                  </p>
                  <p
                    style={{ flex: 1, fontSize: "1em" }}
                    className="prayerbody mb-3"
                  >
                    {clickedPrayer.prayerRequest}
                  </p>
                  <p className="praydate11 flex justify-end">
                    From{" "}
                    {clickedPrayer.mfirstName === "anonymous"
                      ? "Anonymous"
                      : clickedPrayer.mfirstName +
                        " " +
                        clickedPrayer.mlastName}{" "}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default PrayerWall;
