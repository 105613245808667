import React from "react";

import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { FaPeopleArrows, FaUserAlt, FaChild } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import "./product.css";
import f1 from "../../assets/newi/f1.png";
import f2 from "../../assets/newi/f2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductChildren = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Children Management | Churchease - Nurture and Track Child Growth
        </title>
        <meta
          name="description"
          content="Navigate children's details smoothly with Churchease. Our intuitive system fosters a supportive environment, ensuring easy updates and making each child feel integral to the community."
        />
        <link rel="canonical" href="https://churchease.com/product/children" />
        <meta
          property="og:title"
          content="Children Management | Churchease - Nurture and Track Child Growth"
        />
        <meta
          property="og:description"
          content="Navigate children's details smoothly with Churchease. Our intuitive system fosters a supportive environment, ensuring easy updates and making each child feel integral to the community."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/children"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#767DE8" }}>
            <div>
              <FaChild size={100} />
              <p className="headssc">Children</p>
              <p className="headpp">
                Navigate children's details smoothly, from participation to
                milestones. Our intuitive system fosters a supportive
                environment, ensuring easy updates and making each child feel
                integral to the community.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for child management
            </p>
            <p className="product13">
              Your comprehensive solution to nurture and track the growth and
              activities of every child in your community
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={f1} alt="Children" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">VIBRANT CHILDREN'S LIBRARY</p>
              <p className="proddz1">
                Discover a dynamic collection of educational and spiritual
                resources, tailored to inspire and engage children of all age
                groups.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">COMPREHENSIVE CHILDREN'S ROSTER</p>
              <p className="proddz1">
                Seamlessly manage and access detailed profiles of every child,
                ensuring individual attention and care within your community.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CELEBRATE CHILDREN'S MILESTONES</p>
              <p className="proddz1">
                Highlight and celebrate each child's personal achievements and
                milestones, fostering a culture of encouragement and pride.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain">
          <Col>
            <div className="productdiv">
              <p className="proddz">TARGETED CHILDREN'S ASSESSMENTS</p>
              <p className="proddz1">
                Utilize specialized assessment tools to evaluate and understand
                the educational and spiritual needs of children, driving
                targeted interventions.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">DETAILED GROWTH MONITORING</p>
              <p className="proddz1">
                Track the physical, spiritual, and emotional growth of children
                with our intuitive and detailed monitoring tools.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">PARENT MONTHLY REPORTS & ANALYTICS</p>
              <p className="proddz1">
                Provide parents with detailed monthly reports, offering insights
                into their child’s activities, progress, and overall engagement.
                This tool aids in fostering transparent communication and
                collaborative development between the church and families.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={f2} alt="Children" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#767DE8" />
      <Footer />
    </>
  );
};

export default ProductChildren;
