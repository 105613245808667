import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import he from "he";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const EditDevotional = ({
  show,
  setShow,
  selectedDevotional,
  uploadSuccessFunc,
}) => {
  const { logoutUser, user } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);
  const [devotionalsById, setDevotionalById] = useState([{}]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [devotionals, setDevotionals] = useState([{}]);
  const [publishDate, setPublishDate] = useState("");
  const [publishTime, setPublishTime] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await authFetch.get(
          `/devotional/${selectedDevotional?._id}`
        );
        // const [formx] = data;
        setDevotionalById(data);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };
    fetchData();
  }, [selectedDevotional]);

  useEffect(() => {
    if (devotionalsById) {
      setTitle(devotionalsById.title);
      const date = new Date(devotionalsById.publishDate);
      if (!isNaN(date.getTime())) {
        const formattedDate = date.toISOString().split("T")[0];
        setPublishDate(formattedDate);
      }
      // setDescription(formById.description);
      const decodedDescription = he.decode(devotionalsById.description || "");
      setDescription(decodedDescription);
      // setForms(devotionalsById.forms || []);
    }
  }, [devotionalsById]);

  const maxChars = 2000;

  const handleQuillChange = (content) => {
    setDescription(content);
  };

  const handlePlanner = async () => {
    try {
      if (description.length > maxChars) {
        toast(
          <CustomToastContent
            title="Error"
            message="Description is above the required length"
          />
        );
        return;
      }

      if (!publishDate || !publishTime || !title || !description) {
        toast(
          <CustomToastContent
            title="Error"
            message="Please complete the required fields to proceed"
          />
        );
        return;
      }

      const data = {
        title,
        description,
        publishTime,
        publishDate,
      };

      const response = await authFetch.put(
        `/devotional/${selectedDevotional._id}`,
        data
      );
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        handleClose();
        toast(
          <CustomToastContent
            title="Success"
            message="Update Operation Successful"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Title <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicDescription" className="mb-5">
              <Form.Label>
                Form Description <span className="myast">*</span>
              </Form.Label>

              <ReactQuill
                theme="snow"
                placeholder="Enter description"
                className="lpmw"
                style={{ height: "300px" }}
                value={description}
                onChange={handleQuillChange}
                required
              />
              <div className="character-count flex items-center justify-end">
                {`${description.length} / ${maxChars}`} characters.
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Publish Date <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Select Date"
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>
                Publish Time <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="Select Time"
                value={publishTime}
                onChange={(e) => setPublishTime(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil3" onClick={handlePlanner}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditDevotional;
