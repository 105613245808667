import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

const MyGrowthPlan = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser, user } = useAppContext();
  const [uniqueGrowthPlan, setUniqueGrowthPlan] = useState({});
  const [allPlan, setAllPlan] = useState([]);
  const [planner, setPlanner] = useState({});

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/planneruid/${user._id}`);
    setPlanner(data);
  };

  const loadTaskByAll = async () => {
    const { data } = await authFetch.get(`/learning/growthbyall/${user._id}`);

    setAllPlan(data.plannerDetails);
  };

  useEffect(() => {
    loadCourse();

    loadTaskByAll();
  }, []);
  //sMap over the allPlan array to transform it and extract matched data
  // Map over the allPlan array
  const mergedPlans = allPlan.map((plan) => {
    // Check if the current plan's _id matches the planner's planId
    if (planner.planId === plan._id) {
      // If there's a match, return a new object combining data from both the planner and the plan
      return {
        title: planner.title, // Use title from planner
        _id: plan._id, // Use _id from allPlan
        percentageCompleted: planner.percentageCompleted, // Use percentageCompleted from planner
        status: planner.status, // Use status from planner
        notes: planner.notes, // Use notes from planner
        // Include any other fields you need from either planner or plan
      };
    }

    // If no match is found, return the plan with default values or handle as needed
    return {
      title: plan.title, // Use title from allPlan
      _id: plan._id, // Use _id from allPlan
      percentageCompleted: "Not Started", // Example default value
      // Include any other fields with default values or omit them
    };
  });

  console.log(mergedPlans);

  // console.log(allPlan);
  // console.log(planner);

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">My Growth Plan</p>
        </Col>
      </Row>

      <Row>
        <div>
          {mergedPlans && mergedPlans.length > 0 ? (
            <Row className="tabw">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "100%",
                  color: "var(--primary-color)",
                  paddingTop: "3rem",
                }}
              >
                {mergedPlans.length ? (
                  <p>
                    {mergedPlans.length} record
                    {mergedPlans.length > 1 ? "s" : ""} found
                  </p>
                ) : (
                  <p>No Saved View</p>
                )}
              </p>
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr className="trdataheader">
                    <th>PLAN NAME</th>
                    <th>ASSIGNED DATE</th>
                    <th>STATUS</th>
                    <th>NOTES</th>
                    <th style={{ textAlign: "right" }}>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {" "}
                  {/* Ensure there's a <tbody> wrapping your rows */}
                  {mergedPlans.map((plan, index) => (
                    <tr key={index} className="trdata">
                      <td>{plan.title}</td>
                      <td>{moment(plan.createdAt).format("D MMMM YYYY")}</td>
                      <td>{`${plan.percentageCompleted}% Completed`}</td>
                      <td>
                        {plan.notes && plan.notes.trim()
                          ? plan.notes
                          : "Not Available"}
                      </td>
                      <td className="rightco" style={{ textAlign: "right" }}>
                        {plan.status === "Completed" ? (
                          <Link to={`/auth/growthdisabled/${plan._id}`}>
                            <button className="jiluil">Submitted</button>
                          </Link>
                        ) : plan.status === "In Progress" ? (
                          <Link to={`/auth/growthtask/${plan._id}`}>
                            <button className="jiluil">Continue Editing</button>
                          </Link>
                        ) : (
                          <Link to={`/auth/growthedit/${plan._id}`}>
                            <button className="jiluil">Start Plan</button>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          ) : (
            <div className="noDatax">
              <p className="noDataBackup">No data available for display.</p>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default MyGrowthPlan;
