import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Ensure Quill's CSS is imported
import he from "he";

const EditKnowledgeBase = ({
  show,
  setShow,
  selectedData,
  loadKnowledgeBase,
}) => {
  // Configure axios instance
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const [editCategory, setEditCategory] = useState("");
  const [editAccessView, setEditAccessView] = useState("");
  const [editTitleSection, setEditTitleSection] = useState("");
  const [editBlocks, setEditBlocks] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Initialize resourceData state
  const [resourceData, setResourceData] = useState([]);

  useEffect(() => {
    if (selectedData) {
      getKnowledgeById();
    }
  }, [selectedData]);

  const handleClose = () => {
    setShow(false);
  };

  const getKnowledgeById = async () => {
    try {
      const response = await authFetch.get(
        `/knowledgebase/knowledgebyid/${selectedData}`
      );

      if (response.status === 200 || response.status === 201) {
        setEditCategory(response.data.category);
        setEditTitleSection(response.data.titleSection);
        setEditAccessView(response.data.accessView);
        setEditBlocks(response.data.blocks);
        setEditTitle(response.data.title); // Set title from the response if it exists
      }
    } catch (error) {
      console.error("Error loading knowledge base data:", error);
    }
  };

  const modules = {
    toolbar: [
      // Header formatting and font selection
      [
        { header: "1" },
        { header: "2" },
        { header: "3" },
        { header: "4" },
        { font: ["Open Sans", "serif", "monospace"] },
      ],
      // Font size selection
      [{ size: [] }],
      // Basic text formatting: bold, italic, underline, strikethrough, blockquote
      ["bold", "italic", "underline", "strike", "blockquote"],
      // List formatting: ordered list, bullet list, indentation
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // Links, images, and videos
      ["link", "image", "video"],
      // Text color and background color with specified color codes
      [
        {
          color: [
            "#243060",
            "#fe7064",
            "#fed970",
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
        {
          background: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
      ],
      // Text alignment options
      [{ align: [] }],
      // Clear formatting
      ["clean"],
    ],
  };
  // const loadKnowledgeBase = async () => {
  //   try {
  //     setLoading(true); // Set loading state to true before fetching data
  //     const response = await authFetch.get("/knowledgebase");

  //     if (response.status === 200) {
  //       setResourceData(response.data); // Set the fetched data to resourceData
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 404) {
  //       // Handle the 404 error specifically
  //       console.warn("No knowledge base items found.");
  //       setResourceData([]); // Set to empty array if 404 occurs
  //     } else {
  //       console.error("Error loading course resources:", error);
  //       setError("Failed to load resources. Please try again later.");
  //     }
  //   } finally {
  //     setLoading(false); // Reset loading state after fetching data or if an error occurs
  //   }
  // };

  const handleBlockChange = (blockIndex, newValue) => {
    const newBlocks = [...editBlocks];
    newBlocks[blockIndex].content = newValue;
    setEditBlocks(newBlocks);
  };

  const handleFileChange = (blockIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newBlocks = [...editBlocks];
        newBlocks[blockIndex].content = e.target.result;
        setEditBlocks(newBlocks);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTitleChange = (newTitle) => {
    setEditTitle(newTitle);
  };

  const submitUpdatedInfo = async (e) => {
    e.preventDefault();

    try {
      const data = {
        title: editTitle,
        blocks: editBlocks,
      };

      const response = await authFetch.patch(
        `/knowledgebase/updateinfo/${selectedData}`,
        data
      );

      if (response.status === 200 || response.status === 201) {
        handleClose();
        loadKnowledgeBase();
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Edit KnowledgeBase Content</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "100%" }}>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicTitle">
            <Form.Control
              type="text"
              placeholder="Enter Title Here"
              value={editTitle}
              onChange={(e) => handleTitleChange(e.target.value)}
              name="title"
              style={{ fontSize: "1em" }}
            />
          </Form.Group>
          <div
            className="flex flex-column"
            style={{ gap: "20px" }} // Ensures the blocks are aligned vertically with space between them
          >
            {editBlocks.map((block, blockIndex) => {
              const decodedContent = block?.content
                ? he.decode(block.content)
                : "";
              return (
                <div
                  key={block._id}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    backgroundColor:
                      blockIndex % 2 === 0 ? "#f8f8f8" : "#e0e0e0", // Gray color for every other block
                  }}
                >
                  {block.type === "text" ? (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <ReactQuill
                          theme="snow"
                          value={decodedContent}
                          onChange={(newContent) =>
                            handleBlockChange(blockIndex, he.encode(newContent))
                          }
                          modules={modules}
                          className="react-quill-editor"
                          style={{ height: "300px" }}
                        />
                      </div>
                      <div style={{ marginTop: "70px", marginBottom: "50px" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodedContent,
                          }}
                        />
                      </div>
                    </>
                  ) : block.type === "image" ? (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(event) =>
                          handleFileChange(blockIndex, event)
                        }
                      />
                      <img
                        src={block.imageUrl?.Location || block.content}
                        alt={block.title}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </>
                  ) : block.type === "video" ? (
                    <>
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(event) =>
                          handleFileChange(blockIndex, event)
                        }
                      />
                      <video
                        controls
                        src={block.content}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={submitUpdatedInfo} className="btn btn-primary">
          Update
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditKnowledgeBase;
