import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaMoneyBill, FaVideo } from "react-icons/fa";
import j1 from "../../assets/newi/j1.png";
import j2 from "../../assets/newi/j2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductLivestream = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>Livestream | Churchease - Broadcast Services and Events</title>
        <meta
          name="description"
          content="Expand your reach with Churchease's Livestream feature. Broadcast your services and events in real-time with high-quality streaming and interactive participation, connecting your community wherever they are."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/livestream"
        />
        <meta
          property="og:title"
          content="Livestream | Churchease - Broadcast Services and Events"
        />
        <meta
          property="og:description"
          content="Expand your reach with Churchease's Livestream feature. Broadcast your services and events in real-time with high-quality streaming and interactive participation, connecting your community wherever they are."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/livestream"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#B53BE0" }}>
            <div>
              <FaVideo size={100} />
              <p className="headssc"> Livestream</p>
              <p className="headpp">
                Expand your reach with our Livestream feature. Broadcast your
                services and events in real-time, ensuring high-quality
                streaming and interactive participation. This tool connects your
                community, wherever they are, making every session accessible
                and engaging.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">Everything you need for live streaming</p>
            <p className="product13">
              Your gateway to broadcast services and events, connecting your
              community wherever they are with high-quality streaming.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={j1} alt="Livestream" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">HIGH-QUALITY STREAMING</p>
              <p className="proddz1">
                Offer high-quality video streaming that ensures clear and
                uninterrupted service broadcasts.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">INTERACTIVE FEATURES</p>
              <p className="proddz1">
                Engage online attendees with interactive features such as live
                chats, polls, and Q&A sessions.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACCESSIBILITY OPTIONS</p>
              <p className="proddz1">
                Provide accessibility options like subtitles and audio
                descriptions to accommodate all members of your community.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">MULTI-PLATFORM SUPPORT</p>
              <p className="proddz1">
                Stream across multiple platforms simultaneously, reaching a
                wider audience wherever they prefer to watch.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ANALYTICS AND REPORTING</p>
              <p className="proddz1">
                Track viewer statistics and engagement metrics to analyze and
                improve your livestream's reach and impact.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ON-DEMAND CONTENT</p>
              <p className="proddz1">
                Offer on-demand playback of services and events, allowing
                members to watch at their convenience.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={j2} alt="Livestream" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#B53BE0" />
      <Footer />
    </>
  );
};

export default ProductLivestream;
