import React, { useState, useEffect } from "react";
import { Menu, Avatar } from "antd";

import ReactMarkdown from "react-markdown";
import { PlayCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const { Item } = Menu;

const SingleCourse = () => {
  const [clicked, setClicked] = useState(-1);
  const [collapsed, setCollapsed] = useState(false);

  const [course, setCourse] = useState({ lessons: [] });
  const [completedLessons, setCompletedLessons] = useState([]);
  // force state update
  const [updateState, setUpdateState] = useState(false);

  // router
  const { slug } = useParams();

  const { logoutUser, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/user/course/${slug}`);

    setCourse(data);
  };

  const loadCompletedLessons = async () => {
    const { data } = await authFetch.post(`/learning/list-completed`, {
      courseId: course?._id,
      mId: user._id,
      churchName: user?.churchName,
    });
    //console.log(data);
    setCompletedLessons(data);
  };

  useEffect(() => {
    if (slug) loadCourse();
  }, [slug]);

  useEffect(() => {
    if (course) loadCompletedLessons();
  }, [course]);
  const markCompleted = async () => {
    await authFetch.post(`/learning/mark-completed`, {
      courseId: course?._id,
      lessonId: course?.lessons[clicked]?._id,
      churchName: user?.churchName,
      mId: user._id,
      lessons: course.lessons.length,
    });

    setCompletedLessons([...completedLessons, course?.lessons[clicked]?._id]);
  };

  const markIncompleted = async () => {
    try {
      await authFetch.post(`/learning/mark-incomplete`, {
        courseId: course?._id,
        lessonId: course?.lessons[clicked]?._id,
        churchName: user?.churchName,
        mId: user._id,
        lessons: course.lessons.length,
      });

      const all = completedLessons;

      const index = all.indexOf(course?.lessons[clicked]?._id);
      if (index > -1) {
        all.splice(index, 1);

        setCompletedLessons(all);
        setUpdateState(!updateState);
      }
    } catch (err) {}
  };

  const updateEnded = async () => {
    markCompleted();
  };
  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <p
            className="courseHeaderPadding"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {course?.name}
          </p>
        </Row>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Col sm={4}>
            <div style={{ maWidth: 320 }}>
              <Menu
                defaultSelectedKeys={[clicked]}
                inlineCollapsed={collapsed}
                style={{
                  height: "750px",
                  overflow: "scroll",
                  paddingTop: "2rem",
                }}
              >
                {course?.lessons.map((lesson, index) => (
                  <Item
                    onClick={() => setClicked(index)}
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                    icon={
                      <Avatar
                        style={{ backgroundColor: "#FE7064", color: "#fff" }}
                      >
                        {index + 1}
                      </Avatar>
                    }
                  >
                    {lesson?.title.substring(0, 30)}{" "}
                    {completedLessons.includes(lesson?._id) ? (
                      <CheckCircleFilled
                        className="float-right ml-2"
                        style={{
                          marginTop: "13px",
                          color: "var(--primary-color)",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Item>
                ))}
              </Menu>
            </div>
          </Col>
          <Col sm={8}>
            {clicked !== -1 ? (
              <>
                <div className="col alert alert-primary square">
                  <b>{course?.lessons[clicked]?.title.substring(0, 30)}</b>
                  {completedLessons.includes(course?.lessons[clicked]?._id) ? (
                    <span
                      className="float-right gkgjgjg"
                      onClick={markIncompleted}
                    >
                      {" "}
                      - Mark as incomplete
                    </span>
                  ) : (
                    <span
                      className="float-right gkgjgjg"
                      onClick={markCompleted}
                    >
                      {" "}
                      - Mark as completed
                    </span>
                  )}
                </div>

                {course?.lessons[clicked]?.video &&
                  course?.lessons[clicked]?.video?.Location && (
                    <>
                      <div className="wrapper">
                        {/* <ReactPlayer
                          className="player"
                          url={course?.lessons[clicked]?.video?.Location}
                          width="100%"
                          height="650px"
                          controls
                          onEnded={markCompleted}
                        /> */}

                        <video
                          controls
                          autoplay
                          width="100%"
                          height="650"
                          onEnded={updateEnded}
                        >
                          <source
                            type="video/mp4"
                            src={course?.lessons[clicked]?.video?.Location}
                          />
                          something went wrong
                        </video>
                      </div>
                    </>
                  )}

                <ReactMarkdown
                  source={course?.lessons[clicked]?.content}
                  className="single-post"
                />
              </>
            ) : (
              <div className="d-flex justify-content-center p-5">
                <div className="text-center p-5">
                  <PlayCircleOutlined
                    className=" display-1 p-5"
                    style={{ color: "#FE7064" }}
                  />
                  <p className="lead">Click on the lessons to start learning</p>
                </div>
              </div>
            )}
          </Col>
        </div>
      </Container>
    </>
  );
};

export default SingleCourse;
