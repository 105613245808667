import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaPray } from "react-icons/fa";
import k1 from "../../assets/newi/k1.png";
import k2 from "../../assets/newi/k2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductFellowship = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>Fellowship | Churchease - Strengthen Community Ties</title>
        <meta
          name="description"
          content="Strengthen community ties with Churchease's Fellowship module. Facilitate and manage prayer requests and testimonies, enhancing spiritual support and engagement within your congregation."
        />
        <link
          rel="canonical"
          href="https://churchease.com/product/fellowship"
        />
        <meta
          property="og:title"
          content="Fellowship | Churchease - Strengthen Community Ties"
        />
        <meta
          property="og:description"
          content="Strengthen community ties with Churchease's Fellowship module. Facilitate and manage prayer requests and testimonies, enhancing spiritual support and engagement within your congregation."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/fellowship"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#45877B" }}>
            <div>
              <FaPray size={100} />
              <p className="headssc">Fellowship</p>
              <p className="headpp">
                Strengthen community ties with our Fellowship module. Facilitate
                and manage prayer requests and testimonies, enhancing spiritual
                support and engagement. This feature is designed to foster
                deeper connections and spiritual growth within your
                congregation.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for fostering fellowship
            </p>
            <p className="product13">
              Your dedicated platform for sharing prayers and testimonies,
              enriching the spiritual life of your congregation.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={k1} alt="Fellowship" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">PRAYER REQUEST MANAGEMENT</p>
              <p className="proddz1">
                Facilitate and manage prayer requests efficiently, ensuring
                every member's spiritual needs are acknowledged and supported.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">TESTIMONY SHARING</p>
              <p className="proddz1">
                Provide a platform for members to share their testimonies,
                inspiring others and strengthening faith within the community.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">GROUP ENGAGEMENT</p>
              <p className="proddz1">
                Enhance small group interactions with tools designed for
                effective communication and spiritual growth.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">EVENT INTEGRATION</p>
              <p className="proddz1">
                Seamlessly integrate fellowship events into the church calendar,
                encouraging participation and community building.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MEMBER SUPPORT</p>
              <p className="proddz1">
                Offer structured support systems for members through mentoring
                and counseling services.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">INSIGHTS AND ANALYTICS</p>
              <p className="proddz1">
                Gain insights into fellowship activities with analytics that
                help improve programs and member satisfaction.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={k2} alt="Fellowship" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#45877B" />
      <Footer />
    </>
  );
};

export default ProductFellowship;
