import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";
import Alert from "../Alert/Alert";
import "./addtag.css";
import axios from "axios";

const AddTag = ({ show, setShow }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, showAlert, logoutUser, getContainers } = useAppContext();
  const [tag, setTag] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState(false);

  const handleSave = async () => {
    // Trimming input values
    const [trimmedTag, trimmedTagDescription] = trimInputs([
      tag,
      tagDescription,
    ]);

    // Reset error state before starting validation checks
    setError("");

    // Validate non-empty tag
    if (!validateNotEmpty(trimmedTag)) {
      setError(" Please provide all required information to proceed.");
      return;
    }

    // Validate tag length
    if (!validateLength(trimmedTag, NAME_LENGTH_LIMIT)) {
      setError(`Tag cannot exceed ${NAME_LENGTH_LIMIT} words.`);
      return;
    }

    // Validate description length
    if (!validateLength(trimmedTagDescription, DESCRIPTION_LENGTH_LIMIT)) {
      setError(`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} words.`);
      return;
    }

    // Preparing the newTag object with trimmed values
    const newTag = {
      name: trimmedTag,
      description: trimmedTagDescription,
      churchName: user.churchName,
    };

    // const response = await addContainer(newTag);
    // console.log(response);
    // if (response.status === 201 || response.status === 200) {
    //   // Check for a successful response
    //   setTag("");
    //   setTagDescription("");
    //   handleClose(); // Uncomment to close the modal or form upon successful save
    // } else {
    //   // Handle error case if needed
    //   setError("An error occurred while saving the tag.");
    // }

    const response = await authFetch.post("/container", newTag);

    if (response.status === 201 || response.status === 200) {
      // Check for a successful response
      getContainers();
      setTag("");
      setTagDescription("");
      handleClose(); // Uncomment to close the modal or form upon successful save
      toast(
        <CustomToastContent
          title="Success"
          message="Group created successfully!"
        />
      );
    } else {
      // Handle error case if needed
      setError("An error occurred while saving the tag.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert && <Alert />}
        {textError && (
          <p className="inlineerror">Maximum text length exceed!</p>
        )}
        <Form.Label htmlFor="inputPassword5">Group Name</Form.Label>
        <Form.Control
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          required
        />
        <Form.Label className="mt-3" htmlFor="inputPassword5">
          Description (optional)
        </Form.Label>
        <Form.Control
          type="text"
          value={tagDescription}
          onChange={(e) => setTagDescription(e.target.value)}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTag;
