import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";

const EditTag = ({ show, setShow, tagId, setTagId }) => {
  const handleClose = () => setShow(false);

  const { getContainerById, user, uniquecontainer, editContainer } =
    useAppContext();
  const [tag, setTag] = useState("");
  const [tagDescription, setTagDescription] = useState("");

  useEffect(() => {
    getContainerById(tagId);
  }, [tagId]);

  useEffect(() => {
    if (uniquecontainer) {
      setTag(uniquecontainer.name);
      setTagDescription(uniquecontainer.description);
    }
  }, [uniquecontainer]);

  const handleSave = () => {
    const editedTag = {
      name: tag,
      description: tagDescription,
      churchName: user.churchName,
    };

    editContainer(tagId, editedTag);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Tag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label htmlFor="inputPassword5">Ministry Name</Form.Label>
        <Form.Control
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          required
        />

        <Form.Label className="mt-3" htmlFor="inputPassword5">
          Description (optional)
        </Form.Label>
        <Form.Control
          type="text"
          value={tagDescription}
          onChange={(e) => setTagDescription(e.target.value)}
          required
        />
        <div className="my-3"></div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil3" onClick={handleSave}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditTag;
