import React from "react";
import CourseNav from "../../../components/CourseNav/CourseNav";
import ViewAllCourses from "./ViewAllCourses";
import CourseView from "./CourseView";
import LearningMain from "./LearningMain";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";

const LearningHome = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <CourseNav />
      <ViewAllCourses />
    </>
  );
};

export default LearningHome;
