import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const ShareContent = ({ show, setShow, selectedValue }) => {
  const {
    logoutUser,
    completemembers,
    user,
    uniquecontainer,
    getContainers,
    containers,
    getCompleteMembers,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [group, setGroup] = useState("");
  const [giverName, setGiverName] = useState("");
  const [selectedName, setSelectedName] = useState([]);
  const [groupValue, setGroupValue] = useState([]);

  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [notice, setNotice] = useState(false);

  useEffect(() => {
    getContainers();
  }, []);

  const handleClose6 = () => setShow(false);
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    setGroup(value);
  };
  const [teacherValue, setTeacherValue] = useState([]);
  const handleChangex = (value) => {
    //console.log(`selected ${value}`);
    setGroup(value);
  };
  const [teacherData, setTeacherData] = useState([]);
  const [groupTeachers, setGroupTeachers] = useState([]);
  useEffect(() => {
    fetchTeacherData();
  }, []);

  const fetchTeacherData = async () => {
    try {
      const response = await authFetch.get("/children/getteachers");
      // Assuming the actual data is in `response.data.result` based on typical Axios response structure

      if (response && response.data) {
        setTeacherData(response.data);
      } else {
        // Handle the case where the response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // Handle errors from the fetch operation
      console.error("Error fetching classroom data:", error);
    }
  };

  const onChange = (event) => {
    setGiverName(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setGiverName(searchTerm);
    setSelectedName([...selectedName, searchTerm]);
  };

  const transformedContainers = containers.map((container) => ({
    ...container,
    label: container.name, // Setting the label equal to the name field
    value: container._id, // Setting the id equal to the _id field
  }));

  const transformedTeachers = teacherData.map((teacher) => ({
    ...teacher,
    label: teacher.teacherName, // Setting the label equal to the name field
    value: teacher._id, // Setting the id equal to the _id field
  }));

  const handleShareWithMember = async () => {
    try {
      const [fname, lname] = giverName.split(" ");
      const { data } = await authFetch(
        `/members/getmemberidonly/${fname}/${lname}`
      );
      const [memberIdOnly] = data;
      const sharedAudio = {
        mid: memberIdOnly._id,
      };

      await authFetch.put(
        `/learning/share-audio/${selectedValue._id}`,
        sharedAudio
      );
      setNotice(true);
      setGiverName("");
      setSelectedName([]);
      getContainers();
      getCompleteMembers();
      // handleClose6();
    } catch (err) {
      console.log(err);
    }
  };
  // const handleTest = (value) => {
  //   setGroupValue(value);
  // };

  const handleShareGroup = async () => {
    //console.log(groupValue);
    try {
      const sharedAudio = {
        group: groupValue,
        selectedValue: selectedValue._id,
      };

      await authFetch.patch(`/learning/share-audio`, sharedAudio);
      setGroupValue([]);
      getContainers();
      getCompleteMembers();
      handleClose6();
    } catch (err) {
      console.log(err);
    }
  };
  const [teacherIds, setAllTeacherData] = useState([]);
  useEffect(() => {
    const teacherIds = teacherData.map((teacher) => teacher._id);
    setAllTeacherData(teacherIds);
  }, [teacherData]);

  const shareWithAllTeachers = async (e) => {
    e.preventDefault();
    //console.log("Sharing content with all teachers:", selectedValue);

    try {
      const sharedContent = {
        group: "all teachers",
        selectedValue: selectedValue._id, // Ensure selectedValue and _id are valid and available
        teacherIds,
      };

      const response = await authFetch.patch(
        `/children/resource/sharewithallteachers`,
        sharedContent
      );

      // Check response status and handle accordingly
      if (response.ok) {
        console.log("Content shared successfully");
        handleClose6(); // Assuming this function closes a modal or resets UI state
      } else {
        console.error(
          "Failed to share content with all teachers:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error sharing content with all teachers:", err);
    }
  };

  const shareWithTeachers = async () => {
    try {
      const sharedContent = {
        group: "teacher",
        selectedValue: selectedValue._id,
        groupTeachers,
      };

      await authFetch.patch(
        `/children/resource/sharewithteacher`,
        sharedContent
      );
      getContainers();
      getCompleteMembers();
      handleClose6();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title="Share Resource"
      open={show}
      onOk={handleClose6}
      onCancel={handleClose6}
    >
      {notice && (
        <p
          style={{
            textAlign: "center",
            color: "var(--primary-color)",
            fontSize: "1.2em",
          }}
        >
          Sharing Successful!
        </p>
      )}
      <p>You share this audio with members or groups of your community.</p>
      <div className="flex flex-column w-100">
        <div className="flex flex-column w-100">
          <Select
            style={{ width: "100%" }}
            placeholder="Select Group"
            onChange={handleChangex}
            value={group}
            options={[
              {
                value: "member",
                label: "Members",
              },
              {
                value: "group",
                label: "Groups",
              },
              {
                value: "teacher",
                label: "Teachers",
              },
              {
                value: "all teachers",
                label: "All Teachers",
              },
            ]}
          />

          {group === "group" && (
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                onChange={(e) => setGroupValue(e)}
                options={transformedContainers}
                value={groupValue}
                className="
                  my-3"
              />
              <button
                disabled={groupValue.length === 0}
                className="jiluil3"
                onClick={handleShareGroup}
              >
                <PlusOutlined classID="mccccc" /> Share with Groups
              </button>
            </div>
          )}

          {group === "teacher" && (
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                onChange={(e) => setGroupTeachers(e)}
                options={transformedTeachers}
                value={groupTeachers}
                className="
                  my-3"
              />
              <button
                disabled={groupTeachers.length === 0}
                className="jiluil3"
                onClick={shareWithTeachers}
              >
                <PlusOutlined classID="mccccc" /> Share with Teacher
              </button>
            </div>
          )}

          {group === "all teachers" && (
            <div>
              <button
                className="jiluil3"
                onClick={(e) => shareWithAllTeachers(e, setTeacherValue)}
              >
                <PlusOutlined className="mccccc" /> Share with All Teachers
              </button>
            </div>
          )}

          {group === "member" && (
            <div>
              <div className="flex items-start flex-column justify-between  ">
                <Form.Control
                  type="text"
                  value={giverName}
                  onChange={onChange}
                  required
                  placeholder="Enter Member Name"
                  className="my-3 "
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                {completemembers
                  .filter((item) => {
                    const searchTerm = giverName.toLowerCase();
                    const fname = item.mfirstName.toLowerCase();
                    const lname = item.mlastName.toLowerCase();
                    const fullname = fname + lname;
                    return (
                      searchTerm &&
                      fullname.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() =>
                        onSearch(item.mfirstName + " " + item.mlastName)
                      }
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
              <button
                disabled={!giverName}
                className="jiluil3"
                onClick={handleShareWithMember}
              >
                <PlusOutlined classID="mccccc" /> Share with Member
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShareContent;
