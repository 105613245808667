import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT_SMALL,
} from "../../utils/validation";

const AddContent = ({ show, setShow, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => {
    setShow(false);
    setResourceTitle("");
    setResourceDescription("");
    setResourceFile(null);
    setResourceLink("");
    setResourceType("");
    setResourceTags("");
    setReourceCategory("");
    setResourceName("");
    setError("");
    setUploadLink(false);
  };
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceDescription, setResourceDescription] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [resourceLink, setResourceLink] = useState("");
  const [resourceFile, setResourceFile] = useState(null);
  const [resourceTags, setResourceTags] = useState("");
  const [resourceCategory, setReourceCategory] = useState("");

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Audio");
  const [uploadLink, setUploadLink] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [error, setError] = useState("");
  const [resourceName, setResourceName] = useState("");

  const handleResourceUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setResourceFile(file); // Assuming you have a state setter for the resource file
      if (!file) {
        // toast("No file selected");
        return;
      }
      setResourceName(file.name);
      setUploadButtonText(file.name);
      setUploading(true);

      const resourceData = new FormData();
      resourceData.append("resource", file); // 'resource' should match the field name expected by your backend

      try {
        const { data } = await authFetch.post(
          "/children/resource/uploadfile", // Adjust the API endpoint as needed
          resourceData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setResourceFile(data); // Assuming you want to store the uploaded resource data in the state
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleResourceRemove = async () => {
    try {
      setUploading(true);

      // Assuming `resourceFile` holds the current file to be removed
      await authFetch.post(`/learning/resource/remove`, {
        fileId: resourceFile.id,
      });

      setResourceFile(null);
      setProgress(0);

      setUploading(false);
      setUploadButtonText("Upload another resource");
    } catch (err) {
      console.error("Error removing resource:", err);
      setUploading(false);
    }
  };

  const isValidNumber = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 && !isNaN(trimmedValue);
  };

  const handleResourceSubmit = async (e) => {
    e.preventDefault();

    const trimmedName = resourceTitle.trim();
    const trimmedDescription = resourceDescription.trim();
    const trimmedCategory = resourceCategory.trim();

    setError("");
    if (
      trimmedName === "" ||
      trimmedCategory === "" ||
      resourceType === "" ||
      resourceType === ""
    ) {
      toast(
        <CustomToastContent title="Error" message="Please fill all fields" />
      );
      return;
    }

    // Validate name length
    if (trimmedName.length > NAME_LENGTH_LIMIT) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Resource Name cannot exceed ${NAME_LENGTH_LIMIT} characters.`}
        />
      );
      return;
    }

    // Validate description length
    if (trimmedDescription.length > DESCRIPTION_LENGTH_LIMIT_SMALL) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT_SMALL} characters.`}
        />
      );

      return;
    }

    const resourceData = {
      title: trimmedName,
      description: trimmedDescription,
      link: resourceLink,
      churchName: user.churchName,
      url: resourceFile, // Assuming you have a state for the file like `resourceFile`
      type: resourceType, // Assuming you have a way to set the type of the resource
      tags: resourceTags,
      subject: resourceCategory,
      fileName: resourceName,
    };

    try {
      const response = await authFetch.post("/children/resource", resourceData);
      if (response.status === 200) {
        handleReload();
        setResourceTitle("");
        setResourceDescription("");
        setResourceFile(null);
        setResourceLink("");
        setResourceType("");
        setResourceTags("");
        setReourceCategory("");
        setResourceName("");
        setProgress(0);
        setUploading(false);
        setUploadButtonText("Upload another resource");
        setShow(false);
        toast(
          <CustomToastContent
            title="Success"
            message="Resource added successfully"
          />
        );
      }
    } catch (err) {
      console.error(err);
      toast(
        <CustomToastContent title="Error" message="Failed to add resource" />
      );

      setUploading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Content to Library</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="inlineerror">{error}</p>}
        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>
            Content Title <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={resourceTitle}
            onChange={(e) => setResourceTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>
            Content Category <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={resourceCategory}
            onChange={(e) => setReourceCategory(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="resourceDescription">
          <Form.Label>
            Content Description <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={resourceDescription}
            onChange={(e) => setResourceDescription(e.target.value)}
            required
          />
          <p className="smalltext flex justify-end">
            {resourceDescription.length}/100
          </p>
        </Form.Group>

        <Form.Group className="mb-3" controlId="resourceType">
          <Form.Label>
            Content Type <span className="myast">*</span>
          </Form.Label>
          <Form.Select
            value={resourceType}
            onChange={(e) => setResourceType(e.target.value)}
            required
          >
            <option>Select an option</option>
            <option value="audio">Audio</option>
            <option value="book">Book</option>
            <option value="video">Video</option>
            <option value="document">Document</option>
            <option value="other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="useLink">
          <Form.Check
            type="checkbox"
            label="Use link instead of uploading"
            checked={uploadLink}
            onChange={(e) => setUploadLink(e.target.checked)}
          />
        </Form.Group>

        {uploadLink ? (
          <Form.Group className="mb-3" controlId="resourceLink">
            <Form.Label>Content Link</Form.Label>
            <Form.Control
              type="text"
              value={resourceLink}
              onChange={(e) => setResourceLink(e.target.value)}
              placeholder="https://example.com/resource"
              required
            />
          </Form.Group>
        ) : (
          <Form.Group className="mb-3" controlId="uploadResource">
            <Form.Label>
              Upload Resource <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleResourceUpload}
              accept="audio/*, video/*, application/pdf" // Adapt based on your allowed types
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="resourceTitle">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            type="text"
            value={resourceTags}
            onChange={(e) => setResourceTags(e.target.value)}
          />
        </Form.Group>
        <p className="smalltext">Seperate tags using comma</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleResourceSubmit}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContent;
