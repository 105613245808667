import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../context/appContext";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import { Container, Col } from "react-bootstrap";

import { Avatar, Button } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Profile from "../../../assets/images/blank2.png";

import { CSVLink } from "react-csv";
const CampusDetails = () => {
  const { _id } = useParams();
  const { getViewById, uniqueviewdetails, logoutUser } = useAppContext();
  const location = useLocation();
  const { campusName } = location.state || {};

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [campus, setCampus] = useState({});
  const getCampus = async () => {
    try {
      const response = await authFetch.get(
        "/members/campus-details/" + campusName
      );

      setCampus(response.data);
    } catch (error) {
      console.error("Error fetching campus:", error);
    }
  };

  useEffect(() => {
    getCampus();
  }, []);

  const [fnamex, setFnamex] = useState(true);

  const [photox, setPhotox] = useState(true);

  const [lnamex, setLnamex] = useState(true);

  const [emailx, setEmailx] = useState(true);

  const [genderx, setGenderx] = useState(false);

  const [statusx, setStatusx] = useState(true);

  const [maritalx, setMaritalx] = useState(false);

  const [agex, setAgex] = useState(false);

  const [familyx, setFamilyx] = useState(false);

  const [gradex, setGradex] = useState(false);

  const [schoolx, setSchoolx] = useState(false);

  const [employerx, setEmployerx] = useState(false);

  const [workPhonex, setworkPhonex] = useState(false);

  const [mobilex, setmobilePhonex] = useState(true);

  const [postalx, setPostalx] = useState(false);

  const [statex, setStatex] = useState(false);

  const [homephonex, sethomePhonex] = useState(false);

  const [cityx, setCityx] = useState(false);

  // let dataSpread = uniqueviewdetails.viewMembers;

  const copiedArray = campus?.map((obj) => ({
    mfirstName: obj.mfirstName,
    mlastName: obj.mlastName,
    memail: obj.memail,
    mmobile: obj.mmobile,
    mgender: obj.mgender,
    mstatus: obj.mstatus,
    mmartial: obj.mmartial,
    mage: obj.mage,
    mgrade: obj.mgrade,
    mschool: obj.mschool,
    memployer: obj.memployer,
    mworkPhone: obj.mworkPhone,
    mhomePhone: obj.mhomePhone,
    mpostal: obj.mpostal,
    mstate: obj.mstate,
    mcity: obj.mcity,
    mBirthday: obj.mBirthday,
    mAnniversary: obj.mAnniversary,
    maddress: obj.maddress,
  }));

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">{campusName}</p>
      </Row>
      <Row className="tabw">
        <Col>
          <p
            style={{
              fontWeight: "700",
              fontSize: "1em",
              color: "var(--primary-color)",
              paddingTop: "2rem",
            }}
          >
            {campus.length} record
            {campus.length > 1 && "s"} found
          </p>
        </Col>
        <Col>
          {campus?.length && (
            <div className="flex justify-end">
              <button
                className="jiluil3"
                style={{
                  textDecoration: "none",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                }}
              >
                <CSVLink
                  data={copiedArray}
                  filename="savedData-churchease.csv"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download
                </CSVLink>
              </button>
            </div>
          )}
        </Col>
      </Row>

      <Row className="tabw">
        <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
          {campus?.map((member, index) => {
            return (
              <tbody>
                <tr key={index} className="trdata">
                  {photox && (
                    <td>
                      <Avatar
                        size={30}
                        src={
                          member?.mProfilePicture?.Location
                            ? member?.mProfilePicture?.Location
                            : Profile
                        }
                      />
                    </td>
                  )}
                  {fnamex && <td>{member?.mfirstName}</td>}
                  {lnamex && <td>{member?.mlastName}</td>}
                  {emailx && <td>{member?.memail}</td>}
                  {mobilex && (
                    <td>
                      {member?.mmobilePhone ? member?.mmobilePhone : "NA"}
                    </td>
                  )}
                  {genderx && (
                    <td>{member?.mgender ? member?.mgender : "NA"}</td>
                  )}
                  {statusx && (
                    <td>{member?.mstatus ? member?.mstatus : "NA"}</td>
                  )}
                  {maritalx && (
                    <td>{member?.mmarital ? member?.mmarital : "NA"}</td>
                  )}
                  {agex && <td>{member?.mage ? member?.mage : "NA"}</td>}
                  {schoolx && (
                    <td>{member?.mschool ? member?.mschool : "NA"}</td>
                  )}
                  {gradex && <td>{member?.mgrade ? member?.mgrade : "NA"}</td>}
                  {homephonex && (
                    <td>{member?.mhomePhone ? member?.mhomePhone : "NA"}</td>
                  )}
                  {workPhonex && (
                    <td>{member?.mworkPhone ? member?.mworkPhone : "NA"}</td>
                  )}
                  {cityx && <td>{member?.mcity ? member?.mcity : "NA"}</td>}
                  {statex && <td>{member?.mstate ? member?.mstate : "NA"}</td>}
                  {postalx && (
                    <td>{member?.mpostal ? member?.mpostal : "NA"}</td>
                  )}

                  {employerx && (
                    <td>{member?.memployer ? member?.memployer : "NA"}</td>
                  )}
                </tr>
              </tbody>
            );
          })}
        </Table>
      </Row>
    </Container>
  );
};

export default CampusDetails;
