import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/appContext";
import "./alert.css";
import CustomToastContent from "../Toast/CustomToastContent";

const Alert = () => {
  const { alertType, alertText, showAlert, clearAlert } = useAppContext();

  useEffect(() => {
    if (showAlert && alertText) {
      toast(
        <CustomToastContent
          title={alertType === "error" ? "Error" : "Success"}
          message={alertText}
          type={alertType}
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          rtl: false,
          newestOnTop: false,
        }
      );
    }
  }, [showAlert, alertText, alertType]);

  return null;
};

export default Alert;
