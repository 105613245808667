import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import axios from "axios";
import "./calendar.css";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { FaCheck } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { useAppContext } from "../../../context/appContext";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import DeleteCalendar from "../../../components/Calendar/DeleteCalendar";
const labelsClasses = [
  "brown",
  "orange",
  "green",
  "blue",
  "gray",
  "purple",
  "red",
  "black",
];

export default function EventModal() {
  const { logoutUser, getEvents } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [events, setEventxs] = useState([]);
  useEffect(() => {
    getEventById();
  }, []);

  const getEventById = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await authFetch.get(`/events`);

      if (data) {
        setEventxs(data.events);
      }
    } catch (error) {
      logoutUser();
    }
  };
  const [show3, setShow3] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [minDate, setMinDate] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const handleShow3 = () => setShow3(true);
  const { setShowEventModal, daySelected, selectedEvent } =
    useContext(GlobalContext);

  const { createEvent, deleteEvent, updateEvent, user } = useAppContext();

  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.eventTitle : ""
  );

  const [reStartTime, setReEndTime] = useState(
    selectedEvent ? selectedEvent.eventReStartTime : ""
  );
  const [reEndTime, setReStartTime] = useState(
    selectedEvent ? selectedEvent.eventReEndTime : ""
  );

  const [eventPattern, setEventPattern] = useState(
    selectedEvent ? selectedEvent.eventPattern : ""
  );

  const handleRadioChange1 = (e) => {
    setEventPattern(e.target.value);
  };

  const [reEventStartDate, setReEventStartDate] = useState(
    selectedEvent ? selectedEvent.reEventStartDate : ""
  );

  const [eventOccurence, setEventOccurence] = useState(
    selectedEvent ? selectedEvent.eventOccurence : ""
  );

  const handleOccurence = (e) => {
    setEventOccurence(e.target.value);
  };

  useEffect(() => {
    setMinDate(moment().format("YYYY-MM-DD"));
  }, []);

  const [eventEndAfter, setEventEndAfter] = useState(
    selectedEvent ? selectedEvent.eventEndAfter : ""
  );

  const [eventEndBy, setEventEndBy] = useState(
    selectedEvent ? selectedEvent.eventEndBy : ""
  );

  const [eventDay, setStartDate] = useState(
    selectedEvent ? selectedEvent.eventDay : ""
  );
  const [startTime, setStartTime] = useState(
    selectedEvent ? selectedEvent.eventStartTime : ""
  );
  const [endDate, setEndDate] = useState(
    selectedEvent ? selectedEvent.eventEndDate : ""
  );
  const [endTime, setEndTime] = useState(
    selectedEvent ? selectedEvent.eventEndTime : ""
  );
  const [allDay, setAllDay] = useState(
    selectedEvent ? selectedEvent.eventAllDay : false
  );
  const [repeat, setRepeat] = useState(
    selectedEvent ? selectedEvent.eventRepeat : ""
  );
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.eventDescription : ""
  );
  const [eventLink, setEventLink] = useState(
    selectedEvent ? selectedEvent.eventLink : ""
  );
  const [eventLocation, setEventLocation] = useState(
    selectedEvent ? selectedEvent.eventLocation : ""
  );
  const [volunteers, setVolunteers] = useState(
    selectedEvent ? selectedEvent.eventVolunteers : false
  );

  const [eventMode, setEventMode] = useState(
    selectedEvent ? selectedEvent.eventMode : ""
  );

  const [visible, setVisible] = useState(
    selectedEvent ? selectedEvent.eventVisible : false
  );
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.eventLabel)
      : labelsClasses[0]
  );

  function handleSubmit(e) {
    e.preventDefault();
    const currentEvent = {
      eventTitle: title,
      // eventStartDate: daySelected.format("YYYY-MM-DD"),
      eventDay: eventDay.valueOf(),
      eventStartTime: startTime,
      eventEndDate: endDate,
      eventEndTime: endTime,
      eventAllDay: allDay,
      eventRepeat: repeat,
      eventLocation: eventLocation,
      eventVolunteers: volunteers,
      eventMode: eventMode,
      eventDescription: description,
      churchName: user.churchName,
      eventLink: eventLink,
      eventLabel: selectedLabel,
      eventVisible: visible,
      // eventDay: daySelected.valueOf(),
      dashBoardDay: daySelected,
      eventId: selectedEvent ? selectedEvent.eventId : Date.now(),
      realId: selectedEvent ? selectedEvent._id : null,
      reStartTime: reStartTime,
      reEndTime: reEndTime,
      eventPattern: eventPattern,
      reEventStartDate: reEventStartDate,
      eventOccurence: eventOccurence,
      eventEndAfter: eventEndAfter,
      eventEndBy: eventEndBy,
    };

    if (selectedEvent && selectedEvent._id && selectedEvent.eventId) {
      updateEvent({ currentEvent }, selectedEvent.eventId);
      getEventById();
    } else {
      createEvent({ currentEvent });
      getEventById();
    }

    setShowEventModal(false);
  }

  const handleShow2 = (followupId) => {
    setShow2(true);
  };

  const deleteSingleView = (e, selectedEvent) => {
    e.preventDefault();
    handleShow3();
    setSelectedValue(selectedEvent);
  };

  return (
    <Container fluid>
      <div className="eventmode">
        <Form className="eventform">
          <header className="eventhead">
            <div className="headprev">
              <FaBars style={{ color: "#fff !important" }} />
            </div>
            <div>
              <button
                onClick={() => setShowEventModal(false)}
                className="headprev"
              >
                <FaTimes style={{ color: "#fff !important" }} />
              </button>
            </div>
          </header>

          <Row>
            <div className="event1x">
              <p
                style={{
                  fontSize: "130%",
                  fontWeight: "600",
                  marginBottom: "0",
                }}
              >
                Single Event
              </p>
              {!selectedEvent && (
                <button onClick={handleShow2} className="ewfwwwefh">
                  Recurring Event
                </button>
              )}
            </div>
            <div className="event1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Event Name"
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>

              <div className="nmdw">
                <Form.Label>Start:</Form.Label>
                <div className="nmdw1">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="date"
                      name="eventDay"
                      placeholder="Start Date"
                      value={eventDay}
                      required
                      onChange={(e) => setStartDate(e.target.value)}
                      className="nmdw2"
                      min={minDate} // set the minimum date
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="time"
                      name="startTime"
                      placeholder="Start Time"
                      value={reStartTime}
                      required
                      onChange={(e) => setReEndTime(e.target.value)}
                      className="nmdw2"
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                {/* <Form.Label>Event Description</Form.Label> */}
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  placeholder="Event Description"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="radio"
                  label="In Person Only"
                  name="eventMode"
                  value="InPerson"
                  onChange={(e) => setEventMode(e.target.value)}
                  checked={eventMode === "InPerson"}
                />
                <Form.Check
                  type="radio"
                  label="Online Only"
                  name="eventMode"
                  value="OnlineOnly"
                  onChange={(e) => setEventMode(e.target.value)}
                  checked={eventMode === "OnlineOnly"}
                />
                <Form.Check
                  type="radio"
                  label="Hybrid (In Person and Online)"
                  name="eventMode"
                  value="Hybrid"
                  onChange={(e) => setEventMode(e.target.value)}
                  checked={eventMode === "Hybrid"}
                />
              </Form.Group>

              {(eventMode === "OnlineOnly" || eventMode === "Hybrid") && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    name="eventLink"
                    placeholder="Event Link"
                    value={eventLink}
                    onChange={(e) => setEventLink(e.target.value)}
                  />
                </Form.Group>
              )}

              {(eventMode === "InPerson" || eventMode === "Hybrid") && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="eventLocation"
                    placeholder="Event Location"
                    value={eventLocation}
                    required
                    onChange={(e) => setEventLocation(e.target.value)}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Need Volunteers"
                  name="volunteers"
                  checked={volunteers}
                  onChange={(e) => setVolunteers(e.target.checked)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Make visible to members"
                  name="visible"
                  checked={visible}
                  onChange={(e) => setVisible(e.target.checked)}
                />
              </Form.Group>

              <div>
                <p style={{ fontSize: "1rem", fontWeight: "600" }}>Label</p>
                <div className="itez">
                  {labelsClasses.map((lblClass, i) => (
                    <span
                      text-white
                      key={i}
                      onClick={() => setSelectedLabel(lblClass)}
                      className={` tailwindd`}
                      style={{
                        backgroundColor: `${lblClass}`,
                        color: "white",
                      }}
                    >
                      {selectedLabel === lblClass && (
                        <FaCheck style={{ color: "white" }} />
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </Row>
          <footer className="weweeeee">
            <button
              type="submit"
              onClick={handleSubmit}
              className="ewfwwwefh22"
            >
              {selectedEvent ? "Update" : "Create"}
            </button>

            {selectedEvent && (
              <button
                // onClick={() => {
                //   deleteEvent(selectedEvent._id, {
                //     alertText: "Event Deleted",
                //   });
                //   setShowEventModal(false);
                // }}
                className="ewfwwwefh"
                onClick={(e) => deleteSingleView(e, selectedEvent)}
              >
                Delete
              </button>
            )}
          </footer>
        </Form>
      </div>
      <DeleteCalendar
        show3={show3}
        setShow3={setShow3}
        selectedValue={selectedValue}
        setShowEventModal={setShowEventModal}
      />

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "130%", fontWeight: "600" }}>
            Event Recurrence
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="title"
                placeholder="Event Name"
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Event Description</Form.Label> */}
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Event Description"
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="radio"
                label="In Person Only"
                name="eventMode"
                value="InPerson"
                onChange={(e) => setEventMode(e.target.value)}
                checked={eventMode === "InPerson"}
              />
              <Form.Check
                type="radio"
                label="Online Only"
                name="eventMode"
                value="OnlineOnly"
                onChange={(e) => setEventMode(e.target.value)}
                checked={eventMode === "OnlineOnly"}
              />
              <Form.Check
                type="radio"
                label="Hybrid (In Person and Online)"
                name="eventMode"
                value="Hybrid"
                onChange={(e) => setEventMode(e.target.value)}
                checked={eventMode === "Hybrid"}
              />
            </Form.Group>

            {(eventMode === "OnlineOnly" || eventMode === "Hybrid") && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  name="eventLink"
                  placeholder="Event Link"
                  value={eventLink}
                  onChange={(e) => setEventLink(e.target.value)}
                />
              </Form.Group>
            )}

            {(eventMode === "InPerson" || eventMode === "Hybrid") && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="eventLocation"
                  placeholder="Event Location"
                  value={eventLocation}
                  required
                  onChange={(e) => setEventLocation(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Need Volunteers"
                name="defaultFund"
                value="defaultFund"
                onChange={(e) => setVolunteers(e.target.checked)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Make visible to members"
                name="defaultFund"
                value="defaultFund"
                onChange={(e) => setVisible(e.target.checked)}
              />
            </Form.Group>
            <div className="mb-5">
              <p style={{ fontSize: "1rem", fontWeight: "600" }}>Label</p>
              <div className="itez">
                {labelsClasses.map((lblClass, i) => (
                  <span
                    text-white
                    key={i}
                    onClick={() => setSelectedLabel(lblClass)}
                    className={` tailwindd`}
                    style={{
                      backgroundColor: `${lblClass}`,
                      color: "white",
                    }}
                  >
                    {selectedLabel === lblClass && (
                      <FaCheck style={{ color: "white" }} />
                    )}
                  </span>
                ))}
              </div>
            </div>

            <div className="modallsss2">
              <Form.Group className="mb-3 eeesss" controlId="formBasicEmail">
                <Form.Label>Start Time:</Form.Label>
                <Form.Control
                  type="time"
                  value={reStartTime}
                  onChange={(e) => setReEndTime(e.target.value)}
                  name="followName1"
                  style={{ fontSize: "1em" }}
                />
              </Form.Group>
              <Form.Group className="mb-3 eeesss" controlId="formBasicEmail">
                <Form.Label>End Time:</Form.Label>
                <Form.Control
                  type="time"
                  value={reEndTime}
                  onChange={(e) => setReStartTime(e.target.value)}
                  name="followName1"
                  style={{ fontSize: "1em" }}
                />
              </Form.Group>
            </div>

            <div className="modallsss mt-3">
              <Form.Label>Event Pattern</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check // prettier-ignore
                  type="radio"
                  label="Daily"
                  name="eventPattern"
                  value="Daily"
                  checked={eventPattern === "Daily"}
                  onChange={handleRadioChange1}
                  aria-label="radio 1"
                  required
                />
                <Form.Check // prettier-ignore
                  type="radio"
                  label="Weekly"
                  name="eventPattern"
                  value="Weekly"
                  checked={eventPattern === "Weekly"}
                  onChange={handleRadioChange1}
                  aria-label="radio 1"
                  required
                />

                <Form.Check // prettier-ignore
                  type="radio"
                  label="Monthly"
                  name="eventPattern"
                  value="Monthly"
                  checked={eventPattern === "Monthly"}
                  onChange={handleRadioChange1}
                  aria-label="radio 1"
                  required
                />

                <Form.Check // prettier-ignore
                  type="radio"
                  label="Yearly"
                  name="eventPattern"
                  value="Yearly"
                  checked={eventPattern === "Yearly"}
                  onChange={handleRadioChange1}
                  aria-label="radio 1"
                  required
                />
              </Form.Group>
            </div>

            <div className="modallsss mt-5">
              <Form.Label>Event Occurrences</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Start Date</Form.Label>

                <Form.Control
                  type="date"
                  name="eventDay"
                  value={eventDay}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                  className="nmdw2"
                  min={minDate} // set the minimum date
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                {/* <Form.Check // prettier-ignore
                  type="radio"
                  label="No end date"
                  name="eventOccurence"
                  value="No end date"
                  checked={eventOccurence === "No end date"}
                  onChange={handleOccurence}
                  aria-label="radio 1"
                  required
                /> */}

                <Form.Check // prettier-ignore
                  type="radio"
                  label="End after"
                  name="eventOccurence"
                  value="End after"
                  checked={eventOccurence === "End after"}
                  onChange={handleOccurence}
                  aria-label="radio 1"
                  required
                />

                <Form.Check // prettier-ignore
                  type="radio"
                  label="End by"
                  name="eventOccurence"
                  value="End by"
                  checked={eventOccurence === "End by"}
                  onChange={handleOccurence}
                  aria-label="radio 1"
                  required
                />
              </Form.Group>
            </div>
            <div>
              {" "}
              {eventOccurence === "No end date" ? (
                ""
              ) : (
                <div>
                  {eventOccurence === "End after" && (
                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>End after</Form.Label>
                      <Form.Control
                        type="text"
                        value={eventEndAfter}
                        onChange={(e) => setEventEndAfter(e.target.value)}
                        name="followName1"
                        style={{ fontSize: "1em" }}
                      />
                      <p>occurrences</p>
                    </Form.Group>
                  )}
                  {eventOccurence === "End by" && (
                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>End By:</Form.Label>
                      <Form.Control
                        type="date"
                        value={eventEndBy}
                        onChange={(e) => setEventEndBy(e.target.value)}
                        name="followName1"
                        style={{ fontSize: "1em" }}
                      />
                    </Form.Group>
                  )}
                </div>
              )}
            </div>

            {eventOccurence === "End by" &&
              eventDay &&
              reStartTime &&
              eventEndBy &&
              eventPattern && (
                <p className="mt-3">
                  Events starts on {eventDay} at {reStartTime}. It will occur{" "}
                  {eventPattern} and will end by {eventEndBy}
                </p>
              )}

            {eventOccurence === "End after" &&
              eventDay &&
              reStartTime &&
              eventEndAfter &&
              eventPattern && (
                <p className="mt-3">
                  Events starts on {eventDay} at {reStartTime}. It will occur{" "}
                  {eventPattern} and will end after {eventEndAfter} occurrences
                </p>
              )}

            {eventOccurence === "No end date" &&
              eventDay &&
              reStartTime &&
              eventPattern &&
              eventOccurence === "No end date" && (
                <p className="mt-3">
                  Events starts on {eventDay} at {reStartTime}. It will occur{" "}
                  {eventPattern} with no end date{" "}
                </p>
              )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="ewfwwwefh" onClick={handleClose2}>
            Cancel
          </button>
          <button className="ewfwwwwwefh22" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
