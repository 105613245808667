import "./singlecourselessons.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";


import Table from "react-bootstrap/Table";
const SingleCourseLessons = ({
  lessons,
  setPreview,
  showModal,
  setShowModal,
}) => (
  <Container fluid>
    <Row>
      <div className="col lesson-list" style={{ paddingTop: "3rem" }}>
        <p style={{ fontSize: "100%", fontWeight: "600" }}>
          {lessons && <h4>{lessons.length} Lessons</h4>}
        </p>

        <Table responsive hover style={{ backgroundColor: "#f8f8f8" }}>
          {lessons.map((item, index) => (
            <tbody>
              <tr className="trdata">
                <td>{index + 1}</td>
                <td>{item.title}</td>

                <td>
                  {item.video && item.free_preview && (
                    <span
                      onClick={() => {
                        setPreview(item?.video?.Location);
                        setShowModal(!showModal);
                      }}
                      className="pointer text-primary zxz"
                    >
                      Preview
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
    </Row>
  </Container>
);

export default SingleCourseLessons;
