import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Container, Col, Row, Form } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import "./knowledgebase.css";
import { v4 as uuidv4 } from "uuid";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { useAppContext } from "../../../context/appContext";
import { faSpaghettiMonsterFlying } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditKnowledgeBase from "../../../components/KnowlegdeBase/EditKnowledgeBase";
import CreateCategory from "../../../components/KnowlegdeBase/CreateCategory";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import DeleteKnowledge from "../../../components/KnowlegdeBase/DeleteKnowledge";
import DeleteSingleKnowledge from "../../../components/KnowlegdeBase/DeleteSingleKnowledge";
const KnowledgeBase = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser } = useAppContext();
  const [blocks, setBlocks] = useState([
    { id: "1", type: "text", content: "" },
  ]);
  const [title, setTitle] = useState("");

  const [category, setCategory] = useState("");
  const [accessView, setAccessView] = useState("");
  const handleClose1 = () => setShow1(false);
  const [show1, setShow1] = useState(false);
  const [show12, setShow12] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [show2, setShow2] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resourceData, setResourceData] = useState([]);

  // const handleShow1 = (e) => {
  //   e.preventDefault();
  //   setShow1(true);
  //   addBlock("text", -1);
  // };

  const handleShow1xxx = (e) => {
    e.preventDefault();
    setShow12(true);
  };

  useEffect(() => {
    loadKnowledgeBase();
  }, []);

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [values, setValues] = useState({
    loading: false,
  });
  const maxSizeInBytes = 1000000; // 1MB

  const addBlock = (type, index) => {
    const newBlock = {
      id: uuidv4(),
      type,
      content: "",
      imageUrl: type === "image" ? {} : undefined, // Include imageUrl only for image blocks, initialized with default structure
    };

    const updatedBlocks =
      index >= 0
        ? [...blocks.slice(0, index + 1), newBlock, ...blocks.slice(index + 1)]
        : [...blocks, newBlock];

    setBlocks(updatedBlocks);

    // if (type === "image") {
    //   // Optionally trigger file upload or related workflows for new image blocks
    // }
  };

  const handleImage = async (e, blockId) => {
    let file = e.target.files[0];
    if (!file) return;

    // Immediately set preview URL
    const previewUrl = URL.createObjectURL(file);
    updateBlockContent(blockId, previewUrl, "image", {});

    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });

    Resizer.imageFileResizer(file, 700, 500, "JPEG", 100, 0, async (uri) => {
      try {
        if (uri.length > maxSizeInBytes) {
          const newQuality = Math.floor((maxSizeInBytes / uri.length) * 100);
          Resizer.imageFileResizer(
            file,
            700,
            500,
            "JPEG",
            newQuality,
            0,
            (newUri) => {
              uploadImage(newUri, blockId);
            }
          );
        } else {
          uploadImage(uri, blockId);
        }
      } catch (err) {
        console.error(err);
        setValues({ ...values, loading: false });
      }
    });
  };

  const uploadImage = async (uri, blockId) => {
    try {
      let { data } = await authFetch.post("/knowledgebase/upload-image", {
        image: uri,
      });
      // Pass the entire data object to updateBlockContent
      updateBlockContent(blockId, "", "image", data);
      setValues({ ...values, loading: false });
    } catch (err) {
      console.error(err);
      setValues({ ...values, loading: false });
    }
  };

  // const updateBlockContent = (id, content) => {
  //   const updatedBlocks = blocks.map((block) =>
  //     block.id === id ? { ...block, content } : block
  //   );
  //   setBlocks(updatedBlocks);
  // };

  const updateBlockContent = (id, content, type, imageUrl = {}) => {
    const updatedBlocks = blocks.map((block) => {
      if (block.id === id) {
        if (type === "image") {
          // Ensure that imageUrl is an object and merges properly
          return {
            ...block,
            content, // this could be the temporary preview URL or an empty string
            imageUrl: { ...block.imageUrl, ...imageUrl }, // merge with existing data
          };
        }
        // For non-image blocks, update only the content
        return { ...block, content };
      }
      return block;
    });
    setBlocks(updatedBlocks);
  };

  const deleteBlock = (id) => {
    const updatedBlocks = blocks.filter((block) => block.id !== id);
    setBlocks(updatedBlocks);
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [titleSection, setTitleSection] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const validateData = () => {
    if (!title.trim()) {
      setToastMessage("Title cannot be empty.");
      setShowToast(true);
      return false;
    }
    if (blocks.length === 0) {
      setToastMessage("Please add at least one content block.");
      setShowToast(true);
      return false;
    }
    return true;
  };

  const submitToBackend = async (e) => {
    e.preventDefault();

    if (!validateData()) {
      return;
    }

    setIsLoading(true);

    try {
      const data = { title, blocks };

      // console.log("Data to be submitted:", data);
      const response = await authFetch.post(
        `/knowledgebase/new/${category}`,
        data
      );
      if (response.status === 200 || response.status === 201) {
        setToastMessage("Data submitted successfully");
        setTitle("");
        setTitleSection("");
        setCategory("");
        setAccessView("");
        setBlocks([]);
        loadKnowledgeBase();
        handleClose1();
        toast(
          <CustomToastContent
            title={"Success"}
            message={"Data submitted successfully"}
          />
        );
      }
    } catch (error) {
      toast(
        <CustomToastContent title={"Error"} message={"Error submitting data"} />
      );
    } finally {
      setIsLoading(false);
      setShowToast(true);
    }
  };

  const loadKnowledgeBase = async () => {
    try {
      setLoading(true); // Set loading state to true before fetching data
      const response = await authFetch.get("/knowledgebase");

      if (response.status === 200) {
        setResourceData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the 404 error specifically
        console.warn("No knowledge base items found.");
        setResourceData([]); // Set to empty array if 404 occurs
      } else {
        console.error("Error loading course resources:", error);
        setError("Failed to load resources. Please try again later.");
      }
    } finally {
      setLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };

  const [show5, setShow5] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState(null);

  const deleteKnowledgeFromView = async (e, id) => {
    e.preventDefault();
    setShow5(true);
    setItemsToDelete(id);

    // console.log("Deleting knowledge with ID:", id);
    // return;
    // try {
    //   const response = await authFetch.delete(`/knowledgebase/${id}`);
    //   if (response.status === 200) {
    //     loadKnowledgeBase();
    //     toast(
    //       <CustomToastContent
    //         title={"Success"}
    //         message={"Knowledge deleted successfully"}
    //       />
    //     );
    //   }
    // } catch (error) {
    //   console.error("Error deleting knowledge:", error);
    // }
  };
  const [selectedData, setSelectedData] = useState(null);
  const editKnowledge = async (e, resource) => {
    setShow3(true);
    setSelectedData(resource);
  };

  const groupedByCategory = resourceData.reduce((acc, resource) => {
    const { category, titleSection, items } = resource;

    // Initialize the category array if it doesn't exist
    if (!acc[category]) {
      acc[category] = [];
    }

    // Process the items array, or default to an empty array if items is null/undefined
    const processedItems = (items || []).map((item) => ({
      id: item.id,
      title: item.title,
    }));

    // Push the processed resource object into the appropriate category array
    acc[category].push({
      titleSection,
      items: processedItems,
    });

    return acc;
  }, {});

  const [selectedCategory, setSelectedCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [titleSectionnew, setTitleSectionnew] = useState("");
  const [accessViewnew, setAccessViewnew] = useState("");
  const [newData, setNewData] = useState(null);

  const editCategoryName = async (e, category) => {
    e.preventDefault();
    setShow2(true);
    setNewCategory(category);
    setSelectedCategory(category);
    try {
      const response = await authFetch.get(
        `/knowledgebase/newdata/${category}`
      );

      setNewData(response.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    if (newData) {
      setNewCategory(newData.category);
      setTitleSectionnew(newData.titleSection);
      setAccessViewnew(newData.accessView);
    }
  }, [newData]);
  const submitTitleChange = async () => {
    try {
      const data = {
        newCategory,
        titleSectionnew,
        accessViewnew,
      };
      await authFetch.put(
        `/knowledgebase/updateknowledgecategory/${selectedCategory}`,
        data
      );
      loadKnowledgeBase();
      handleClose2();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };
  const deleteCategory = async (e, category) => {
    setShow4(true);
    setSelectedCategory(category);
  };

  const modules = {
    toolbar: [
      // Header formatting and font selection
      [
        { header: "1" },
        { header: "2" },
        { header: "3" },
        { header: "4" },
        { font: ["Open Sans", "serif", "monospace"] },
      ],
      // Font size selection
      [{ size: [] }],
      // Basic text formatting: bold, italic, underline, strikethrough, blockquote
      ["bold", "italic", "underline", "strike", "blockquote"],
      // List formatting: ordered list, bullet list, indentation
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // Links, images, and videos
      ["link", "image", "video"],
      // Text color and background color with specified color codes
      [
        {
          color: [
            "#243060",
            "#fe7064",
            "#fed970",
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
        {
          background: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
      ],
      // Text alignment options
      [{ align: [] }],
      // Clear formatting
      ["clean"],
    ],
  };

  const renderBlock = (block, index) => {
    switch (block.type) {
      case "text":
        return (
          <div key={block.id} className="mb-3">
            <ReactQuill
              value={block.content}
              onChange={(content) => updateBlockContent(block.id, content)}
              style={{ height: "300px", minHeight: "150px" }}
              modules={modules}
              className="react-quill-editor"
            />
            <button
              onClick={() => deleteBlock(block.id)}
              className="jiluil3"
              style={{ marginTop: "80px" }}
            >
              Delete Text
            </button>
          </div>
        );
      case "image":
        return (
          <div key={block.id} className="mb-3">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImage(e, block.id)}
            />
            <button onClick={() => deleteBlock(block.id)} className="jiluil">
              Delete Image
            </button>
          </div>
        );
      case "video":
        return (
          <div key={block.id} className="mb-3">
            <input
              type="file"
              accept="video/*"
              onChange={(e) =>
                updateBlockContent(
                  block.id,
                  URL.createObjectURL(e.target.files[0])
                )
              }
            />
            <button onClick={() => deleteBlock(block.id)} className="jiluil2">
              Delete Video
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const uploadContent = async (e, category) => {
    e.preventDefault();
    setCategory(category);
    addBlock("text", -1);
    setShow1(true);
  };

  return (
    <Container fluid>
      <Row className="courseheader course-header">
        <h1>Knowledge Base</h1>
      </Row>

      <Row className="sssssssecwef mt-5">
        <div className="bnmjo ">
          {/* <button className="wedweded" onClick={() => addBlock("text", -1)}>
            Upload Content
          </button> */}

          <button className="wedweded" onClick={handleShow1xxx}>
            Create Category
          </button>

          {/* <button className="wedweded" onClick={handleShow1}>
            Upload Content
          </button> */}
        </div>
        {/* {resourceData.length ? (
          <h5>
            {resourceData.length} record{resourceData.length > 1 ? "s" : ""}{" "}
            found
          </h5>
        ) : (
          ""
        )} */}
      </Row>
      <Row>
        {Object.keys(groupedByCategory).map((category) => (
          <Col key={category} md={6} lg={6} className="mt-3 mb-5 maincik">
            {/* Category Header with Actions */}
            <div
              className="maintextt flex flex-row gap-3"
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              {category}
              {/* Actions affecting all resources under this category */}
              <p
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  editCategoryName(e, category);
                }}
                className="clikknowledge222"
              >
                Edit Category Title
              </p>
              <p
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteCategory(e, category);
                }}
                className="clikknowledge222"
              >
                Delete Category
              </p>
              <p
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  uploadContent(e, category);
                }}
                className="clikknowledge222"
              >
                Upload Content
              </p>
            </div>

            {/* Resources under each category */}
            <div>
              {groupedByCategory[category].map((resource, resourceIndex) => {
                // Ensure resource.items is defined and has elements before accessing the first item
                const firstItem =
                  resource.items && resource.items.length > 0
                    ? resource.items[0]
                    : null;

                return (
                  <div
                    key={firstItem ? firstItem.id : resourceIndex} // Fallback to resourceIndex if no items exist
                    className="course-card"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    {/* Resource Title Section */}
                    {/* <div>
                      <Link
                        to={
                          firstItem
                            ? `/auth/knowledgebases/${firstItem.id}`
                            : "#"
                        }
                        style={{
                          textDecoration: "none",
                          cursor: firstItem ? "pointer" : "default",
                        }}
                      >
                        <p className="maintitle text-bold">
                          {resource.titleSection || "Untitled Resource"}
                        </p>
                      </Link>
                    </div> */}
                    <p className="maintitle text-bold">
                      {resource.titleSection || "Untitled Resource"}
                    </p>

                    {/* Blocks within the resource */}
                    {Array.isArray(resource.items) &&
                    resource.items.length > 0 ? (
                      resource.items.map((block, index) => {
                        return (
                          <div
                            key={index}
                            style={{ marginTop: "10px" }}
                            className="flex flex-row gap-3"
                          >
                            {/* Block Title */}

                            <Link
                              to={
                                firstItem
                                  ? `/auth/knowledgebases/${block.id}`
                                  : "#"
                              }
                              style={{
                                textDecoration: "none",
                                cursor: firstItem ? "pointer" : "default",
                              }}
                            >
                              <p className="maintitle">
                                {block.title || "Untitled Block"}
                              </p>
                            </Link>

                            {/* <p className="maintitle">
                              {block.title || "Untitled Block"}
                            </p> */}

                            {/* Edit and Delete Actions for the Block */}
                            <p
                              onClick={(e) => {
                                e.preventDefault(); // Prevent link navigation
                                editKnowledge(e, block.id);
                              }}
                              className="clikknowledge"
                              style={{ cursor: "pointer" }}
                            >
                              Edit
                            </p>
                            <p
                              onClick={(e) => {
                                e.preventDefault(); // Prevent link navigation
                                deleteKnowledgeFromView(e, block.id);
                              }}
                              className="clikknowledge"
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              Delete
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <p>No blocks available</p>
                    )}
                  </div>
                );
              })}
            </div>
          </Col>
        ))}
      </Row>

      <CreateCategory
        setShow={setShow12}
        show={show12}
        reload={loadKnowledgeBase}
      />
      <Modal show={show1} size="xl" onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Create Knowledge Base - {category}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Enter Title Here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name="className"
              style={{ fontSize: "1em" }}
            />
          </Form.Group>

          {blocks.map((block, index) => (
            <>
              <div key={block.id} className="mb-3 mt-3 flex gap-3 flex-col">
                {renderBlock(block, index)}
                <button
                  onClick={() => addBlock("text", index)}
                  className="jiluil3"
                >
                  Add Text Below
                </button>
                <button
                  onClick={() => addBlock("image", index)}
                  className="jiluil"
                >
                  Add Image Below
                </button>
                <button
                  onClick={() => addBlock("video", index)}
                  className="jiluil2"
                >
                  Add Video Below
                </button>
              </div>
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose1}>
            Cancel
          </button>
          <button
            className="jiluil3"
            onClick={submitToBackend}
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit to Backend"}
          </button>
          <Toast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "600", fontSize: "100%" }}>
            Edit KnowledgeBase Title
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "100%" }}>
          {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
          {/* <Form.Control
              type="text"
              placeholder="Enter Category Here"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              name="className"
              style={{ fontSize: "100%" }}
            />
          </Form.Group> */}

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setNewCategory(e.target.value)}
              value={newCategory}
            >
              <option>Choose Category</option>
              <option value="General">General</option>
              <option value="Getting Started">Getting Started</option>
              <option value="People">People</option>
              <option value="Events">Events</option>
              <option value="Ministry">Ministry</option>
              <option value="Fellowship">Fellowship</option>
              <option value="Service Planner">Service Planner</option>
              <option value="Groups">Groups</option>
              <option value="Announcements">Announcements</option>
              <option value="Forms">Forms</option>
              <option value="Live Stream">Live Stream</option>
              <option value="Learning Center">Learning Center</option>
              <option value="Church Giving">Church Giving</option>
              <option value="Church Accounting">Church Accounting</option>
              <option value="Children">Children</option>
              <option value="Follow Up">Follow Up</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Enter Title Section Here"
              value={titleSectionnew}
              onChange={(e) => setTitleSectionnew(e.target.value)}
              name="className"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setAccessViewnew(e.target.value)}
              value={accessViewnew}
            >
              <option>Choose who can view</option>
              <option value="paid">Paid Users</option>
              <option value="general">General Users</option>
              <option value="both">Both</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="wewecwe" onClick={handleClose2}>
            Close
          </button>
          <button className="wewecwe" onClick={submitTitleChange}>
            Updatex
          </button>
        </Modal.Footer>
      </Modal>

      <EditKnowledgeBase
        show={show3}
        setShow={setShow3}
        selectedData={selectedData}
        loadKnowledgeBase={loadKnowledgeBase}
      />

      <DeleteKnowledge
        show={show4}
        setShow={setShow4}
        selectedValue={selectedCategory}
        loadKnowledgeBase={loadKnowledgeBase}
      />

      <DeleteSingleKnowledge
        show={show5}
        setShow={setShow5}
        selectedValue={itemsToDelete}
        loadKnowledgeBase={loadKnowledgeBase}
      />

      {/* {blocks.length > 0 && (
        <button onClick={submitToBackend}>Submit to Backend</button>
      )} */}
    </Container>
  );
};

export default KnowledgeBase;
