import React from "react";
import { useAppContext } from "../../context/appContext";
import {
  FaSuitcaseRolling,
  FaCalendarCheck,
  FaBug,
  FaRuler,
  FaRunning,
} from "react-icons/fa";
import StatsItem from "../StatsItem/StatsItem";
import "./statscontainer.css";
import { icon } from "@fortawesome/fontawesome-svg-core";

const StatsContainer = () => {
  const { stats } = useAppContext();

  const defaultStats = [
    {
      title: "Members",
      count: stats.Members || 0,
      icon: <FaSuitcaseRolling />,
      color: "#FED970",
      bcg: "#FCEFC7",
    },
    {
      title: "Visitors",
      count: stats.Visitors || 0,
      icon: <FaCalendarCheck />,
      color: "#acacad",
      bcg: "#e0e8f9",
    },
    {
      title: "Inactive",
      count: stats.Inactive || 0,
      icon: <FaBug />,
      color: "#FE7064",
      bcg: "#ffeeee",
    },
    {
      title: "Membership In Progress",
      count: stats.MembershipInProgress || 0,
      icon: <FaRunning />,
      color: "#C2C9FF",
      bcg: "#C2C9FF",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: "1.5rem",
        textAlign: "center",
        paddingTop: "3rem",
        paddingBottom: "2rem",
      }}
    >
      {defaultStats.map((item, index) => {
        return <StatsItem key={index} {...item} />;
      })}
    </div>
  );
};

export default StatsContainer;
