import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./register.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MemberAccountReady = () => {
  const navigate = useNavigate();
  const loginHandle = () => {
    navigate("/login");
  };
  return (
    <>
      <Header />
      <Container fluid className="passwordset">
        <Row>
          <p className="passwordresetheader">Your account is ready!</p>
          <p>
            Stay connected with your community, learn, grow, and give with ease
            using Churchease. Welcome to the family!
          </p>
        </Row>
        <Row className="flex justify-center w-45">
          <Button
            type="button"
            className="buttonner mt-5"
            onClick={loginHandle}
          >
            Back to Login
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default MemberAccountReady;
