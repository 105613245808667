import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faPeopleArrows,
  faCalendar,
  faLayerGroup /* other icons you need */,
  faPray,
  faMicrophone,
  faMoneyBill,
  faLaptop,
  faTableTennis,
  faTh,
  faThList,
  faFont,
  faPassport,
  faVideo,
  faChartPie,
  faFlagCheckered,
  faRunning,
  faLevelUpAlt,
  faChild,
  faMoneyBillWave,
  faBuilding,
  faPlusSquare,
  faComputer,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import "./banner.css";

const Banner = ({ speed = 90000 }) => {
  const icons = [
    { id: "People", icon: faUserAlt },
    { id: "Ministry", icon: faPeopleArrows },
    { id: "Event", icon: faCalendar },
    { id: "Dynamic Group", icon: faLayerGroup },
    { id: "Service Planner", icon: faPeopleArrows },
    { id: "Prayer", icon: faPray },
    { id: "FaithMate Chat", icon: faMicrophone },
    { id: "Giving", icon: faMoneyBill },
    { id: "Learning Center", icon: faLaptop },
    { id: "Testimonies", icon: faTableTennis },
    { id: "Attendance", icon: faThList },
    { id: "Dynamic Forms", icon: faFont },
    { id: "Volunteer Center", icon: faPassport },
    { id: "Livestream", icon: faVideo },
    { id: "Growth Planner", icon: faPlusSquare },
    { id: "Digital Library", icon: faComputer },
    { id: "Budget and Expense", icon: faMoneyBillWave },
    { id: "Children", icon: faChild },
    { id: "Account Management", icon: faLevelUpAlt },
    { id: "Follow Up", icon: faRunning },
    { id: "2-Way Communication", icon: faMessage },
    { id: "Family Check In", icon: faFlagCheckered },

    // add other icons as needed
  ];

  const allIcons = [...icons, ...icons]; // Adjust based on your needs

  return (
    <div className="innerxxx">
      <div className="wrapperxxx">
        <div className="sectionxxx" style={{ "--speed": `${speed}ms` }}>
          {allIcons.map(({ id, icon }, index) => (
            <div className="icon centerirem" key={`${id}-${index}`}>
              <FontAwesomeIcon icon={icon} className="homeic" />
              <p className="scrot" style={{ width: "100%" }}>
                {id}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Banner };
