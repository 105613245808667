import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const AddAudio = ({ show, setShow, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [audioName, setAudioName] = useState("");
  const [audioDescription, setAudioDescription] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Audio");
  const [uploadLink, setUploadLink] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [error, setError] = useState("");

  const handleAudioUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setAudioFile(file);
      if (!file) {
        //toast("No file selected");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const audioData = new FormData();
      audioData.append("audio", file);
      // Ensure 'video' matches the field name expected by Multer

      // Sending video as form data to the backend
      try {
        const { data } = await authFetch.post(
          "/learning/audio/upload-audio",
          audioData,
          {
            headers: {
              // Don't set Content-Type header, the browser will set it with the correct boundary
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );
        setAudioFile(data);
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleAudioRemove = async () => {
    try {
      setUploading(true);

      await authFetch.post(`/learning/audio/video-audio`, audioFile);
      setAudioFile(null);
      setProgress(0);

      setUploading(false);
      setUploadButtonText("Upload another audio");
    } catch (err) {
      setUploading(false);
    }
  };

  const handleAudio = async (e) => {
    e.preventDefault();

    const [trimmedAudio, trimmedAudioDescription] = trimInputs([
      audioName,
      audioDescription,
    ]);

    setError("");

    if (
      !validateNotEmpty(trimmedAudio) ||
      !validateNotEmpty(trimmedAudioDescription) ||
      (!uploadLink && !audioFile)
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please provide all required information to proceed."
        />
      );

      return;
    }

    // Validate tag length
    if (!validateLength(trimmedAudio, NAME_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Audio Name cannot exceed ${NAME_LENGTH_LIMIT} words.`}
        />
      );

      return;
    }

    // Validate description length
    if (!validateLength(trimmedAudioDescription, DESCRIPTION_LENGTH_LIMIT)) {
      toast(
        <CustomToastContent
          title="Error"
          message={`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} words.`}
        />
      );
      return;
    }

    const audioData = {
      audioName: trimmedAudio,
      audioDescription: trimmedAudioDescription,
      audioLink: audioLink,
      churchName: user.churchName,
      audioFile: audioFile,
    };

    try {
      const response = await authFetch.post("/learning/audio", audioData);
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="Audio uploaded successfully."
          />
        );
        handleReload();
        setAudioName("");
        setAudioDescription("");
        setAudioFile(null);
        setAudioLink("");
        setProgress(0);
        setUploading(false);
        setUploadButtonText("Upload another audio");
        setShow(false);
      } else {
        toast(
          <CustomToastContent
            title="Error"
            message="Failed to upload audio. Please try again."
          />
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Audio Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Audio Name <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={audioName}
            onChange={(e) => setAudioName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Audio Description <span className="myast">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="description"
            onChange={(e) => setAudioDescription(e.target.value)}
            value={audioDescription}
            className="lpmw"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Check
            type="checkbox"
            label="Use link instead of uploading audio"
            checked={uploadLink}
            onChange={(e) => setUploadLink(e.target.checked)}
          />
        </Form.Group>

        {uploadLink ? (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Audio Link</Form.Label>
            <Form.Control
              type="text"
              value={audioLink}
              onChange={(e) => setAudioLink(e.target.value)}
              placeholder="https://example.com/audio.mp3"
              required
            />
          </Form.Group>
        ) : (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Upload Audio <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleAudioUpload}
              accept="audio/*"
              className="lpm"
            />
            <p style={{ color: "red" }}>
              <span className="myast text-bold">Note:</span> Audio must be in
              MP3 format.
            </p>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleAudio}>
          Save Audio
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAudio;
