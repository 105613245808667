import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

import axios from "axios";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";

const PostAnnouncement = ({ show, setShow, handleReload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [annoucementImage, setAnnouncementImage] = useState("");
  const { user, alertType, logoutUser } = useAppContext();
  const [annoucementName, setAnnouncementName] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const [announcementFile, setAnnouncementFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState(
    "Upload Announcement"
  );
  const [uploadLink, setUploadLink] = useState(false);
  const [announcementLink, setAnnouncementLink] = useState("");
  const [error, setError] = useState("");

  const handleAnnoucementUpload = async (e) => {
    try {
      const file = e.target.files[0];
      setAnnouncementFile(file);
      if (!file) {
        //toast("No file selected");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const announcementData = new FormData();
      announcementData.append("video", file);
      // Ensure 'video' matches the field name expected by Multer

      // Sending video as form data to the backend
      try {
        const { data } = await authFetch.post(
          "/announcement/video/upload-announcement",
          announcementData,
          {
            headers: {
              // Don't set Content-Type header, the browser will set it with the correct boundary
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setAnnouncementFile(data);
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleAudioRemove = async () => {
    try {
      setUploading(true);

      await authFetch.post(`/learning/audio/video-audio`, announcementFile);
      setAnnouncementFile(null);
      setProgress(0);

      setUploading(false);
      setUploadButtonText("Upload another audio");
    } catch (err) {
      setUploading(false);
    }
  };

  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];

      setAnnouncementImage(file);
      if (!file) {
        //toast("No file selected");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const announcementDatax = new FormData();
      announcementDatax.append("image", file);
      // Ensure 'video' matches the field name expected by Multer

      // Sending video as form data to the backend
      try {
        const { data } = await authFetch.post(
          "/announcement/image/upload-image",
          announcementDatax,
          {
            headers: {
              // Don't set Content-Type header, the browser will set it with the correct boundary
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setAnnouncementImage(data);
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleImageRemove = async () => {
    try {
      setUploading(true);

      await authFetch.post(`/learning/audio/video-audio`, announcementFile);
      setAnnouncementImage(null);
      setProgress(0);

      setUploading(false);
      setUploadButtonText("Upload another audio");
    } catch (err) {
      setUploading(false);
    }
  };

  const handleAnnouncement = async (e) => {
    e.preventDefault();

    const [trimmedAnnouncementName, trimmedAnnouncementDescription] =
      trimInputs([annoucementName, announcementDescription]);

    setError("");

    if (!validateNotEmpty(trimmedAnnouncementName)) {
      setError("Please provide all required information to proceed.");
      return;
    }

    // Validate tag length
    if (!validateLength(trimmedAnnouncementName, NAME_LENGTH_LIMIT)) {
      setError(`Audio Name cannot exceed ${NAME_LENGTH_LIMIT} words.`);
      return;
    }

    // Validate description length
    if (
      !validateLength(trimmedAnnouncementDescription, DESCRIPTION_LENGTH_LIMIT)
    ) {
      setError(`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} words.`);
      return;
    }

    const announcementData = {
      annoucementName: trimmedAnnouncementName,
      announcementDescription: trimmedAnnouncementDescription,
      announcementLink: announcementLink,
      churchName: user.churchName,
      announcementFile: announcementFile,
      annoucementImage: annoucementImage,
    };

    try {
      const response = await authFetch.post("/announcement", announcementData);

      if (response.status === 201 || response.status === 200) {
        handleReload();

        setAnnouncementName("");
        setAnnouncementDescription("");
        setAnnouncementFile(null);
        setAnnouncementLink("");
        setAnnouncementImage("");
        setProgress(0);
        setUploading(false);
        setUploadButtonText("Upload another audio");
        setShow(false);
        toast(
          <CustomToastContent title="Success" message="Announcement Created!" />
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Announcement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Announcement Header</Form.Label>
          <Form.Control
            type="text"
            value={annoucementName}
            onChange={(e) => setAnnouncementName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Announcement</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="announcementDescription"
            onChange={(e) => setAnnouncementDescription(e.target.value)}
            value={announcementDescription}
            className="lpmw"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Announcement Cover(Image)</Form.Label>
          <Form.Control
            type="file"
            name="image"
            onChange={handleImageUpload}
            accept="image/*"
            className="lpm"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Upload Announcement (Video)</Form.Label>
          <Form.Control
            type="file"
            onChange={handleAnnoucementUpload}
            accept="video/*"
            className="lpm"
          />
          <p className="smalltext">
            upload video files of any format. File size 10mb maximum
          </p>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleAnnouncement}>
          Save as draft
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PostAnnouncement;
