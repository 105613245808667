import React from "react";
import { Modal, Form, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const CreateCategory = ({ show, setShow, reload }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { logoutUser } = useAppContext();
  const handleClose1 = () => {
    setShow(false);
  };

  const [category, setCategory] = React.useState("");
  const [titleSection, setTitleSection] = React.useState("");

  const [accessView, setAccessView] = React.useState("");

  const submitToBackend = async (e) => {
    e.preventDefault();
    if (!category || !titleSection || !accessView) {
      toast(
        <CustomToastContent
          title={"Error"}
          message={"All fields are required"}
        />
      );
      return;
    }

    try {
      const data = {
        category,
        titleSection,
        accessView,
      };

      const response = await authFetch.post("/knowledgebase", data);
      if (response.status === 201 || response.status === 200) {
        reload();
        toast(
          <CustomToastContent
            title={"Success"}
            message={"Category Created Successfully"}
          />
        );
        setShow(false);
        setCategory("");
        setTitleSection("");
        setAccessView("");
      }
    } catch (error) {
      console.error("Error creating category: ", error);
      toast(
        <CustomToastContent
          title={"Error"}
          message={"Error creating category"}
        />
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose1}>
      <Modal.Header closeButton>
        <Modal.Title>Create Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            style={{ fontSize: "1em" }}
          >
            <option>Choose Category</option>
            <option value="General">General</option>
            <option value="Getting Started">Getting Started</option>
            <option value="People">People</option>
            <option value="Events">Events</option>
            <option value="Ministry">Ministry</option>
            <option value="Fellowship">Fellowship</option>
            <option value="Service Planner">Service Planner</option>
            <option value="Groups">Groups</option>
            <option value="Announcements">Announcements</option>
            <option value="Forms">Forms</option>
            <option value="Live Stream">Live Stream</option>
            <option value="Learning Center">Learning Center</option>
            <option value="Church Giving">Church Giving</option>
            <option value="Church Accounting">Church Accounting</option>
            <option value="Children">Children</option>
            <option value="Security">Security</option>
            <option value="Follow-Up">Follow-Up</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="text"
            placeholder="Enter Title Section Here"
            value={titleSection}
            onChange={(e) => setTitleSection(e.target.value)}
            name="className"
            style={{ fontSize: "1em" }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Select
            aria-label="Default select example"
            style={{ fontSize: "1em" }}
            onChange={(e) => setAccessView(e.target.value)}
            value={accessView}
          >
            <option>Choose who can view</option>
            <option value="paid">Paid Users</option>
            <option value="general">General Users</option>
            <option value="both">Both</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose1}>
          Cancel
        </button>
        <button className="jiluil" onClick={submitToBackend}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCategory;
