import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaBuilding, FaCentercode, FaMoneyBill } from "react-icons/fa";
import q1 from "../../assets/newi/q1.png";
import q2 from "../../assets/newi/q2.png";
import MoreProduct from "../../components/Product/MoreProduct";

const ProductCenter = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#744A33" }}>
            <FaBuilding size={100} />
            <h1>Message Center</h1>
            <p className="headpp">
              Improve administrative efficiency with our Message Center.
              Configure automated reports for strategic planning and
              decision-making, ensuring church leaders are well-informed and
              equipped to manage effectively.
            </p>
            <button className="headerintro-button" onClick={handleDemoClick}>
              Book a demo
            </button>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for access management
            </p>
            <p className="product13">
              Your robust tool to control and monitor access within your
              church's system, ensuring data security and compliance with
              standards.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={q1} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">AUTOMATED REPORTING</p>
              <p className="proddz1">
                Set up automated reports to be sent to church admins at
                specified dates, ensuring timely information flow.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">DECISION SUPPORT</p>
              <p className="proddz1">
                Provide data and analytics to support decision making, enhancing
                planning and operational effectiveness.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">CUSTOMIZABLE ALERTS</p>
              <p className="proddz1">
                Customize alerts for different scenarios to keep the
                administration informed about critical events or milestones.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">SCHEDULED DISPATCHES</p>
              <p className="proddz1">
                Schedule dispatches of communications based on calendar events
                or predefined criteria.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">INTEGRATED COMMUNICATIONS</p>
              <p className="proddz1">
                Integrate with existing email and communication platforms to
                streamline message delivery across various channels.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">INSIGHTS AND TRENDS</p>
              <p className="proddz1">
                Analyze communication patterns and trends to continuously refine
                and improve messaging strategies.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={q2} alt="Giving" style={{ fontSize: "50%" }} />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#744A33" />
      <Footer />
    </>
  );
};

export default ProductCenter;
