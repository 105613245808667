import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import { getMonth } from "../../../utils";
import "./calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function SmallCalendar() {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  const { monthIndex, setSmallCalendarMonth, setDaySelected, daySelected } =
    useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }
  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected && daySelected.format(format);
    if (nowDay === currDay) {
      return "bg-primary rounded-circle text-light";
    } else if (currDay === slcDay) {
      return "bg-warning rounded-circle text-primary fw-bold";
    } else {
      return "";
    }
  }
  return (
    <Container fluid className="smallcalendar">
      <Row className="smallcalhead">
        <Col md={8} sm={8}>
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
        </Col>
        <Col>
          <button onClick={handlePrevMonth} className="headprev2">
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button onClick={handleNextMonth} className="headprev2">
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </Col>
      </Row>

      <Row>
        <div className="mt-2">
          <div className="smallcalg">
            {currentMonth[0].map((day, i) => (
              <span key={i} className="smallcal">
                {day.format("dd").charAt(0)}
              </span>
            ))}
            {currentMonth.map((row, i) => (
              <React.Fragment key={i}>
                {row.map((day, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      setSmallCalendarMonth(currentMonthIdx);
                      setDaySelected(day);
                    }}
                    className={`sspan ${getDayClass(day)}`}
                  >
                    <span className="text-sm">{day.format("D")}</span>
                  </button>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Row>
    </Container>
  );
}
