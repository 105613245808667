import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useAppContext } from "../../../context/appContext";
import StatsContainer from "../../../components/StatsContainer/StatsContainer";
import ChartsContainer from "../../../components/ChartsContainer/ChartsContainer";
import BarChartComponent from "../../../components/BarChart/BarChart2";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { currencyFormatter } from "../../../utils/helpers";
import "./dashboard.css";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import GroupCampusPieChart from "../../../components/PieChartDashboard/GroupCampusPieChart";
import OnboardingModal from "../../../components/OnboardTour/OnboardTour";
import FreeOnboarding from "../../../components/OnboardTour/FreeOnboarding";
import { Bar } from "recharts";
import PieChartAccountStatus from "../../../components/BarChart/PieChartAccountStatus";
import PieChartMaritalStatus from "../../../components/BarChart/PieChartMaritalStatus";

const Dashboard = () => {
  const {
    showStats,
    monthlyNewMembers,
    getEvents,
    events,
    memberbyMonth,
    getRecentMembers,
    RecentMembers,
    logoutUser,
    user,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [prayc, setPrayc] = useState(0);
  const [givingByMonth, setGivingByMonth] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [isFree3, setIsFree3] = useState(false);
  const [isFree2, setIsFree2] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    // Check if user's role includes any of the specified roles
    const roles = ["Super Admin", "App Admin", "Admin"];
    const hasRequiredRole = roles.some((role) =>
      user?.role[0]?.roleName?.includes(role)
    );

    if (hasRequiredRole) {
      setSuperAdmin(true);
    }
  }, [user.role]);

  const prayerCount = async () => {
    try {
      const response = await authFetch.get("/prayers/prayercount");
      setPrayc(response.data);
    } catch (error) {
      console.error("Error fetching prayer count:", error);
    }
  };

  const givingByTotalMonth = async () => {
    try {
      const response = await authFetch.get("/giving/give/totalgivingbymonth/");

      setGivingByMonth(response.data.totalAmount);
    } catch (error) {
      console.error("Error fetching prayer count:", error);
    }
  };

  useEffect(() => {
    getCelebrants();
  }, []);

  const [celebrants, setCelebrants] = useState({
    anniversaryCelebrants: [],
    birthdayCelebrants: [],
  });

  const getCelebrants = async () => {
    try {
      const { data } = await authFetch.get("/members/celebrants");

      if (data) {
        setCelebrants(data);
      }
    } catch (error) {
      console.error("Error fetching celebrants:", error);
    }
  };
  const [campus, setCampus] = useState({});
  const getCampus = async () => {
    try {
      const response = await authFetch.get("/members/getgroupcampus");

      if (response.status === 200 || response.status === 201) {
        setCampus(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching campus:", error);
    }
  };

  useEffect(() => {
    prayerCount();
  }, []);

  useEffect(() => {
    getCampus();
  }, []);

  useEffect(() => {
    givingByTotalMonth();
  }, []);

  useEffect(() => {
    showStats();
    getEvents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    memberbyMonth();
    RecentMembers();
  }, []);

  const dataGreaterThanToday = events?.filter((item) => {
    return moment(`${item.eventDay}`).isSameOrAfter(
      moment().startOf("day"),
      "day"
    );
  });

  const sortedData = dataGreaterThanToday?.sort((a, b) => {
    return moment(a.eventDay).diff(moment(b.eventDay));
  });

  const gotoPrayer = () => {
    navigate("/auth/prayerwall");
  };

  useEffect(() => {
    const fetchChurchSub = async () => {
      const churchdomain = user?.churchDomain;

      if (!churchdomain) {
        console.error("Church domain is missing");
        return;
      }

      try {
        const response = await authFetch.get(
          `/members/churchsub/${churchdomain}`
        );
        const churchAccount = response.data;

        // Redirect to pricing list if subscription is empty or not present
        if (
          churchAccount.memberShip === "Paid" &&
          (!churchAccount?.subscription ||
            churchAccount?.subscription?.length === 0)
        ) {
          navigate("/auth/paymentpage");
        } else {
          // Assuming we're checking the first subscription for this example
          const firstSubscription = churchAccount.subscription[0];

          if (location.pathname !== "/auth/paymentpage") {
            if (firstSubscription.status === "canceled") {
              navigate("/auth/paymentpage");
            } else {
              const planNickname = firstSubscription.plan.nickname;
              setIsFree(planNickname === "Free");
              setIsFree3(
                ["Free", "Basic", "Standard", "Demo"].includes(planNickname)
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching church subscription:", error);
      }
    };

    fetchChurchSub();

    // Existing logic for user subscription...
  }, [user, navigate, location.pathname]);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      //  console.log(user._id);
      try {
        const response = await authFetch.get(
          `/members/getthisuser/${user._id}`
        );
        const userData = response.data; // Use a different name to avoid shadowing
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user._id]);

  const [isModalOpen, setIsModalOpen] = useState(true);
  //console.log(isFree3);
  return (
    <>
      <Container fluid style={{ paddingBottom: "6rem" }}>
        {userData &&
          superAdmin &&
          userData.memberShip === "Paid" &&
          userData.completedTour === false && (
            <OnboardingModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
            />
          )}

        {userData &&
          userData.memberShip === "Free" &&
          userData.completedFreeTour === false && (
            <FreeOnboarding
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
            />
          )}
        <Row className="courseheader"></Row>
        <Row>
          <StatsContainer />
        </Row>

        <Row>
          <Col sm={4} className="colsm">
            <div className="dixic">
              <div className="cardTit">Gender</div>

              <BarChartComponent data={monthlyNewMembers} />
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="dixic">
              <div className="cardTit">Account Status</div>

              <PieChartAccountStatus />
            </div>
          </Col>
          <Col sm={4} className="colsm">
            <div className="dixic">
              <div className="cardTit">Marital Status</div>

              <PieChartMaritalStatus />
            </div>
          </Col>
        </Row>
        <Row
          className="flex justify-center flex-row"
          style={{
            paddingTop: "2rem",
          }}
        >
          <div className="dixic " style={{ width: "97%" }}>
            <div className="cardTit">Monthly new members</div>
            <ChartsContainer />
          </div>
        </Row>
        <Row className="dashRow">
          <Col sm={4} className="colsm">
            <div className="diic2">
              <div className="cardTit">Recently added members</div>
              <Table size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "#fff",
                      fontSize: "0.8em",
                    }}
                  >
                    {/* <th>#</th> */}
                    <th>NAME</th>
                    {/* <th>Last Name</th> */}
                    <th>DATE</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {getRecentMembers?.length &&
                    getRecentMembers?.map((item, index) => {
                      return (
                        <tr
                          style={{ fontSize: "0.8em", paddingBottom: "1rem" }}
                        >
                          <td>
                            {item?.mfirstName} {item?.mlastName}
                          </td>

                          <td>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            <div className="flex justify-center">
                              <Link to={"/auth/peopleview/" + item._id}>
                                <FaEye style={{ color: "#FE7064" }} />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody> */}

                <tbody>
                  {getRecentMembers?.length &&
                    getRecentMembers?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            paddingBottom: "1rem",
                            cursor: "pointer", // Change cursor to pointer on the row
                            fontSize: "1em",
                          }}
                          className="hover:bg-gray-100" // Apply hover effect
                        >
                          {/* First Name and Last Name */}
                          <td>
                            <Link
                              to={"/auth/peopleview/" + item._id}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }} // Remove underline from the link
                            >
                              {item?.mfirstName} {item?.mlastName}
                            </Link>
                          </td>

                          {/* Date Created */}
                          <td>
                            <Link
                              to={"/auth/peopleview/" + item._id}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }} // Remove underline from the link
                            >
                              {moment(item?.createdAt).format("MMMM DD")}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
          {isFree ? (
            <Col sm={4} className="colsm">
              <div className="diic2">
                <div className="cardTit">Unread prayer requests</div>
                <div
                  className="praydas"
                  style={{ color: "#FED970", fontSize: "1em" }}
                >
                  Not available in your current plan.
                </div>
                <div className="dasf">
                  <button className="kkkkewe disabled" onClick={gotoPrayer}>
                    View
                  </button>
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={4} className="colsm">
              <div className="diic2">
                <div className="cardTit">Unread prayer requests</div>
                <div className="praydas" style={{ color: "#FE7064" }}>
                  {prayc}
                </div>
                <div className="dasf">
                  <button className="kkkk1" onClick={gotoPrayer}>
                    View
                  </button>
                </div>
              </div>
            </Col>
          )}
          {isFree3 ? (
            <Col sm={4} className="colsm">
              <div className="diic2">
                <div className="cardTit">Total givings by month</div>
                <div
                  className="praydas"
                  style={{ color: "#FE7064", fontSize: "100%" }}
                >
                  Not available in your current plan.
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={4} className="colsm">
              <div className="diic2">
                <div className="cardTit">Total givings by month</div>
                <div className="praydas" style={{ color: "#FE7064" }}>
                  {currencyFormatter({
                    amount: givingByMonth,
                    currency: "usd",
                  })}
                </div>
              </div>
            </Col>
          )}
        </Row>

        <Row className="dashRow">
          <Col sm={7} md={7} className="colsm22">
            <div className="diic">
              <div className="cardTit">Today's celebration</div>
              <Table size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "#fff",
                      fontSize: "0.8em",
                    }}
                  >
                    {/* <th>#</th> */}
                    <th>NAME</th>
                    {/* <th>Last Name</th> */}
                    <th>DATE</th>
                    <th>CELEBRATION</th>
                    {/* <th>VIEW</th> */}
                  </tr>
                </thead>
                <tbody
                  style={{
                    borderWidth: "0",
                    borderStyle: "none",
                    paddingBottom: "5px",
                  }}
                >
                  {celebrants?.anniversaryCelebrants?.length > 0 ||
                  celebrants?.birthdayCelebrants?.length > 0 ? (
                    <>
                      {celebrants?.birthdayCelebrants?.map((item, index) => (
                        <tr key={`birthday-${index}`}>
                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {item.mfirstName} {item.mlastName}
                            </Link>
                          </td>

                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {moment(item.mBirthdayCont, "MMMM DD").format(
                                "MMMM DD"
                              )}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {celebrants.birthdayCelebrants ? "Birthday" : ""}
                            </Link>
                          </td>
                          {/* <td>
                            <div className="flex justify-center">
                              <Link to={`/auth/peopleview/${item._id}`}>
                                <FaEye style={{ color: "#FE7064" }} />
                              </Link>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                      {celebrants.anniversaryCelebrants.map((item, index) => (
                        <tr key={`anniversary-${index}`}>
                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {item.mfirstName} {item.mlastName}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {moment(item.mAnniversaryCont, "MMMM DD").format(
                                "MMMM DD"
                              )}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/auth/peopleview/${item._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {celebrants.anniversaryCelebrants
                                ? "Anniversary"
                                : ""}
                            </Link>
                          </td>
                          {/* <td>
                            <div className="flex justify-center">
                              <Link to={`/auth/peopleview/${item._id}`}>
                                <FaEye style={{ color: "#FE7064" }} />
                              </Link>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No celebrants today
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col sm={4} className="colsm22">
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="diic"
            >
              <div className="cardTit">Upcoming's events</div>
              {!sortedData?.length && (
                <p
                  className={
                    isFree ? "flex justify-center items-center" : "italic"
                  }
                >
                  {isFree
                    ? "Not available in your current plan."
                    : "There are upcoming events"}
                </p>
              )}
              <div className="cardsroll">
                {sortedData.map((item, index) => {
                  const isToday = moment(item.eventDay).isSame(moment(), "day");
                  return (
                    <div key={index} className="upcoming ">
                      <div
                        className="cardCo flex flex-column"
                        style={{
                          backgroundColor: isToday
                            ? `var(--primary-color)`
                            : "",
                          color: isToday ? "#fff" : "",
                          padding: "1rem", // Added padding around the card
                        }}
                      >
                        <div className="cardDa flex justify-between">
                          <div
                            style={{
                              backgroundColor: item?.eventLabel,
                              width: "3%",
                              height: "8%",
                            }}
                          ></div>
                          <div
                            className="eventInfo flex justify-between items-center gap-5"
                            style={{
                              width: "100%",
                              paddingLeft: "0.8rem",
                              fontSize: "1em",
                            }}
                            // style={{ width: "calc(100% - 4%)", paddingLeft: "0.5rem", fontSize: "0.9em", borderBottom: "1px solid #ccc" }} // Added bottom border for separation
                          >
                            <p style={{ marginBottom: "0px" }}>
                              {moment(item?.eventDay).format("MMM D, YYYY")}
                            </p>
                            <p style={{ marginBottom: "0px" }}>
                              {item?.eventLink ? (
                                <button
                                  onClick={() => {
                                    const link = item?.eventLink;
                                    if (link) {
                                      window.open(
                                        link,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }
                                  }}
                                  className="jiluil3"
                                  style={{
                                    fontSize: "1em",
                                  }}
                                >
                                  Join
                                </button>
                              ) : (
                                "In Person"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="cardTi">{item?.eventTitle}</div>
                      </div>
                      <p
                        style={{
                          width: "calc(100% - 4%)",
                          paddingLeft: "0.5rem",
                          fontSize: "1em",
                          borderBottom: "1px solid #ccc",
                          marginBottom: "0",
                        }}
                      ></p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
        {campus && Object.keys(campus).length > 0 && superAdmin && (
          <Row className="dashRow">
            <Col sm={8} style={{ width: "66%" }}>
              <div
                className="diic"
                style={{
                  padding: "1rem 1rem",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                }}
              >
                <p className="cardTit">Church Campus</p>
                <div>
                  {user?.churchCampuses?.length > 0 && (
                    <GroupCampusPieChart data={campus} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
