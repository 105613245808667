import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";

import "./register.css";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import LoginGrap from "../../assets/images/LoginGrap.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../context/appContext";
import { Link, useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import sss from "../../assets/images/sss.png";

const PasswordReset = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!token) {
      setError("Invalid or missing token. Redirecting...");

      setTimeout(() => navigate("/login"), 3000); // Redirect to login after 3 seconds
    } else {
      // Optionally, you could verify the token by making an API call here
      setSuccess(true);
    }
  }, [token, navigate]);

  const { user, displayAlert, logoutUser, resetPassword } = useAppContext();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      displayAlert("Please fill in all fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const userData = {
      token: token,
      password: password,
    };

    try {
      const response = await resetPassword({ userData });

      if (response.status === 200 || response.status === 201) {
        displayAlert("Password reset successful. Redirecting to login...");
        setTimeout(() => navigate("/passwordupdatesuccess"), 2000); // Redirect to login after 3 seconds
      }
    } catch (err) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <>
      <Container fluid style={{ padding: "0" }}>
        <Row className="container2">
          <Col sm={6} md={6} className="logindiv2xx">
            <Image fluid src={sss} className="imgsizexs" />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingTop: "2rem",
                fontSize: "1.9rem",
                width: "65%",
              }}
            >
              Transform Your Church Management with Churchease
            </p>
            {/* <Image fluid src={LoginGrap} className="imgsizex" /> */}
            <p className="redi">
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
          <Col className="signupcolumn">
            <Form>
              {error && <p className="error-message">{error}</p>}

              <p className="welcometssext">Password Reset</p>

              {success && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: "600", fontSize: "1em" }}>
                      New Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="forminput"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: "600", fontSize: "1em" }}>
                      Confirm New Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="forminput"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </Form.Group>
                </>
              )}

              <Button onClick={handlePasswordReset} className="buttonner">
                Reset Password
              </Button>

              <br></br>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PasswordReset;
