import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import { useState } from "react";

const PlanCard = ({ plan }) => {
  const handlePlay = (e, plan) => {
    setShow(true);
    setSelectedPlan(plan);
  };

  const handlePlay2 = (e, plan) => {
    setShow2(true);
    setSelectedPlan(plan);
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  console.log(plan);
  return (
    <>
      <div
        onClick={(e) => handlePlay2(e, plan)}
        className="pointer"
        style={{ textDecoration: "none" }}
      >
        <Card className="main">
          <Card.Body>
            <Card.Title className="courseTitle">
              {plan.title.length > 50
                ? `${plan.title.substring(0, 50)}...`
                : plan.title}
            </Card.Title>
            <small className="text-muted">
              posted in {moment(plan.createdAt).format("MMMM YYYY")}
            </small>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default PlanCard;
