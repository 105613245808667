import React, { useState, useEffect } from "react";
import ViewAllCourses from "./../ViewAllCourses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../../context/appContext";
import { Tooltip } from "antd";
import { Select, Space } from "antd";
import Form from "react-bootstrap/Form";
import { PlusOutlined } from "@ant-design/icons";
import "../learning.css";
import Table from "react-bootstrap/Table";

import AddDocument from "../../../../components/Document/AddDocument";
import ReactAudioPlayer from "react-audio-player";
import ViewDocument from "../../../../components/Document/ViewDocument";
import { Button, Modal } from "antd";

import {
  FaCaretDown,
  FaCaretUp,
  FaTrash,
  FaPlay,
  FaEye,
  FaCheck,
  FaUnlink,
  FaShare,
  FaCheckCircle,
} from "react-icons/fa";
import DeleteDocument from "../../../../components/Document/DeleteDocument";
import PublishDocument from "../../../../components/Document/PublishDocument";
import ShareDocument from "../../../../components/Document/ShareDocument";

const DocumentDashboard = () => {
  const {
    logoutUser,
    completemembers,
    user,
    uniquecontainer,
    getContainers,
    containers,
    getCompleteMembers,
  } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/document`);
    setDocuments(data);
  };
  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [tag, setTag] = useState([]);
  const [member, setMember] = useState("");
  const [group, setGroup] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  console.log(documents);
  useEffect(() => {
    loadCourse();
  }, []);
  const handleReload = () => {
    loadCourse();
  };

  const handleShow = (event, document) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedDocument(document);
  };

  const handleShow5 = (event, document) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedDocument(document);
  };

  const handleShow6 = (event, document) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedDocument(document);
    setShow6(true);
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  const addDocument = (id) => {
    setShow2(true);
  };

  const handleView = (e, document) => {
    setSelectedDocument(document);
    setShow3(true);
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Document Dashboard</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addDocument}>
              Add Document
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {documents && documents.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "100%",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {documents.length ? (
                    <p>
                      {documents.length} record{documents.length > 1 ? "s" : ""}{" "}
                      found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {documents.map((document, index) => (
                    <tbody>
                      <tr
                        key={index}
                        className="trdata"
                        style={{ verticalAlign: "middle" }}
                      >
                        <td>{document.documentName}</td>
                        <td className="rightco">
                          <button
                            className="q3rq3r23"
                            onClick={(e) => handleView(e, document)}
                          >
                            View
                          </button>

                          <button
                            className={
                              document.published ? "jiluil3" : "jiluil"
                            }
                            onClick={(event) => handleShow5(event, document)}
                          >
                            {document.published ? "Unpublish" : "Publish"}
                          </button>

                          <button
                            className="jiluil3"
                            onClick={(event) => handleShow6(event, document)}
                          >
                            Share
                          </button>

                          <button
                            className="jiluil2"
                            onClick={(event) => handleShow(event, document)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>

      <AddDocument
        show={show2}
        setShow={setShow2}
        handleReload={handleReload}
      />

      <ViewDocument
        selectedDocument={selectedDocument}
        setShow={setShow3}
        show={show3}
        handleClose={() => setShow3(false)}
      />
      <DeleteDocument
        show={show}
        setShow={setShow}
        selectedDocument={selectedDocument}
        handleReload={handleReload}
      />
      <PublishDocument
        show={show5}
        setShow={setShow5}
        selectedDocument={selectedDocument}
        handleReload={handleReload}
      />

      <ShareDocument
        show={show6}
        setShow={setShow6}
        selectedDocument={selectedDocument}
        handleReload={handleReload}
      />
    </>
  );
};

export default DocumentDashboard;
