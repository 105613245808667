import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../utils/validation";

const EditLessonPlanner = ({ show, setShow, handleReload, clicked }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, alertType, logoutUser } = useAppContext();
  const [resourceTitle, setResourceTitle] = useState("");

  const [resourceType, setResourceType] = useState("audio");
  const [resourceLink, setResourceLink] = useState("");
  const [resourceFile, setResourceFile] = useState(null);
  const [resourceTags, setResourceTags] = useState("");
  const [resourceCategory, setReourceCategory] = useState("");

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Audio");
  const [uploadLink, setUploadLink] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [error, setError] = useState("");
  const [additionalResources, setAdditionalResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [libraryResources, setLibraryResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resourceData, setResourceData] = useState([]);

  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [lessonFocus, setLessonFocus] = useState("");
  const [learningObjectives, setLearningObjectives] = useState("");
  const [classActivities, setClassActivities] = useState("");
  const [dateLessonDate, setDateLessonDate] = useState(
    moment().format("YYYY-MM-DD")
  ); // Initialize with current date in correct format
  const [type, setType] = useState("");

  useEffect(() => {
    if (clicked) {
      setTitle1(clicked.title);
      setTopic1(clicked.topic);
      setLessonFocus(clicked.lessonFocus);
      setLearningObjectives1(clicked.learningObjectives);
      const formattedDate = moment(clicked.dateLessonDate).format("YYYY-MM-DD");
      setResourceType1(clicked.type);
      setDateLessonDate1(formattedDate);
      setClassActivities1(clicked.classActivities);
      setType(clicked.type);
      setFileData(clicked.files);
      setAdditionalResources1(clicked.additionalResources);
    }
  }, [clicked]);

  //const [lessonPlan, setLessonPlan] = useState(initialLessonPlanState);

  // const handleChange = (e) => {
  //   setLessonPlan({ ...lessonPlan, [e.target.id]: e.target.value });
  // };

  // const handleDateChange = (e) => {
  //   // Ensure the date is handled correctly
  //   setLessonPlan({ ...lessonPlan, dateLessonDate: e.target.value });
  // };

  useEffect(() => {
    loadCourse();
  }, []);

  const loadCourse = async () => {
    try {
      setLoading(true); // Set loading state to true before fetching data
      const { data } = await authFetch.get("/children/resource");
      setResourceData(data);
    } catch (error) {
      console.error("Error loading course resources:", error);
      // Optionally, set an error state here to display an error message to the user
      setError("Failed to load resources. Please try again later.");
    } finally {
      setLoading(false); // Reset loading state after fetching data or if an error occurs
    }
  };

  const handleResourceUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        // toast("No file selected");
        return;
      }

      setResourceFile(file); // Assuming you have a state setter for the resource file
      setUploadButtonText(file.name);
      setUploading(true);

      const resourceData = new FormData();
      resourceData.append("resource", file); // 'resource' should match the field name expected by your backend

      try {
        const { data } = await authFetch.post(
          "/children/resource/uploadfile", // Adjust the API endpoint as needed
          resourceData,
          {
            headers: {
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );
        console.log("Uploaded resource data:", data);

        // Correct way to update the lessonPlan state assuming it's an object and file is a property
        setLessonPlan((prevState) => ({
          ...prevState,
          file: data, // Assuming `data` contains the file info you need
        }));
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
        setUploading(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleResourceRemove = async () => {
    try {
      setUploading(true);

      // Assuming `resourceFile` holds the current file to be removed
      await authFetch.post(`/learning/resource/remove`, {
        fileId: resourceFile.id,
      });

      setResourceFile(null);
      setProgress(0);

      setUploading(false);
      setUploadButtonText("Upload another resource");
    } catch (err) {
      console.error("Error removing resource:", err);
      setUploading(false);
    }
  };

  const [title1, setTitle1] = useState("");
  const [lessonPlan, setLessonPlan] = useState({});
  const [topic1, setTopic1] = useState("");
  const [learningObjectives1, setLearningObjectives1] = useState("");
  const [classActivities1, setClassActivities1] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [dateLessonDate1, setDateLessonDate1] = useState("");
  const [resourceType1, setResourceType1] = useState("");
  const [resourceLink1, setResourceLink1] = useState("");
  const [fileData, setFileData] = useState([]);
  const [additionalResources1, setAdditionalResources1] = useState([]);

  const handleResourceSubmit = async (e) => {
    e.preventDefault();

    const [trimmedName, trimmedTopic] = trimInputs([title1, topic1]);
    console.log("lessonPlan", trimmedName);

    setError("");

    if (!validateNotEmpty(trimmedName)) {
      setError("Please provide all required information to proceed.");
      return;
    }

    // Validate name length
    if (!validateLength(trimmedName, NAME_LENGTH_LIMIT)) {
      setError(`Resource Name cannot exceed ${NAME_LENGTH_LIMIT} characters.`);
      return;
    }

    const resourceDataxx = {
      title: trimmedName,

      topic: trimmedTopic,
      dateLessonDate: dateLessonDate1,

      churchName: user.churchName,
      additionalResources: additionalResources,
      type: resourceType1, // Assuming you have a way to set the type of the resource

      link: resourceLink1,
      files: lessonPlan, // Assuming you have a state for the file like `resourceFile`

      learningObjectives: learningObjectives1,
      classActivities: classActivities1,
    };

    try {
      const response = await authFetch.patch(
        `/children/updatelessonplanner/${clicked._id}`,
        resourceDataxx
      );
      //resetLessonPlanForm();
      handleClose();
      handleReload();
      //  setLessonPlan(initialLessonPlanState);
      setAdditionalResources([]);

      setProgress(0);
      setUploading(false);
      setUploadButtonText("Upload another resource");
      setShow(false);
    } catch (err) {
      console.error(err);
      setError("Failed to upload resource. Please try again.");
      setUploading(false);
    }
  };

  const handleDeleteResource = async (e, file) => {
    e.preventDefault();

    try {
      const response = await authFetch.patch(
        `/children/removefromteacheritemsadd/${clicked._id}`,
        {
          file,
        }
      );

      if (response.status === 200 || response.status === 201) {
        setAdditionalResources1(fileData.filter((f) => f.id !== file.id));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Lesson Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>
              Lesson Plan Title <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={title1}
              onChange={(e) => setTitle1(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="topic">
            <Form.Label>
              Topic <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={topic1}
              onChange={(e) => setTopic1(e.target.value)}
              required
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="lessonFocus">
            <Form.Label>Lesson Focus</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={lessonFocus1}
              onChange={(e) => setLessonFocus1(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="learningObjectives">
            <Form.Label>Learning Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={learningObjectives1}
              onChange={(e) => setLearningObjectives1(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="classActivities">
            <Form.Label>Class Activities</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={classActivities1}
              onChange={(e) => setClassActivities1(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" style={{ position: "relative" }}>
            <Form.Control
              type="text"
              placeholder="Search by topic or subject"
              value={searchTerm1}
              onChange={(e) => setSearchTerm1(e.target.value)}
              style={{ fontSize: "1em", zIndex: 1 }} // Ensure input is above the dropdown
              required
            />
            {searchTerm1 && resourceData.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "100%", // Position right below the input
                  left: 0,
                  width: "100%",
                  backgroundColor: "white", // Match the input background
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add some shadow for better visibility
                  zIndex: 2, // Ensure it's above other content
                  maxHeight: "200px", // Limit height and allow scrolling if necessary
                  overflowY: "auto",
                }}
              >
                {resourceData
                  .filter((resource) => {
                    const lowercaseSearchTerm = searchTerm1.toLowerCase();
                    const topic = resource.title.toLowerCase();
                    const subject = resource.description.toLowerCase();

                    return (
                      lowercaseSearchTerm &&
                      (topic.includes(lowercaseSearchTerm) ||
                        subject.includes(lowercaseSearchTerm))
                    );
                  })
                  .slice(0, 10)
                  .map((resource) => (
                    <div
                      onClick={() => {
                        setAdditionalResources([
                          ...additionalResources,
                          resource,
                        ]);
                        setSearchTerm1(""); // Clear search term after selection
                      }}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #ddd",
                        width: "100%",
                      }}
                      key={resource.id} // Assume each resource has a unique 'id' property
                    >
                      {resource.title} - {resource.description}
                    </div>
                  ))}
              </div>
            )}
          </Form.Group>

          <div>
            {additionalResources.map((resource) => (
              <div
                key={resource.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "10px", // Add some space between the items
                }}
              >
                <div>
                  {resource.title} - {resource.description}
                </div>
                <div>
                  <button
                    variant="danger"
                    onClick={() =>
                      setAdditionalResources(
                        additionalResources.filter((r) => r.id !== resource.id)
                      )
                    }
                    style={{ marginRight: "5px" }}
                    className="jiluil"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>

          {additionalResources1?.length > 0 && (
            <div>
              {additionalResources1
                .filter((item) => item._id) // Filter out any items without a file object
                .map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between p-3 mb-3"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      marginBottom: "0px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "white",
                      }}
                    >
                      {item.url.key}{" "}
                      {/* Access the key property of the file object */}
                    </p>
                    <FaTimes
                      onClick={(e) => handleDeleteResource(e, item)}
                      style={{ cursor: "pointer", color: "white" }} // Adding cursor pointer for better user experience
                    />
                  </div>
                ))}
            </div>
          )}

          <Form.Group className="mb-3" controlId="dateLessonDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={dateLessonDate1}
              onChange={(e) => setDateLessonDate1(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="resourceType">
            <Form.Label>Resource Type</Form.Label>
            <Form.Control
              as="select"
              value={resourceType1}
              onChange={(e) => setResourceType1(e.target.value)}
              required
            >
              <option value="">Select Type</option>
              <option value="document">Document</option>
              <option value="video">Video</option>
              <option value="audio">Audio</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>

          {resourceType1 === "other" && (
            <Form.Group className="mb-3" controlId="resourceLink">
              <Form.Label>Resource Link</Form.Label>
              <Form.Control
                type="text"
                value={resourceLink1}
                onChange={(e) => setResourceLink1(e.target.value)}
                placeholder="https://example.com/resource"
              />
            </Form.Group>
          )}

          {fileData?.length > 0 && (
            <div>
              {fileData
                .filter((item) => item.file) // Filter out any items without a file object
                .map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between p-3 mb-3"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      marginBottom: "0px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "white",
                      }}
                    >
                      {item.file.key}{" "}
                      {/* Access the key property of the file object */}
                    </p>
                    <FaTimes
                      onClick={(e) => handleDeleteResource(e, item.file)}
                      style={{ cursor: "pointer", color: "white" }} // Adding cursor pointer for better user experience
                    />
                  </div>
                ))}
            </div>
          )}

          {!fileData?.length &&
            ["document", "video", "audio"].includes(resourceType1) && (
              <Form.Group className="mb-3" controlId="uploadResource">
                <Form.Label>Upload Resource</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleResourceUpload}
                  accept="audio/*, video/*, application/pdf" // Adapt based on your allowed types
                />
              </Form.Group>
            )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleResourceSubmit}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditLessonPlanner;
