import { useEffect, useState } from "react";
import AddFolderModal from "../../../components/Tags/AddFolderModal";
import AddTagModal from "../../../components/Tags/AddTagModal";
import EditFolderModal from "../../../components/Tags/EditFolderModal";
import EditTagModal from "../../../components/Tags/EditTagModal";
import { useAppContext } from "../../../context/appContext";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DeleteFolderModal from "../../../components/Tags/DeleteFolderModal";
import DeleteTagModal from "../../../components/Tags/DeleteTagModal";

const FolderView = (props) => {
  const params = useParams();
  let id = params?.id;
  const { folderDetail, getFolder, deleteTag, deleteFolder } = useAppContext();

  const [addTagModal, setAddTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [editTagID, setEditTagID] = useState("");
  const [delete_id, setDelete_id] = useState("");
  const [editTagName, setEditTagName] = useState("");
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [editFolderID, setEditFolderID] = useState("");
  const [editFolderName, setEditFolderName] = useState("");
  const [addFolderModal, setAddFolderModal] = useState(false);
  const toggle = () => {
    setAddTagModal(!addTagModal);
  };
  const editModal = (id, name) => {
    setEditTagID(id);
    setEditTagName(name);
    setEditTagModal(true);
  };
  const handleEditFolderModal = (id, name) => {
    setEditFolderID(id);
    setEditFolderName(name);
    setEditFolderModal(true);
  };
  const toggleEditTag = () => {
    setEditTagID("");
    setEditTagName("");
    setEditTagModal(false);
  };
  const toggleDeleteModal = () => {
    setDeleteItem("");
    setDelete_id("");
    setDeleteModal(false);
  };
  const toggleEditFolder = () => {
    setEditFolderID("");
    setEditFolderName("");
    setEditFolderModal(false);
  };
  const toggleFolder = () => {
    setAddFolderModal(!addFolderModal);
  };
  useEffect(() => {
    if (id) {
      getFolder(id);
    }
  }, [id]);

  const [showTagModal, setShowTagModal] = useState(false);
  const [tagDelete, setTagDelete] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [folderDelete, setFolderDelete] = useState(null);
  const [folderId, setFolderId] = useState("");
  const deleteTagData = (e, tag) => {
    e.preventDefault();
    console.log(tag);
    setShowTagModal(true);
    setTagDelete(tag?._id);
    setFolderId(id);
  };

  const deleteFolderData = (e, folder) => {
    e.preventDefault();
    setFolderId(id);
    setShowFolderModal(true);
    setFolderDelete(folder?._id);
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader" style={{ marginBottom: "2rem" }}>
          <Col sm={8}>
            <p className="sahs">{folderDetail.folderName}</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <button
              className="jiluil"
              onClick={() => {
                setAddTagModal(!addTagModal);
              }}
              style={{ fontSize: "1em" }}
            >
              Add Group
            </button>
            <button
              className="jiluil3"
              onClick={() => {
                setAddFolderModal(!addFolderModal);
              }}
              style={{ fontSize: "1em" }}
            >
              Add Folder
            </button>
          </div>
        </Row>
        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#fff" }}>
            <tbody>
              {folderDetail.childFolders
                ?.sort(function (a, b) {
                  if (a.folderName.toLowerCase() < b.folderName.toLowerCase()) {
                    return -1;
                  }
                  if (a.folderName.toLowerCase() > b.folderName.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map((folder, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="tag-row draggable "
                      draggable="true"
                    >
                      <td className="hjk" style={{ fontSize: "1em" }}>
                        <svg
                          width={20}
                          height={15}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="folder"
                          className="svg-inline--fa fa-folder fa-w-16 folder-component__icon"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z"
                          ></path>
                        </svg>
                        <span className="tag__link wqjuy">
                          <Link
                            to={`/auth/folder-detail/${folder?._id}`}
                            draggable="false"
                            style={{ textDecoration: "none", color: "black" }}
                            className="tav2"
                          >
                            {folder?.folderName}
                          </Link>
                        </span>
                      </td>
                      <td className="secondary-info text-right">
                        {/* 10 People */}
                      </td>
                      <td
                      // style="min-width: 8%;"
                      >
                        <div className="tag-list-tag__actions table__actions text-center justify-end flex items-end gap-2">
                          <button
                            className="jiluil3"
                            onClick={() =>
                              handleEditFolderModal(
                                folder._id,
                                folder?.folderName
                              )
                            }
                          >
                            Edit
                          </button>

                          <button
                            className="jiluil"
                            onClick={(e) => deleteFolderData(e, folder)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {folderDetail.tags
                ?.sort(function (a, b) {
                  if (a.tagName.toLowerCase() < b.tagName.toLowerCase()) {
                    return -1;
                  }
                  if (a.tagName.toLowerCase() > b.tagName.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map((tag, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="tag-row draggable"
                      draggable="true"
                    >
                      <td className="hjk" style={{ fontSize: "1em" }}>
                        <svg
                          width={20}
                          height={15}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="tag"
                          className="svg-inline--fa fa-tag fa-w-16 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"
                          ></path>
                        </svg>

                        <span className="tag__link wqjuy">
                          <Link
                            to={`/auth/tag-detail/${tag?.tagName}`}
                            draggable="false"
                            style={{ textDecoration: "none", color: "black" }}
                            className="tav2"
                          >
                            {tag?.tagName}
                          </Link>
                        </span>
                      </td>
                      <td className="secondary-info text-right"></td>
                      <td>
                        <div className="tag-list-tag__actions table__actions text-center justify-end flex items-end gap-2">
                          <button
                            className="jiluil3"
                            onClick={() => editModal(tag._id, tag?.tagName)}
                          >
                            Edit
                          </button>

                          <button
                            className="jiluil"
                            onClick={(e) => deleteTagData(e, tag)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <AddTagModal
        addTagModal={addTagModal}
        toggle={toggle}
        name={folderDetail.folderName}
        id={id}
      />
      <AddFolderModal
        addFolderModal={addFolderModal}
        toggleFolder={toggleFolder}
        name={folderDetail.folderName}
        id={id}
      />
      <EditTagModal
        editTagModal={editTagModal}
        toggleEditTag={toggleEditTag}
        name={editTagName}
        id={editTagID}
        folder_id={id}
      />
      <EditFolderModal
        editFolderModal={editFolderModal}
        toggleEditFolder={toggleEditFolder}
        name={editFolderName}
        id={editFolderID}
        folder_id={id}
      />
      <DeleteFolderModal
        showFolderModal={showFolderModal}
        setShowFolderModal={setShowFolderModal}
        folderDelete={folderDelete}
        folderId={folderId}
      />

      <DeleteTagModal
        showTagModal={showTagModal}
        setShowTagModal={setShowTagModal}
        tagDelete={tagDelete}
        folderId={folderId}
      />

      {/* <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Folder</ModalHeader>
        <ModalBody className="modal-wrapper__body">
          <Row>
            <Col md="12"></Col>
            <div class="jsx-parser">
              {deleteItem === "tag"
                ? "Do you really want to remove this ministry?"
                : " Do you really want to remove this folder? All ministries and folders within this folder will also be deleted."}
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              if (deleteItem === "tag") {
                await deleteTag(delete_id);
              } else if (deleteItem === "folder") {
                await deleteFolder(delete_id);
              }
              toggleDeleteModal();
              await getFolder(id);
            }}
            color="primary"
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
};

export default FolderView;
