import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";

import axios from "axios";
import moment from "moment/moment";
import "./usermanagement.css";
import EditSubscription from "../../../components/ManageSubscription/EditSubscription";

const AccountManagement = () => {
  const [subscription, setSubscription] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { logoutUser, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user) getSubscriptions();
  }, [user]);

  // const getSubscriptions = async () => {
  //   const { data } = await authFetch.get("/payment/subscriptions");

  //   setSubscription(data?.data);
  // };

  const getSubscriptions = async () => {
    try {
      // Attempt to fetch subscription data
      const { data } = await authFetch.get("/payment/subscriptions");

      // Optional chaining to safely access nested data

      // Update state only if subscriptions are not undefined
      if (data.data) {
        setSubscription(data?.data);
      } else {
        console.error("No subscription data found");
        // Optionally set to an empty array or a default state
        setSubscription([]);
      }
    } catch (error) {
      // Log error to console or handle it according to your error handling policy
      console.error("Failed to fetch subscriptions:", error);
      // Optionally set an error state or inform the user
    }
  };

  const uploadSuccessFunc = () => {
    getSubscriptions();
    window.location.reload();
  };

  const handleModal = () => {
    setShowModal(true);
  };

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Account Management</div>
        <div className="bannersmall">
          Use Account Management to easily manage payments and plan upgrades,
          keeping everything straightforward and convenient.
        </div>
      </Row>
      <Row
        className="flex justify-center items-row"
        style={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingBottom: "5rem",
        }}
      >
        {subscription &&
          subscription.map((sub) => (
            <Col key={sub.id}>
              <Card style={{ width: "30%" }}>
                <div className="card-body">
                  <h5 className="card-title" style={{ fontWeight: "600" }}>
                    {sub.plan.nickname}
                  </h5>

                  <p className="card-text">{sub.plan.description}</p>
                  <p className="card-text">
                    <h6
                      className="card-title cardcoo"
                      style={{ fontWeight: "600" }}
                    >
                      Last Payment Date:
                    </h6>
                    <small className="text-muted">
                      {moment(sub.created * 1000)
                        .format("dddd, MMMM Do YYYY h:mm:ss a")
                        .toString()}
                    </small>
                  </p>
                  <p className="card-text">
                    <h6
                      className="card-title cardcoo"
                      style={{ fontWeight: "600" }}
                    >
                      Current Period End:
                    </h6>
                    <small className="text-muted">
                      {moment(sub.current_period_end * 1000)
                        .format("dddd, MMMM Do YYYY h:mm:ss a")
                        .toString()}
                    </small>
                  </p>
                  <p className="card-text">
                    <h6
                      className="card-title cardcoo"
                      style={{ fontWeight: "600" }}
                    >
                      Account Status:
                    </h6>
                    <small className="text-muted">
                      {sub.status === "active" ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </small>
                  </p>
                  <p className="card-text">
                    <h6
                      className="card-title cardcoo"
                      style={{ fontWeight: "600" }}
                    >
                      Card Last 4 Digits:
                    </h6>
                    <small className="text-muted">
                      {sub?.default_payment_method?.card?.last4 || "N/A"}
                    </small>
                  </p>

                  <p className="card-text">
                    <small className="text-muted">
                      {sub.cancel_at_period_end ? (
                        <span className="text-danger">
                          <h6 className="card-title cardcoo">
                            Cancel at period end
                          </h6>
                        </span>
                      ) : (
                        ""
                      )}
                    </small>
                  </p>
                  <p className="card-text">
                    <h6
                      className="card-title cardcoo"
                      style={{ fontWeight: "600" }}
                    >
                      Amount:
                    </h6>
                    <small className="text-muted">
                      {(sub.plan.amount / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: sub.plan.currency,
                      })}
                    </small>
                  </p>

                  <button className="jiluil3" onClick={handleModal}>
                    Manage Subscription
                  </button>
                </div>
              </Card>
            </Col>
          ))}
        <EditSubscription
          show={showModal}
          onHide={() => setShowModal(false)}
          uploadSuccessFunc={uploadSuccessFunc}
        />
      </Row>
    </Container>
  );
};

export default AccountManagement;
