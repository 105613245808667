import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";
import { FaFacebook, FaLinkedin, FaMedium } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Logo from "../Logo/Logo";
import asset from "../../assets/images/asset.png";
const Footer = () => {
  return (
    <Container fluid>
      <Row className="footera">
        <Col xs={12} sm={12} md={4} lg={3}>
          <div className="footerb">
            <p className="headfoot">QUICK ACCESS</p>
            <p>
              <Link to="/pricing" className="footerLink">
                Sign Up
              </Link>
            </p>
            <p>
              <Link to="/login" className="footerLink">
                Log In
              </Link>
            </p>
            <p>
              <a
                href="https://calendly.com/churchease/demo-churchease"
                target="_blank"
                rel="noopener noreferrer"
                className="footerLink"
              >
                Demo
              </a>
            </p>
            <p>
              <Link to="/pricing" className="footerLink">
                Pricing
              </Link>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={3}>
          <div className="footerb">
            <p className="headfoot">PRODUCT</p>
            <p>
              <Link to="/product/people" className="footerLink">
                People
              </Link>
            </p>
            <p>
              <Link to="/product/giving" className="footerLink">
                Giving
              </Link>
            </p>
            <p>
              <Link to="/product/group" className="footerLink">
                Groups
              </Link>
            </p>
            <p>
              <Link to="/product/fellowship" className="footerLink">
                Fellowship
              </Link>
            </p>
            <p>
              <Link to="/product/children" className="footerLink">
                Children
              </Link>
            </p>
          </div>
        </Col>

        <Col xs={12} sm={12} md={4} lg={3}>
          <div className="footerb">
            <p className="headfoot">COMPANY</p>
            <p>
              <Link to="/aboutus" className="footerLink">
                About Us
              </Link>
            </p>

            <p>
              <Link to="/careers" className="footerLink">
                Career
              </Link>
            </p>
            <p>
              <Link to="/contact" className="footerLink">
                Contact Us
              </Link>
            </p>
            <p>
              <Link to="/blog" className="footerLink">
                Blog
              </Link>
            </p>
            <p>
              <Link to="/faq" className="footerLink">
                FAQ
              </Link>
            </p>
            <p>
              <Link to="/knowledgebase" className="footerLink">
                Knowledge Base
              </Link>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={3}>
          <div className="footerb">
            <p className="headfoot">LEGAL</p>

            <p>
              <Link to="/privacy" className="footerLink">
                Privacy Policy
              </Link>
            </p>
            <p>
              <Link to="/terms" className="footerLink">
                Terms of Service
              </Link>
            </p>
            <p>
              <Link to="/security" className="footerLink">
                Security
              </Link>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="footera">
        <Col xs={12} sm={6} md={4} lg={4}>
          <div className="footerb">
            <p style={{ height: "40px", textAlign: "center" }}>
              <img
                src={asset}
                height={40}
                alt="Logo"
                style={{ height: "40px" }}
              />
            </p>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <div className="footerd">©2024 Churchease Software Inc</div>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <div className="footerd">
            <a
              href="https://www.facebook.com/churcheasechms"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="logzi" />
            </a>
            <a
              href="https://www.linkedin.com/company/churchease/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="logzi" />
            </a>
            <a
              href="https://www.instagram.com/churchease"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="logzi" />
            </a>
            <a
              href="https://medium.com/@churchease"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMedium className="logzi" />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
