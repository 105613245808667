import React, { useState, useEffect } from "react";
import ViewAllCourses from "./../ViewAllCourses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../../context/appContext";
import { Tooltip } from "antd";
import { Select, Space } from "antd";
import Form from "react-bootstrap/Form";
import { PlusOutlined } from "@ant-design/icons";
import "../learning.css";
import Table from "react-bootstrap/Table";

import AddAudio from "../../../../components/Audio/AddAudio";
import ReactAudioPlayer from "react-audio-player";
import PlayAudio from "../../../../components/Audio/PlayAudio";
import { Button, Modal } from "antd";

import DeleteAudio from "../../../../components/Audio/DeleteAudio";
import PublishAudio from "../../../../components/Audio/PublishAudio";
import ShareAudio from "../../../../components/Audio/ShareAudio";
import AudioLink from "../../../../components/Audio/AudioLink";

const AudioDashboard = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const loadCourse = async () => {
    const { data } = await authFetch.get(`/learning/audio`);
    setAudio(data);
  };

  const [audio, setAudio] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show4, setShow4] = useState(false);
  const [tag, setTag] = useState([]);
  const [member, setMember] = useState("");
  const [group, setGroup] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null);

  useEffect(() => {
    loadCourse();
  }, []);

  const handleShow = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedValue(saved);
  };

  const handleShow5 = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedValue(saved);
  };

  const handleShow6 = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedValue(saved);
    setShow6(true);
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  const addAudio = (id) => {
    setShow2(true);
  };

  const handleplay = (e, saved) => {
    setShow3(true);
    setSelectedAudio(saved);
  };

  const handleplayLink = (e, saved) => {
    setShow4(true);
    setSelectedAudio(saved);
  };

  const handleReload = () => {
    loadCourse();
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Audio Dashboard</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addAudio}>
              Add Audio
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {audio && audio.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "100%",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {audio.length ? (
                    <p>
                      {audio.length} record{audio.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {audio.map((saved, index) => (
                    <tbody>
                      <tr
                        key={index}
                        className="trdata"
                        style={{ verticalAlign: "middle" }}
                      >
                        <td>{saved.audioName}</td>
                        <td className="rightco">
                          {saved.audioLink ? (
                            <button
                              className="q3rq3r23"
                              onClick={(e) => handleplayLink(e, saved)}
                            >
                              Play
                            </button>
                          ) : (
                            <button
                              className="q3rq3r23"
                              onClick={(e) => handleplay(e, saved)}
                            >
                              Play
                            </button>
                          )}

                          <button
                            className={saved.published ? "jiluil3" : "jiluil"}
                            onClick={(event) => handleShow5(event, saved)}
                          >
                            {saved.published ? "Unpublish" : "Publish"}
                          </button>

                          <button
                            className="jiluil3"
                            onClick={(event) => handleShow6(event, saved)}
                          >
                            Share
                          </button>

                          <button
                            className="jiluil2"
                            onClick={(event) => handleShow(event, saved)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <AddAudio show={show2} setShow={setShow2} handleReload={handleReload} />
      <PlayAudio
        show={show3}
        setShow={setShow3}
        selectedAudio={selectedAudio}
      />
      <DeleteAudio
        show={show}
        setShow={setShow}
        selectedValue={selectedValue}
        handleReload={handleReload}
      />
      <PublishAudio
        show={show5}
        setShow={setShow5}
        selectedValue={selectedValue}
        handleReload={handleReload}
      />

      <ShareAudio
        show={show6}
        setShow={setShow6}
        selectedValue={selectedValue}
        handleReload={handleReload}
      />
      <AudioLink
        show={show4}
        setShow={setShow4}
        selectedAudio={selectedAudio}
      />
    </>
  );
};

export default AudioDashboard;
