import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "react-quill/dist/quill.snow.css";

import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import {
  SiQuickbooks,
  SiMailchimp,
  SiStripe,
  SiFacebook,
  SiInstagram,
  SiX,
} from "react-icons/si";
import axios from "axios";
import "./usermanagement.css";
import MailChimp from "../../../components/Integrations/MailChimp";

const Integrations = () => {
  const code = new URLSearchParams(window.location.search).get("code");
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthStatus] = useState(null);
  const [enrollStatus, setEnrollStatus] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [isConnected, setIsConnected] = useState(false); // Assume not connected initially
  const [error, setError] = useState(null);
  const [enrollStatusFB, setEnrollStatusFB] = useState("");
  const [show1, setShow1] = useState(false);

  const handleShow1 = () => setShow1(true);

  useEffect(() => {
    if (code) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000);

      // Cleanup the timer when the component is unmounted or if enrollStatus changes
      return () => {
        clearTimeout(timer);
      };
    }
  }, [enrollStatus]);

  const showMailChimp = () => {
    setShow(true);
  };

  const handleConnectToQuickBooks = async () => {
    try {
      const response = await authFetch("/quickbook/start-authentication"); // Use authFetchX instead of authFetch
      const { auth_url } = response.data;
      console.log("auth_url: ", auth_url);

      window.location.href = auth_url;

      setIsConnected(true); // Assuming the redirect was successful
      setError(null);
    } catch (err) {
      setIsConnected(false);
      setError("Failed to connect to QuickBooks. Please try again.");
    }
  };

  const handleStripe = () => {
    try {
      setLoading(true);

      const res = authFetch.post("/learning/become-seller");
      console.log(res);
      window.location.href = res.data;
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {showMessage && (
        <div className="alerwuccess">Enrollment is successful.</div>
      )}
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="headerbanner">
          <div className="bannerbig">Thirdparty Integration</div>
          <div className="bannersmall">
            Maximize your church management system by integrating third-party
            apps through our Integration feature, streamlining workflows and
            expanding your ministry's reach
          </div>
        </Row>
        <Row className="courseheader">
          {authStatus && authStatus.access_token && (
            <div>Authenticated successfully!</div>
          )}
          {authStatus && authStatus.error && <div>{authStatus.error}</div>}
        </Row>

        <Row className="fundc2" style={{ paddingTop: "4rem" }}>
          <Col>
            <div className="qedqxx">
              <SiQuickbooks
                className={enrollStatus === "true" ? "intsideax" : "intsidea"}
                onClick={handleConnectToQuickBooks}
              />
              <button
                className="hjujuj1sss"
                onClick={handleConnectToQuickBooks}
              >
                Enroll
              </button>
            </div>
          </Col>
          <Col>
            <div className="qedqxx">
              <SiMailchimp
                className={enrollStatus === "true" ? "intsideax" : "intsidea"}
              />

              {enrollStatus !== "true" ? (
                <button
                  onClick={showMailChimp}
                  className={
                    enrollStatus === "true" ? "hjujuj1sss1" : "hjujuj1sss"
                  }
                >
                  Enroll
                </button>
              ) : (
                <button
                  className={
                    enrollStatus === "true" ? "hjujuj1sss1" : "hjujuj1sss"
                  }
                >
                  {enrollStatus === "true" ? "Unenroll" : "Enroll"}
                </button>
              )}
            </div>
          </Col>

          <Col>
            <div className="qedqxx">
              <SiStripe
                className={
                  user.stripeConnectAccountId ? "intsideax" : "hjujuj1sss"
                }
              />

              <button
                className={
                  user.stripeConnectAccountId ? "hjujuj1sss1" : "hjujuj1sss"
                }
                onClick={handleStripe}
              >
                {user.stripeConnectAccountId ? "Enrolled" : "Enroll"}
              </button>
            </div>
          </Col>
        </Row>
        <Row className="fundc2" style={{ paddingTop: "4rem" }}>
          <Col>
            <div className="qedqxx">
              <SiX className="intsidea" />
              <button className="hjujuj1sss">Enroll</button>
            </div>
          </Col>
          <Col>
            <div className="qedqxx">
              <SiFacebook
                className={enrollStatusFB === "true" ? "intsideax" : "intsidea"}
              />
              <button
                className={
                  enrollStatusFB === "true" ? "hjujuj1sss1" : "hjujuj1sss"
                }
                onClick={handleShow1}
              >
                {enrollStatusFB === "true" ? "Enrolled" : "Enroll"}
              </button>
            </div>
          </Col>

          <Col>
            <div className="qedqxx">
              <SiInstagram className="intsidea" />
              <button className="hjujuj1sss">Enroll</button>
            </div>
          </Col>
        </Row>
        {show && <MailChimp show={show} setShow={setShow} />}
      </Container>
    </>
  );
};

export default Integrations;
