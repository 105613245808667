import React, { useEffect, useState } from "react";

import "../Alert/alert.css";

const CustomAlert = ({ alertType, alertText }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Set a timer to hide the alert after 7 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 7000);

    // Cleanup the timer when the component is unmounted or when the alert changes
    return () => clearTimeout(timer);
  }, [alertText, alertType]);

  return isVisible ? (
    <div
      className={`alert alert-${alertType}`}
      style={{
        position: "fixed", // Fix the position
        top: 0, // Align to the top
        left: 0, // Align to the left
        right: 0, // Align to the right
        zIndex: 9999, // Ensure it's above other content
        textAlign: "center", // Center the text
      }}
    >
      {alertText}
    </div>
  ) : null;
};

export default CustomAlert;
