import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

const EditDynamicFields = ({
  uploadSuccessFunc,
  show,
  setShow,
  clickedField,
}) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { user, logoutUser } = useAppContext();
  const [fieldsData, setFieldsData] = useState({});
  const [fields, setFields] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (show && clickedField) {
      getCustomFieldById(); // Call the function to get the field details
    }
  }, [show, clickedField]);

  const getCustomFieldById = async () => {
    try {
      const response = await authFetch.get(`/dynamicfields/${clickedField}`);
      if (response.status === 200 || response.status === 201) {
        setFieldsData(response.data);
        setTitle(response.data.category || "");
        setFields(response.data.fields || []); // Access the fields array correctly
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTaskChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, fieldName: value } : field
    );
    setFields(updatedFields);
  };

  const handleTypeChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, fieldType: value } : field
    );
    setFields(updatedFields);
  };

  const handleCheckedChange = (index, isChecked) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, checked: isChecked } : field
    );
    setFields(updatedFields);
  };

  const addTask = () => {
    setFields([
      ...fields,
      { fieldName: "", fieldType: "text", options: [], checked: false },
    ]);
  };

  const addOptionToTask = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].options.push("");
    setFields(updatedFields);
  };

  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const updatedFields = [...fields];
    updatedFields[taskIndex].options[optionIndex] = value;
    setFields(updatedFields);
  };

  const removeOption = (taskIndex, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[taskIndex].options.splice(optionIndex, 1);
    setFields(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleUpdateField = async (e) => {
    e.preventDefault();
    try {
      if (
        !title ||
        fields.some((field) => !field.fieldName || !field.fieldType)
      ) {
        return toast(
          <CustomToastContent title="Error" message="Please fill all fields" />
        );
      }
      const updatedData = {
        category: title, // Category name
        fields: fields, // Array of updated fields
        churchDomain: user.churchDomain,
      };

      const response = await authFetch.patch(
        `/dynamicfields/${clickedField}`,
        updatedData
      );
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        //getDynamicFields();
        handleClose();
        //  getDynamicFields(); // Refresh dynamic fields after update
        setTitle("");
        setFields([]);
        toast(
          <CustomToastContent title="Success" message="Update Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Update {fieldsData.category} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form onSubmit={handleUpdateField}>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            {fields.map((task, index) => (
              <Row key={index} className="mb-3">
                <Col md={6}>
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. First Name"
                    value={task.fieldName}
                    onChange={(e) => handleTaskChange(index, e.target.value)}
                  />
                </Col>

                <Col md={4}>
                  <Form.Label>Field Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={task.fieldType}
                    onChange={(e) => handleTypeChange(index, e.target.value)}
                  >
                    <option value="text">Single Line</option>
                    <option value="textarea">Multi Line</option>
                    <option value="radio">Single Select Option</option>
                    <option value="checkbox">Multiple Select Option</option>
                    <option value="date">Date</option>
                    <option value="number">Number</option>
                  </Form.Select>
                </Col>

                <Col
                  md={2}
                  className="d-flex flex-row justify-between align-items-end"
                >
                  <Form.Check
                    type="checkbox"
                    label="Required"
                    checked={task.checked}
                    onChange={(e) =>
                      handleCheckedChange(index, e.target.checked)
                    }
                  />
                  {index > 0 && (
                    <p
                      onClick={() => removeField(index)}
                      style={{
                        marginBottom: "0px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      Remove
                    </p>
                  )}
                </Col>

                {(task.fieldType === "radio" ||
                  task.fieldType === "checkbox") && (
                  <>
                    {task.options.map((option, optionIndex) => (
                      <Form.Group
                        key={optionIndex}
                        className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                      >
                        <Form.Control
                          type="text"
                          placeholder={`Option ${optionIndex + 1}`}
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(
                              index,
                              optionIndex,
                              e.target.value
                            )
                          }
                        />
                        <FaTimes
                          onClick={() => removeOption(index, optionIndex)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </Form.Group>
                    ))}
                    <button
                      className="jiluil3 mt-3 mb-3"
                      onClick={() => addOptionToTask(index)}
                    >
                      Add Option
                    </button>
                  </>
                )}
              </Row>
            ))}
            <button className="jiluil" onClick={addTask}>
              Add Field
            </button>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleUpdateField}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditDynamicFields;
