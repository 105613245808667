import React from "react";
import { FaCheck } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
const StandardPlan = () => {
  return (
    <div>
      <div>
        <p className="colbb cardli noino">All Basic features, plus</p>
        <p className="noino">
          <FaCheck className="okjyb" /> Giving and Donations
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Service Planner
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Thirdparty Integrations
        </p>
        <p className="noino">
          {" "}
          <FaCheck className="okjyb" /> Prayers
        </p>
      </div>

      <div className="my-3">
        <p style={{ fontWeight: "600" }} className="noino">
          Also includes
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> 1000 texts per month
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> 100GB storage space
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited users and contacts
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited emails
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited support
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Free data transfer
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Cancel anytime
        </p>
      </div>
    </div>
  );
};

export default StandardPlan;
