import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./blogcreateform.css";

const BlogCreateForm = ({
  handleSubmit,
  handleImage,
  handleChange,
  values,
  setValues,
  preview,
  handleImageRemove,
  handleQuillChange,
  setMarkdownContent,
  markdownContent,
  editPage = false,
}) => {
  return (
    <>
      <Container fluid>
        <Row className="courseform">
          <Form onSubmit={handleSubmit} className="formercoursexx">
            {/* Post Title */}
            <Form.Group className="mb-3" controlId="blogTitle">
              <Form.Label className="corelabel">Post Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Post Title"
                name="blogTitle"
                onChange={handleChange}
                value={values.blogTitle}
                className="lpm"
              />
            </Form.Group>

            {/* Post Category */}
            <Form.Group className="mb-3" controlId="category">
              <Form.Label className="corelabel">Post Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="category"
                onChange={handleChange}
                value={values.category}
              >
                <option value="uncategorized">Select a category</option>
                <option value="general">General Topics</option>
                <option value="guides">How To Guides</option>
                <option value="learning">Learning and Developments</option>
                <option value="NewReleases">New Releases</option>
                <option value="Announcements">Announcements</option>
                <option value="spiritual">Spiritual Growth</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="homeIntro">
              <Form.Label className="corelabel">
                Post Intro (max 400)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Post Title"
                name="homeIntro"
                onChange={handleChange}
                value={values.homeIntro}
                className="lpm"
              />
              <p>{values.homeIntro ? values.homeIntro.length : 0}/400</p>
            </Form.Group>

            {/* Post Details */}
            <Form.Group className="mb-3" controlId="blogDescription">
              <Form.Label className="corelabel">
                Post Details (use markup)
              </Form.Label>
              <ReactQuill
                theme="snow"
                placeholder="Write something..."
                className="lpmw"
                style={{ height: "300px" }}
                onChange={handleQuillChange}
                value={values.blogDescription}
              />
            </Form.Group>

            {/* Image Upload */}
            <Form.Group controlId="formFile" className="mb-3 mt-5">
              {values.loading ? (
                <p className="corelabel">Uploading</p>
              ) : (
                <p className="corelabel">Image Upload</p>
              )}
              <Form.Control
                type="file"
                name="image"
                onChange={handleImage}
                accept="image/*"
                className="lpm"
              />
              <p style={{ fontSize: "70%", color: "#FE7064" }}>
                For the best quality, image dimension should be 1208 by 698.
              </p>
            </Form.Group>

            {/* Image Preview and Removal */}
            {preview && (
              <>
                <p className="corelabel">Image Preview</p>
                <Image
                  src={preview}
                  style={{ width: "600px", height: "auto" }}
                />
              </>
            )}
            {editPage && values.image && (
              <Badge count="X" onClick={handleImageRemove} className="pointer">
                <Image
                  src={values.image.Location}
                  thumbnail
                  style={{ width: "600px", height: "auto" }}
                />
              </Badge>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              className="wedweded"
              disabled={values.loading || values.uploading}
            >
              {values.loading ? "Saving..." : "Save & Continue"}
            </Button>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default BlogCreateForm;
