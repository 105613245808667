import React, { useState, useEffect } from "react";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";
import HighLightText from "../../../components/HighLightText/HighLightText";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { isNonNegativeInteger } from "../../../utils";

const CreateVolunteer = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [enterEvent, setEnterEvent] = useState("");
  const [useEventData, setUseEventData] = useState("");
  const searchTerm = useEventData?.toLowerCase().trim();
  const [publish, setPublish] = useState(false);
  const [schedule, setSchedule] = useState("");
  const [repeat, setRepeat] = useState("");
  const [frequency, setFrequency] = useState("");
  const [teamMembers, setTeamMembers] = useState([
    {
      memberId: 0,
      memberName: "",
      positions: [
        { positionName: "", quantity: "", requireBackgroundCheck: false },
      ],
    },
  ]);

  const [useEvent, setUseEvent] = useState(false);

  const [scheduleDescription, setScheduleDescription] = useState("");
  const [skillRequired, setSkillRequired] = useState([{ id: 0, value: "" }]);
  const [schedules, setSchedules] = useState([
    { id: 0, startTime: "", endTime: "" },
  ]);

  const {
    showAlert,
    logoutUser,
    user,
    getEventByVolunteers,
    eventsByVolunteer,
    createSchedule,
  } = useAppContext();

  useEffect(() => {
    getEventByVolunteers();
  }, []);

  useEffect(() => {
    if (useEvent === false) {
      setUseEventData("");
    }
  }, [useEvent]);

  const handleInputChange = (id, value) => {
    const newInputs = skillRequired.map((input) =>
      input.id === id ? { ...input, value } : input
    );
    setSkillRequired(newInputs);
  };

  const addNewSkill = () => {
    setSkillRequired((prevInputs) => [
      ...prevInputs,
      { id: prevInputs.length, value: "" },
    ]);
  };

  const removeSkill = (id) => {
    if (id !== 0) {
      // Do not remove the input with id:0
      const newInputs = skillRequired.filter((input) => input.id !== id);
      setSkillRequired(newInputs);
    }
  };

  const handleTimeChange = (id, type, value) => {
    const newSchedules = schedules.map((schedule) =>
      schedule.id === id ? { ...schedule, [type]: value } : schedule
    );
    setSchedules(newSchedules);
  };

  const addNewSchedule = () => {
    const newId = Math.max(...schedules.map((schedule) => schedule.id)) + 1;
    setSchedules((prevSchedules) => [
      ...prevSchedules,
      { id: newId, startTime: "", endTime: "" },
    ]);
  };

  const removeSchedule = (id) => {
    if (id !== 0) {
      const newSchedules = schedules.filter((schedule) => schedule.id !== id);
      setSchedules(newSchedules);
    }
  };

  //////

  const handleMemberChange = (memberId, value) => {
    const newMembers = teamMembers.map((member) =>
      member.memberId === memberId ? { ...member, memberName: value } : member
    );
    setTeamMembers(newMembers);
  };

  const handlePositionChange = (memberId, positionIndex, type, value) => {
    const newMembers = teamMembers.map((member) => {
      if (member.memberId === memberId) {
        const newPositions = member.positions.map((position, index) =>
          index === positionIndex ? { ...position, [type]: value } : position
        );
        return { ...member, positions: newPositions };
      }
      return member;
    });
    setTeamMembers(newMembers);
  };

  const addNewPosition = (memberId) => {
    const newMembers = teamMembers.map((member) => {
      if (member.memberId === memberId) {
        return {
          ...member,
          positions: [
            ...member.positions,
            { positionName: "", quantity: "", requireBackgroundCheck: false },
          ],
        };
      }
      return member;
    });
    setTeamMembers(newMembers);
  };

  const addNewMember = () => {
    const newMemberId =
      Math.max(...teamMembers.map((member) => member.memberId)) + 1;
    setTeamMembers([
      ...teamMembers,
      {
        memberId: newMemberId,
        memberName: "",
        positions: [
          { positionName: "", quantity: "", requireBackgroundCheck: false },
        ],
      },
    ]);
  };
  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    setMinDate(moment().format("YYYY-MM-DD"));
  }, []);

  const removeTeamMember = (memberId) => {
    const newMembers = teamMembers.filter(
      (member) => member.memberId !== memberId
    );
    setTeamMembers(newMembers);
  };

  const removePosition = (memberId, positionIndex) => {
    const newMembers = teamMembers.map((member) => {
      if (member.memberId === memberId) {
        const newPositions = member.positions.filter(
          (_, index) => index !== positionIndex
        );
        return { ...member, positions: newPositions };
      }
      return member;
    });
    setTeamMembers(newMembers);
  };

  const navigateToView = () => {
    navigate("/auth/volunteermanagement");
  };
  const [error, setError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!schedule || !frequency || !scheduleDescription || !teamMembers) {
      window.scrollTo(0, 0);
      setError(true);
      return;
    }

    const currentSchedule = {
      schedule: schedule,
      repeat: repeat,
      teamMembers: teamMembers,
      useEvent: useEvent,
      scheduleDescription: scheduleDescription,
      skillRequired: skillRequired,
      volunteerSchedules: schedules,
      useEventData: useEventData,
      churchName: user.churchName,
      startDate: frequency,
      published: publish,
    };

    createSchedule({ currentSchedule });

    setSchedule("");
    setRepeat("");
    setTeamMembers([
      {
        memberId: 0,
        memberName: "",
        positions: [
          { positionName: "", quantity: "", requireBackgroundCheck: false },
        ],
      },
    ]);
    setUseEvent(false);
    setScheduleDescription("");
    setSkillRequired([{ id: 0, value: "" }]);
    setSchedules([{ id: 0, startTime: "", endTime: "" }]);
    setUseEventData("");
    navigateToView();
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="fundc" style={{ marginBottom: "3rem" }}>
          {error && (
            <CustomAlert
              alertType="danger"
              alertText="Please complete the asterisk fields to proceed!"
            />
          )}

          <p
            style={{ textAlign: "center", fontSize: "150%", fontWeight: "500" }}
          >
            Schedule Information
          </p>
        </Row>

        <Row className="viewmain">
          <div>
            <p className="formtext">Basic Schedule Information</p>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Update schedule using existing events"
                name="defaultFund"
                value="defaultFund"
                onChange={(e) => setUseEvent(e.target.checked)}
              />
            </Form.Group>

            {useEvent && (
              <Form.Group
                className="mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="input-container">
                  <Form.Control
                    type="text"
                    value={useEventData}
                    onChange={(e) => {
                      setUseEventData(e.target.value);
                      setShowDropdown(true);
                    }}
                    placeholder="Type the event name"
                    className="formvalue"
                    required
                  />

                  {useEventData && (
                    <span
                      className="close-icon"
                      onClick={() => {
                        setSchedule("");
                        setUseEventData("");
                        setShowDropdown(false);
                      }}
                      style={{ color: "red" }}
                    >
                      X
                    </span>
                  )}
                </div>
                {showDropdown && (
                  <div className="dropdown-container">
                    {eventsByVolunteer &&
                      eventsByVolunteer
                        .filter((item) => {
                          if (!searchTerm) return false;
                          const eventTitle = item.eventTitle?.toLowerCase();
                          return eventTitle.includes(searchTerm);
                        })
                        .sort(
                          (a, b) => new Date(a.eventDay) - new Date(b.eventDay)
                        )
                        .slice(0, 10)
                        .map((item) => (
                          <div
                            onClick={() => {
                              const eventDetail = `${
                                item.eventTitle
                              } - ${moment(item.eventDay).format(
                                "MMMM Do YYYY"
                              )}`;
                              setSchedule(eventDetail);
                              setUseEventData(eventDetail); // Set the selected event detail to the useEventData
                              setShowDropdown(false); // Hide the dropdown after selecting an item
                            }}
                            className="clickable-event"
                            key={item.eventTitle}
                          >
                            <HighLightText
                              text={item.eventTitle}
                              highlight={useEventData}
                            />{" "}
                            -{moment(item.eventDay).format("MMMM Do YYYY")}
                          </div>
                        ))}
                  </div>
                )}
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label className="hed">
                Schedule Name<span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={schedule}
                name="schedule"
                onChange={(e) => setSchedule(e.target.value)}
                className="formvaluexc1"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="hed">
                Schedule Description <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="scheduleDescription"
                value={scheduleDescription}
                required
                onChange={(e) => setScheduleDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="hed">
                Start Date <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                className="formvaluexc1"
                value={frequency}
                name="frequency"
                onChange={(e) => setFrequency(e.target.value)}
                required
                min={minDate} // set the minimum date
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="hed">Frequency</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={repeat}
                onChange={(e) => setRepeat(e.target.value)}
                defaultValue={"Does not repeat"}
              >
                <option value="Does not repeat">Does not repeat</option>
                <option value="Daily">Repeat Daily</option>
                <option value="Weekly">Repeat Weekly on Friday</option>
                <option value="Weekday">Repeat on Weekday (Mon - Fri)</option>
                <option value="Monthly">Repeat Monthly</option>
                <option value="Quarterly">Repeat Quarterly</option>
                <option value="Annually">Repeat Annually</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div>
            <p className="formtext">Tell us about the volunteers you need</p>
            <div className="mb-3">
              {teamMembers.map((member) => (
                <div key={member.memberId} className="mb-3">
                  <p style={{ margin: "0" }} className="hed">
                    Team Name<span className="myast">*</span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Form.Control
                      type="text"
                      value={member.memberName}
                      onChange={(e) =>
                        handleMemberChange(member.memberId, e.target.value)
                      }
                      required
                    />
                    {member.memberId !== 0 && (
                      <FaTimes
                        className="pluss"
                        onClick={() => removeTeamMember(member.memberId)}
                      />
                    )}
                  </div>

                  {member.positions.map((position, positionIndex) => (
                    <div key={positionIndex} className="mb-3 mt-3">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "80%",
                            fontWeight: "600",
                          }}
                        >
                          Position Name:
                        </p>
                        <Form.Control
                          type="text"
                          value={position.positionName}
                          onChange={(e) =>
                            handlePositionChange(
                              member.memberId,
                              positionIndex,
                              "positionName",
                              e.target.value
                            )
                          }
                          required
                        />
                        <p
                          style={{
                            margin: "0",
                            fontSize: "80%",
                            fontWeight: "600",
                          }}
                        >
                          How Many:
                        </p>
                        <Form.Control
                          type="number"
                          value={position.quantity}
                          style={{ width: "19%" }}
                          onChange={(e) =>
                            handlePositionChange(
                              member.memberId,
                              positionIndex,
                              "quantity",
                              e.target.value
                            )
                          }
                          required
                        />
                        {positionIndex !== 0 && (
                          <FaTimes
                            className="pluss"
                            onClick={() =>
                              removePosition(member.memberId, positionIndex)
                            }
                          />
                        )}
                      </div>
                      <Form.Group
                        className="mb-3 mt-3"
                        controlId={`requireBackgroundCheck_${member.memberId}_${positionIndex}`}
                      >
                        <Form.Check
                          type="checkbox"
                          className="formvalue"
                          label="Require Background Check"
                          checked={position.requireBackgroundCheck}
                          onChange={(e) =>
                            handlePositionChange(
                              member.memberId,
                              positionIndex,
                              "requireBackgroundCheck",
                              e.target.checked
                            )
                          }
                          required
                        />
                      </Form.Group>
                    </div>
                  ))}

                  <p
                    onClick={() => addNewPosition(member.memberId)}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    + Add new position
                  </p>
                </div>
              ))}

              <button
                type="submit"
                onClick={addNewMember}
                className=" mt-3  py-2 px-4 mr-5 ewfwwwefh"
              >
                Add Team Member
              </button>
            </div>
          </div>

          <div>
            <p className="formtext">What time do you need the volunteers</p>
            <Form.Group className="mb-3">
              {schedules.map((schedule, index) => (
                <div key={index} className="mnyw mb-3">
                  <p
                    style={{ margin: "0", fontSize: "80%", fontWeight: "600" }}
                  >
                    Start Time:<span className="myast">*</span>
                  </p>
                  <Form.Control
                    type="time"
                    className="formvalue"
                    value={schedule.startTime}
                    onChange={(e) =>
                      handleTimeChange(schedule.id, "startTime", e.target.value)
                    }
                    name={`scheduleStartTime_${index}`}
                    required
                  />
                  <p
                    style={{ margin: "0", fontSize: "80%", fontWeight: "600" }}
                  >
                    End Time:
                  </p>
                  <Form.Control
                    type="time"
                    className="formvalue"
                    value={schedule.endTime}
                    onChange={(e) =>
                      handleTimeChange(schedule.id, "endTime", e.target.value)
                    }
                    name={`scheduleEndTime_${index}`}
                    required
                  />

                  {schedule.id !== 0 && (
                    <FaTimes
                      className="pluss"
                      onClick={() => removeSchedule(schedule.id)}
                    />
                  )}
                </div>
              ))}

              <button
                type="submit"
                onClick={addNewSchedule}
                className=" mt-3 py-2 px-4 mr-5 ewfwwwefh"
              >
                Add
              </button>
            </Form.Group>
          </div>

          <div>
            <p className="formtext">What skills are required</p>
            <Form.Group className="mb-5">
              <div className="d-flex flex-column ">
                {skillRequired.map((input, index) => (
                  <div key={index} className="mb-3 mnyw">
                    <Form.Control
                      type="text"
                      className="formvaluexc1"
                      value={input.value}
                      onChange={(e) =>
                        handleInputChange(input.id, e.target.value)
                      }
                      name={`skillRequired_${index}`}
                      placeholder="Enter Skill"
                      required
                    />
                    {input.id !== 0 && (
                      <FaTimes
                        className="pluss"
                        onClick={() => removeSkill(input.id)}
                      />
                    )}
                  </div>
                ))}
              </div>

              <button
                type="submit"
                onClick={addNewSkill}
                className="mt-3 py-2 px-4 mr-5 ewfwwwefh"
              >
                Add
              </button>
            </Form.Group>
          </div>

          <div className="flex justify-center mb-5">
            <button className="wedweded1" onClick={(e) => handleSubmit(e)}>
              Save
            </button>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CreateVolunteer;
