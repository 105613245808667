import dayjs from "dayjs";
import React, { useContext, useState, useRef } from "react";
import "./calendar.css";
import GlobalContext from "../../../calendarcontext/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useReactToPrint } from "react-to-print";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import PrintCalendar from "../../../components/PrintCalendar/PrintCalendar";

export default function CalendarHeader({ month }) {
  const navigate = useNavigate();

  const { monthIndex, setMonthIndex, setShowEventModal } =
    useContext(GlobalContext);

  const componentRef = useRef();

  const printView = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Calendar Report",
  });

  const printMe = () => {
    printView();
  };
  const Calendarlist = () => {
    navigate("/auth/calendarview");
  };

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }

  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }

  function handleReset() {
    setMonthIndex(dayjs().month());
  }

  function downloadMe() {
    const link = document.createElement("a");
    link.href = `data:text/html,${encodeURIComponent(
      componentRef.current.outerHTML
    )}`;
    link.download = "calendar.html";
    link.click();
  }
  return (
    <div className="calendarheader">
      <Row className="flex items-center justify-between">
        <Col sm={3}>
          <button onClick={handlePrevMonth} className="headprevleft">
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button onClick={handleReset} className="buttonnerxx">
            Today
          </button>
          <button onClick={handleNextMonth} className="headprevleft">
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </Col>
        <Col sm={5}>
          <h2 className="hhead">
            {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
          </h2>
        </Col>
        <Col sm={4} className="flex gap-3">
          <button onClick={() => setShowEventModal(true)} className="jiluil">
            New Event
          </button>

          <button onClick={Calendarlist} className="jiluil3">
            List View
          </button>
          <button onClick={printMe} className="jiluil2">
            Print
          </button>
        </Col>
      </Row>
      <div style={{ display: "none" }}>
        <PrintCalendar ref={componentRef} />
      </div>
    </div>
  );
}
