import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Table,
  Form,
  InputGroup,
  Button,
  FormControl,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import "./powerquery.css";

const PowerQuery = () => {
  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [queries, setQueries] = useState([
    [{ field: "", operator: "", value: "", include: true, parameter: "" }],
  ]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const handleConditionChange = (queryIndex, conditionIndex, key, value) => {
  //   const updatedQueries = queries.map((conditions, qIndex) => {
  //     if (qIndex === queryIndex) {
  //       return conditions.map((condition, cIndex) => {
  //         if (cIndex === conditionIndex) {
  //           return { ...condition, [key]: value };
  //         }
  //         return condition;
  //       });
  //     }
  //     return conditions;
  //   });
  //   console.log(
  //     `Query updated at index ${queryIndex}, condition index ${conditionIndex}: ${key} set to ${value}`
  //   );

  //   setQueries(updatedQueries);
  // };

  const handleConditionChange = (queryIndex, conditionIndex, key, value) => {
    const updatedQueries = queries.map((conditions, qIndex) => {
      if (qIndex === queryIndex) {
        return conditions.map((condition, cIndex) => {
          if (cIndex === conditionIndex) {
            // Determine what needs to be reset based on what key is being changed
            if (key === "field") {
              // Reset operator and inputValue when field changes
              return {
                ...condition,
                [key]: value,
                operator: "",
                inputValue: "",
              };
            } else if (key === "operator") {
              // Reset inputValue when operator changes, if necessary
              return {
                ...condition,
                [key]: value,
                inputValue:
                  condition.operator === value ? condition.inputValue : "",
              };
            } else {
              // For other keys, just update the value
              return { ...condition, [key]: value };
            }
          }
          return condition;
        });
      }
      return conditions;
    });
    console.log(
      `Query updated at index ${queryIndex}, condition index ${conditionIndex}: ${key} set to ${value}`
    );

    setQueries(updatedQueries);
  };

  const addCondition = (queryIndex) => {
    const updatedQueries = queries.map((conditions, qIndex) => {
      if (qIndex === queryIndex) {
        return [
          ...conditions,
          {
            field: "",
            operator: "",
            value: "",
            include: true,
            parameter: "",
          },
        ];
      }
      return conditions;
    });
    setQueries(updatedQueries);
  };

  const addQuery = () => {
    setQueries([
      ...queries,
      [{ field: "", operator: "", value: "", include: true, parameter: "" }],
    ]);
  };

  const deleteCondition = (queryIndex, conditionIndex) => {
    const updatedQueries = queries.map((conditions, qIndex) => {
      if (qIndex === queryIndex) {
        return conditions.filter((_, cIndex) => cIndex !== conditionIndex);
      }
      return conditions;
    });
    setQueries(updatedQueries);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    console.log(queries);
    try {
      // Construct the payload based on current state of queries
      const payload = queries.map((group) =>
        group.map((condition) => ({
          field: condition.field,
          operator: condition.operator,
          value: condition.value,
          include: condition.include,
          inputValue: condition.inputValue,
          parameter: condition.parameter,
        }))
      );

      const response = await authFetch.post("/powerquery", payload);

      setResults(response.data);
    } catch (error) {
      // Axios wraps the response error in error.response
      setError(
        `Error: ${error.response ? error.response.data : error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        <Col>
          <p className="sahs">Forms</p>
        </Col>
      </Row>
      <Row className="courseheader">
        <Form onSubmit={handleSearch}>
          {queries.map((conditions, queryIndex) => (
            <div key={queryIndex}>
              {conditions.map((condition, conditionIndex) => (
                <div>
                  <p className="powerp">Query {conditionIndex + 1}</p>
                  <InputGroup
                    className="mb-3"
                    key={condition.id || conditionIndex}
                  >
                    <DropdownButton
                      variant="outline-secondary"
                      title={condition.include ? "Include" : "Exclude"}
                      id={`input-group-dropdown-${queryIndex}-${conditionIndex}`}
                    >
                      <Dropdown.Item
                        onClick={() =>
                          handleConditionChange(
                            queryIndex,
                            conditionIndex,
                            "include",
                            true
                          )
                        }
                      >
                        Include
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleConditionChange(
                            queryIndex,
                            conditionIndex,
                            "include",
                            false
                          )
                        }
                      >
                        Exclude
                      </Dropdown.Item>
                    </DropdownButton>
                    <Form.Select
                      aria-label="Field select"
                      value={condition.field}
                      onChange={(e) =>
                        handleConditionChange(
                          queryIndex,
                          conditionIndex,
                          "field",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Field</option>
                      <option value="people">People</option>
                    </Form.Select>
                    <Form.Select
                      aria-label="Value select"
                      value={condition.value}
                      onChange={(e) =>
                        handleConditionChange(
                          queryIndex,
                          conditionIndex,
                          "value",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Value</option>
                      <option value="mfirstName">First Name</option>
                      <option value="mlastName">Last Name</option>
                      <option value="preferredName">Preferred Name</option>
                      <option value="mProfilePicture">Photo</option>
                      <option value="mBirthMonth">Birthday</option>
                      <option value="mAnniversaryMonth">Anniversary</option>
                      <option value="createdAt">Created</option>
                      <option value="updatedAt">Last Updated</option>
                      <option value="memail">Email</option>
                      <option value="mmobilePhone">Mobile Phone</option>
                      <option value="mgender">Gender</option>
                      <option value="mnotes">Notes</option>
                      <option value="isActive">Account is Active</option>
                      <option value="familyId">Household</option>
                    </Form.Select>
                    {[
                      "mfirstName",
                      "mlastName",
                      "preferredName",
                      "memail",
                      "mmobilePhone",
                    ].includes(condition.value) && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="starts_with">Starts with</option>
                          <option value="ends_with">Ends with</option>
                          <option value="contains">Contains</option>
                          <option value="not_equals">Not Equal</option>
                          <option value="equals">Equals</option>
                        </Form.Select>
                        <FormControl
                          aria-label="Text input"
                          type="text"
                          value={condition.inputValue || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "inputValue",
                              e.target.value
                            )
                          }
                        />
                      </>
                    )}

                    {["createdAt", "updatedAt"].includes(condition.value) && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="is_set">Is Defined</option>
                          <option value="on_date">On</option>
                          <option value="on_or_before">On or Before</option>
                          <option value="on_or_after">On or After</option>
                          <option value="is_today">Today</option>
                        </Form.Select>
                      </>
                    )}

                    {["on_date", "on_or_before", "on_or_after"].includes(
                      condition.operator
                    ) && (
                      <>
                        <FormControl
                          aria-label="Text input"
                          type="date"
                          value={condition.inputValue || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "inputValue",
                              e.target.value
                            )
                          }
                        />
                      </>
                    )}

                    {condition.value === "familyId" && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="exist_indb">Exist</option>
                          <option value="not_exist_indb">Not Exist</option>
                        </Form.Select>
                      </>
                    )}

                    {condition.value === "mnotes" && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="exist_indb">Exist</option>
                          <option value="not_exist_indb">Not Exist</option>
                        </Form.Select>
                      </>
                    )}

                    {condition.value === "mProfilePicture" && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="is_object">Exist</option>
                          <option value="is_not_object">Not Exist</option>
                        </Form.Select>
                      </>
                    )}

                    {condition.value === "isActive" && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="is_active">True</option>
                          <option value="is_not_active">False</option>
                        </Form.Select>
                      </>
                    )}

                    {["mBirthMonth", "mAnniversaryMonth"].includes(
                      condition.value
                    ) && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="equals">Equals</option>
                          <option value="not_equals">Not Equal</option>
                        </Form.Select>
                        <Form.Select
                          aria-label="Text input"
                          value={condition.inputValue || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "inputValue",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select a time context</option>

                          <option value="january">January</option>
                          <option value="february">February</option>
                          <option value="march">March</option>
                          <option value="april">April</option>
                          <option value="may">May</option>
                          <option value="june">June</option>
                          <option value="july">July</option>
                          <option value="august">August</option>
                          <option value="september">September</option>
                          <option value="october">October</option>
                          <option value="november">November</option>
                          <option value="december">December</option>
                        </Form.Select>
                      </>
                    )}

                    {condition.value === "mgender" && (
                      <>
                        <Form.Select
                          aria-label="Operator select"
                          value={condition.operator || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "operator",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Operator</option>
                          <option value="equals">Is</option>
                          <option value="not_equals">Not </option>
                        </Form.Select>
                        <Form.Select
                          aria-label="Text input"
                          value={condition.inputValue || ""}
                          onChange={(e) =>
                            handleConditionChange(
                              queryIndex,
                              conditionIndex,
                              "inputValue",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select an option </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Select>
                      </>
                    )}

                    <Button
                      variant="danger"
                      onClick={() =>
                        deleteCondition(queryIndex, conditionIndex)
                      }
                    >
                      Delete
                    </Button>
                  </InputGroup>
                  <div className="flex flex-row mb-5">
                    <Form.Select
                      aria-label="Logical operator"
                      value={condition.parameter}
                      onChange={(e) =>
                        handleConditionChange(
                          queryIndex,
                          conditionIndex,
                          "parameter",
                          e.target.value
                        )
                      }
                      style={{ width: "17%" }}
                    >
                      <option value="">Add condition</option>
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </Form.Select>
                    <Button
                      variant="secondary"
                      onClick={() => addCondition(queryIndex)}
                      style={{
                        backgroundColor:
                          condition.parameter === "AND"
                            ? "var(--primary-color)"
                            : condition.parameter === "OR"
                            ? "var(--secondary-color)"
                            : "black",
                      }}
                    >
                      Add Condition
                    </Button>
                  </div>

                  <hr style={{ color: "red" }}></hr>
                </div>
              ))}
            </div>
          ))}

          {/* <Button variant="success" onClick={addQuery} className="m-2">
            Add New Rule
          </Button> */}

          <div className="flex justify-center w-100">
            <button
              className="jiluil"
              style={{ width: "30%" }}
              disabled={loading}
            >
              Search
            </button>
          </div>
        </Form>
      </Row>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <Row className="tabw">
        <p
          style={{
            fontWeight: "700",
            fontSize: "1em",
            color: "var(--primary-color)",
            paddingTop: "2rem",
          }}
        >
          {results && (
            <p>
              {results?.length} record{results?.length > 1 ? "s" : ""} found
            </p>
          )}
        </p>
        {results && (
          // <div>
          //   <h3>Search Results</h3>
          //   <pre>{JSON.stringify(results, null, 2)}</pre>
          // </div>
          <Table
            hover
            responsive
            style={{ backgroundColor: "#f7f7f7", marginBottom: "3rem" }}
          >
            <thead>
              <tr className="trdataheader">
                <th>First Name</th>
                <th>Last Name</th>
                <th>Preferred Name</th>
                <th>Gender</th>
                <th>Marital Status</th>
                <th>Phone Number</th>
                <th>Email</th>
              </tr>
            </thead>
            {results.length > 0 &&
              results.map((result, index) => (
                <tbody>
                  <tr key={index} className="trdata">
                    <td>{result.mfirstName}</td>
                    <td>{result.mlastName}</td>
                    <td>{result.preferredName}</td>
                    <td>{result.mgender}</td>
                    <td>{result.mmarital}</td>
                    <td>{result.mmobilePhone}</td>
                    <td>{result.memail}</td>
                  </tr>
                </tbody>
              ))}
          </Table>
        )}
      </Row>
    </Container>
  );
};

export default PowerQuery;
