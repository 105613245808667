import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import QRCode from "qrcode.react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareForm = ({ show, setShow, selectedForm, uploadSuccessFunc }) => {
  const { logoutUser, user } = useAppContext();
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [copiedShortUrl, setCopiedShortUrl] = useState(false);
  const [shortenedUrl, setShortenedUrl] = useState("");

  const handleClose = () => setShow(false);

  // Base URL for form sharing
  const baseURL = "https://churchease.com/form";
  const formUrl = `${baseURL}/${selectedForm?._id}`;

  // Fetch shortened URL when form is selected
  useEffect(() => {
    if (selectedForm && show) {
      fetchShortenedUrl();
    }
  }, [selectedForm, show]);

  const fetchShortenedUrl = async () => {
    try {
      const res = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(formUrl)}`
      );
      setShortenedUrl(res.data);
    } catch (error) {
      console.error("Error shortening URL:", error);
    }
  };

  const handleCopyOriginal = () => {
    setCopiedUrl(true);
    setTimeout(() => setCopiedUrl(false), 2000);
  };

  const handleCopyShort = () => {
    setCopiedShortUrl(true);
    setTimeout(() => setCopiedShortUrl(false), 2000);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share Form: {selectedForm?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(copiedUrl || copiedShortUrl) && (
          <div className="inlinesuccess mb-3">Copied!</div>
        )}

        <Form.Label>Form Link</Form.Label>
        <div className="flex flex-row gap-3">
          <Form.Control type="text" value={formUrl} disabled />
          <CopyToClipboard text={formUrl} onCopy={handleCopyOriginal}>
            <button className="jiluil">Copy</button>
          </CopyToClipboard>
        </div>

        <Form.Label className="mt-3">Shortened Link</Form.Label>
        <div className="flex flex-row gap-3">
          <Form.Control type="text" value={shortenedUrl} disabled />
          <CopyToClipboard text={shortenedUrl} onCopy={handleCopyShort}>
            <button className="jiluil3">Copy</button>
          </CopyToClipboard>
        </div>

        <div className="mt-3 mb-3">
          {shortenedUrl && <QRCode value={shortenedUrl} />}
        </div>

        <div className="mt-3 p-3 bg-gray-100 rounded">
          <h6 className="mb-2">Form Status</h6>
          <p className="mb-1">
            • Status: {selectedForm?.published ? "Published" : "Unpublished"}
          </p>
          <p className="mb-1">• Access: Anyone with the link can respond</p>
          <p className="mb-0">
            • Responses: {selectedForm?.responseCount || 0} total
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareForm;
