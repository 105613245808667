import React, { useState } from "react";
import "./membernav.css";
import logot from "../../assets/images/logot.png";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import { FaAngleRight, FaArrowLeft } from "react-icons/fa";

const MemberFooter = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, user } = useAppContext();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const hasNotice = true; // Change this based on your notice logic
  const showSupport = () => {
    setShow(true);
  };
  const [show, setShow] = useState(false);
  const [support, setSupport] = useState("");
  const [level, setLevel] = useState("");
  const [supportDescription, setSupportDescription] = useState("");
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const handleClose = () => setShow(false);
  const handleSupport = async () => {
    if (!support || !supportDescription || !level) {
      setError(true);
      return;
    }

    const data = {
      support,
      level,
      supportDescription,
      userId: user?._id,
      userEmail: user?.memail,
      userNames: user?.mfirstName + " " + user?.mlastName,
      churchName: user?.churchName,
    };

    try {
      const response = await authFetch.post("/email/support", {
        data,
      });

      if (response.status === 200) {
        setSent(true);
        setSupport("");
        setSupportDescription("");
        setLevel("");
        setError(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="page-container">
        <div className="contentFooter"> {/* Your main content here */} </div>

        <div className="footer-logo" onClick={togglePopup}>
          <img src={logot} alt="Churchease Logo" />
          {hasNotice && (
            <Badge pill bg="danger" className="notification-badge"></Badge>
          )}

          {isPopupVisible && (
            <div className="popup">
              <div className="popupheader">
                <span className="close" onClick={togglePopup}>
                  &times;
                </span>
                <p className="supportheader">Churchease Help Center</p>
              </div>
              <div className="popup-content">
                <div>
                  <p className="popup-text">
                    What's New in Churchease? <FaAngleRight />
                  </p>
                  <p className="smalltext33">
                    Latest features and enhancements in Churchease
                  </p>
                </div>

                <hr className="footline" />
                <div>
                  <p onClick={showSupport} className="popup-text">
                    Contact Us <FaAngleRight />
                  </p>
                  <p className="smalltext33">
                    Suggest new feature changes or report product issues
                    directly to the Churchease team
                  </p>
                </div>
                <hr className="footline" />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <p className="inlineerror">Please fill all fields to proceed!!</p>
          )}
          {sent && <p className="inlinesuccess">Request sent successful!</p>}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Request Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setSupport(e.target.value)}
              value={support}
            >
              <option>Request Type</option>
              <option value="Feature">New Feature</option>
              <option value="Billing">Billing</option>
              <option value="Issue">Issue with application</option>
              <option value="Support">
                Support in setting up the application
              </option>
              <option value="Feedback">Feedback</option>
              <option value="Training">Training Request</option>
            </Form.Select>
          </Form.Group>

          {support !== "Feature" && (
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Service Level</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setLevel(e.target.value)}
                value={level}
              >
                <option>Service Level</option>
                <option value="high">High (2 Business Day)</option>
                <option value="medium">Medium (3 Business Days)</option>
                <option value="low">Low (4 - 5 Business Weeks)</option>
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="supportDescription"
              onChange={(e) => setSupportDescription(e.target.value)}
              value={supportDescription}
              className="lpmw"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil3" onClick={handleSupport}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemberFooter;
