import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import axios from "axios";
import { currencyFormatter } from "../../../utils/helpers";
import "./standard.css";
import { useNavigate } from "react-router-dom";

const StandardFormSubmitted = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { showAlert, user, logoutUser } = useAppContext();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/auth/standardform");
  };

  return (
    <>
      <Container fluid>
        <Row className="flex justify-center items-center">
          <div
            className="flex justify-center items-start flex=column"
            style={{
              marginTop: "8rem",
              border: "1px solid #d7d7d7",
              padding: "2rem 2rem",
              borderRadius: "10px",
              borderTop: "15px solid var(--primary-color)",
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p className="sahs" style={{ fontSize: "2em" }}>
              Thank you for your response.
            </p>

            <p>You can fill out this form only once.</p>
            <p>
              Try contacting the owner of the form if you think this is a
              mistake.
            </p>
            <button className="jiluil" onClick={handleBack}>
              Go Back
            </button>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StandardFormSubmitted;
