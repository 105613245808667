import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import "react-toastify/dist/ReactToastify.css";
import "./need.css";
import { useAppContext } from "../../context/appContext";
import Alert from "../../components/Alert/Alert.js";
import Header from "../../components/Navbar/Navbar";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  FaCalendar,
  FaInfoCircle,
  FaCheck,
  FaChess,
  FaEnvelope,
  FaFont,
  FaMicrophone,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaTextHeight,
  FaUserAlt,
  FaUserFriends,
  FaVideo,
  FaChild,
} from "react-icons/fa";

const features = [
  {
    name: "People",

    description: "Effectively manage church member details and records.",
    image: <FaUserAlt />,
  },
  {
    name: "Ministry",

    description: "Tools for managing various ministries within the church.",
    image: <FaPeopleArrows />,
  },
  {
    name: "Groups",

    description: "Organize and manage small groups for better engagement.",
    image: <FaUserFriends />,
  },
  {
    name: "Follow Up",

    description: "Track and follow up with visitors and members.",
    image: <FaRunning />,
  },
  {
    name: "Event",

    description: "Plan and manage church events seamlessly.",
    image: <FaCalendar />,
  },
  {
    name: "Service Planner",

    description:
      "Schedule and plan services, including volunteers and resources.",
    image: <FaCheck />,
  },
  {
    name: "Forms",

    description: "Create custom forms for surveys, signups, and more.",
    image: <FaFont />,
  },
  {
    name: "Fellowship",

    description:
      "Enhance the sense of community and fellowship within the church using Prayer and Testimonies.",
    image: <FaPray />,
  },
  {
    name: "Learning Center",

    description: "Educational tools for discipleship and learning.",
    image: <FaEnvelope />,
  },
  {
    name: "Live Streaming",

    description:
      "Broadcast your services and events live to reach a wider audience.",
    image: <FaVideo />,
  },
  {
    name: "Giving",

    description: "Facilitate tithes, offerings, and donations digitally.",
    image: <FaChess />,
  },
  {
    name: "Communication",

    description:
      "Comprehensive tools for effective communication within the church community.",
    image: <FaTextHeight />,
  },
  {
    name: "Chatbot",

    description:
      "Automate responses and engage with members 24/7 through an intelligent chatbot.",
    image: <FaMicrophone />,
  },
  {
    name: "Children",
    description: "Manage children's ministry and activities.",
    image: <FaChild />,
  },
];
const Need = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [churchName, setChurchName] = useState();
  const [aboutUs, setAboutUs] = useState();
  const [country, setCountry] = useState();
  const [churchSize, setChurchSize] = useState();
  const [churchSMS, setChurchSMS] = useState();
  const [churchContent, setChurchContent] = useState();
  const {
    isLoading,
    showAlert,
    displayAlert,
    userNeed,
    userNeedData,
    logoutUser,
  } = useAppContext();
  const [scroll, setScroll] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [churchWebsite, setChurchWebsite] = useState();
  const [emailError, setEmailError] = useState(false);
  const [feedback, setFeedback] = useState({});

  function handleFeatureSelect(featureName, isChecked) {
    setSelectedFeatures((prev) =>
      isChecked ? [...prev, featureName] : prev.filter((f) => f !== featureName)
    );
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !country ||
      !lastName ||
      !churchName ||
      !phoneNumber ||
      !email ||
      !churchSize ||
      !churchSMS ||
      !churchContent ||
      !churchWebsite ||
      !country ||
      selectedFeatures.length === 0
    ) {
      displayAlert();
      return;
    }

    const demoUser = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      churchName: churchName,
      country: country,
      churchWebsite: churchWebsite,
      mmobilePhone: phoneNumber,
      churchSize: churchSize,
      churchSMS: churchSMS,
      churchContent: churchContent,
      selectedFeatures: selectedFeatures,
    };

    // userNeed({ demoUser });
    try {
      const response = await authFetch.post("/demo/forneed", demoUser);
      console.log(response.data.ok);
      if (response && response.data.ok === true) {
        navigate("/needsubmitted");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(feedback);
    // if (feedback && feedback.ok === true) {
    //   navigate("/needsubmitted");
    // } else {
    //   setEmailError(
    //     "There was an error submitting your request. Please try again."
    //   );
    //   window.scrollTo(0, 0);
    // }

    setFirstName("");
    setLastName("");
    setChurchName("");
    setCountry("");
    setAboutUs("");
    setPhoneNumber("");
    setEmail("");
    setChurchSize("");
    setChurchSMS("");
    setChurchContent("");
    setSelectedFeatures([]);
    setChurchWebsite("");
  };

  return (
    <>
      <Helmet>
        <title>Complete your need assessment form - Churchease</title>
        <meta
          name="description"
          content="Sign up for a live demo of Churchease and see how our church management software can simplify administration, enhance community engagement, and streamline giving."
        />
      </Helmet>
      <Header />
      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", margin: "0" }}>
          <div className="flex items-center flex-column wrrer">
            <Form className="formdivfdss">
              {emailError && <p className="inlineerror">{emailError}</p>}
              <p className="awwqx mb-3">Complete Your Needs Assessment Form</p>
              <p className="mb-4">
                Thank you for your interest in Churchease. This brief
                questionnaire is designed to capture your church's specific
                needs, helping us provide a tailored solution. Your responses
                will guide us in customizing our services to better fit your
                requirements.
              </p>
              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Church Name <span style={{ color: "#FE7064" }}>*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Church Name"
                  value={churchName}
                  onChange={(e) => setChurchName(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Church Website <span style={{ color: "#FE7064" }}>*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Church Website"
                  value={churchWebsite}
                  onChange={(e) => setChurchWebsite(e.target.value)}
                  className="forminput cnm"
                />
                <span className="sdsdkkk">
                  If you do not have a website, please enter 'Not Applicable'.
                </span>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  First Name <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="firstname"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Last Name <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="lastname"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Phone <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="phone"
                  placeholder="Phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Email <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Select Country <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="sssswq"
                >
                  <option value="" selected="selected"></option>
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="North Macedonia">North Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia and Montenegro">
                    Serbia and Montenegro
                  </option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  What is your church total size?{" "}
                  <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  id="churchsize"
                  placeholder="Church Size"
                  value={churchSize}
                  onChange={(e) => setChurchSize(e.target.value)}
                  className="forminput cnm"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  On average, how many SMS messages do you require per month?{" "}
                  <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  id="churchsms"
                  placeholder="Church Size"
                  value={churchSMS}
                  onChange={(e) => setChurchSMS(e.target.value)}
                  className="forminput cnm"
                />
                <span className="sdsdkkk">
                  Our platform supports global messaging.
                </span>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  How much content in gigabytes (GB) do you expect to upload and
                  manage monthly <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  id="churchcontent"
                  placeholder="Church Size"
                  value={churchContent}
                  onChange={(e) => setChurchContent(e.target.value)}
                  className="forminput cnm"
                />
                <span className="sdsdkkk">
                  Please include all types of files such as audio, video, and
                  documents.
                </span>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-black">
                  Select all product features you are interested in.{" "}
                  <span style={{ color: "#FE7064" }}>*</span>
                </Form.Label>
                {features.map((feature, index) => (
                  <Form.Check
                    type="checkbox"
                    id={`feature-${index}`}
                    key={index}
                    onChange={(e) =>
                      handleFeatureSelect(feature.name, e.target.checked)
                    }
                    label={
                      <div className="feature-label d-flex align-items-center gap-2">
                        {feature.name}
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>
                              {feature.description}
                            </Tooltip>
                          }
                        >
                          <span>
                            <FaInfoCircle className="info-icon" />
                          </span>
                        </OverlayTrigger>
                      </div>
                    }
                  />
                ))}
              </Form.Group>

              <p className="custb">
                <button
                  className="jiluilxxxc"
                  onClick={HandleSubmit}
                  disabled={isLoading}
                  style={{ width: "100%", height: "3rem" }}
                >
                  Submit Requirements
                </button>
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "0.7rem",
                  color: "gray",
                }}
              >
                By entering your contact details, you agree to get updates and
                notifications from Churchease, such as emails, phone calls, and
                SMS messages. Normal message and data rates may apply. The
                number of messages may vary. You can unsubscribe at any time. To
                understand how we handle your personal information and your
                rights, please check our{" "}
                <Link
                  to="/privacy"
                  style={{ textDecoration: "none", color: "#FE7064" }}
                >
                  privacy policy.
                </Link>
                .
              </p>
            </Form>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Need;
