import { useAppContext } from "../../context/appContext";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { Container, Row, Col } from "react-bootstrap";
import "./pagebtn.css";

const PageBtn = () => {
  const { numOfPages, page, changePage } = useAppContext();

  const pages = Array.from({ length: numOfPages }, (_, index) => {
    return index + 1;
  });
  const nextPage = () => {
    let newPage = page + 1;
    if (newPage > numOfPages) {
      newPage = 1;
    }
    changePage(newPage);
  };
  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = numOfPages;
    }
    changePage(newPage);
  };
  return (
    <Container fluid>
      <Row>
        <Col className="butbody">
          <button className="prev-btn" onClick={prevPage}>
            <HiChevronDoubleLeft />
            prev
          </button>
          <div className="btn-container">
            {pages.map((pageNumber) => {
              return (
                <button
                  type="button"
                  className={
                    pageNumber === page ? "pageBtn active-my" : "pageBtn"
                  }
                  key={pageNumber}
                  onClick={() => changePage(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}
          </div>
          <button className="next-btn" onClick={nextPage}>
            next
            <HiChevronDoubleRight />
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default PageBtn;
