import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";

const PermissionManagement = () => {
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  const { logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleSubmit = async () => {
    const menuData = {
      roleName: roleName,
      roleDescription: roleDescription,
      generatedby: "System",
      churchName: "System",
    };

    try {
      const response = await authFetch.post("/permission/new", menuData);
      if (response.status === 201 || response.status === 200) {
        setRoleName("");
        setRoleDescription("");
        alert("Permission Added Successfully");
      }
    } catch (error) {
      logoutUser();
    }
  };
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Role Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Menu"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Role Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Permission Name"
          value={roleDescription}
          onChange={(e) => setRoleDescription(e.target.value)}
        />
      </Form.Group>

      {/* <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group> */}

      <Button variant="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
  );
};

export default PermissionManagement;
