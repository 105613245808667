import axios from "axios";
import { useEffect, useState } from "react";
import CourseCard from "../../../components/CourseCard/CourseCard";
import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DocumentCard from "../../../components/DocumentCard/DocumentCard";
import AudioCard from "../../../components/Audio/AudioCard";
import PlanCard from "../../../components/Planner/PlanCard";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const LearningMain = () => {
  const [courses, setCourses] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [audios, setAudios] = useState([]);
  const { logoutUser, user } = useAppContext();
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [planner, setPlanner] = useState([]);

  useEffect(() => {
    const loadCourse = async () => {
      const { data } = await authFetch.get(`/learning/planner/published`);
      setPlanner(data);
    };

    loadCourse();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      const { data } = await authFetch.get("/learning/courses");

      setCourses(data);
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      const { data } = await authFetch.get("/learning/document/published");

      setDocuments(data);
    };

    fetchDocuments();
  }, []);

  useEffect(() => {
    const fetchAudios = async () => {
      const { data } = await authFetch.get("/learning/audio/published");

      setAudios(data);
    };

    fetchAudios();
  }, []);

  const handleEnroll = async (e, course) => {
    e.preventDefault();
    navigate(`/auth/course/${course.slug}`);
  };
  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <div
            className="courseHeaderPadding"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            Welcome to {user.churchName} Learning Center
          </div>
        </Row>
        <Row>
          <p className="coursetext">Unpublished Courses</p>
          {courses &&
            courses.map((course) => (
              <Card className="mian" onClick={(e) => handleEnroll(e, course)}>
                <div className="pklkm">
                  <a>
                    <img
                      src={course.image ? course.image.Location : "/course.png"}
                      style={{
                        backgroundColor: "#f7f7f7",
                      }}
                    />
                  </a>
                </div>
                <Card.Body>
                  <a>
                    <Card.Title className="courseTitle">
                      {course.name.length > 50
                        ? `${course.name.substring(0, 50)}...`
                        : course.name}
                    </Card.Title>
                  </a>

                  <Card.Text>
                    <p style={{ fontSize: "1em" }}>
                      {course.description.length > 50
                        ? `${course.description.substring(0, 100)}...`
                        : course.description}
                    </p>
                  </Card.Text>

                  <div className="flex gap-3">
                    <button
                      className="jiluil3 mt-3"
                      onClick={(e) => handleEnroll(e, course)}
                    >
                      Enroll for this course
                    </button>
                  </div>
                </Card.Body>
              </Card>
            ))}
        </Row>
        {audios && audios.length > 0 && (
          <Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
            <p className="headhhh">Audio</p>
            <hr />
            <div className="row pt-3">
              {audios?.map((audio) => (
                <div
                  key={audio?._id}
                  className="col-md-4"
                  style={{ width: "20%" }}
                >
                  <AudioCard key={audio?._id} audio={audio} />
                </div>
              ))}
            </div>
          </Row>
        )}
        {documents && documents.length > 0 && (
          <Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
            <p className="headhhh">Documents</p>
            <hr />
            <div className="row pt-3">
              {documents?.map((document) => (
                <div
                  key={document?._id}
                  className="col-md-4"
                  style={{ width: "20%" }}
                >
                  <DocumentCard key={document?._id} document={document} />
                </div>
              ))}
            </div>
          </Row>
        )}

        {planner && planner.length > 0 && (
          <Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
            <p className="headhhh">Growth Planner</p>
            <hr />
            <div className="row pt-3">
              {planner?.map((plan) => (
                <div key={plan?._id} className="col-md-4">
                  <Link
                    to={`/auth/growthedit/${plan._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <PlanCard plan={plan} />
                  </Link>
                </div>
              ))}
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default LearningMain;
