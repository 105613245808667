import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const DeleteSingleKnowledge = ({
  show,
  setShow,
  selectedValue,
  loadKnowledgeBase,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const deleteCategory = async () => {
    if (!selectedValue) {
      console.error("No category selected for deletion.");
      return;
    }

    try {
      const response = await authFetch.delete(
        `/knowledgebase/${selectedValue}`
      );
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        loadKnowledgeBase(); // Refresh the knowledge base data
      } else {
        console.error("Failed to delete category:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          Delete Knowledge Base
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        This action cannot be reversed.
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            deleteCategory(selectedValue);
            handleClose();
          }}
          className="jiluil"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSingleKnowledge;
