import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import "./coursecreateform.css";
import { useAppContext } from "../../context/appContext";
import { FaTimes } from "react-icons/fa";
import CustomAlert from "../CustomAlert/CustomAlert";
const CourseCreateForm = ({
  handleSubmit,
  error,
  setError,
  handleImage,
  handleChange,
  values,
  setValues,
  preview,
  handleImageRemove,
  editPage = false,
}) => {
  const { user } = useAppContext();

  const [updated, setUpdated] = useState(false);
  useEffect(() => {
    if (user && !user.stripeConnectAccountId) {
      setUpdated(true);
    }
  }, [user]);
  return (
    <>
      <Container fluid>
        <Row className="courseform">
          <Form onSubmit={handleSubmit} className="formercourse">
            {error && (
              <p className="inlineerror">
                Please provide a course name and description to proceed.
              </p>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="corelabel">Course Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Course Name"
                name="name"
                onChange={handleChange}
                value={values.name}
                className="lpm"
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="corelabel">Course Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter course description"
                name="description"
                onChange={handleChange}
                value={values.description}
                className="lpmw"
              />
            </Form.Group>

            <div>
              <Form.Group controlId="formFile" className="mb-3">
                {values.loading ? (
                  <p className="corelabel">Uploading</p>
                ) : (
                  <p className="corelabel">Image Upload</p>
                )}
                <Form.Control
                  type="file"
                  name="image"
                  onChange={handleImage}
                  accept="image/*"
                  className="lpm"
                />
                <p
                  style={{
                    fontSize: "70%",
                    color: "#FE7064",
                  }}
                >
                  For the best quality, image dimension should be 700 by 500.
                </p>
              </Form.Group>

              {preview && (
                <>
                  <p className="corelabel">Image Preview</p>
                  <div className="flex gap-5 items-center mb-5 mt-3">
                    <Image width={240} src={preview} />
                    <FaTimes onClick={handleImageRemove} />
                  </div>
                </>
              )}
            </div>

            <button
              onClick={handleSubmit}
              className="wedweded"
              disabled={values.loading || values.uploading}
              loading={values.loading}
            >
              {values.loading ? "Saving..." : "Save & Continue"}
            </button>

            <br></br>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default CourseCreateForm;
