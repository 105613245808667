import React from "react";
import { FaCheck } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
const FreePlan = () => {
  return (
    <div>
      <div>
        <p className="colbb cardli noino">What you get:</p>
        <p className="noino">
          <FaCheck className="okjyb" /> Member Management
        </p>
      </div>

      <div className="my-3">
        <p style={{ fontWeight: "600" }} className="noino">
          Also includes
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited users and contacts
        </p>
        <p className="noino">
          <FaCheck className="okjyb" /> Unlimited emails
        </p>
      </div>
    </div>
  );
};

export default FreePlan;
