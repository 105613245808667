export const HighLightText = ({ text, highlight }) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <b key={i} style={{ backgroundColor: "yellow" }}>
            {part}
          </b>
        ) : (
          part
        )
      )}
    </span>
  );
};

export default HighLightText;
