export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const SETUP_DEMO_BEGIN = "SETUP_DEMO_BEGIN";
export const SETUP_DEMO_SUCCESS = "SETUP_DEMO_SUCCESS";
export const SETUP_DEMO_ERROR = "SETUP_DEMO_ERROR";

export const RESET_NEWPASSWORD_BEGIN = "RESET_NEWPASSWORD_BEGIN";
export const RESET_NEWPASSWORD_SUCCESS = "RESET_NEWPASSWORD_SUCCESS";
export const RESET_NEWPASSWORD_ERROR = "RESET_NEWPASSWORD_ERROR";

export const SET_PAGE = "SET_PAGE";

export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";

export const SETUP_NEED_BEGIN = "SETUP_NEED_BEGIN";
export const SETUP_NEED_SUCCESS = "SETUP_NEED_SUCCESS";
export const SETUP_NEED_ERROR = "SETUP_NEED_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATEMEMBER_USER_BEGIN = "UPDATEMEMBER_USER_BEGIN";
export const UPDATEMEMBER_USER_SUCCESS = "UPDATEMEMBER_USER_SUCCESS";
export const UPDATEMEMBER_USER_ERROR = "UPDATEMEMBER_USER_ERROR";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_VALUES = "CLEAR_VALUES";

export const CREATE_MEMBER_BEGIN = "CREATE_MEMBER_BEGIN";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_ERROR = "CREATE_MEMBER_ERROR";

export const GET_MEMBERS_BEGIN = "GET_MEMBERS_BEGIN";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";

export const SET_EDIT_MEMBER = "SET_EDIT_MEMBER";

export const DELETE_ROLE_BEGIN = "DELETE_ROLE_BEGIN";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

export const DELETE_MEMBER_BEGIN = "DELETE_MEMBER_BEGIN";
export const DELETE_MEMBER_ERROR = "DELETE_MEMBER_ERROR";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";

export const EDIT_MEMBER_BEGIN = "EDIT_MEMBER_BEGIN";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_ERROR = "EDIT_MEMBER_ERROR";

export const SHOW_STATS_BEGIN = "SHOW_STATS_BEGIN";
export const SHOW_STATS_SUCCESS = "SHOW_STATS_SUCCESS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_FILTERS_MEMBER = "CLEAR_FILTERS_MEMBER";
export const CHANGE_PAGE = "CHANGE_PAGE";

export const GET_CURRENT_USER_BEGIN = "GET_CURRENT_USER_BEGIN";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const SEND_MAIL = "SEND_MAIL";

export const CREATE_EVENT_BEGIN = "CREATE_EVENT_BEGIN";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const GET_EVENT_BEGIN = "GET_EVENT_BEGIN";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";

export const FORGOT_PASSWORD_BEGIN = "FORGOT_PASSWORD_BEGIN";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const ADD_CONTRIBUTION_BEGIN = "ADD_CONTRIBUTION_BEGIN";
export const ADD_CONTRIBUTION_SUCCESS = "ADD_CONTRIBUTION_SUCCESS";
export const ADD_CONTRIBUTION_ERROR = "ADD_CONTRIBUTION_ERROR";

export const CLOSE_BATCH_BEGIN = "CLOSE_BATCH_BEGIN";
export const CLOSE_BATCH_SUCCESS = "CLOSE_BATCH_SUCCESS";
export const CLOSE_BATCH_ERROR = "CLOSE_BATCH_ERROR";
export const GET_COMPLETE_BEGIN = "GET_COMPLETE_BEGIN";
export const GET_COMPLETE_SUCCESS = "GET_COMPLETE_SUCCESS";

export const DELETE_METHOD_BEGIN = "DELETE_METHOD_BEGIN";
export const DELETE_METHOD_ERROR = "DELETE_METHOD_ERROR";
export const DELETE_METHOD_SUCCESS = "DELETE_METHOD_SUCCESS";
export const GET_METHOD_BEGIN = "GET_METHOD_BEGIN";
export const GET_METHOD_SUCCESS = "GET_METHOD_SUCCESS";
export const ADD_METHOD_BEGIN = "ADD_METHOD_BEGIN";
export const ADD_METHOD_ERROR = "ADD_METHOD_ERROR";
export const ADD_METHOD_SUCCESS = "ADD_METHOD_SUCCESS";

export const DELETE_FUND_BEGIN = "DELETE_FUND_BEGIN";
export const DELETE_FUND_ERROR = "DELETE_FUND_ERROR";
export const DELETE_FUND_SUCCESS = "DELETE_FUND_SUCCESS";
export const GET_FUND_BEGIN = "GET_FUND_BEGIN";
export const GET_FUND_SUCCESS = "GET_FUND_SUCCESS";
export const ADD_FUND_BEGIN = "ADD_FUND_BEGIN";
export const ADD_FUND_ERROR = "ADD_FUND_ERROR";
export const ADD_FUND_SUCCESS = "ADD_FUND_SUCCESS";

export const GET_BATCHSUMMARY_BEGIN = "GET_BATCHSUMMARY_BEGIN";
export const GET_BATCHSUMMARY_SUCCESS = "GET_BATCHSUMMARY_SUCCESS";

export const GET_BATCHVIEW_BEGIN = "GET_BATCHVIEW_BEGIN";
export const GET_BATCHVIEW_SUCCESS = "GET_BATCHVIEW_SUCCESS";

export const GET_MEMBERBYID_BEGIN = "GET_MEMBERBYID_BEGIN";
export const GET_MEMBERBYID_SUCCESS = "GET_MEMBERBYID_SUCCESS";

export const GET_ROOT_FOLDER = "GET_ROOT_FOLDER";
export const GET_FOLDER = "GET_FOLDER";

export const GET_GROUP_BEGIN = "GET_GROUP_BEGIN";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";

export const REMOVE_FROMGROUP_BEGIN = "REMOVE_FROMGROUP_BEGIN";
export const REMOVE_FROMGROUP_ERROR = "REMOVE_FROMGROUP_ERROR";
export const REMOVE_FROMGROUP_SUCCESS = "REMOVE_FROMGROUP_SUCCESS";
export const GET_COMPLETE_USER_BEGIN = "GET_COMPLETE_USER_BEGIN";
export const GET_COMPLETE_USER_SUCCESS = "GET_COMPLETE_USER_SUCCESS";

export const SETUP_NEWUSER_SUCCESS = "SETUP_NEWUSER_SUCCESS";
export const SETUP_NEWUSER_BEGIN = "SETUP_NEWUSER_BEGIN";
export const SETUP_NEWUSER_ERROR = "SETUP_NEWUSER_ERROR";

export const UPDATE_USERPASSWORD_BEGIN = "UPDATE_USERPASSWORD_BEGIN";
export const UPDATE_USERPASSWORD_SUCCESS = "UPDATE_USERPASSWORD_SUCCESS";
export const UPDATE_USERPASSWORD_ERROR = "UPDATE_USERPASSWORD_ERROR";
export const DELETE_EVENT_BEGIN = "DELETE_EVENT_BEGIN";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";

export const UPDATE_EVENT_BEGIN = "UPDATE_EVENT_BEGIN";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";
export const CREATE_SAVE_BEGIN = "CREATE_SAVE_BEGIN";
export const CREATE_SAVE_SUCCESS = "CREATE_SAVE_SUCCESS";
export const CREATE_SAVE_ERROR = "CREATE_SAVE_ERROR";

export const GET_SAVED_BEGIN = "GET_SAVED_BEGIN";
export const GET_SAVED_SUCCESS = "GET_SAVED_SUCCESS";

export const GET_VIEWBYID_BEGIN = "GET_VIEWBYID_BEGIN";
export const GET_VIEWBYID_SUCCESS = "GET_VIEWBYID_SUCCESS";
export const GET_MEMBERBYMONTH_BEGIN = "GET_MEMBERBYMONTH_BEGIN";
export const GET_MEMBERBYMONTH_SUCCESS = "GET_MEMBERBYMONTH_SUCCESS";

export const ADD_ATTENDANCE_BEGIN = "ADD_ATTENDANCE_BEGIN";
export const ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS";
export const ADD_ATTENDANCE_ERROR = "ADD_ATTENDANCE_ERROR";

export const SHOW_EVENTSSTATS_BEGIN = "SHOW_EVENTSSTATS_BEGIN";
export const SHOW_EVENTSSTATS_SUCCESS = "SHOW_EVENTSSTATS_SUCCESS";

export const GET_RECENTMEMBER_BEGIN = "GET_RECENTMEMBER_BEGIN";
export const GET_RECENTMEMBER_SUCCESS = "GET_RECENTMEMBER_SUCCESS";

export const DELETE_VIEW_BEGIN = "DELETE_VIEW_BEGIN";
export const DELETE_VIEW_ERROR = "DELETE_VIEW_ERROR";
export const DELETE_VIEW_SUCCESS = "DELETE_VIEW_SUCCESS";

export const GET_GIVING_BEGIN = "GET_GIVING_BEGIN";
export const GET_GIVING_SUCCESS = "GET_GIVING_SUCCESS";

export const GET_FUNDALL_BEGIN = "GET_FUNDALL_BEGIN";
export const GET_FUNDALL_SUCCESS = "GET_FUNDALL_SUCCESS";

export const GET_PRICING_BEGIN = "GET_PRICING_BEGIN";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";

export const CREATE_SUBSCRIPTION_BEGIN = "CREATE_SUBSCRIPTION_BEGIN";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";

export const GET_PRICING_BEGIN1 = "GET_PRICING_BEGIN1";
export const GET_PRICING_SUCCESS1 = "GET_PRICING_SUCCESS1";

export const STATUS_SUBSCRIPTION_BEGIN = "STATUS_SUBSCRIPTION_BEGIN";
export const STATUS_SUBSCRIPTION_SUCCESS = "STATUS_SUBSCRIPTION_SUCCESS";

export const UPDATE_ATTENDANCE_BEGIN = "UPDATE_ATTENDANCE_BEGIN";
export const UPDATE_ATTENDANCE_SUCCESS = "UPDATE_ATTENDANCE_SUCCESS";
export const UPDATE_ATTENDANCE_ERROR = "UPDATE_ATTENDANCE_ERROR";

export const GET_REPORT_BEGIN = "GET_REPORT_BEGIN";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";

export const SETUP_NEWPERMISSION_BEGIN = "SETUP_NEWPERMISSION_BEGIN";

export const SETUP_NEWPERMISSION_SUCCESS = "SETUP_NEWPERMISSION_SUCCESS";

export const SETUP_NEWPERMISSION_ERROR = "SETUP_NEWPERMISSION_ERROR";

export const GET_PERMISSION_BEGIN = "GET_PERMISSION_BEGIN";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";

export const CREATE_PRAYER_BEGIN = "CREATE_PRAYER_BEGIN";
export const CREATE_PRAYER_SUCCESS = "CREATE_PRAYER_SUCCESS";
export const CREATE_PRAYER_ERROR = "CREATE_PRAYER_ERROR";
export const GET_SAVEDPRAYER_BEGIN = "GET_SAVEDPRAYER_BEGIN";
export const GET_SAVEDPRAYER_SUCCESS = "GET_SAVEDPRAYER_SUCCESS";

export const DELETE_PRAYER_BEGIN = "DELETE_PRAYER_BEGIN";
export const DELETE_PRAYER_ERROR = "DELETE_PRAYER_ERROR";
export const DELETE_PRAYER_SUCCESS = "DELETE_PRAYER_SUCCESS";
export const UPDATE_PRAYER_BEGIN = "UPDATE_PRAYER_BEGIN";
export const UPDATE_PRAYER_SUCCESS = "UPDATE_PRAYER_SUCCESS";
export const UPDATE_PRAYER_ERROR = "UPDATE_PRAYER_ERROR";

export const CREATE_FORM_BEGIN = "CREATE_FORM_BEGIN";
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_ERROR = "CREATE_FORM_ERROR";

export const GET_FORMS_BEGIN = "GET_FORMS_BEGIN";
export const GET_FORMS_SUCCESS = "GET_FORMS_SUCCESS";

export const GET_FORMBYID_BEGIN = "GET_FORMBYID_BEGIN";
export const GET_FORMBYID_SUCCESS = "GET_FORMBYID_SUCCESS";

export const UPDATE_FORMRESPONSE_BEGIN = "UPDATE_FORMRESPONSE_BEGIN";
export const UPDATE_FORMRESPONSE_SUCCESS = "UPDATE_FORMRESPONSE_SUCCESS";
export const UPDATE_FORMRESPONSE_ERROR = "UPDATE_FORMRESPONSE_ERROR";

export const DELETE_FORM_BEGIN = "DELETE_FORM_BEGIN";
export const DELETE_FORM_ERROR = "DELETE_FORM_ERROR";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";

export const UPLOAD_MEMBER_MANY_BEGIN = "UPLOAD_MEMBER_MANY_BEGIN";
export const UPLOAD_MEMBER_MANY_SUCCESS = "UPLOAD_MEMBER_MANY_SUCCESS";
export const UPLOAD_MEMBER_MANY_ERROR = "UPLOAD_MEMBER_MANY_ERROR";

export const UPLOAD_GIVING_MANY_BEGIN = "UPLOAD_GIVING_MANY_BEGIN";
export const UPLOAD_GIVING_MANY_SUCCESS = "UPLOAD_GIVING_MANY_SUCCESS";
export const UPLOAD_GIVING_MANY_ERROR = "UPLOAD_GIVING_MANY_ERROR";
export const ADD_SETTINGS_BEGIN = "ADD_SETTINGS_BEGIN";
export const ADD_SETTINGS_ERROR = "ADD_SETTINGS_ERROR";
export const ADD_SETTINGS_SUCCESS = "ADD_SETTINGS_SUCCESS";
export const GET_SETTINGALL_BEGIN = "GET_SETTINGALL_BEGIN";
export const GET_SETTINGALL_SUCCESS = "GET_SETTINGALL_SUCCESS";
export const DELETE_SETTING_ERROR = "DELETE_SETTING_ERROR";
export const DELETE_SETTING_BEGIN = "DELETE_SETTING_BEGIN";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";

export const DELETE_FOLLOWUP_BEGIN = "DELETE_FOLLOWUP_BEGIN";
export const DELETE_FOLLOWUP_ERROR = "DELETE_FOLLOWUP_ERROR";
export const DELETE_FOLLOWUP_SUCCESS = "DELETE_FOLLOWUP_SUCCESS";

export const GET_ALLFOLLOWUP_BEGIN = "GET_ALLFOLLOWUP_BEGIN";
export const GET_ALLFOLLOWUP_SUCCESS = "GET_ALLFOLLOWUP_SUCCESS";

export const UPDATE_UPDATESETTINGS_BEGIN = "UPDATE_UPDATESETTINGS_BEGIN";
export const UPDATE_UPDATESETTINGS_SUCCESS = "UPDATE_UPDATESETTINGS_SUCCESS";
export const UPDATE_UPDATESETTINGS_ERROR = "UPDATE_UPDATESETTINGS_ERROR";

export const GET_EVENTBYVOLUNTEER_BEGIN = "GET_EVENTBYVOLUNTEER_BEGIN";
export const GET_EVENTBYVOLUNTEER_SUCCESS = "GET_EVENTBYVOLUNTEER_SUCCESS";

export const CREATE_SCHEDULE_BEGIN = "CREATE_SCHEDULE_BEGIN";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_ERROR = "CREATE_SCHEDULE_ERROR";

export const GET_SCHEDULE_BEGIN = "GET_SCHEDULE_BEGIN";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";

export const DELETE_SCHEDULE_BEGIN = "DELETE_SCHEDULE_BEGIN";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";

export const UPDATE_SCHEDULE_BEGIN = "UPDATE_SCHEDULE_BEGIN";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_ERROR = "UPDATE_SCHEDULE_ERROR";

export const GET_SCHEDULEBYID_BEGIN = "GET_SCHEDULEBYID_BEGIN";
export const GET_SCHEDULEBYID_SUCCESS = "GET_SCHEDULEBYID_SUCCESS";

export const DELETE_APPLICATION_BEGIN = "DELETE_APPLICATION_BEGIN";
export const DELETE_APPLICATION_ERROR = "DELETE_APPLICATION_ERROR";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";

export const GET_APPLICATION_BEGIN = "GET_APPLICATION_BEGIN";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";

export const GET_APPLICATIONBYID_BEGIN = "GET_APPLICATIONBYID_BEGIN";
export const GET_APPLICATIONBYID_SUCCESS = "GET_APPLICATIONBYID_SUCCESS";

export const GET_CHECKIN_BEGIN = "GET_CHECKIN_BEGIN";
export const GET_CHECKIN_SUCCESS = "GET_CHECKIN_SUCCESS";

export const CREATE_CHECKIN_BEGIN = "CREATE_CHECKIN_BEGIN";
export const CREATE_CHECKIN_SUCCESS = "CREATE_CHECKIN_SUCCESS";
export const CREATE_CHECKIN_ERROR = "CREATE_CHECKIN_ERROR";

export const GET_MAILCHIMP_BEGIN = "GET_MAILCHIMP_BEGIN";
export const GET_MAILCHIMP_SUCCESS = "GET_MAILCHIMP_SUCCESS";

export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_BEGIN = "CREATE_SERVICE_BEGIN";
export const CREATE_SERVICE_ERROR = "CREATE_SERVICE_ERROR";

export const GET_SERVICE_BEGIN = "GET_SERVICE_BEGIN";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";

export const DELETE_SERVICE_BEGIN = "DELETE_SERVICE_BEGIN";
export const DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";

export const UPDATE_SERVICE_BEGIN = "UPDATE_SERVICE_BEGIN";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_ERROR = "UPDATE_SERVICE_ERROR";

export const GET_SERVICEBYID_BEGIN = "GET_SERVICEBYID_BEGIN";
export const GET_SERVICEBYID_SUCCESS = "GET_SERVICEBYID_SUCCESS";

export const UPDATE_FORM_BEGIN = "UPDATE_FORM_BEGIN";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_ERROR = "UPDATE_FORM_ERROR";

export const GET_CONTAINER_BEGIN = "GET_CONTAINER_BEGIN";
export const GET_CONTAINER_SUCCESS = "GET_CONTAINER_SUCCESS";

export const GET_CONTAINERBYID_BEGIN = "GET_CONTAINERBYID_BEGIN";
export const GET_CONTAINERBYID_SUCCESS = "GET_CONTAINERBYID_SUCCESS";
export const EDIT_CONTAINER_BEGIN = "EDIT_CONTAINER_BEGIN";
export const EDIT_CONTAINER_SUCCESS = "EDIT_CONTAINER_SUCCESS";
export const EDIT_CONTAINER_ERROR = "EDIT_CONTAINER_ERROR";

export const GET_TOCONTAINER_BEGIN = "GET_TOCONTAINER_BEGIN";
export const GET_TOCONTAINER_SUCCESS = "GET_TOCONTAINER_SUCCESS";

export const ADD_QUICKTOCONTAINER_ERROR = "ADD_QUICKTOCONTAINER_ERROR";
export const ADD_QUICKTOCONTAINER_SUCCESS = "ADD_QUICKTOCONTAINER_SUCCESS";

export const SETUP_CONTAINER_BEGIN = "SETUP_CONTAINER_BEGIN";
export const SETUP_CONTAINER_SUCCESS = "SETUP_CONTAINER_SUCCESS";

export const BULK_EMAIL_BEGIN = "BULK_EMAIL_BEGIN";
export const BULK_EMAIL_SUCCESS = "BULK_EMAIL_SUCCESS";
export const BULK_EMAIL_ERROR = "BULK_EMAIL_ERROR";

export const BULK_SMS_BEGIN = "BULK_SMS_BEGIN";
export const BULK_SMS_SUCCESS = "BULK_SMS_SUCCESS";
export const BULK_SMS_ERROR = "BULK_SMS_ERROR";

export const ADD_WORKFLOW_BEGIN = "ADD_WORKFLOW_BEGIN";
export const ADD_WORKFLOW_SUCCESS = "ADD_WORKFLOW_SUCCESS";
export const ADD_WORKFLOW_ERROR = "ADD_WORKFLOW_ERROR";

export const GET_WORKFLOW_BEGIN = "GET_WORKFLOW_BEGIN";
export const GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS";

export const UPDATE_CAMPUS_BEGIN = "UPDATE_CAMPUS_BEGIN";
export const UPDATE_CAMPUS_SUCCESS = "UPDATE_CAMPUS_SUCCESS";
export const UPDATE_CAMPUS_ERROR = "UPDATE_CAMPUS_ERROR";

export const CREATE_CAMPUS_BEGIN = "CREATE_CAMPUS_BEGIN";
export const CREATE_CAMPUS_SUCCESS = "CREATE_CAMPUS_SUCCESS";
export const CREATE_CAMPUS_ERROR = "CREATE_CAMPUS_ERROR";

export const ADD_ONLINEGIVING_BEGIN = "ADD_ONLINEGIVING_BEGIN";

export const ADD_ONLINEGIVING_SUCCESS = "ADD_ONLINEGIVING_SUCCESS";

export const ADD_ONLINEGIVING_ERROR = "ADD_ONLINEGIVING_ERROR";

export const UPDATE_COLOR_BEGIN = "UPDATE_COLOR_BEGIN";
export const UPDATE_COLOR_SUCCESS = "UPDATE_COLOR_SUCCESS";
export const UPDATE_COLOR_ERROR = "UPDATE_COLOR_ERROR";

export const GET_ONLINEGIVING_BEGIN = "GET_ONLINEGIVING_BEGIN";
export const GET_ONLINEGIVING_SUCCESS = "GET_ONLINEGIVING_SUCCESS";

export const UPDATE_ONLINEGIVING_BEGIN = "UPDATE_ONLINEGIVING_BEGIN";

export const UPDATE_ONLINEGIVING_SUCCESS = "UPDATE_ONLINEGIVING_SUCCESS";

export const UPDATE_ONLINEGIVING_ERROR = "UPDATE_ONLINEGIVING_ERROR";
export const ADD_EXPENSE_BEGIN = "ADD_EXPENSE_BEGIN";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_ERROR = "ADD_EXPENSE_ERROR";

export const GET_EXPENSE_BEGIN = "GET_EXPENSE_BEGIN";
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS";

export const DELETE_EXPENSE_BEGIN = "DELETE_EXPENSE_BEGIN";
export const DELETE_EXPENSE_ERROR = "DELETE_EXPENSE_ERROR";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";

export const GET_EXPENSEALL_BEGIN = "GET_EXPENSEALL_BEGIN";
export const GET_EXPENSEALL_SUCCESS = "GET_EXPENSEALL_SUCCESS";

export const GET_EXPENSEDETAILS_BEGIN = "GET_EXPENSEDETAILS_BEGIN";
export const GET_EXPENSEDETAILS_SUCCESS = "GET_EXPENSEDETAILS_SUCCESS";

export const CHURCH_ACCOUNT_BEGIN = "CHURCH_ACCOUNT_BEGIN";
export const CHURCH_ACCOUNT_SUCCESS = "CHURCH_ACCOUNT_SUCCESS";
export const CHURCH_ACCOUNT_ERROR = "CHURCH_ACCOUNT_ERROR";

export const GET_LIVESTREAM_BEGIN = "GET_LIVESTREAM_BEGIN";
export const GET_LIVESTREAM_SUCCESS = "GET_LIVESTREAM_SUCCESS";

export const ADD_CHILDCHECKIN_BEGIN = "ADD_CHILDCHECKIN_BEGIN";
export const ADD_CHILDCHECKIN_SUCCESS = "ADD_CHILDCHECKIN_SUCCESS";
export const ADD_CHILDCHECKIN_ERROR = "ADD_CHILDCHECKIN_ERROR";

export const ADD_MINISTRY_BEGIN = "ADD_MINISTRY_BEGIN";
export const ADD_MINISTRY_SUCCESS = "ADD_MINISTRY_SUCCESS";
export const ADD_MINISTRY_ERROR = "ADD_MINISTRY_ERROR";

export const DELETE_MINISTRY_BEGIN = "DELETE_MINISTRY_BEGIN";
export const DELETE_MINISTRY_ERROR = "DELETE_MINISTRY_ERROR";
export const DELETE_MINISTRY_SUCCESS = "DELETE_MINISTRY_SUCCESS";

export const ADD_MINISTRY_FOLDER_BEGIN = "ADD_MINISTRY_FOLDER_BEGIN";
export const ADD_MINISTRY_FOLDER_SUCCESS = "ADD_MINISTRY_FOLDER_SUCCESS";
export const ADD_MINISTRY_FOLDER_ERROR = "ADD_MINISTRY_FOLDER_ERROR";

export const DELETE_MINISTRY_FOLDER_BEGIN = "DELETE_MINISTRY_FOLDER_BEGIN";
export const DELETE_MINISTRY_FOLDER_ERROR = "DELETE_MINISTRY_FOLDER_ERROR";
export const DELETE_MINISTRY_FOLDER_SUCCESS = "DELETE_MINISTRY_FOLDER_SUCCESS";

export const UPDATE_MINISTRY_BEGIN = "UPDATE_MINISTRY_BEGIN";
export const UPDATE_MINISTRY_ERROR = "UPDATE_MINISTRY_ERROR";
export const UPDATE_MINISTRY_SUCCESS = "UPDATE_MINISTRY_SUCCESS";

export const UPDATE_MINISTRY_FOLDER_BEGIN = "UPDATE_MINISTRY_FOLDER_BEGIN";
export const UPDATE_MINISTRY_FOLDER_ERROR = "UPDATE_MINISTRY_FOLDER_ERROR";
export const UPDATE_MINISTRY_FOLDER_SUCCESS = "UPDATE_MINISTRY_FOLDER_SUCCESS";
