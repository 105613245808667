import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../context/appContext";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Select from "react-select";

import { Container, Col } from "react-bootstrap";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import Modal from "react-bootstrap/Modal";
import nodd from "../../../assets/newi/nodd.png";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import he from "he";
import StarRating from "../../../components/Star/StarRating";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const GoToClass = () => {
  const quillRef = useRef(null);
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    logoutUser,
    showAlert,
    getCompleteMembers,
    completemembers,
    events,
    getEvents,
    user,
    childCheckin,

    displayAlert,
  } = useAppContext();

  useEffect(() => {
    getClassroomByID();
    getChildxx();
  }, []);
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const [fullscreen, setFullscreen] = useState(true);

  const [classid, setClassid] = useState("");
  const [event, setEvent] = useState(null);

  const [childData, setChildData] = useState([]);
  const [childName, setChildName] = useState("");
  const [parentName, setParentName] = useState("Enoor");
  const [teacherName, setTeacherName] = useState("");

  const defaultTextx = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
          }
          .email-container {
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
              background-color: #ffffff;
              border: 1px solid #dddddd;
          }
          .email-header {
              text-align: center;
              background-color: #004080;
              color: #ffffff;
              padding: 10px 0;
          }
          .email-body {
              margin: 20px 0;
          }
          .email-footer {
              margin-top: 20px;
              font-size: 14px;
              color: #555555;
          }
          h2 {
              color: #333333;
              font-size: 18px;
              margin-bottom: 10px;
          }
          p {
              color: #666666;
              font-size: 16px;
              line-height: 1.5;
              margin-bottom: 10px;
              margin-top: 0; /* Remove top margin to prevent excessive spacing */
          }
      </style>
  </head>
  <body>
      <div class="email-container">
          <div class="email-body">
              <p style="margin-bottom: 10px; margin-top: 0;">Dear ${parentName},</p>
  
              <p style="margin-bottom: 10px; margin-top: 0;">This is a summary of your child's activities and progress in our church's children's program for the past month:</p>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Participation Overview</h2>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Educational Highlights</h2>
              <p style="margin-bottom: 10px; margin-top: 0;">Last month, we focused on learning about important topics that help in the growth and understanding of spiritual and educational values.</p>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Behavioral Insights</h2>
              <p style="margin-bottom: 10px; margin-top: 0;">We are pleased to report positive developments in various areas of your child's behavior.</p>
              <p style="margin-bottom: 10px; margin-top: 0;">There are also areas where improvement can be encouraged, and we will continue to work together to support this growth.</p>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Upcoming Events and Preparation</h2>
              <p style="margin-bottom: 10px; margin-top: 0;">Please prepare for upcoming events that are designed to further your child's learning and spiritual development.</p>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Spiritual Growth</h2>
              <p style="margin-bottom: 10px; margin-top: 0;">At home, you might discuss topics related to their recent learnings to reinforce their spiritual growth.</p>
  
              <h2 style="margin-bottom: 10px; margin-top: 0;">Feedback</h2>
              <p style="margin-bottom: 10px; margin-top: 0;">If you have any questions or would like to discuss your child's progress further, please feel free to contact us at any time.</p>
  
              <p style="margin-bottom: 10px; margin-top: 0;">Warm regards,</p>
              <p style="margin-bottom: 10px; margin-top: 0;">Mrs Zion</p>
          </div>
          <div class="email-footer">
              <p style="margin-bottom: 10px; margin-top: 0;">This email was sent to you by Churchease. If you have any questions, please feel free to contact us at support@churchease.com.</p>
          </div>
      </div>
  </body>
  </html>
  `;

  const modules = {
    toolbar: {
      container: "#toolbar",
      "custom-dropdown": ["option1", "option2", "option3"],

      // Linking to our custom toolbar
    },
  };

  const colorOptions = [
    { value: "white", label: "White" },
    { value: "purple", label: " Purple" },
    { value: "orange", label: " Orange" },
    { value: "brown", label: " Brown" },
    { value: "black", label: "Black" },
    { value: "lightyellow", label: "Light Yellow" },
    { value: "lightblue", label: "Light Blue" },
    { value: "lightgreen", label: "Light Green" },
    { value: "lightcoral", label: "Light Coral" },
    { value: "lightpink", label: "Light Pink" },
    { value: "lightgray", label: "Light Gray" },
    { value: "lightsalmon", label: "Light Salmon" },
    { value: "lightseagreen", label: "Light Sea Green" },
    { value: "lightcyan", label: "Light Cyan" },

    // Add more colors as needed
  ];

  // const [defaultText, setDefaultText] = useState(defaultTextx);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value,
      color: state.data.value === "white" ? "black" : "white",
    }),
    // Add more custom styles if needed
  };

  const [isChecked, setIsChecked] = useState(childData.publishReport || false);
  // const [childName, setChildName] = useState("Chris");

  const [editorText, setEditorText] = useState(defaultTextx);

  const getClassroomByID = async () => {
    try {
      const response = await authFetch.get(`/children/getclassroom/${id}`);
      console.log(response);
      if (response && response.data) {
        setClassid(response.data);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching classroom data:", error);
    }
  };

  const getChildxx = async () => {
    try {
      const { data } = await authFetch.get(`/children/getchildren/${id}`);

      if (data) {
        setChildData(data);
      } else {
        console.error("Unexpected response structure:", data);
      }
    } catch (error) {
      console.error("Error fetching classroom data:", error);
    }
  };

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    events && getEvents();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [error, setError] = useState(false);
  const [childId, setChildId] = useState("");

  const [childHobby, setChildHobby] = useState("");
  const [showAlertx, setShowAlertx] = useState(false);
  const [show1, setShow1] = useState(false);
  const [clicked, setClicked] = useState("");

  useEffect(() => {
    if (quillRef.current && editorText !== null) {
      const quill = quillRef.current.getEditor();

      // Check if the content is different before updating
      if (quill.root.innerHTML !== editorText) {
        const range = quill.getSelection(); // Save the current cursor position
        quill.clipboard.dangerouslyPasteHTML(editorText);

        if (range) {
          // Restore the saved cursor position
          quill.setSelection(range.index, range.length);
        }
      }
    }
  }, [editorText]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!childName) {
      toast(
        <CustomToastContent title="Error" message="Child Name is required" />
      );
      return;
    }

    const data = {
      childName: childName,
      childHobby: childHobby,
      className: classid.className,
      classId: classid._id,
      childId: selectedChildId,
      childParentId: selectedChildren,
      teacherId: user._id,
    };

    const response = await authFetch.post("/children/addchild", data);

    if (response.status === 200) {
      setChildName("");
      setChildHobby("");
      setSelectedChildId("");
      setSelectedChildren([]);
      setFamilyValue([]);

      getChildxx();
      handleClose();
      toast(
        <CustomToastContent
          title="Success"
          message="Child added successfully"
        />
      );
    }
  };
  const showData = (e, childD) => {
    e.preventDefault();
    setShow3(true);
    setClicked(childD);
  };
  const showRemove = (e, childD) => {
    e.preventDefault();

    setShow4(true);
    setClicked(childD._id);
  };

  // useEffect(() => {
  //   // Decode HTML entities when the component mounts or childData changes
  //   if (clicked) {
  //     const decodedText = classid.monthlyReport
  //       ? he.decode(classid.monthlyReport)
  //       : editorText;
  //     setEditorText(decodedText);
  //   }
  // }, []);

  const checkin = async (e, childD) => {
    e.preventDefault();
    const currentTime = new Date().toISOString();
    if (!event) {
      // displayAlert();
      toast(
        <CustomToastContent title="Error" message="Please select an event" />
      );
      // setShowAlertx("Please select an event to check in.");
      return;
    }
    const data = {
      mid: childD._id,
      selectedName: childD.childName,
      // eventid: event,
      classroomId: id,
      churchName: user.churchName,
      eventTitle: event.eventTitle,
      eventId: event.eventId,
      event_id: event._id,
      checkIn: currentTime,
      //event: event,
      eventDay: event.eventDay,
    };

    childCheckin(data);
  };

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    const selected = events.find((e) => e._id === eventId);
    setEvent(selected); // set the entire event object
  };

  const [messageSubject, setMessageSubject] = useState("");
  const saveReportTemplate = async () => {
    try {
      const datax = {
        monthlyReport: editorText,
        publishReport: isChecked,
        classid: id,
        messageSubject: messageSubject,
      };

      const response = await authFetch.patch(`/children/teacherreport/${id}`, {
        datax,
      });

      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="Report saved successfully"
          />
        );

        // handleClose6();
      }
    } catch (error) {
      toast(
        <CustomToastContent title="Error" message="Failed to save report" />
      );
    }
  };

  const [showReport, setShowReport] = useState(false);
  const closeReport = () => {
    setShowReport(false);
  };

  const handleSendReport = async () => {
    setShowReport(true);
  };

  const sendReportToParents = async () => {
    try {
      if (!messageSubject) {
        toast(
          <CustomToastContent title="Error" message="Subject is required" />
        );
        return;
      }
      const datax = {
        monthlyReport: editorText,
        messageSubject: messageSubject,
        classid: id,
        churchid: classid.churchId,
      };

      const response = await authFetch.post(`/email/sendreport/${id}`, {
        datax,
      });

      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="Notification sent successfully"
          />
        );

        closeReport();
      }
    } catch (error) {
      toast(
        <CustomToastContent title="Error" message="Failed to send report" />
      );
    }
  };

  const [classActivity, setClassActivity] = useState("");
  const [rating, setRating] = useState(0);

  const handleRating = (rating) => {
    setRating(rating);
  };

  const handleArchievement = async (e) => {
    e.preventDefault();
    if (!classActivity) {
      toast(
        <CustomToastContent title="Error" message="Activity Name is required" />
      );
      return;
    }

    const data = {
      activityName: classActivity,
      rating: rating,
      classId: id,
      childId: clicked._id,
    };

    const response = await authFetch.post("/children/addachievement", data);
    setClassActivity("");
    setRating(0);
    getChildxx();
    handleClose3();
    toast(
      <CustomToastContent
        title="Success"
        message="Achievement added successfully"
      />
    );
  };
  useEffect(() => {
    getAssessment();
    fetchData();
  }, []);
  const [assessments, setAssessments] = useState([]);
  const [assignNote, setAssignNote] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const getAssessment = async () => {
    try {
      const data = await authFetch.get("/children/assessment");

      if (data.status === 200 && data.data) {
        setAssessments(data.data);
      }
    } catch (error) {
      console.error("Failed to retrieve assessment data:", error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await authFetch.get("/children/getclassrooms");
      // Assuming the actual data is in `response.data.result` based on typical Axios response structure

      if (response && response.data) {
        setClassrooms(response.data);
      } else {
        // Handle the case where the response does not have the expected structure
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // Handle errors from the fetch operation
      console.error("Error fetching classroom data:", error);
    }
  };

  const handleAssignAssessment = async (e) => {
    e.preventDefault();
    if (!selectedAssessment) {
      displayAlert();
      return;
    }

    const data = {
      note: assignNote,
      selectedAssessmentId: selectedAssessment,
      childId: childData,
      classId: id,
    };

    await authFetch.patch("/children/assignassessment", data);
    setAssignNote("");
    getChildxx();
    handleClose();
  };

  // Depend on childName and the original list of members

  const [removeFromClass, setRemoveFromClass] = useState("");
  const [selectedClassroom, setSelectedClassroom] = useState("");

  const handleRemoveFromClass = async (e, childId) => {
    e.preventDefault();
    const dataxxx = {
      selectedClassroom: selectedClassroom, // ID of the new classroom for transfer
      currentClassroom: id, // ID of the current classroom from which to remove
    };

    try {
      if (removeFromClass === "remove") {
        // Patch request to remove the child from the current classroom
        const response = await authFetch.patch(
          `/children/removechild/${clicked}`,
          dataxxx
        );

        if (response.status === 200 || response.status === 201) {
          setShow4(false);
          setChildData(childData.filter((child) => child._id !== clicked));
          toast(<CustomToastContent title="Success" message="Child removed" />);
        }

        //   setChildData(childData.filter((child) => child._id !== clicked));
        //  setShow4(false);
        getChildxx();
      } else {
        // Patch request to transfer the child to a new classroom
        const response = await authFetch.patch(
          `/children/transferchild/${clicked}`,
          dataxxx
        );
        if (response.status === 200 || response.status === 201) {
          setShow4(false);
          setChildData(childData.filter((child) => child._id !== clicked));
          toast(
            <CustomToastContent title="Success" message="Child transferred" />
          );
        }
      }

      // Refetch the data to update the local view
      fetchData();

      // Additional operations after the child is removed or transferred
      getChildxx();
    } catch (error) {
      console.error(
        "Failed to remove or transfer child from class:",
        error.message
      );
    }
  };
  const [loading, setIsLoading] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState("");
  const [familyValue, setFamilyValue] = useState([]);

  const getFamilyId = async (childId) => {
    if (childId) {
      setIsLoading(true); // Set loading true
      try {
        const { data } = await authFetch(
          `/members/getfamilydetailssssss/${childId}`
        );
        if (data) {
          setFamilyValue(data);
          setIsLoading(false); // Set loading false
        }
      } catch (error) {
        console.error("Error fetching family ID:", error);
      }
    } else {
      console.error("No child ID provided");
    }
  };
  const [selectedChildren, setSelectedChildren] = useState([]);
  const toggleChildSelection = (childId) => {
    setSelectedChildren((prevSelected) => {
      if (prevSelected.includes(childId)) {
        return prevSelected.filter((id) => id !== childId);
      } else {
        return [...prevSelected, childId];
      }
    });
  };
  // console.log(childData);
  const [selectedAssessmentData, setSelectedAssessmentData] = useState("");

  const handleSelectChild = async (itemId, fullName) => {
    setSelectedChildId(itemId);
    setFamilyValue([]); // Clear the existing family data right before fetching new
    await getFamilyId(itemId); // Assume this function fetches and then sets the new family data
    setChildName(fullName);
  };

  const viewProfile = (e, childD) => {
    e.preventDefault();
    console.log(childD);
    navigate(`/auth/peopleview/${childD.childId}`);
  };

  const assignAssignment = async (e) => {
    e.preventDefault();

    if (selectedAssessmentData) {
      const data = {
        selectedAssessmentId: selectedAssessmentData,
        // childData: childData._id,
        classId: id,
      };

      try {
        // Assuming you are sending data which generally should be done via POST or PATCH
        const response = await authFetch.post(
          "/children/assignassessmenttask",
          data
        );
        if (response.status === 200) {
          toast(
            <CustomToastContent
              title="Success"
              message="Assignment added successfully"
            />
          );
          getChildxx(); // Fetch updated children list
          handleClose5(); // Close modal or any open component
        }
      } catch (error) {
        toast(
          <CustomToastContent title="Error" message="Failed to assign task" />
        );
        // Optionally handle the error in UI, like showing a message to the user
      }
    } else {
      toast(
        <CustomToastContent
          title="Error"
          message="Please select an assessment"
        />
      );
    }
  };
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (completemembers) {
      const roles = ["Child", "Teenager", "Youth"]; // Define the roles to filter by
      const filtered = completemembers.filter(
        (member) => roles.includes(member.familyRole) // Check if the member's role is one of the specified roles
      );
      setFilteredData(filtered);
    }
  }, [completemembers]); // This effect runs whenever completemembers changes

  useEffect(() => {
    if (familyValue) {
      const selected = familyValue.map((item) => item._id);
      setSelectedChildren(selected);
    }
  }, [familyValue]);
  console.log(classid);
  useEffect(() => {
    if (classid.monthlyReport) {
      const decodedText = he.decode(classid.monthlyReport);
      setEditorText(decodedText);
      setMessageSubject(classid.messageSubject);
    }
  }, [classid.monthlyReport]);

  const insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill.getSelection()?.index ?? 0;
      quill.insertText(cursorPosition, placeholder);
      quill.setSelection(cursorPosition + placeholder.length);
    }
  };

  return (
    <>
      <Container fluid>
        {/* {showAlertx && <div className="inlineerror">{showAlertx}</div>} */}
        {showAlert && <Alert />}
        <Row>
          <div className="flex align-center justify-between w-100 mb-3 mt-5">
            <p className="lscal">
              Welcome, {user.mfirstName} {user.mlastName}{" "}
            </p>
            <p className="lscal">{childData.className}</p>
          </div>
          <div className="bnmjo" style={{ paddingBottom: "2rem" }}>
            <button className="jiluil3" onClick={handleShow}>
              Add Child
            </button>

            <button className="jiluil" onClick={handleShow5}>
              Assign Task
            </button>
            <button
              className="jiluil4"
              onClick={() => {
                handleShow6();
                if (classid.monthlyReport) {
                  const decodedText = he.decode(classid.monthlyReport);
                  setEditorText(decodedText); // Ensure it updates before opening the modal
                }
              }}
            >
              Parent Notification
            </button>
          </div>

          <Form.Group
            className="mb-5 mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
            controlId="formBasicCheckbox"
          >
            <Form.Select
              aria-label="Default select example"
              value={event ? event._id : ""}
              onChange={handleEventChange}
              style={{ width: "50%", height: "2.5rem", fontSize: "1em" }}
            >
              <option value="">Select an event to Check In</option>
              {events
                .filter((e) => new Date(e.eventDay) >= new Date()) // Filter out past events
                .sort((a, b) => new Date(a.eventDay) - new Date(b.eventDay))
                .slice(0, 10)
                .map((event, index) => (
                  <option key={event._id} value={event._id}>
                    {event.eventTitle} -{" "}
                    {moment(event.eventDay).format("MMMM Do YYYY")}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Row>

        {childData.length > 0 ? (
          <Row className="fundc2">
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>NAME</th>
                  {/* <th style={{ fontSize: "1em" }}>CLASS DESCRIPTION</th> */}
                  <th style={{ fontSize: "1em" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {childData
                  .sort((a, b) => {
                    if (a.childName.toLowerCase() < b.childName.toLowerCase())
                      return -1;
                    if (a.childName.toLowerCase() > b.childName.toLowerCase())
                      return 1;
                    return 0;
                  })
                  .map((childD) => (
                    <tr key={childD._id}>
                      <td style={{ fontSize: "1em" }}>{childD.childName}</td>
                      <td
                        style={{
                          gap: "10px",
                          display: "flex",
                          fontSize: "1em",
                        }}
                      >
                        <button
                          className="jiluil"
                          onClick={(e) => viewProfile(e, childD)}
                        >
                          View Profile
                        </button>
                        <button
                          className="jiluil4"
                          onClick={(e) => checkin(e, childD)}
                        >
                          Check In
                        </button>

                        <button
                          className="jiluil3"
                          onClick={(e) => showData(e, childD)}
                        >
                          Achievements
                        </button>
                        <button
                          className="jiluil"
                          style={{ backgroundColor: "red" }}
                          onClick={(e) => showRemove(e, childD)}
                        >
                          Remove From Class
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <div className="noData">
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Add Child
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && (
              <p className="inlineerror">Child Name is required to proceed</p>
            )}

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Type Name"
                value={childName}
                onChange={(e) => {
                  setChildName(e.target.value);
                }}
                name="className"
                required
              />
              {childName && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "#fff", // Added for better visibility
                  }}
                >
                  {filteredData
                    .filter((item) => {
                      const searchTerm = childName.toLowerCase();
                      const fullName = `${item.mfirstName?.toLowerCase()} ${item.mlastName?.toLowerCase()}`;
                      return (
                        fullName.includes(searchTerm) && fullName !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item) => {
                      const fullName = `${item.mfirstName} ${item.mlastName}`;
                      return (
                        <div
                          onClick={() => handleSelectChild(item._id, fullName)}
                          className="dropdownmy-row"
                          style={{ width: "100%", cursor: "pointer" }}
                          key={item._id}
                        >
                          {fullName}
                        </div>
                      );
                    })}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="childHobby">
              <Form.Label>Child Hobby</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Hobby"
                value={childHobby}
                onChange={(e) => setChildHobby(e.target.value)}
                name="className"
                required
              />
            </Form.Group>
          </Form>
          {/* <p className="smalltext">Select parents to be notified to</p> */}
          <tbody>
            {familyValue && familyValue.length > 0 ? (
              familyValue
                .filter((child) => child._id !== selectedChildId)
                .map((child) => (
                  <tr key={child._id}>
                    <td>
                      <div className="flex align-items-center gap-3">
                        <input
                          type="checkbox"
                          checked={selectedChildren.includes(child._id)}
                          onChange={() => toggleChildSelection(child._id)}
                        />
                        <span style={{ fontSize: "1em", paddingLeft: "1rem" }}>
                          {child.mfirstName} {child.mlastName}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <>
                <tr>
                  <td colSpan="2">
                    {/* Provide feedback when data is loading or no data is available */}
                    {/* {familyValue.length === 0 && !loading
                    ? "No children available or no matches found."
                    : "Loading..."} */}
                    {familyValue.length === 0 && !loading ? (
                      <p style={{ color: "var(--primary-color)" }}>
                        Searching for family members...
                      </p>
                    ) : (
                      <p tyle={{ color: "var(--primary-color)" }}>
                        No children available or no matches found.
                      </p>
                    )}
                  </td>
                </tr>
                <hr></hr>
                <tr>
                  <td>
                    {" "}
                    <p
                      style={{
                        color: "red",

                        fontSize: "0.9em",
                      }}
                    >
                      Note: Family Members must be identified before adding a
                      child.
                    </p>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="jiluil"
            onClick={handleSubmit}
            disabled={familyValue.length === 0}
          >
            Assign to Class
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show6} size="xl" onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Parent Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              value={messageSubject}
              onChange={(e) => setMessageSubject(e.target.value)}
              required
              style={{ fontSize: "1em" }}
            />
          </Form.Group>

          <div id="toolbar" className="flex items-center">
            <button className="ql-bold">Bold</button>
            <button className="ql-italic">Italic</button>
            <button className="ql-underline">Underline</button>
            <button className="ql-header" value="1">
              Header 1
            </button>
            <button className="ql-header" value="2">
              Header 2
            </button>
            <button className="ql-list" value="ordered">
              Ordered List
            </button>
            <button className="ql-list" value="bullet">
              Bullet List
            </button>
            <button className="ql-align" value=""></button>
            <button className="ql-align" value="center"></button>
            <button className="ql-align" value="right"></button>
            <button className="ql-align" value="justify"></button>
            <select className="ql-color">
              <option value="red">Red</option>
              <option value="green">Green</option>
              <option value="blue">Blue</option>
              <option value="black">Black</option>
              <option value="yellow">Yellow</option>
              <option value="orange">Orange</option>
              <option value="purple">Purple</option>
              <option value="pink">Pink</option>
              <option value="gray">Gray</option>
              <option value="pink">Pink</option>
              <option value="brown">Brown</option>
              <option value="grey">Grey</option>
              <option value="white">White</option>
              {/* Add more colors as needed */}
            </select>

            <select className="ql-size">
              <option value="small">Small</option>
              <option selected>Normal</option>
              <option value="large">Large</option>
              <option value="huge">Huge</option>
            </select>
            {/* <button className="ql-link">Link</button>
            <button className="ql-image">Image</button> */}
          </div>
          <ReactQuill
            value={editorText}
            ref={quillRef}
            onChange={(newText) => setEditorText(newText)}
            theme="snow"
            height="500px"
            className="react-quill-editor"
            modules={modules}
            style={{
              minHeight: "400px",
              marginBottom: "0",
            }}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <button className="jiluil3" onClick={handleClose6}>
              Cancel
            </button>
          </div>
          <div className="flex gap-2">
            <button
              className="jiluil1"
              onClick={() => insertPlaceholder(`[ChildFirstName]`)}
            >
              Child First Name
            </button>
            <button
              className="jiluil1"
              onClick={() => insertPlaceholder(`[ChildLastName]`)}
            >
              Child Last Name
            </button>
            <button
              className="jiluil1"
              onClick={() => insertPlaceholder(`[ParentFirstName]`)}
            >
              Parent First Name
            </button>
            <button
              className="jiluil1"
              onClick={() => insertPlaceholder(`[ParentLastName]`)}
            >
              Parent Last Name
            </button>
          </div>
          <div className="flex gap-2">
            <button className="jiluil" onClick={saveReportTemplate}>
              Save
            </button>
            <button className="jiluil4" onClick={handleSendReport}>
              Send to Parents
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Achievement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Activity Name</Form.Label>
            <Form.Control
              type="text"
              value={classActivity}
              onChange={(e) => setClassActivity(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="topic">
            <StarRating onRating={handleRating} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose3}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleArchievement}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Child</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="title">
            {/* <Form.Label>Activity Name</Form.Label> */}
            <Form.Select
              aria-label="Default select example"
              value={removeFromClass}
              onChange={(e) => setRemoveFromClass(e.target.value)}
            >
              <option>Select an action</option>
              <option value="remove">Remove from this class</option>
              <option value="transfer">Transfer to another class</option>
            </Form.Select>

            {removeFromClass === "transfer" && (
              <Form.Select
                aria-label="Default select example"
                className="mt-3"
                value={selectedClassroom}
                onChange={(e) => setSelectedClassroom(e.target.value)}
              >
                <option>Select a class</option>
                {classrooms.map((classroom) => (
                  <option key={classroom._id} value={classroom._id}>
                    {classroom.className}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose4}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleRemoveFromClass}>
            Remove
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="title">
            <Form.Select
              aria-label="Default select example"
              value={selectedAssessmentData}
              onChange={(e) => setSelectedAssessmentData(e.target.value)}
            >
              <option>Select an assessment</option>
              {assessments.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.title}
                </option>
              ))}
            </Form.Select>

            <p className="smalltext">
              This assessment will be assigned to every member of this class.
            </p>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose5}>
            Cancel
          </button>
          <button className="jiluil" onClick={assignAssignment}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReport} onHide={closeReport}>
        <Modal.Header closeButton>
          <Modal.Title>Send Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to send this report to parents?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={closeReport}>
            Cancel
          </button>
          <button className="jiluil" onClick={sendReportToParents}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GoToClass;
