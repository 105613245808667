import React from "react";

import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { FaPeopleArrows, FaUserAlt } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import "./product.css";
import b1 from "../../assets/newi/b1.png";
import b2 from "../../assets/newi/b2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductGroup = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Group Management | Churchease - Enhance Collaboration and Engagement
        </title>
        <meta
          name="description"
          content="Optimize group interactions with Churchease. Our system enhances collaboration by managing roles and activities, ensuring seamless updates and a cohesive group environment."
        />
        <meta
          property="og:title"
          content="Group Management | Churchease - Enhance Collaboration and Engagement"
        />
        <meta
          property="og:description"
          content="Optimize group interactions with Churchease. Our system enhances collaboration by managing roles and activities, ensuring seamless updates and a cohesive group environment."
        />
        <meta
          property="og:url"
          content="https://churchease.com/product/groups"
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://churchease.com/product/groups" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro">
            <div>
              <FaPeopleArrows size={100} />
              <p className="headssc">Group</p>
              <p className="headpp">
                Optimize group interactions with ease. Our system enhances
                collaboration by managing member roles and activities
                effectively. The intuitive interface ensures seamless updates,
                fostering a dynamic and cohesive group environment.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for group management
            </p>
            <p className="product13">
              Your comprehensive solution to organize, manage, and engage all
              church groups, enhancing communication and community involvement.
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={b1} alt="Group" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">GROUP CREATION & MANAGEMENT</p>
              <p className="proddz1">
                Easily create and manage church groups, from small study groups
                to large service teams, with tools designed to streamline group
                administration.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MEMBER ASSIGNMENT</p>
              <p className="proddz1">
                Assign members to specific groups based on their interests,
                skills, or geographic location, ensuring everyone finds their
                best fit.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">COMMUNICATION TOOLS</p>
              <p className="proddz1">
                Facilitate communication within groups through integrated
                messaging systems, enabling efficient coordination and
                information sharing.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain">
          <Col>
            <div className="productdiv">
              <p className="proddz">EVENT SCHEDULING</p>
              <p className="proddz1">
                Schedule and manage group events, meetings, and gatherings
                directly from the platform, with calendar integration to keep
                everyone on track.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ATTENDANCE TRACKING</p>
              <p className="proddz1">
                Monitor and record attendance for group activities, providing
                valuable data to track engagement and participation rates.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RESOURCE SHARING</p>
              <p className="proddz1">
                Share resources such as documents, schedules, and training
                materials easily among group members, enhancing collaboration
                and support.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={b2} alt="Group" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#909fdb" />
      <Footer />
    </>
  );
};

export default ProductGroup;
