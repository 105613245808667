import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import AddLessonForm from "../../../components/AddLessonForm/AddLessonForm";
import { useNavigate, useParams } from "react-router-dom";
import AssignCourse from "../../../components/AssignCourse/AssignCourse";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";

import { useAppContext } from "../../../context/appContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

const CourseView = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  // for lessons
  const [visible, setVisible] = useState(false);
  const [noVideo, setNoVideo] = useState(false);
  const [values, setValues] = useState({
    title: "",
    content: "",
    video: {},
  });
  const [students, setStudents] = useState(0);

  const [selectedName, setSelectedName] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Video");
  const [progress, setProgress] = useState(0);
  const { logoutUser, user, completemembers, getCompleteMembers } =
    useAppContext();
  const { slug } = useParams();

  useEffect(() => {
    getCompleteMembers();
  }, []);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    loadCourse();
  }, [slug]);

  const loadCourse = useCallback(async () => {
    try {
      const { data } = await authFetch.get(`/learning/course/${slug}`);
      setCourse(data);
    } catch (error) {
      // Handle any errors here
    }
  }, [authFetch, setCourse, slug]);

  useEffect(() => {
    loadCourse();
    return () => {};
  }, []);

  const fetchCourse = async () => {
    let { data } = await authFetch.get(`/learning/course/${slug}`);

    setCourse(data);
  };

  const studentCount = async () => {
    const { data } = await authFetch.post(`/learning/student-count`, {
      courseId: course._id,
    });

    setStudents(data.length);
  };

  useEffect(() => {
    course && studentCount();
  }, [course]);

  useEffect(() => {
    if (slug) fetchCourse();
  }, [slug]);

  const navigatetoEdit = () => {
    // 👇️ navigate to /contacts
    navigate("/auth/courseedit/" + slug);
  };

  // FUNCTIONS FOR ADD LESSON
  const handleAddLesson = async (e) => {
    e.preventDefault();

    try {
      const { data } = await authFetch.post(
        `/learning/course/lesson/${slug}/${course._id}`,
        values
      );

      setValues({ ...values, title: "", content: "", video: {} });
      setVisible(false);
      setUploadButtonText("Upload video");
      setCourse(data);
      handleClose2();
      progress === 100 && setProgress(0);
    } catch (err) {
      toast("Lesson add failed");
    }
  };

  const handleVideo = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        toast("No file selected");
        return;
      }

      setUploadButtonText(file.name);
      setUploading(true);

      const videoData = new FormData();
      videoData.append("video", file);
      // Ensure 'video' matches the field name expected by Multer

      // Sending video as form data to the backend
      try {
        const { data } = await authFetch.post(
          "/learning/course/video-upload",
          videoData,
          {
            headers: {
              // Don't set Content-Type header, the browser will set it with the correct boundary
              Accept: "application/json", // Assuming you expect a JSON response
            },
            onUploadProgress: (e) => {
              setProgress(Math.round((100 * e.loaded) / e.total));
            },
          }
        );

        setValues({ ...values, video: data });
      } catch (uploadErr) {
        console.error("Upload error:", uploadErr);
        toast("Video upload failed");
      }
    } catch (err) {
      console.error("Error:", err);
      setUploading(false);
    } finally {
      setUploading(false); // Ensure uploading is set to false in any case
    }
  };

  const handleVideoRemove = async () => {
    try {
      setUploading(true);

      await authFetch.post(`/learning/course/video-remove`, values.video);
      setValues({ ...values, video: {} });
      setUploading(false);
      setUploadButtonText("Upload another video");
      setProgress(0);
    } catch (err) {
      setUploading(false);
      toast("Video remove failed");
    }
  };

  const handlePublish = async () => {
    try {
      const { data } = await authFetch.put(
        `/learning/course/publish/${course._id}`
      );

      handleClose3();
      //toast.success("Course Published"); // Using toast.success to show success messages
      toast(
        <CustomToastContent
          title="Success"
          message="Course Published"
          type="success"
        />
      );

      setCourse(data);
    } catch (err) {
      toast.error("Course publish failed. Try again"); // Using toast.error to show error messages
    }
  };

  const handleUnpublish = async () => {
    // return;
    try {
      const { data } = await authFetch.put(
        `/learning/course/unpublish/${course._id}`
      );
      handleClose4();
      setCourse(data);
      // toast.success("Course Unpublished"); // Using toast.success to show success messages
      toast(
        <CustomToastContent
          title="Success"
          message="Course Unpublished"
          type="success"
        />
      );
    } catch (err) {
      toast.error("Course unpublish failed. Try again");
    }
  };

  const handleDeleteLesson = async (index) => {
    let allLessons = course.lessons;
    // const removed = allLessons.splice(index, 1);
    // remove previous video
    const removed = allLessons.splice(index2, 1);
    if (removed && removed.length && removed[0].video) {
      await authFetch.post(
        `/learning/course/remove-video/${course._id}`,
        removed[0].video
      );
      handleClose1();
    }

    setValues({ ...course, lessons: allLessons });

    const { data } = await authFetch.post(
      `/learning/course/${course._id}/${removed[0]._id}`
    );
    if (data.ok)
      toast(
        <CustomToastContent
          title="Success"
          message="Lesson Deleted"
          type="success"
        />
      );
    handleClose1();
  };

  const [selectedItem, setSelectedItem] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const previewVideo = async (index, item) => {
    setSelectedItem(item);
    setShow(true);
  };
  const [index2, setIndex2] = useState();
  const deleteThisLesson = (index, item) => {
    setShow1(true);
    setIndex2(index);
    setSelectedItem(item);
  };
  const [assignedResponse, setAssignedResponse] = useState("");
  //const [assigned, setAssigned] = useState([]);
  const [assignName, setAssignName] = useState();
  const [assignName1, setAssignName1] = useState([]);
  const [memberIdOnly, setMemberIdOnly] = useState();
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const courseAssign = () => {
    setShow5(true);
  };

  const [allTag, setAllTag] = useState([]);
  const allTags = async () => {
    const { data } = await authFetch.get(`/tags/all-tags`);
    setAllTag(data);
  };

  useEffect(() => {
    allTags();
  }, []);

  // const onChange = (event) => {
  //   setAssignName(event.target.value);
  // };

  // const onSearch = async (searchTerm) => {
  //   setAssignName(searchTerm);
  //   setSelectedName([...selectedName, searchTerm]);
  // };

  // const transformedContainers = allTag.map((container) => ({
  //   ...container,
  //   label: container.name, // Setting the label equal to the name field
  //   value: container._id, // Setting the id equal to the _id field
  // }));

  return (
    <Container fluid>
      {assignedResponse && <p>{assignedResponse}</p>}
      <Row>
        {course && (
          <div className="maintdddd">
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "300px" }}>
                <div className="pklkm">
                  <img
                    src={course.image ? course.image.Location : "/course.png"}
                    alt="Image"
                  />
                </div>
              </div>

              <div className="courseviewcard">
                <div>
                  <p className="courseTitle2">{course.name}</p>
                  <p
                    style={{
                      marginTop: "-10px",
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    {course.lessons && course.lessons.length} Lessons |{" "}
                    {`${students} Enrolled`}
                  </p>
                </div>

                <div className="courseicons">
                  {/* total students enrolled */}

                  <p style={{ fontSize: "1em" }}>
                    {course.lessons && course.lessons.length < 5 ? (
                      "Min 5 lessons required to publish"
                    ) : course.published ? (
                      <button
                        onClick={() => setShow4(true)}
                        className="jiluil2"
                      >
                        Unpublish
                      </button>
                    ) : (
                      <button
                        onClick={() => setShow3(true)}
                        className="jiluil3"
                      >
                        Publish
                      </button>
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ReactMarkdown source={course.description} />
              </div>
            </div>
            <div className="cardlower">
              <button onClick={() => setShow2(true)} className="w4434">
                Add Lesson
              </button>
              <button onClick={navigatetoEdit} className="w4434q">
                Edit
              </button>
              <button onClick={courseAssign} className="w4434qss">
                Assign
              </button>
            </div>

            <br />

            <div
              className="row pb-5"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                paddingTop: "3rem",
              }}
            >
              <div className="col lesson-list">
                <p style={{ fontSize: "100%", fontWeight: "600" }}>
                  {course && course.lessons && course.lessons.length} Lessons
                  Uploaded{" "}
                </p>

                <Table responsive hover style={{ backgroundColor: "#f8f8f8" }}>
                  {course &&
                    course.lessons &&
                    course.lessons.map((item, index) => (
                      <tbody>
                        <tr className="trdata">
                          <td>{index + 1}</td>
                          <td>{item.title}</td>

                          <td className="flex items-center justify-end gap-3">
                            <button
                              onClick={() => previewVideo(index, item)}
                              className="ewfwefh"
                            >
                              View
                            </button>
                            <button
                              onClick={() => deleteThisLesson(index, item)}
                              className="iukyuk"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </Table>
              </div>
            </div>
          </div>
        )}

        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%" }}>Add Lesson </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {noVideo && (
              <span
                style={{ color: "red", fontSize: "72%", textAlign: "center" }}
              >
                Video is not uploaded.
              </span>
            )}

            <AddLessonForm
              values={values}
              setValues={setValues}
              handleAddLesson={handleAddLesson}
              uploading={uploading}
              uploadButtonText={uploadButtonText}
              handleVideo={handleVideo}
              progress={progress}
              handleVideoRemove={handleVideoRemove}
              handleClose2={handleClose2}
            />
          </Modal.Body>
          {/* <Modal.Footer>
            <button className="jiluil4" onClick={handleClose2}>
              Close
            </button>
          </Modal.Footer> */}
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedItem && selectedItem.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem?.video && selectedItem?.video?.Location && (
              <div className="pt-2 d-flex justify-content-center">
                <video controls width="410" height="240">
                  <source
                    type="video/mp4"
                    src={selectedItem?.video?.Location}
                  />
                  something went wrong
                </video>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
              {selectedItem && selectedItem.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "1em" }}>
            This action will permanently delete this lesson. This action cannot
            be reversed.
          </Modal.Body>
          <Modal.Footer>
            <button className="w443y4q" onClick={handleClose1}>
              Close
            </button>
            <button className="w443sy4q" onClick={handleDeleteLesson}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
              Publish Course
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "1em" }}>
            Once you publish this course, it will be live for all members and
            workers to enroll.
          </Modal.Body>
          <Modal.Footer>
            <button className="jiluil3" onClick={handleClose3}>
              Close
            </button>
            <button className="jiluil" onClick={handlePublish}>
              Publish
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
              Unpublish Course
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "1em" }}>
            Once you unpublish this course, it will not appear in the
            marketplace for members and workers to enroll.
          </Modal.Body>
          <Modal.Footer>
            <button className="w443sy4q" onClick={handleClose4}>
              Close
            </button>
            <button className="w443y4q" onClick={handleUnpublish}>
              Unpublish
            </button>
          </Modal.Footer>
        </Modal>
        <AssignCourse
          show5={show5}
          setShow5={setShow5}
          handleClose5={handleClose5}
          assignName={assignName}
          setAssignName={setAssignName}
          course={course}
          memberIdOnly={memberIdOnly}
          setMemberIdOnly={setMemberIdOnly}
          assignedResponse={assignedResponse}
          setAssignedResponse={setAssignedResponse}
        />
      </Row>
    </Container>
  );
};

export default CourseView;
