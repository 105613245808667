import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactAudioPlayer from "react-audio-player";
import "./announcement.css";

const ViewAnnouncement = ({ show, setShow, selectedAudio }) => {
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Previewing Announcement</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex items-center justify-center my-5 text-center">
        <div className="flex flex-col items-start justify-center yyyanno">
          <div className="flex justify-center w-100">
            <img
              src={selectedAudio?.annoucementImage?.Location}
              alt="Announcement"
              className="viewam"
            />
          </div>
          <div className="mt-2 viewheader">
            {selectedAudio?.annoucementName}
          </div>
          <div className="mt-2 viewbody">
            {selectedAudio?.announcementDescription}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewAnnouncement;
