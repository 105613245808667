import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import { Container, Col, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";

import AddTag from "../../../components/ContainerGroup/AddTag";
import moment from "moment";
const ContainerView = () => {
  const { id, containername } = useParams();
  const navigate = useNavigate();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [show, setShow] = useState(false);
  const [uniqueContainer, setUniqueContainer] = useState(null);
  const [disContainer, setDisContainer] = useState(null);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getContainerByMemberId();
  }, []);

  const getContainerByMemberId = async () => {
    const { data } = await authFetch.get(
      `/container/getcontainerbymemberid/${id}`
    );

    setUniqueContainer(data.containerByMember);
  };

  useEffect(() => {
    const getContainerById = async () => {
      const { data } = await authFetch.get(
        `/container/findcontainerinsteps/${id}`
      );

      setDisContainer(data.containerInSteps);
    };
    getContainerById();
  }, []);

  const { getContainers, getContainerMembers, logoutUser, user } =
    useAppContext();

  const navigateToUser = (userId) => {
    navigate("/auth/peopleview/" + userId);
  };

  const navigateToStep = (containerId) => {
    navigate(`/auth/workflowview/${containerId}/${containername}`);
  };
  const [key, setKey] = useState("member");

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteTag, setDeleteTag] = useState("");
  const showDeleteConfirm = (id, tag) => {
    setDeleteId(id);
    setDeleteTag(tag);
    setShowDelete(true);
  };

  const yesDelete = async () => {
    // await removeFromContainer(deleteId, deleteTag);
    // getContainerByMemberId();
    // handleCloseDelete();
    try {
      const response = await authFetch.put(
        `/members/tag/${deleteId}/${deleteTag}`
      );
      if (response.status === 200 || response.status === 201) {
        getContainerMembers(deleteId);
        getContainers();
        getContainerMembers(deleteId);
        getContainerByMemberId();
        handleCloseDelete();
        toast(
          <CustomToastContent
            title="Success"
            message="Member Removed Successfully!"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container fluid>
      <Row className="courseheader">
        <p className="sahs">{uniqueContainer?.name}</p>
      </Row>

      <Row className="tabw mb-5 mt-5 text-bold text-xl">{containername}</Row>
      <Row>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 custom-tab"
        >
          <Tab
            eventKey="member"
            title={`Members (${uniqueContainer ? uniqueContainer.length : 0})`}
          >
            <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th>NAME</th>

                  <th>EMAIL</th>
                  <th>PHONE</th>
                  <th>DATE JOINED</th>
                </tr>
              </thead>
              <tbody>
                {uniqueContainer?.map((user) => (
                  <tr
                    key={user._id}
                    className="pointer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <td
                      onClick={() => navigateToUser(user._id)}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {user.mfirstName} {user.mlastName}
                    </td>
                    <td>{user.memail}</td>
                    <td>{user.mmobilePhone}</td>
                    <td>{moment(user.createdAt).format("MMMM DD, YYYY")}</td>
                    <td>
                      <button
                        className="jiluil"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the event from bubbling up
                          // removeFromContainer(user._id, id);
                          showDeleteConfirm(user._id, id);
                        }}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="workflow"
            title={`WorkFlows (${disContainer ? disContainer.length : 0})`}
          >
            {disContainer?.length ? (
              <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                <thead>
                  <tr>
                    <th>WORKFLOW NAME</th>

                    <th>ACTION</th>

                    <th>LAST UPDATED</th>
                  </tr>
                </thead>
                <tbody>
                  {disContainer?.map((step) => (
                    <tr style={{ textDecoration: "none", color: "black" }}>
                      <td
                        onClick={() => navigateToStep(step._id)}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {step.workflowName}
                      </td>
                      <td>{step.selectedAction}</td>

                      <td>
                        {format(new Date(step.updatedAt), "MMMM dd, yyyy")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="flex items-center justify-center flex-column mt-5 text-bold">
                No WorkFlows Found!
              </div>
            )}
          </Tab>
        </Tabs>
      </Row>
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete?</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleCloseDelete}>
            Close
          </button>
          <button className="jiluil" onClick={yesDelete}>
            Remove Member
          </button>
        </Modal.Footer>
      </Modal>
      <AddTag show={show} setShow={setShow} />
    </Container>
  );
};

export default ContainerView;
