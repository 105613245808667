import React, { useState, useEffect } from "react";
import { SiMailchimp } from "react-icons/si";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import axios from "axios";

const MailChimp = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user, isLoading, showAlert, displayAlert, updateUser, logoutUser } =
    useAppContext();

  const [apiKey, setApiKey] = useState(user.apiKeyMailChimp);
  const [listId, setListId] = useState(user.listIdMailChimp);
  const [dataCenter, setDataCenter] = useState(user.dataCenterMailChimp);
  const [enrollStatus, setEnrollStatus] = useState(false);
  const [enrollToken, setEnrollToken] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user.mailchimpAccessToken?.length > 0) {
      setEnrollToken(true);
    }
  }, []);

  useEffect(() => {
    if (user?.apiKey) setApiKey(user?.apiKey);
    if (user?.listId) setListId(user?.listId);
    if (user?.dataCenter) setDataCenter(user?.dataCenter);
  }, [user]);

  useEffect(() => {
    if (
      user?.apiKeyMailChimp &&
      user?.listIdMailChimp &&
      user?.dataCenterMailChimp
    ) {
      setEnrollStatus(true);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentUser = {
      dataCenterMailChimp: dataCenter,
      apiKeyMailChimp: apiKey,
      listIdMailChimp: listId,
    };
    updateUser({ currentUser });
  };

  function redirectToMailchimpAuth() {
    const authURL = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID}&redirect_uri=${process.env.REACT_APP_REDIRECTURI}`;

    window.location.href = authURL;
  }

  const handleRevokeAccess = async () => {
    try {
      const response = await authFetch.post(
        "/mailchimp/revoke-mailchimp",
        {
          userId: user._id,
        },
        {
          headers: {
            Authorization: user.mailchimpAccessToken, // If you're using token-based auth
          },
        }
      );

      if (response.data.success) {
        alert("Mailchimp access revoked.");
        setEnrollStatus(false);
      } else {
        alert("Error revoking access. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Setup MailChimp</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {showAlert && <Alert />} */}
        <div
          style={{
            textAlign: "center",
            padding: "2rem 2rem",
          }}
        >
          <SiMailchimp style={{ fontSize: "79px" }} />
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ fontWeight: "600" }}>API Key</Form.Label>
          <Form.Control
            type="text"
            name="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="forminput"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ fontWeight: "600" }}>
            List ID (Audience ID)
          </Form.Label>
          <Form.Control
            type="text"
            name="listId"
            value={listId}
            onChange={(e) => setListId(e.target.value)}
            className="forminput"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ fontWeight: "600" }}>Data Center</Form.Label>
          <Form.Control
            type="text"
            name="dataCenter"
            value={dataCenter}
            onChange={(e) => setDataCenter(e.target.value)}
            className="forminput"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="buttonner mt-3 mb-3"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Save
        </button>
        {enrollStatus &&
          (enrollToken === true ? (
            <button
              type="button"
              className="buttonner mt-3 mb-5"
              onClick={handleRevokeAccess}
              disabled={isLoading}
              // Assuming red color for "Unenroll" button
            >
              <SiMailchimp /> Unenroll
            </button>
          ) : (
            <button
              type="button"
              className="buttonner23323 mt-3 mb-5"
              onClick={redirectToMailchimpAuth}
              disabled={isLoading}
            >
              <SiMailchimp /> Enroll
            </button>
          ))}
      </Modal.Footer>
    </Modal>
  );
};

export default MailChimp;
