import React, { useState, useEffect } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent";
import "./profile.css";
import { Modal, ProgressBar, Form, Container, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import nodd from "../../assets/newi/nodd.png";
import { set } from "date-fns";

const ArchivedList = ({ show, setShow }) => {
  const { user, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getArchivedList();
  }, []);

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);
  const [archivedList, setArchivedList] = useState([]);

  const getArchivedList = async () => {
    try {
      const response = await authFetch.get(`/members/archived-list`);

      if (response.status === 200 || response.status === 201) {
        setArchivedList(response.data);
      }
    } catch (error) {
      console.error("Error fetching archived list:", error);
      // Optionally, you could update the UI or handle the error in a user-friendly way here
    }
  };

  const permanentlyDelete = async (id) => {
    try {
      const response = await authFetch.delete(
        `/members/permanently-delete/${id}`
      );
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            type="Member Deleted"
            message="The member has been permanently deleted."
          />
        );

        getArchivedList();
        setPermanentDeleteModal(false);
      }
    } catch (error) {
      console.error("Error deleting member:", error);
      toast.error(
        <CustomToastContent
          header="Error Deleting Member"
          body="An error occurred while deleting the member."
        />
      );
    }
  };

  const restoreMember = async (id) => {
    try {
      const response = await authFetch.patch(`/members/restore/${id}`);
      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="The member has been restored."
          />
        );

        getArchivedList();
        setRestoreModal(false);
      }
    } catch (error) {
      console.error("Error restoring member:", error);
      toast.error(
        <CustomToastContent
          title="Error"
          message="An error occurred while restoring the member."
        />
      );
    }
  };

  const [restoreModal, setRestoreModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const showRestoreModal = (id) => {
    setRestoreModal(true);
    setSelectedMemberId(id);
  };

  const [permanentDeleteModal, setPermanentDeleteModal] = useState(false);

  const showPermanentDeleteModal = (id) => {
    setPermanentDeleteModal(true);
    setSelectedMemberId(id);
  };

  const handleCloseAll = () => {
    setShowDeleteAll(false);
  };

  const deleteAllData = async () => {
    try {
      const response = await authFetch.delete(
        `/members/myujyujyujyujuyjuy/${user.churchName}`
      );

      // Check for successful deletion (status 200 or 204 for successful delete)
      if (response.status === 200 || response.status === 204) {
        toast(
          <CustomToastContent
            type="Success"
            message="All non-archived members have been permanently deleted."
          />
        );

        handleCloseAll(); // Close the modal
        // setPermanentDeleteModal(false); // Close the modal
        getArchivedList(); // Fetch the updated list of archived members after deletion
      } else {
        throw new Error("Failed to delete members");
      }
    } catch (error) {
      console.error("Error deleting members:", error);

      // Show a toast notification on error
      toast.error(
        <CustomToastContent
          header="Error Deleting Members"
          body="An error occurred while deleting the members. Please try again."
        />
      );
    }
  };

  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const showDeleteAllxx = () => {
    setShowDeleteAll(true);
  };

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Archived List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {archivedList && archivedList.length > 0 ? (
              <Row className="tabw">
                <div
                  className="flex justify-content-between items-center"
                  style={{
                    fontWeight: "700",
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    paddingTop: "2rem",
                    marginBottom: "4rem",
                  }}
                >
                  <div>
                    {archivedList.length ? (
                      <p>
                        {archivedList.length} record
                        {archivedList.length > 1 ? "s" : ""} found
                      </p>
                    ) : (
                      <p>No Saved View</p>
                    )}
                  </div>
                  <div>
                    <button className="jiluil2" onClick={showDeleteAllxx}>
                      Permanently Delete All
                    </button>
                  </div>
                </div>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  <thead>
                    <tr className="trdataheader">
                      <th>ARCHIVED DATE</th>
                      <th>FIRST NAME</th>
                      <th>LAST NAME</th>
                      <th>EMAIL</th>
                      <th>GENDER</th>

                      <th style={{ textAlign: "center" }}>ACTIONS</th>
                    </tr>
                  </thead>
                  {archivedList.length > 0 &&
                    archivedList.map((saved, index) => (
                      <tbody>
                        <tr key={index} className="trdata">
                          <td>{moment(`${saved.updatedAt}`).format("LLL")}</td>
                          <td>{saved.mfirstName}</td>
                          <td>{saved.mlastName}</td>
                          <td>{saved.memail}</td>
                          <td>{saved.mgender}</td>

                          <td className="rightco">
                            <button
                              className="jiluil"
                              onClick={() => showRestoreModal(saved._id)}
                            >
                              Restore Member
                            </button>
                            <button
                              className="jiluil4"
                              onClick={() =>
                                showPermanentDeleteModal(saved._id)
                              }
                            >
                              Permanently Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </Table>
              </Row>
            ) : (
              <div className="noData">
                <img src={nodd} alt="no data" />
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={restoreModal} onHide={() => setRestoreModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Restore Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to restore this member?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="jiluil3" onClick={() => setRestoreModal(false)}>
            Cancel
          </button>
          <button
            className="jiluil"
            onClick={() => restoreMember(selectedMemberId)}
          >
            Restore
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={permanentDeleteModal}
        onHide={() => setPermanentDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Permanently Delete Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to permanently delete this member?</p>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="jiluil3"
            onClick={() => setPermanentDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="jiluil"
            onClick={() => permanentlyDelete(selectedMemberId)}
          >
            Permanently Delete
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteAll} onHide={() => setShowDeleteAll(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Permanently Delete ALL Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to permanently delete ALL archived member?
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="jiluil3"
            onClick={() => setPermanentDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="jiluil" onClick={() => deleteAllData()}>
            Permanently ALL Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchivedList;
