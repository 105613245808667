import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import "./volunteer.css";
import Alert from "../../../components/Alert/Alert";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const VolunteerManagement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();

  const createVolunteer = () => {
    navigate("/auth/createVolunteer");
  };

  const {
    showAlert,
    deleteSchedule,
    logoutUser,

    getSchedules,
    schedule,
  } = useAppContext();

  // Extract users older than 30 and add them to a new object

  useEffect(() => {
    schedule && getSchedules();
  }, []);
  const [show, setShow] = useState(false);

  const [appId, setAppId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (appId) => {
    setShow(true);
    setAppId(appId);
  };

  const handleDelete = () => {
    deleteSchedule(appId._id, {
      alertText: "Schedule Deleted",
    });

    handleClose();
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}
        <Row className="fundc" style={{ marginBottom: "2rem" }}>
          <Col className="sahs">Volunteer Centre</Col>
        </Row>

        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button
              variant="danger"
              className="wedweded"
              onClick={createVolunteer}
            >
              New Schedule
            </Button>
          </div>
        </Row>

        <Row className="fundc2">
          <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
            {schedule.length > 0 && (
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>SCHEDULE NAME</th>
                  <th style={{ fontSize: "1em" }}>SCHEDULE DESCRIPTION</th>
                  <th style={{ fontSize: "1em" }}>REPEAT</th>
                  <th style={{ fontSize: "1em" }}>ACTION</th>
                </tr>
              </thead>
            )}
            <tbody>
              {schedule.length > 0 ? (
                schedule.map((data, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: "1em" }}>{data.schedule}</td>
                    <td style={{ fontSize: "1em", width: "40%" }}>
                      {data.scheduleDescription}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {data.repeat ? data.repeat : "No"}
                    </td>
                    <td className="flex gap-3">
                      <Link to={"/auth/viewvolunteer/" + data._id}>
                        <button className="wewecwe1">Edit</button>
                      </Link>

                      <button
                        className="wewecwe"
                        onClick={() => handleShow(data)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <p className="noData" style={{ textAlign: "center" }}>
                  No data available for display.
                </p>
              )}
            </tbody>
          </Table>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleted Schedule cannot be recovered!</Modal.Body>
            <Modal.Footer>
              <button className="wewecwe1" onClick={handleClose}>
                Cancel
              </button>
              <button className="wewecwe" onClick={handleDelete}>
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default VolunteerManagement;
