import React, { useState, useEffect } from "react";
// import ViewAllCourses from "./../ViewAllCourses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import { Tooltip } from "antd";
import { Select, Space } from "antd";
import Form from "react-bootstrap/Form";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import Table from "react-bootstrap/Table";

// import AddAudio from "../../../../components/Audio/AddAudio";
import ReactAudioPlayer from "react-audio-player";
// import PlayAudio from "../../../../components/Audio/PlayAudio";
import { Button, Modal } from "antd";
import PostAnnouncement from "../../../components/Announcement/PostAnnouncement";
import DeleteAnnouncement from "../../../components/Announcement/DeleteAnnouncement";
import PublishAnnouncement from "../../../components/Announcement/PublishAnnouncement";
import EditAnnouncement from "../../../components/Announcement/EditAnnouncement";
import ViewAnnouncement from "../../../components/Announcement/ViewAnnouncement";
// import DeleteAudio from "../../../../components/Audio/DeleteAudio";
// import PublishAudio from "../../../../components/Audio/PublishAudio";
// import ShareAudio from "../../../../components/Audio/ShareAudio";
// import AudioLink from "../../../../components/Audio/AudioLink";

const Announcement = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    getAnnouncement();
  }, []);

  const getAnnouncement = async () => {
    try {
      const { data } = await authFetch.get(`/announcement`);
      setAnnouncement(data);
    } catch (err) {
      console.error(err);
    }
  };

  const [audio, setAudio] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show4, setShow4] = useState(false);
  const [tag, setTag] = useState([]);
  const [member, setMember] = useState("");
  const [group, setGroup] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null);

  const handleShow = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedValue(saved);
  };

  const handleShow5 = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedValue(saved);
  };

  const handleShow6 = (event, saved) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedValue(saved);
    setShow6(true);
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  const addAnnouncement = (id) => {
    setShow2(true);
  };

  const handleEdit = (e, announcements) => {
    setShow3(true);
    setSelectedAudio(announcements);
  };

  const handleView = (e, announcements) => {
    setShow4(true);
    setSelectedAudio(announcements);
  };

  const handleReload = () => {
    getAnnouncement();
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Announcement Dashboard</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addAnnouncement}>
              Add Announcement
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {announcement && announcement.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "100%",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {announcement.length ? (
                    <p>
                      {announcement.length} record
                      {announcement.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {announcement.map((announcements, index) => (
                    <tbody>
                      <tr
                        key={index}
                        className="trdata"
                        style={{ verticalAlign: "middle" }}
                      >
                        <td>{announcements.annoucementName}</td>
                        <td className="rightco">
                          <button
                            className="q3rq3r23"
                            onClick={(e) => handleView(e, announcements)}
                          >
                            View
                          </button>

                          <button
                            className="jiluil6"
                            onClick={(e) => handleEdit(e, announcements)}
                          >
                            Edit
                          </button>

                          <button
                            className={
                              announcements.published ? "jiluil3" : "jiluil"
                            }
                            onClick={(event) =>
                              handleShow5(event, announcements)
                            }
                          >
                            {announcements.published ? "Unpublish" : "Publish"}
                          </button>

                          {/* <button
                            className="jiluil3"
                            onClick={(event) =>
                              handleShow6(event, announcements)
                            }
                          >
                            Share
                          </button> */}

                          <button
                            className="jiluil2"
                            onClick={(event) =>
                              handleShow(event, announcements)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <PostAnnouncement
        show={show2}
        setShow={setShow2}
        handleReload={handleReload}
      />
      <DeleteAnnouncement
        show={show}
        setShow={setShow}
        selectedValue={selectedValue}
        handleReload={handleReload}
      />
      <PublishAnnouncement
        show={show5}
        setShow={setShow5}
        selectedValue={selectedValue}
        handleReload={handleReload}
      />
      <EditAnnouncement
        show={show3}
        setShow={setShow3}
        selectedAudio={selectedAudio}
        handleReload={handleReload}
      />
      <ViewAnnouncement
        show={show4}
        setShow={setShow4}
        selectedAudio={selectedAudio}
      />
    </>
  );
};

export default Announcement;
