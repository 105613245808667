import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../context/appContext";

const GroupCampusPieChart = ({ data }) => {
  const navigate = useNavigate();
  //console.log(data, "data");
  const chartData = Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  }));

  const COLORS = [
    "#FED970",
    "#FE7064",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28FEF",
    " #243060",
  ];

  const handlePieClick = (data, index) => {
    navigate(`/auth/campus-details/${data.name}`, {
      state: { campusName: data.name },
    });
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          outerRadius="60%"
          fill="#8884d8"
          dataKey="value"
          label={({ name }) => name}
          onClick={handlePieClick}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default GroupCampusPieChart;
