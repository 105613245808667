import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
const PublishDevotional = ({
  show,
  setShow,
  selectedDevotional,
  publishedDevotional,
  unpublishedDevotional,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [devotionals, setDevotionals] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/devotional`);
    setDevotionals(data);
  };

  const handlePublish = async () => {
    try {
      const response = await authFetch.put(
        `/devotional/publish/${selectedDevotional._id}`
      );
      if (response.status === 200 || response.status === 201) {
        loadCourse();
        handleClose5();
        publishedDevotional();
        toast(
          <CustomToastContent
            message="Devotional Published Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      const response = await authFetch.put(
        `/devotional/unpublish/${selectedDevotional._id}`
      );
      if (response.status === 200 || response.status === 201) {
        loadCourse();
        handleClose5();
        unpublishedDevotional();
        toast(
          <CustomToastContent
            message="Devotional Unpublished Successfully"
            type="success"
          />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose5 = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          {selectedDevotional?.published
            ? "Unpublish Devotional"
            : "Publish Devotional"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        {selectedDevotional?.published
          ? "You are about to unpublish this devotional. Do you want to continue?"
          : "You are about to publish this devotional. Do you want to continue?"}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose5} className="jiluil">
          Close
        </button>
        <button
          onClick={() => {
            selectedDevotional.published
              ? handleUnpublish(selectedDevotional)
              : handlePublish(selectedDevotional);
            handleClose5();
          }}
          className="jiluil3"
        >
          {selectedDevotional?.published ? "Unpublish" : "Publish"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishDevotional;
