import React from "react";
import "./product.css";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { FaFont } from "react-icons/fa";
import h1 from "../../assets/newi/h1.png";
import h2 from "../../assets/newi/h2.png";
import MoreProduct from "../../components/Product/MoreProduct";
import { Helmet } from "react-helmet";

const ProductForms = () => {
  const handleDemoClick = () => {
    window.open("https://calendly.com/churchease/demo-churchease", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Form Management | Churchease - Create and Manage Custom Forms
        </title>
        <meta
          name="description"
          content="Easily create and manage forms with Churchease. Tailor forms to gather essential information, engage users, and streamline data processing for effective insights collection."
        />
        <link rel="canonical" href="https://churchease.com/product/forms" />

        <meta
          property="og:title"
          content="Form Management | Churchease - Create and Manage Custom Forms"
        />
        <meta
          property="og:description"
          content="Easily create and manage forms with Churchease. Tailor forms to gather essential information, engage users, and streamline data processing for effective insights collection."
        />
        {/* <meta property="og:image" content="URL_TO_IMAGE" /> */}
        <meta
          property="og:url"
          content="https://churchease.com/product/forms"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Container fluid>
        <Row>
          <div className="headerintro" style={{ backgroundColor: "#A43329" }}>
            <div>
              {" "}
              <FaFont size={100} />
              <p className="headssc">Forms</p>
              <p className="headpp">
                Easily create and manage forms with our versatile tool. Tailor
                forms to gather essential information, engage users, and
                streamline data processing. The intuitive system ensures quick
                updates and effective insights collection.
              </p>
              <button className="headerintro-button" onClick={handleDemoClick}>
                Book a demo
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <div className="productx11">
            <p className="product12">
              Everything you need for effective form management
            </p>
            <p className="product13">
              Your all-in-one tool to create, distribute, and analyze custom
              forms, enhancing communication and data collection
            </p>
          </div>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col className="flex justify-center align-center">
            <img src={h1} alt="Forms" className="opls" />
          </Col>
          <Col>
            <div className="productdiv">
              <p className="proddz">CUSTOM FORM CREATION</p>
              <p className="proddz1">
                Easily design custom forms to capture the exact information you
                need from your congregation or event attendees.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">AUTOMATED DATA PROCESSING</p>
              <p className="proddz1">
                Streamline data entry and analysis with automated processing
                features, reducing errors and saving time.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">RESPONSE TRACKING</p>
              <p className="proddz1">
                Monitor and manage responses in real-time, ensuring timely
                follow-ups and comprehensive data collection.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="flex justify-end align-center productmain mb-5">
          <Col>
            <div className="productdiv">
              <p className="proddz">INTEGRATED ANALYTICS</p>
              <p className="proddz1">
                Utilize built-in analytics tools to gain insights from form
                responses, helping you make informed decisions.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">ACCESS CONTROL</p>
              <p className="proddz1">
                Control who can view and fill out forms with customizable access
                settings, enhancing security and privacy.
              </p>
            </div>
            <div className="productdiv">
              <p className="proddz">MOBILE COMPATIBILITY</p>
              <p className="proddz1">
                Ensure accessibility and convenience with forms that are
                optimized for mobile devices, allowing responses on the go.
              </p>
            </div>
          </Col>
          <Col className="flex justify-center align-center">
            <img src={h2} alt="Forms" className="opls" />
          </Col>
        </Row>
      </Container>
      <MoreProduct color="#A43329" />
      <Footer />
    </>
  );
};

export default ProductForms;
