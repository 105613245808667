import React, { useEffect } from "react";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms of Service - Churchease</title>
        <meta
          name="description"
          content="Review the terms and conditions that govern your use of the Churchease platform and services."
        />
      </Helmet>

      <Header />
      <div className="privacy-body">
        <div className="privacy-policy-container">
          <h1>Terms of Service</h1>
        </div>
        <div>
          <p style={{ paddingBottom: "3rem" }}>
            <i>Last updated August 28, 2023</i>
          </p>
          <p className="privacy-di">
            Welcome to Churchease! These Terms of Service ("Terms") govern your
            use of our website and services. By accessing or using [Your Church
            Management Portal Name] (referred to as "Service" or "Portal"), you
            agree to comply with and be bound by these Terms. If you do not
            agree with these Terms, please do not use our services.
          </p>

          <div className="privacy-content">
            <h2>Acceptable Use</h2>
            <p className="privacy-di">
              You agree to use Churchease only for lawful purposes and in a
              manner consistent with these Terms. You shall not use the Service
              in any way that violates any applicable laws or regulations or
              infringes upon the rights of others.
            </p>
          </div>

          <div className="privacy-content">
            <h2>User Accounts</h2>
            <p className="privacy-di">
              To access certain features of the Service, you may need to create
              an account. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. You agree to provide accurate and
              complete information when creating or updating your account.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Privacy</h2>
            <p className="privacy-di">
              Your use of Churchease is subject to our Privacy Policy, which can
              be found <Link to="/privacy">here</Link>. By using the Service,
              you consent to the collection, use, and sharing of your
              information as described in the Privacy Policy.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Content</h2>
            <p className="privacy-di">
              You are solely responsible for the content you upload or share on
              Churchease. You retain ownership of your content, but you grant us
              a worldwide, non-exclusive, royalty-free license to use,
              reproduce, modify, and distribute your content for the purpose of
              providing the Service.l information with third parties except as
              described below:
            </p>
          </div>

          <div className="privacy-content">
            <h2>Intellectual Property</h2>
            <p className="privacy-di">
              All materials, including text, graphics, logos, icons, and
              software, used or displayed on Churchease, are the property of
              Churchease or its licensors and are protected by copyright,
              trademark, and other laws. You may not reproduce, modify,
              distribute, or otherwise use any part of the materials without our
              prior written consent.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Termination</h2>
            <p className="privacy-di">
              We may suspend or terminate your access to Churchease at any time,
              without notice, for any reason, including but not limited to a
              violation of these Terms. Upon termination, your account and any
              content you have submitted may be deleted.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Limitation of Liability</h2>
            <p className="privacy-di">
              To the extent permitted by law, [Your Company Name] shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses, resulting from (a) your use
              or inability to use the Service; (b) any unauthorized access to or
              use of our servers; or (c) any errors, omissions, or inaccuracies
              in the Service.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Changes to Terms</h2>
            <p className="privacy-di">
              We may modify these Terms at any time, and such modifications
              shall be effective immediately upon posting on [Your Church
              Management Portal Name]. Your continued use of the Service after
              changes are posted constitutes your acceptance of the modified
              Terms.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Governing Law</h2>
            <p className="privacy-di">
              These Terms shall be governed by and construed in accordance with
              the laws of [Your Jurisdiction], without regard to its conflict of
              law principles.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Contact Us</h2>
            <p className="privacy-di">
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <span style={{ color: "#FE7064" }}>privacy@churchease.com</span>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
