import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./standard.css";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip as ReTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../utils/validation";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]; // Colors for pie chart slices

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const SummaryFormResponse = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { logoutUser, user } = useAppContext();
  const [errorMessage1, setErrorMessage1] = useState("");
  const [error, setError] = useState("");
  const [note, setNote] = useState("");

  const [form, setForm] = useState(null);

  const aggregateRadioData = (plans) => {
    return plans
      .filter((plan) => plan.type === "radio")
      .map((plan) => ({
        name: plan.question,
        data: plan.options.map((option) => ({
          name: option,
          value: plan.response === option ? 1 : 0,
        })),
      }));
  };

  const preprocessChartData = (responses) => {
    const counts = responses.reduce((acc, response) => {
      acc[response] = (acc[response] || 0) + 1;
      return acc;
    }, {});

    const total = responses.length;
    return Object.keys(counts).map((name) => ({
      name,
      value: counts[name],
      percentage: ((counts[name] / total) * 100).toFixed(2) + "%", // Calculate percentage
    }));
  };

  // Helper function to aggregate data for checkbox responses
  const aggregateCheckboxData = (plans) => {
    return plans
      .filter((plan) => plan.type === "checkbox")
      .map((plan) => ({
        name: plan.question,
        data: plan.options.map((option) => ({
          name: option,
          value: plan.response?.includes(option) ? 1 : 0,
        })),
      }));
  };

  // Aggregate data for charts
  // const radioData = form.forms ? aggregateRadioData(form.forms) : [];
  // const checkboxData = form.forms ? aggregateCheckboxData(form.forms) : [];

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms/viewsummaryresponse/${id}`);

    const [dataxx] = data;

    setForm(data);
  };

  useEffect(() => {
    loadCourse();
  }, []);

  const handleTaskTextChange = (newValue, taskIndex) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = newValue; // Update the response field
    setForm({ ...form, forms: updatedTasks });
  };

  const handleRadioChange = (taskIndex, selectedOption) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = selectedOption; // Set the selected option as the response
    setForm({ ...form, forms: updatedTasks });
  };

  const handleCheckboxChange = (taskIndex, optionValue) => {
    const updatedTasks = [...form.forms];
    const selectedOptions = updatedTasks[taskIndex].response || [];

    if (selectedOptions.includes(optionValue)) {
      updatedTasks[taskIndex].response = selectedOptions.filter(
        (option) => option !== optionValue
      );
    } else {
      updatedTasks[taskIndex].response = [...selectedOptions, optionValue];
    }

    setForm({ ...form, forms: updatedTasks });
  };
  console.log(form);
  return (
    <Container fluid>
      <Row className="flex justify-center" style={{ paddingTop: "6rem" }}>
        {form && form.length > 0 ? (
          <div className="taskmain" style={{ paddingBottom: "4rem" }}>
            {form.map((item, index) => (
              <div key={index} className="mb-4">
                <Form.Label
                  style={{ fontWeight: "600" }}
                  htmlFor={`question-${index}`}
                >
                  {index + 1} {item.question}
                  <span style={{ fontWeight: "400", marginLeft: "10px" }}>
                    (Responses: {item.responses.length})
                  </span>
                </Form.Label>

                {/* Determine how to display based on the type */}
                {item.type === "radio" ? (
                  <PieChart width={400} height={300}>
                    <Pie
                      data={preprocessChartData(item.responses)}
                      cx={200}
                      cy={150}
                      labelLine={false}
                      label={renderCustomizedLabel} // Use the custom label for percentages
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {item.responses.map((entry, idx) => (
                        <Cell
                          key={`cell-${idx}`}
                          fill={COLORS[idx % COLORS.length]}
                        />
                      ))}
                    </Pie>

                    <ReTooltip />
                    <Legend />
                  </PieChart>
                ) : item.type === "checkbox" ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={preprocessChartData(item.responses)}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  // For text and textarea, simply list responses
                  <div>
                    {item.responses.map((response, respIndex) => (
                      <p
                        className="summaryborder"
                        key={`response-${index}-${respIndex}`}
                      >
                        {response}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default SummaryFormResponse;
