import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";

const ViewLivestreamRecent = ({ show, setShow, selectedRecentLiveStream }) => {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{selectedRecentLiveStream?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex items-center justify-center my-5 text-center">
        <ReactPlayer url={selectedRecentLiveStream?.youtubeLink} controls />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewLivestreamRecent;
