import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import { FaPlus, FaTimes } from "react-icons/fa";

const ViewVolunteer = () => {
  const params = useParams();
  const _id = params.id;
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { scheduleViewById, uniqueschedule, user, logoutUser } =
    useAppContext();

  useEffect(() => {
    scheduleViewById(_id);
  }, []);

  const navigate = useNavigate();
  const [schedule, setSchedule] = useState("");
  const [publish, setPublish] = useState(false);
  const [useEvent, setUseEvent] = useState("");
  const [repeat, setRepeat] = useState("");
  const [scheduleDescription, setScheduleDescription] = useState("");
  const [frequency, setFrequency] = useState("");
  const [useEventData, setUseEventData] = useState("");

  const [skillRequired, setSkillRequired] = useState([{ id: 0, value: "" }]);
  const [schedules, setSchedules] = useState([
    { id: 0, startTime: "", endTime: "" },
  ]);

  const [teamMembers, setTeamMembers] = useState([
    {
      memberId: 0,
      memberName: "",
      positions: [
        { positionName: "", quantity: "", requireBackgroundCheck: false },
      ],
    },
  ]);
  useEffect(() => {
    if (uniqueschedule?.schedule) {
      setSchedule(uniqueschedule?.schedule);
    }
    if (uniqueschedule?.scheduleDescription) {
      setScheduleDescription(uniqueschedule?.scheduleDescription);
    }
    if (uniqueschedule?.repeat) {
      setRepeat(uniqueschedule?.repeat);
    }
    if (uniqueschedule?.startDate) {
      setFrequency(uniqueschedule?.startDate);
    }
    if (uniqueschedule?.skillRequired) {
      setSkillRequired(uniqueschedule?.skillRequired);
    }

    if (uniqueschedule?.volunteerSchedules) {
      setSchedules(uniqueschedule.volunteerSchedules);
    }
  }, [uniqueschedule]);

  const removeSkill = (id) => {
    if (id !== 0) {
      // Do not remove the input with id:0
      const newInputs = skillRequired.filter((input) => input.id !== id);
      setSkillRequired(newInputs);
    }
  };

  const handleTimeChange = (id, type, value) => {
    const newSchedules = schedules.map((schedule) =>
      schedule.id === id ? { ...schedule, [type]: value } : schedule
    );
    setSchedules(newSchedules);
  };

  const handleInputChange = (id, value) => {
    const newInputs = skillRequired.map((input) =>
      input.id === id ? { ...input, value } : input
    );
    setSkillRequired(newInputs);
  };

  const addNewSchedule = () => {
    const newId = Math.max(...schedules.map((schedule) => schedule.id)) + 1;
    setSchedules((prevSchedules) => [
      ...prevSchedules,
      { id: newId, startTime: "", endTime: "" },
    ]);
  };

  const removeSchedule = (id) => {
    if (id !== 0) {
      const newSchedules = schedules.filter((schedule) => schedule.id !== id);
      setSchedules(newSchedules);
    }
  };

  const addNewSkill = () => {
    setSkillRequired((prevInputs) => [
      ...prevInputs,
      { id: prevInputs.length, value: "" },
    ]);
  };

  const navigateToView = () => {
    navigate("/auth/volunteermanagement");
  };

  const updateVolunteer = async () => {
    const currentSchedule = {
      schedule: schedule,
      repeat: repeat,
      teamMembers: teamMembers,
      useEvent: useEvent,
      scheduleDescription: scheduleDescription,
      skillRequired: skillRequired,
      volunteerSchedules: schedules,
      useEventData: useEventData,
      churchName: user.churchName,
      startDate: frequency,
      published: publish,
    };

    try {
      await authFetch.patch(
        `/schedule/updateschedule/${uniqueschedule?._id}`,
        currentSchedule
      );
      setSchedule("");
      setRepeat("");
      setTeamMembers([
        {
          memberId: 0,
          memberName: "",
          positions: [
            { positionName: "", quantity: "", requireBackgroundCheck: false },
          ],
        },
      ]);
      setUseEvent(false);
      setScheduleDescription("");
      setSkillRequired([{ id: 0, value: "" }]);
      setSchedules([{ id: 0, startTime: "", endTime: "" }]);
      setUseEventData("");
      navigateToView();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container fluid>
      <Row className="fundc" style={{ marginBottom: "3rem" }}>
        <p style={{ textAlign: "center", fontSize: "150%", fontWeight: "500" }}>
          Edit Schedule
        </p>
      </Row>

      <Row className="viewmain mt-3">
        <div className="mb-3">
          <p className="formtext">Basic Schedule Information</p>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              Schedule Name<span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={schedule}
              name="schedule"
              onChange={(e) => setSchedule(e.target.value)}
              className="formvaluexc"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="hed">
              Schedule Description <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              name="scheduleDescription"
              value={scheduleDescription}
              required
              onChange={(e) => setScheduleDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">Start Date</Form.Label>
            <Form.Control
              type="date"
              className="formvaluexc"
              value={frequency}
              name="frequency"
              onChange={(e) => setFrequency(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Label className="hed">Frequency</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={repeat}
              onChange={(e) => setRepeat(e.target.value)}
            >
              <option value="Does not repeat">Does not repeat</option>
              <option value="Daily">Repeat Daily</option>
              <option value="Weekly">Repeat Weekly on Friday</option>
              <option value="Weekday">Repeat on Weekday (Mon - Fri)</option>
              <option value="Monthly">Repeat Monthly</option>
              <option value="Quarterly">Repeat Quarterly</option>
              <option value="Annually">Repeat Annually</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div className="mb-3 mt-3">
          <p className="formtext">What time do you need the volunteers</p>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            {schedules.map((schedule, index) => (
              <div key={index} className="mnyw mb-3">
                <p style={{ margin: "0", fontSize: "80%" }}>
                  Start Time:<span className="myast">*</span>
                </p>
                <Form.Control
                  type="time"
                  className="formvalue"
                  value={schedule.startTime}
                  onChange={(e) =>
                    handleTimeChange(schedule.id, "startTime", e.target.value)
                  }
                  name={`scheduleStartTime_${index}`}
                  required
                />
                <p style={{ margin: "0", fontSize: "80%" }}>End Time:</p>
                <Form.Control
                  type="time"
                  className="formvalue"
                  value={schedule.endTime}
                  onChange={(e) =>
                    handleTimeChange(schedule.id, "endTime", e.target.value)
                  }
                  name={`scheduleEndTime_${index}`}
                  required
                />

                {schedule.id !== 0 && (
                  <FaTimes
                    className="pluss"
                    onClick={() => removeSchedule(schedule.id)}
                  />
                )}
                <FaPlus onClick={addNewSchedule} />
              </div>
            ))}
          </Form.Group>
        </div>
        <div className="mb-3 mt-3">
          <p className="formtext">
            Tell us about the volunteers you need (Cannot be updated)
          </p>
          <div className="container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Team Name</th>
                  <th>Position Name</th>
                  <th>Openings</th>
                  <th>Background Check</th>
                </tr>
              </thead>
              <tbody>
                {uniqueschedule?.teamMembers?.map((member) => {
                  return member?.positions?.map((position, index) => (
                    <tr key={index}>
                      {index === 0 && (
                        <>
                          <td>{member?.memberName}</td>
                        </>
                      )}
                      <td>{position?.positionName}</td>
                      <td>{position?.quantity}</td>
                      <td>{position?.requireBackgroundCheck ? "Yes" : "No"}</td>
                    </tr>
                  ));
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="mb-3 mt-3">
          <p className="formtext">What skills are required</p>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <div className="d-flex flex-column ">
              {skillRequired.map((input, index) => (
                <div key={index} className=" mnyw">
                  <Form.Control
                    type="text"
                    className="formvalue"
                    value={input.value}
                    onChange={(e) =>
                      handleInputChange(input.id, e.target.value)
                    }
                    name={`skillRequired_${index}`}
                    placeholder="Enter Skill"
                    required
                  />
                  {input.id !== 0 && (
                    <FaTimes
                      className="pluss"
                      onClick={() => removeSkill(input.id)}
                    />
                  )}
                  <FaPlus onClick={addNewSkill} />
                </div>
              ))}
            </div>
          </Form.Group>
        </div>
        <div className="flex justify-center mb-5">
          <button className="wedweded1" onClick={updateVolunteer}>
            Save
          </button>
        </div>
      </Row>
    </Container>
  );
};

export default ViewVolunteer;
