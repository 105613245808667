import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const PublishForm = ({
  show,
  setShow,
  selectedForm,
  publishedForm,
  unpublishedForm,
}) => {
  const { logoutUser } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [forms, setForms] = useState([]);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms`);
    setForms(data);
  };

  const handlePublish = async () => {
    try {
      const response = await authFetch.put(
        `/forms/publish/${selectedForm._id}`
      );
      if (response.status === 200 || response.status === 201) {
        publishedForm();
        loadCourse();
        handleClose5();
        toast(
          <CustomToastContent title="Success" message="Publish Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnpublish = async () => {
    try {
      const response = await authFetch.put(
        `/forms/unpublish/${selectedForm._id}`
      );
      if (response.status === 200 || response.status === 201) {
        unpublishedForm();
        loadCourse();
        handleClose5();
        toast(
          <CustomToastContent title="Success" message="Unpublish Successful!" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose5 = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
          {selectedForm?.published ? "Unpublish Form" : "Publish Form"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "1em" }}>
        {selectedForm?.published
          ? "You are about to unpublish this form. Do you want to continue?"
          : "You are about to publish this form. Do you want to continue?"}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose5} className="jiluil3">
          Close
        </button>
        <button
          onClick={() => {
            selectedForm.published
              ? handleUnpublish(selectedForm)
              : handlePublish(selectedForm);
            handleClose5();
          }}
          className="jiluil"
        >
          {selectedForm?.published ? "Unpublish" : "Publish"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishForm;
