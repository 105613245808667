// src/analytics.js
import ReactGA from "react-ga4";

const isProd = process.env.REACT_APP_ENV === "production";
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

if (isProd && measurementId) {
  ReactGA.initialize(measurementId);
}

export const logPageView = () => {
  if (isProd && measurementId) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
};

export const logEvent = (category, action, label = "", value = 0) => {
  if (isProd && category && action) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
};

export const logException = (description = "", fatal = false) => {
  if (isProd && description) {
    ReactGA.exception({ description, fatal });
  }
};
