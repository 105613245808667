import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/appContext";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import AddWorkFlow from "../../../components/WorkFlow/AddWorkFlow";
import { useNavigate } from "react-router-dom";
import DeleteWorkflow from "../../../components/WorkFlow/DeleteWorkflow";
import axios from "axios";
import EditWorkflow from "../../../components/WorkFlow/EditWorkflow";

const WorkFlow = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const { getWorkflows, logoutUser } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [viewNameFlag, setViewNameFlag] = useState(true);
  const [dateFlag, setDateFlag] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleShow3 = () => setShow3(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [handleRefresh, setHandleRefresh] = useState(false);
  const [uniqueSteps, setUniqueSteps] = useState(null);

  const workflowDelete = (e, container) => {
    e.stopPropagation();
    handleShow2();
    setSelectedValue(container);
  };

  const workflowEdit = (e, container) => {
    e.stopPropagation();
    handleShow3();
    setSelectedValue(container);
  };

  useEffect(() => {
    if (handleRefresh) {
      getWorkflows();
    }
  }, [handleRefresh]);

  useEffect(() => {
    countSteps();
  }, [handleRefresh]);

  const countSteps = async () => {
    try {
      setLoading(true);
      const { data } = await authFetch.get(`/workflow/countsteps`);
      // console.log(data);
      setUniqueSteps(data);

      setError(null);
    } catch (err) {
      console.log(err);
      setError(err.message); // Set error state for displaying in UI
    } finally {
      setLoading(false);
    }
  };

  const refreshWorkflows = () => {
    getWorkflows();
    setHandleRefresh(true);
    countSteps();
  };

  const handleViewName = (viewNameFlag) => {
    // if (!viewNameFlag) {
    //   let sorted = [...savedView].sort(function (a, b) {
    //     return a.viewName.localeCompare(b.viewName);
    //   });
    //   setSavedView(sorted);
    // } else {
    //   let sorted = [...savedView].reverse();
    //   setSavedView(sorted);
    // }
    // setViewNameFlag(!viewNameFlag);
  };

  return (
    <Container fluid>
      <Row className="headerbanner">
        <div className="bannerbig">Workflow</div>
        <div className="bannersmall">
          Workflow feature enhances efficiency by automating timely
          notifications via email and SMS, triggered by calendar dates, events,
          and group activities, ensuring prompt communication.
        </div>
      </Row>

      <Row className="courseheader" style={{ marginBottom: "2rem" }}>
        <div className="bnmjo">
          <button
            className="jiluil3"
            onClick={handleShow}
            style={{ fontSize: "1em" }}
          >
            Workflow History
          </button>
          <button
            className="jiluil"
            onClick={handleShow}
            style={{ fontSize: "1em" }}
          >
            New Workflow
          </button>
        </div>
      </Row>

      <Row style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
          {uniqueSteps && uniqueSteps.length > 0 ? (
            <>
              <thead>
                <tr className="trdataheader">
                  <th onClick={handleViewName}>
                    WORKFLOW NAME{" "}
                    {viewNameFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>

                  <th>STEPS</th>
                  <th>ACTIVE GROUPS</th>
                  <th>EVENT</th>
                  <th className="flex  justify-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {uniqueSteps.map((container, index) => (
                  <tr
                    key={index}
                    className="trdata"
                    style={{ cursor: "pointer" }}
                  >
                    <td>{container.name}</td>
                    <td>{container.stepCount}</td>
                    <td>{container.selectedGroupTotalCount}</td>
                    <td>
                      {container.trigger === "calendarTrigger"
                        ? "Calendar Trigger"
                        : container.trigger === "eventTrigger"
                        ? "Event Trigger"
                        : container.trigger
                        ? "Group Trigger"
                        : ""}
                    </td>

                    <td className="flex gap-3 justify-center items-center">
                      <button
                        className="jiluil"
                        onClick={() =>
                          navigate(
                            `/auth/workflowview/${container._id}/${container.name}`
                          )
                        }
                      >
                        Manage Steps
                      </button>
                      <button
                        className="jiluil4"
                        onClick={(e) => workflowEdit(e, container)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil3"
                        onClick={(e) => workflowDelete(e, container)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <div className="flex items-center justify-center noData">
              No Workflow Found
            </div>
          )}
        </Table>
      </Row>

      <AddWorkFlow
        show={show}
        setShow={setShow}
        refreshWorkflows={refreshWorkflows}
      />
      <DeleteWorkflow
        show2={show2}
        setShow2={setShow2}
        selectedValue={selectedValue}
        refreshWorkflows={refreshWorkflows}
      />
      <EditWorkflow
        show3={show3}
        setShow3={setShow3}
        selectedValue={selectedValue}
        refreshWorkflows={refreshWorkflows}
      />
    </Container>
  );
};

export default WorkFlow;
