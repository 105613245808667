import { Modal } from "react-bootstrap";

const PreviewModal = ({ showModal, setShowModal, preview, course }) => {


  return (
    <>
      <Modal show={showModal} onHide={setShowModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{course.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video controls autoPlay preload="auto" width="720" height="405">
            <source type="video/mp4" src={preview} />
            something went wrong
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewModal;
