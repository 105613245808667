import React from "react";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Table } from "react-bootstrap";
import { useAppContext } from "../../../context/appContext";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import he from "he";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../utils/validation";

const StandardFormEdit = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const { logoutUser, user } = useAppContext();
  const [errorMessage1, setErrorMessage1] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [submitted, setSubmitted] = useState(false);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms/${id}`);

    const [dataxx] = data;

    if (dataxx) {
      setForm(dataxx);
    }
  };

  const checkFormSubmission = async () => {
    const memberId = user._id;
    const { data } = await authFetch.get(
      `/forms/check-form-submission/${id}/${memberId}`
    );

    if (data.length > 0) {
      setSubmitted(true);
    }
  };

  useEffect(() => {
    loadCourse();
    checkFormSubmission();
  }, []);

  const validateTasks = () => {
    for (let i = 0; i < form.forms.length; i++) {
      const task = form.forms[i];

      // Ensure task.response is a string before calling trim()
      const trimmedResponse =
        typeof task.response === "string" ? task.response.trim() : "";

      const lengthLimit =
        task.type === "text" ? NAME_LENGTH_LIMIT : DESCRIPTION_LENGTH_LIMIT;

      if (!validateLength(trimmedResponse, lengthLimit)) {
        setError(
          `Response for "${task.question}" cannot exceed ${lengthLimit} words.`
        );
        return false; // Indicate that validation failed
      }
    }

    setError(""); // Clear any previous error if all validations pass
    return true; // Indicate that all validations passed
  };

  const [form, setForm] = useState({
    forms: [
      {
        question: "Example Question 1",
        type: "text",
        text: "",
        response: "",
        submitted: false,
        status: "pending", // Initial status can be "pending", "in progress", "completed", etc.
      },
      // Add more tasks as needed
    ],
  });
  //console.log(planner);

  const handleTaskTextChange = (newValue, taskIndex) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = newValue; // Update the response field
    setForm({ ...form, forms: updatedTasks });
  };

  const handleRadioChange = (taskIndex, selectedOption) => {
    const updatedTasks = [...form.forms];
    updatedTasks[taskIndex].response = selectedOption; // Set the selected option as the response
    setForm({ ...form, forms: updatedTasks });
  };

  const handleCheckboxChange = (taskIndex, optionValue) => {
    const updatedTasks = [...form.forms];
    const selectedOptions = updatedTasks[taskIndex].response || [];

    if (selectedOptions.includes(optionValue)) {
      updatedTasks[taskIndex].response = selectedOptions.filter(
        (option) => option !== optionValue
      );
    } else {
      updatedTasks[taskIndex].response = [...selectedOptions, optionValue];
    }

    setForm({ ...form, forms: updatedTasks });
  };

  const [errorMessage, setErrorMessage] = useState("");

  const submitPlanner = async () => {
    if (!validateTasks()) {
      // If validation fails, stop here. The error message is already set.
      return;
    }
    // Calculate percentage of completed tasks

    const memberId = user._id;
    // Prepare the planner object with new elements
    const plannerToSave = {
      ...form,
      status: "Completed", // Example status logic

      planId: id,
      memberId,
    };

    try {
      await authFetch.post("/forms/form-responses", plannerToSave);
      //setErrorMessage("Saved successfully");
      navigate("/auth/standardformsubmit");
      // Optional: Update the planner state if the backend returns the updated planner
      // setPlanner(response.data);
    } catch (error) {
      console.error("Error saving planner:", error);
      setErrorMessage("Failed to save");
    }
  };

  const handleBack = () => {
    navigate("/auth/standardform");
  };
  return (
    <Container fluid>
      <Row className="sssssssecwef"></Row>
      <Row className="flex justify-center">
        {errorMessage && <p className="inlinesuccess">{errorMessage}</p>}
        {errorMessage1 && <p className="inlineerror">{errorMessage1}</p>}
        {error && <p className="inlineerror">{error}</p>}

        {submitted ? (
          <div
            className="flex justify-center items-start flex=column"
            style={{
              marginTop: "6rem",
              border: "1px solid #d7d7d7",
              padding: "2rem 2rem",
              borderRadius: "10px",
              borderTop: "15px solid var(--primary-color)",
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p className="sahs" style={{ fontSize: "2em" }}>
              {form.title}
            </p>
            <p>Thank you for your response.</p>
            <p>You can fill out this form only once.</p>
            <p>
              Try contacting the owner of the form if you think this is a
              mistake.
            </p>
            <button className="jiluil" onClick={handleBack}>
              Go Back
            </button>
          </div>
        ) : (
          <>
            {form.forms && form.forms.length > 0 ? (
              <div className="taskmain">
                <div className=" flex flex-column">
                  <p className="sahs"> {form.title}</p>
                  {form.description ? (
                    <ReactQuill
                      value={he.decode(form.description)}
                      readOnly={true}
                      theme="bubble"
                      className="custom-quilleee"
                    />
                  ) : (
                    <p>No description available.</p>
                  )}
                  <hr />
                </div>
                {form.forms.map((plan, index) => (
                  <div key={index} className="mb-4">
                    <Form.Label
                      style={{ fontWeight: "600" }}
                      htmlFor={`taskInput-${index}`}
                    >
                      {plan.order}. {plan.question}
                    </Form.Label>

                    {plan.type === "text" && (
                      <Form.Control
                        id={`taskInput-${index}`}
                        type="text"
                        value={plan.response || ""}
                        onChange={(e) =>
                          handleTaskTextChange(e.target.value, index)
                        }
                        disabled={plan.status === "Completed"}
                        required
                      />
                    )}

                    {plan.type === "textarea" && (
                      <Form.Control
                        id={`taskInput-${index}`}
                        as="textarea"
                        rows={10}
                        value={plan.response || ""}
                        onChange={(e) =>
                          handleTaskTextChange(e.target.value, index)
                        }
                        disabled={plan.status === "Completed"}
                        required
                      />
                    )}

                    {plan.type === "radio" &&
                      plan.options.map((option, optionIndex) => (
                        <Form.Check
                          key={`${index}-${optionIndex}`}
                          type="radio"
                          id={`radio-${index}-${optionIndex}`}
                          label={option}
                          name={`radioGroup-${index}`}
                          checked={option === plan.response}
                          onChange={() => handleRadioChange(index, option)}
                          disabled={plan.status === "Completed"}
                        />
                      ))}

                    {plan.type === "checkbox" &&
                      plan.options.map((option, optionIndex) => (
                        <Form.Check
                          key={`${index}-${optionIndex}`}
                          type="checkbox"
                          id={`checkbox-${index}-${optionIndex}`}
                          label={option}
                          name={`checkboxGroup-${index}`}
                          checked={plan.response?.includes(option)}
                          onChange={() => handleCheckboxChange(index, option)}
                          disabled={plan.status === "Completed"}
                        />
                      ))}
                  </div>
                ))}

                <div className="flex justify-center mt-5 items-center gap-3">
                  <button
                    className="jiluil"
                    onClick={handleShow}
                    style={{ width: "30%" }}
                  >
                    Submit
                  </button>
                </div>
                <p
                  className="flex justify-center pt-4"
                  style={{ fontSize: "0.9em" }}
                >
                  Never submit password through the form.
                </p>
              </div>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </>
        )}
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
            Submit Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1em" }}>
          You are about to submit this form. Do you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="jiluil">
            Close
          </button>
          <button
            onClick={() => {
              submitPlanner();
              handleClose();
            }}
            className="jiluil3"
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default StandardFormEdit;
