import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";

import axios from "axios";

const ClearOnlineGiving = ({ show1, setShow1, dataRefresh, refreshedData }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const { logoutUser, user, updateOnlineGiving } = useAppContext();

  const handleDelete = async () => {
    try {
      const data = {
        skinsertId: user.skinsertId,
        churchName: user.churchName,
      };

      // Send PATCH request
      updateOnlineGiving(data);

      // Refresh data and UI

      handleClose1();
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  };

  return (
    <Modal show={show1} onHide={handleClose1}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Online Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This action will permanently delete your online payment details.
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose1}>
          Close
        </button>
        <button className="jiluil" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClearOnlineGiving;
