import React, { useEffect } from "react";
import Header from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./privacy.css";
import { Helmet } from "react-helmet";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Churchease</title>
        <meta
          name="description"
          content="Your privacy is important to us. Read Churchease's privacy policy to understand how we collect, use, and protect your information."
        />
      </Helmet>

      <Header />
      <div className="privacy-body">
        <div className="privacy-policy-container">
          <h1>Privacy Policy</h1>
          <p>Privacy Policy for Churchease</p>
        </div>
        <div>
          <p style={{ paddingBottom: "3rem" }}>
            <i>Last updated August 28, 2023</i>
          </p>
          <p className="privacy-di">
            Thank you for using Churchease "the App", provided by Churchease
            ("we," "us," or "our"). This Privacy Policy outlines how we collect,
            use, and safeguard your personal information when you use our App.
            By accessing or using the App, you agree to the terms of this
            Privacy Policy.
          </p>

          <div className="privacy-content">
            <h2>Information We Collect</h2>
            <p className="privacy-di">
              We may collect and store the following types of personal
              information when you use our App:
            </p>
            <ul>
              <li className="privacy-di">
                Information provided by you, such as your name, email address,
                church affiliation, and other contact details when you register
                for an account or use certain features of the App.
              </li>
              <li className="privacy-di">
                Information about your church's members, activities, events, and
                communications within the App.
              </li>
              <li className="privacy-di">
                Transactional information related to any purchases made within
                the App.
              </li>
              <li className="privacy-di">
                Usage information, including your interactions with the App,
                content viewed, and features accessed.
              </li>
            </ul>
          </div>

          <div className="privacy-content">
            <h2>How We Use Your Information</h2>
            <p className="privacy-di">
              We use your personal information for the following purposes:
            </p>
            <ul>
              <li className="privacy-di">
                To communicate with you regarding updates, announcements, and
                other relevant information about the App.
              </li>
              <li className="privacy-di">
                To process transactions and send transactional notifications.
              </li>
              <li className="privacy-di">
                To personalize your experience and recommend features that may
                be of interest to you.
              </li>
              <li className="privacy-di">
                To monitor and analyze usage patterns to enhance the App's
                performance and user experience.
              </li>
            </ul>
          </div>

          <div className="privacy-content">
            <h2>How We Protect Your Information</h2>
            <p className="privacy-di">
              We employ industry-standard security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction. However, no data transmission or
              storage method can be guaranteed to be 100% secure.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Sharing Your Information</h2>
            <p className="privacy-di">
              We do not share your personal information with third parties
              except as described below:
            </p>
            <ul>
              <li className="privacy-di">
                With your consent: We may share your information with third
                parties if you give us your consent to do so.
              </li>
              <li className="privacy-di">
                Service Providers: We may engage third-party service providers
                to assist us in delivering our services. These providers are
                obligated to protect your information and are prohibited from
                using it for any other purpose.
              </li>
              <li className="privacy-di">
                Legal Compliance: We may disclose your information to comply
                with applicable laws, regulations, legal processes, or
                governmental requests.
              </li>
            </ul>
          </div>

          <div className="privacy-content">
            <h2>Your Choices</h2>
            <p className="privacy-di">
              You have the following rights regarding your personal information:
            </p>
            <ul>
              <li className="privacy-di">
                Access: You can request access to your personal information held
                by us.
              </li>
              <li className="privacy-di">
                Correction: You can request corrections or updates to inaccurate
                information.
              </li>
              <li className="privacy-di">
                Deletion: You can request the deletion of your personal
                information from our records.
              </li>
              <li className="privacy-di">
                Opt-Out: You can opt-out of receiving promotional communications
                from us by following the instructions provided in the
                communication.
              </li>
            </ul>
          </div>

          <div className="privacy-content">
            <h2>Changes to Privacy Policy</h2>
            <p className="privacy-di">
              We may update this Privacy Policy from time to time. We will
              notify you of any significant changes by posting a notice on our
              website or within the App.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Contact us</h2>
            <p className="privacy-di">
              If you have any questions, concerns, or requests regarding this
              Privacy Policy, please contact us at privacy@churchease.com.
            </p>
          </div>

          <div className="privacy-content">
            <h2>Additional Terms for Canadian Users:</h2>
            <p className="privacy-di">
              For Canadian users, this Privacy Policy is subject to the Personal
              Information Protection and Electronic Documents Act (PIPEDA). You
              have the right to access your personal information, request
              corrections, and withdraw consent. If you have concerns or
              complaints about our data handling practices, you can contact the
              Privacy Commissioner of Canada.
            </p>

            <p className="privacy-di">
              For users in the European Union, this Privacy Policy is compliant
              with the General Data Protection Regulation (GDPR). We are the
              data controller of your personal information. You have the right
              to access, rectify, erase, restrict, and object to the processing
              of your personal data. You can also lodge a complaint with a
              supervisory authority if you believe we have violated your rights
              under GDPR.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
