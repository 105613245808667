import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../context/appContext";
import { Link, useNavigate } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import LoginGrap from "../../assets/images/LoginGrap.png";
import Alert from "../../components/Alert/Alert.js";
import Form from "react-bootstrap/Form";
import sss from "../../assets/images/sss.png";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent.js";
const NewUserVerify = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const { user, showAlert, displayAlert, resetNewMemberPassword } =
    useAppContext();
  useEffect(() => {
    if (!token) {
      setError("Invalid or missing token. Redirecting...");

      setTimeout(() => navigate("/login"), 2000); // Redirect to login after 3 seconds
    } else {
      // Optionally, you could verify the token by making an API call here
      setSuccess(true);
    }
  }, [token, navigate]);

  const handleVerifyUser = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      displayAlert("Please fill in all fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const userData = {
      token: token,
      password: password,
    };

    try {
      const response = await resetNewMemberPassword({ userData });

      if (response.status === 200 || response.status === 201) {
        displayAlert("Password reset successful. Redirecting to login...");
        setTimeout(() => navigate("/memberaccountready"), 2000); // Redirect to login after 3 seconds
      }
    } catch (err) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="container2">
          <Col sm={6} md={6} className="logindiv2xx">
            <Image fluid src={sss} className="imgsizexs" />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingTop: "2rem",
                fontSize: "1.9rem",
                width: "65%",
              }}
            >
              Transform Your Church Management with Churchease
            </p>
            <p className="redi">
              <Link to="/" className="single5">
                <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back
                to home page
              </Link>
            </p>
          </Col>
          <Col className="signupcolumn">
            <form onSubmit={handleVerifyUser} className="formdiv">
              <p className="logintextmessagec">Account Verification</p>
              {info && (
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "1em",
                  }}
                >
                  Password do not match. Try again.
                </p>
              )}

              {showAlert && <Alert />}

              <>
                <div className="label">
                  <Form.Label
                    htmlFor="inputPassword5"
                    style={{ fontWeight: "600", fontSize: "1em" }}
                  >
                    Password
                  </Form.Label>

                  <input
                    type="password"
                    id="email"
                    className="forminput"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>

                <div className="label">
                  <Form.Label
                    htmlFor="inputPassword5"
                    style={{ fontWeight: "600", fontSize: "1em" }}
                  >
                    Confirm Password
                  </Form.Label>

                  <input
                    type="password"
                    id="email"
                    className="forminput"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                </div>
              </>

              <div className="flex justify-center">
                <button type="submit" className="buttonner">
                  Set Password
                </button>
              </div>

              <br></br>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewUserVerify;
