import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/appContext";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Container, Col } from "react-bootstrap";
import axios from "axios";

const VolunteerSignUp = () => {
  const params = useParams();
  const _id = params.id;

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const { scheduleViewById, uniqueschedule, showAlert, user, logoutUser } =
    useAppContext();
  useEffect(() => {
    scheduleViewById(_id);
  }, []);

  useEffect(() => {
    getExpressionInterest();
  }, []);
  const [checkInterest, setCheckInterest] = useState([]);
  const [dataExist, setDataExist] = useState(false);
  useEffect(() => {
    const exists = checkInterest.some((item) => item.caseId === _id);
    setDataExist(exists);
  }, [checkInterest]);

  const [showx, setShowx] = useState(false);
  const handleClosex = () => setShowx(false);
  const handleShowx = (item) => {
    setShowx(true);
  };
  const [showNotice, setShowNotice] = useState(false);
  const skillsRequired = uniqueschedule?.skillRequired?.map(
    (skill) => skill.value
  );

  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  useEffect(() => {
    if (showNotice) {
      const timer = setTimeout(() => {
        setShowNotice(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showNotice]);

  const expressInterest = async () => {
    const data = {
      positionName: selectedPosition,
      scheduleId: uniqueschedule._id,
      scheduleName: uniqueschedule.schedule,
      memberName: user.mfirstName + " " + user.mlastName,
      memberId: user._id,
      churchName: user.churchName,
      status: "Applied",
      caseId: _id,
    };
    try {
      await authFetch.post("/volunteerapp", data);
      setShowNotice(true);
      handleClosex();
      setSelectedPosition(null);
    } catch (error) {
      console.error("Could not post:", error);
    }
  };

  const getExpressionInterest = async () => {
    try {
      const { data } = await authFetch.get("/volunteerapp");

      setCheckInterest(data);
    } catch (error) {
      logoutUser();
    }
  };

  return (
    <>
      <Container fluid className="wlwl2">
        {showNotice && (
          <div className="interte">Your interest has been submitted.</div>
        )}
        <Row className="fundc">
          <Col className="sahs" style={{ fontWeight: "600" }}>
            {uniqueschedule.schedule}
          </Col>
          <hr style={{ marginTop: "20px" }} />
        </Row>
        <Row className="funwdc">
          <div>
            <p>{uniqueschedule.scheduleDescription}</p>
            <hr />
            <div>
              <p className="fontrger">When are your required?</p>
              <ul>
                {uniqueschedule.volunteerSchedules?.map((schedule, index) => (
                  <li key={index}>
                    Start Time: {schedule.startTime} to End Time:{" "}
                    {schedule.endTime}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="fontrger">What skills are required?</p>
              <ul>
                {skillsRequired?.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </div>
            <div>
              <p className="fontrger">What skills are required?</p>
              <ul>
                {uniqueschedule.teamMembers?.map((member, index) => (
                  <li key={index}>
                    {member.memberName}
                    <ul>
                      {member.positions.map((position, posIndex) => (
                        <li key={posIndex}>
                          {position.positionName} (Open Spots:{" "}
                          {position.quantity})
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            {dataExist ? (
              ""
            ) : (
              <button className="jiluil mt-5" onClick={handleShowx}>
                Express Interest
              </button>
            )}
          </div>
        </Row>
      </Container>

      <Modal show={showx} onHide={handleClosex}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "120%", fontWeight: "600" }}>
            Express Interest
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              {uniqueschedule.teamMembers?.map((member, index) => (
                <div key={index} style={{ paddingTop: "5px" }}>
                  <p className="dvv">{member.memberName}</p>
                  {member.positions?.map((position, posIndex) => (
                    <Form.Check
                      key={posIndex}
                      type="radio"
                      label={position.positionName}
                      value={position.positionName}
                      checked={selectedPosition === position.positionName}
                      onChange={handleRadioChange}
                      className="qewwwww"
                    />
                  ))}
                </div>
              ))}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="but-delete" onClick={handleClosex}>
            Cancel
          </button>
          <button className="wqwqw3r3" onClick={expressInterest}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VolunteerSignUp;
