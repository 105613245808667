import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Alert from "../Alert/Alert";
import { useAppContext } from "../../context/appContext";
const EditFolderModal = ({
  editFolderModal,
  toggleEditFolder,
  name,
  id,

  folder_id,
}) => {
  const { editFolder, getRootFolder, getFolder, showAlert } = useAppContext();
  const [folderName, setFolderName] = useState("");

  const handleUpdate = async () => {
    await editFolder(id, folderName);
    setFolderName("");
    toggleEditFolder();
    if (folder_id) {
      await getFolder(folder_id);
    } else {
      await getRootFolder();
    }
  };
  useEffect(() => {
    setFolderName(name);
  }, [name]);
  return (
    <Modal isOpen={editFolderModal} toggle={toggleEditFolder}>
      {showAlert && <Alert />}
      <ModalHeader toggle={toggleEditFolder}>Edit Folder</ModalHeader>
      <ModalBody className="modal-wrapper__body">
        <Row>
          <Col md="4">
            <Label for="tag-name" className="modal-form-group-label">
              Folder Name
            </Label>
          </Col>
          <Col>
            <Input
              type="text"
              name="name"
              id="tag-name"
              placeholder="Ministry Name"
              required
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="jiluil3" onClick={toggleEditFolder}>
          Cancel
        </button>
        <button onClick={handleUpdate} className="jiluil">
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EditFolderModal;
