import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import "./profile.css";
import { Modal } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import { SiMailchimp } from "react-icons/si";
import CustomAlert from "../CustomAlert/CustomAlert";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const TextSetup = ({ show, setShow }) => {
  const { user, logoutUser } = useAppContext();

  const [uniqueTextId, setUniqueTextId] = useState({});
  const [uniqueText, setUniqueText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phone, setPhone] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [alloted, setAlloted] = useState(false);
  const [error, setError] = useState(false);
  const [dataValue, setDataValue] = useState(false);
  const [userNumber, setUserNumber] = useState(user.primaryPhoneNumber);
  const [selectedNumber, setSelectedNumber] = useState();

  // Get available phone numbers
  const getPhoneNumbers = async () => {
    try {
      const response = await authFetch.get("/text");

      setPhoneNumbers(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTextById = async () => {
    try {
      const response = await authFetch.get(`/text/getnumberId/${userNumber}`);
      const texts = response.data;
      const [data] = texts;
      setUniqueText(data);
      const text = texts.length > 0 ? texts[0] : null;
      setUniqueTextId(text);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   checkChange();
  // }, [user?.primaryPhoneNumber, uniqueTextId?.status]);

  useEffect(() => {
    if (user && user.primaryPhoneNumber) {
      setUserNumber(user.primaryPhoneNumber);
      setDataValue(user._id);
    }
  }, [user]);

  useEffect(() => {
    getTextById();
  }, [user.primaryPhoneNumber]);

  useEffect(() => {
    if (!selectedNumber) {
      getPhoneNumbers();
    }
  }, []);

  // const checkChange = () => {
  //   if (user.primaryPhoneNumber && uniqueTextId?.status === "alloted") {
  //     setAlloted(true);
  //   } else {
  //     setAlloted(false);
  //   }
  // };

  useEffect(() => {
    getMemberById();
  }, []);

  const getMemberById = async () => {
    try {
      const response = await authFetch.get(`/members/getthisuser/${user._id}`);

      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setSelectedNumber(data.primaryPhoneNumber);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //console.log("selectedNumber", selectedNumber);
  // const handlePhoneChange = (event) => {
  //   const phoneObj = phoneNumbers.find(
  //     (phone) => phone.phone === event.target.value
  //   );
  //   setSelectedPhone(phoneObj);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPhone) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please choose a preferred phone number to continue!"
        />
      );
      return;
    }

    const data = {
      primaryPhoneNumber: selectedPhone,
      churchId: user._id,
      // textId: selectedPhone._id,
    };

    setIsLoading(true); // Start loading

    try {
      const response = await authFetch.post(`/text/push`, data);

      if (response.status === 200 || response.status === 201) {
        getMemberById();
        handleClose();
        getPhoneNumbers();
        getTextById();
        handleClosexx();
        toast(
          <CustomToastContent
            title="Success"
            message="Phone number successfully taken!"
          />
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  const [showxx, setShowxx] = useState(false);
  const handleClosexx = () => setShowxx(false);

  const handleShowxx = () => setShowxx(true);

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Text Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="leftgivediv eweyuu">
            <form className="srgerged">
              {!user.country && (
                <div className="notification-prompt">
                  Please update your church information and country to choose a
                  phone number.
                </div>
              )}

              <p className="profiletitle pt-5"></p>

              <Form.Group className="mb-3">
                <Form.Label className="flex justify-between">
                  <p style={{ marginBottom: "0px" }}>Select a Phone Number </p>
                  {!selectedNumber && (
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "var(--secondary-color)",
                      }}
                    >
                      {phoneNumbers.length} Phone numbers available in your
                      region
                    </p>
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  disabled={selectedNumber}
                  //onChange={handlePhoneChange}
                  value={selectedPhone}
                  onChange={(e) => setSelectedPhone(e.target.value)}
                >
                  <option value="">
                    {selectedNumber ? selectedNumber : "Select a phone number"}
                  </option>
                  {phoneNumbers?.map((phone) => (
                    <option key={phone._id} value={phone.phoneNumber}>
                      {phone.friendlyName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {!selectedNumber && (
                <button
                  type="button"
                  className={`jiluil mt-3 ${!alloted ? "mb-5" : "mb-3"}`}
                  disabled={!user.country}
                  onClick={handleShowxx}
                >
                  <SiMailchimp /> TAKE NUMBER
                </button>
              )}

              {selectedNumber && (
                <p style={{ textAlign: "center" }}>
                  Your primary phone number is{" "}
                  <span style={{ fontWeight: "bold" }}>{selectedNumber}.</span>
                </p>
              )}
            </form>
          </div>
          <div
            className="flex align-center justify-center flex-column w-100 gap-5"
            style={{ textAlign: "center" }}
          >
            <p>
              <span
                style={{ fontWeight: "600", color: "var(--secondary-color)" }}
              >
                Note:
              </span>{" "}
              The selected phone number will be used for all text communications
              in your church. This action can only be performed once.
              <span style={{ fontWeight: "600" }}>
                {" "}
                Selected phone numbers cannot be modified for the entire
                lifecycle of the church.
              </span>
            </p>

            <p className="qubulkf2">
              Check our{" "}
              <a
                href="https://churchease.com/knowledgebaseview/66716274c206720057060140"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "var(--secondary-color)",
                  textDecoration: "none",
                }}
              >
                knowledge base
              </a>{" "}
              for more information on Configuring text messaging for your
              church.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showxx} onHide={handleClosexx}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="leftgivediv">
            This action can only be performed once. Are you sure you want to
            take this number?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClosexx}>
            Close
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TextSetup;
