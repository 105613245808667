import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import nodd from "../../../assets/newi/nodd.png";
import AddForm from "../../../components/Forms/AddForm";
import EditForm from "../../../components/Forms/EditForm";
import DeleteForm from "../../../components/Forms/DeleteForm";
import PublishForm from "../../../components/Forms/PublishForm";
import ShareForm from "../../../components/Forms/ShareForm";
import AssignForm from "../../../components/Forms/AssignForm";
import UpdatedFormsTable from "../../../components/Forms/UpdatedFormsTable";
import "./forms.css"; // Import the external CSS for alignment

const Forms = () => {
  const { logoutUser } = useAppContext();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  useEffect(() => {
    loadCourse();
  }, []);

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms`);
    setForms(data.forms);
  };

  const handleShow = (event, form) => {
    event.preventDefault();
    setShow(true);
    setSelectedForm(form);
  };

  const handleShow5 = (event, form) => {
    event.preventDefault();
    setShow5(true);
    setSelectedForm(form);
  };

  const handleShow6 = (event, form) => {
    event.preventDefault();
    setShow6(true);
    setSelectedForm(form);
  };

  const handleShow7 = (event, form) => {
    event.preventDefault();
    setShow7(true);
    setSelectedForm(form);
  };

  const handleView = (event, form) => {
    event.preventDefault();
    setShow3(true);
    setSelectedForm(form);
  };

  const handleCopy = async (event, form) => {
    event.preventDefault();
    const copyForm = { ...form, title: form.title + " (copy)" };
    delete copyForm._id;

    try {
      const response = await authFetch.post("/forms", copyForm);
      if (response.status === 200 || response.status === 201) {
        loadCourse();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error copying form:", error);
    }
  };

  // Define the handlers object after the functions are declared
  const handlers = {
    handleView,
    handleCopy,
    handleShow5,
    handleShow7,
    handleShow,
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Forms</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={() => setShow2(true)}>
              New Form
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {forms.length > 0 ? (
              <Row className="tabw">
                <p className="records-count text-bold text-lg">
                  {forms.length} record{forms.length > 1 ? "s" : ""} found
                </p>
                <UpdatedFormsTable forms={forms} handlers={handlers} />
              </Row>
            ) : (
              <div className="noData">
                <img src={nodd} alt="no data" />
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>

      {/* Modals for various actions */}
      <AddForm show={show2} setShow={setShow2} uploadSuccessFunc={loadCourse} />
      <EditForm
        show={show3}
        setShow={setShow3}
        selectedForm={selectedForm}
        uploadSuccessFunc={loadCourse}
      />
      <DeleteForm
        show={show}
        setShow={setShow}
        selectedForm={selectedForm}
        uploadSuccessFunc={loadCourse}
      />
      <PublishForm
        show={show5}
        setShow={setShow5}
        selectedForm={selectedForm}
        uploadSuccessFunc={loadCourse}
      />
      <ShareForm
        show={show6}
        setShow={setShow6}
        selectedForm={selectedForm}
        uploadSuccessFunc={loadCourse}
      />
      <AssignForm
        show={show7}
        setShow={setShow7}
        selectedForm={selectedForm}
        uploadSuccessFunc={loadCourse}
      />
    </>
  );
};

export default Forms;
