import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./formssss.css";
import he from "he";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const EditForm = ({ show, setShow, selectedForm, uploadSuccessFunc }) => {
  const { logoutUser, user } = useAppContext();
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const handleClose = () => setShow(false);
  const [formById, setFormById] = useState([{}]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [forms, setForms] = useState([{}]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await authFetch.get(`/forms/${selectedForm?._id}`);
        const [formx] = data;
        setFormById(formx);
      } catch (error) {
        console.error("Error fetching form data:", error);
        // Handle the error appropriately
      }
    };
    fetchData();
  }, [selectedForm]);

  useEffect(() => {
    if (formById) {
      setTitle(formById.title);
      // setDescription(formById.description);
      const decodedDescription = he.decode(formById.description || "");
      setDescription(decodedDescription);
      setForms(formById.forms || []);
    }
  }, [formById]);

  const maxChars = 800;

  const handleTypeChange = (index, value) => {
    const newTasks = [...forms];
    newTasks[index].type = value;
    setForms(newTasks);
  };

  const handleQuillChange = (content) => {
    setDescription(content);
  };

  const addOptionToTask = (index, event) => {
    event.preventDefault();
    const updatedFields = forms.map((form, i) =>
      i === index ? { ...form, options: [...form.options, ""] } : form
    );
    setForms(updatedFields);
  };

  // Function to add a new field to the form
  const addForm = (event) => {
    event.preventDefault();
    const newField = { question: "", type: "text", options: [] };
    setForms([...forms, newField]);
  };

  // Function to remove a specific option from a field
  const removeOption = (taskIndex, optionIndex) => {
    const updatedFields = forms.map((form, i) => {
      if (i === taskIndex) {
        const filteredOptions = form.options.filter(
          (_, j) => j !== optionIndex
        );
        return { ...form, options: filteredOptions };
      }
      return form;
    });
    setForms(updatedFields);
  };

  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const updatedFields = forms.map((form, i) => {
      if (i === taskIndex) {
        const updatedOptions = form.options.map((option, j) =>
          j === optionIndex ? value : option
        );
        return { ...form, options: updatedOptions };
      }
      return form;
    });
    setForms(updatedFields);
  };

  const handleCheckedChange = (index, isChecked) => {
    const updatedForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, checked: isChecked };
      }
      return form;
    });
    setForms(updatedForms);
  };

  const handleTaskChange = (index, value) => {
    const updatedFields = forms.map((form, i) =>
      i === index ? { ...form, question: value } : form
    );
    setForms(updatedFields);
  };

  const handlePlanner = async () => {
    try {
      const data = {
        title,
        description,
        forms,
        churchName: user.churchName,
      };

      const response = await authFetch.put(`/forms/${selectedForm._id}`, data);
      if (response.status === 200 || response.status === 201) {
        uploadSuccessFunc();
        handleClose();
        toast(
          <CustomToastContent title="Success" message="Operation Successful" />
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeForm = (index) => {
    const updatedForms = forms
      .filter((_, formIndex) => formIndex !== index)
      .map((form, i) => ({ ...form, order: i + 1 }));
    setForms(updatedForms);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form>
            <Form.Group controlId="formBasicTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="formBasicDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group controlId="formBasicDescription" className="mb-5">
              <Form.Label>Form Description</Form.Label>

              <ReactQuill
                theme="snow"
                placeholder="Enter description"
                className="lpmw"
                style={{ height: "300px" }}
                value={description}
                onChange={handleQuillChange}
              />
              <div className="character-count flex items-center justify-end">
                {`${description.length} / ${maxChars}`} characters.
              </div>
            </Form.Group>

            <div>
              <Form.Label>Field Title</Form.Label>
              {forms.map((task, index) => (
                <Row key={index} className="mb-3">
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      placeholder="e.g. First Name"
                      value={task.question}
                      onChange={(e) => handleTaskChange(index, e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Select
                      aria-label="Default select example"
                      value={task.type}
                      onChange={(e) => handleTypeChange(index, e.target.value)}
                    >
                      <option>Choose...</option>
                      <option value="text">Short answer</option>
                      <option value="textarea">Paragraph</option>
                      <option value="radio">Multiple Choice</option>
                      <option value="checkbox">Checkboxes</option>
                    </Form.Select>
                  </Col>
                  <Col
                    md={2}
                    className="flex flex-row justify-between "
                    style={{ alignItems: "center" }}
                  >
                    <Form.Check
                      type="checkbox"
                      label="Required"
                      checked={task.isChecked}
                      onChange={(e) =>
                        handleCheckedChange(index, e.target.checked)
                      }
                    />
                    {index > 0 && (
                      <p
                        onClick={() => removeForm(index)}
                        style={{
                          marginBottom: "0px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Remove
                      </p>
                    )}
                  </Col>
                  {task.type === "radio" || task.type === "checkbox" ? (
                    <>
                      {task.options.map((option, optionIndex) => (
                        <Form.Group
                          key={optionIndex}
                          className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                        >
                          <Form.Check
                            type={task.type}
                            name={`group-${index}`}
                          />
                          <Form.Control
                            type="text"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                e.target.value
                              )
                            }
                          />
                          <FaTimes
                            onClick={() => removeOption(index, optionIndex)}
                            style={{ cursor: "pointer" }}
                          />
                        </Form.Group>
                      ))}
                      <button
                        className="jiluil3 mt-3 mb-3"
                        onClick={(event) => addOptionToTask(index, event)}
                      >
                        Add Option
                      </button>
                      <hr></hr>
                    </>
                  ) : null}
                </Row>
              ))}
              <hr></hr>
              <button className="jiluil mb-3s" onClick={addForm}>
                Add Form
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handlePlanner}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditForm;
