import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "../../../components/Alert/Alert";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import nodd from "../../../assets/newi/nodd.png";
import { Link } from "react-router-dom";
import moment from "moment";
import AddLessonPlanner from "../../../components/LessonPlanner/AddLessonPlanner";
import LessonAssessment from "../../../components/LessonPlanner/LessonAssessment";
import EditLessonPlanner from "../../../components/LessonPlanner/EditLessionPlanner";
import LessonGetAssessment from "../../../components/LessonPlanner/LessonGetAssessment";
import { useParams } from "react-router-dom";

const TeachersDesk = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const navigate = useNavigate();
  const { teacherId } = useParams();
  const { logoutUser, showAlert, user } = useAppContext();
  const [lessonPlanner, setLessonPlanner] = useState([]);
  const [clicked, setClicked] = useState("");
  const [show, setShow] = useState(false); // Modal for Add Lesson Planner
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show3, setShow3] = useState(false); // Modal for Add Assessment
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true); // Triggered by "Add Assessment" button

  const [clickeddd, setClickeddd] = useState("");

  const [teacherDetails, setTeacherDetails] = useState(null);

  const [show4, setShow4] = useState(false); // Modal for Delete Lesson Plan
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (id) {
      setShow4(true);
      setClickeddd(id);
    }
  };
  console.log(clickeddd);
  const [show5, setShow5] = useState(false); // Modal for Edit Lesson Planner
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (id) {
      setShow5(true);
      setClicked(id);
    }
  };

  const [clickedData, setClickeddata] = useState(null);
  const [show6x, setShow6x] = useState(false); // Modal for Get Assessment
  const handleClose6x = () => setShow6x(false);
  const handleShow6x = (e, planner) => {
    e.preventDefault();
    setShow6x(true);
    setClickeddata(planner);
  };

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getLessonPlanner();
    getAssessment();
  }, []);

  const handleShowxxxxx = () => {
    navigate("/auth/volunteermanagement");
  };

  const getLessonPlanner = async () => {
    try {
      setIsLoading(true);
      const data = await authFetch.get("/children/lessonplanner");
      if (data.status === 200 && data.data) {
        setLessonPlanner(data.data);
      }
    } catch (error) {
      console.error("Failed to retrieve lesson planner data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteLessonPlan = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const data = await authFetch.delete(
        `/children/lessonplanner/${clickeddd}`
      );
      if (data.status === 200) {
        getLessonPlanner();
        handleClose4();
      }
    } catch (error) {
      console.error("Failed to delete lesson plan:", error.message);
    }
  };

  const [assessments, setAssessments] = useState([]);
  const [showMyAssessment, setShowMyAssessment] = useState(false);
  const [showMyLesson, setShowMyLesson] = useState(false);

  const showAssessment = (e) => {
    e.preventDefault();
    setShowMyAssessment(true);
    setShowMyLesson(false);
  };

  const showLesson = (e) => {
    e.preventDefault();
    setShowMyLesson(true);
    setShowMyAssessment(false);
  };

  const getAssessment = async () => {
    try {
      setIsLoading(true);
      const data = await authFetch.get("/children/assessment");
      if (data.status === 200 && data.data) {
        setAssessments(data.data);
      }
    } catch (error) {
      console.error("Failed to retrieve assessment data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const [selectedidd, setSelectedidd] = useState("");
  const [show9, setShow9] = useState(false); // Modal for Delete Assessment
  const handleClose9 = () => setShow9(false);
  const showDelete = (e, id) => {
    e.preventDefault();
    if (id) {
      setShow9(true);
      setSelectedidd(id);
    }
  };

  const deleteAssessment = async (e, id) => {
    e.preventDefault();
    try {
      const data = await authFetch.delete(
        `/children/assessment/${selectedidd}`
      );
      if (data.status === 200) {
        getAssessment();
        handleClose9();
      }
    } catch (error) {
      console.error("Failed to delete assessment:", error.message);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="sssssssecwef mt-5">
          <div className="bnmjo">
            <button className="jiluil" onClick={showLesson}>
              Lesson Plan
            </button>
            <button className="jiluil4" onClick={showAssessment}>
              Assessment
            </button>
            <button className="jiluil3" onClick={handleShowxxxxx}>
              Volunteer Request
            </button>
          </div>
        </Row>
        {isLoading ? (
          <Loading />
        ) : showMyAssessment && assessments.length > 0 ? (
          <Row className="fundc2">
            <div className="mb-5 mt-3">
              <button className="jiluil" onClick={handleShow3}>
                Create Assessment
              </button>
            </div>
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>CREATED DATE</th>
                  <th style={{ fontSize: "1em" }}>ASSESSMENT TITLE</th>
                  <th style={{ fontSize: "1em" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {assessments.map((planner) => (
                  <tr key={planner._id}>
                    <td style={{ fontSize: "1em" }}>
                      {moment(planner.createdAt).format("MMMM Do YYYY")}
                    </td>
                    <td style={{ fontSize: "1em" }}>{planner.title}</td>
                    <td
                      style={{ display: "flex", gap: "10px", fontSize: "1em" }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => handleShow6x(e, planner)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil"
                        onClick={(e) => showDelete(e, planner._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : showMyAssessment ? (
          <div className="noData">
            <div className="flex justify-start w-100">
              <button className="jiluil4" onClick={handleShow3}>
                Add Assessment
              </button>
            </div>
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">No assessment available for display.</p>
          </div>
        ) : null}

        {showMyLesson && lessonPlanner.length > 0 ? (
          <Row className="flex justify-start ">
            <div className="mb-5 mt-3">
              <button className="jiluil" onClick={handleShow}>
                Create Lesson Plan
              </button>
            </div>
            <Table responsive hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "1em" }}>CREATED DATE</th>
                  <th style={{ fontSize: "1em" }}>TITLE</th>
                  <th style={{ fontSize: "1em" }}>TOPIC</th>
                  <th style={{ fontSize: "1em" }}>LESSON DATE</th>
                  <th style={{ fontSize: "1em" }}>LESSON OBJECTIVE</th>
                  <th style={{ fontSize: "1em" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {lessonPlanner.map((planner) => (
                  <tr key={planner._id}>
                    <td style={{ fontSize: "1em" }}>
                      {moment(planner.createdAt).format("MMMM Do YYYY")}
                    </td>
                    <td style={{ fontSize: "1em" }}>{planner.title}</td>
                    <td style={{ fontSize: "1em" }}>{planner.topic}</td>
                    <td style={{ fontSize: "1em" }}>
                      {moment(planner.lessonDate).format("MMMM Do YYYY")}
                    </td>
                    <td style={{ fontSize: "1em" }}>
                      {planner.learningObjectives}
                    </td>
                    <td
                      style={{ display: "flex", gap: "10px", fontSize: "1em" }}
                    >
                      <button
                        className="jiluil3"
                        onClick={(e) => handleShow5(e, planner)}
                      >
                        Edit
                      </button>
                      <button
                        className="jiluil"
                        onClick={(e) => handleShow4(e, planner._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : showMyLesson ? (
          <div className="noData">
            <div className="flex justify-start w-100">
              <button className="jiluil" onClick={handleShow}>
                Create Lesson Plan
              </button>
            </div>
            <img src={nodd} alt="no data" />
            <p className="noDataBackup">
              No lesson plan available for display.
            </p>
          </div>
        ) : null}
      </Container>

      {/* Modals */}
      <AddLessonPlanner
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleReload={getLessonPlanner}
        teacherDetails={teacherDetails}
      />
      <LessonAssessment
        show={show3}
        setShow={setShow3}
        handleClose={handleClose3}
        handleReload={getAssessment}
        teacherDetails={teacherDetails}
      />
      <LessonGetAssessment
        show={show6x}
        setShow={setShow6x}
        handleClose={handleClose6x}
        handleReload={getAssessment}
        clicked={clickedData}
      />
      <EditLessonPlanner
        show={show5}
        setShow={setShow5}
        handleClose={handleClose5}
        handleReload={getLessonPlanner}
        clicked={clicked}
      />
      {/* Delete Lesson Plan Modal */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>
            Delete Lesson Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected lesson plan.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose4}>
            Cancel
          </button>
          <button className="jiluil" onClick={deleteLessonPlan}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Assessment Modal */}
      <Modal show={show9} onHide={handleClose9}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1em" }}>
            Delete Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action will permanently delete the selected assessment.
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose9}>
            Cancel
          </button>
          <button className="jiluil" onClick={deleteAssessment}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeachersDesk;
