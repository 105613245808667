import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import OneTimeGiving from "./OneTimeGiving";
import { Container, Row } from "react-bootstrap";
import Loading from "../../components/Loading/Loading";
const PayElements = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const { clientSecret, pkValue, skValue, currency, amount, churchName } =
    location.state || {};
  const [secretKey, setSecretKey] = useState(null);
  const [amountx, setAmount] = useState(null);
  const [currencyx, setCurrency] = useState(null);

  useEffect(() => {
    if (pkValue) {
      // Initialize Stripe only when pkValue is available
      const stripe = loadStripe(pkValue);
      setStripePromise(stripe);
    }
  }, [pkValue]); // Re-initialize Stripe when pkValue changes

  useEffect(() => {
    if (skValue && amount && currency) {
      // Initialize Stripe only when pkValue is available
      setSecretKey(skValue);
      setAmount(amount);
      setCurrency(currency);
    }
  }, [skValue]); // Re-initialize Stripe when pkValue changes

  return (
    <Container fluid className="contWwainer2">
      <Row style={{ padding: "0", margin: "0" }}>
        <div className="flex items-center flex-column wrrerx">
          <div className="formdivfdssx">
            <div className="flex flex-column">
              <p className="awwq1 mb-4">Step 2</p>
              <p className="awwq mb-4">Secure Payment</p>
            </div>
            {stripePromise ? (
              <Elements stripe={stripePromise}>
                <OneTimeGiving
                  clientSecret={clientSecret}
                  secretKey={secretKey}
                  amount={amountx}
                  currency={currencyx}
                  churchName={churchName}
                />
              </Elements>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default PayElements;
