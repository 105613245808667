import React, { useState, useEffect } from "react";
import "./serviceplanner.css";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import moment from "moment/moment";
import Alert from "../../../components/Alert/Alert";
import HighLightText from "../../../components/HighLightText/HighLightText";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  trimInputs,
  validateLength,
  validateNotEmpty,
  NAME_LENGTH_LIMIT,
  DESCRIPTION_LENGTH_LIMIT,
} from "../../../utils/validation";

const ServicePlanner = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    showAlert,
    events,
    logoutUser,
    getEvents,
    createService,
    deleteService,
    getServices,
    servicePlanner,
    user,
    isLoading,
  } = useAppContext();

  const navigate = useNavigate();
  const [deleteData, setDeleteData] = useState({});
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (data) => {
    setShow2(true);
    setDeleteData(data);
    //deleteService(data._id, { alertText: "Schedule Deleted" });
  };

  const deleteServ = async () => {
    deleteService(deleteData._id);
    handleClose2();
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [appId, setAppId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (appId) => {
    setShow1(true);
    setAppId(appId);
  };

  const [myEvent, setMyEvent] = useState([]);
  const [useEventData, setUseEventData] = useState("");
  const searchTerm = useEventData?.toLowerCase().trim();
  const [showDropdown, setShowDropdown] = useState(false);
  const [useEvent, setUseEvent] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [frequency, setFrequency] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [minDate, setMinDate] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setMinDate(formattedDate);
  }, []);
  useEffect(() => {
    events && getEvents();
    servicePlanner && getServices();
  }, []);

  useEffect(() => {
    if (events && events.length > 0) {
      setMyEvent(events);
    }
  }, [events]);

  const handleSubmit = async () => {
    if (!startDate || !startTime || !endTime) {
      setError("Complete all fields to proceed.");
      return;
    }
    const [trimmedserviceName, trimmedserviceDescription] = trimInputs([
      serviceName,
      serviceDescription,
    ]);

    // Reset error state before starting validation checks
    setError("");

    if (!validateNotEmpty(trimmedserviceName)) {
      setError(" Please provide Service Name to proceed.");

      return;
    }

    // Validate tag length
    if (!validateLength(trimmedserviceName, NAME_LENGTH_LIMIT)) {
      setError(`Service Name cannot exceed ${NAME_LENGTH_LIMIT} words.`);
      return;
    }

    // Validate description length
    if (!validateLength(trimmedserviceDescription, DESCRIPTION_LENGTH_LIMIT)) {
      setError(`Description cannot exceed ${DESCRIPTION_LENGTH_LIMIT} words.`);
      return;
    }

    const currentService = {
      serviceName: trimmedserviceName,
      serviceDescription: trimmedserviceDescription,
      startTime: startTime,
      endTime: endTime,
      startDate: startDate,
      churchName: user.churchName,
    };

    createService({ currentService });

    setServiceName("");
    setServiceDescription("");
    setStartTime("");
    setEndTime("");
    setStartDate("");
    getServices();
    handleClose();
  };

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="headerbanner">
          <div className="bannerbig">Service Planner</div>
          <div className="bannersmall">
            Efficiently plan your church events with our Service Planner. Assign
            tasks to members and send them notifications to ensure everyone
            knows their responsibilities, keeping every event organized and on
            track.
          </div>
        </Row>
        <Row className="sssssssecwef">
          <div className="bnmjo">
            <Button variant="danger" className="wedweded" onClick={handleShow}>
              New Service Plan
            </Button>
          </div>
        </Row>
        <Row className="fundc2 ">
          {servicePlanner && servicePlanner.length > 0 ? (
            <Table
              hover
              responsive
              className="table-wrap"
              style={{ backgroundColor: "#f7f7f7" }}
            >
              <thead>
                <tr>
                  <th>SCHEDULE NAME</th>
                  <th>SCHEDULE DESCRIPTION</th>

                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {servicePlanner.map((data, index) => (
                  <tr>
                    <td className="servicetable">{data?.serviceName}</td>
                    <td className="servicetable">{data?.serviceDescription}</td>

                    <td className="servicetable">
                      <Link
                        to={"/auth/viewservice/" + data?._id}
                        style={{ color: "black", marginRight: "10px" }}
                      >
                        <button className="jiluil">Edit</button>
                      </Link>

                      <button
                        className="jiluil3"
                        onClick={() => handleShow2(data)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="noDataBackup flex items-center justify-center">
              Click the button above to create your first service plan.
            </div>
          )}

          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleted Schedules cannot be recovered!</Modal.Body>
            <Modal.Footer>
              <Button className="wewecwe" onClick={handleClose2}>
                Cancel
              </Button>
              <Button className="wewecwe1" onClick={deleteServ}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="inlineerror">{error}</p>}
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Update service using existing events"
              name="defaultFund"
              value="defaultFund"
              onChange={(e) => setUseEvent(e.target.checked)}
            />
          </Form.Group>
          {useEvent && (
            <Form.Group
              className="mb-5"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="input-container">
                <Form.Control
                  type="text"
                  value={useEventData}
                  onChange={(e) => {
                    setUseEventData(e.target.value);
                    setShowDropdown(true);
                  }}
                  placeholder="Type the event name"
                  className="formvalueccccc"
                  required
                />

                {useEventData && (
                  <span
                    className="close-icon"
                    onClick={() => {
                      setServiceName("");
                      setUseEventData("");
                      setShowDropdown(false);
                    }}
                    style={{ color: "red" }}
                  >
                    X
                  </span>
                )}
              </div>
              {showDropdown && (
                <div className="dropdown-container">
                  {myEvent &&
                    myEvent
                      .filter((item) => {
                        if (!searchTerm) return false;
                        const eventTitle = item.eventTitle?.toLowerCase();
                        return eventTitle.includes(searchTerm);
                      })
                      .sort(
                        (a, b) => new Date(a.eventDay) - new Date(b.eventDay)
                      )
                      .slice(0, 10)
                      .map((item) => (
                        <div
                          onClick={() => {
                            const eventDetail = `${item.eventTitle} - ${moment(
                              item.eventDay
                            ).format("MMMM Do YYYY")}`;
                            // setSchedule(eventDetail);
                            setServiceName(item.eventTitle);
                            setStartDate(item.eventDay);
                            setServiceDescription(item.eventDescription);
                            setStartTime(item.eventStartTime);
                            setEndTime(item.eventEndTime);
                            setUseEventData(eventDetail); // Set the selected event detail to the useEventData
                            setShowDropdown(false); // Hide the dropdown after selecting an item
                          }}
                          className="clickable-event"
                          key={item.eventTitle}
                        >
                          <HighLightText
                            text={item.eventTitle}
                            highlight={useEventData}
                          />{" "}
                          -{moment(item.eventDay).format("MMMM Do YYYY")}
                        </div>
                      ))}
                </div>
              )}
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="hed">
              Service Name<span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={serviceName}
              name="serviceName"
              onChange={(e) => setServiceName(e.target.value)}
              className="formvalueccccc"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="hed">Schedule Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="serviceDescription"
              value={serviceDescription}
              required
              onChange={(e) => setServiceDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="hed">Start Date</Form.Label>
            <Form.Control
              type="date"
              className="formvalueccccc"
              value={startDate}
              name="startDate"
              onChange={(e) => setStartDate(e.target.value)}
              min={minDate}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="hed">Start Time</Form.Label>
            <Form.Control
              type="time"
              className="formvalueccccc"
              value={startTime}
              name="startTime"
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="hed">End Time</Form.Label>
            <Form.Control
              type="time"
              className="formvalueccccc"
              value={endTime}
              name="endTime"
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose}>
            Close
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            Create Service Plan
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicePlanner;
