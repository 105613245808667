import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import { currencyFormatter } from "../../../utils/helpers";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import "./giving.css";

const Funds = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { displayAlert, showAlert, allfunds, getFund, addFund } =
    useAppContext();
  const [fundName, setFundName] = useState();
  const [defaultFund, setDefaultFund] = useState();
  const [taxDeductible, setTaxDeductible] = useState();
  const [fundId, setFundId] = useState();

  const [amountFlag, setAmountFlag] = useState(false);
  const [allfundsx, setAllFundsx] = useState([]);
  useEffect(() => {
    getFund();
  }, [getFund]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fundName) {
      displayAlert();
      return;
    }
    const fundDetails = {
      fundName: fundName,
      fundId: fundId,
      defaultFund: defaultFund,
      taxDeductible: taxDeductible,
    };

    addFund({ fundDetails });
    getFund();
    handleClose();
    setFundId();
    setFundName();
    setDefaultFund();
    setTaxDeductible();
  };

  const handleAmount = (e) => {
    let sorted = allfunds.sort((a, b) => {
      return amountFlag
        ? b.totalAmount - a.totalAmount
        : a.totalAmount - b.totalAmount;
    });
    setAllFundsx(sorted);
    setAmountFlag(!amountFlag);
  };

  // const handleFundsSort = (fundFlag) => {
  //   if (!fundFlag) {
  //     let sorted = allfunds.sort(function (a, b) {
  //       return a._id.localeCompare(b._id);
  //     });
  //     setAllFundsx(sorted);
  //   } else {
  //     let sorted = allfunds.reverse();
  //     setAllFundsx(sorted);
  //   }
  //   setFundFlag(!fundFlag);
  // };

  // console.log(allfunds);
  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="courseheader">
          <Col sm={10}>
            <p className="courseHeaderPadding">Funds Summary</p>
          </Col>
          <Col sm={2} className="courseheaderbutton"></Col>
        </Row>
        {allfunds && allfunds.length > 0 ? (
          <Row className="fundc2" style={{ paddingTop: "3rem" }}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Fund Name</th>
                  <th onClick={handleAmount}>
                    Accumulated Amount
                    {amountFlag ? <FaCaretDown /> : <FaCaretUp />}
                  </th>
                </tr>
              </thead>
              {allfunds.map((fundss, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{fundss._id}</td>
                    <td>
                      {currencyFormatter({
                        amount: fundss.totalAmount,
                        currency: "USD",
                      })}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </Row>
        ) : (
          <div className="noDatax">
            <p className="noDataBackup">No data available for display.</p>
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Fund Name"
                value={fundName}
                onChange={(e) => setFundName(e.target.value)}
                name="fundName"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="This fund is Tax Deductible"
                name="taxDeductible"
                value="taxDeductible"
                onChange={(e) => setTaxDeductible(e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="This is the Default Fund"
                name="defaultFund"
                value="defaultFund"
                onChange={(e) => setDefaultFund(e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Fund ID"
                value={fundId}
                onChange={(e) => setFundId(e.target.value)}
                name="fundId"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select aria-label="Default select example">
                <option>Create Within Folder</option>
                <option value="1">None</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose}>
            Cancel
          </button>
          <button className="jiluil3" onClick={handleSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Funds;
